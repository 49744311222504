import { compose } from 'recompose';

import PickList from './PickList';

import apiInterceptor from '../../api/interceptor';

import { pickListApi } from '../common/base.api';

const composedPickList = compose(apiInterceptor({ ...pickListApi }))(PickList);

export default composedPickList;
