import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ACTION } from '../../../data/enums/enums';
import { Icon } from '../../../components/BillingMDC';
import BillingTextField from '../../../components/TextField/BillingTextField';

const propTypes = { data: PropTypes.instanceOf(Array), };

const defaultProps = { data: {}, };

const OpeningBalanceChildView = ({ data,handleInputChange,onRowClick,dialogType ,activeIndex,index }) => {
  return (
    <Fragment>
      <tr>
        <th>Batch</th>
        <th className ='text-right'>Quantity</th>
        <th className ='text-right'>Cost Per Unit</th>
        <th></th>
      </tr>
      {data.batches && data.batches.length > 0 && data.batches.map((batch, i) => <Row key={i} rowData={batch} handleInputChange={handleInputChange} index={index} i ={i} dialogType={dialogType} onRowClick ={onRowClick} activeIndex={activeIndex}/>)}
    </Fragment>
  );
};

const Row = ({ rowData,handleInputChange,onRowClick,dialogType,index ,activeIndex  ,i }) => (
  <tr>
    <td>{rowData.batch}</td>
    <td className='text-right'>{rowData.quantity}</td>
    <td className={`text-right costPer ${dialogType ===ACTION.UPDATE && activeIndex === i ? 'enabled' :'disabled'}`}>
      <BillingTextField
        value={ rowData.per_unit_rate}
        handleChange={(e, value) => handleInputChange(e, value,index,i,rowData)}
        param='perUnitRate'
        type='number'
        zeroError={false}
        className='right-side-gap'
      />
    </td>
    <td className='right-align'>
      <Icon name='edit' onClick={() => onRowClick(rowData,ACTION.UPDATE,i)} />
    </td>
  </tr>
);

OpeningBalanceChildView.propTypes = propTypes;

OpeningBalanceChildView.defaultProps = defaultProps;

export default OpeningBalanceChildView;
