import React, { Component } from 'react';
import moment from 'moment/moment';
import DetailView from '../../common/detail/DetailView';
import PageButtonWrapper from '../../common/PageButtonWrapper';
import config from './config';
import { checkIfApiCallSuccess, jvApi,tagApi ,ledgerApi} from '../../common/base.api';
import withBaseState from '../../common/withBaseState';
import { CONTRA_ENTRY_BASE } from '../../../data/enums/Route';
import * as filterUtil from '../../../utils/filterUtil';
import { Button ,Icon} from '../../../components/BillingMDC';
import history from '../../../utils/history';
import { voucherDecoder } from '../../../utils/miscellaneous';
import { clone } from '../../../utils/arrayProcessor';
import Header from '../../../components/PrintComponent/Header';
import TableHeader from '../../../components/TableHeader/TableHeader';
import VoucherFooter from '../../../components/PrintComponent/voucherFooter';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { validateForm } from '../../common/validation';
import numberToWords from '../../../utils/numberToTextConverter';
import ContraEntryStyled from '../ContraEntryStyled';
import {flattenDataPrintAttributeWise, groupPrintDataSet} from "../../common/print.service";
import {ConfirmationDialog,CEDialog} from './Dialog';
import * as snackService from '../../common/snack.service';
import { BillingSnackBar } from '../../../components/BillingMDC';
import { getBackDatedStatus } from '../../../views/fundamentals/common/helpers';

const propTypes = {};
const defaultProps = {};

class ContraEntryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        summary: {},
      },
      userDetail: { id: 'userId', title: '' },
      preparedBy: { title: 'Prepared By', value: '' },
      dataList: [],
      print: false,
      createCE:config.formMapper({}),
      editInput:false,
      updateBtnSummary:false,
      showUpdateBtn:false,
      readModalOpen:false,
      editModalOpen:false,
      summary:{
        totalDrAmount:0,
        totalCrAmount:0,
        mainNarration:'',
        documentDate: filterUtil.getCurrentDay(),
      },
      summaryBackUp:{},
      validation: {
        flag: false,
        fieldList: config.formValidationFieldList,
      },
      idJournalVoucherDetail:0,
      ledgerList:[],
      ledgerListCash:[],
      dataBackup:[],
      backDatedStatus:getBackDatedStatus(),
      snack: { ...snackService.snackParameters },
    };
    this.dataListLength = 0;
  }

  componentDidMount() {
    this.getData();
    this.getLedgerList();
    this.getLedgerListCashTrue();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }
  getLedgerList = () => {
    ledgerApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const responseDataList = response.data.list;
        responseDataList.map(ledger => {
          ledger.label = `${ledger.customerId}. ${ledger.title}`;
        });
        this.setState({ ledgerList: responseDataList });
      }
    });
  };
  getLedgerListCashTrue = () => {
    ledgerApi.getList({}, 'isCashBankLedger=true').then(response => {
      if (checkIfApiCallSuccess(response)) {
        const responseDataList = response.data.list;
        responseDataList.map(ledger => {
          ledger.label = `${ledger.customerId}. ${ledger.title}`;
        });
        this.setState({ ledgerListCash: responseDataList });
      }
    });
  };

  getData = () => {
    const { match,getDetails } = this.props;
    const { preparedBy ,summary} = this.state;
    const voucher_number = voucherDecoder(match.params.id);
    getDetails({ voucher_number, user_id: match.params.userId || '' }).then(response => {
        const { journalVoucherDetail, journalVoucher } = response;
        const formattedList = flattenDataPrintAttributeWise(journalVoucherDetail, config.lengthyAttributes, config.getData);
        const {
          narration,
          drAmount,
          crAmount,
          date = journalVoucher.transactionDate,
          mitiTitle,
          voucherNumber,
          documentDate,
          idJournalVoucher,
        } = journalVoucher;
        const data = {
          list: formattedList,
          summary: {
            narration,
            drAmount,
            crAmount,
            date,
            mitiTitle,
            voucherNumber,
            idJournalVoucher,
            totalInWords: numberToWords(drAmount || 0),
          },
        };
        const backUpData = clone(formattedList);
        const userDetail = config.userDetailMapper(response.data);
        const documentDateUpdated =filterUtil.formatToDateMonthYear(documentDate);
        summary.mainNarration = narration;
        summary.documentDate = documentDateUpdated;
        preparedBy.value=response?.journalVoucher?.user?.name;
        this.setState({ data,dataBackup:clone(backUpData), userDetail, preparedBy,summary ,summaryBackUp:clone({...summary})});
    });
  };

  printInvoice = () => {
    const self = this;
    const { match } = this.props;
    self.setState({ print: true }, () => {
      window.print();
    });

    window.onafterprint = function () {
      if (match.params.userId) {
        window.close();
      } else {
        history.push(`/${CONTRA_ENTRY_BASE}`);
      }
    };
  };
  handleCEClose = (items) => {
  const{dataBackup,data,idJournalVoucherDetail} =this.state;
   const dataList= clone(dataBackup).find(a=>a.idJournalVoucherDetail ===idJournalVoucherDetail);
    let dataIndex = data?.list.findIndex((obj => obj.idJournalVoucherDetail == idJournalVoucherDetail));
    data?.list?.splice(dataIndex, 1, dataList);
    this.resetCEDialog();
    this.setState({data},()=>{
      this.getChangedDataList();
    });
  };
  handleUpdate = () => {
    this.setState({readModalOpen:true});
    }

  btnWrapper = () => {
    const { showUpdateBtn,updateBtnSummary} = this.state;
    return(
    <>
      <Button accent className="cancel-btn modal-btn" onClick={() => history.push(`/${CONTRA_ENTRY_BASE}`)}>
        Cancel
      </Button>
      { (updateBtnSummary || showUpdateBtn) || 
      <Button accent className="margin-right-0 save-btn modal-btn" onClick={() => this.handlePrintClick()}>
        Print
      </Button>
  }
      {  (updateBtnSummary || showUpdateBtn) && 
      <Button
        accent
        className="margin-right-0 save-btn modal-btn"
        onClick={() => {
          this.handleUpdate();
        }}
      >
        Update
      </Button>
  }
    </>
    )
};
summaryEdit = () => {
  const { editInput} = this.state;
  return(
  <>
       <div className="edit-cancel print_display_none">
        <Icon name='edit' className ={`${editInput && 'active'}`} onClick={() =>{this.handleSummary()}} />
        <Icon name='close' onClick={() =>{ 
          this.handleResetSummary();
          }}/>
        </div>
  </>
  )
};
  groupPrintDataSetNew() {
    const { data} = this.state;
    const { company  } = this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(data.list);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };
  handlePrintClick = () => {
    const self = this;
    this.groupPrintDataSetNew();
    this.setState({ print: true }, () => {
      window.print();
    });
    window.onafterprint = function () {
      self.setState({ print: false });
    };
  };
  handleDateChange = async(field, value) => {
    const {summary } = this.state
      summary.documentDate =filterUtil.formatToDateMonthYear(value);
      this.setState({  summary},()=>this.handleSummary());
  };
  checkBalance =() =>{
    const {data,summary} =this.state;
    const totalCrAmount = data.list.reduce((a, b) => ({crAmount: a.crAmount + b.crAmount}));
    const totalDrAmount = data.list.reduce((a, b) => ({drAmount: a.drAmount + b.drAmount}));
    const compare =( totalCrAmount.crAmount === totalDrAmount.drAmount) ? true : false;
    summary.totalCrAmount =totalCrAmount.crAmount;
    data.summary.drAmount=totalCrAmount.crAmount;
    summary.totalDrAmount =totalDrAmount.drAmount;
    data.summary.crAmount=totalDrAmount.drAmount;
    this.setState({summary,data});
    return compare;
  }
  onChange = async(field, value, all = {}) => {
    const { data,summary,createCE} = this.state;
    if(field ==="mainNarration"){
      summary[field] = value;
      data.summary.narration = summary.mainNarration;
      }
      else{
        createCE[field] = value;
      }
    this.setState({data,summary,createCE},()=>{
      this.handleSummary();
    });
  };
  handleEditIconClick = list => {   
    this.setState({
      createCE:config.formMapper({...list}),
      editModalOpen:true,
      idJournalVoucherDetail:list?.idJournalVoucherDetail,
    })
  };
  onModalSubmit =() =>{
    const { createCE ,data,validation,idJournalVoucherDetail,ledgerList} = this.state;
      const formValid = validateForm(createCE, validation, valid => this.setState({ validation: valid }));
      if (formValid) {
        const objIndex = data.list.findIndex((obj => obj.idJournalVoucherDetail === idJournalVoucherDetail));
          data.list[objIndex].drAmount = createCE.drAmount;
          data.list[objIndex].crAmount = createCE.crAmount;
          data.list[objIndex].narration = createCE.narration;
          data.list[objIndex].customer.customerId = createCE?.customerId;
          data.list[objIndex].customerTitle =ledgerList.filter((a)=>a?.customerId === createCE.customerId)[0]?.title;
        this.setState({data},()=>{
          this.getChangedDataList();
          this.checkBalance();
        })
        this.resetCEDialog();
        this.resetBtn();
      } else {
      this.setState({data})
    }
  }
  getConfirmation = () =>{
      this.getUpdateApi();
  }
  getUpdateApi = () => {
    const {data,summary} =this.state;
    const {update,serverResponseWaiting} =this.props;
    const checkBalanceStatus =this.checkBalance(); 
    const apiTransformedData = config.updateApiTransformedData(data?.list,summary);
    const customerIds =data.list.map(({customer})=>customer?.customerId);
    const duplicateLedgersCheck =(customerIds.length !== new Set(customerIds).size) ? false :true;   
    if(checkBalanceStatus && duplicateLedgersCheck ){
      update({
        id: data.summary.idJournalVoucher,
        query: apiTransformedData,
      })
        .then(response => {
          const snack = snackService.generateUpdateMessage();
          this.setState({ snack });
          this.resetCEData();
        })
        .catch(err => {
          const snack = snackService.generateFailureMessage(err?.error?.message || 'Error while updating Contra Entry Details');
          this.setState({ snack });
          this.resetCEData();
          this.resetBtn();
        });
    }
    else{
      const errorMsg = !duplicateLedgersCheck ? 'Duplicate Ledgers': "Debit Credit Not Balanced";
      const snack = snackService.generateFailureMessage(errorMsg);
      this.setState({ snack });
      this.getData();
    }
    this.resetCEDialog();
    this.resetBtn();
  }
  // for dataList only 
  getChangedDataList =() =>{
    const {data,dataBackup}= this.state;
    const filteredData = data.list.filter(this.compareDataList(clone(dataBackup)));
    this.setState({showUpdateBtn: !!filteredData.length})
    return filteredData;
  }
  compareDataList =(otherArray) =>{
    return function(c){
      return otherArray.filter(function(o){
        return o.drAmount === c?.drAmount && o?.crAmount === c?.crAmount && o?.narration === c?.narration  &&  o?.customer?.customerId === c?.customer?.customerId}).length === 0;
    }
  }
  // for summary only
  compareSummary =(otherArray) =>{
    return function(c){
      return otherArray.filter(function(o){
        return o.mainNarration === c?.mainNarration && o?.documentDate === c?.documentDate}).length === 0;
    }
  }
  handleSummary =() =>{
   const {summaryBackUp,summary}= this.state;
    const filteredData = [summary].filter(this.compareSummary([summaryBackUp]));
    this.setState({editInput:true,updateBtnSummary: !!filteredData.length})
  }
  handleResetSummary =async() =>{
    const {summaryBackUp} =this.state;
  await this.onChange('mainNarration',summaryBackUp.mainNarration)
  await this.handleDateChange('',summaryBackUp.documentDate);
    this.setState({editInput:false})
  }
    // for summary only end

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };
  handleModalClose =( ) =>{
    this.resetCEDialog();
  }
  resetCEDialog = () => {
    this.setState({ editModalOpen: false,readModalOpen: false,formEmptyField: false});
  }
  resetBtn =()=>{
    this.setState({showUpdateBtn:false,editInput:false,updateBtnSummary:false});
  }
  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  resetCEData = () => {
    this.getData();
  };

  render() {
    const { serverResponseWaiting, company } = this.props;
    const { data, print, dataMiscellaneousList, dataList, preparedBy ,editInput,summary,readModalOpen,editModalOpen,validation,createCE,ledgerList,snack,ledgerListCash,backDatedStatus} = this.state;
    return (
      <ContraEntryStyled className={`contra-entry-details ${serverResponseWaiting ? 'clickable-false' : ''}`}>
        <div className={print ? 'no-print' : 'display-block'}>
        <PageButtonWrapper renderBtn={this.summaryEdit} />
          <DetailView
            display={{
              header: true,
              footer: true,
              summary: true,
            }}
            serverResponseWaiting={serverResponseWaiting}
            headerConfig={{
              company,
              title: config.title,
              miti: data.summary.mitiTitle,
              date: moment(summary.documentDate || new Date()).format('DD MMM YYYY'),
            }}
            pageConfig={config}
            data={data}
            editInput ={editInput}
            handleDateChange={this.handleDateChange}
            handleInputChange ={this.onChange}
            documentDate={summary.documentDate}
            mainNarration={summary.mainNarration}
            onTableBodyClick={this.handleEditIconClick}
            ledgerList={ledgerList}
            backDatedStatus={backDatedStatus}
          />
           { editModalOpen && 
                <CEDialog
                onConfirmModalClose={this.handleCEClose}
                onModalSubmit={this.onModalSubmit}
                editModalOpen={editModalOpen}
                handleInputChange={this.onChange}
                formEmptyField={validation.flag}
                createCE={createCE}
                ledgerList={ledgerList}
                ledgerListCash={ledgerListCash}
                />
          }
          { readModalOpen && 
                <ConfirmationDialog
                summary ={summary}
                onConfirmModalClose={this.handleModalClose}
                getConfirmation={this.getConfirmation}
                readModalOpen ={readModalOpen}
                createCE={createCE}

                />
          }
          <PageButtonWrapper renderBtn={this.btnWrapper} />
        </div>
        <div className={print ? 'display-block portrait-type ce' : 'no-print'}>
          {dataList.map((ceItem, key) => (
            <div className="print-body zoom-reduce voucher-print">
              <div className="jv card-body">
                <Header
                  company={company}
                  divClassName={key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'}
                  date={moment(summary.documentDate || new Date()).format('DD MMM YYYY')}
                  miti={data.summary.mitiTitle}
                  pageTitle={<h2>{config.title}</h2>}
                  dataMiscellaneousList={dataMiscellaneousList}
                />
                <div
                  className={`overflow-scrollable fixed-table-wrapper ${
                    !ceItem.footer ? 'fixed-table-height ' : 'payment-total-footer'
                  }`}
                >
                  <table className="voucher-table">
                    <TableHeader headerDetails={config.header} filterHeaderLabel={false} />

                    {config.getTableBody({ dataList: ceItem.list,remainingLines:ceItem.remainingLines,dataSummary:data.summary,footer:ceItem.footer })}
                  </table>
                </div>
                {ceItem.footer && (
                  <table className="left-footer print-active total-section-wrapper footer clearfix">
                    <>
                      <VoucherFooter
                        preparedBy={preparedBy}
                        totalInWords={data.summary.totalInWords}
                        signatureList={config.signatureTitleList}
                        lineMargin="padding-r-24"
                        remarksStatus
                        remarks={data.summary.narration}
                      />
                    </>
                  </table>
                )}
              </div>
              <div className="footer-block">
                <PageNumber value={ceItem.page} totalPage={dataList.length} />
              </div>
            </div>
          ))}
        </div>
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </ContraEntryStyled>
    );
  }
}

ContraEntryDetail.propTypes = propTypes;
ContraEntryDetail.defaultProps = defaultProps;

const ContraEntryDetailWithState = withBaseState(ContraEntryDetail);

export default ContraEntryDetailWithState;
