import React, { Fragment } from 'react';
import { STOCK_TYPE } from '../../../data/enums/enums';
import { formatToReadableDate } from '../../../utils/filterUtil';

const StockLedgerBody = ({ dataList, remainingLines }) => {
  return (
    <tbody>
      {dataList.map((detail, index) => (
        <>
          <tr>
            <td>{detail.index ? detail.index + 1 : index + 1}</td>
            <td className='left-align bu'>{detail.buTitle}</td>
            <td className='left-align sku'>{detail.skuTitle}</td>
            <td className='left-align batch'>{detail.batchTitle}</td>
            <td className='left-align type'>
              {detail.type === STOCK_TYPE.INWARD ? 'Inward' : 'Outward'}
            </td>
            <td className='right-align normal '>{detail.normal}</td>
            <td className='right-align damage '>{detail.damage}</td>
            <td className='right-align expiry '>{detail.expiry}</td>
            <td className='right-align quantity'>{detail.quantity}</td>
            <td className='right-align quantity'>{detail.shortage}</td>
            <td className='left-align remark'>{detail.remarks}</td>
            <td className='left-align date'>{formatToReadableDate(detail.date)}</td>
          </tr>
        </>
      ))}
      {remainingLines && remainingLines.length
        ? remainingLines.map((i) => (
          <tr className='blank-tr'>
            <td></td>
            <td colSpan ='11' className=''></td>
          </tr>
        ))
        : null}
    </tbody>
  );
};

const stockLedgerConfig = [
  {
    id: 1,
    label: '',
    title: 'S.N',
    className: ' id',
    sortable: false,
  },
  {
    id: 6,
    label: 'business_company.business_alias bu',
    title: 'BU',
    className: '',
    sortable: false,
  },
  {
    id: 2,
    label: 'skus.title',
    title: 'SKU',
    className: ' sku',
    sortable: false,
  },
  {
    id: 3,
    label: 'sku_batch_price.title',
    title: 'Batch',
    className: ' batch',
    sortable: false,
  },
  {
    id: 4,
    label: 'type',
    title: 'Status',
    className: ' type',
    sortable: false,
  },
  {
    id: 5,
    label: 'normal',
    title: 'Sellable (Pcs)',
    className: ' normal right-align',
    sortable: false,
  },
  {
    id: 6,
    label: 'damage',
    title: 'Damage (Pcs)',
    className: ' damage right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'expiry',
    title: 'Expiry (pcs)',
    className: ' expiry right-align',
    sortable: false,
  },
  {
    id: 8,
    label: 'quantity',
    title: 'Quantity (Pcs)',
    className: ' quantity right-align',
    sortable: false,
  },
  {
    id: 9,
    label: 'shortage',
    title: 'Shortage (Pcs)',
    className: ' quantity right-align',
    sortable: false,
  },
  {
    id: 10,
    label: 'remarks',
    title: 'Remarks',
    className: ' remark',
    sortable: false,
  },
  {
    id: 11,
    label: 'date',
    title: 'Date',
    className: '',
    sortable: false,
  },
];
const lengthyAttributes = ['skuTitle', 'remarks','buTitle','batchTitle'];
const printEnums = {
  skuTitle: 43,
  remarks: 18,
  buTitle: 18,
  batchTitle: 18,
  withoutFooterLimit: 38,
  withFooterLimit: 38,
  lengthyAttributes
};

const config = {
  header: stockLedgerConfig,
  getTableBody: StockLedgerBody,
  printEnums,
  lengthyAttributes,
  uniqueIdGetter: (i) => i.idStockJournalDetail,
};

export default config;
