import styled from 'styled-components';

const CustomStyle = styled.div`
.dropdown{
   position: absolute;
  top: 32px;
  right: 10px;
  opacity: 0.6;
}
  .filter-dropdown-card {
    left: 0 !important;
  }
    .auto-complete-pop-up {
    .filter-dropdown-card {
      min-width: 40px;
      padding: 10px;
      top: 115%;
      left: 0 !important;
      box-shadow: none;
      border: none;

      .autocomplete-list {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
        padding: 8px 0;
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(231, 231, 237);
        border-image: initial;
      }
    }
  }
`;
const LabelStyled = styled.label`
    display: inline-block;
    margin-bottom: 4px;
    color: #272833;
    font-size: 14px;
    font-weight: 600;
`;


export { CustomStyle, LabelStyled };
