// import AgeingReport from './AgeingReportCopy';

// export default AgeingReport;

import { compose } from 'recompose';

import AgeingReport from './AgeingReport';

import apiInterceptor from '../../../api/interceptor';

import { ageingReportApi } from '../../common/base.api';

const composedAgeingReport = compose(apiInterceptor({ ...ageingReportApi }))(AgeingReport);

export default composedAgeingReport;
