import { connect } from 'react-redux';
import React, { Component } from 'react';
import tableConfig from './config';
import OpeningBalanceStyled from './OpeningBlncStyled';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { OPENING_BALANCE_CONFIG } from '../../common/domain.config';
import { ACTION, STATUS } from '../../../data/enums/enums';
import { BillingSnackBar ,Button} from '../../../components/BillingMDC';
import Dialog from './Dialog';
import { any } from 'prop-types';
import { clone } from '../../../utils/arrayProcessor';
import * as downloadService from '../../../views/common/download.service';
import { debouncer } from '../../../utils/handlers';

class StockLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      dialogType: ACTION.NULL,

      validation: {
        flag: false,
        fieldList: tableConfig.batchFormValidationFields,
      },
      buList: [],
      businessId: '',
      file: null,
      activeIndex:0,

      snack: { ...snackService.snackParameters },
      queryParameters: {
        search: queryService.queryParameters.search,
        sort: queryService.queryParameters.sort,
        pagination: queryService.queryParameters.pagination,
        filter: {
          period_id: [],
        },
      },
      data: {
        list: [],
        total: 0,
      },
      openingBlncDetail: [],
      dataList:[],
    };

    const { downloadList, sampledownloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }
  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({ dialogType: ACTION.NULL, validation });
  };
  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    this.loadBUList();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    getList(queryParameters)
      .then(response => {
        const { data } = this.state;
        data.list = response.list;
        data.total = response.total;
        this.setState({ data});
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleUploadIconClick = () => {
    this.setState({ dialogType: ACTION.UPLOADOB });
  };
  handleFileUpload = event => {
    event.preventDefault();
    let { file } = this.state;
    file = event.target.files[0];

    this.setState({ file });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleRowClick = (data,type,index) => {
    if(type === ACTION.UPDATE ){
      this.setState({ dialogType: ACTION.UPDATE ,activeIndex:index});
    }
    else{
    this.setState({
      openingBlncDetail: data,
    });
  }
  };
  getUpdate = (dataList) => {
    const { update } = this.props;
    update(dataList).then(response => {
      const snack = snackService.generateSuccessMessage('Cost Per Unit is successfully updated.');
      this.setState({ snack,dataList:[]},()=>{
        this.onCancel();
      });
    })
    .catch(err => {
      const snack = snackService.generateFailureMessage('Error while updating Cost per unit.');
      this.setState({ snack});
    });
  };
  onSave= () => {
  const filteredList =this.getUniqueDatalList();
  this.getUpdate(filteredList);
  }
  onCancel = () => {
    this.setState({ dialogType: ACTION.NULL,businessId:'',file:null});
    this.loadTableData();
  }

  loadBUList = () => {
    const { getBUList } = this.props;
    const { buList } = this.state;
    getBUList().then(response => {
      const filteredBUList = response.list.filter(list => list.status === STATUS.ACTIVE);
      this.setState({ buList: filteredBUList });
    });
  };
  getUniqueDatalList = () => {
    const {dataList} =this.state;
    const uniqueList = [
      ...new Map(dataList.map((item) => [item["id"], item])).values(),
  ];
  return uniqueList;
  }
  getDataList = (subData) => {
    const {dataList} =this.state;
    const {quantity,batch,...filteredData} =subData;
    dataList.push(filteredData);
    this.setState({dataList});
  }

  handleInputChange = (field, value,index,i,subData) => {
    const {data} =this.state;
    if(field ==="perUnitRate"){
    data.list[index].batches[i].per_unit_rate = value;
    subData.per_unit_rate = value;
    }
  
    this.setState({ businessId: value ,data},()=>{
      if (field === 'perUnitRate') {
        debouncer(this.getDataList, 1000)(subData);
      }
    });
  };

  handleModalSubmit = () => {
    const { dialogType } = this.state;
    this.handleModalClose();
    switch (dialogType) {
      case ACTION.UPLOADOB:
        this.uploadSKU();
        break;
      default:
        break;
    }
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  handleClick = businessId => {
    const { downloadList, sampledownloadList } = this.props;
    sampledownloadList({
      type: 'csv',
      query: { businessid: businessId },
    }).then(response => downloadService.resolver(response));
  };

  uploadSKU = () => {
    const { file } = this.state;
    const formData = new FormData();
    formData.append('file', file);

    const { upload } = this.props;
    upload(formData)
      .then(response => {
        const totalLength = response.length;
        const snack = snackService.generateSuccessMessage(`${totalLength} Entry Created`);
        this.setState({ snack }, () =>this.onCancel());
        
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({ snack });
      });
  };
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      search: queryService.queryParameters.search,
      sort: queryService.queryParameters.sort,
      pagination: queryService.queryParameters.pagination,
      filter: {
        period_id: [],
      },
    });
  }

  render() {
    const {
      queryParameters,
      data,
      display,
      openingBlncDetail,
      snack,
      dialogType,
      validation,
      buList,
      businessId,
      file,
      activeIndex,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <OpeningBalanceStyled>
        <div className="stock-ledger opening-blnc">
          <PageView
            domainConfig={OPENING_BALANCE_CONFIG}
            headerConfig={{
              search: true,
              date: false,
              upload: true,
              download: true,
              filter: true,
              create: false,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            resetFilter={this.handleResetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleUploadClick={this.handleUploadIconClick}
            handleDownloadClick={this.basePaginationService.handleDownloadClick}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            data={data}
            onTableClickData={openingBlncDetail}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.handleRowClick}
            onPageSelect={this.basePaginationService.onPageSelect}
            onInputChange={this.handleInputChange}
            dialogType={dialogType}
            activeIndex={activeIndex}
          />
          { dialogType !==  ACTION.UPDATE &&
          <Dialog
            buList={buList}
            businessId={businessId}
            validation={validation}
            actionType={dialogType}
            handleFileUpload={this.handleFileUpload}
            onModalClose={this.handleModalClose}
            onModalSubmit={this.handleModalSubmit}
            onInputChange={this.handleInputChange}
            onClick={this.handleClick}
            file={file}
          />
  }
          { dialogType ===  ACTION.UPDATE &&
            <div className="btns">
            <Button accent className='mdc-button dialog-cancel cancel-btn modal-btn'  onClick={() => this.onCancel()}>
              Cancel
            </Button>
            <Button
              accent
              className='mdc-button save-btn modal-btn'
              onClick={() => this.onSave()}
            >
              Save
            </Button>
          </div>
        }
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </div>
      </OpeningBalanceStyled>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeStockLedger: action => dispatch(action),
  };
}

const StockLedgerWithState = withBaseState(StockLedger);

export default connect(null, mapDispatchToProps)(StockLedgerWithState);
