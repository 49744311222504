const resolver = (response) => {
  const { name, path } = response.file;
  const newWin = window.open(`${window.origin}/${name}`);
  if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
    alert('Please enable pop for this site');
  }
};

const handleArchiveDownload = ({ reportType, queryParameters, isDateFilterChanged, downloadList }) => {
  const queryUpdate = {
    ...queryParameters,
    date: { ...queryParameters.date, start: '', end: '' },
  };
  reportType = reportType ? reportType : 'CSV';
  return downloadList({
    type: reportType,
    query: !isDateFilterChanged ? queryUpdate : queryParameters,
  }).then((response) => resolver(response));
};

export { resolver, handleArchiveDownload };

export default resolver;
