import { compose } from 'recompose';
import DayBookSummary from './DayBookSummary';
import apiInterceptor from '../../../api/interceptor';
import { dayBookSummaryApi } from '../../common/base.api';

const composedDayBookSummary = compose(
  apiInterceptor({ ...dayBookSummaryApi }),
)(DayBookSummary);

export default composedDayBookSummary;
