import { compose } from 'recompose';

import apiInterceptor from '../../../../api/interceptor';

import CreateAbbreviatedInvoice from './CreateAbbreviatedInvoice';
import {
  buApi, catalogsApi, ledgerApi, salesInvoiceApi
} from '../../../common/base.api';

const composedCreateAbbreviatedInvoice = compose(
  apiInterceptor({
    ...buApi,
    ...catalogsApi,
    create: { ...salesInvoiceApi },
    getCustomerList: { ledgerApi },
  }),
)(CreateAbbreviatedInvoice);

export default composedCreateAbbreviatedInvoice;
