import { trimEnd } from 'lodash';
import useLedgerDetailPdfContent from './useSimplePrintPdfContent';

const SimplePrint = ({ configData, printData, orientation, variation }) => {
  // these enums are not neccessary, I only used it to tell others what properties are available

  const orientationEnums = {
    portrait: true,
    landscape: true,
  };
  const variationEnums = {
    normal: true,
    multipleFooter: true,
  };

  const orientationValue = orientationEnums.hasOwnProperty(orientation) && orientationEnums[orientation] ? orientation : 'portrait';
  const variationValue = variationEnums.hasOwnProperty(variation) && variationEnums[variation] ? variation : 'normal';

  const pdfUri = useLedgerDetailPdfContent(configData, printData, orientationValue, variationValue);

  return <>{pdfUri && <embed  src={pdfUri} />}</>;
};
export default SimplePrint;
