import styled from 'styled-components';
const SalesOutstandingStyle = styled.div`
  .page_display_none {
    display: none;
  }
  .total-amount {
    color: rgba(0, 0, 0, 0.54);
    font-weight: 600;
    margin: 0;
    span {
      color: #2196f3;
      font-weight: 600;
      margin-left: 24px;
    }
  }
  .border-btm {
    td {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .sales-header {
    background: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px;
    margin-bottom: 16px;
    .select-css {
      width: 205px;
      .zindex-2__value-container {
        padding-left: 0;
      }
    }
  }
  .total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
  }
  .bold-font {
    font-weight: 500;
  }

  /* print style */
  .print-body {
    height: 100% !important;
    page-break-after: always;
  }
  .receipt_print {
    .card-body {
      .mdc-layout-grid__inner {
        padding-bottom: 24px;
      }
      .page_display_none {
        display: revert;
      }
      .print-sales-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 16px;
        border-top: 0.5px solid #868282;
      }
      .total-amount {
        color: rgba(0, 0, 0, 0.88);
      }
      .fixed-table-wrapper {
        height: 1370px !important;
        table {
          thead th {
            border-top: 1px solid #868282;
            border-bottom: 1px solid #868282;
          }
        }
        .border-btm {
          border-bottom: 0.5px solid #868282 !important;
          &:last-child {
            border-bottom: 0 !important;
          }
          td {
            padding-right: 0;
            font-weight: 600;
          }
        }
      }
    }
    .customer-name {
      width: 310px !important;
      min-width: 310px !important;
      max-width: 310px !important;
      white-space: normal !important;
    }
  }

  @media print{ 
    .sales-outstanding .print-body{
      padding:0 !important;
      margin:0.28in 0.3in !important;
    }
    .mdc-layout-grid__inner{
      display:inline-block;
      width:100%;
    }
      .mdc-layout-grid__cell{
         width:33.33%;
         display:inline-block;
         vertical-align: text-top;
         &:nth-child(2) h2{
         text-align:center;
         font-size:18px;
     }
     }
  }

  @media print and (max-width: 5.83in) {
    .sales-outstanding .print-body{
      padding:0 !important;
      margin:0.4in 0.45in !important;
    }
    .receipt_print {
      .card-body {
        .fixed-table-wrapper {
          height: 1360px !important;
        }
      }
    }
  }
`;
export default SalesOutstandingStyle;
