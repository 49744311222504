const defaultHeaderConfig = {
  search: false,
  date: true,
  upload: false,
  print: false,
  download: true,
  filter: true,
};

export { defaultHeaderConfig };
