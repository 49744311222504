import React, { Component } from 'react';
import Dialog from './Dialog';
import tableConfig from './config';
import { ACTION } from '../../../data/enums/enums';
import { validateForm } from '../../common/validation';
import PageView from '../../common/pagination/PageView';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import { BillingSnackBar } from '../../../components/BillingMDC';
import { ACCOUNT_GROUP_CONFIG } from '../../common/domain.config';

export class AccountGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: { ...queryService.queryParameters },
      dialogType: ACTION.NULL,
      data: {
        list: [],
        total: 0,
      },
      accountGroup: tableConfig.accountGroupMapper({}),
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
    };
    const { downloadList } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { accountGroupList } = this.props;
    const { queryParameters } = this.state;

    accountGroupList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total;
      this.setState({ data });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleEditIconClick = data => {
    // const { accountGroup } = this.state;
    this.setState({
      dialogType: ACTION.UPDATE,
      accountGroup: { ...tableConfig.accountGroupMapper(data) },
    });
  };

  handleFabButtonClick = () => {
    const { accountGroup } = this.state;
    this.setState({
      dialogType: ACTION.ADD,
      accountGroup,
    });
  };

  handleModalSubmit = () => {
    const { accountGroup, validation, dialogType } = this.state;
    const formValid = validateForm(accountGroup, validation, valid => this.setState({ validation: valid }));

    if (!formValid) return false;

    this.handleModalClose();

    switch (dialogType) {
      case ACTION.ADD:
        this.createAgent();
        break;
      case ACTION.UPDATE:
        this.editAgent();
        break;
      default:
        break;
    }
  };

  createAgent = () => {
    const { create } = this.props;
    const { accountGroup, data } = this.state;
    const { id, ...payload } = accountGroup;
    create(payload)
      .then(response => {
        this.loadTableData();
        const snack = snackService.generateSuccessMessage();
        this.setState({
          data,
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({
          snack,
        });
      });
  };

  editAgent = () => {
    const { update } = this.props;
    const { accountGroup } = this.state;
    const { id, ...query } = accountGroup;
    update({
      id: accountGroup.id,
      query,
    })
      .then(res => {
        this.loadTableData();
        const snack = snackService.generateUpdateMessage();
        this.setState({
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({
          snack,
        });
      });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.resetAccountGroup();
    this.setState({ dialogType: ACTION.NULL });
  };

  handleInputChange = (field, value) => {
    const { accountGroup } = this.state;
    accountGroup[field] = value;
    this.setState({ accountGroup });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  resetAccountGroup = () => {
    this.setState({ accountGroup: tableConfig.accountGroupMapper({}) });
  };

  render() {
    const { data, display, snack, dialogType, accountGroup, validation } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <div className="agents account-group">
        <PageView
          domainConfig={ACCOUNT_GROUP_CONFIG}
          headerConfig={{
            search: false,
            date: false,
            download: false,
            filter: false,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          data={data}
          tableConfig={tableConfig}
          onTableBodyClick={this.handleEditIconClick}
          createHandler={this.handleFabButtonClick}
          handleTableSorting={this.basePaginationService.handleTableSorting}
        />

        <Dialog
          actionType={dialogType}
          onModalSubmit={this.handleModalSubmit}
          onModalClose={this.handleModalClose}
          onInputChange={this.handleInputChange}
          validationFlag={validation.flag}
          accountGroup={accountGroup}
          accountGroupList={data.list}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

export default AccountGroup;
