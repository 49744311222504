import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { VALIDATION_TYPES } from '../../common/validation';
import { Icon } from '../../../components/BillingMDC';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { TRANSACTION_TYPE } from '../../../data/enums/enums';
import { formatToNumericDate } from '../../../utils/filterUtil';

const initializeCreateCE = () => ({
  ledger_name: '',
  ledger_id: '',
  type_id: '',
  dr_amount: 0,
  cr_amount: 0,
  ref_number: '',
  ref_date: new Date(),
  narration: '',
  entered_by: '',
  party_id: '',
  party_name: '',
  party_pan_no: '',
  party_taxable_amount: 0,
});

const apiTransformedCeList = (ceList, user, businessId) =>
  ceList.map((ceItem) => {
    delete ceItem.includePartyInfo;
    delete ceItem.ledger_name;
    delete ceItem.party_pan_no;
    delete ceItem.from_account;
    // delete ceItem.party_name;
    ceItem.business_id = businessId !== '' ? businessId : null;
    ceItem.entered_by = user.idUsers;
    ceItem.ref_date = formatToNumericDate(ceItem.ref_date);
    return ceItem;
  });

const formValidateFieldArray = [
  // { title: 'ledger_id' },
  { title: 'narration' },
  { title: 'amount', type: VALIDATION_TYPES.ZERO_ERROR },
  { title: 'to_account', type: VALIDATION_TYPES.ZERO_ERROR },
];
const pageValidateFieldArray = [{ title: 'narration' }];

const apiTransformedCeSummary = (ceSummary, user, businessId) => ({
  business_id: businessId !== '' ? businessId : null,
  entered_by: user.idUsers,
  transaction_type: TRANSACTION_TYPE.CONTRA_ENTRY,
  ...ceSummary,
  isContraEntry: true,
});

const CreateContraEntryTableBody = ({ dataList, partyIncluded, handleEditIconClick, handleDeleteIconClick }) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr>
        <td>{index + 1}</td>
        <td>{data.to_account}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.amount)}</td>
        <td className='left-align'>{data.ref_number}</td>
        <td className='left-align'>{moment(data.ref_date).format('ll')}</td>
        <td className='left-align'>{data.narration}</td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => handleEditIconClick(data, index)} />
          <Icon name='delete' onClick={() => handleDeleteIconClick(index)} />
        </td>
      </tr>
    ))}
  </tbody>
);

CreateContraEntryTableBody.defaultProps = { enableUpdate: true, };

CreateContraEntryTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteIconClick: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  enableUpdate: PropTypes.bool,
};

const createContraEntryConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'index',
      title: 'SN',
      className: null,
      sortable: false,
      display: true,
    },
    {
      id: 2,
      label: 'to_account',
      title: 'To Account',
      className: null,
      sortable: false,
      display: true,
    },
    {
      id: 3,
      label: 'amount',
      title: 'Amount',
      className: 'right-align',
      sortable: false,
      display: true,
    },

    {
      id: 5,
      label: 'ref_number',
      title: 'RefNo',
      className: 'left-align',
      sortable: false,
      display: true,
    },
    {
      id: 6,
      label: 'ref_date',
      title: 'RefDate',
      className: 'left-align',
      sortable: false,
      display: true,
    },
    {
      id: 7,
      label: 'narration',
      title: 'Narration',
      className: 'left-align',
      sortable: false,
      display: true,
    },
    {
      id: 11,
      label: 'action',
      title: '',
      className: 'right-align',
      sortable: false,
      display: true,
    },
  ],
};

const message = {
  success: 'Contra Entry created.',
  error: '!Error while creating Contra Entry',
  balanceError: '!Error Debit and Credit amount must be equal',
};

const partyHeaderList = ['party_name', 'party_pan_no', 'party_taxable_amount'];

export {
  initializeCreateCE,
  apiTransformedCeList,
  apiTransformedCeSummary,
  formValidateFieldArray,
  pageValidateFieldArray,
  partyHeaderList,
  message,
  createContraEntryConfig,
  CreateContraEntryTableBody,
};
