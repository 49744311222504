import orderBy from 'lodash/orderBy';
import * as httpUtils from '../api/client';
import { filterInitApi } from '../views/common/base.api';
import { filterListTransformer, filterThirdPartyBU } from '../views/common/DrawerFilter/config';
import { FilterItems } from '../views/common/DrawerFilter/filterItems';
import { setMetaData } from '../actions';
import { STATUS } from '../data/enums/enums';
import { getBUFilterList, getIntegratedBUList } from '../views/common/common';

const responseOrder = {
  brand: 0,
  bu: 1,
};

const loadFilterData = (dispatcher) => {
  const metaData = {};
  httpUtils
    .all([
      filterInitApi.getBrandList(),
      filterInitApi.getBuList(),
      filterInitApi.getFiscalYearList(),
      filterInitApi.getAgentList(),
      filterInitApi.getAccountGroupList(),
    ])
    .then((response) => {
      if (response && response[responseOrder.brand].success) {
        const transformedList = filterListTransformer(
          response[responseOrder.brand].data.list,
          FilterItems.catalog_detail_id.objMapper,
        );
        const uniqueList = [];
        transformedList.forEach((el) => {
          const idx = uniqueList.findIndex((element) => element.title === el.title);
          if (idx < 0) {
            uniqueList.push(el);
          }
        });
        metaData[FilterItems.catalog_detail_id.label] = orderBy(uniqueList, 'title');
      }

      if (response && response[responseOrder.bu].success) {
        const buList = response[responseOrder.bu].data.list;
        const activeBUList = buList.filter((bu) => bu.status === STATUS.ACTIVE);
        const thirdPartyBUlist = getBUFilterList(buList);
        const rosiaIntegratedBU = filterListTransformer(getIntegratedBUList(buList), FilterItems.business_id.objMapper);
        const transformedList = filterListTransformer(activeBUList, FilterItems.business_id.objMapper);
        const transformedThirdPartyBUList = filterThirdPartyBU(thirdPartyBUlist);
        const transformedBUList = [...rosiaIntegratedBU, ...transformedThirdPartyBUList];
        metaData[FilterItems.business_id.label] = orderBy(transformedList, 'title');
        metaData[FilterItems.business_id_thirdParty.label] = orderBy(transformedBUList, 'title');
      }

      if (response && response[2].success) {
        const transformedList = filterListTransformer(response[2].data.list, FilterItems.period_id.objMapper);
        metaData[FilterItems.period_id.label] = orderBy(transformedList, 'title');
      }

      if (response && response[3].success) {
        const transformedList = filterListTransformer(response[3].data.list, FilterItems.agent_ids.objMapper);
        const activeAgentList = transformedList?.filter(a=>a.status) || [];
        metaData[FilterItems.agent_ids.label] = orderBy(activeAgentList, 'name');
      }

      if (response && response[4].success) {
        const transformedList = filterListTransformer(response[4].data.list, FilterItems.account_groups.objMapper);
        metaData[FilterItems.account_groups.label] = orderBy(transformedList, 'title');
      }

      dispatcher(setMetaData(metaData));
    });
};

export { loadFilterData };
