import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogTitle,
  DialogFooter,
  Button,
  Grid,
  Cell,
} from '../../../components/BillingMDC';
import { LEDGERS } from '../../../data/enums/enums';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';

const DialogView = ({
  onModalClose,
  modalOpen,
  editModalOpen,
  deleteModalOpen,
  createCN,
  handleInputChange,
  onModalSubmit,
  formEmptyField,
  tagList,
  addTag,
  renderLedgerSelectOptions,
}) => {
  return(
    <Dialog
      open={modalOpen || editModalOpen || deleteModalOpen}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        {modalOpen && <DialogTitle>Create Credit Note</DialogTitle>}
        {editModalOpen && <DialogTitle>Edit Credit Note</DialogTitle>}
        {deleteModalOpen && <DialogTitle>Delete Credit Note</DialogTitle>}
      </DialogHeader>
      {!deleteModalOpen && (
        <DialogBody>
          <div className='dialog-upperpart'>
            <Grid className='grid-padding'>
            {renderLedgerSelectOptions()}

              <Cell col={6} tablet={6}>
                <BillingTextField
                  value={createCN.dr_amount}
                  handleChange={handleInputChange}
                  floatingLabel='Amount'
                  param='dr_amount'
                  type='number'
                  required={true}
                  zeroError={false}
                  className='billing-required right-side-gap'
                  emptyField={formEmptyField}
                />
              </Cell>
            </Grid>
            <Grid className='grid-padding'>
              <Cell col={6} tablet={8}>
                <label className='select-custom-label'>Tag</label>
                <BillingSelect
                  name='form-field-name'
                  param='tagid'
                  clearable
                  value={createCN.tagid}
                  valueKey='idTags'
                  labelKey='title'
                  options={tagList}
                  floatingLabel='Tag'
                  required={addTag && true}
                  handleChange={handleInputChange}
                  multipleParam
                  emptyField={addTag && formEmptyField}
                />
              </Cell>
            </Grid>

            <Grid className='grid-padding'>
              <Cell col={12} tablet={12}>
                <BillingTextField
                  value={createCN.narration}
                  param='narration'
                  floatingLabel='Narration'
                  className='textbox billing-required'
                  handleChange={handleInputChange}
                />
              </Cell>
            </Grid>
          </div>
        </DialogBody>
      )}

      {deleteModalOpen && (
        <div className='default-margin-24'>Are you sure you want to delete ?</div>
      )}

      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            onModalClose();
          }}
        >
        Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
        Ok
        </Button>
      </DialogFooter>
    </Dialog>
  )};

export default DialogView;
