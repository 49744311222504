import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import tableConfig from './config';
import * as queryService from '../../../common/query.service';
import PageView from '../../../common/pagination/PageView';
import withBaseState from '../../../common/withBaseState';
import * as filterUtil from '../../../../utils/filterUtil';
import { DOMAIN, REPORT_TYPE } from '../../../../data/enums/enums';
import * as downloadService from '../../../../views/common/download.service';

const propTypes = {
  stockDetails: PropTypes.shape({
    detail: {
      name: PropTypes.string,
      ledgerId: PropTypes.number,
      debit: PropTypes.number,
      credit: PropTypes.number,
      openingBalance: PropTypes.number,
      closingBalance: PropTypes.number,
    },
    date: {
      start: PropTypes.string,
      end: PropTypes.string,
    },
  }),
  getStockLedgerDetails: PropTypes.func.isRequired,
};

const defaultProps = {
  stockDetails: {
    detail: {
      name: '',
      ledgerId: null,
      debit: null,
      credit: null,
      openingBalance: null,
      closingBalance: null,
    },
    date: {
      start: filterUtil.getCurrentDay(),
      end: filterUtil.getCurrentDay(),
    },
  },
};

class StockLedgerDetail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        filter: false,
      },
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        ...queryService.queryParameters,
      },
    };
    const { downloadSkuDetail } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadSkuDetail,
    );
    this.basePaginationService.resetFilter();
  }

  static getDerivedStateFromProps(props, state) {
    const { stockDetails } = props;
    const { queryParameters } = state;
    const { date } = stockDetails;

    return {
      queryParameters: {
        ...queryParameters,
        date,
      },
    };
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { stockDetails, getDetails } = this.props;
    const { queryParameters } = this.state;
    const { row } = stockDetails;
    const extraQuery = `&sku_id=${row.skuId}&bu_id=${row.bu.id}`;
    getDetails({ query: queryParameters, extraQuery }).then(response => {
      const { data } = this.state;
      data.list = response;
      data.total = response.total ? response.total : response.length;
      this.setState({ data });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleDownloadClick = reportType => {
    const { downloadSkuDetail, stockDetails } = this.props;
    const { queryParameters } = this.state;
    const { row } = stockDetails;
    downloadSkuDetail({
      type: reportType,
      query: queryParameters,
      extraQueryString: `&sku_id=${row.skuId}&bu_id=${row.bu.id}`,
    }).then(response => downloadService.resolver(response));
  };

  render() {
    const { data, display, queryParameters } = this.state;

    const { serverResponseWaiting, stockDetails } = this.props;
    const { row } = stockDetails;
    return (
      <div className="stock-ledger ledger-report">
        <PageView
          domainConfig={{
            title: row.title,
            downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF],
            domain: DOMAIN.STOCK_LEDGER_DETAIL,
          }}
          headerConfig={{
            search: false,
            date: true,
            download: true,
            filter: true,
            create: false,
          }}
          footerConfig={{
            pagination: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.basePaginationService.resetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleDownloadClick={this.handleDownloadClick}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleTableBodyClick}
          onPageSelect={this.basePaginationService.onPageSelect}
        />
      </div>
    );
  }
}

StockLedgerDetail.propTypes = propTypes;

StockLedgerDetail.defaultProps = defaultProps;

const mapStateToProps = state => ({
  stockDetails: state.stockLedgerData || {},
});

const stockLedgerDetail = connect(mapStateToProps)(StockLedgerDetail);

const StockLedgerDetailWithState = withBaseState(stockLedgerDetail);

export default StockLedgerDetailWithState;
