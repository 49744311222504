import React from 'react';
import moment from 'moment/moment';
import Icon from 'react-mdc-web/lib/Icon/Icon';
import * as filterUtil from '../../../utils/filterUtil';
import { VALIDATION_TYPES } from '../../common/validation';
import { formatToReadableDate } from '../../../utils/filterUtil';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { paymentTypes } from '../../../data/enums/enums';

const getPayment = (data = {}) => {
  const payment = {
    document_date: filterUtil.formatToNumericDate(data.document_date) || filterUtil.getCurrentDay(),
    reference_date: filterUtil.formatToNumericDate(data.reference_date) || filterUtil.getCurrentDay(),
    reference_number: data?.reference_number || '',
    payment_type: data.payment_type || '',
    reconciled: false,
    to_ledger: data.to_ledger || '',
    tagid: data.tagid || '',
    id: data.id,
    amount: data.amount || 0,
    from_ledger: data.from_ledger || '',
    narration: data.narration || '',
    invoice_number: data.invoice_number,
    agent_id:data.agent_id ||  null,
  };
  return payment;
};

const labelMappings = {
  id: 'id',
  invoice_number: 'invoice_number',
  tagid: 'tagid',
  document_date: 'document_date',
  payment_date: 'payment_date',
  reference_number: 'reference_number',
  reference_date: 'reference_date',
  customer: 'customer',
  payment_type: 'payment_type',
  title: 'title',
  amount: 'amount',
  narration: 'narration',
  deleteIcon: 'deleteIcon',
  agent:'agent',
};

const paymentConfig = [
  {
    id: 2,
    label: 'document_date',
    title: 'Document Date',
    className: ' document-date',
    sortable:  false,
  },
  {
    id: 3,
    label: 'payment_date',
    title: 'Payment Date',
    className: ' payment-date',
    sortable:  false,
  },
  {
    id: 4,
    label: 'reference_number',
    title: 'Ref. Number',
    className: ' reference-num',
    sortable:  false,
  },
  {
    id: 11,
    label: 'reference_date',
    title: 'Ref. Date',
    className: ' reference-date',
    sortable:  false,
  },
  {
    id: 5,
    label: 'customer',
    title: 'Customer Ledger',
    className: ' customer',
    sortable:  false,
  },
  { id: 6, label: 'payment_type', title: 'Payment Type', className: 'payment-type' },
  {
    id: 7,
    label: 'tag_id',
    title: 'Tag',
    className: ' tag print_display_none',
    sortable:  false,
  },
  {
    id: 8,
    label: 'amount',
    title: 'Amount Received',
    className: 'right-align amount',
    sortable: false,
  },
  { id: 9, label: labelMappings.agent, title: 'Salesperson', className: 'agent print_display_none' },
  {
    id: 10,
    label: 'narration',
    title: 'Narration',
    className: ' narration',
    sortable: false,
  },
];
const getTableBody = (data, labelConfig, deleteHandler,tagList,agentList) => ({
  id: (
    <td key={`${labelConfig.id}-name`} className='left-align'>
      {data[labelConfig.label]}
    </td>
  ),
  invoice_number: (
    <td key={`${labelConfig}-invoice_number`} className='left-align invoice-num'>
      {data[labelConfig.label]}
    </td>
  ),
  document_date: (
    <td key={`${labelConfig}-document_date`} className='left-align document-date'>
      {formatToReadableDate(data.document_date)}
    </td>
  ),
  payment_date: (
    <td key={`${labelConfig}-payment_date`} className='left-align payment-date'>
      {formatToReadableDate(data.payment_date)}
    </td>
  ),
  reference_number: (
    <td key={`${labelConfig}-reference_number`} className='left-align reference-num'>
      {data[labelConfig.label]}
    </td>
  ),
  reference_date: (
    <td key={`${labelConfig}-reference_date`} className='left-align reference-date'>
      {moment(data[labelConfig.label]).format('ll')}
    </td>
  ),
  customer: (
    <td key={`${labelConfig}-customer`} className='left-align customer'>
      {data[labelConfig.label]}
    </td>
  ),
  payment_type: (
    <td key={`${labelConfig.id}-brand-coverage`} className='left-align payment-type'>
      {data.payment_type ? paymentTypes.filter((list) => list.id === data.payment_type)[0].title : ''}
    </td>
  ),
  tagid: (
    <td key={`${labelConfig.id}-tagid`} className='left-align print_display_none  payment-type'>
      {data?.tagid ? tagList?.filter((list) => list?.idTags === data?.tagid)[0]?.title : ''}
    </td>
  ),
  title: (
    <td key={`${labelConfig}-title`} className='left-align print_display_none tag'>
      {data[labelConfig.label]}
    </td>
  ),
  amount: (
    <td key={`${labelConfig}-amount`} className='right-align amount'>
      {fixedFloatAndCommas(data[labelConfig.label])}
    </td>
  ),
  agent: (
    <td key={`${labelConfig.id}-agent`} className='left-align agent print_display_none'>
      {agentList?.find((a) => a?.idAgent === data?.agent_id)?.name}
    </td>
  ),
  narration: (
    <td key={`${labelConfig}-narration`} className='left-align narration'>
      {data[labelConfig.label]}
    </td>
  ),
  deleteIcon: (
    <td className='right-align print_display_none' onClick={(e) => e.stopPropagation()}>
      <Icon name='delete' onClick={() => deleteHandler(data)} />
    </td>
  ),
});

const returnTableBody = (data, labelConfig = [], deleteHandler,tagList,agentList=[]) =>
  labelConfig.map((item) => {
    const tableRow = getTableBody(data, item, deleteHandler,tagList,agentList);

    return tableRow[item.label];
  });

const PaymentBody = ({ dataList, onRowClick, viewType, deleteHandler,remainingLines=[] ,tagList ,agentList=[] }) => {
  return (
    <tbody>
      {dataList.map((data, index,arr) => (

        <tr key={data.id} onClick={() => onRowClick(data, index)} className={` ${index === dataList.length - 1 && 'last-tr'}`}>
          {returnTableBody(data, tabBasedHeaderConfig[viewType], deleteHandler,tagList,agentList)}
        </tr>
      ))}
      {remainingLines && remainingLines.length
        ? remainingLines.map((i) => (
          <tr className='blank-tr'>
            <td></td>
            <td colSpan ='14' className=''></td>
          </tr>
        ))
        : null}
    </tbody>
  )
};

const tabBasedHeaderConfig = {
  [0]: [
    { id: 1, label: labelMappings.id, title: 'Id', className: 'id' },
    ...paymentConfig,
    { id: 11, label: labelMappings.deleteIcon, title: '', className: 'print_display_none' },
  ],
  [1]: [
    { id: 1, label: labelMappings.invoice_number, title: 'Voucher Number', className: 'invoice-num' },
    ...paymentConfig,
  ],
};
const PAYMENT_TABS = {
  PENDING: 'pending',
  RECONCILED: 'reconciled',
};
const tabMapper = {
  0: PAYMENT_TABS.PENDING,
  1: PAYMENT_TABS.RECONCILED,
};

const formValidationFieldList = [
  { title: 'narration' },
  { title: 'from_ledger' },
  { title: 'amount', type: VALIDATION_TYPES.ZERO_ERROR },
  { title: 'payment_type' },
  { title: 'to_ledger' },
];
const chequeValidationFieldList = [...formValidationFieldList, { title: 'reference_number' }];
const getApiTransformedData = (data) => {
  const transformedPayment = {
    amount: data.amount,
    from_ledger: data.from_ledger,
    document_date: filterUtil.formatToNumericDate(data?.document_date),
    narration: data.narration,
    reference_date: filterUtil.formatToNumericDate(data?.reference_date),
    reference_number: data?.reference_number,
    transaction_type: 1,
    payment_type: data.payment_type,
    to_ledger: data.to_ledger,
    tagid: data.tagid,
    invoice_number: data.invoice_number,
    reconciled: data.reconciled === false ? 0 : 1,
    period_id: data.period_id,
    agent_id :data?.agent_id || null,
  };
  return transformedPayment;
};
const getApiTransformedDataReconcile =(data) => {
  const transformedPaymentReconcile ={
    amount: data.amount || 0,
    from_ledger:data.from_ledger || 0,
    to_ledger:data.to_ledger || 0,
    narration:data.narration || '',
    tagid:data.tagid || 0,
    document_date:data.document_date || '',
    agent_id:data.agent_id || null,
  }
  return transformedPaymentReconcile ;
}

const updateApiTransformedData = (data) => {
  const transformedPayment = {
    ...getApiTransformedData(data),
  };

  return transformedPayment;
};
const updateApiTransformedDataReconcile = (data) => {
  const transformedPayment = {
    ...getApiTransformedDataReconcile(data),
  };
  return transformedPayment;
};
const lengthyAttributes = ['customer','narration'];
const printEnums = {
  withoutFooterLimit: 40,
  withFooterLimit: 40,
  customer: 25,
  narration: 25,
  lengthyAttributes,
};
const PAYMENT_TAB ={
  PENDING:0,
  RECONCILE:1,
}

const config = {
  tabBasedHeaderConfig,
  getTableBody: PaymentBody,
  getDetail: getPayment,
  getApiTransformedData,
  updateApiTransformedDataReconcile,
  formValidationFieldList,
  PAYMENT_TABS,
  tabMapper,
  updateApiTransformedData,
  chequeValidationFieldList,
  printEnums,
  PAYMENT_TAB,
  uniqueIdGetter: (i) => i.invoice_number
};

export default config;
