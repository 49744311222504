import React, { Component } from 'react';
import {
  Redirect, Router, Route, Switch
} from 'react-router-dom';
import { Login, Base } from './views';
import history from './utils/history';
import ScrollToTop from './views/common/ScrollToTop';
import { authenticateHandlers } from './utils/authUtils';
import ResetPassword from './views/login/resetPassword';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authenticateHandlers(props) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
const handleEnterKey = event => {
  if (event.keyCode === 13 ) {
    event.preventDefault();
  }
};
class App extends Component {
  componentDidMount() {
    document.addEventListener('keydown', handleEnterKey);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', handleEnterKey);
  }
  render() {
    return (
      <Router history={history}>
        <div className='router'>
          <div>
            <Router history={history}>
              <Switch>
                <Route exact path='/' render={() => <Redirect to='/login' />} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/reset-password/:token' component={ResetPassword} />
                <ScrollToTop>
                  <PrivateRoute path='/' component={Base} />
                </ScrollToTop>
                <Route exact path='`/new/:page`' component={Login} />
              </Switch>
            </Router>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
