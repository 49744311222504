import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SortingElement from '../SortingElement';
import { has } from '../../utils/hasOwnProperty';
import { SORTING, PRINT_INFO_DEFAULT } from '../../data/enums/enums';

const propTypes = {
  headerDetails: PropTypes.array,
  headerFieldToView: PropTypes.array,
  handleSorting: PropTypes.func,
  filterHeaderLabel: PropTypes.bool,
};
const defaultProps = {
  sortingLabel: SORTING.NO_SORT,
  headerDetails: [],
  filterHeaderLabel: true,
};

class TableHeader extends Component {
  state = {
    sorting: {
      label: '',
      order: '',
    },
  };

  componentDidMount() {
    const { sorting } = this.state;
    sorting.label = this.props.sortingLabel;
    this.setState({ sorting });
  }

  handleHeaderClick = labelName => {
    const { label: currentLabel, order } = this.state.sorting;
    const { handleSorting } = this.props;

    let newSortingOrder;

    if (currentLabel === labelName) {
      newSortingOrder = this.getNewSortingOrder(order);
    } else {
      newSortingOrder = SORTING.ASC;
    }

    this.setState({ sorting: { order: newSortingOrder, label: labelName } }, () =>
      handleSorting(labelName, newSortingOrder),
    );
    // ToDo :-> call the function to handle the sorting components from parent
  };

  getNewSortingOrder = currentOrder => {
    if (currentOrder === SORTING.NO_SORT) {
      return SORTING.ASC;
    }

    if (currentOrder === SORTING.ASC) {
      return SORTING.DESC;
    }

    return SORTING.NO_SORT;
  };

  renderHeader = headerListToRender => {
    const { sorting } = this.state;
    const headerList = [];
    /* eslint-disable no-unused-expressions */
    headerListToRender.map(header => {
      has.call(header, 'display')
        ? header.display
          ? headerList.push(this.headerItem(header, sorting))
          : null
        : headerList.push(this.headerItem(header, sorting));
    });
    return headerList;
  };

  headerItem = (header, sorting) => (
  
   !((header.label === 'excise' && !JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise)) && <>
      <th className={header.className} onClick={header.sortable ? () => this.handleHeaderClick(header.label) : null}>
        {header.title}
        {sorting.label === header.label && (
          <SortingElement labelName={header.label} sortingOrder={sorting.order} handleClick={this.handleHeaderClick} />
        )}
      </th>
    </>
  );

  render() {
    const { sorting, filterHeaderLabel } = this.state;
    const { headerDetails, headerFieldToView,upperHeaderExistTH } = this.props;
    let headerListToRender = [];
    if (filterHeaderLabel) {
      headerListToRender = headerDetails.filter(header => headerFieldToView.includes(header.label));
    } else {
      headerListToRender = headerDetails;
    }

    // todo filter the header Details according to Render Components.
    return (
      <thead> 
      {  upperHeaderExistTH ?<tr>{this.renderHeader(headerListToRender)}</tr> :
         <tr>{this.renderHeader(headerListToRender)}</tr>
          }
      </thead>
    );
  }
}

TableHeader.defaultProps = defaultProps;
/**
 * @type {{filterList: (*), filterTitle: (*), filterFields: (*), handleFilterChange: (*)}}
 * filterList -> complete Filter List Obtained.
 * filterTitle -> Title for the filter.
 * filterFields -> specifies the labelName for id value (e.g id_channel) and title (e.g id_title)
 */
TableHeader.propTypes = propTypes;

export default TableHeader;
