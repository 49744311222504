import React, { Component, Fragment } from 'react';
import Header from './Header';
import TableHeader from '../TableHeader/TableHeader';
import BillTotalContent from '../PrintComponent/BillTotalContent';
import PageNumber from '../PrintComponent/PageNumber';
import PropTypes from 'prop-types';

const propTypes = {
  billSummary: PropTypes.object.isRequired,
  dataMiscellaneousList: PropTypes.object.isRequired,
  billTotalList: PropTypes.object.isRequired,
  invoiceDetail: PropTypes.object.isRequired,
  date: PropTypes.string,
  miti: PropTypes.string,
  company: PropTypes.object,
  dataList: PropTypes.object.isRequired,
  dataListLength: PropTypes.number,
  config: PropTypes.object.isRequired,
  signatureTitle: PropTypes.string,
  printInfoBill: PropTypes.shape({ batchEnabled: PropTypes.bool, }),
  batchControl: PropTypes.bool,
};

const defaultProps = {
  date: ' ',
  miti: ' ',
  dataListLength: 0,
  company: {},
  signatureTitle: ' ',
  printInfoBill: { batchEnabled: false },
  batchControl: false,
};
const ReportView = ({
  billSummary,
  dataMiscellaneousList,
  billTotalList,
  invoiceDetail,
  date,
  miti,
  company,
  dataList,
  config,
  pageConfig,
  batchControl,
  paymentDetail,
  totalQuantity,
}) => {
  const printInfoBill = company.printInfo;
  return (
    <Fragment>
      {dataList.map((data, key) => (
        <div className='print-body zoom-less-reduce'>
          <div className='srn-card card-body'>
            <Header
              company={company}
              divClassName={key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'}
              date={date}
              miti={miti}
              pageTitle={<h2>{config.title.original}</h2>}
              dataMiscellaneousList={dataMiscellaneousList}
              paymentModeStatus={paymentDetail && paymentDetail.status}
              paymentMode={paymentDetail && paymentDetail.mode}
            />
            <div className={` fixed-table-wrapper ${!data.footer ? 'fixed-table-height ' : 'total-footer'}`}>
              <table
                className='batch_exist table data-table'
              >
                <TableHeader
                  headerDetails={batchControl ? pageConfig.header : config.header}
                  filterHeaderLabel={false}
                />
                {config.getTableBody({ dataList: data.list, printInfoBill,remainingLines:data.remainingLines })}
              </table>
            </div>
            {data.footer && (
              <table className='print-active total-section-wrapper footer clearfix'>
                <BillTotalContent
                  totalList={billTotalList}
                  totalValue={billSummary.totalValue}
                  invoiceDetail={invoiceDetail}
                  print={false}
                  printInfo={[]}
                  totalInWords={billSummary.totalInWords}
                  signatureTitle={config.signatureTitle}
                  lineMargin='padding-r-24'
                  remarksStatus
                  remarks={billSummary.remarks}
                  totalQuantity={totalQuantity}
                  company={company}
                />
              </table>
            )}
          </div>
          <div className='footer-block'>
            <PageNumber value={data.page} totalPage={dataList.length} />
          </div>
        </div>
      ))}
    </Fragment>
  );
};

ReportView.propTypes = propTypes;

ReportView.defaultProps = defaultProps;
export default ReportView;
