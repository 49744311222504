import {
  disbursementTypeList,
  promotionCriteriaDimension,
  promotionCriteriaOperation,
  promotionType,
} from '../../../../data/enums/enums';
import { getCurrentDate, getEndOfCurrentMonth } from '../../../../utils/filterUtil';

const formValidationFields = [
  { title: 'title' },
  { title: 'brandId' },
  { title: 'criteriaValue' },
  { title: 'disbursementValue' },
];

const promotionDetailsMapper = (data = {}) => ({
  title: data.title || '',
  description: data.desc || '',
  active: data.active || true,
  startDate: data.start_date || getCurrentDate(),
  endDate: data.end_date || getEndOfCurrentMonth(),
  businessId: data.scope && data.scope.length ? data.scope[0].buId : '',
  brandId: data.scope && data.scope.length ? data.scope[0].brandId : '',
  skuIds: data.scope && data.scope.length ? data.scope.map((el) => el.skuId) : [],
  criteriaType: data.criteria
    ? data.criteria[0].type
      ? data.criteria[0].type
      : promotionType[0].value
    : promotionType[0].value,
  criteriaCondition: data.criteria ? data.criteria[0].condition : promotionCriteriaDimension[0].value,
  operation: data.criteria ? data.criteria[0].criteria : promotionCriteriaOperation[0].value,
  criteriaValue: data.criteria ? data.criteria[0].value : '',
  disbursementType: data.disbursement ? data.disbursement[0].disbursementType : disbursementTypeList[0].value,
  disbursementValue: data.disbursement ? data.disbursement[0].value : '',
});

const getApiTransformedData = (data) => {
  const details = {
    title: data.title,
    desc: data.description,
    active: data.active,
    start_date: data.startDate,
    end_date: data.endDate,
    scope: [
      {
        bu_id: data.businessId,
        brand_id: data.brandId,
        sku_id: data.skuIds,
      },
    ],
    criteria: [
      {
        type: data.criteriaType,
        condition: data.criteriaCondition,
        criteria: data.operation,
        value: data.criteriaValue,
      },
    ],
    disbursement: [
      {
        type: data.disbursementType,
        value: data.disbursementValue,
      },
    ],
  };

  return details;
};

const message = {
  ADD: 'PROMOTION created successfully!',
  UPDATE: 'PROMOTION updated successfully!',
  SERVER_ERROR: '!Server Error',
  CREATE_ERROR: '!Error while creating PROMOTION',
  UPDATE_ERROR: '!Error while updating PROMOTION',
};

export {
  promotionDetailsMapper, formValidationFields, getApiTransformedData, message
};
