import React from 'react';
import { formatToReadableDate } from '../../../utils/filterUtil';
import { fixedFloatAndCommas, roundAndCommas } from '../../../utils/conversion';

const numberConfig = { maximumFractionDigits: 2 };

const netSalesReportBody = ({ dataList }) => (
  <tbody>
    {dataList.map((data) => (
      <tr className='cursor-pointer'>
        <td className='left-align'>{data.business_id}</td>
        <td className='left-align'>{data.business_alias}</td>
        <td className='left-align'>{data.sku_id}</td>
        <td className='left-align'>{data.sku_name}</td>
        <td className='left-align'>{data.brand}</td>
        <td className='right-align'>{data.sku_family}</td>
        <td className='right-align'>{data.sales_qty}</td>
        <td className='right-align'>{data.sales_amt}</td>
        <td className='right-align'>{data.rtn_qty}</td>
        <td className='left-align'>{data.rtn_amt}</td>
        <td className='left-align'>{data.net_qty}</td>
        <td className='left-align'>{data.net_amt}</td>
        <td className='left-align'>{data.account_group}</td>
        <td className='left-align'>{data.customer}</td>
        <td className='right-align'>{data.document_date}</td>
        <td className='right-align'>{data.voucher_number}</td>
        <td className='right-align'>{data.payment_mode}</td>
        <td className='right-align'>{data.sales_person}</td>
      
      </tr>
    ))}
  </tbody>
);

const netSalesReportConfig = [
  { id: 1, title: 'Business Code', label: 'business_id', className: 'cursor-pointer left-align', sortable: true },
  { id: 2, title: 'Business Unit', label: 'business_alias', className: 'cursor-pointer left-align', sortable: true },
  { id: 5, title: 'SKU Code', label: 'sku_id', className: 'cursor-pointer left-align', sortable: true },
  { id: 6, title: 'SKU Name', label: 'sku_name', className: 'cursor-pointer left-align', sortable: true },
  { id: 11, title: 'Brand', label: 'brand', className: 'cursor-pointer left-align', sortable: true },
  { id: 7, title: 'SKU Family', label: 'sku_family', className: 'cursor-pointer right-align', sortable: true },
  { id: 8, title: 'Sales [QTY]', label: 'sales_qty', className: 'cursor-pointer right-align', sortable: true },
  { id: 9, title: 'Sales Amount', label: 'sales_amt', className: 'cursor-pointer right-align', sortable: true },
  { id: 10, title: 'SRN [QTY]', label: 'rtn_qty', className: 'cursor-pointer right-align', sortable: true },
  { id: 12, title: 'SRN Amount', label: 'rtn_amt', className: 'cursor-pointer left-align', sortable: true },
  { id: 15, title: 'Net Sales [QTY]', label: 'net_qty', className: 'cursor-pointer left-align', sortable: true },
  { id: 16, title: 'Net Sales Amount', label: 'net_amt', className: 'cursor-pointer left-align', sortable: true },
  { id: 17, title: 'Account Group', label: 'account_group', className: 'cursor-pointer left-align', sortable: true },
  { id: 18, title: 'Customer', label: 'customer', className: 'cursor-pointer left-align', sortable: true },
  { id: 19, title: 'Document Date', label: 'document_date', className: 'cursor-pointer right-align', sortable: true },
  { id: 20, title: 'Voucher Number', label: 'voucher_number', className: 'cursor-pointer right-align', sortable: true },
  { id: 21, title: 'Payment Mode', label: 'payment_mode', className: 'cursor-pointer right-align', sortable: true },
  { id: 21, title: 'Sales Person', label: 'sales_person', className: 'cursor-pointer right-align', sortable: true },
];

const config = {
  header: netSalesReportConfig,
  getTableBody: netSalesReportBody,
};

export default config;
