import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';

import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import {
  BALANCE_TYPE, LEDGER_TYPE, ACTION
} from '../../../data/enums/enums';
import DynamicSampleFileDownload from '../../../components/DynamicSampleFileDownload';

const LedgerDialog = ({
  sku,
  actionType,
  onModalClose,
  onModalSubmit,
  onInputChange, // onChange

  handleFileUpload,
  fileRef,
  validation,
  buList,
  businessId,
  onClick,
  file,
  invalidPAN,
}) => (
  <Dialog
    open={actionType !== ACTION.NULL}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      <DialogTitle>{actionType}</DialogTitle>
    </DialogHeader>

    <DialogBody>
      <div className='dialog-upperpart'>
        {actionType === ACTION.UPLOAD && (
          <Grid className='grid-padding'>
            <Cell col={6} tablet={8}>
              <label>Business Unit</label>
              <BillingSelect
                name='form-field-name'
                param='businessId'
                clearable={false}
                value={sku ? sku.businessId : ''}
                valueKey='businessId'
                labelKey='businessAlias'
                options={buList}
                handleChange={onInputChange}
                required={true}
              />
            </Cell>
            <Cell col={6} table={8}>
              <input
                type='file'
                name='resume'
                className='input-file-upload'
                ref={(ref) => (fileRef = ref)}
                onChange={(e) => handleFileUpload(e)}
              />
            </Cell>
          </Grid>
        )}
        {/* {actionType !== ACTION.UPLOADOB && actionType !== ACTION.UPLOADOB && (
          <div>
            <Grid className='grid-padding'>
              <Cell col={5} tablet={8}>
                <BillingTextField
                  value={sku ? sku.title : ''}
                  handleChange={onInputChange}
                  floatingLabel='Name'
                  param='title'
                  required={true}
                  className='right-side-gap'
                  emptyField={validation.flag}
                />
              </Cell>
              <Cell col={3} tablet={8}>
                <BillingTextField
                  value={sku ? sku.panNo : ''}
                  handleChange={onInputChange}
                  floatingLabel='PAN Number'
                  param='panNo'
                  required={false}
                  errorMessage='It should contain 10 digits'
                  rule='isInt'
                  type='number'
                  emptyField={validation.flag}
                  invalidError={invalidPAN}
                />
              </Cell>
              <Cell col={4} tablet={8}>
                <BillingTextField
                  value={sku ? sku.address : ''}
                  handleChange={onInputChange}
                  floatingLabel='Address'
                  param='address'
                  required={false}
                  className='right-side-gap'
                />
              </Cell>
            </Grid>
            <Grid className='grid-padding'>
              <Cell col={3} tablet={8} className='input-select'>
                <label>Ledger Type</label>
                <BillingSelect
                  name='form-field-name'
                  param='typeId'
                  clearable={false}
                  value={sku ? sku.typeId : ''}
                  valueKey='value'
                  labelKey='title'
                  options={LEDGER_TYPE}
                  handleChange={onInputChange}
                  required={false}
                />
              </Cell>
              <Cell col={3} tablet={8} className='input-select'>
                <label>Opening Balance Type</label>
                <BillingSelect
                  name='form-field-name'
                  param='openingBalanceType'
                  clearable={false}
                  value={sku ? sku.openingBalanceType : ''}
                  valueKey='value'
                  labelKey='title'
                  options={BALANCE_TYPE}
                  handleChange={onInputChange}
                  required={false}
                  disabled={actionType === ACTION.UPDATE}
                />
              </Cell>
              <Cell col={3} tablet={8}>
                <BillingTextField
                  value={sku ? sku.openingBalance : ''}
                  handleChange={onInputChange}
                  floatingLabel='Opening Balance'
                  param='openingBalance'
                  required={false}
                  disabled={actionType === ACTION.UPDATE}
                  className='right-side-gap'
                />
              </Cell>
              <Cell col={3} tablet={8}>
                <BillingTextField
                  value={sku ? sku.city : ''}
                  handleChange={onInputChange}
                  floatingLabel='City'
                  param='city'
                  required={false}
                  className='right-side-gap'
                />
              </Cell>
            </Grid>
            <Grid className='grid-padding'>
              <Cell col={3} tablet={8}>
                <BillingTextField
                  type='number'
                  value={sku ? sku.phoneNumber : ''}
                  handleChange={onInputChange}
                  floatingLabel='Phone Number'
                  param='phoneNumber'
                  required={false}
                  className='right-side-gap'
                />
              </Cell>
              <Cell col={5} tablet={8}>
                <BillingTextField
                  value={sku ? sku.creditLimit : ''}
                  handleChange={onInputChange}
                  floatingLabel='Credit Limit'
                  param='creditLimit'
                  type='number'
                  required={false}
                />
              </Cell>
              <Cell col={5} tablet={8}>
                <BillingTextField
                  value={sku ? sku.creditDay : ''}
                  handleChange={onInputChange}
                  floatingLabel='Credit Day'
                  param='creditDay'
                  type='number'
                  rule='isInt'
                  required={false}
                />
              </Cell>
            </Grid>
          </div>
        )} */}
        {actionType === ACTION.UPLOADOB && (
          <Grid className='grid-padding'>
            <Cell col={6} tablet={8}>
              <label>Business Unit</label>
              <BillingSelect
                name='form-field-name'
                param='businessId'
                clearable={false}
                value={businessId}
                valueKey='businessId'
                labelKey='businessAlias'
                options={buList}
                handleChange={onInputChange}
                required={true}
              />
            </Cell>
            <Cell col={6} table={8}>
              <input
                type='file'
                name='resume'
                className='input-file-upload'
                ref={(ref) => (fileRef = ref)}
                onChange={(e) => handleFileUpload(e)}
              />
            </Cell>
          </Grid>
        )}
      </div>
      {actionType === ACTION.UPLOADOB && <DynamicSampleFileDownload businessId={businessId} handleclick={onClick} />}
    </DialogBody>
    <DialogFooter>
      <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
        Cancel
      </Button>
      <Button
        accent
        disabled={(actionType === ACTION.UPLOADOB && !file) || (actionType === ACTION.UPLOAD && !sku.file)}
        className='dialog-ok modal-btn'
        onClick={() => {
          onModalSubmit();
        }}
      >
        Save
      </Button>
    </DialogFooter>
  </Dialog>
);

export default LedgerDialog;
