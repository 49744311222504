import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const SalesMaterializedViewTableBody = ({ data }) => {
  return (
    <tr className='cursor-default'>
      <td className='left-align'>{data.fiscal_year}</td>
      <td className='left-align'>{data.bill_no}</td>
      <td className='left-align'>{data.customer_name}</td>
      <td className='left-align'>{data.customer_pan}</td>
      <td className='left-align'>{moment(data.bill_date).format('D MMM YYYY')}</td>
      <td className='right-align'>{data.amount ? data.amount.toLocaleString() : data.amount}</td>
      <td className='right-align'>{data.discount}</td>
      <td className='right-align'>
        {data.taxable_amount ? data.taxable_amount.toLocaleString() : data.taxable_amount}
      </td>
      <td className='right-align'>{data.tax_amount}</td>
      <td className='right-align'>{data.total_amount ? data.total_amount.toLocaleString() : data.total_amount}</td>
      <td className='center-align'>{data.sync_with_ird}</td>
      <td className='center-align'>{data.is_bill_printed}</td>
      <td className='center-align'>{data.is_bill_active}</td>
      <td className='left-align'>
        {data.printed_time ? moment(data.printed_time).format('D MMM YYYY') : data.printed_time}
      </td>
      <td className='left-align'>{data.entered_by}</td>
      <td className='left-align'>{data.printed_by}</td>
      <td className='center-align'>{data.is_realtime}</td>
    </tr>
  );
};

SalesMaterializedViewTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const salesMaterializedViewConfig = {
  headerDetails: [
    { id: 1, label: 'fiscal_year', title: 'Fiscal Year', className: 'cursor-pointer', sortable: true },
    { id: 2, label: 'bill_no', title: 'Bill No', className: 'cursor-pointer left-align', sortable: true },
    { id: 3, label: 'customer_name', title: 'Customer Name', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'customer_pan', title: 'Customer PAN No.', className: 'cursor-pointer', sortable: true },
    { id: 5, label: 'bill_date', title: 'Bill Date', className: 'cursor-pointer left-align', sortable: true },
    { id: 6, label: 'amount', title: 'Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'discount', title: 'Discount', className: 'cursor-pointer right-align', sortable: true },
    {
      id: 5,
      label: 'taxable_amount',
      title: 'Taxable Amount',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    { id: 5, label: 'tax_amount', title: 'Tax Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'total_amount', title: 'Total Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'sync_with_ird', title: 'Sync with IRD', className: 'cursor-pointer left-align', sortable: true },
    { id: 5, label: 'is_bill_printed', title: 'Bill Printed', className: 'cursor-pointer left-align', sortable: true },
    { id: 5, label: 'is_bill_active', title: 'Bill Active', className: 'cursor-pointer left-align', sortable: true },
    { id: 5, label: 'printed_time', title: 'Printed Time', className: 'cursor-pointer left-align', sortable: true },
    { id: 5, label: 'entered_by', title: 'Entered By', className: 'cursor-pointer left-align', sortable: true },
    { id: 5, label: 'printed_by', title: 'Printed By', className: 'cursor-pointer left-align', sortable: true },
    { id: 5, label: 'is_realtime', title: 'Real Time', className: 'cursor-pointer left-align', sortable: true },
  ],
};

export { salesMaterializedViewConfig, SalesMaterializedViewTableBody };
