import { compose } from 'recompose';

import JournalVoucher from './JournalVoucher';

import apiInterceptor from '../../api/interceptor';

import { jvApi } from '../common/base.api';

const composedJournalVoucher = compose(apiInterceptor({ ...jvApi }))(JournalVoucher);

export default composedJournalVoucher;
