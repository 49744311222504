// import StockAgeingReport from './stockAgeingReport';

// export default StockAgeingReport;

import { compose } from 'recompose';

import StockAgeingReport from './StockAgeingReport';
import apiInterceptor from '../../../api/interceptor';
import { stockAgeingReportApi } from '../../common/base.api';

const composedStockAgeingReport = compose(
  apiInterceptor({ ...stockAgeingReportApi }),
)(StockAgeingReport);

export default composedStockAgeingReport;
