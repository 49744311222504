import { fixedFloatAndCommas } from '../../../../utils/conversion';

const getConfigData = (footerData, branchEnabled = false) => {
  let columnDatas = [
    { content: 'SN' },
    { content: 'SKU' },
    { content: 'Qty', styles: { halign: 'right' } },
    { content: 'Unit' },
    { content: 'Rate', styles: { halign: 'right' } },
    { content: 'Discount', styles: { halign: 'right' } },
    { content: 'Net Amount', styles: { halign: 'right' } },
  ];
  branchEnabled && columnDatas.splice(2, 0, { content: 'Batch' });
  const columns = [columnDatas];

  // added empty string instead of empty array so that new footer box does
  // not form when footer is empty on print
  let footer = '';
  if (footerData?.billTotalList && footerData?.billTotalList.length > 0) {
    footer = [
      footerData?.billTotalList.map((data, index) => {
        if (index === 0) {
          let footerRow = [
            '',
            { content: 'Total Qty (Pcs)', styles: { halign: 'right' } },
            { content: fixedFloatAndCommas(footerData?.totalQuantity) ?? '0' },
            { content: data?.title ?? '', colSpan: 3, styles: { halign: 'right' } },
            { content: fixedFloatAndCommas(data?.value) ?? '0' },
          ];
          if (branchEnabled) {
            footerRow[1] = '';
            footerRow.splice(2, 0, { content: 'Total Qty (Pcs)', styles: { halign: 'right' } });
          }
          return footerRow;
        } else {
          let footerRow = [
            '',
            '',
            '',
            { content: data?.title ?? '', colSpan: 3, styles: { halign: 'right' } },
            { content: fixedFloatAndCommas(data?.value) ?? '0' },
          ];
          branchEnabled && footerRow.splice(2, 0, '');
          return footerRow;
        }
      }),
    ];
  }

  const columnStyles = {
    // 0: { cellWidth: 10 },
    // 1: { cellWidth: 52 },
    // 2: { cellWidth: 8 },
    3: { cellWidth: 14 },
    5: { cellWidth: 24 },
    6: { cellWidth: 24 },
  };

  if (branchEnabled) {
    columnStyles['2'] = { cellWidth: 23 };
    // columnStyles['3'] = { cellWidth: 11 };
    columnStyles['4'] = { cellWidth: 14 };
    columnStyles['5'] = { cellWidth: 'auto' };
    columnStyles['6'] = { cellWidth: 24 };
    columnStyles['7'] = { cellWidth: 24 };
  }

  const cellsToAlignRight = { 3: '3', 5: '5', 6: '6', 7: '7'};
  if (!branchEnabled) {
    cellsToAlignRight['2'] = 2;
    cellsToAlignRight['4'] = 4;
    delete cellsToAlignRight['3'];
  }
  return {
    columns,
    footer: footer.flat(),
    columnStyles,
    cellsToAlignRight,
  };
};

export default getConfigData;
