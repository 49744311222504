import React from 'react';
import { Icon, MiniAddFab } from '../../../../../components/BillingMDC';
import BillingSelect from '../../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../../components/TextField/BillingTextField';
import { EMPTY } from '../../../../../data/enums/errorMessage';

const View = ({ ...props }) => {
  const { uom_list, UOMList, handleInputChange, handleAddClick } = props;
  const selectedUOM = uom_list.map((uom) => uom.value);

  const getDropDownForUOMList = (value) => UOMList.filter((uom) => uom.value === value || !selectedUOM.includes(uom.value));
  return (
    <>
      {uom_list?.map((packing, index) => (
        <div className='form-multi-select' key={packing.id}>
          <BillingSelect
            name='form-field-name'
            param='value'
            clearable={false}
            value={packing.value||packing.short_name}
            valueKey='value'
            labelKey='label'
            options={getDropDownForUOMList(packing.value||packing.short_name)}
            multipleParam
            disabled={packing.value==='pcs'}
            required
            emptyField={packing.uomflag}
            handleChange={(param, value) => handleInputChange(param, value, index)}
          />
          {/* <div className='upc-wrap'> */}
          <BillingTextField
            value={packing.pcs||packing.unit_per_case}
            handleChange={(param, value) => handleInputChange(param, value, index)}
            param='pcs'
            disabled={packing.value==='pcs'}
            type='number'
            required
            emptyField={packing.flag}
            errorMessage={EMPTY}
          />
          {/* </div> */}
          <div className='config-icon'>
            {!packing.is_basic&&<div className='flex m-0'>
              <Icon
                disabled={packing.is_basic}
                name='close' className='cross_icon' onClick={() => handleAddClick('remove',index)} />
            </div>}
          </div>
        </div>
      ))}
      <MiniAddFab handleFabClick={() => handleAddClick('add')} />
    </>
  );
};

export default View;
