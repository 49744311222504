import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  apiTransformedDNSummary,
  createDebitNoteConfig,
  CreateDebitNoteTableBody,
  formValidateFieldArray,
  getApiTransformedData,
  initializeCreateDN,
  message,
  pageValidateFieldArray,
  enumVar,jvTypeList
} from './config';
import { Button, Cell, Fab, Grid, Icon, LinearProgress, Snackbar,Checkbox } from '../../../components/BillingMDC';
import DateRangePicker from '../../../components/DatePicker/DatePicker';
import DatePicker from '../../../components/DatePicker/DatePicker';
import * as filterUtil from '../../../utils/filterUtil';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import DebitNoteStyled from '../DebitNoteStyled';
import TableHeader from '../../../components/TableHeader';
import DialogView from './Dialog';
import { checkIfApiCallSuccess, debitNoteAPi, ledgerApi, tagApi } from '../../common/base.api';
import { validateForm } from '../../common/validation';
import { clone } from '../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { DEBIT_NOTE, DEBIT_NOTE_DETAILS } from '../../../data/enums/Route';
import history from '../../../utils/history';
import { voucherEncoder } from '../../../utils/miscellaneous';
import GRNStyled from '../../fundamentals/grn/GRNStyled';
import {LEDGERS, VAT_PERCENTAGE ,VAT_ZERO_PERCENTAGE,VAT_PER_DERIVE} from '../../../data/enums/enums';
import { JWT } from '../../../environment';
import { formatToNumericDate } from '../../../utils/filterUtil';
import { getBackDatedStatus ,getCustomerList} from '../../../views/fundamentals/common/helpers';
import CustomerSelect from '../../../common/CustomerSelect';

class CreateDebitNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexInDN: 0,
      loading: false,
      success: false,
      showMessage: false,
      saveDisable: false,
      createModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      voucherNumber: '',
      tagList: [],
      dnList: [],
      ledgerList: [],
      ledgerListPartial:[],
      partyLedgerList:[],
      partyLedgerListPartial:[],
      message: message.success,
      createDN: initializeCreateDN(),
      debitNoteSummary: {
        ledger_id: '',
        ledger_name: '',
        panNo: '',
        ref_invoice_number: '',
        narration: '',
        ref_date: filterUtil.getCurrentDay(),
        amount: '',
        vat: 13,
        vat_amount: '',
        net_amount: '',
        type:'',
        document_date: filterUtil.getCurrentDay(),
      },
      pageValidation: {
        flag: false,
        fieldList: pageValidateFieldArray,
      },
      formValidation: {
        flag: false,
        fieldList: formValidateFieldArray,
      },
      addTag: false,
      backDatedStatus:getBackDatedStatus(),
      vatStatus:true,
    };
  }

  componentDidMount = () => {
    this.getLedgerList();
    this.getTagList();
    this.setAddTagStatus();
  };

  setAddTagStatus() {
    const settings = localStorage.getItem(JWT.LOCAL_STORAGE.SETTINGS.NAME);
    if (settings) {
      let parsedSettings = JSON.parse(settings);
      if (parsedSettings) {
        this.setState({ addTag: !!parsedSettings.addTag });
      }
    }
  }

  directToMainPage = () => {
    const { showMessage, success, voucherNumber } = this.state;
    if (!showMessage && success) {
      const modifiedVoucherNumber = voucherEncoder(voucherNumber) || '';
      history.push(`/${DEBIT_NOTE_DETAILS}/${modifiedVoucherNumber}`);
    }
  };

  handleCancelClick = () => {
    history.push(`/${DEBIT_NOTE}`);
  };

  addPartyAndVatToDNList(dnList, user, debitNoteSummary) {
    const jvListItems = [
      ...dnList,
      {
        ledger_id: LEDGERS.VAT.ledger_id,
        dr_amount: 0,
        cr_amount: debitNoteSummary.vat_amount,
        narration: '',
        entered_by: user.idUsers,
        ref_date: formatToNumericDate(debitNoteSummary.ref_date),
        type_id: LEDGERS.VAT.type,
        party_taxable_amount: debitNoteSummary.amount,
        party_id: debitNoteSummary.ledger_id,
        party_name: debitNoteSummary.ledger_name,
        business_id: null,
      },
    ];
    jvListItems.push({
      ledger_name: debitNoteSummary.ledger_name,
      ledger_id: debitNoteSummary.ledger_id,
      cr_amount: 0,
      dr_amount: debitNoteSummary.net_amount,
      narration: debitNoteSummary.narration,
      entered_by: user.idUsers,
      ref_date: formatToNumericDate(debitNoteSummary.ref_date),
      ref_number: debitNoteSummary.ref_invoice_number,
      type_id: debitNoteSummary.type_id,
      party_taxable_amount: 0,
      party_id: '',
      party_name: '',
      business_id: null,
    });
    return jvListItems;
  }

  handleSaveClick = () => {
    // generate the object to send to the server
    const { dnList, debitNoteSummary, pageValidation } = this.state;
    const { user } = this.props;

    let valid = validateForm(debitNoteSummary, pageValidation, validStatus =>
      this.setState({ pageValidation: validStatus }),
    );
    const dnListCheck = dnList.length > 0;

    if (valid && dnListCheck) {
      this.setState({ loading: true });
      const summaryList = apiTransformedDNSummary(debitNoteSummary, user);

      const updatedDNList = this.addPartyAndVatToDNList(dnList, user, debitNoteSummary);

      const filteredJvList = getApiTransformedData(clone(updatedDNList), user);

      const object = {
        jv: {
          summary: summaryList,
          detail: filteredJvList,
        },
      };

      this.setState({ saveDisable: true });

      debitNoteAPi
        .create(object)
        .then(response => {
          this.setState({ loading: false });
          if (response.success) {
            const { voucher_number = '' } = response.data.journalVoucherData;
            this.setState({
              showMessage: true,
              success: true,
              message: message.success,
              voucherNumber: voucher_number,
              partyHeaderIncluded: false,
              saveDisable: true,
            });
          }
        })
        .catch(err => {
          this.setState({
            loading: false,
            showMessage: true,
            success: false,
            message: err?.error?.message || message.error,
            saveDisable: false,
          });
        });
    } else {
      this.setState({
        showMessage: true,
        message: 'Debit Note List is empty',
      });
    }
  };

  // close the modal resetting  all the value
  handleModalClose = () => {
    this.setState({ createModalOpen: false, editModalOpen: false, deleteModalOpen: false });
    this.resetDNDialog();
  };

  // reset DN Dialog
  resetDNDialog = () => {
    this.setState({
      createDN: initializeCreateDN(),
      formEmptyField: false,
    });
  };

  getLedgerList = async () => {
    const ledgerListAll =await getCustomerList('all');
    this.getOtherListList(ledgerListAll)
  }
  getOtherListList = (ledgerListAll) =>{
    const ledgerList =ledgerListAll.filter(a=>!([LEDGERS.VAT.type].includes(a.typeId)))
    const ledgerListPartial = ledgerList.slice(0,300);
    this.setState({partyLedgerListPartial:ledgerListPartial,ledgerListPartial,partyLedgerList:ledgerList,ledgerList}) 
  }

  getTagList = () => {
    tagApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ tagList: response.data.list });
      }
    });
  };

  // create the sales Return, update the discount value
  createDebitNote = () => {
    const { dnList, createDN } = this.state;
    dnList.push(createDN);

    this.setState({ dnList: dnList }, () => {
      this.handleDebitListUpdate();
      this.handleModalClose();
    });
  };

  // edut the particular debit note from dnList.
  editDebitNote = (data, index) => {
    const { dnList, createDN } = this.state;
    dnList[index] = createDN;
    this.setState({ dnList: dnList }, () => {
      this.handleDebitListUpdate();
      this.handleModalClose();
    });
  };

  // delete the particular debit note from dnList.
  deleteDNItem = index => {
    const { dnList, indexInDN } = this.state;
    if (dnList.length >= indexInDN) {
      dnList.splice(indexInDN, 1);
      this.setState({ dnList: dnList }, () => {
        this.handleDebitListUpdate();
        this.handleModalClose();
      });
    }
  };

  handleDebitListUpdate = () => {
    const { dnList, debitNoteSummary } = this.state;
    let cr_amount = 0;
    dnList.map(element => {
      cr_amount += Number(element.cr_amount);
    });

    debitNoteSummary.amount = Number(Number(cr_amount).toFixed(2));
    debitNoteSummary.vat_amount = debitNoteSummary.amount * (debitNoteSummary.vat / 100);
    debitNoteSummary.net_amount = debitNoteSummary.amount + debitNoteSummary.vat_amount;
    this.setState({ debitNoteSummary });
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (createDN, index) => {
    this.setState({ createDN: clone(createDN), editModalOpen: true, indexInDN: index });
  };

    handleDeleteIconClick = index => {
      this.setState({ deleteModalOpen: true, indexInDN: index });
    };
    getFormValidationConfig = () => {
      const { formValidation, addTag } = this.state;

      let validationConfig = {
        flag: formValidation.flag,
        fieldList:formValidation.fieldList
      }
      validationConfig = addTag ? {
        flag: formValidation.flag,
        fieldList: [...validationConfig.fieldList, { title: 'tagid' }]
      } : formValidation;
      return validationConfig;
    };

    // handle on modal ok click
    handleModalOk = () => {
      // check edit or create config //close the dialog
      const { createModalOpen, editModalOpen, createDN, formValidation, indexInDN, addTag} = this.state;
      if (createModalOpen || editModalOpen) {
        const valid = validateForm(createDN, this.getFormValidationConfig(),
          (valid) => this.setState({ formValidation: valid }));
        if (valid) {
          if (createModalOpen) {
            this.createDebitNote();
          } else {
            this.editDebitNote(createDN, indexInDN);
          }
        }
      } else {
        this.deleteDNItem();
      }
    };

  handleDateChange = (field, value) => {
    const { debitNoteSummary } = this.state;
    const formattedDate = filterUtil.formatToNumericDate(value);
    debitNoteSummary[field] = formattedDate;
    this.setState({ debitNoteSummary });
  };

  handleInputChange = (field, value, all) => {
    const { debitNoteSummary } = this.state;
    debitNoteSummary[field] = value;
    if (field === 'vat') {
      debitNoteSummary.vat_amount = debitNoteSummary.amount * (value / 100);
      debitNoteSummary.net_amount = debitNoteSummary.amount + debitNoteSummary.vat_amount;
    }
    if (field === 'ledger_id') {
      debitNoteSummary.ledger_name = all && all['title'] || 'title';
      debitNoteSummary.type_id = all?.accountGroup;
      debitNoteSummary.party_id = '';
      debitNoteSummary.party_name = '';
      debitNoteSummary.panNo = all?.panNo;
      debitNoteSummary.party_taxable_amount = 0;
      value !==null && this.getType();
      }
    this.setState({ debitNoteSummary});
    };
    getType =() =>{
      const { debitNoteSummary,partyLedgerList } = this.state;
      const filteredList = partyLedgerList?.find((a)=>a?.customerId === debitNoteSummary?.ledger_id);
      if( filteredList?.accountGroup === 4 ){
        debitNoteSummary.type = enumVar?.salesRelated;
      } else  {debitNoteSummary.type = enumVar?.purchaseRelated;}
      this.setState({ debitNoteSummary});
    }

    handleCreate =() =>{
      this.setState({createModalOpen: true});
    }
    updateTotal = () =>{
      const { debitNoteSummary,vatStatus} = this.state;
      debitNoteSummary.vat = vatStatus ? VAT_PERCENTAGE :VAT_ZERO_PERCENTAGE;
      this.setState({ debitNoteSummary},()=>{
        this.handleInputChange('vat', debitNoteSummary?.vat)
      });
    }
    onChange = (field, value, all) => {
      const { createDN } = this.state;
      if(field ==='vatStatus'){
        this.setState({ vatStatus:value },()=>{
          this.updateTotal();
        });
        return;
      }
      if(field === 'ledger_id'){
        createDN.ledger_name = all?.title;
        createDN.type_id = all?.accountGroup;
      }
      createDN[field] = value;
      this.setState({ createDN });
    };

    getLedgerSelectOptions = () => {
      const {createDN,formValidation,ledgerList,ledgerListPartial} =this.state;
      return(
        <Cell col={6} tablet={6}>
                <label className='select-custom-label'>Ledger</label>
                <CustomerSelect
                  value ={createDN.ledger_id}
                  param='ledger_id'
                  valueKey='customerId'
                  clearable
                  onHandleChange={this.onChange}
                  required
                  pageValidation={formValidation.flag}
                  type='excludeVat'
                  ledgerList={ledgerList}
                  ledgerListPartial={ledgerListPartial}
                  />
              </Cell>
      )
    }

  render() {
    const {
      loading,
      success,
      message,
      showMessage,
      createDN,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      ledgerList,
      dnList,
      pageValidation,
      formValidation,
      debitNoteSummary,
      saveDisable,
      tagList,
      addTag,
      backDatedStatus,
      vatStatus,
      partyLedgerListPartial,
      partyLedgerList,
    } = this.state;
    const { fiscalYearRange } = this.props;
    const minDate = fiscalYearRange?.previous_fiscal_year_closed ? fiscalYearRange?.start_date : fiscalYearRange?.previous_fiscal_year_start;
    return (
      <DebitNoteStyled>
        <div className='create-debit-note'>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="message-snackbar">
            {
              <Snackbar
                className={success ? 'success-message' : ''}
                timeout={success ? 100 : 4000}
                onTimeout={() => {
                  this.setState({ showMessage: false },() => {
                      this.directToMainPage();
                  });
                }}
                open={showMessage}
              >
                {message}
              </Snackbar>
            }
          </div>
          <div className="card-header-bar clearfix" ref="tableReference">
            <div className="header-left">
              <h2>New Debit Note</h2>
            </div>
            <GRNStyled>
              <div className={`${backDatedStatus || 'disabled-opacityFull'} header-right document_date`}>
                <span className="date-picker-wrapper">
                  <label>Document Date:</label>
                  <DatePicker
                    date={filterUtil.getMomentDate(debitNoteSummary.document_date)}
                    onChange={e => this.handleDateChange('document_date', e)}
                    maxDate={filterUtil.getCurrentDate()}
                    minDate={filterUtil.getMomentDate(minDate)}
                  />
                </span>
              </div>
            </GRNStyled>
          </div>
          <div className="card-body">

            <div>
              <div className="three-input-wrapper">
                <Grid>
                  <Cell col={3} >
                    <label className="select-custom-label">Party Ledger</label>
                    <CustomerSelect
                      value ={debitNoteSummary.ledger_id}
                      param='ledger_id'
                      valueKey='customerId'
                      clearable
                      onHandleChange={this.handleInputChange}
                      required
                      pageValidation={pageValidation.flag}
                      type='excludeVat'
                      ledgerList={partyLedgerList}
                      ledgerListPartial={partyLedgerListPartial}
                      />
                  </Cell>
                  <Cell col={3} >
                  <label className="select-custom-label">Type</label>
                  <BillingSelect
                    name="form-field-name"
                    param="type"
                    clearable
                    value={debitNoteSummary.type}
                    valueKey="title"
                    labelKey="label"
                    options={jvTypeList}
                    floatingLabel="Type"
                    handleChange={this.handleInputChange}
                    multipleParam
                    emptyField={pageValidation.flag}
                    required
                  />
                </Cell>

                  <Cell col={3} className="input-field">
                    <BillingTextField
                      value={debitNoteSummary.panNo || ''}
                      param="panNo"
                      floatingLabel="PAN Number"
                      type='number'
                      className="billing-required no-pointer-events"
                    />
                  </Cell>

                  <Cell col={3}>
                    <span className='date-picker-wrapper refDate'>
                          <label>Ref Date</label>
                          <DateRangePicker
                            date={filterUtil.getMomentDate(debitNoteSummary.ref_date)}
                            onChange={(e) =>this.handleDateChange('ref_date' ,e)}
                            maxDate={filterUtil.getCurrentDate()}
                          />
                  </span>
                </Cell>

                  <Cell col={3} className="input-field">
                    <BillingTextField
                      value={debitNoteSummary.ref_invoice_number}
                      param="ref_invoice_number"
                      floatingLabel="Ref Invoice Number"
                      required={false}
                      className="billing-required"
                      handleChange={this.handleInputChange}
                    />
                  </Cell>
                  <Cell col={3} className="input-field">
                    <BillingTextField
                      value={debitNoteSummary.narration}
                      param="narration"
                      floatingLabel="Narration"
                      required={true}
                      className="billing-required"
                      emptyField={pageValidation.flag}
                      handleChange={this.handleInputChange}
                    />
                  </Cell>
                </Grid>
              </div>
              <div className="table-wrapper">
                <div ref="fixedTableBody" className="overflow-scrollable fixed-table-wrapper">
                  <table>
                    <TableHeader
                      headerDetails={createDebitNoteConfig.headerDetails}
                      filterHeaderLabel={false}
                      handleSorting={this.handleTableSorting}
                    />
                    <CreateDebitNoteTableBody
                      dataList={dnList}
                      handleEditIconClick={this.handleEditIconClick}
                      handleDeleteIconClick={this.handleDeleteIconClick}
                      tagList={tagList}
                    />
                  </table>
                </div>

                {/* Fab Icon*/}
                <div className="mini-fab-button">
                  <Fab mini onClick={this.handleCreate}>
                    <Icon name="add" />
                  </Fab>
                </div>
              </div>
            </div>
            <div className="total-section-wrapper">
                <Grid>
                  <Cell col={8} />
                  <Cell col={2} className="right-align active-opacity-text">
                    Sub Total
                  </Cell>
                  <Cell col={1} />
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(debitNoteSummary.amount)}
                  </Cell>
                </Grid>

                <Grid>
                  <Cell col={8} />
                  <Cell col={2} className="right-align active-opacity-text">
                    VAT %
                  <Checkbox
                  checked={vatStatus}
                  onChange={({ target: { checked } }) => {
                    this.onChange('vatStatus', checked);
                  }}
                />
                  </Cell>
                  <Cell col={1}>
                    <BillingTextField
                      value={debitNoteSummary.vat}
                      handleChange={this.handleInputChange}
                      type="number"
                      param="vat"
                      disabled
                      zeroError={false}
                    />
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(Number(debitNoteSummary.amount) *Number(vatStatus ?VAT_PER_DERIVE : VAT_ZERO_PERCENTAGE))}
                  </Cell>
                </Grid>
                <div className="hr" />
                <Grid>
                  <Cell col={8} />
                  <Cell col={2} className="right-align active-opacity-text">
                    Total
                  </Cell>
                  <Cell col={1} />
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(Number(debitNoteSummary.net_amount))}
                  </Cell>
                </Grid>
              </div>
            </div>
            <div className="newinvoice-btn-wrapper ">
              <Grid>
                <Cell col={8} />
                <Cell col={4} className="right-align">
                  <Button
                    accent
                    className="cancel-btn modal-btn"
                    onClick={() => {
                      this.handleCancelClick();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    accent
                    className={saveDisable ? 'save-btn modal-btn btn-disabled' : 'save-btn modal-btn'}
                    disabled={saveDisable}
                    onClick={() => {
                      this.handleSaveClick();
                    }}
                  >
                    Save
                  </Button>
                </Cell>
              </Grid>
            </div>

        <DialogView
          ledgerList={ledgerList}
          addTag={addTag}
          renderLedgerSelectOptions={this.getLedgerSelectOptions}
          onModalClose={this.handleModalClose}
          modalOpen={createModalOpen}
          editModalOpen={editModalOpen}
          deleteModalOpen={deleteModalOpen}
          createDN={createDN}
          handleInputChange={this.onChange}
          onModalSubmit={this.handleModalOk}
          formEmptyField={formValidation.flag}
          handleDateChange={this.handleDateChange}
          tagList={tagList}
          />
      </div>
      </div>
      </DebitNoteStyled>
    );
  }
}

CreateDebitNote.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
    fiscalYearRange: state.billing.fiscalYearRange || null,
  };
};

const createDebitNote = connect(mapStateToProps)(CreateDebitNote);

export default createDebitNote;
