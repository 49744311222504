import React, { Component } from 'react';
import tableConfig from './config';
import history from '../../../utils/history';
import * as filterUtil from '../../../utils/filterUtil';
import { clone } from '../../../utils/arrayProcessor';
import StockJournalStyled from './StockJournalStyled';
import withBaseState from '../../common/withBaseState';
import { Grid, Cell } from '../../../components/BillingMDC';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { BillingSnackBar } from '../../../components/BillingMDC';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { STOCK_JOURNAL_CONFIG } from '../../common/domain.config';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { STOCK_JOURNAL_CREATE,STOCK_JOURNAL_DETAILS } from '../../../data/enums/Route';
import {flattenDataPrintAttributeWise, groupPrintDataSet} from "../../common/print.service";

class StockJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      printButtonClicked: false,
      snack: { ...snackService.snackParameters },
      queryParameters: { ...queryService.queryParameters },
      data: {
        list: [],
        total: 0,
      },
      dataList: [],
      printData: [],
      date: filterUtil.getCurrentDay(),
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
  window.addEventListener('keydown', this.handleKeyDown);
}
componentWillUnmount() {
  window.removeEventListener('keydown', this.handleKeyDown);
}
handleKeyDown = e => {
  const charCode = String.fromCharCode(e.which).toLowerCase();
  if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
    e.preventDefault();
    this.getDataForPrint();
  }
};

  getDataForPrint = async () => {
    const self = this;
    await this.loadTableData(true);
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.loadTableData()
      self.setState({ printButtonClicked: false });
    };
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  loadPrintData = () => {
    const { queryParameters } = this.state;
    const {getDetailList} = this.props;
    let query = clone(queryParameters);
    query.pagination.limit = 2000;
    query.pagination.page=1;
    getDetailList(query)
      .then(response => {
        const formattedList = flattenDataPrintAttributeWise(response.list, tableConfig.lengthyAttributes, tableConfig.getData);
        const printData = formattedList|| [];
        this.setState({ printData }, () => {
          this.groupPrintDataSetNew();
        });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage('Error while fetching print data set');
      });
  };
  /** get data for grid, adjust loading flag */
  loadTableData = async (print =false)=> {
    const {getDetailList} = this.props;
    const {queryParameters} = this.state;
    let query = clone(queryParameters);
    query.pagination.limit = 2000;
    await getDetailList(print ? query : queryParameters).then(response => {
      const {data} = this.state;
      data.list = response.list;
      data.total = response.total || 0;
      this.setState({data}, () => {
        print && this.groupPrintDataSetNew();
      });
    });
  };
  groupPrintDataSetNew() {
    const { data} = this.state;
    const { company  } = this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(data.list);
    const dataList = groupPrintDataSet(orders, printInfoBill, tableConfig);
    this.setState({ dataList });
  }

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  handleTableBodyClick = id => {
    // history.push(`/${STOCK_JOURNAL_DETAILS}/${id}`);
  };

  handleFabClick = () => {
    history.push(`/${STOCK_JOURNAL_CREATE}`);
  };

  render() {
    const { queryParameters, data, display, printButtonClicked, dataList, date, snack } = this.state;
    const { serverResponseWaiting, company } = this.props;
    return (
      <StockJournalStyled className="stock-journal">
        <div className={printButtonClicked ? 'no-print' : 'display-block sales-outstanding'}>
          <PageView
            domainConfig={STOCK_JOURNAL_CONFIG}
            headerConfig={{
              search: false,
              date: true,
              download: true,
              filter: true,
              create: true,
              print: true,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            handlePrintClick={this.getDataForPrint}
            clearSearchText={this.basePaginationService.clearSearchText}
            resetFilter={this.basePaginationService.resetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.basePaginationService.handleDownloadClick}
            data={data}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.handleTableBodyClick}
            onPageSelect={this.basePaginationService.onPageSelect}
            createHandler={this.handleFabClick}
          />
        </div>
        <div className={printButtonClicked ? 'display-block stock_journal_print portrait-type' : 'no-print'}>
          {dataList.map((ceItem, key) => (
            <div className="print-body zoom-reduce voucher-print">
              <div className="jv card-body">
                <header>
                  <Grid className="flex">
                    <Cell col={2} tablet={2} phone={2} className="left">
                      <div className="header-title">{company.title}</div>
                      <span className="header-title-content">
                        <span className="active-opacity-text">Address: </span>
                        <span className="font-bold">{company.address}</span>
                      </span>
                      <span className="header-title-content">
                        <span className="active-opacity-text">Phone: </span>
                        <span className="font-bold">{company.phone}</span>
                      </span>
                      <span className="no-margin-bottom header-title-content">
                        <span className="active-opacity-text">PAN No: </span>
                        <span className="font-bold">{company.panNumber}</span>
                      </span>
                    </Cell>
                    <Cell col={4} tablet={4} phone={4} className="center">
                      <div className="center-align">
                        <h2>{STOCK_JOURNAL_CONFIG.title}</h2>
                        <div className="default-margin-top-3">
                          <span className="header-title-content no-margin-bottom">
                            <span className="active-opacity-text">
                              Report from
                              <span className="date-rendered">
                                {` ${filterUtil.formatToReadableDate(
                                  queryParameters.date.start,
                                )} - ${filterUtil.formatToReadableDate(queryParameters.date.end)}`}
                              </span>
                            </span>
                          </span>
                          {data.nepaliDateRange && (
                            <span className="header-title-content no-margin-bottom">
                              <span className="active-opacity-text">
                                Report from
                                <span className="date-rendered">
                                  {` ${data.nepaliDateRange.start_date_nepali} - ${data.nepaliDateRange.end_date_nepali}`}
                                </span>
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                    </Cell>
                    <Cell col={2} tablet={2} phone={2} className="right">
                      <div className="header-menu-right">
                        <p>
                          <span className="">Date: </span>
                          <span className="">{filterUtil.formatToReadableDate(date)}</span>
                        </p>
                        {data.miti && (
                          <p>
                            <span className="">Miti: </span>
                            <span className="">{data.miti}</span>
                          </p>
                        )}
                      </div>
                    </Cell>
                  </Grid>
                </header>
                <div className={` fixed-table-wrapper ${!data.footer ? 'fixed-table-height ' : 'total-footer'}`}>
                  <table>
                    <TableHeader headerDetails={tableConfig.header} filterHeaderLabel={false} />
                    {tableConfig.getTableBody({
                      dataList: ceItem.list,remainingLines:ceItem.remainingLines,
                    })}
                  </table>
                </div>
              </div>
              <div className="footer-block">
                <PageNumber value={ceItem.page} totalPage={dataList.length} />
              </div>
            </div>
          ))}
        </div>
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </StockJournalStyled>
    );
  }
}

const StockJournalWithState = withBaseState(StockJournal);

export default StockJournalWithState;
