const getConfigData = footerData => {
  const columns = [
    [
      { content: 'SN' },
      { content: 'Customer Name' },
      { content: 'PAN Number' },
      { content: 'Taxable Value' },
      { content: 'VAT' },
      { content: 'Tax Free Sale' },
    ],
  ];

  const footer = [
    [
      '',
      'Total',
      '',
      { content: footerData?.totalTaxableAmount ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalTaxableVat ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalTaxExemptedSales ?? '', styles: { lineWidth: 0.1 } },
    ],
  ];

  const columnStyles = {
    0: { cellWidth: 10 },
    2: { cellWidth: 18 },
  };

  const cellsToAlignRight = { 3: '3', 4: '4', 5: '5'};

  return {
    columns,
    footer,
    columnStyles,
    cellsToAlignRight,
  };
};

export default getConfigData;
