/* eslint-disable object-curly-newline,object-property-newline */
import React from 'react';
import { ACTION } from '../../../../../data/enums/enums';
import { Icon } from '../../../../../components/BillingMDC';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { formatToReadableDate, getCurrentDay, getEndOfCurrentMonth } from '../../../../../utils/filterUtil';

const getBatch = (batch = {}) => {
  return {
    id: batch.id || batch.businessBatchId || 0,
    businessBatchId: batch.businessBatchId || '',
    businessId: batch.businessId || '',
    businessSkuId: batch.businessSkuId || '',
    updatedAt: batch.updatedAt || getCurrentDay(),
    title: batch.title || '',
    dlp: batch.dlp || 0,
    vat: batch.vat || 13,
    mfgDate: getCurrentDay(),
    expiryDate: getEndOfCurrentMonth(),
  };
};
const formValidationFields = [{ title: 'title' }, { title: 'dlp' }, { title: 'mfgDate' }, { title: 'expiryDate' }];

const TableBody = ({ dataList, onRowClick, crudMode }) => (
  <tbody>
    {dataList.map((data) => (
      <tr key={data.id}>
        <td className='left-align'>{data.title}</td>
        <td className='left-align'>{formatToReadableDate(data.updatedAt ? data.updatedAt : getCurrentDay())}</td>
        <td>{formatToReadableDate(data.mfgDate ? data.mfgDate : getCurrentDay())}</td>
        <td>{formatToReadableDate(data.expiryDate ? data.expiryDate : getEndOfCurrentMonth())}</td>
        <td>{fixedFloatAndCommas(data.dlp)}</td>
        {crudMode !== ACTION.READ && (
          <td className='right-align'>
            <Icon name='edit' onClick={() => onRowClick(data, ACTION.UPDATE)} />
          </td>
        )}
      </tr>
    ))}
  </tbody>
);

const headerConfig = [
  {
    id: 1,
    label: 'title',
    title: 'Batch',
    className: 'cursor-pointer left-align',
    sortable: false,
  },
  {
    id: 2,
    label: 'date',
    title: 'Updated Date',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 3,
    label: 'mfgDate',
    title: 'Manufactured Date',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 4,
    label: 'expiryDate',
    title: 'Expiry Date',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 5,
    label: 'dlp',
    title: 'DLP',
    className: 'cursor-pointer',
    sortable: false,
  },
  { id: 6, label: '', title: '', className: '', sortable: false },
];

const config = {
  header: headerConfig,
  getTableBody: TableBody,
  getDetail: getBatch,
};

export { config, formValidationFields };
