import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { formatToReadableDate } from '../../../utils/filterUtil';

const SRNTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <tr className='cursor-pointer' onClick={() => onRowClick(data)}>
        <td className='left-align'>{data.salesReturnInvoiceNumber}</td>
        <td className='left-align'>{data.refNumber}</td>
        <td className='left-align'>{data.customerName ? data.customerName : data.customer.title}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.netAmount)}</td>
        <td className='left-align'>{formatToReadableDate(data.documentDate)}</td>
        <td className='left-align'>{data.mitiTitle}</td>
        <td className='left-align'>{formatToReadableDate(data.createdAt)}</td>
      </tr>
    ))}
  </tbody>
);

SRNTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const srnHeader = {
  headerDetails: [
    { id: 1, label: 'sales_return_invoice_number', title: 'SRN Number', className: 'cursor-pointer', sortable: true },
    { id: 6, label: 'voucher_number', title: 'Sales Invoice Number', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'customer_ref_id', title: 'Customer Name', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'net_amount', title: 'SRN Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'date', title: 'Document Date', className: 'cursor-pointer left-align', sortable: true },
    { id: 7, label: 'miti_title', title: 'Miti', className: 'cursor-pointer', sortable: true },
    { id: 8, label: 'created_at', title: 'Created Date', className: 'cursor-pointer', sortable: true },
  ],
};

export { srnHeader, SRNTableBody };
