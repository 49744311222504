import { compose } from 'recompose';

import Users from './Users';

import apiInterceptor from '../../../api/interceptor';

import { usersApi, userGroupApi } from '../../common/base.api';

const composedUsers = compose(apiInterceptor({ ...usersApi, getUserGroupListApi: userGroupApi.getUserGroupList }))(
  Users,
);

export default composedUsers;
