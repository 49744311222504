import styled from 'styled-components';

const DateRangeStyled = styled.div`
  .inline {
    display: inline;
  }
  .right-drawer {
    direction: ltr;
  }
  .fixed-calendar-label {
    font-size: 14px;
    color: var(--secondary-color);
    opacity: var(--hover-opacity);
  }
  .fixed-calendar,
  .fixed-calendar-active {
    margin: 0 8px 0 0;
  }
  .fixed-calendar-active {
    color: var(--accent-color) !important;
    opacity: initial;
  }
  .calendar-icon {
    border: none;
  }
  .calendar-arrow-icon {
    text-align: right;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--gray-color);
  }

  .date-range-picker-wrapper {
    position: relative;
    outline: none;
    .rdrCalendarWrapper {
      position: absolute;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24) !important;
      z-index: 50;
    }
    .rdrMonthPicker,
    .rdrYearPicker {
      select {
        option {
          display: revert;
        }
      }
    }
    .rdrDays {
      button {
        margin-right: 0px !important;
      }
    }
  }
  .date-picker-holder,
  .date-picker-holder-active {
    cursor: pointer;
    padding-left: 24px;
  }
  .calendar-date {
    position: relative;
  }
  .calendar-status {
    width: 100px;
    position: absolute;
    top: -22px;
    font-size: 10px;
    color: var(--gray-color);
  }
  .date-picker-holder-active .calendar-status {
    color: var(--accent-color);
  }
  .date-picker-holder-active > .calendar-date,
  .date-picker-holder-active > .calendar-arrow-icon {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--accent-color);
  }
  .date-picker-holder > .calendar-date {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--gray-color);
  }

  .date-range-picker-view-toggle,
  .date-range-picker-view-toggle-active {
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    border: none;
    display: inline;
    border-radius: 0;
    outline: none;
    line-height: 3rem;
    width: 40%;
    margin: 0 0 20px 0;
    padding: 0 0 5px;
    box-sizing: content-box;
    transition: all 0.3s;
  }
  .date-range-picker-view-toggle-active {
    border-bottom: 1px solid var(--accent-color);
  }
  .date-range-picker-view-toggle {
    border-bottom: 1px solid var(--gray-color);
  }
  .rdr-Calendar {
    position: absolute;
    top: 56px;
    width: 295px !important;
    padding: 16px !important;
    margin-left: 24px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24) !important;
    z-index: 50;
  }
  .rdr-DateRange {
    position: absolute;
    z-index: 300;
    top: 40px;
    left: 24px;
  }
  .is-inRange,
  .is-selected {
    background: var(--accent-light-color) !important;
    color: #fff !important;
  }
  .rdr-Day:hover {
    background: var(--accent-color) !important;
    color: #fff !important;
    transition: background-color 1s ease !important;
  }
  /* .rdr-MonthAndYear-button.prev {
    cursor: pointer;
    background: url(svg/left_arrow.svg) no-repeat center!important;
}
.rdr-MonthAndYear-button.next {
    cursor: pointer;
    background: url(svg/right_arrow.svg) no-repeat center!important;
} */
  .rdr-MonthAndYear-button.prev > i,
  .rdr-MonthAndYear-button.next > i {
    display: none !important;
  }

  .date-picker-wrapper {
    position: relative;
    float: right;
  }

  .date-picker-wrapper .rdr-Calendar {
    right: 0px;
    line-height: 20px;
  }
  .date-picker-wrapper .date-picker-holder {
    color: rgba(0, 0, 0, 0.54);
  }
  .date-picker-wrapper .date-picker-holder > .calendar-date {
    border-bottom: none;
  }
  .date-picker-wrapper .calendar-arrow-icon {
    border-bottom: none;
  }
  .date-picker-wrapper .date-range-picker-view-toggle-active,
  .date-picker-wrapper .date-picker-holder-active > .calendar-date {
    border-bottom: none;
  }

  .dialog-date-picker-wrapper {
    position: relative;
    float: left;
    top: 2px;
    display: block;
    width: 100%;
  }

  .dialog-date-picker-wrapper .rdr-Calendar {
    top: -40px;
  }

  .dialog-date-picker-wrapper .date-picker-holder {
    padding-left: 0px;
  }
  .dialog-date-picker-wrapper #date-range-picker-id {
    padding-bottom: 8px;
  }

  .dialog-date-picker-wrapper .calendar-arrow-icon {
    padding-bottom: 8px;
    padding-left: 30%;
  }
`;
export default DateRangeStyled;
