import React from 'react';
import { roundAndCommas,fixedFloatAndCommas } from '../../../utils/conversion';

const LedgerBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <tr onClick={() => onRowClick(data)}>
        <td className='left-align'>{data.name}</td>
        <td className='right-align'>{data.openingBalance ? roundAndCommas(Math.abs(data.openingBalance)) : 0}</td>
        <td className='right-align'>{data.openingBalance === 0 ? ' ' : data.openingBalance > 0 ? 'Dr' : 'Cr'}</td>
        <td className='right-align'>{data.debit ? roundAndCommas(data.debit) : 0}</td>
        <td className='right-align'>{data.credit ? roundAndCommas(data.credit) : 0}</td>
        <td className='right-align'>{data.closingBalance ? roundAndCommas(Math.abs(data.closingBalance)) : 0}</td>
        <td className='right-align'>{data.closingBalance === 0 ? ' ' : data.closingBalance > 0 ? 'Dr' : 'Cr'}</td>
      </tr>
    ))}
  </tbody>
);

const stockLedgerConfig = [
  {
    id: 2,
    label: 'title',
    title: 'Ledger',
    className: '',
    sortable: false,
  },
  {
    id: 3,
    label: 'opening_balance',
    title: 'Opening',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 4,
    label: 'opening_balance_status',
    title: '',
    className: '',
    sortable: false,
  },
  {
    id: 5,
    label: 'debit',
    title: 'Debit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 6,
    label: 'credit',
    title: 'Credit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'closing_balance',
    title: 'Closing',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'closing_balance_status',
    title: '',
    className: '',
    sortable: false,
  },
];

const getTotalDetails = (data = {}) => {
  const totalDetails = [
    {
      id: 1,
      label: 'total',
      title: 'Total',
      className: 'total',
      sortable: false,
    },
    {
      id: 2,
      label: 'openingBalance',
      title: 'Opening Balance',
      className: 'openingBalance text-right',
      sortable: false,
      classes:'text-right',
      value:fixedFloatAndCommas(data?.openingBalance) || 0,
    },
    {
      id: 8,
      label: 'blnc',
      title: '',
      className: '',
      sortable: false,
    },
    {
      id: 3,
      label: 'debit',
      title: 'Debit',
      className: 'credit text-right',
      sortable: false,
      classes:'text-right',
      value:fixedFloatAndCommas(data?.debit) || 0,
    },
    {
      id: 4,
      label: 'credit',
      title: 'Credit',
      className: 'credit text-right',
      sortable: false,
      classes:'text-right',
      value:fixedFloatAndCommas(data?.credit) || 0,
    },
    {
      id: 5,
      label: 'closingBalance',
      title: 'Closing Balance',
      className: 'closingBalance text-right',
      classes:'text-right',
      sortable: false,
      value:fixedFloatAndCommas(data?.closingBalance) || 0,
    },
    {
      id: 7,
      label: 'blnc',
      title: '',
      className: '',
      sortable: false,
    },
  ]
  return totalDetails;
  }

const config = {
  header: stockLedgerConfig,
  getTableBody: LedgerBody,
  getTotalDetails,
};

export default config;
