import PropTypes from 'prop-types';
import React from 'react';
import TableHeader from '../TableHeader';
import PageNumber from './PageNumber';
import IrdReportHeader from './IrdReportHeader';

const IrdReportPrintBody = ({
  dataList,
  tableConfig,
  columnTotal,
  domainConfig,
  date,
  nepaliDate,
  company,
  disableUpperHeaderDetails,
  nepaliDateExist,
  singleDate,
  singleDateExist,
  printFlag,
  printFooterEnable,
  colSpanTotal,
}) => {
  return (
    <div>
      {dataList.map((chunk, key) => {
        const { list = [], footer = false, page = 0 ,remainingLines =[] } = chunk;
        return (
          <div className='print-body zoom-reduce'>
            <div className='pdf-body card-body'>
              <IrdReportHeader
                divClassName='first-header-bar'
                domainConfig={domainConfig}
                nepaliDate={nepaliDate}
                company={company}
                date={date}
                nepaliDateExist={nepaliDateExist}
                singleDate={singleDate}
                singleDateExist={singleDateExist}
              />
              <div className={` fixed-table-wrapper ${!footer ? 'report-table-wrapper ' : 'report-table-footer'}`}>
                <table className='pdf-table'>
                  <TableHeader headerDetails={tableConfig.header.headerDetails} filterHeaderLabel={false} />
                  {tableConfig.getTableBody({ dataSet: list ,remainingLines:remainingLines,columnTotal , colSpanTotal, printFlag,footer })}
                  { printFooterEnable && footer && tableConfig.getTableFooter({ columnTotal, colSpanTotal, printFlag })}
                </table>
              </div>
            </div>
            <div className='footer-block'>
              <PageNumber value={page} totalPage={dataList.length} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default IrdReportPrintBody;

IrdReportPrintBody.propTypes = {
  columnTotal: PropTypes.instanceOf(Object),
  dataList: PropTypes.instanceOf(Array),
  tableConfig: PropTypes.shape({
    getTableBody: PropTypes.func.isRequired,
    getTableFooter: PropTypes.func,
    header: PropTypes.instanceOf(Object),
  }).isRequired,
  domainConfig: PropTypes.instanceOf(Object),
  printFlag: PropTypes.bool,
  printFooterEnable:PropTypes.bool,
  colSpanTotal:PropTypes.num,
};

IrdReportPrintBody.defaultProps = {
  columnTotal: {},
  dataList: [],
  domainConfig: {},
  printFlag: false,
  printFooterEnable:true,
  colSpanTotal:2,
};
