import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { config, getTotalDetails } from './config';
import '../../../styles/printStyle.css';
import { clone } from '../../../utils/arrayProcessor';
import IrdView from '../../common/irdReport/IrdView';
import withBaseState from '../../common/withBaseState';
import * as queryService from '../../common/query.service';
import { defaultHeaderConfig } from '../../common/irdReport/config';
import { NEW_SALES_VAT_REPORT_CONFIG } from '../../common/domain.config';
import { getIrdSalesColumnTotals } from '../../../utils/handlers';
import * as snackService from '../../common/snack.service';
import { groupPrintDataSet } from '../../common/print.service';
import VatReportStyle from '../NewSRNVatReport/VatReportStyle';
import { onRowItemClick, route } from '../../fundamentals/common/helpers';
import SimplePrint from '../../../components/PrintComponent/simplePrint/simplePrint';
import getPrintData from './getPrintData';
import getConfigData from './getConfigData';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  company: PropTypes.shape({
    address: PropTypes.string,
    panNumber: PropTypes.number,
    title: PropTypes.string,
    phone: PropTypes.string,
  }),
};

const defaultProps = {
  company: {},
  serverResponseWaiting: false,
};

class NewSalesVatReport extends Component {
  constructor(props) {
    super(props);
    this.box = React.createRef();
    this.state = {
      totalData: 1,
      dataSet: [],
      queryParameters: { ...queryService.queryParameters },
      nepaliDate: {
        start: '',
        end: '',
      },
      columnTotal: {
        totalSales: 0,
        taxExemptedSales: 0,
        exportSales: 0,
        discount: 0,
        totalTaxableAmount: 0,
        vat: 0,
        totalQuantity: 0,
      },
      dataList: [],
      printButtonClicked: false,
      display: {
        searchBox: false,
        filter: false,
      },
    };
    const { downloadList } = this.props;
    this.dataListLength = 0;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  handleOutsideClick = event => {
    if (this.box && !this.box.current.contains(event.target)) {
      this.state.printButtonClicked === true && this.setState({ printButtonClicked: false });
    }
  };

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    window.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('mousedown', this.handleOutsideClick);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  /** remove eventlistener on component unmount* */
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  /** get data for grid, adjust loading flag */
  loadTableData = async (print = false) => {
    const self = this;
    const { getList } = this.props;
    const { queryParameters } = this.state;
    let query = clone(queryParameters);
    query.pagination.limit = 2000;
    query.pagination.page = 1;
    await getList(print ? query : queryParameters)
      .then(response => {
        const nepaliDate = {
          start: response.nepali_date.start_date_nepali,
          end: response.nepali_date.end_date_nepali,
        };
        const columnTotal = getIrdSalesColumnTotals(response);
        const data = response.list;
        self.setState(
          {
            dataSet: data,
            totalData: response.total,
            nepaliDate,
            columnTotal,
          },
        );
      })
      .catch(error => {
        console.error(error);
        this.sendErrorMessage();
      });
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  handlePrintClick = async () => {
    await this.loadTableData(true);
    const self = this;
    self.setState({ printButtonClicked: true });
  };

  groupPrintDataSetNew = async () => {
    const { dataSet } = this.state;
    const { company } = this.props;
    const printInfoBill = { ...company, printInfo: { ...company.printInfo, batchEnabled: false } } || {};
    const orders = clone(dataSet);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;

    this.setState(display);
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  render() {
    const { queryParameters, totalData, columnTotal, dataList, nepaliDate, display, dataSet, printButtonClicked } =
      this.state;
    const { company, serverResponseWaiting } = this.props;

    const middleHeaderData = {
      date: {
        reportPeriod: true,
        value: queryParameters?.date,
      },
      title: NEW_SALES_VAT_REPORT_CONFIG?.title,
    };
    const printData = getPrintData([...dataSet], company, middleHeaderData, columnTotal);
    const configData = getConfigData(printData?.tableFooterData);

    return (
      <VatReportStyle>
        <div className="new-sales-vat upperTotalExist">
          <IrdView
            company={company}
            dataSet={dataSet}
            display={display}
            printList={dataList}
            tableConfig={{ ...config, header: { ...config?.header, upperHeaderDetails: getTotalDetails(columnTotal) } }}
            totalData={totalData}
            nepaliDate={nepaliDate}
            columnTotal={columnTotal}
            // printFlag={printButtonClicked}
            loading={serverResponseWaiting}
            // printColumnTotal={printData.total}
            queryParameters={queryParameters}
            controlDisplay={this.controlDisplay}
            domainConfig={NEW_SALES_VAT_REPORT_CONFIG}
            handlePrintClick={this.handlePrintClick}
            printReference={this.box}
            handleDownloadClick={this.basePaginationService.handleDownloadClick}
            resetFilter={this.basePaginationService.resetFilter}
            headerConfig={{ ...defaultHeaderConfig, print: true }}
            handlePageSelect={this.basePaginationService.onPageSelect}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            onTableBodyClick={onRowItemClick}
            printFooterEnable={false}
            enableTableFooter={false}
            disableUpperHeaderDetails={false}
            colSpan_t_l={1}
            colSpan_t_r={1}
          />
        </div>
        {printButtonClicked && (
          <div className="display-block portrait-type">
            <SimplePrint configData={configData} printData={printData} orientation={'landscape'} />
          </div>
        )}
      </VatReportStyle>
    );
  }
}

NewSalesVatReport.propTypes = propTypes;
NewSalesVatReport.defaultProps = defaultProps;

const NewsalesVatReportWithState = withBaseState(NewSalesVatReport);

export default NewsalesVatReportWithState;
