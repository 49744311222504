import React, { Component } from 'react';
import Dialog from './Dialog';
import tableConfig from './config';
import FYIStyled from './FYMStyled';
import { downLoadFYMSumaryTitle } from './config';
import { ACTION } from '../../../data/enums/enums';
import { validateForm } from '../../common/validation';
import PageView from '../../common/pagination/PageView';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import { fymMapper, formValidationFieldList } from './config';
import { BillingSnackBar } from '../../../components/BillingMDC';
import * as downloadService from '../../../views/common/download.service';
import { FISCAL_YEAR_MIGRATION_CONFIG } from '../../common/domain.config';
import { fixedFloat } from '../../../utils/conversion';
export class FiscalYearMigration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: { ...queryService.queryParameters },
      dialogType: ACTION.NULL,
      data: {
        list: [],
        total: 0,
      },
      dataMigrate: {},
      fymGroup: fymMapper({}),
      migrateData: {
        migrateList: [],
        migrateTotal: {},
        newYearMigrateList: [],
        newYearMigrateTotal: {},
      },
      accountGroupList: [],
      showErrorMessage: false,
      validation: {
        flag: false,
        fieldList: formValidationFieldList,
      },
    };
    const { downloadList } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { getPaginatedList } = this.props;
    const { queryParameters } = this.state;
    getPaginatedList(queryParameters)
      .then(response => {
        const { data } = this.state;
        data.list = response.list.filter(d => new Date(d.startDate) <= new Date());
        data.total = response.total;
        this.setState({ data });
      })
      .catch(err => {
        console.log(err);
      });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleEditIconClick = data => {
    this.getAccountGroupList();
    this.setState({
      dialogType: ACTION.UPDATE,
      dataMigrate: data,
      fymgroup: fymMapper(data),
    });
  };

  handleModalSubmit = () => {
    const { dialogType, validation, fymGroup } = this.state;
    const formValid = validateForm(fymGroup, validation, valid => this.setState({ validation: valid }));
    if (!formValid) return false;
    switch (dialogType) {
      case ACTION.UPDATE:
        this.getFYMSummary();
        break;
      case ACTION.READ:
        this.getConfirmation();
        break;
      default:
        break;
    }
  };

  getFYMSummary = (showStockStatus = true) => {
    const { getMigrateList } = this.props;
    const { fymGroup, dataMigrate, migrateData } = this.state;
    getMigrateList({
      start_date: dataMigrate.startDate,
      end_date: dataMigrate.endDate,
      title: fymGroup.title,
      account_group: fymGroup.accountGroup,
      show_stock: showStockStatus,
    }).then(response => {
      if (response) {
        migrateData.migrateList = response.data;
        migrateData.migrateTotal = response.total;
        migrateData.newYearMigrateList = response.newFiscalYearJvEntry || [];
        migrateData.newYearMigrateTotal = response.newFiscalYearJvTotal || {};
        this.setState({ migrateData });
      }
    });
    this.setState({ dialogType: ACTION.READ });
  };

  getConfirmation = () => {
    const { migrateData, dataMigrate, fymGroup } = this.state;
    const { confirm } = this.props;
    const openingListCheck = fymGroup.jvEntry ? migrateData.newYearMigrateList : [];
    const openingTotalCheck = fymGroup.jvEntry ? migrateData.newYearMigrateTotal : {};
    const data = {
      fiscalYearId: dataMigrate.idFiscalYear,
      closingList: migrateData.migrateList,
      closingTotal: migrateData.migrateTotal,
      openingList: openingListCheck,
      openingTotal: openingTotalCheck,
    };
    const balancedStatus = this.checkClosingBlncFunc();
    if (balancedStatus) {
      confirm({ data })
        .then(response => {
          if (response) {
            const snack = snackService.generateSuccessMessage();
            this.setState({ snack }, () => this.loadTableData());
          }
        })
        .catch(err => {
          const snack = snackService.generateFailureMessage();
          this.setState({
            snack,
          });
        });
      this.handleModalClose();
      this.setState({ dialogType: ACTION.NULL });
    }
  };

  getAccountGroupList = () => {
    const { getGroupList } = this.props;
    getGroupList().then(response => {
      if (response) {
        const accountGroupList = response;
        this.setState({ accountGroupList });
      }
    });
  };

  checkClosingBlncFunc = () => {
    const { migrateData } = this.state;
    if (fixedFloat(migrateData.migrateTotal.debit, 4) === fixedFloat(migrateData.migrateTotal.credit, 4)) {
      this.setState({ showErrorMessage: false });
      return true;
    } else {
      this.setState({ showErrorMessage: true });
      return false;
    }
  };

  handleErrorMessage = () => {
    this.setState({ showErrorMessage: false });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({ dialogType: ACTION.NULL, fymGroup: fymMapper({}) });
  };

  handleDownloadClick = (reportType = 'csv') => {
    const queryParameter = this.basePaginationService.stateGetter();
    const { downloadList } = this.props;
    const { fymGroup, dataMigrate } = this.state;
    const newQuery = { ...queryParameter, date: { start: dataMigrate.startDate, end: dataMigrate.endDate } };
    downloadList({
      type: reportType,
      query: newQuery,
      extraQueryString: `&title=${fymGroup.title}&account_group=${fymGroup.accountGroup}&show_stock=${fymGroup.showStock}`,
    }).then(response => downloadService.resolver(response));
  };

  handleInputChange = (field, value) => {
    if (field === 'showStock') {
      this.getFYMSummary(value);
      this.handleErrorMessage();
    }
    const { fymGroup } = this.state;
    fymGroup[field] = value;
    this.setState({ fymGroup });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const {
      data,
      display,
      snack,
      dialogType,
      dataMigrate,
      fymGroup,
      migrateData,
      validation,
      accountGroupList,
      showErrorMessage,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <FYIStyled>
        <PageView
          headerConfig={{
            search: false,
            date: false,
            download: false,
            filter: false,
            create: false,
          }}
          footerConfig={{
            pagination: false,
          }}
          data={data}
          display={display}
          tableConfig={tableConfig}
          controlDisplay={this.controlDisplay}
          onTableBodyClick={this.handleEditIconClick}
          serverResponseWaiting={serverResponseWaiting}
          domainConfig={FISCAL_YEAR_MIGRATION_CONFIG}
          handleTableSorting={this.basePaginationService.handleTableSorting}
        />
        <Dialog
          data={dataMigrate}
          fymGroup={fymGroup}
          actionType={dialogType}
          migrateData={migrateData}
          validationFlag={validation.flag}
          accountGroupList={accountGroupList}
          onModalClose={this.handleModalClose}
          onInputChange={this.handleInputChange}
          onModalSubmit={this.handleModalSubmit}
          handleErrorMessage={this.handleErrorMessage}
          showErrorMessage={showErrorMessage}
          domainConfig={{
            ...FISCAL_YEAR_MIGRATION_CONFIG,
            title: downLoadFYMSumaryTitle || '',
          }}
          handleDownloadClick={this.handleDownloadClick}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </FYIStyled>
    );
  }
}

export default FiscalYearMigration;
