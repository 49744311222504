import { STRING_TYPE } from '../data/enums/enums';

const isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

/** const generateString = (obj,type, separator = ' ') => {
  debugger;
  // eslint-disable-next-line max-len
  const processedString = Object.keys(obj).reduce((accumulator, currentValue, currentIndex, array) => {
    debugger;
    if(type === STRING_TYPE.query) {
      if(currentIndex === 0) {
        accumulator+='?';
      }
      else accumulator+='&';
      return accumulator+`${array[currentIndex]}=${obj[array[currentIndex]]}`;
    }
    return accumulator+`${obj[array[currentIndex]]}${separator}`;

  }, '');
  debugger;
  return processedString;
} */

const generateString = null;

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

const keysToCamel = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

const checkIfObject = (value) => typeof value === 'object';

export {
  isObjectEmpty, generateString, keysToCamel, checkIfObject
};
