import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const downloadFormat = [{ id: 1, value: 'Download Stock Ledger CSV' }];

const StockLedgerTableBody = ({ data }) => {
  return (
    <tr>
      {/*   <td className="left-align">1</td>*/}
      <td className='left-align'>{data.title}</td>
      <td className='right-align'>
        {data.openingBalance ? data.openingBalance.toLocaleString() : data.openingBalance}
      </td>
      <td className='right-align'>{data.inward ? data.inward.toLocaleString() : data.inward}</td>
      <td className='right-align'>{data.outward ? data.outward.toLocaleString() : data.outward}</td>
      <td className='right-align'>
        {data.closingBalance ? data.closingBalance.toLocaleString() : data.closingBalance}
      </td>
    </tr>
  );
};

StockLedgerTableBody.propTypes = { data: PropTypes.object.isRequired, };

const stockLedgerConfig = {
  headerDetails: [
    { id: 2, label: 'sku', title: 'SKU', className: 'cursor-pointer', sortable: true },
    {
      id: 3,
      label: 'openingBalance',
      title: 'Opening Balance',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    { id: 4, label: 'inward', title: 'Inward', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'outward', title: 'Outward', className: 'cursor-pointer right-align', sortable: true },
    {
      id: 6,
      label: 'closingBalance',
      title: 'Closing Balance',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
  ],
};

export {
  stockLedgerConfig, StockLedgerTableBody, downloadFormat
};

{
  /*<td className="left-align">{data.idStockJournal}</td>
      <td className="left-align">{moment(data.date).format('ll')}</td>
      <td className="left-align">{data.remarks}</td>*/
}
