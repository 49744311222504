import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const propTypes = { data: PropTypes.instanceOf(Array) };

const defaultProps = { data: [] };

const ProfitLossChildView = ({ data }) => (
  <Fragment>
    {data.map((d) => (
      <tr>
        <td className='left-align'>{d.particulars}</td>
        <td className='right-align'>{fixedFloatAndCommas(d.balance)}</td>
      </tr>
    ))}
  </Fragment>
);

ProfitLossChildView.propTypes = propTypes;

ProfitLossChildView.defaultProps = defaultProps;

export default ProfitLossChildView;
