import React from 'react';
import Loading from '../../../common/Loading';
import DetailTableView from '../detail/DetailTableView';
import RadioButtonList from '../../../components/RadioButtonList/RadioButtonList';
import * as filterUtil from '../../../utils/filterUtil';
import PageButtonWrapper from '../PageButtonWrapper';

const defaultProps = {
  render: { btnWrapper: () => <></>, },
  tableAction: {},
  buConfig: {
    list: [],
    status: true,
    defaultValue: '',
    handler: () => null,
  },
};

const refs = { tableHeader: React.createRef(), };

const CreateTemplate = ({
  display, // header, summary, footer
  loading,
  headerConfig,
  buConfig,
  render,
}) => {
  return (
    <div ref={refs.tableHeader}>
      <Loading display={loading} />
      <div className={loading ? 'clickable-false' : ''}>
        <div className='card-header-bar clearfix'>
          <div className='header-left'>
            <h2>{headerConfig.title}</h2>
          </div>
          <div className='header-right'>{headerConfig.date || filterUtil.getCurrentDay()}</div>
        </div>
        <div className='card-body'>
          {display.header && (
            <div>
              {render.header ? (
                render.header()
              ) : (
                <div className={`radio-list-wrapper ${!buConfig.status ? 'no-pointer-events' : ''}`}>
                  {buConfig.list.length > 0 && (
                    <RadioButtonList
                      data={buConfig.list}
                      handleBuClick={buConfig.handler}
                      defaultValue={buConfig.defaultValue}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {render.body ? render.body() : <div> this is body </div>}
        </div>
        {display.btnWrapper && <PageButtonWrapper renderBtn={render.btnWrapper} />}
      </div>
    </div>
  );
};

export default CreateTemplate;
