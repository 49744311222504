import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Fab, Icon, Textfield } from 'react-mdc-web';

import InvoiceDetails from './detail';
import history from '../../../utils/history';
import Download from '../../common/Download';
import * as appConfig from '../../../config';
import Loading from '../../../common/Loading';
import * as httpUtils from '../../../utils/httpUtils';
import Pagination from '../../../components/Pagination';
import DrawerWithFilter from '../../common/DrawerFilter';
import TableHeader from '../../../components/TableHeader';
import * as queryService from '../../common/query.service';
import * as printService from '../../common/print.service';
import DateToRender from '../../../components/DateToRender';
import * as tableLayoutService from '../../common/tableLayout';
import { salesInvoiceHeader, SalesInvoiceTableBody } from './config';
import { SALES_INVOICE_CONFIG } from '../../common/domain.config';
import { SALES_CREATE } from '../../../data/enums/Route';
import PageHeaderStyled from '../../common/pagination/PageHeaderStyled';
import CreateBtn from '../../common/pagination/CreateBtn/CreateBtn';

const salesId = '';

class SalesInvoice extends Component {
  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);
  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };
  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;

    getList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.totalCount;
      this.setState({ data });
    });
  };
  resetFilter = () => this.bibekQueryService.resetFilter();
  onPageSelect = (page, limit) => this.bibekQueryService.onPageSelect(page, limit);
  handleDateRangeChange = (start, end) => this.bibekQueryService.handleDateRangeChange(start, end);
  handleDateChange = date => this.bibekQueryService.handleDateRangeChange(date);
  handleFilterChange = (field, list) => this.bibekQueryService.handleFilterChange(field, list);
  handleTableSorting = (label, order) => this.bibekQueryService.handleTableSorting(label, order);
  handleSearchChange = value => this.bibekQueryService.handleSearchInputChange(value);
  handleDownloadClick = (reportType = 'csv') => this.bibekQueryService.handleDownloadClick(reportType);
  handleFabButtonClick = () => history.push(`${SALES_CREATE}`);
  onSalesInvoiceItemClick = data => {
    const { print } = this.state;
    print.start = true;
    print.id = data.invoiceNumber;
    const { user } = this.props;
    const printServiceResponse = printService.dataProcessor(data, user.idUsers);
    print.details = printServiceResponse.printDetails;
    print.info = printServiceResponse.printInfo;
    this.setState({ print });
  };

  handlePostPrint = () => {
    const { print } = this.state;
    print.start = false;
    this.setState(print);
  };

  clearSearchText = () => this.bibekQueryService.clearSearchText();
  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  constructor(props) {
    super(props);
    this.state = {
      display: {
        filter: false,
        menu: false,
        searchBox: false,
      },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          business_id: [],
        },
      },
      data: {
        list: [],
        total: 1,
      },
      print: {
        id: '',
        start: false,
        info: [
          { title: 'Printed On', value: '' },
          { title: 'Printed Time', value: '' },
          {
            title: 'Print Count',
            value: '',
          },
          { title: 'Printed By', value: '' },
        ],
        details: {
          transaction: '',
          invoiceNumber: '',
          printedBy: '',
        },
      },
    };

    const { downloadList } = this.props;

    this.bibekQueryService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getTableDataForGrid,
      downloadList,
    );
    this.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.getTableDataForGrid();
    tableLayoutService.adjustToScreen(this.refs.tableReference, this.refs.fixedTableFooter, this.refs.fixedTableBody);
  }

  render() {
    const { queryParameters, data, print, display } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <>
        <Loading display={serverResponseWaiting} />
        {!print.start && (
          <div className={`sales-invoice${serverResponseWaiting ? 'clickable-false' : ''}`}>
            <PageHeaderStyled>
              <div className="card-header-bar" ref="tableReference">
                <h2>Sales Invoice - No Print Count</h2>
                <div className="header-menu">
                  <div className="header-menu-left ">
                    <span
                      className="search-icon-wrapper"
                      onClick={() => this.controlDisplay('searchBox', !display.searchBox)}
                    >
                      <Icon name="search" className="search-icon material-icons" />
                    </span>
                    <span
                      className={`top-search-input-wrapper ${display.searchBox ? 'input-active' : 'input-inactive'}`}
                    >
                      {display.searchBox && (
                        <Textfield
                          value={queryParameters.search}
                          onChange={e => {
                            this.handleSearchChange(e.target.value);
                          }}
                          autoFocus
                        />
                      )}
                      {queryParameters.search && (
                        <Icon
                          name="close"
                          className="cross-icon material-icons"
                          onClick={() => this.clearSearchText()}
                        />
                      )}
                    </span>
                  </div>
                  <div className="header-menu-right">
                    <span className="date-rendered">
                      <Icon name="date_range" className="material-icons" />
                      <DateToRender date={queryParameters.date} />
                    </span>

                    <Download config={SALES_INVOICE_CONFIG} onClick={this.handleDownloadClick} />

                    <span onClick={() => this.controlDisplay('filter', true)}>
                      <Icon name="filter_list" />
                    </span>
                  </div>
                </div>
              </div>
            </PageHeaderStyled>

            <div ref="fixedTableBody" className="fixed-table-wrapper end-list-table">
              <table>
                <TableHeader headerDetails={salesInvoiceHeader.headerDetails} handleSorting={this.handleTableSorting} />

                <SalesInvoiceTableBody dataList={data.list} onRowClick={this.onSalesInvoiceItemClick} />
              </table>
            </div>
            <CreateBtn createHandler={this.handleFabButtonClick} />
            <div ref="fixedTableFooter">
              <Pagination
                pageSize={queryParameters.pagination.limit}
                currentPage={queryParameters.pagination.page}
                orientation="top"
                totalItems={data.total}
                onPageSelect={this.onPageSelect}
              />
            </div>

            {/* <div className="create-fab-button">
                <Fab onClick={() => this.handleFabButtonClick()}>
                  <Icon name="add" />
                </Fab>
              </div> */}
          </div>
        )}
        {print.start && (
          <InvoiceDetails
            id={print.id}
            firstCopy={true}
            printInfo={print.info}
            postPrintHandler={this.handlePostPrint}
          />
        )}

        <DrawerWithFilter
          display={display.filter}
          displayController={this.controlDisplay}
          resetFilter={this.resetFilter}
          queryParameters={queryParameters}
          onDateRangeChange={this.handleDateRangeChange}
          handleFilterChange={this.handleFilterChange}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
  bu_id: state.billing.bu_id || null,
});
const SalesInvoiceWithState = connect(mapStateToProps)(SalesInvoice);

export default SalesInvoiceWithState;
