import React, { Component } from 'react';
import { connect } from 'react-redux';
import tableConfig from './config';
import history from '../../../utils/history';
import { clone } from '../../../utils/arrayProcessor';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { STOCK_LEDGER_CONFIG } from '../../common/domain.config';
import { STOCK_LEDGER_DETAILS } from '../../../data/enums/Route';
import { setStockLedgerData } from '../../../actions';
import * as downloadService from '../../common/download.service';
import StockLedgerStyled from './StockLedgerStyled';
import TableHeader from '../../../components/TableHeader/TableHeader';
import * as filterUtil from '../../../utils/filterUtil';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { Grid, Cell, Icon } from '../../../components/BillingMDC';
import {flattenDataPrintAttributeWise, groupPrintDataSet} from "../../common/print.service";
import {storeInLocalStorage ,fetchFromLocalStorage} from "../../common/common";

class StockLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          business_id: [],
          catalog_detail_id: [],
        },
      },
      data: {
        list: [],
        total: 0,
      },
      dataList: [],
      printData: [],
    };
    this.dataListLength = 0;

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.getFilterStateFromStorage();
    window.addEventListener('keydown', this.handleKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
  setFilterStateInStorage = () => {
    const { queryParameters,display} = this.state;
    storeInLocalStorage(
      'DISPLAY',
      display,
      'STOCK_LEDGER'
    );
    storeInLocalStorage(
      'FILTER',
      {
       queryParameters,
      },
      "STOCK_LEDGER"
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters,display} =this.state;
    const localData = fetchFromLocalStorage('FILTER', "STOCK_LEDGER");
    const localDisplay = fetchFromLocalStorage('DISPLAY', 'STOCK_LEDGER');
    this.setState(
      { queryParameters:localData?.queryParameters || queryParameters ,
        display:{...localDisplay,filter:false} || display},
      () => {
        this.loadTableData();
      },
    );
  };
  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handleDownloadClick = (type, label) => {
    const { queryParameters } = this.state;
    const { downloadList, downloadDetail } = this.props;

    if (label === 'stock_ledger_batch_details') {
      downloadDetail({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    } else {
      downloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    }
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = async (print=false) => {
    const { getList } = this.props;
    const { queryParameters,data } = this.state;  this.setFilterStateInStorage();
    let query = clone(queryParameters);
    query.pagination.limit = 2000;
    query.pagination.page=1;
    await getList( print? query:queryParameters)
      .then(response => {
        data.list = response.data || [];
        data.total = response.total || 0;
        const formattedList = flattenDataPrintAttributeWise(response.data, tableConfig.lengthyAttributes, tableConfig.getData);
        const printData = formattedList || [];
        this.setState({ data ,printData},() =>{
          print && this.groupPrintDataSetNew();
        });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage();
      });
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handlePrintClick = async() => {
    const self = this;
    await this.loadTableData(true);
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.loadTableData()
      self.setState({ printButtonClicked: false });
    };
  };
  groupPrintDataSetNew() {
    const { printData} = this.state;
    const { company  } = this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(printData);
    const dataList = groupPrintDataSet(orders, printInfoBill, tableConfig);
    this.setState({ dataList });
  }

  handleRowClick = data => {
    const { queryParameters } = this.state;
    const { storeStockLedger } = this.props;
    storeStockLedger(setStockLedgerData({ row: data, date: queryParameters.date }));
    history.push(`/${STOCK_LEDGER_DETAILS}/${data.skuId}`);
  };
  handleClearSearch =() =>{
    const {display}=this.state;
    this.setState({display:{...display,searchBox:false,search:false}});
    this.basePaginationService.clearSearchText();
  }
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        business_id: [],
          catalog_detail_id: [],
      },
    });
  }

  render() {
    const { queryParameters, data, display, snack, printButtonClicked, dataList, date } = this.state;
    const { serverResponseWaiting, company } = this.props;
    return (
      <StockLedgerStyled className="stock-ledger">
        <div className={printButtonClicked ? 'no-print' : 'display-block'}>
          <PageView
            domainConfig={STOCK_LEDGER_CONFIG}
            headerConfig={{
              search: true,
              date: true,
              download: true,
              filter: true,
              create: false,
              print: true,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.handleClearSearch}
            resetFilter={this.handleResetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.handleDownloadClick}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            data={data}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.handleRowClick}
            onPageSelect={this.basePaginationService.onPageSelect}
            handlePrintClick={this.handlePrintClick}
          />
        </div>

        <div className={printButtonClicked ? 'display-block portrait-type' : 'no-print'}>
          <div>
            {dataList.map((chunk, key) => {
              const { list = [], page = 0,remainingLines=[] } = chunk;
              return (
                <div className="print-body zoom-reduce">
                  <div className="pickList-body card-body">
                    <header>
                      <Grid className="flex">
                        <Cell col={2} tablet={2} phone={2} className="left">
                          <div className="header-title">{company.title}</div>
                          <span className="header-title-content">
                            <span className="active-opacity-text">Address: </span>
                            <span>{company.address}</span>
                          </span>
                          <span className="header-title-content">
                            <span className="active-opacity-text">Phone: </span>
                            <span>{company.phone}</span>
                          </span>
                          <span className="no-margin-bottom header-title-content">
                            <span className="active-opacity-text">PAN No: </span>
                            <span>{company.panNumber}</span>
                          </span>
                        </Cell>
                        <Cell col={4} tablet={4} phone={4} className="center">
                          <div className="center-align">
                            <h2>{STOCK_LEDGER_CONFIG.title}</h2>
                            <div className="default-margin-top-3">
                              <span className="header-title-content no-margin-bottom">
                                <span className="active-opacity-text">
                                  Report from
                                  <span className="date-rendered">
                                    {` ${filterUtil.formatToReadableDate(
                                      queryParameters.date.start,
                                    )} - ${filterUtil.formatToReadableDate(queryParameters.date.end)}`}
                                  </span>
                                </span>
                              </span>
                              <span className="header-title-content"></span>
                            </div>
                          </div>
                        </Cell>
                        <Cell col={2} tablet={2} phone={2} className="right">
                          <div className="header-menu-right">
                            <span>
                              <span className="">Date: </span>
                              <span>{filterUtil.formatToReadableDate(date)}</span>
                            </span>
                          </div>
                        </Cell>
                      </Grid>
                    </header>
                    <div className="fixed-table-wrapper">
                      <table className="pdf-table">
                        <TableHeader
                            headerDetails={tableConfig.header.printHeader}
                            filterHeaderLabel={false}
                        />
                        {tableConfig.getTablePrintBody({ dataList: list ,remainingLines })}
                      </table>
                    </div>
                  </div>
                  <div className="footer-block">
                    <PageNumber value={page} totalPage={dataList.length} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </StockLedgerStyled>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeStockLedger: action => dispatch(action),
  };
}

const StockLedgerWithState = withBaseState(StockLedger);

export default connect(null, mapDispatchToProps)(StockLedgerWithState);
