import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as httpUtils from '../../../utils/httpUtils';
import Pagination from '../../../components/Pagination';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import DatePicker from '../../../components/DatePicker/DatePicker';
import DateToRender from '../../../components/DateToRender';
import TableHeader from '../../../components/TableHeader';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { Icon, Cell, Grid, Drawer, LinearProgress } from 'react-mdc-web';
import Select from 'react-select';
import { ageingReportConfig, AgeingReportTableBody } from './AgeingReportConfig';
import { getFixTableMeasurements } from '../../../utils/tableMetrics';
import * as filterUtil from '../../../utils/filterUtil';
import * as appConstants from '../../config';
import cloneDeep from 'lodash/cloneDeep';
import '../../../styles/printStyle.css';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import { has } from '../../../utils/hasOwnProperty';

const AS_ON_DATE = 'As on Date';
const DEFAULT_SLAB_NO = 7;
const SLAB_COLUMN = 5;
const SLAB_INTERVAL_CONFIG = [
  { id: 1, title: '2 days', value: 2 },
  { id: 2, title: '5 days', value: 5 },
  { id: 3, title: '7 days', value: 7 },
  { id: 4, title: '15 days', value: 15 },
  { id: 5, title: '30 days', value: 30 },
];
let headerTemplateObj = { id: '', label: '', title: '', className: 'cursor-pointer right-align', sortable: true };
let grandTotal = 0;

class AgeingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showMenu: false,
      totalData: 1,
      dataSet: [],
      businessUnitId: '',
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
          reportDate: filterUtil.getCurrentDay(),
        },
        filter: {},
      },
      slab_no: DEFAULT_SLAB_NO,
      dynamicHeaderList: [],
      headerList: cloneDeep(ageingReportConfig.headerDetails),
      buTotal: {
        grandTotal: { title: 'Grand Total', value: 0 },
      },
      customerDetails: {
        customerId: '',
        customerTitle: '',
      },
    };
  }

  /** adjust table width and height according to screen **/
  componentDidMount() {
    this.getFiscalYear();
    this.generateHeaders(SLAB_INTERVAL_CONFIG[2].value, SLAB_COLUMN);
    this.getTableDataForGrid();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    let table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
  }

  // get the customer list for the selection.
  getCustomerList = () => {
    this.setState({ loading: true });
    const type = 'all';
    httpUtils.get(`${appConstants.baseUrl}ledger/customer-list?type=${type}`).then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        let responseDataList = response.data;
        responseDataList.map(customer => {
          customer['label'] = customer.customerId + '. ' + customer.name;
        });
        this.setState({ customerList: responseDataList });
      }
    });
  };

  getFiscalYear = () => {
    this.setState({ loading: true });
    const { queryParameters } = this.state;
    httpUtils.get(`${appConstants.baseUrl}period/single`).then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        queryParameters.date.start = has.call(response.data.list, 'startDate')
          ? response.data.list.startDate
          : this.state.queryParameters.date.start;
        this.setState({ queryParameters });
      }
    });
  };

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    this.setState({ loading: true });
    const { queryParameters } = this.state;
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    queryParameters.date['report_date'] = moment(queryParameters.date.reportDate).format('YYYY-MM-DD');
    let urlString = `${appConstants.baseUrl}ageing-report/paginated-list?page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}&start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&report_date=${queryParameters.date.report_date}&slab_no=${this.state.slab_no}`;
    httpUtils
      .get(urlString)
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data.list, totalData: response.data.total, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
  generateHeaders = (slabNumber = 7, totalColumn = 5) => {
    const endValue = slabNumber * totalColumn;
    const originalHeaderDetails = cloneDeep(ageingReportConfig.headerDetails);
    let dynamicHeadersList = [];
    let extraHeaderList = [];
    let previousValue = 0;
    let stringValue = '';
    let iteration = 1;
    let headerTemplate = cloneDeep(headerTemplateObj);
    for (let count = slabNumber; iteration < totalColumn; count = count + slabNumber) {
      headerTemplate = cloneDeep(headerTemplateObj);
      stringValue = `${previousValue} - ${count}`;
      headerTemplate.id = iteration;
      headerTemplate.label = `slab${iteration}`;
      headerTemplate.title = stringValue;
      dynamicHeadersList.push(stringValue);
      extraHeaderList.push(headerTemplate);
      previousValue = cloneDeep(count) + 1;

      iteration++;
    }
    headerTemplate = cloneDeep(headerTemplateObj);
    stringValue = `> ${previousValue - 1}`;
    headerTemplate.id = iteration;
    headerTemplate.label = `slab${iteration}`;
    headerTemplate.title = stringValue;
    dynamicHeadersList.push(stringValue);
    extraHeaderList.push(headerTemplate);
    const headerList = [...originalHeaderDetails, ...extraHeaderList];
    this.setState({ headerList });
  };

  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getTableDataForGrid());
  };

  onChange = (fieldName, event, value) => {
    this.setState({ [fieldName]: event[value] }, () => {
      this.generateHeaders(this.state.slab_no, SLAB_COLUMN);
      this.getTableDataForGrid();
    });
  };

  /** on Date Range Change */
  handleDateRangeChange = (start, end) => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  handleDateChange = date => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.reportDate = date.format('D MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  handleDownloadClick = () => {
    const type = 'CSV';
    const self = this;
    const schema = 'https://';
    const { queryParameters } = this.state;
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    queryParameters.date['report_date'] = moment(queryParameters.date.reportDate).format('YYYY-MM-DD');
    httpUtils
      .get(
        appConstants.baseUrl +
          `download-ageing-report?&start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&report_date=${queryParameters.date.report_date}&slab_no=${this.state.slab_no}&type=${type}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ loading: false });
          const url = response.data.url;
          const newWin = window.open(schema + window.location.hostname + url, 'Download');
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            alert('Please enable pop for this site');
          }
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.dataSet;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';
    return orderBy(this.state.dataSet, labelName, sortingName);
  };

  render() {
    const { loading, queryParameters, totalData, sorting, dataSet } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    return (
      <div>
        <div>
          {loading && (
            <div className="linear-progress-wrapper temp-progress-wrapper">
              <LinearProgress accent indeterminate />
            </div>
          )}
          <div className={this.state.printButtonClicked ? 'no-print' : 'display-block'}>
            <div className={loading ? 'clickable-false' : ''}>
              <div className="header-bar" ref="tableReference">
                <Grid>
                  <Cell col={2} className="input-select">
                    <label>Ageing Slab</label>
                    <Select
                      name="form-field-name"
                      clearable={false}
                      value={this.state.slab_no}
                      valueKey="value"
                      options={SLAB_INTERVAL_CONFIG}
                      labelKey="title"
                      onChange={e => this.onChange('slab_no', e, 'value')}
                    />
                  </Cell>
                  <Cell col={2}></Cell>
                  <Cell col={4}>
                    <div className="center-align">
                      <h2>Ageing Report</h2>
                      <div className="default-margin-top-12">
                        <span className="header-title-content no-margin-bottom">
                          <span className="active-opacity-text">
                            Report from:{' '}
                            <span className="date-rendered">
                              <DateToRender date={queryParameters.date} />
                            </span>
                          </span>
                        </span>
                        <span className="header-title-content">
                          <span className="active-opacity-text">
                            As on Date:{' '}
                            <span className="date-rendered">
                              <span>{queryParameters.date.reportDate}</span>
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </Cell>
                  <Cell col={4}>
                    <div className="header-menu-right">
                      <span className="download-menu" onClick={() => this.handleDownloadClick()}>
                        <Icon name="get_app" />
                      </span>
                      <span onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                        <Icon name="filter_list" />
                      </span>
                    </div>
                    <div className="header-menu-right">
                      {/*<span className="header-right-content">
                     <span className="">Date: </span>
                     <span>{this.state.queryParameters.date.end}</span>
                    </span>*/}
                    </div>
                  </Cell>
                </Grid>
              </div>
            </div>

            <div ref="fixedTableBody" className="fixed-table-wrapper">
              <table>
                <TableHeader
                  headerDetails={this.state.headerList}
                  filterHeaderLabel={false}
                  handleSorting={this.handleTableSorting}
                />
                {sortedDataSet.map((data, key) => (
                  <tbody>
                    <AgeingReportTableBody data={data} />
                  </tbody>
                ))}

                {/*<tfoot className='right-content-footer'>
                {
                  <OutstandingTotal totalData={this.state.buTotal.grandTotal} />
                }
                </tfoot>*/}
              </table>
            </div>

            {
              <div ref="fixedTableFooter">
                <Pagination
                  pageSize={queryParameters.pagination.limit}
                  currentPage={queryParameters.pagination.page}
                  orientation="top"
                  totalItems={totalData}
                  onPageSelect={this.onPageSelect}
                />
              </div>
            }
          </div>
        </div>
        <div dir="rtl">
          <Drawer
            open={this.state.showFilter}
            className="right-drawer"
            onClose={() => {
              this.setState({ showFilter: false });
            }}
          >
            <div className="filter-wrapper">
              <h2 className="default-horizontal-padding-24">
                <span>Filter</span>
                <span className="float-right">
                  <Icon name="replay" onClick={() => this.resetFilter()} />
                  <Icon name="clear" onClick={() => this.setState({ showFilter: false })} />
                </span>
              </h2>
              <div className="divider"></div>
              <DateRangePicker
                startDate={moment(queryParameters.date.start)}
                endDate={moment(queryParameters.date.end)}
                onChange={this.handleDateRangeChange}
              />
              <div className="padding-top-24">
                <div className="">
                  <DatePicker
                    date={moment(queryParameters.date.reportDate)}
                    showLabel={true}
                    showIcon={true}
                    label={AS_ON_DATE}
                    onChange={this.handleDateChange}
                  />
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

AgeingReport.contextTypes = {
  router: PropTypes.object,
};

export default AgeingReport;
