import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import BaseView from './View';
import history from '../../utils/history';
import {DOWNLOAD_SAMPLE_FILE_URL, HELP_FILE, REPORT_TYPE} from '../../data/enums/enums';
import * as downloadService from '../common/download.service';
import { JWT } from '../../environment';

const propTypes = {
  company: PropTypes.object,
  user: PropTypes.object.isRequired,
  downloadDatabase: PropTypes.func.isRequired,
  commonDownloadResolver: PropTypes.func.isRequired,
  serverResponseError: PropTypes.string.isRequired,
  serverResponseWaiting: PropTypes.bool.isRequired,
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerMiniState: true,
    };
  }

  handleDatabaseDownloadClick = () => {
    const { user, downloadDatabase } = this.props;
    downloadDatabase({
      type: REPORT_TYPE.CSV,
      query: `user_id=${user.idUsers}`,
    }).then(response => {
      downloadService.resolver(response);
    });
  };

  handleInfoClick = () => {
    const newWin = window.open(`${DOWNLOAD_SAMPLE_FILE_URL}/${HELP_FILE}`);
    if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
      alert('Please enable pop for this site');
    }
  };

  handleLogOutClick = () => {
    localStorage.clear();
    localStorage.removeItem(JWT.LOCAL_STORAGE.TOKEN.NAME);
    localStorage.removeItem(JWT.LOCAL_STORAGE.APP_STORAGE.NAME);
    history.push('/');
  };

  toggleDrawerState = () => {
    const { drawerMiniState } = this.state;
    this.setState({ drawerMiniState: !drawerMiniState });
  };

  render() {
    const { drawerMiniState } = this.state;
    const { user, company, serverResponseWaiting, serverResponseError } = this.props;

    return (
      <BaseView
        user={user}
        company={company}
        drawerState={drawerMiniState}
        loading={serverResponseWaiting}
        errorMessage={serverResponseError}
        onInfoClick={this.handleInfoClick}
        onLogOutClick={this.handleLogOutClick}
        onInputChange={this.onFormInputChange}
        toggleDrawerState={this.toggleDrawerState}
        onDownloadClick={this.handleDatabaseDownloadClick}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
});

App.contextTypes = {
  router: PropTypes.object,
};

App.propTypes = propTypes;

const Application = connect(mapStateToProps)(App);

export default Application;
