import styled from 'styled-components';

const AreaStyled = styled.div`
table{
  th,td{
    &.edit,
    &.delete{
      width:40px;
      i{
        margin-left:0;
      }
    }
  }
  thead{
    th{
      z-index:2;
    }

  }
  table{

  }
}
`;
export default AreaStyled;
