const getConfigData = () => {
  const columns = [
    [
      { content: 'SN' },
      { content: 'Customer Name' },
      { content: 'Voucher No.' },
      { content: 'Doc Date' },
      { content: 'Bill Amount', styles: { halign: 'right' } },
      { content: 'Outstanding Balance', styles: { halign: 'right' } },
      { content: 'Due Date' },
      { content: 'Bill Age' },
    ],
  ];

  const columnStyles = {
    0: { cellWidth: 10 },
    2: { cellWidth: 31 },
    3: { cellWidth: 15 },
    4: { cellWidth: 18 },
  };

  const cellsToAlignRight = { 4: '4', 5: '5' };

  return {
    columns,
    columnStyles,
    cellsToAlignRight,
  };
};

export default getConfigData;
