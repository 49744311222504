import React from 'react';
import { Icon } from '../../../components/BillingMDC';
import { balanceType } from '../../../v1/views/Configuration/Ledgers/LedgersConfig';
import { LEDGER_TYPE, ACTION, LEDGERS } from '../../../data/enums/enums';
import { VALIDATION_TYPES } from '../../common/validation';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { has } from '../../../utils/hasOwnProperty';

const defaultOpeningBalanceInfo = [{ credit: null, debit: null, type: balanceType[1].value }];

const getCustomerData = (ledger = {}) => {
  const openingBalanceInfo = ledger.openingBalanceInfo
    ? ledger.openingBalanceInfo.length > 0
      ? ledger.openingBalanceInfo
      : defaultOpeningBalanceInfo
    : defaultOpeningBalanceInfo;
  const openingBalance = openingBalanceInfo[openingBalanceInfo.length - 1];

  const details = {
    title: ledger.title || '',
    address: ledger.address || '',
    areaId: ledger.areaId || null,
    salesPersonId: ledger.salesPersonId || null,
    city: ledger.city || '',
    state: ledger.state || '',
    panNo: Number(ledger.panNo) || null,
    country: ledger.country || '',
    creditDay: ledger.creditDay || 0,
    phoneNumber: ledger.phoneNumber || 0,
    creditLimit: ledger.creditLimit || 0,
    openingBalance: openingBalance.amount || 0,
    typeId: ledger.typeId || LEDGER_TYPE[0].value,
    openingBalanceType: openingBalance.type,
    accountGroup: ledger.accountGroup,
    accountGroupTitle: ledger.accountGroupTitle,
    isCashBankLedger: ledger.isCashBankLedger ? ledger.isCashBankLedger : false,
    insertOb: ledger.insertOb || false,
    customerId: ledger.customerId || null,
    obChanged: ledger.obChanged || false,
    status: has.call(ledger, 'status') ? ledger.status : 1,
  };

  if (ledger.idLedger) {
    details.idLedger = Number(ledger.idLedger);
  }

  return details;
};


const getApiTransformedData = (ledger, action = ACTION.ADD) => {
  const details = {
    title: ledger.title || '',
    address: ledger.address || '',
    area_id: ledger.areaId || null,
    sales_person_id: ledger.salesPersonId || null,
    city: ledger.city || '',
    state: ledger.state || '',
    pan_no: ledger.panNo || 0,
    country: ledger.country || 'Default',
    credit_day: ledger.creditDay || 0,
    phone_number: (ledger.phoneNumber)?.toString() || '',
    credit_limit: ledger.creditLimit || 0,
    type_id: ledger.typeId || null,
    account_group: ledger.accountGroup || 0,
    isCashBankLedger: ledger.isCashBankLedger || false,
    opening_balance: ledger.openingBalance || 0,
    opening_balance_type: ledger.openingBalanceType || balanceType[1].value,
    status: ledger.status || 0,
  };

  if (action === ACTION.ADD) {
    details.opening_balance = ledger.openingBalance || 0;
    details.opening_balance_type = ledger.openingBalanceType;
  }
  if (action === ACTION.UPDATE) {
    details.customer_id = ledger.customerId;
    details.ob_changed = ledger.obChanged;
  }

  return details;
};

const formValidationFieldList = [
  {
    title: 'title',
  },
  {
    title: 'accountGroup',
  },
];

const formWithPANValidation = [
  ...formValidationFieldList,
  {
    title: 'panNo',
    type: VALIDATION_TYPES.PAN_NO,
  },
];
const resolveLedgerType = (typeId) => {
  const ledgerType = LEDGER_TYPE.find((el) => el.value === typeId);

  return ledgerType ? ledgerType.title : '';
};

const CustomerLedgerBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr>
        <td className="left-align">{data.customerId}</td>
        <td className="left-align">{data.title}</td>
        <td className="left-align">{data.address}</td>
        <td className="left-align">{data.area}</td>
        <td className="left-align">{data.salesPerson}</td>
        <td className="left-align">{data.panNo === '0' ? '' : data.panNo}</td>
        <td className="left-align">{data.phoneNumber === '0' ? '' : data.phoneNumber}</td>
        <td className="left-align">{data.creditDay || ''}</td>
        <td className="left-align">{fixedFloatAndCommas(data.creditLimit) || ''}</td>
        <td className="left-align">{data.accountGroupTitle}</td>
        <td className="right-align">
          <Icon name="edit" onClick={() => onRowClick(data, index)} />
        </td>
      </tr>
    ))
  }
  </tbody>
);

const customerLedgerConfig = [
  {
    id: 1, label: 'customer_id', title: 'Customer ID', className: 'cursor-pointer left-align', sortable: true,
  },
  {
    id: 2, label: 'title', title: 'Title', className: 'cursor-pointer', sortable: true,
  },
  {
    id: 3, label: 'address', title: 'Address', className: 'cursor-pointer', sortable: true,
  },
  {
    id: 11, label: 'address', title: 'Area', className: 'cursor-pointer', sortable: true,
  },
  {
    id: 11, label: 'address', title: 'Sales Person', className: 'cursor-pointer', sortable: true,
  },
  {
    id: 4, label: 'pan_no', title: 'PAN No', className: 'cursor-pointer', sortable: true,
  },
  {
    id: 5, label: 'phone_number', title: 'Phone No', className: 'cursor-pointer', sortable: true,
  },
  {
    id: 6, label: 'credit_day', title: 'Credit Day', className: 'cursor-pointer', sortable: true,
  },
  {
    id: 7, label: 'credit_limit', title: 'Credit Limit', className: 'cursor-pointer', sortable: true,
  },
  {
    id: 9, label: 'accountGroupTitle', title: 'Account Group', sortable: false,
  },
  {
    id: 10, label: 'action', title: '', className: 'right-align', sortable: false,
  },
];

const ledgerStatus = [
  { status: 1, name: 'Active' },
  { status: 0, name: 'Inactive' },
];

const config = {
  header: customerLedgerConfig,
  getTableBody: CustomerLedgerBody,
  getDetail: getCustomerData,
  getApiTransformedData,
  formValidationFieldList,
  formWithPANValidation,
  ledgerStatus,
};

export default config;
