import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const OpeningBalanceTableBody = ({ data }) => {
  return (
    <tr>
      <td className='left-align'>{data.idOpeningBalance}</td>
      <td className='left-align'>{data.businessInfo.businessAlias}</td>
      <td className='left-align'>{data.skuDetail ? data.skuDetail.title : null}</td>
      <td className='right-align'>{data.quantity}</td>
    </tr>
  );
};

OpeningBalanceTableBody.propTypes = { data: PropTypes.object.isRequired, };

const openingBalanceConfig = {
  headerDetails: [
    { id: 1, label: 'idOpeningBalance', title: 'ID', className: 'cursor-pointer left-align', sortable: true },
    { id: 2, label: 'businessId', title: 'Business Unit', className: 'cursor-pointer left-align', sortable: true },
    { id: 3, label: 'skuDetail.title', title: 'SKU', className: 'cursor-pointer left-align', sortable: true },
    { id: 4, label: 'quantity', title: 'Qty', className: 'cursor-pointer right-align', sortable: true },
  ],
};

const downloadFormat = [{ id: 1, value: 'Download Opening Balance upload template' }];

const message = {
  success: 'Opening Balance uploaded successfully.',
  error: '!Error while uploading Opening Balance.',
  downloadError: '!Error while downloading sample template.',
};

export {
  downloadFormat, message, openingBalanceConfig, OpeningBalanceTableBody
};
