import { compose } from 'recompose';

import Tag from './Tag';

import apiInterceptor from '../../../api/interceptor';

import { tagApi } from '../../common/base.api';

const composedTag = compose(apiInterceptor({ ...tagApi }))(Tag);

export default composedTag;
