const TITLE = {
  ORIGINAL: 'TAX INVOICE',
  ORIGINAL2: 'INVOICE',
  COPY: 'INVOICE (Copy Of Original)',
};
const TEMPORARY_TITLE = {
  ORIGINAL: 'ESTIMATE',
  ORIGINAL2: 'ESTIMATE',
  COPY: 'ESTIMATE',
};
const PRINT_COPY = 3;

const MESSAGE = { NEXT_PAGE: 'Continued on next page ...', };

export {
  TITLE, MESSAGE, PRINT_COPY, TEMPORARY_TITLE
};
