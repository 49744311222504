import { fixedFloatAndCommas } from '../../../utils/conversion';

const title = 'Reconcile';

const getSummaryViewList = (data = {}) => {
  const summaryList = [
    {
      title: 'Customer',
      value: data.customer || '',
    },
    {
      title: 'Outstanding Amount',
      value: fixedFloatAndCommas(data.outstanding) || 0,
    },
    {
      title: 'Payment Amount',
      value: fixedFloatAndCommas(data.payment) || 0,
    },
    {
      title: 'Remaining Outstanding',
      value: fixedFloatAndCommas(data.remaining) || 0,
    },
  ];

  return summaryList;
};

const getSummaryReconcileData = (data = {}) => ({
  customer: data.customer || '',
  outstanding: data.outstandingAmount || 0,
  payment: data.paymentAmount || 0,
  remaining: data.outstandingAmount || 0,
  reconcileTotal: 0,
});

const getApiTransformedList = (list = []) => {
  return list.reduce((acc, item) => {
    if (item.reconcileAmount > 0) {
      const refinedItem = {
        sales_invoice_id: item.salesInvoiceId,
        voucher_no: item.invoiceNumber,
        amount: item.reconcileAmount,
        type: item.type,
      };
      acc.push(refinedItem);
    }
    return acc;
  }, []);
};

const reconcileConfig = [
  { id: 1, label: '', title: 'SN', className: 'cursor-pointer', sortable: false },
  { id: 2, label: 'invoiceNumber', title: 'Invoice Number', className: 'cursor-pointer', sortable: true },
  { id: 3, label: 'amount', title: 'Pending Amount', className: 'cursor-pointer right-align', sortable: true },
  {
    id: 4,
    label: 'reconcileAmount',
    title: 'Reconcile Amount',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
  {
    id: 5,
    label: 'pendingAmount',
    title: 'New Pending Amount',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
];
const config = {
  title,
  getSummaryReconcileData,
  getSummaryViewList,
  getApiTransformedList,
  headerDetails: reconcileConfig,
};
export default config;
