import PropTypes from 'prop-types';
import React, {Component} from 'react';
import moment from 'moment';
import '../../../../styles/printStyle.css';
import {clone} from '../../../../utils/arrayProcessor';
import {has} from '../../../../utils/hasOwnProperty';
import {Button, LinearProgress} from '../../../../components/BillingMDC';
import {isObjectEmpty} from '../../../../utils/objectProcessor'
import {getPercentage} from '../../../../utils/miscellaneous';
import {GET_BATCH_TH} from '../../../../data/enums/enums';
import config from './config';
import * as filterUtil from '../../../../utils/filterUtil';
import history from '../../../../utils/history';
import DetailView from '../../../common/detail/DetailView';
import withBaseState from '../../../common/withBaseState';
import {INVENTORY_BASE} from '../../../../data/enums/Route';
import PageButtonWrapper from '../../../common/PageButtonWrapper';
import numberToWords from '../../../../utils/numberToTextConverter';
import ReportView from '../../../../components/PrintComponent/ReportView';
import InvoiceDetailsStyled from '../../sales/detail/InvoiceDetailsStyled';
import { groupPrintDataSet } from '../../../common/print.service';
import getPrintData from './getPrintData';
import getConfigData from './getConfigData';
import ComplexPrint from '../../../../components/PrintComponent/complexPrint/complexPrint';

const propTypes = {
  serverResponseWaiting: PropTypes.bool.isRequired,
  getDetails: PropTypes.func.isRequired,
};

class GRNDetails extends Component {
  constructor(props) {
    super(props);
    this.box = React.createRef();
    this.state = {
      id: props.match.params.id || '',
      data: {
        list: [],
        summary: {},
      },
      loading: false,
      customerDetails: config.customerDetailMapper({}),
      billSummary: config.billSummaryMapper({}),
      date: '',
      miti: '',
      invoiceList: [],
      printButtonClicked: false,
      billTotalList: [
        { title: 'Sub Total', value: 0 },
        { title: 'Bill Discount', value: 0 },
        {
          title: 'Trade Discount',
          value: 0,
        },
        { title: 'VAT', value: 0 },
      ],
      company: isObjectEmpty(props.company) ? { printInfo: {} } : props.company,
      dataMiscellaneousList: [
        { title: 'Vendor Invoice Number', value: '' },
        {
          title: 'GRN Number',
          value: '',
        },
        { title: 'Vendor Name', value: '' },
        { title: 'Address', value: '' },
        { title: 'PAN No', value: '' },
      ],
      dataList: [],
      totalQuantity: 0,
      invoiceDetail: { title: 'Prepared By', value: '' },
    };
    this.pageConfig = this.getPageConfig();
  }

  handlePrintClick = () => {
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ printButtonClicked: true });
    // self.setState({ printButtonClicked: true }, () => {
    //   setTimeout(() => {
    //     window.print();
    //   }, 500);
    // });
    // window.onafterprint = function () {
    //   self.setState({ printButtonClicked: false });
    // };
  };

  groupPrintDataSetNew() {
    const { invoiceList, company } = this.state;
    const printInfoBill = company.printInfo || {};
    const orders = clone(invoiceList);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }

  getPageConfig = () => {
    const {
      company: {
        printInfo: { batchEnabled },
      },
    } = this.state;
    const header = [...config.header];
    if (batchEnabled) {
      header.splice(2, 0, GET_BATCH_TH);
      return { ...config, header };
    }
    return config;
  };

  componentDidMount() {
    this.getDetail();
    window.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('mousedown', this.handleOutsideClick);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleOutsideClick = event => {
    const { printDetails } = this.state;
    if (this.box && !this.box.current.contains(event.target)) {
      this.state.printButtonClicked === true && this.state.printButtonClicked === true && this.setState({ printButtonClicked : false});
    }
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  getDetail = () => {
    const { getDetails, location } = this.props;
    const { invoiceDetail, company } = this.state;
    const { id } = this.state;
    getDetails(id).then(response => {
      if (response) {
        const { details, ...summary } = response;
        const data = {
          list: response.details,
          summary,
        };
        summary.subTotal = summary.amount - (summary.promotionDiscount || 0) + (summary.oExciseAmount || 0);
        summary.taxableAmount = summary.subTotal - (summary.billDiscount || 0) - (summary.tradeDiscount || 0);
        summary.actualTaxableAmount = summary.taxableAmount - (summary.taxExemptedAmount || 0) + (summary.excise || 0);
        summary.totalInWords = numberToWords(summary.netAmount || 0);
        const billPercent = getPercentage(summary.billDiscount, summary.subTotal);
        const tradePercent = getPercentage(summary.tradeDiscount, summary.subTotal - summary.billDiscount);

        const billSummary = config.billSummaryMapper({
          ...summary,
          billPercent,
          tradePercent,
        });
        let companyInfo = { ...company, printInfo: { count: company?.printInfo?.count ,batchEnabled:company?.printInfo?.batchEnabled} };
        const customerDetails = config.customerDetailMapper(summary);
        const dataMiscellaneousList = [
          {
            title: 'Vendor Invoice Number',
            value: summary.vendorInvoiceNumber,
          },
          {
            title: 'GRN Number',
            value: summary.purchaseInvoiceNumber,
          },
          { title: 'Vendor', value: customerDetails.customerName },
          {
            title: 'Address',
            value: summary.address,
          },
          {
            title: 'PAN No',
            value: customerDetails.panNo,
          },
        ];
        const billTotalList = [
          { title: 'Sub Total', value: summary.subTotal },
          {
            title: `Bill Discount${billPercent ? ` (${billPercent}%)` : ''}`,
            value: summary.billDiscount,
          },
          {
            title: `Trade Discount${tradePercent ? ` (${tradePercent}%)` : ''}`,
            value: summary.tradeDiscount,
          },
          // JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise
          //   ? { title: 'Excise Duty', value: summary.excise }
          //   : {},
          { title: 'Taxable Amount', value: summary.actualTaxableAmount },
          { title: 'VAT ', value: summary.vat },
        ];
        if (JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise) {
          billTotalList.splice(3, 0, { title: 'Excise Duty', value: data.summary.excise });
        }
        if (has.call(summary.user || {}, 'name')) {
          invoiceDetail.value = summary.user.name;
        }
        const totalQuantity = details.reduce((acc, curr) => {
          return acc + curr.quantity;
        }, 0);
        this.setState(
          {
            date: filterUtil.formatToDateMonthYear(summary.documentDate) || filterUtil.getCurrentDay(),
            miti: summary.miti,
            invoiceList: details || [],
            billSummary,
            invoiceDetail,
            billTotalList,
            customerDetails,
            data,
            dataMiscellaneousList,
            totalQuantity,
            company: companyInfo,
          },
          () => {
            location && location.state && location.state.printEnable && this.handlePrintClick();
          },
        );
      }
    });
  };
  calculateTotal = (detailList, key) => {
    let totalValue = 0;
    detailList.map(item => {
      totalValue += item[key];
    });
    return Number(Number(totalValue).toFixed(2));
  };

  btnWrapper = () => (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button accent className="margin-right-0 cancel-btn modal-btn" onClick={() => history.push(`/${INVENTORY_BASE}`)}>
        Cancel
      </Button>
      <div ref={this.box}>
        <Button
          accent
          className="save-btn modal-btn"
          onClick={() => {
            this.handlePrintClick();
          }}
        >
          Print
        </Button>
      </div>
    </div>
  );

  render() {
    const {
      id: GRN_ID,
      data,
      loading,
      invoiceList,
      dataList,
      billSummary,
      customerDetails,
      billTotalList,
      invoiceDetail,
      date,
      miti,
      printButtonClicked,
      dataMiscellaneousList,
      totalQuantity,
      company,
    } = this.state;
    const { serverResponseWaiting } = this.props;

    const headerData = {
      // printCountInfo: 1,
      title: config.title,
      date,
      miti,
      dataMiscellaneousList,
    };

    const addToBillTotal = [...billTotalList, { title: 'Total', value: billSummary?.totalValue ?? 0 }];

    const totalDatas = {
      billTotalList: addToBillTotal,
      paymentMode: billSummary.paymentMode,
      invoiceDetail,
      print,
      batchEnabled: company.printInfo.batchEnabled,
      // printInfo,
      totalInWords: billSummary.totalInWords,
      // remarksStatus,
      remarks: billSummary.remarks,
      // agentInfo,
      totalQuantity,
      // printFooterNote,
    };

    // console.log('billSummary',data.summary.excise)
    // console.log('addToBillTotal',addToBillTotal)


    const printData = getPrintData([...invoiceList], { ...company }, headerData, totalDatas);
    const configData = getConfigData(printData?.tableFooterData, company?.printInfo?.batchEnabled);
    return (
      <InvoiceDetailsStyled>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={` sales-invoice srn srn-detail grn-detail ${loading ? 'clickable-false' : ''}`}>
          <div>
            <DetailView
              display={{
                header: true,
                footer: true,
                summary: true,
              }}
              serverResponseWaiting={serverResponseWaiting}
              headerConfig={{
                company,
                title: `${config.title.original}# ${GRN_ID}`,
                date: moment(data.summary.documentDate || new Date()).format('DD MMM YYYY'),
              }}
              printInfoBill={company.printInfo}
              pageConfig={this.pageConfig}
              data={data}
            />
            <PageButtonWrapper renderBtn={this.btnWrapper} />
          </div>
          {printButtonClicked && (
            <div className=" portrait display-block">
              {/* <ReportView
              billTotalList={billTotalList}
              billSummary={billSummary}
              dataList={dataList}
              dataMiscellaneousList={dataMiscellaneousList}
              date={date}
              company={company}
              miti={miti}
              invoiceDetail={invoiceDetail}
              data={data}
              config={config}
              pageConfig={this.pageConfig}
              batchControl
              totalQuantity={totalQuantity}
            /> */}
              <ComplexPrint configData={configData} printData={printData} orientation={'portrait'} />
            </div>
          )}
        </div>
      </InvoiceDetailsStyled>
    );
  }
}

GRNDetails.propTypes = propTypes;
const GRNDetailsWithState = withBaseState(GRNDetails);

export default GRNDetailsWithState;
