import React from 'react';
import { Icon } from '../../../components/BillingMDC';
import { LEDGER_GROUP_TYPE } from '../../../data/enums/enums';

const AccountGroupBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr>
        <td className='left-align'>{data.title}</td>
        <td className='left-align'>{data.parentTitle}</td>
        <td className='left-align'>
          {(data.ledgerType && LEDGER_GROUP_TYPE.find((t) => t.id === data.ledgerType).title) || null}
        </td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => onRowClick(data, index)} />
        </td>
      </tr>
    ))}
  </tbody>
);

const agentConfig = [
  {
    id: 1,
    label: 'title',
    title: 'Title',
    className: '',
    sortable: false,
  },
  {
    id: 2,
    label: 'parent_title',
    title: 'Parent Account Group',
    className: '',
    sortable: false,
  },
  {
    id: 2,
    label: 'ledgerType',
    title: 'Ledger Type',
    className: '',
    sortable: false,
  },
  {
    id: 3,
    label: '',
    title: '',
    className: '',
    sortable: false,
  },
];

const formValidationFieldList = [{ title: 'title' }, { title: 'ledgerType' }];

const accountGroupMapper = (data = {}) => ({
  id: data.idAccountGroup || null,
  title: data.title || '',
  parent: data.parent || null,
  ledgerType: data.ledgerType || null,
});
const config = {
  header: agentConfig,
  getTableBody: AccountGroupBody,
  formValidationFieldList,
  accountGroupMapper,
};

export default config;
