import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as httpUtils from '../../../utils/httpUtils';
import { JournalVoucherPrintTableBody, journalVoucherPrintConfig } from './JournalVoucherPrintConfig';
import TableHeader from '../../../components/TableHeader';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
  Radio,
  RadioGroup,
  Snackbar,
} from 'react-mdc-web';
// import 'react-select/dist/react-select.css'
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import * as appConstants from '../../config';
import history from '../../../utils/history';
import Header from '../../../components/PrintComponent/Header';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import VoucherFooter from '../../../components/PrintComponent/voucherFooter';
import { JOURNAL_VOUCHER_BASE } from '../../../data/enums/Route';
import numberToWords from '../../../utils/numberToTextConverter';

const title = {
  original: 'Voucher',
};
let dataListLength = 0;
let userId = '';
class JournalVoucherPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      indexInJVList: 0,
      jvList: [],
      dataList: [],
      jvSummary: {
        narration: '',
        totalDebit: '',
        totalCredit: '',
        transactionDate: '',
        miti: '',
        voucherNumber: '',
        totalInWords: '',
      },
      userDetail: {
        id: '',
        title: '',
      },
      totalList: [
        { title: 'Total', value: 'Total' },
        { title: 'Debit', value: 0 },
        { title: 'Credit', value: 0 },
      ],
      preparedBy: { title: 'Prepared By', value: '' },
      dataMiscellaneousList: [{ title: 'Voucher Number', value: '' }],
      headerTitleToView: journalVoucherPrintConfig.headerTitleToView,
      signatureTitleList: [
        { id: 0, title: 'Received By', value: '' },
        { id: 1, title: 'Prepared By', value: '' },
        { id: 3, title: 'ApprovedBy', value: '' },
      ],
      printButtonClicked: false,
      print: false,
    };
  }
  // after mounted get the customer list.
  componentDidMount() {
    if (this.props.params.hasOwnProperty('userId')) {
      let root = document.getElementsByTagName('html')[0];
      root.setAttribute('class', 'printDetailClass');
      userId = this.props.params.userId;
    }
    this.getDetail();
  }

  getDetail = () => {
    const { queryParameters } = this.state;
    this.setState({ loading: true });
    const id = this.props.params.id.replace(/%2F/g, '/');
    //const userId = this.props.params.hasOwnProperty('userId') ? this.props.params.userId : '';
    const queryUrl =
      userId === ''
        ? `report/journal-detail?&voucher_number=${id}`
        : `report/journal-detail?user_id=${userId}&voucher_number=${id}`;
    httpUtils.get(appConstants.baseUrl + queryUrl).then(response => {
      this.setState({ loading: false });
      if ((response.status == '200') & response.success) {
        const jvSummaryObj = response.data.journalVoucher;
        const jvSummary = {
          narration: jvSummaryObj.narration,
          totalDebit: jvSummaryObj.drAmount,
          totalCredit: jvSummaryObj.crAmount,
          transactionDate: moment(jvSummaryObj.transactionDate).format('ll'),
          miti: jvSummaryObj.mitiTitle,
          voucherNumber: jvSummaryObj.voucherNumber,
          totalInWords: numberToWords(jvSummaryObj.drAmount || 0),
        };
        let userDetail = { id: 'userId', title: '' };
        if (response.data.hasOwnProperty('actionUserDetail')) {
          if (response.data.actionUserDetail !== null) {
            userDetail.title = response.data.actionUserDetail.hasOwnProperty('name')
              ? response.data.actionUserDetail.name
              : '';
          } else {
            userDetail.title = response.data.journalVoucher.user.hasOwnProperty('name')
              ? response.data.journalVoucher.user.name
              : '';
          }
        } else {
          userDetail.title = response.data.journalVoucher.user.hasOwnProperty('name')
            ? response.data.journalVoucher.user.name
            : '';
        }
        const preparedBy = { title: 'Prepared By', value: userDetail.title };
        const dataMiscellaneousList = [{ title: 'Voucher Number', value: jvSummary.voucherNumber }];
        //jvList: jvObject.jvList
        this.setState(
          {
            jvList: response.data.journalVoucherDetail,
            jvSummary: jvSummary,
            dataMiscellaneousList: dataMiscellaneousList,
            userDetail: userDetail,
            preparedBy: preparedBy,
          },
          () => {
            this.groupPrintDataset();
          },
        );
      } else {
      }
    });
  };

  //create the sales Return, update the discount value
  createJournalVoucher = () => {
    const { jvList, createJV, businessUnitId } = this.state;
    createJV.business_id = businessUnitId;
    //createJV.ref_date = moment(createJV.ref_date).format('ll');
    jvList.push(createJV);
    this.setState({ jvList: jvList }, () => {
      this.closeModal();
      this.handleVoucherListUpdate();
    });
  };

  validateForm = (formObject, config, flag, zeroFlag) => {
    const data = Object.keys(formObject).some(k => {
      if (config.indexOf(k) > -1) {
        let value = formObject[k];
        const conditionParameter = zeroFlag ? value === '' : !value || value === '0';
        if (conditionParameter) {
          this.setState({ [flag]: true });
          return true;
        }
      }
    });
    return !data;
  };

  handlePrintClick = () => {
    const printButtonStatus = this.state.printButtonClicked;
    const self = this;
    this.setState({ printButtonClicked: true }, () => {
      window.print();
    });
    window.onafterprint = function () {
      //self.setState({printButtonClicked: false}, () => {
      if (userId !== '') {
        window.close();
      } else {
        history.push(`/${JOURNAL_VOUCHER_BASE}`);
      }
    };
  };

  groupPrintDataset = () => {
    const { jvList } = this.state;
    let chunk = 12;
    let dataList = [];
    let arrayList = [];
    let count = 0;
    arrayList = cloneDeep(jvList);
    const lastArrayIndex = jvList.length ? Math.ceil(jvList.length / chunk) : 0;
    const listLeftForLastPage = jvList.length ? jvList.length % chunk : 0;
    arrayList.forEach((item, index) => {
      item['index'] = index;
      arrayList[index] = item;
    });
    for (let index = 0, j = arrayList.length; index < j; index += chunk) {
      count++;
      let chunkArray = [];
      let d = lastArrayIndex - 1;
      if (dataList.length >= d) {
        if (dataList.length === d && listLeftForLastPage > 12) {
          chunk = 12;
          chunkArray = arrayList.slice(index, index + chunk);
          chunkArray['footer'] = false;
          chunkArray['page'] = count;
        } else {
          chunkArray = arrayList.slice(index, index + chunk);
          chunkArray['footer'] = true;
          chunkArray['page'] = count;
        }
      } else {
        chunk = 12;
        chunkArray = arrayList.slice(index, index + chunk);
        chunkArray['footer'] = false;
        chunkArray['page'] = count;
      }
      dataList.push(chunkArray);
    }
    dataListLength = dataList.length;
    this.setState({ dataList: dataList }, () => {
      if (userId !== '') {
        this.handlePrintClick();
      }
      //this.printInvoice();
    });
  };

  handleKeyDown = e => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  directToMainPage = () => {
    const { showMessage, success } = this.state;
    if (!showMessage && success) {
      history.push(`/${JOURNAL_VOUCHER_BASE}`);
    }
  };

  handleCancelClick = () => {
    history.push(`/${JOURNAL_VOUCHER_BASE}`);
  };

  render() {
    const { loading, jvList, dataList } = this.state;
    const { user, company } = this.props;
    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className={this.state.printButtonClicked ? 'no-print' : 'display-block'}>
            <div className="card-body">
              <div className="header-bar">
                <Grid>
                  <Cell col={4} tablet={8}>
                    <div className="header-title">{company.title}</div>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Address: </span>
                      <span>{company.address}</span>
                    </span>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Phone: </span>
                      <span>{company.phone}</span>
                    </span>
                    <span className="no-margin-bottom header-title-content">
                      <span className="active-opacity-text">PAN No: </span>
                      <span>{company.panNumber}</span>
                    </span>
                  </Cell>
                  <Cell col={4} tablet={8}>
                    <div className="header-middle-title center-align">
                      <h2>{title.original}</h2>
                    </div>
                  </Cell>
                  <Cell col={4} tablet={8}>
                    <div className="right-align">
                      <span className="header-right-content">
                        <span className="">Date: </span>
                        <span>{this.state.jvSummary.transactionDate}</span>
                      </span>
                      {/*<span className="header-right-content">
                   <span className="">Sambat</span>
                   <span>1 Kartik, 2074</span>
                 </span>*/}
                    </div>
                  </Cell>
                </Grid>
              </div>
              <div className="">
                <div ref="fixedTableBody" className="overflow-scrollable fixed-table-wrapper">
                  <table>
                    <TableHeader
                      headerDetails={journalVoucherPrintConfig.headerDetails}
                      filterHeaderLabel={false}
                      handleSorting={this.handleTableSorting}
                    />
                    {jvList.map((data, key) => (
                      <tbody>
                        <JournalVoucherPrintTableBody data={data} index={key} />
                      </tbody>
                    ))}
                  </table>
                </div>

                <div className="total-section-wrapper">
                  <Grid>
                    <Cell col={8}></Cell>
                    <Cell col={3} className="right-align active-opacity-text">
                      Total Debit:{' '}
                    </Cell>
                    <Cell col={1} className="right-align">
                      {this.state.jvSummary.totalDebit}
                    </Cell>
                  </Grid>

                  <Grid>
                    <Cell col={8}></Cell>
                    <Cell col={3} className="right-align active-opacity-text">
                      Total Credit:
                    </Cell>
                    <Cell col={1} className="right-align">
                      {this.state.jvSummary.totalCredit}
                    </Cell>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="newinvoice-btn-wrapper">
              <Grid>
                <Cell col={8}></Cell>
                <Cell col={4} className="right-align">
                  <Button
                    accent
                    className="cancel-btn modal-btn"
                    onClick={() => {
                      this.handleCancelClick();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    accent
                    className="save-btn modal-btn"
                    onClick={() => {
                      this.handlePrintClick();
                    }}
                  >
                    Print
                  </Button>
                </Cell>
              </Grid>
            </div>
          </div>
          <div className={this.state.printButtonClicked ? 'display-block' : 'no-print'}>
            {dataList.map((data, key) => {
              return (
                <div className="print-body zoom-less-reduce voucher-print">
                  <div className="srn-card card-body" ref="t">
                    <Header
                      company={company}
                      divClassName={
                        key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'
                      }
                      date={this.state.jvSummary.transactionDate}
                      miti={this.state.jvSummary.miti}
                      pageTitle={<h2>{title.original}</h2>}
                      dataMiscellaneousList={this.state.dataMiscellaneousList}
                    />
                    <div
                      ref="fixedTableBody"
                      className={`overflow-scrollable fixed-table-wrapper ${
                        !data.footer ? 'fixed-table-height ' : 'payment-total-footer'
                      }`}
                    >
                      <table className="voucher-table">
                        <TableHeader
                          headerDetails={journalVoucherPrintConfig.headerDetails}
                          filterHeaderLabel={false}
                          handleSorting={this.handleTableSorting}
                        />
                        {data.map((voucherDetails, keyTest) => (
                          <tbody>
                            <JournalVoucherPrintTableBody data={voucherDetails} index={keyTest} />
                          </tbody>
                        ))}
                        {data.footer && (
                          <tfoot className="">
                            <tr>
                              <td colSpan="3"></td>
                              <td>Total</td>
                              <td className="font-bold right-align">{this.state.jvSummary.totalDebit}</td>
                              <td className="font-bold right-align">{this.state.jvSummary.totalCredit}</td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </div>
                    {data.footer && (
                      <table className="left-footer print-active total-section-wrapper footer clearfix">
                        <VoucherFooter
                          print={this.state.print}
                          printInfo={this.state.printInfo}
                          preparedBy={this.state.preparedBy}
                          totalInWords={this.state.jvSummary.totalInWords}
                          signatureList={this.state.signatureTitleList}
                          lineMargin="padding-r-24"
                          remarksStatus={true}
                          remarks={this.state.jvSummary.narration}
                        />
                      </table>
                    )}
                  </div>
                  <div className="footer-block">
                    <PageNumber value={data.page} totalPage={dataListLength} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

JournalVoucherPrint.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
  };
};

const journalVoucherPrint = connect(mapStateToProps)(JournalVoucherPrint);

export default journalVoucherPrint;
