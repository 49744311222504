import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const PurchaseReturnVatReportTableBody = ({ data }) => {
  return (
    <tr className='cursor-pointer'>
      {/*<td className="left-align">{data.purchase_invoice_no}</td>
      <td className="left-align">{moment(data.transaction_date).format('ll')}</td>*/}
      <td className='left-align'>{data.bill_no}</td>
      <td className='left-align'>{data.miti_title}</td>
      <td className='left-align'>{data.invoice_number}</td>
      <td className='left-align'>{data.ledger_name}</td>
      <td className='left-align'>{data.pan_no}</td>
      <td className='right-align'>
        {data.total_purchase ? fixedFloatAndCommas(data.total_purchase) : data.total_purchase}
      </td>
      <td className='right-align'>
        {data.tax_exempted_purchase ? fixedFloatAndCommas(data.tax_exempted_purchase) : data.tax_exempted_purchase}
      </td>
      <td className='right-align'>
        {data.taxable_purchase_amount
          ? fixedFloatAndCommas(data.taxable_purchase_amount)
          : data.taxable_purchase_amount}
      </td>
      <td className='right-align'>
        {data.taxable_purchase_vat ? fixedFloatAndCommas(data.taxable_purchase_vat) : data.taxable_purchase_vat}
      </td>
      <td className='right-align'>{data.import_amount ? fixedFloatAndCommas(data.import_amount) : 0}</td>
      <td className='right-align'>{data.import_vat ? fixedFloatAndCommas(data.import_vat) : 0}</td>
      <td className='right-align'>
        {data.capitalized_purchase_amount ? fixedFloatAndCommas(data.capitalized_purchase_amount) : 0}
      </td>
      <td className='right-align'>
        {data.capitalized_purchase_vat ? fixedFloatAndCommas(data.capitalized_purchase_vat) : 0}
      </td>
    </tr>
  );
};

PurchaseReturnVatReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const purchaseReturnVatReportConfig = {
  headerDetails: [
    { id: 1, label: 'bill_no', title: 'PR Invoice Number', className: 'cursor-pointer left-align', sortable: true },
    //{id: 1, label: 'date', title: 'Date', className: 'cursor-pointer left-align', sortable: true},
    { id: 3, label: 'miti_title', title: 'Miti', className: 'cursor-pointer left-align', sortable: true },
    {
      id: 4,
      label: 'purchase_invoice_number',
      title: 'Ref Number',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    { id: 5, label: 'vendor_name', title: 'Vendor Name', className: 'cursor-pointer left_align', sortable: true },
    { id: 6, label: 'vendor_pan_no', title: 'PAN No.', className: 'cursor-pointer left-align', sortable: false },
    {
      id: 7,
      label: 'total_purchase',
      title: 'Total Purchase',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 8,
      label: 'tax_exempted_purchase',
      title: 'Tax Exempt',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 9,
      label: 'taxable_purchase_amount',
      title: 'Taxable',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 10,
      label: 'taxable_purchase_vat',
      title: 'Purchase VAT',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    { id: 11, label: 'import_amount', title: 'Import Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 12, label: 'import_vat', title: 'Import VAT', className: 'cursor-pointer right-align', sortable: true },
    {
      id: 13,
      label: 'capitalized_purchase_amount',
      title: 'Capitalized Purchase Amount',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 14,
      label: 'capitalized_purchase_vat',
      title: 'Capitalized Purchase VAT',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
  ],
};

export { purchaseReturnVatReportConfig, PurchaseReturnVatReportTableBody };
