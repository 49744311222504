import { compose } from 'recompose';

import DebitNote from './DebitNote';

import apiInterceptor from '../../api/interceptor';

import { debitNoteAPi } from '../common/base.api';

const composedDebitNote = compose(apiInterceptor({ ...debitNoteAPi }))(DebitNote);

export default composedDebitNote;
