import { compose } from 'recompose';

import GRN from './GRN';
import CreateGRN from './create';
import GRNDetail from './detail/Detail';

import apiInterceptor from '../../../api/interceptor';

import { grnApi } from '../../common/base.api';

const composedGRN = compose(apiInterceptor({ ...grnApi }))(GRN);

export default composedGRN;

export {
  CreateGRN, composedGRN as GRNList, GRNDetail
};
