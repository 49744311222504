import numberToText from 'number2text';

const stringMapper = {
  Rupee: 'Rupees',
  Paise: 'Paisa',
};
const numberToWords = (number = 0, lang = 'indian', currency = 'Rupees') =>
  numberToText(number, lang, currency).replace(/Rupee|Paise/gi, (matched) => stringMapper[matched]);

export default numberToWords;
