const getConfigData = () => {
  const columns = [
    [
      { content: 'SN', rowSpan: 2 },
      { content: 'Source', rowSpan: 2 },
      { content: 'Voucher Number', rowSpan: 2 },
      { content: 'Action ', colSpan: 5, styles: { halign: 'center' } },
      { content: 'Ledger', rowSpan: 2 },
      { content: 'Net Amount', rowSpan: 2, styles: { halign: 'right' } },
    ],
    [
      { content: 'Type', styles: { valign: 'middle' } },
      { content: 'Date', styles: { valign: 'middle' } },
      { content: 'Miti', styles: { valign: 'middle' } },
      { content: 'Time', styles: { valign: 'middle' } },
      { content: 'User', styles: { valign: 'middle' } },
    ],
  ];

  const columnStyles = {
    0: { cellWidth: 10 },
  };

  const cellsToAlignRight = { 9: '9' };

  return {
    columns,
    columnStyles,
    cellsToAlignRight,
  };
};

export default getConfigData;
