import React from 'react';
import moment from 'moment/moment';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { formatToReadableDate } from '../../../utils/filterUtil';

const PurchaseReturnBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <tr className='cursor-pointer' onClick={() => onRowClick(data)}>
        <td className='left-align'>{data.purchaseReturnInvoiceNumber}</td>
        <td className='left-align'>{data.refNumber}</td>
        <td className='left-align'>{formatToReadableDate(data.documentDate)}</td>
        <td className='left-align'>{data.mitiTitle}</td>
        <td className='left-align'>{data.customer.title}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.netAmount)}</td>
        <td className='left-align'>{data.remarks}</td>
        <td className='left-align'>{formatToReadableDate(data.createdAt)}</td>
      </tr>
    ))}
  </tbody>
);

const purchaseReturnConfig = [
  { id: 1, label: 'purchase_return_invoice_number', title: 'PR Number', className: 'cursor-pointer', sortable: true },
  { id: 1, label: 'ref_number', title: 'GRN Number', className: 'cursor-pointer', sortable: true },
  { id: 2, label: 'date', title: 'Document Date', className: 'cursor-pointer left-align', sortable: true },
  { id: 6, label: 'miti_title', title: 'Miti', className: 'cursor-pointer', sortable: true },
  { id: 3, label: 'ledgers.title', title: 'Vendor Name', className: 'cursor-pointer', sortable: true },
  { id: 4, label: 'net_amount', title: 'PR Amount', className: 'cursor-pointer right-align', sortable: true },
  { id: 5, label: 'remarks', title: 'Remarks', className: 'cursor-pointer left-align', sortable: true },
  { id: 8, label: 'created_at', title: 'Created Date', className: 'cursor-pointer', sortable: true },
];

const config = {
  header: purchaseReturnConfig,
  getTableBody: PurchaseReturnBody,
};

export default config;
