import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
} from 'react-mdc-web';

class CreateSalesInvoiceTableBody extends Component {
  render() {
    const { data, index } = this.props;
    const discount = Number(data.promotion_discount) + Number(data.trade_discount) + Number(data.bill_discount);
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{data.skuTitle}</td>
        <td className='right-align'>{data.quantity}</td>
        <td className='right-align'>{data.rate}</td>
        <td className='right-align'>{data.amount}</td>
        <td className='right-align'>{discount}</td>
        <td className='right-align'>{data.net_amount}</td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => this.props.handleEditIconClick(data, index)} />
          <Icon name='delete' onClick={() => this.props.handleDeleteIconClick(index)} />
        </td>
      </tr>
    );
  }
}

CreateSalesInvoiceTableBody.defaultProps = { enableUpdate: true, };

CreateSalesInvoiceTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteIconClick: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  enableUpdate: PropTypes.bool,
};

const createSalesInvoiceConfig = {
  headerDetails: [
    { id: 1, label: 'test', title: 'SN', className: null, sortable: false },
    { id: 2, label: 'sku', title: 'SKU', className: null, sortable: false },
    { id: 3, label: 'quantity', title: 'Quantity (pcs)', className: 'right-align', sortable: false },
    { id: 4, label: 'rate', title: 'Rate', className: 'right-align', sortable: false },
    { id: 5, label: 'amount', title: 'Amount', className: 'right-align', sortable: false },
    { id: 6, label: 'discount', title: 'Discount', className: 'right-align', sortable: false },
    { id: 7, label: 'net_amount', title: 'Net Amount', className: 'right-align', sortable: false },
    { id: 8, label: 'test', title: '', className: 'right-align', sortable: false },
  ],
};

export { createSalesInvoiceConfig, CreateSalesInvoiceTableBody };
