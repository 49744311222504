import React from 'react';
import moment from 'moment';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
  Icon,
} from '../../../components/BillingMDC/index';
import Switch from '../../../components/Switch';
import Download from '../../common/Download';
import { ACTION } from '../../../data/enums/enums';
import { confirmationTitle, migrateTitle } from './config';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import HorizontalLabelValueBar from '../../common/horizontalLabellValueBar';

const FYMDialog = ({
  actionType,
  onModalClose,
  onModalSubmit,
  onInputChange,
  data,
  fymGroup,
  migrateData,
  validationFlag,
  accountGroupList,
  showErrorMessage,
  handleErrorMessage,
  domainConfig,
  handleDownloadClick,
}) => {
  const typeUpdate = actionType === ACTION.UPDATE;
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader className={`${!typeUpdate && 'confirm-header'}`}>
        <DialogTitle>{typeUpdate ? migrateTitle : confirmationTitle}</DialogTitle>
        <span>{!typeUpdate && <Download config={domainConfig} onClick={handleDownloadClick} />}</span>
      </DialogHeader>
      <DialogBody>
        <div className={`dialog-upperpart ${!typeUpdate && 'fym-dialog-body'}`}>
          {typeUpdate ? (
            <FYMMigrateDialog
              onInputChange={onInputChange}
              data={data}
              fymGroup={fymGroup}
              validationFlag={validationFlag}
              accountGroupList={accountGroupList}
            />
          ) : (
            <>
              <div className='form-switchs stock-blnc-switch one'>
                <SwitchWrapper
                  label='Include Stock Balance'
                  param='showStock'
                  fymGroup={fymGroup}
                  onInputChange={onInputChange}
                />
              </div>
              <ClosingSummaryTable migrateData={migrateData} />

              {migrateData.newYearMigrateList.length > 0 && <OpeningSummaryTable migrateData={migrateData} />}
              <div className='form-switchs stock-blnc-switch two'>
                <SwitchWrapper
                  label='Create JV Entry for new Fiscal Year'
                  param='jvEntry'
                  fymGroup={fymGroup}
                  onInputChange={onInputChange}
                />
              </div>
              <ErrorPopOver showErrorMessage={showErrorMessage} handleErrorMessage={handleErrorMessage} />
            </>
          )}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          className={`dialog-ok modal-btn ${showErrorMessage && 'errorColor'} `}
          onClick={() => {
            onModalSubmit();
          }}
        >
          {typeUpdate ? 'MIGRATE' : 'CONFIRM'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
const ErrorPopOver = ({ showErrorMessage, handleErrorMessage }) => (
  <>
    {showErrorMessage && (
      <div className='fym-error error-message'>
        <span>
          <strong>Error: </strong>There is issue in your transaction.
        </span>
        <i className='material-icons' onClick={handleErrorMessage}>
          close
        </i>
      </div>
    )}
  </>
);

const SwitchWrapper = ({ label, param, fymGroup, onInputChange }) => (
  <HorizontalLabelValueBar
    label={label}
    value={
      <Switch
        onToggle={(checked, event) => onInputChange(param, checked)}
        checked={fymGroup[param]}
        id={`switch-${param}-id`}
      />
    }
  />
);
const ClosingSummaryTable = ({ migrateData }) => (
  <div className='fym table-wrap'>
    <table>
      <caption>Closing Summary</caption>
      <thead>
        <tr>
          <th className='text-left'>Ledger</th>
          <th className='text-right'>Debit</th>
          <th className='text-right'>Credit</th>
        </tr>
      </thead>
      <tbody>
        {migrateData.migrateList.map((data, index) => (
          <tr>
            <td className='text-left'>{data.particulars}</td>
            <td className='text-right'>{fixedFloatAndCommas(data.debit)}</td>
            <td className='text-right'>{fixedFloatAndCommas(data.credit)}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <td className='text-left'>Grand Total</td>
        <td className='text-right'>{fixedFloatAndCommas(migrateData.migrateTotal.debit || 0)}</td>
        <td className='text-right'>{fixedFloatAndCommas(migrateData.migrateTotal.credit || 0)}</td>
      </tfoot>
    </table>
  </div>
);
const OpeningSummaryTable = ({ migrateData }) => (
  <div className='fym table-wrap'>
    <table>
      <caption>Opening Summary</caption>
      <thead>
        <tr>
          <th className='text-left'>Ledger</th>
          <th className='text-right'>Debit</th>
          <th className='text-right'>Credit</th>
        </tr>
      </thead>
      <tbody>
        {migrateData.newYearMigrateList.map((data, index) => (
          <tr>
            <td className='text-left'>{data.particulars}</td>
            <td className='text-right'>{fixedFloatAndCommas(data.debit)}</td>
            <td className='text-right'>{fixedFloatAndCommas(data.credit)}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <td className='text-left'>Grand Total</td>
        <td className='text-right'>{fixedFloatAndCommas(migrateData.newYearMigrateTotal.debit || 0)}</td>
        <td className='text-right'>{fixedFloatAndCommas(migrateData.newYearMigrateTotal.credit || 0)}</td>
      </tfoot>
    </table>
  </div>
);

const FYMMigrateDialog = ({ onInputChange, data, fymGroup, validationFlag, accountGroupList }) => (
  <Grid className='grid-padding'>
    <Cell col={12} tablet={12}>
      <div className='migrate_date'>
        <BillingTextField
          value={moment(data.startDate).format('ll')}
          param='start_date'
          floatingLabel='Start Date'
          disabled={true}
          handleChange={onInputChange}
        />
        <Icon name='date_range' className='material-icons' />
      </div>
    </Cell>
    <Cell col={12} tablet={12}>
      <div className='migrate_date'>
        <BillingTextField
          value={moment(data.endDate).format('ll')}
          param='end_date'
          floatingLabel='Cut Off'
          disabled={true}
          handleChange={onInputChange}
        />
        <Icon name='date_range' className='material-icons' />
      </div>
    </Cell>
    <Cell col={6} tablet={6} className='migrate_date'>
      <BillingTextField
        value={fymGroup.title}
        param='title'
        floatingLabel='Migration Ledger'
        handleChange={onInputChange}
        required={true}
        emptyField={validationFlag}
        placeholder=''
      />
    </Cell>
    <Cell col={6} tablet={6} className='input-select'>
      <label>Account Group*</label>
      <BillingSelect
        name='form-field-name'
        param='accountGroup'
        clearable={false}
        value={fymGroup.accountGroup}
        valueKey='id_account_group'
        labelKey='title'
        options={accountGroupList}
        handleChange={onInputChange}
        required={true}
        emptyField={validationFlag}
      />
    </Cell>
  </Grid>
);

export default FYMDialog;
