import React from 'react';
import { Icon } from '../../../components/BillingMDC';
import {DOWNLOAD_SAMPLE_FILE_URL} from "../../../data/enums/enums";


const SampleFileDownload = ({ domain }) => (
  <a href={`${DOWNLOAD_SAMPLE_FILE_URL}/sample_upload_${domain}.csv`}>
    <span className='sample-csv'>
      <Icon name='get_app' />
      Sample CSV
    </span>
  </a>
);

export default SampleFileDownload;
