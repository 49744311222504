import React, { Component } from 'react';
import Dialog from './Dialog';
import tableConfig from './config';
import { validateForm } from '../../common/validation';
import PageView from '../../common/pagination/PageView';
import { AREA_CONFIG } from '../../common/domain.config';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import { ACTION } from '../../../data/enums/enums';
import { BillingSnackBar } from '../../../components/BillingMDC';
import AreaStyled from './AreaStyled';
import { getValidationErrorMsg } from '../../../views/fundamentals/common/helpers';

export class Area extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: { ...queryService.queryParameters },
      dialogType: ACTION.NULL,
      data: {
        list: [],
        total: 0,
      },
      area: {
        title:'',
        status:false,
      },
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
    };
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { getAreaList } = this.props;
    const { queryParameters } = this.state;

    getAreaList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total;
      this.setState({ data });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleEditIcon = (data) =>{
    this.setState({
      dialogType: ACTION.UPDATE,
      area:data,
    });
  }

  handleFabButtonClick = () => {
    this.setState({
      dialogType: ACTION.ADD,
    });
  };

  handleModalSubmit = () => {
    const { area, validation, dialogType } = this.state;
    const formValid = validateForm(area, validation, valid => this.setState({ validation: valid }));

    if (!formValid) return false;

    switch (dialogType) {
      case ACTION.ADD:
        this.createArea();
        break;
      case ACTION.UPDATE:
        this.editArea();
        break;
      default:
        break;
    }
  };

  createArea = () => {
    const { create } = this.props;
    const { area } = this.state;
    const areaForRequest = {
      title: area.title,
    };
    create(areaForRequest)
      .then(response => {
        const snack = snackService.generateSuccessMessage();
        this.handleModalClose();
        this.setState({
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage(getValidationErrorMsg(err));
        this.setState({
          snack,
        });
      });
  };

  editArea = () => {
    const { update } = this.props;
    const { area } = this.state;
    update({id: area.idAreas,
      query: {
        title: area.title,
      },})
      .then(res => {
        const snack = snackService.generateUpdateMessage();
        this.handleModalClose();
        this.setState({
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage(getValidationErrorMsg(err));
        this.setState({ snack });
      });
  };

  handleModalClose = () => {
    const { validation} = this.state;
    validation.flag = false;
    this.loadTableData();
    this.setState({ dialogType: ACTION.NULL,validation ,area: {
      title:'',
      status:false,
    }});
  };

  handleInputChange = (field, value,data) => {
    const { area } = this.state;
    if(field ==='status'){
      this.toggleStatus(value,data);
      return;
    }
    area[field] = value;
    this.setState({ area });
  };


toggleStatus = (checked ,id) => {
  const {updateStatus} = this.props;
  const {data} = this.state;
  const queryData = {
      status: checked,
  };
  updateStatus({
      id,
      query: queryData,
  })
      .then(response => {
          const snack = snackService.generateUpdateMessage();
          const toggleIndex = data.list.findIndex(x => x.idAreas === id );
          data.list[toggleIndex].active = response.active;
          this.setState({snack ,data});
      })
      .catch(err => {
          const snack = snackService.generateFailureMessage(err?.error?.message || err?.message );
          this.setState({snack});
      });
};


  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { data, display, snack, dialogType, area, validation } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <AreaStyled className="areas">
        <PageView
          domainConfig={AREA_CONFIG}
          headerConfig={{
            search: false,
            date: false,
            download: false,
            filter: false,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          data={data}
          tableConfig={tableConfig}
          onTableBodyClick={this.handleEditIcon}
          createHandler={this.handleFabButtonClick}
          onInputChange={this.handleInputChange}
          toggleStatus={this.toggleStatus}
        />

        <Dialog
          actionType={dialogType}
          onModalSubmit={this.handleModalSubmit}
          onModalClose={this.handleModalClose}
          onInputChange={this.handleInputChange}
          area={area}
          validationFlag={validation.flag}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </AreaStyled>
    );
  }
}

export default Area;
