import { connect } from 'react-redux';
import * as filterUtil from "../../utils/filterUtil";
import {getStartDate} from "./query.service";

const mapStateToProps = (state) => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
  bu_id: state.billing.bu_id || null,
  fiscalYearRange: state.billing.fiscalYearRange || null,
  permission: state.billing.permission || null,
  tagDetail: state.tagDetail || { date: { start: getStartDate(),
      end: filterUtil.getCurrentDay() } }
});

const withBaseState = (Component) => connect(mapStateToProps)(Component);

export default withBaseState;
