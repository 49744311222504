import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog';
import { ACTION } from '../../../../../data/enums/enums';
import TableHeader from '../../../../../components/TableHeader';
import { MiniAddFab } from '../../../../../components/BillingMDC';

const propTypes = {
  batch: PropTypes.shape({
    date: PropTypes.instanceOf(Object),
    dlp: PropTypes.number,
    expiryDate: PropTypes.instanceOf(Object),
    mfgDate: PropTypes.instanceOf(Object),
    rlp: PropTypes.number,
    title: PropTypes.string,
  }),
  batchList: PropTypes.instanceOf(Array),
  crudMode: PropTypes.string,
  dialogType: PropTypes.string,
  validation: PropTypes.shape({
    flag: PropTypes.bool,
    fieldList: PropTypes.instanceOf(Array),
  }),
  handleInputChange: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  tableConfig: PropTypes.shape({
    getDetail: PropTypes.func.isRequired,
    getTableBody: PropTypes.func.isRequired,
    header: PropTypes.instanceOf(Array),
  }).isRequired,
};

const defaultProps = {
  batch: {},
  batchList: [],
  crudMode: ACTION.ADD,
  dialogType: ACTION.NULL,
  validation: {
    flag: false,
    fieldList: [],
  },
};

const BatchView = ({
  batchList,
  crudMode,
  tableConfig,
  onIconClick,
  handleInputChange,
  handleModalClose,
  handleModalSubmit,
  validation,
  batch,
  dialogType,
}) => (
  <div className='sku-batch'>
    <div>
      <table>
        <TableHeader headerDetails={tableConfig.header} />

        {tableConfig.getTableBody({ dataList: batchList, onRowClick: onIconClick, crudMode })}
      </table>
    </div>
    {crudMode !== ACTION.READ && <MiniAddFab handleFabClick={onIconClick} />}
    <Dialog
      batch={batch}
      validation={validation}
      actionType={dialogType}
      onInputChange={handleInputChange}
      onModalClose={handleModalClose}
      onModalSubmit={handleModalSubmit}
    />
  </div>
);

BatchView.propTypes = propTypes;
BatchView.defaultProps = defaultProps;

export default BatchView;
