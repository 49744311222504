import { DBS } from '../../data/enums/enums';

const errorPageData = [
  {
    value: 404,
    name: 'Page Not Found',
    message1: 'Whooops! We don\'t have the page you\'re looking for...',
    message2: 'You may have mistyped the address or the page may have moved.',
  },
  {
    value: 500,
    name: 'Internal Server Error',
    message1: `Sorry, ${DBS} faced a technical problem on your last request.`,
    message2: 'Please try again in few minutes.',
  },
  {
    value: 502,
    name: 'Bad Gateway',
    message1: `${DBS} reported an error while processing your request.`,
    message2: 'Please try again in few minutes.',
  },
];

export default errorPageData;
