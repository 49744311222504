import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup } from '../BillingMDC';
import { STATUS } from '../../data/enums/enums';
import {
  getActiveBUList,
  getBUFilterList,
  getIntegratedBUList,
  getRosiaEnabledStatus,
} from '../../views/common/common';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  handleBuClick: PropTypes.func.isRequired,
  disableRosiaBu: PropTypes.bool,
  otherBU: PropTypes.bool,
  radioDisableControl: PropTypes.bool,
};

const defaultProps = {
  disableRosiaBu: false,
  otherBU: false,
  radioDisableControl: false,
};

class RadioButtonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedValue: '',
      defaultValue: '',
    };
  }

  componentDidMount() {
    const { data, handleBuClick, defaultValue } = this.props;

    this.setState({
      data,
      handleChange: handleBuClick,
      selectedValue: defaultValue,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.defaultValue !== prevState.defaultValue) {
      return {
        defaultValue: nextProps.defaultValue,
        selectedValue: nextProps.defaultValue,
      };
    }
    return {
      defaultValue: prevState.defaultValue,
      selectedValue: prevState.selectedValue,
    };
  }

  handleClick = event => {
    const value = parseInt(event.target.value, 10);
    this.setState({ selectedValue: value }, () => this.state.handleChange(value));
  };

  render() {
    const { data, selectedValue } = this.state;
    const { disableRosiaBu, otherBU, radioDisableControl } = this.props;
    const activeBuList = otherBU ? getIntegratedBUList(data) : getActiveBUList(data);
    const thirdPartyBUlist = getBUFilterList(data);

    return (
      <>
        <RadioGroup onChange={this.handleClick} name="bu" value={selectedValue}>
          {activeBuList.map(item => (
            <Radio
              value={item.businessId}
              disabled={disableRosiaBu ? !!getRosiaEnabledStatus(item) : false}
              className={
                radioDisableControl
                  ? ' '
                  : selectedValue
                  ? item.businessId === selectedValue
                    ? 'active-radio'
                    : 'radio-disabled'
                  : ''
              }
            >
              {item.businessAlias}
            </Radio>
          ))}
          {otherBU ? <Radio value={0}>{`Other (${thirdPartyBUlist.map(a => a.businessAlias)})`}</Radio> : <div />}
        </RadioGroup>
      </>
    );
  }
}

RadioButtonList.propTypes = propTypes;

RadioButtonList.defaultProps = defaultProps;

export default RadioButtonList;
