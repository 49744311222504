import { compose } from 'recompose';

import BalanceSheet from './BalanceSheet';

import apiInterceptor from '../../../api/interceptor';

import { balanceSheetApi } from '../../common/base.api';

const composedBalanceSheet = compose(apiInterceptor({ ...balanceSheetApi }))(BalanceSheet);

export default composedBalanceSheet;
