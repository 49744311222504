import styled from 'styled-components';

const SIReportStyled = styled.div`
  .dialog-upperpart {
    .note {
      margin: 0;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
`;
export default SIReportStyled;
