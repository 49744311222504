import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogTitle,
  DialogFooter,
  Button,
  Grid,
  Cell,
} from '../../../components/BillingMDC';
import BillingTextField from '../../../components/TextField/BillingTextField';
import BillingSelect from '../../../components/Select/BillingSelect';
import { connect } from 'react-redux';
import * as filterUtil from '../../../utils/filterUtil';
import { LEDGERS } from '../../../data/enums/enums';
import DateRangePicker from '../../../components/DatePicker/DatePicker';

const CNDialogView = (
  {
    onModalClose,
    modalOpen,
    editModalOpen,
    readModalOpen,
    createCN,
    handleInputChange,
    onModalSubmit,
    formEmptyField,
    transactionDisabled,
    editModalOpenLedger,
    partyLedgerId,
    documentDate,
    fiscalYearRange,
    backDatedStatus,
    renderLedgerSelectOptions,
  },
) =>{
  const minDate = fiscalYearRange?.previous_fiscal_year_closed ? fiscalYearRange?.start_date : fiscalYearRange?.previous_fiscal_year_start;
  return (
    <Dialog
      open={modalOpen || editModalOpen || readModalOpen || editModalOpenLedger}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        {(editModalOpenLedger || editModalOpen) && <DialogTitle>Edit Credit Note</DialogTitle>}
        {readModalOpen && <DialogTitle>Confirmation Dialog</DialogTitle>}
      </DialogHeader>
      <>
        { editModalOpenLedger &&   <DialogBody>
          <div className='dialog-upperpart dialog-view'>
            <Grid>
              <Cell col={6} tablet={8} >
                <label className='select-custom-label'>Party Ledger</label>
                {renderLedgerSelectOptions('partyLedgerId',partyLedgerId,'')}
              </Cell>
              { backDatedStatus && 
              <Cell col={6}>
                <span className='date-picker-wrapper documentDate'>
                  <label>Document Date</label>
                  <DateRangePicker
                    date={filterUtil.getMomentDate(documentDate)}
                    onChange={(e) =>handleInputChange('document_date' ,e)}
                    minDate={filterUtil.getMomentDate(minDate)}
                    maxDate={filterUtil.getCurrentDate()}
                  />
                </span>
              </Cell>
                }
            </Grid>
          </div>
        </DialogBody> }

        {editModalOpen && (
          <DialogBody>
            <div className='dialog-upperpart table-view'>
              <Grid className='grid-padding'>
                <Cell col={4} tablet={8}>
                  <label className='select-custom-label'>Ledger</label>
                  {renderLedgerSelectOptions('customerId',createCN.customerId,'')}
                </Cell>
                <Cell col={4} tablet={8}>
                  <BillingTextField
                    value={createCN.drAmount}
                    handleChange={handleInputChange}
                    floatingLabel='Amount'
                    param='drAmount'
                    type='number'
                    zeroError={false}
                    required
                    className='billing-required right-side-gap'
                    emptyField={formEmptyField}
                  />
                </Cell>
                <Cell  col={4} tablet={8}>
                  <BillingTextField
                    value={createCN.narration}
                    param='narration'
                    floatingLabel='Narration'
                    className='textbox billing-required'
                    handleChange={handleInputChange}
                  />
                </Cell>
              </Grid>
            </div>
          </DialogBody>
        )}

        {readModalOpen && <div className='dialog-upperpart'>Are you sure you want to progress ?</div>}
      </>

      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            onModalClose();
          }}
        >
        Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
        Ok
        </Button>
      </DialogFooter>
    </Dialog>
  )};
const mapStateToProps = (state) => {
  return {
    fiscalYearRange: state.billing.fiscalYearRange || null,
  };
};

const cnDialogView = connect(mapStateToProps)(CNDialogView);

export default cnDialogView;
