import { JWT } from '../environment';
import {
  ROSIA_SRN_PRINT, ROSIA_SALES_PRINT, ROSIA_ABBREVIATED_INVOICE_PRINT,ROSIA_PURCHASE_RETURN_PRINT
} from '../data/enums/Route';
import { decryptText, voucherDecoder } from './miscellaneous';

/**
 * Get current user from localStorage.
 *
 * @returns {object|null}
 */
export function getCurrentUser() {
  const currentUser = localStorage.getItem(JWT.LOCAL_STORAGE.TOKEN.NAME);

  return currentUser != null;
}

/**
 * Check if local storage has a logged in user.
 *
 * @returns {boolean}
 */
export function hasCurrentUser() {
  if (getCurrentUser()) {
    return true;
  }

  return false;
}

/**
 * Changes route to '/login' for unauthenticated users.
 *
 * @param {object} nextState
 * @param {function|object} replace
 */
export function checkAuthentication() {
  return hasCurrentUser();
}

const rosiaPrintAuthenticator = (props, urlStringList) => {
  const invoiceHash = urlStringList[2];
  const encodedInvoiceNumber = urlStringList[3];
  const invoiceNumber = voucherDecoder(encodedInvoiceNumber);
  const generatedHash = decryptText(voucherDecoder(invoiceHash), encodedInvoiceNumber);

  return invoiceNumber === generatedHash;
};

export function authenticateHandlers(props) {
  const pathnameList = props.location.pathname.split('/');

  if (
    pathnameList.length > 0 &&
    (pathnameList[1] === ROSIA_SALES_PRINT ||
      pathnameList[1] === ROSIA_ABBREVIATED_INVOICE_PRINT ||
      pathnameList[1] === ROSIA_SRN_PRINT ||  pathnameList[1] === ROSIA_PURCHASE_RETURN_PRINT)
  ) {
    return rosiaPrintAuthenticator(props, pathnameList);
  }
  return checkAuthentication();
}
