import * as actionTypes from '../data/enums/actionTypes';

/*
 * action creators
 */

export const setDetails = (details) => ({ type: actionTypes.SET_DETAILS, details });

export const setLedgerGroupDetails = (details) => ({ type: actionTypes.LEDGER_GROUP_DETAILS, details });

export const setMetaData = (details) => ({ type: actionTypes.SET_META_DATA, details });

export const setStockLedgerData = (details) => ({ type: actionTypes.STOCK_LEDGER, details });
