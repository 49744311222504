import { compose } from 'recompose';

import LedgerWise from './LedgerWise';

import apiInterceptor from '../../../api/interceptor';

import {
 tagReportApi
} from '../../common/base.api';

const composedLedgerWise = compose(
  apiInterceptor({ ...tagReportApi }),
)(LedgerWise);

export default composedLedgerWise;

