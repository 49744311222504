import React, { Component } from 'react';
import View from './View';
import { formValidationFields, getApiTransformedData, promotionDetailsMapper, message } from './config';
import { validateForm } from '../../../common/validation';
import * as snackService from '../../../common/snack.service';
import { PROMOTION_CONFIG } from '../../../common/domain.config';
import Loading from '../../../../common/Loading';
import { ACTION } from '../../../../data/enums/enums';
import * as filterUtil from '../../../../utils/filterUtil';
import { BillingSnackBar } from '../../../../components/BillingMDC';
import { buApi, catalogsApi, checkIfApiCallSuccess, promotionApi } from '../../../common/base.api';
import { getBUFilterList } from '../../../common/common';
import { PROMOTION } from '../../../../data/enums/Route';
import history from '../../../../utils/history';

class Detail extends Component {
  constructor(props) {
    super(props);
    const id = props.match.params.id ? parseInt(props.match.params.id) : 0;
    this.state = {
      cancelClicked: false,
      buList: [],
      skuList: [],
      brandList: [],
      data: promotionDetailsMapper({}),
      serverData: promotionDetailsMapper({}),
      id,
      crudMode: id ? ACTION.READ : ACTION.ADD,
      validation: {
        flag: false,
        fieldList: formValidationFields,
      },
      snack: { ...snackService.snackParameters },
    };
  }

  componentDidMount() {
    let { id } = this.state;
    this.getBUList();
    if (id) {
      this.getPromotionDetails(id);
    }
  }

  getPromotionDetails = id => {
    promotionApi.getPromotionDetails(id).then(response => {
      const { scope } = response.data;
      const { brandId, buId } = scope[0];
      const updated = promotionDetailsMapper(response.data || {});
      this.getBrandList(buId);
      this.getSKUListUnderBrand(brandId, buId);
      this.setState({ data: updated, serverData: { ...updated } });
    });
  };

  getBUList = () => {
    buApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ buList: getBUFilterList(response.data.list) });
      }
    });
  };

  getBrandList = businessId => {
    catalogsApi.getBrandList({ business_id: businessId }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ brandList: response.data.list });
      }
    });
  };

  getSKUListUnderBrand = (brandId, businessId) => {
    catalogsApi
      .getSkuUnderBrand({
        business_id: businessId,
        brand_id: brandId,
      })
      .then(response => {
        if (checkIfApiCallSuccess(response)) {
          this.setState({ skuList: response.data.list });
        }
      });
  };

  getTitle = () => {
    const { id, crudMode, data } = this.state;
    if (id) {
      if (crudMode !== ACTION.READ) return `${crudMode} ${data.title}`;
      return `${data.title}`;
    }

    return `${crudMode} ${PROMOTION_CONFIG.title}`;
  };

  handleDateChange = (param, value) => {
    const { data } = this.state;
    const formattedDate = filterUtil.formatToNumericDate(value);

    data[param] = formattedDate;

    this.setState({ data });
  };

  handleInputChange = (param, value, e) => {
    const { data } = this.state;
    data[param] = value;
    if (param === 'businessId') {
      this.getBrandList(value);
    }
    if (param === 'brandId') {
      this.getSKUListUnderBrand(value, data.businessId);
    }
    this.setState({ data });
  };

  updateCrudMode = crudMode => {
    this.setState({ crudMode });
  };

  handleCancelClick = () => {
    const { id, serverData } = this.state;
    if (id) {
      this.setState({
        data: { ...serverData },
        cancelClicked: true,
        crudMode: ACTION.READ,
      });
    } else {
      this.setState({ data: promotionDetailsMapper({}), cancelClicked: true });
    }
  };

  handleSaveClick = () => {
    const { id, data, crudMode, validation } = this.state;
    let valid = validateForm(data, validation, validStatus => this.setState({ validation: validStatus }));
    if (valid) {
      const submit = getApiTransformedData(data);
      const requestData = this.mapScopeBySku(submit);
      if (ACTION.ADD === crudMode) {
        promotionApi
          .create(requestData)
          .then(response => {
            if (response) {
              this.setState({ snack: { ...snackService.generateSuccessMessage(message.ADD) } });
            }
          })
          .catch(err => {
            console.log(err);
            this.setState({
              snack: {
                ...snackService.generateFailureMessage(message.CREATE_ERROR),
              },
            });
          });
      } else if (ACTION.UPDATE === crudMode) {
        promotionApi
          .update({ id, query: requestData })
          .then(response => {
            if (response) {
              this.setState({
                snack: { ...snackService.generateSuccessMessage(message.UPDATE) },
              });
            }
          })
          .catch(err => {
            console.log(err);
            this.setState({
              snack: {
                ...snackService.generateFailureMessage(message.UPDATE_ERROR),
              },
            });
          });
      }
      setTimeout(() => {
        history.push(`/${PROMOTION}`);
      }, 1000);
    }
  };

  mapScopeBySku = data => {
    let formattedScope = [];
    data.scope.forEach(el => {
      if (el.sku_id.length) {
        formattedScope = el.sku_id.map(id => {
          return {
            brand_id: el.brand_id,
            bu_id: el.bu_id,
            sku_id: id,
          };
        });
      } else {
        formattedScope = [{ bu_id: data.scope[0].bu_id, brand_id: data.scope[0].brand_id }];
      }
    });

    data.scope = formattedScope;
    return data;
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { data, snack, buList, skuList, brandList, crudMode, validation, cancelClicked } = this.state;
    const { serverResponseWaiting } = this.props;
    const title = this.getTitle();
    return (
      <>
        <Loading display={serverResponseWaiting} />
        <View
          data={data}
          title={title}
          buList={buList}
          skuList={skuList}
          crudMode={crudMode}
          brandList={brandList}
          cancelFlag={cancelClicked}
          enableErrorDisplay={validation.flag}
          updateCrudMode={this.updateCrudMode}
          handleSaveClick={this.handleSaveClick}
          handleCancelClick={this.handleCancelClick}
          handleInputChange={this.handleInputChange}
          handleDateChange={this.handleDateChange}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </>
    );
  }
}

export default Detail;
