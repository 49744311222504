import { compose } from 'recompose';

import Reconcile from './Reconcile';

import apiInterceptor from '../../../api/interceptor';

import { paymentApi } from '../../common/base.api';

const composedReconcile = compose(apiInterceptor({ ...paymentApi }))(Reconcile);

export default composedReconcile;
