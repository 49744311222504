import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Error from './Error';

const propTypes = { match: PropTypes.instanceOf(Object), };

const defaultProps = { match: { params: { id: 1, }, }, };

const ErrorPage = ({ ...props }) => {
  const { match } = props;
  return <Error value={parseInt(match.params.id, 10)} />;
};

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
