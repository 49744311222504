import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const downloadFormat = [{ id: 1, value: 'Download Journal Voucher CSV' }];

const JournalVoucherReportTableBody = ({ data, onRowClick, type }) => {
  return (
    <tr
      className={type === data.transactionType ? 'cursor-pointer' : 'cursor-default'}
      onClick={() => onRowClick(data.idJournalVoucher, data.transactionType, data.voucherNumber)}
    >
      <td className='left-align'>{data.voucherNumber}</td>
      <td className='left-align'>{moment(data.transactionDate).format('ll')}</td>
      <td className='left-align'>{data.mitiTitle}</td>
      <td className='left-align'>{data.customer ? data.customer.name : null}</td>
      <td className='right-align'>{data.drAmount ? data.drAmount.toLocaleString() : 0}</td>
      <td className='right-align'>{data.crAmount ? data.crAmount.toLocaleString() : 0}</td>
      <td className='left-align'>{data.narration}</td>
    </tr>
  );
};

JournalVoucherReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const journalVoucherReportConfig = {
  headerDetails: [
    { id: 1, label: 'voucherNumber', title: 'VoucherNo.', className: 'cursor-pointer left-align', sortable: true },
    { id: 2, label: 'transactionDate', title: 'Date', className: 'cursor-pointer left-align', sortable: true },
    { id: 3, label: 'mitiTitle', title: 'Miti', className: 'cursor-pointer left-align', sortable: true },
    { id: 4, label: 'customer.name', title: 'Ledger', className: 'cursor-pointer left-align', sortable: true },
    { id: 5, label: 'drAmount', title: 'Debit', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'crAmount', title: 'Credit', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'narration', title: 'Narration', className: 'cursor-pointer left-align', sortable: true },
  ],
};

export {
  journalVoucherReportConfig, JournalVoucherReportTableBody, downloadFormat
};
