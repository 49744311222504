import PropTypes from 'prop-types';
import React from 'react';
import {
  Grid, Cell, Textfield
} from 'react-mdc-web';

const ReportHeader = ({ headerContent, divClassName }) => {
  return (
    <div>
      <div className={divClassName}>
        {
          <Grid>
            {headerContent.headerLeft.length && (
              <Cell col={3}>
                {headerContent.headerLeft.map((item, key) => {
                  if (key === 0) {
                    return <div className='header-title'>{item.value}</div>;
                  } else {
                    return (
                      <span className='header-title-content'>
                        <span className='active-opacity-text'>{item.title}: </span>
                        <span>{item.value}</span>
                      </span>
                    );
                  }
                })}
              </Cell>
            )}
            {headerContent.headerMiddle.length && (
              <Cell col={3} className='center-align'>
                <div className='center-align'>
                  {headerContent.headerMiddle.map((item, key) => {
                    if (item.id === 1) {
                      return <h2 className=''>{item.value}</h2>;
                    } else if (item.id === 2) {
                      return <h4>Value Added Tax Ledger</h4>;
                    } else {
                      return (
                        <span className='header-title-content no-margin-bottom'>
                          <span className='active-opacity-text'>
                            Report from{' '}
                            <span className='date-rendered'>
                              <span>{item.value.start}</span> - <span>{item.value.end}</span>
                            </span>
                          </span>
                        </span>
                      );
                    }
                  })}
                </div>
              </Cell>
            )}
            {headerContent.headerRight.length && (
              <Cell col={2}>
                <div className='right-align'>
                  {headerContent.headerRight.map((item, key) => {
                    return (
                      <span className='header-right-content'>
                        <span className=''>{item.title}</span>
                        <span>{item.value}</span>
                      </span>
                    );
                  })}
                </div>
              </Cell>
            )}
          </Grid>
        }
      </div>
    </div>
  );
};

ReportHeader.defaultProps = {
  headerContent: {
    headerLeft: [],
    headerMiddle: [],
    headerRight: [],
  },
};
ReportHeader.propTypes = { company: PropTypes.object, };

export default ReportHeader;
