import { compose } from 'recompose';
import StockLedgerDetail from './Detail';
import apiInterceptor from '../../../../api/interceptor';
import { stockLedgerApi } from '../../../common/base.api';

const composedStockLedgerDetails = compose(
  apiInterceptor({ getDetails: stockLedgerApi.getDetails, downloadSkuDetail: stockLedgerApi.downloadSkuDetail }),
)(StockLedgerDetail);

export default composedStockLedgerDetails;
