import React from 'react';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import moment from 'moment/moment';
import { Icon } from '../../../components/BillingMDC';
import { has } from '../../../utils/hasOwnProperty';
import * as filterUtil from '../../../utils/filterUtil';
import { TRANSACTION_TYPE } from '../../../data/enums/enums';

const title = 'Journal Voucher';

const formMapper = (element) => ({
  drAmount: element?.drAmount || 0,
  crAmount: element?.crAmount ||0,
  narration: element?.narration || '',
  customerId:element?.customer?.customerId ||0,
  tagsId:element?.tag?.id_tags ||0,
  partyLedgerId:element?.partyLedgerId ||0,
  partyTaxableAmount:element?.partyTaxableAmount || 0,
});

const getSummaryViewList = (data = {}) => {
  const summaryList = [
    {
      title: 'Narration',
      value: data.narration || '',
    },
  ];

  return summaryList;
};

const getPrintSummaryDetails = (data = {}) => {
  const printSummaryDetails = [{ title: 'Voucher Number', value: data.voucherNumber }];

  return printSummaryDetails;
};
const TRANSACTION_TYPE_ALLOWED= [8,9,10];

const JvDetailBody = ({ dataList,remainingLines,dataSummary,footer =false,onRowClick,summary={} ,ledgerList=[],print=false ,tagList }) => {
  const transactionTypeStatus= TRANSACTION_TYPE_ALLOWED.includes(summary.transactionType );
  return(
    <tbody>
      {dataList.map((data, index) => (
        <tr className=' '>
          <td className='left-align title'>{data.customerTitle}</td>
          <td className='right-align'>{fixedFloatAndCommas(data.drAmount) || 0}</td>
          <td className='right-align'>{fixedFloatAndCommas(data.crAmount) || 0}</td>
          <td className='left-align ref-no'>{data.refNumber}</td>
          <td className='left-align voucher-num'>{data.voucherNumber}</td>
          <td className='left-align tag'>
            {data.tagTitle}
          </td>
          <td className='left-align narration'>{data.narration}</td>
          <td className='left-align print_display_none'>{data?.partyInfo?.title}</td>
          <td className='left-align print_display_none'>{data?.partyInfo?.panNo === '0'? ' ' : data?.partyInfo?.panNo ?  data?.partyInfo?.panNo : ''}</td>
          <td className='right-align print_display_none'>{fixedFloatAndCommas(data.partyTaxableAmount)}</td>
          { transactionTypeStatus &&
        <td className='right-align print_display_none'>
          <Icon name='edit' onClick={() => onRowClick(data, index)} />
        </td>
          }
        </tr>
      ))}
      { footer &&
    <tr className='total'>
      {config.getFooterContent(dataSummary).map((item, index) => (
        <td className={`font-bold ${index !== 0 ? 'right-align' : ''}`}>
          {fixedFloatAndCommas(item.value)}
        </td>
      ))}
      <td colSpan='6'></td>
    </tr>
      }
      {remainingLines && remainingLines.length
        ? remainingLines.map((i) => (
          <tr className='blank-tr'>
            <td></td>
            <td colSpan ='11' className=''></td>
          </tr>
        ))
        : null}
    </tbody>
  )};

const getFooterContent = (data) => {
  const footerList = [
    { title: 'Total', value: 'Total', display: false },
    { title: 'Total Debit:', value: data.drAmount },
    { title: 'Total Credit:', value: data.crAmount },
    { title: 'Difference:', value: data.drAmount - data.crAmount },
  ];
  return footerList;
};

const userDetailMapper = (data = {}) => {
  let userDetail = { id: 'userId', title: '' };
  if (has.call(data, 'actionUserDetail') && data.actionUserDetail) {
    userDetail.title = data.actionUserDetail.name || '';
  } else userDetail.title = data.journalVoucher ? (data.journalVoucher.user ? data.journalVoucher.user.name : '') : '';
  return userDetail;
};

const jvDetailConfig = [
  { id: 2, label: 'ledgers.title', title: 'Ledger', className: '  left-align', sortable: false },
  { id: 3, label: 'dr_amount', title: 'Debit', className: '  right-align', sortable: false },
  { id: 4, label: 'cr_amount', title: 'Credit', className: '  right-align', sortable: false },
  { id: 5, label: 'ref_number', title: 'Ref No', className: '  left_align', sortable: false },
  { id: 7, label: 'voucher_number', title: 'Voucher No.', className: '  left_align', sortable: false },
  { id: 10, label: 'tag', title: 'Tag', className: '  left-align tag', sortable: false },
  { id: 6, label: 'narration', title: 'Narration', className: '  left-align', sortable: false },
  { id: 8, label: 'partyInfo.title', title: 'Party Name', className: 'left-align print_display_none', sortable: false },
  {
    id: 9,
    label: 'partyInfo.panNo',
    title: 'Party PAN No.',
    className: 'left-align print_display_none',
    sortable: false,
  },
  {
    id: 10,
    label: 'party_taxable_amount',
    title: 'Taxable Amount',
    className: 'right-align print_display_none',
    sortable: false,
  },
];
const  newJvDetailConfig= [
  ...jvDetailConfig,
  {
    id: 11,
    label: '',
    title: '',
    className: '  print_display_none',
    sortable: false,
  },
];

const signatureTitleList = [
  { id: 0, title: 'Received By', value: '' },
  {
    id: 1,
    title: 'Prepared By',
    value: '',
  },
  { id: 3, title: 'ApprovedBy', value: '' },
];

const lengthyAttributes = ['tagTitle', 'customerTitle', 'narration' ];

const getData = (data, label) => {
  switch(label) {
  case 'narration': return (data.narration) || null;
  case 'tagTitle': return (data.tag && data.tag.title) || null;
  case 'customerTitle': return (data.customer && data.customer.title) || null;
  default:
    return null;
  }
}
const formValidationFieldList = [{ title: 'drAmount'  }, { title: 'crAmount' } ,
// { title:'narration' }
];

const printEnums = {
  customerTitle: 20,
  narration: 20,
  tagTitle: 20,
  lengthyAttributes,
  withoutFooterLimit: 46,
  withFooterLimit: 43,
};
const getApiTransformedData =(data) => {
  const transformedJVList  = data.map((d) => {
    return {
      id_journal_voucher_detail: d?.idJournalVoucherDetail || 0,
      dr_amount :d?.drAmount || 0,
      cr_amount :d?.crAmount || 0,
      narration:d?.narration || '',
      tagid:d?.tag?.id_tags || 0,
      ledger_id:d?.customer?.customerId || '',
      party_id:d?.partyLedgerId || 0,
      party_taxable_amount:d?.partyTaxableAmount || 0,
    }
  })
  return transformedJVList;
}
const updateApiTransformedData = (data,summary,isCapitalPurchase) => {
  const transformedJV = {
    inputDetails:[...getApiTransformedData(data)],
    total_credit_amount:summary.totalCrAmount,
    total_debit_amount:summary.totalDrAmount,
    narration:summary.mainNarration,
    transaction_type: TRANSACTION_TYPE.JV,
    document_date: filterUtil.formatToNumericDate(summary.documentDate),
    is_capital_purchase:isCapitalPurchase,
  };
  return transformedJV;

};

const config = {
  title,
  header: jvDetailConfig,
  newHeader:newJvDetailConfig,
  getTableBody: JvDetailBody,
  getFooterContent,
  getSummaryViewList,
  userDetailMapper,
  getPrintSummaryDetails,
  signatureTitleList,
  printEnums,
  lengthyAttributes,
  getData,
  uniqueIdGetter: (i) => i.idJournalVoucherDetail,
  formValidationFieldList,
  formMapper,
  updateApiTransformedData,
  TRANSACTION_TYPE_ALLOWED,
};

export default config;
