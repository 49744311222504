import styled from 'styled-components';

const PaginationStyled = styled.div`
  .pagination-wrapper {
    text-align: center;
    font-size: 13px;
    line-height: 48px;
    padding: 0 24px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.54);
  }
  .pagination-wrapper i {
    cursor: pointer;
    opacity: var(--hover-opacity);
    margin: 0 !important;
  }
  .pagination-total-pages {
    /*background: url(./svg/down_arrow.svg) no-repeat right;*/
    min-width: 40px;
    height: 48px;
  }
  .pagination-wrapper i:hover,
  .pagination-page:hover {
    color: var(--secondary-color);
    transition: background-color 1s ease !important;
  }
  .pagination-page {
    cursor: pointer;
    font-size: 13px;
    margin-left: 8px;
    background: none;
    color: var(--secondary-color);
    opacity: var(--hover-opacity);
  }
  .pagination-page:last-of-type {
    margin-right: 8px;
  }
  .pagination-page-active {
    color: var(--secondary-color);
    opacity: var(--active-opacity);
  }
  .pagination-wrapper .custom-select {
    display: inline-block;
    float: left;
    background: url('./svg/arrow_drop_down.svg') no-repeat right;
    min-width: 42px;
    height: 48px;
    font-size: 13px;
    border-bottom: none;
  }

  .pagination-wrapper .custom-select-option {
    display: inline-block;
    line-height: 48px;
    float: left;
    font-weight: 500;
  }
  .pagination-display-label {
    color: var(--secondary-color);
    opacity: var(--hover-opacity);
    font-size: 13px;
  }
  .pagination-label {
    cursor: pointer;
    color: var(--secondary-color);
    opacity: var(--inactive-opacity);
  }
  .pagination-label:hover {
    opacity: var(--hover-opacity);
  }

  /*For the dropdown in pagination*/
  .pagination-wrapper .custom-select-wrapper > .custom-options-list-bottom,
  .pagination-wrapper .custom-select-wrapper > .custom-options-list-top {
    width: 70px !important;
  }
  .pagination-wrapper .custom-select-wrapper > .custom-options-list-bottom li,
  .pagination-wrapper .custom-select-wrapper > .custom-options-list-top li {
    padding: 0px 10px;
  }
  .pagination-wrapper .mdc-layout-grid {
    padding: 0px !important;
  }

  .page-leftside-wrapper-show {
    float: left;
    margin-right: 10px;
    font-size: 13px;
  }
  .nv-margin {
    margin-left: -60px;
  }
`;
export default PaginationStyled;
