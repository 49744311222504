import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../components/TableHeader';
import * as appConfig from '../../../config';
import history from '../../../utils/history';

import {
  Icon,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Radio,
  RadioGroup,
  Snackbar,
} from 'react-mdc-web';
import {
  CreateStockJournalTableBody,
  createStockJournalConfig,
} from './CreateStockJournalConfig';
import * as httpUtils from '../../../utils/httpUtils';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import RadioButtonList from '../../../components/RadioButtonList/RadioButtonList';
import { STOCK_JOURNAL_BASE } from '../../../data/enums/Route';
import {
  buApi,
  catalogsApi,
  checkIfApiCallSuccess,
} from '../../../views/common/base.api';
// const businessUnitID = JSON.parse(localStorage.getItem('business_id'));

import { STOCK_TYPE } from '../../../data/enums/enums';
import { handleBatchSelect } from '../../../utils/handlers';
import {quantityDefaultValues} from "../../../views/fundamentals/purchaseReturn/create/config";
import {getQuantityValidationStatus} from "../../../views/common/common";

const message = {
  success: 'Stock Journal created successfully.',
  error: '!Error while creating Stock Journal',
};

//invoice id is present in the list.
class CreateStockJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      qtyValidation: false,
      createModalOpen: false,
      editModalOpen: false,
      formEmptyField: false,
      deleteModalOpen: false,
      showMessage: false,
      saveDisable: false,
      success: true,
      businessUnitId: '',
      message: message.success,
      indexInInvoiceList: 0,
      stockQuantity:quantityDefaultValues,
      createSKU: {
        brand: '',
        brandTitle: '',
        sku_id: 0,
        skuTitle: '',
        batch_id: '',
        batchTitle: '',
        type: STOCK_TYPE.INWARD,
        typeTitle: 'Inward',
        remarks: '',
        normal: 0,
        damage: 0,
        expiry: 0,
        shortage: 0,
        quantity: 0,
      },
      invoiceList: [],
      formValidateFieldArray: ['sku_id', 'batch_id', 'remarks'],
      headerTitleToView: createStockJournalConfig.headerTitleToView,
      brandList: [],
      skuList: [],
      batchList: [],
      buList: [],
    };
  }

  // after mounted get the customer list.
  componentDidMount() {
    this.getBUList();
  }

  handleBUClick = id => {
    this.setState({ businessUnitId: id }, () => {
      this.getBrandList();
    });
  };

  resetStockAndSkuQuantities = () => {
    const { createSKU } = this.state;
    createSKU.normal = 0;
    createSKU.expiry = 0;
    createSKU.shortage = 0;
    createSKU.quantity = 0;
    createSKU.damage = 0;
    this.setState({ stockQuantity: quantityDefaultValues, createSKU });
  }

  onChange = (field, value, detail = {}) => {
    let { createSKU } = this.state;
    createSKU[field] = value;
    createSKU.quantity = createSKU.normal + createSKU.damage + createSKU.expiry;
    if (field === 'brand') {
      this.resetSKUDialog();
      this.getSKUListUnderBrand(value);
      this.resetStockAndSkuQuantities();
    }

    if (field === 'sku_id') {
      createSKU.skuTitle = detail.title || '';
      createSKU = {
        ...(handleBatchSelect(
          createSKU,
          (detail.batchList.length > 0 && detail.batchList[0]) || {}
        ) || createSKU),
      };
      this.setState({ batchList: detail.batchList || [] },
        () => {
        if(createSKU.type === '1') this.getAvailableStock(createSKU.sku_id, createSKU.batch_id)
        });
    }

    if (field === 'batch_id') {
      createSKU = { ...(handleBatchSelect(createSKU, detail) || createSKU) };
      this.resetStockAndSkuQuantities();
      this.getAvailableStock(createSKU.sku_id, createSKU.batch_id);
    }

    if (field === 'normal' || field === 'damage' || field === 'expiry') {
      createSKU.promotion_discount = 0;
      if(createSKU.type === '1') this.checkQuantity()
    }

    // update the amount with rate and amount.
    createSKU.amount = Number(
      Number(createSKU.quantity * createSKU.rate).toFixed(2)
    );
    const discount =
      Number(createSKU.promotion_discount) + Number(createSKU.trade_discount);
    createSKU.net_amount = Number(
      Number(createSKU.amount - discount).toFixed(2)
    );

    this.setState({ createSKU });
  };

  checkQuantity = () => {
    const {stockQuantity, createSKU} = this.state;
    const { condition, breakingQuantityLabel } = getQuantityValidationStatus(stockQuantity, createSKU);

    if (!condition) {
      this.setState({
        showMessage: true,
        success: false,
        message: `Should not be greater than available quantity (${breakingQuantityLabel})`,
      });
      return false;
    }
    return true;
  };

  /*checkQuantity =() => {
    const { createSKU,stockQuantity } = this.state;

    if ((createSKU.normal + createSKU.damage + createSKU.expiry) > stockQuantity) {
      this.setState({
        qtyValidation: true,
      });
      return false;
    }else{
      this.setState({
        qtyValidation: false,
      });
    return true;
  }
  };*/

  getAvailableStock = (skuId, batchId) => {
    const { businessUnitId, createSKU } = this.state;
    catalogsApi
      .getBatchAvailableStock({ business_id: businessUnitId, sku_id: skuId, batch_id: batchId })
      .then(response => {
        if (response.data.length > 0) {
          const res = response.data[0];
          const stockQuantity = {
            normal: res.available_quantity ? parseInt(res.available_quantity, 10) : 0,
            damage: res.available_damage ? parseInt(res.available_damage, 10): 0,
            expiry: res.available_expiry ? parseInt(res.available_expiry, 10): 0,
            shortage: res.available_shortage ? parseInt(res.available_shortage, 10): 0,
          };
          createSKU.normal = stockQuantity.normal;
          createSKU.damage = stockQuantity.damage;
          createSKU.expiry = stockQuantity.expiry;
          createSKU.shortage = stockQuantity.shortage;
          createSKU.quantity = createSKU.normal + createSKU.damage + createSKU.expiry;
          this.setState({ stockQuantity, createSKU });
        } else {
          this.setState({
            showMessage: true,
            message: 'SKU stock quantity unknown',
            stockQuantity: quantityDefaultValues,
          });
        }
      }).catch((err) => {
      this.setState({
        showMessage: true,
        message: '!Error while fetching available stock quantity',
        stockQuantity: quantityDefaultValues
      });
    });
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data, index) => {
    const createSKU = {
      brand: data.brand,
      brandTitle: data.brandTitle,
      sku_id: data.sku_id,
      skuTitle: data.skuTitle,
      batch_id: data.batch_id,
      batchTitle: data.batchTitle,
      type: data.type,
      typeTitle: data.typeTitle,
      remarks: data.remarks,
      normal: data.normal,
      damage: data.damage,
      expiry: data.expiry,
      shortage: data.shortage,
      quantity: data.quantity,
    };
    // this.setState({editModalOpen: true, indexInInvoiceList: index});
    this.setState({
      createSKU: createSKU,
      editModalOpen: true,
      indexInInvoiceList: index,
    });
  };

  // create the sales Return, update the discount value
  createStockJournal = () => {
    const { invoiceList, createSKU } = this.state;
    invoiceList.push(createSKU);
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
    });
  };

  // editStockJournal
  editStockJournal = (data, index) => {
    const { invoiceList, createSKU } = this.state;
    // update the discount value
    // createSKU.discount = createSKU.quantity * createSKU.rate - createSKU.price;
    // createSKU.amount = (Number(createSKU.quantity) * Number(createSKU.rate)).toFixed(2);
    invoiceList[index] = createSKU;
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
    });
  };

  // delete the sales Return for particular sku, and update the total value.
  deleteGRNItem = index => {
    const { invoiceList, indexInInvoiceList } = this.state;
    if (invoiceList.length >= indexInInvoiceList) {
      invoiceList.splice(indexInInvoiceList, 1);
      this.setState({ invoiceList: invoiceList }, () => {
        this.closeModal();
      });
    }
  };

  // close the modal resetting  all the value
  closeModal = () => {
    this.setState({
      createModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      qtyValidation: false,
    });
    this.resetSKUDialog();
  };

  // reset SKU Dialog
  resetSKUDialog = () => {
    const createSKU = {
      brand: '',
      brandTitle: '',
      sku_id: '',
      skuTitle: '',
      batch: '',
      type: '0',
      typeTitle: '',
      remarks: '',
      normal: 0,
      damage: 0,
      expiry: 0,
      shortage: 0,
      quantity: 0,
    };
    this.setState({ createSKU: createSKU, formEmptyField: false });
  };

  // handle on modal ok click
  handleModalOkClick = () => {
    const {
      createModalOpen,
      editModalOpen,
      createSKU,
      formValidateFieldArray,
    } = this.state;
    if (createModalOpen || editModalOpen) {
      const flag = createSKU.quantity !== 0;
      const valid = this.validateForm(
        createSKU,
        formValidateFieldArray,
        'formEmptyField',
        flag
      );
     // const qtyValidation = createSKU.type === '1' ? this.checkQuantity() : true;
      if (valid) {
        if (createModalOpen) {
          this.createStockJournal();
        } else {
          this.editStockJournal(
            this.state.createSKU,
            this.state.indexInInvoiceList
          );
        }
      }
    } else {
      this.deleteGRNItem();
    }
  };


  validateForm = (formObject, config, flag, zeroFlag) => {
    const data = Object.keys(formObject).some((k) => {
      if (config.indexOf(k) > -1) {
        let value = formObject[k];
        const conditionParameter = zeroFlag
          ? value === ''
          : !value || value === '0';
        if (conditionParameter) {
          this.setState({ [flag]: true });
          return true;
        }
      }
    });
    return !data;
  };

  directToMainPage = () => {
    const { showMessage, success } = this.state;
    if (!showMessage && success) {
      history.push(`/${STOCK_JOURNAL_BASE}`);
    }
  };

  // get the brand list
  getBrandList = () => {
    const { businessUnitId } = this.state;
    catalogsApi
      .getBrandList({ business_id: businessUnitId })
      .then((response) => {
        if (checkIfApiCallSuccess(response)) {
          this.setState({ brandList: response.data.list });
        }
      });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = (brandId) => {
    const { businessUnitId } = this.state;
    catalogsApi
      .getSkuUnderBrand({ business_id: businessUnitId, brand_id: brandId })
      .then((response) => {
        if (checkIfApiCallSuccess(response)) {
          this.setState({ skuList: response.data.list });
        }
      });
  };

  getBUList = () => {
    buApi.getList().then((response) => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  handleSaveClick = () => {
    const { invoiceList } = this.state;
    this.setState({ loading: true,
      saveDisable: true, });
    const apiSkuList = invoiceList.map((sku) => ({
      sku_id: sku.sku_id,
      batch_id: sku.batch_id,
      normal: sku.normal,
      damage: sku.damage,
      expiry: sku.expiry,
      shortage: sku.shortage,
      quantity: sku.quantity,
      //type: Number(sku.type),
      remarks: sku.remarks,
    }));
    const object = {
      business_id: this.state.businessUnitId,
      entered_by: 1,
      skuList: apiSkuList,
    };
    // todo : backEnd filters the unnecessary object attributes.

    /** object['skuList'] = newInvoiceList.map(sku => {
      const skuDetails = Object.assign({}, sku);
      delete skuDetails['brand'];
      delete skuDetails['brandTitle'];
      delete skuDetails['skuTitle'];
      delete skuDetails['typeTitle'];
      return skuDetails;
    });*/
    httpUtils
      .post(`${appConfig.baseUrl}stock-journals`, object)
      .then(response => {
        this.setState({ loading: false });
        if (response.success) {
          this.setState({
            showMessage: true,
            success: true,
            message: message.success,
            saveDisable: true,
          });
          /** alert('Stock Journal Created');
        history.push('/stock-journal');*/
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          showMessage: true,
          success: false,
          message: message.error,
          saveDisable: false,
        });
      });
  };

  handleCancelClick = () => {
    history.push(`/${STOCK_JOURNAL_BASE}`);
  };

  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInInvoiceList: index });
  };

  onRadioChange = value => {
    const { createSKU } = this.state;
    createSKU.type = value;
    if (createSKU.type === '0') {
      createSKU.typeTitle = 'Inward';
    } else {
      createSKU.typeTitle = 'Outward';
    }
    this.setState({ createSKU: createSKU });
  };

  getStockQtyMessage = () => {
    const { stockQuantity, createSKU } = this.state;
    if (createSKU.type === '1') {
      return `Sum should not be greater than available quantity i.e ${stockQuantity}`;
    }
    return '';
  };

  render() {
    const {
      loading,
      invoiceList,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      showMessage,
      saveDisable,
      buList,
      createSKU,
      formEmptyField,
      qtyValidation,
      stockQuantity
    } = this.state;
    return (
      <>
        {loading && (
          <div className='linear-progress-wrapper temp-progress-wrapper'>
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div
          className={`stock-journal-create${loading ? 'clickable-false' : ''}`}
        >
          <div className='message-snackbar'>
            {
              <Snackbar
                className={this.state.success ? 'success-message' : ''}
                timeout={this.state.success ? 1000 : 4000}
                onTimeout={() => {
                  this.setState({ showMessage: false }, () => {
                    this.directToMainPage();
                  });
                }}
                open={showMessage}
              >
                {this.state.message}
              </Snackbar>
            }
          </div>
          <div className='card-header-bar' ref='tableReference'>
            <h2>New Stock Journal</h2>
          </div>
          <div className='card-body'>
            <div className='radio-list-wrapper'>
              {buList.length && (
                <RadioButtonList
                  data={buList}
                  disableRosiaBu
                  handleBuClick={this.handleBUClick}
                />
              )}
            </div>
            <div className={this.state.businessUnitId ? '' : 'block-overlay'}>
              <div className='table-wrapper'>
                <div ref='fixedTableBody' className='fixed-table-wrapper'>
                  <table>
                    <TableHeader
                      headerDetails={createStockJournalConfig.headerDetails}
                      filterHeaderLabel={false}
                      handleSorting={this.handleTableSorting}
                    />
                    {invoiceList.map((data, key) => (
                      <tbody>
                        <CreateStockJournalTableBody
                          data={data}
                          index={key}
                          deleteSalesInvoice={this.deleteSalesInvoice}
                          handleEditIconClick={this.handleEditIconClick}
                          handleDeleteIconClick={this.handleDeleteIconClick}
                        />
                      </tbody>
                    ))}
                  </table>
                </div>

                {/*Fab Icon*/}
                <div className='mini-fab-button'>
                  <Fab
                    mini
                    onClick={() => this.setState({ createModalOpen: true })}
                  >
                    <Icon name='add' />
                  </Fab>
                </div>
              </div>
            </div>
            {/* Sales invoice table summary goes over here.*/}
          </div>
        </div>
        <div className='newinvoice-btn-wrapper'>
          <Grid>
            <Cell col={8}></Cell>
            <Cell col={4} className='right-align'>
              <Button
                accent
                className='cancel-btn modal-btn'
                onClick={() => {
                  this.handleCancelClick();
                }}
              >
                Cancel
              </Button>

              <Button
                accent
                className={
                  invoiceList.length
                    ? 'save-btn modal-btn'
                    : 'save-btn modal-btn btn-disabled'
                }
                disabled={saveDisable}
                onClick={() => {
                  this.handleSaveClick();
                }}
              >
                Save
              </Button>
            </Cell>
          </Grid>
        </div>

        {/*Begin of the dialog Body*/}
        <Dialog
          open={createModalOpen || editModalOpen || deleteModalOpen}
          onClose={() => {
            this.closeModal();
          }}
          className='stock-journal-dialog'
        >
          <DialogHeader>
            {createModalOpen && <DialogTitle>Create Stock Journal</DialogTitle>}
            {editModalOpen && <DialogTitle>Edit Stock Journal</DialogTitle>}
            {deleteModalOpen && <DialogTitle>Delete Stock Journal</DialogTitle>}
          </DialogHeader>
          {!deleteModalOpen && (
            <DialogBody>
              <div className='dialog-upperpart'>
                <div className='row_one row'>
                  {/*<>
                    <div>
                      <label>Stock Detail</label>
                    </div>
                    <div className='stock-detail-radio'>
                      <RadioGroup
                        onChange={({ target: { value } }) => {
                          this.onRadioChange(value);
                        }}
                        name='inward-outward'
                        value={this.state.createSKU.type}
                      >
                        <Radio value='0'>Inward</Radio>
                        <Radio value='1'>Outward</Radio>
                      </RadioGroup>
                    </div>
                  </>*/}

                  <Grid className='grid-padding'>
                    <Cell col={6} tablet={8}>
                      <label>Brand</label>
                      <BillingSelect
                        name='form-field-name'
                        param='brand'
                        clearable={false}
                        value={this.state.createSKU.brand}
                        valueKey='businessCatalogDetailId'
                        labelKey='title'
                        options={this.state.brandList}
                        handleChange={this.onChange}
                        required={true}
                      />
                    </Cell>

                    <Cell col={6} tablet={8}>
                      <label>SKU</label>
                      <BillingSelect
                        name='form-field-name'
                        param='sku_id'
                        clearable={false}
                        value={this.state.createSKU.sku_id}
                        valueKey='businessSkuId'
                        labelKey='title'
                        options={this.state.skuList}
                        handleChange={this.onChange}
                        required
                        emptyField={this.state.formEmptyField}
                        multipleParam
                      />
                    </Cell>
                    <Cell col={4} tablet={8}>
                      <label>Batch</label>
                      <BillingSelect
                        name='form-field-name'
                        param='batch_id'
                        clearable={false}
                        value={this.state.createSKU.batch_id}
                        valueKey='businessBatchId'
                        labelKey='title'
                        options={this.state.batchList}
                        handleChange={this.onChange}
                        multipleParam
                        required={true}
                        emptyField={this.state.formEmptyField}
                      />
                    </Cell>
                  </Grid>
                </div>
                <div className='row_two row'>
                  <label htmlFor=''>Quantity(Pcs)</label>
                  <Grid className='grid-padding'>
                    <Cell col={3} tablet={8}>
                      <BillingTextField
                        value={this.state.createSKU.normal}
                        handleChange={this.onChange}
                        floatingLabel='Sellable'
                        param='normal'
                        required={false}
                        zeroError={false}
                        type='number'
                        rule='isInt'
                        className='billing-required right-side-gap'
                       /* errorMessage={
                          qtyValidation ? this.getStockQtyMessage() : 'Should not be empty'
                        }
                        invalidError={
                          qtyValidation
                        }
                        emptyField={this.state.formEmptyField}*/
                      />
                      <span>{`Available Quantity: ${stockQuantity.normal}`}</span>
                    </Cell>
                      <Cell col={3} tablet={8}>
                        <BillingTextField
                            value={this.state.createSKU.damage}
                            handleChange={this.onChange}
                            floatingLabel='Damage '
                            param='damage'
                            required={false}
                            zeroError={false}
                            type='number'
                            rule='isInt'
                            className='billing-required right-side-gap'
                            /*errorMessage={
                              qtyValidation && this.getStockQtyMessage()
                            }
                            invalidError={
                              qtyValidation
                            }*/
                        />
                        <span>{`Damage Stock: ${stockQuantity.damage}`}</span>
                      </Cell>
                      <Cell col={3} tablet={8}>
                        <BillingTextField
                            value={this.state.createSKU.expiry}
                            handleChange={this.onChange}
                            floatingLabel='Expiry '
                            param='expiry'
                            required={false}
                            zeroError={false}
                            type='number'
                            rule='isInt'
                            className='billing-required right-side-gap'
                            /*errorMessage={
                              qtyValidation && this.getStockQtyMessage()
                            }
                            invalidError={
                              qtyValidation
                            }*/
                        />
                        <span>{`Expiry Stock: ${stockQuantity.expiry}`}</span>
                      </Cell>
                      <Cell col={3} tablet={8}>
                        <BillingTextField
                            value={this.state.createSKU.shortage}
                            handleChange={this.onChange}
                            floatingLabel='Shortage'
                            param='shortage'
                            required={false}
                            zeroError={false}
                            type='number'
                            className='billing-required right-side-gap'
                        />
                        <span>{`Shortage Stock: ${stockQuantity.shortage}`}</span>
                      </Cell>
                    <Cell col={4} tablet={8} className='display_none'>
                      <BillingTextField
                        value={this.state.createSKU.quantity}
                        handleChange={this.onChange}
                        floatingLabel='Quantity (pcs)'
                        param='quantity'
                        required={true}
                        zeroError={true}
                        type='number'
                        rule='isInt'
                        className='billing-required right-side-gap'
                        emptyField={this.state.formEmptyField}
                        errorMessage={
                          this.state.createSKU.quantity === 0
                            ? 'Should not be 0'
                            : 'Should not be empty'
                        }
                      />
                    </Cell>
                  </Grid>
                </div>
                <div className='row_three row'>
                  <Grid className='grid-padding'>
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={this.state.createSKU.remarks}
                        param='remarks'
                        floatingLabel='Remarks'
                        required={true}
                        className='billing-required'
                        emptyField={this.state.formEmptyField}
                        handleChange={this.onChange}
                      />
                    </Cell>
                  </Grid>
                </div>
              </div>
            </DialogBody>
          )}

          {deleteModalOpen && (
            <div className='default-margin-24'>
              Are you sure you want to delete ?
            </div>
          )}

          <DialogFooter>
            <Button
              accent
              className='dialog-cancel modal-btn'
              onClick={() => {
                this.closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              accent
              className='dialog-ok modal-btn'
              onClick={() => {
                this.handleModalOkClick();
              }}
            >
              Ok
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

CreateStockJournal.contextTypes = {
  router: PropTypes.object,
};

export default CreateStockJournal;
