import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

import { STOCK_TYPE } from '../../../data/enums/enums';

class CreateStockJournalTableBody extends Component {
  render() {
    const { data, index } = this.props;
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{data.skuTitle}</td>
        <td className="left-align">{data.batchTitle}</td>
        <td className="right-align">{data.normal}</td>
        <td className="right-align">{data.damage}</td>
        <td className="right-align">{data.expiry}</td>
        <td className="right-align">{data.quantity}</td>
        <td className="right-align">{data.shortage}</td>
          {/*  <td>{data.type === STOCK_TYPE.INWARD.toString() ? 'Inward' : 'Outward' }</td>*/}
        <td className="left-align">{data.remarks}</td>
        <td className="right-align">
          <Icon name='edit' onClick={() => this.props.handleEditIconClick(data, index)}/>
          <Icon name='delete' onClick={() => this.props.handleDeleteIconClick(index)}/>
        </td>
      </tr>
    );
  }
}

CreateStockJournalTableBody.defaultProps = { enableUpdate: true, };

CreateStockJournalTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteIconClick: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  enableUpdate: PropTypes.bool,
};

const createStockJournalConfig = {
  headerDetails: [
    { id: 1, label: 'test', title: 'SN', className: null, sortable: false },
    { id: 2, label: 'skuTitle', title: 'SKU', className: null, sortable: false },
    { id: 3, label: 'batchTitle', title: 'Batch', className: null, sortable: false },
    { id: 3, label: 'normal', title: 'Sellable', className: 'right-align', sortable: false },
    { id: 3, label: 'damage', title: 'Damage', className: 'right-align', sortable: false },
    { id: 3, label: 'expiry', title: 'Expiry', className: 'right-align', sortable: false },
    { id: 3, label: 'quantity', title: 'Quantity', className: 'right-align', sortable: false },
    { id: 3, label: 'shortage', title: 'Shortage', className: 'right-align', sortable: false },
      /*  { id: 5, label: 'typeTitle', title: 'Status', className: 'left-align', sortable: false },*/
    { id: 6, label: 'remarks', title: 'Remarks', className: 'left-align', sortable: false },
    { id: 7, label: 'remarks', title: '', className: 'right-align', sortable: false }
  ]
};

export { createStockJournalConfig, CreateStockJournalTableBody };
