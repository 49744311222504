import React from 'react';
import PropTypes from 'prop-types';
import { formatToReadableDate } from '../../../utils/filterUtil';

const promotionTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.length
      ? dataList.map((data) => (
        <tr onClick={() => onRowClick(data.id)}>
          <td className='left-align'>{data.id}</td>
          <td className='left-align'>{data.title}</td>
          <td key={`outlet-${data.id}-status`} className='text-center'>
            <span className={`status-label ${data.active ? 'active' : 'passive'}`}>
              {data.active ? 'ACTIVE' : 'INACTIVE'}
            </span>
          </td>
          <td className='left-align'>{data.type}</td>
          <td className='left-align'>{formatToReadableDate(data.startDate)}</td>
          <td className='left-align'>{formatToReadableDate(data.endDate)}</td>
        </tr>
      ))
      : null}
  </tbody>
);

promotionTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const promotionConfig = [
  { id: 1, label: 'id', title: 'id' },
  { id: 2, label: 'title', title: 'Title' },
  { id: 3, label: 'status', title: 'Status', className: 'text-center' },
  { id: 4, label: 'promotion_type', title: 'Promotion Type' },
  { id: 5, label: 'start_date', title: 'Start Date' },
  { id: 6, label: 'end_date', title: 'End Date' },
];

const config = {
  header: promotionConfig,
  getTableBody: promotionTableBody,
};

export default config;
