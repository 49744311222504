import React from 'react';
import { ACTION, PRINT_INFO_DEFAULT } from '../../../data/enums/enums';
import { Icon } from '../../../components/BillingMDC';
import { VALIDATION_TYPES } from '../../common/validation';

const defaultBillFormat = {
  jv_prefix: '',
  invoice_suffix: '',
  invoice_prefix: '',
  srn_prefix: '',
  srn_suffix: '',
  prn_prefix: '',
  prn_suffix: '',
  purchase_suffix: '',
  purchase_prefix: '',
  payment_prefix: '',
  payment_suffix: '',
  abi_prefix: '',
  abi_suffix: '',
  ce_prefix: '',
  ce_suffix: '',
  pir_prefix: '',
  pir_suffix: '',
  credit_note_prefix: '',
  credit_note_suffix: '',
  debit_note_prefix: '',
  debit_note_suffix: '',
};
const defaultPrintInfo = PRINT_INFO_DEFAULT;
const getbillFormatPrefix = (billFormat = {}) => {
  const details = {
    jv_prefix: billFormat.jv_prefix || '',
    invoice_prefix: billFormat.invoice_prefix || '',
    srn_prefix: billFormat.srn_prefix || '',
    prn_prefix: billFormat.prn_prefix || '',
    purchase_prefix: billFormat.purchase_prefix || '',
    payment_prefix: billFormat.payment_prefix || '',
    abi_prefix: billFormat.abi_prefix || '',
    ce_prefix: billFormat.ce_prefix || '',
    pir_prefix: billFormat.pir_prefix || '',
    credit_note_prefix: billFormat.credit_note_prefix || '',
    debit_note_prefix: billFormat.debit_note_prefix || '',
  };
  return details;
};
const getCompanyData = (company = {}) => {
  const details = {
    title: company.title || '',
    address: company.address || '',
    panNumber: company.pan_number || '',
    phone: company.phone,
    buList: [],
    billFormat: getbillFormatPrefix(company.bill_format) || defaultBillFormat,
    printInfo: company.print_info || defaultPrintInfo,
    periodId:company.periodId || 0,
  };
  return details;
};

const getApiTransformedData = (company, action = ACTION.ADD) => {
  const details = {
    title: company.title,
    address: company.address,
    pan_number: company.panNumber,
    phone: company.phone,
    email:company.email,
    bill_format: getbillFormatPrefix(company.billFormat),
    bu_list: company.buList,
    print_info: company.printInfo || defaultPrintInfo,
    period_id:company.periodId,
  };

  return details;
};

const formValidationFields = [
  { title: 'title', },
  { title: 'address', },
  { title: 'city', },
  {
    title: 'panNumber',
  },
  { title: 'phone', },
  { title: 'period_id', },
];
const formWithEmailValidation = [
  {
    title:'externalEmail',
    type:VALIDATION_TYPES.EMAIL_RULE,
  }
];

const billFormatValidationField = [
  { title: 'jv_prefix' },
  { title: 'jv_suffix' },
  { title: 'invoice_prefix' },
  { title: 'invoice_suffix' },
  { title: 'srn_prefix' },
  { title: 'srn_suffix' },
  { title: 'prn_prefix' },
  { title: 'prn_suffix' },
  { title: 'purchase_prefix' },
  { title: 'purchase_suffix' },
  { title: 'payment_prefix' },
  { title: 'payment_suffix' },
  { title: 'credit_note_prefix' },
  { title: 'credit_note_suffix' },
  { title: 'debit_note_prefix' },
  { title: 'debit_note_suffix' },
];

const validationFields = {
  form: formValidationFields,
  billFormat: billFormatValidationField,
};

const CustomerLedgerBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr>
        <td className='left-align'>{data.idLedger}</td>
        <td className='left-align'>{data.title}</td>
        <td className='left-align'>{data.address}</td>
        <td className='left-align'>{data.panNo}</td>
        <td className='left-align'>{data.phoneNumber}</td>
        <td className='left-align'>{data.creditDay}</td>
        <td className='left-align'>{data.creditLimit ? data.creditLimit.toLocaleString() : data.creditLimit}</td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => onRowClick(data, index)} />
        </td>
      </tr>
    ))}
  </tbody>
);

const customerLedgerConfig = [
  {
    id: 1,
    label: 'customerId',
    title: 'Customer ID',
    className: 'cursor-pointer left-align',
    sortable: true,
  },
  {
    id: 2,
    label: 'name',
    title: 'Title',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 3,
    label: 'address',
    title: 'Address',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 4,
    label: 'panNo',
    title: 'PAN No',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 5,
    label: 'phoneNumber',
    title: 'Phone No',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 6,
    label: 'creditDay',
    title: 'Credit Day',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 6,
    label: 'creditLimit',
    title: 'Credit Limit',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 7,
    label: 'action',
    title: '',
    className: 'right-align',
    sortable: false,
  },
];

const config = {
  header: customerLedgerConfig,
  getTableBody: CustomerLedgerBody,
  getDetail: getCompanyData,
  getApiTransformedData,
  validationFields,
  formWithEmailValidation,
};

export default config;
