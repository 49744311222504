import React, { Component } from 'react';
import * as httpUtils from '../../../utils/httpUtils';
import TableHeader from '../../../components/TableHeader';
import * as appConfig from '../../config';

import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  FormField,
  Checkbox,
} from 'react-mdc-web';
import Select from 'react-select';
import serverCall from '../../../utils/filterPromise';
import * as appConstants from '../../config';
import { createSalesInvoiceConfig, CreateSalesInvoiceTableBody } from './CreateSalesInvoiceConfig';

const businessUnitID = JSON.parse(localStorage.getItem('business_id'));
const userInfo = JSON.parse(localStorage.getItem('user_info'));

const options = [
  { value: 'one', label: 'One' },
  { value: 'two', label: 'Two' },
  { value: 'three', label: 'Three' },
  { value: 'four', label: 'Four' },
];

const discountOptions = [
  { value: 'per', label: 'in %' },
  { value: 'rs', label: 'in Rs' },
];
const quantityTypeOptions = [
  { value: 'pcs', label: 'Pcs' },
  { value: 'case', label: 'Case' },
];

//inject customer ref id , and invoice_number in each of the details.

class PrintSalesInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      indexInInvoiceList: 0,
      cashDiscountCheckBoxFlag: false,
      tradeDiscountCheckBoxFlag: false,
      createSKU: {
        brand: '',
        sku: '',
        sales_id: 0,
        business_id: businessUnitID,
        order_id: 0,
        sku_id: 0,
        rate: 10,
        quantity: 0,
        batch: '',
        amount: 0,
        promotion_discount: 0,
        bill_discount: 0,
        trade_discount: 0,
        net_amount: 0,
        vat: 0,
      },
      customerDetails: {
        id: '',
        name: '',
        address: '',
        panNo: '',
      },
      billSummary: {
        invoiceGrossValue: 0,
        cashDiscountParam: discountOptions[1].value,
        cashDiscount: 0,
        cashDiscountValue: 0,
        tradeDiscountParam: discountOptions[0].value,
        tradeDiscount: 0,
        tradeDiscountValue: 0,
        promotionDiscountValue: 0,
        vatAmount: 0,
        invoiceNetValue: 0,
      },
      invoiceList: [],
      customerList: [],
      brandList: [],
      skuList: [],
    };
  }

  //return net value based upon parameters passed
  getDiscountedValue = (grossValue, parameter, parameterValue) => {
    let discountedValue = 0;
    switch (parameter) {
      case 'rs':
        discountedValue = parameterValue;
        break;
      case 'per':
        discountedValue = (parameterValue / 100) * grossValue;
        break;
      default:
        break;
    }
    return discountedValue;
  };

  //handle when invoice item number changes or updates.
  handleInvoiceItemUpdate = () => {
    //set the invoice total
    const { invoiceList } = this.state;
    //update the sales summary
    let sales = 0;
    invoiceList.map(element => (sales += element.net_amount));
    //set sales invoice Summary.
    const { billSummary } = this.state;
    billSummary.invoiceGrossValue = sales;
    this.setState({ billSummary: billSummary }, () => {
      this.setSalesInvoiceSummary();
    });
    //update the sales Invoice Summary
    this.setSalesInvoiceSummary();
  };

  //set the sales invoice summary
  setSalesInvoiceSummary = () => {
    //check the cash discount parameters
    const { billSummary } = this.state;
    const cashDiscountValue = this.getDiscountedValue(
      billSummary.invoiceGrossValue,
      billSummary.cashDiscountParam,
      billSummary.cashDiscount,
    );
    billSummary.cashDiscountValue = cashDiscountValue;
    const tradeDiscountValue = this.getDiscountedValue(
      billSummary.invoiceGrossValue,
      billSummary.tradeDiscountParam,
      billSummary.tradeDiscount,
    );
    billSummary.tradeDiscountValue = tradeDiscountValue;
    const invoiceWithoutVat = billSummary.invoiceGrossValue - cashDiscountValue - tradeDiscountValue;
    billSummary.vatAmount = Number(((13 / 100) * invoiceWithoutVat).toFixed(2));
    billSummary.invoiceNetValue = (invoiceWithoutVat + billSummary.vatAmount).toFixed(2);
    //set the bill summary
    this.setState({ billSummary: billSummary });
  };

  /** adjust table width and height according to screen **/
  componentDidMount() {
    // this.getDetail();
    this.getCustomerList();
    this.getBrandList();
  }

  //handle change of create sku events
  onChange = (field, value, title = 'title', rate = 0) => {
    const { createSKU } = this.state;
    createSKU[field] = value;
    // set the title if title is configured.
    if (field == 'sku_id') {
      createSKU.skuTitle = title;
      createSKU.rate = rate;
      // get the sku price and set to createSKU.price //todo
    }
    // update the amount with rate and amount.
    createSKU.amount = createSKU.quantity * createSKU.rate;
    const discount =
      Number(createSKU.promotion_discount) + Number(createSKU.trade_discount) + Number(createSKU.trade_discount);
    createSKU.net_amount = createSKU.amount - discount;
    this.setState({ createSKU: createSKU });
    // set the brand if brand is not configured.
    if (field == 'brand') {
      this.getSKUListUnderBrand(value);
    }
  };

  //on change of the bill summary & update bill summary details.
  onChangeBillSummary = (field, value) => {
    const { billSummary } = this.state;
    billSummary[field] = value;
    this.setState({ billSummary: billSummary }, () => {
      this.setSalesInvoiceSummary();
    });
  };

  //creation of the new invoice
  //update the invoice grossValue
  createSalesInvoice = () => {
    const { invoiceList, createSKU } = this.state;
    //calculate the price
    createSKU.price = createSKU.quantity * createSKU.rate - createSKU.discount;
    invoiceList.push(createSKU);
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
      //handle invoice item update
      this.handleInvoiceItemUpdate();
    });
  };

  //editSalesInvoice
  editSalesInvoice = (data, index) => {
    const { invoiceList, createSKU } = this.state;
    invoiceList[index] = createSKU;
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
      //handle invoice
      this.handleInvoiceItemUpdate();
    });
  };

  //close the modal resetting  all the value
  closeModal = () => {
    this.setState({ modalOpen: false, editModalOpen: false, deleteModalOpen: false });
    this.resetSKUDialog();
  };

  //reset SKU Dialog
  resetSKUDialog = () => {
    const createSKU = {
      brand: '',
      sku: '',
      sales_id: 0,
      order_id: 0,
      sku_id: 0,
      rate: 10,
      quantity: 0,
      batch: '',
      amount: 0,
      promotion_discount: 0,
      bill_discount: 0,
      trade_discount: 0,
      net_amount: 0,
      vat: 0,
    };
    this.setState({ createSKU: createSKU });
  };

  //delete the sales invoice
  deleteSalesInvoice = () => {
    const { invoiceList, indexInInvoiceList } = this.state;
    invoiceList.splice(indexInInvoiceList, 1);
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
      this.handleInvoiceItemUpdate();
    });
  };

  //on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data, index) => {
    this.setState({ editModalOpen: true, indexInInvoiceList: index });
    const createSKU = {
      brand: data.brand,
      sku: data.sku,
      sku_id: data.sku_id,
      rate: data.rate,
      quantity: data.quantity,
      batch: data.batch,
      amount: data.amount,
      promotion_discount: data.promotion_discount,
      bill_discount: data.bill_discount,
      trade_discount: data.trade_discount,
      net_amount: data.net_amount,
      vat: data.vat,
    };
    this.setState({ createSKU: createSKU });
  };

  //handle delete icon click.
  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInInvoiceList: index });
  };

  //on okay click for the modal
  handleModalOkClick = () => {
    const { modalOpen, editModalOpen, deleteModalOpen } = this.state;
    if (modalOpen) {
      this.createSalesInvoice();
    } else if (editModalOpen) {
      this.editSalesInvoice(this.state.createSKU, this.state.indexInInvoiceList);
    } else {
      this.deleteSalesInvoice();
    }
  };

  handleCheckBoxClick = (fieldName, flag) => {
    this.setState({ [fieldName]: flag });
    const { billSummary } = this.state;
    //if the flag is set off reset value to 0 and disable
    if (fieldName === 'cashDiscountCheckBoxFlag') {
      billSummary.cashDiscountValue = 0;
      billSummary.cashDiscount = 0;
      billSummary.cashDiscountParam = discountOptions[1].value;
    } else if (fieldName === 'tradeDiscountCheckBoxFlag') {
      billSummary.tradeDiscountValue = 0;
      billSummary.tradeDiscount = 0;
      billSummary.tradeDiscountParam = discountOptions[0].value;
    }
    this.setState({ billSummary: billSummary }, () => this.setSalesInvoiceSummary());
  };

  // get the customer list for the selection.
  getCustomerList = () => {
    this.setState({ loading: true });
    httpUtils.get(appConfig.rosiaUrl + 'customer-list').then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        let responseDataList = response.data.list;
        responseDataList.map(customer => {
          customer['label'] = customer.idLedger + '. ' + customer.name;
        });
        this.setState({ customerList: responseDataList });
      }
    });
  };

  // get the brand list
  getBrandList = () => {
    httpUtils.get(appConfig.baseUrl + 'brand/list?bu_id=' + businessUnitID).then(response => {
      if (response.success) {
        this.setState({ brandList: response.data.brandList });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = brandId => {
    //const brandId = this.state.createSKU.brand;
    // bu_id=1&bu_brand_id=3
    httpUtils.get(appConfig.baseUrl + 'sku-list?bu_id=' + businessUnitID + '&bu_brand_id=' + brandId).then(response => {
      if (response.success) {
        this.setState({ skuList: response.data });
      }
    });
  };

  // handle the customer drop down change
  onCustomerDropDownChange = (field, customer) => {
    const customerDetails = {
      id: customer.idLedger,
      name: customer.name,
      address: customer.address,
      panNo: customer.panNo,
    };
    this.setState({ customerDetails: customerDetails });
  };

  // set the sales invoice calling to the server. //also set custome ref id
  setSalesInvoice = () => {
    const { billSummary, customerDetails } = this.state;
    const objectDetail = {
      business_id: businessUnitID,
      customer_ref_id: customerDetails.id,
      entered_by: userInfo.name,
      amount: billSummary.invoiceGrossValue,
      bill_discount: billSummary.cashDiscount,
      trade_discount: billSummary.tradeDiscountValue,
      promotion_discount: billSummary.promotionDiscountValue,
      vat: billSummary.vatAmount,
      net_amount: billSummary.invoiceNetValue,
      skuDetail: this.state.invoiceList,
    };

    this.setState({ loading: true });
    httpUtils.post(appConfig.baseUrl + 'sales-invoice', objectDetail).then(response => {
      this.setState({ loading: false });
      //todo direct to print page.
      alert('sales invoice created');
    });
  };

  render() {
    const {
      loading,
      invoiceList,
      modalOpen,
      editModalOpen,
      deleteModalOpen,
      cashDiscountCheckBoxFlag,
      tradeDiscountCheckBoxFlag,
      createSKU,
    } = this.state;

    return (
      <>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={`print-sales-invoice pad-b-24 ${loading ? 'clickable-false' : ''}`}>
          <div className="card-body" ref="tableReference">
            <div className="header-bar">
              <Grid>
                <Cell col={4}>
                  <div className="header-title">Amazon Distributors</div>
                  <span className="header-title-content">
                    <span className="active-opacity-text">Address:</span>
                    <span> Jawlakhel, Lalitpur </span>
                  </span>
                  <span className="header-title-content">
                    <span className="active-opacity-text">Phone:</span>
                    <span>01-4256783</span>
                  </span>
                  <span className="header-title-content">
                    <span className="active-opacity-text">PAN No:</span>
                    <span>4256783</span>
                  </span>
                </Cell>
                <Cell col={4}>
                  <div className="center-align">
                    <h2>Tax Invoice</h2>
                  </div>
                </Cell>
              </Grid>
            </div>
            <div className="three-input-wrapper">
              <Grid>
                <Cell col={4}>
                  <label>Customer Name</label>
                  <Select
                    name="form-field-name"
                    clearable={false}
                    value={this.state.customerDetails.id}
                    valueKey="idLedger"
                    options={this.state.customerList}
                    onChange={e => this.onCustomerDropDownChange('name', e)}
                  />
                </Cell>
                <Cell col={4}>
                  <Textfield
                    className="right-side-gap no-pointer-events"
                    floatingLabel="Address"
                    value={this.state.customerDetails.address}
                  />
                </Cell>
                <Cell col={4}>
                  <Textfield
                    className="right-side-gap no-pointer-events"
                    floatingLabel="PAN Number"
                    value={this.state.customerDetails.panNo}
                  />
                </Cell>
              </Grid>
            </div>
            <div className="table-wrapper">
              <div ref="fixedTableBody" className="fixed-table-wrapper">
                <table>
                  <TableHeader
                    headerDetails={createSalesInvoiceConfig.headerDetails}
                    filterHeaderLabel={false}
                    handleSorting={this.handleTableSorting}
                  />
                  {invoiceList.map((data, key) => (
                    <tbody>
                      <CreateSalesInvoiceTableBody
                        data={data}
                        index={key}
                        deleteSalesInvoice={this.deleteSalesInvoice}
                        handleEditIconClick={this.handleEditIconClick}
                        handleDeleteIconClick={this.handleDeleteIconClick}
                      />
                    </tbody>
                  ))}
                </table>
              </div>

              {/*Fab Icon*/}
              <div className="mini-fab-button">
                <Fab mini onClick={() => this.setState({ modalOpen: true })}>
                  <Icon name="add" />
                </Fab>
              </div>
            </div>
            {/* Sales invoice table summary goes over here.*/}
            <div className="total-section-wrapper">
              <Grid>
                <Cell col={8}></Cell>
                <Cell col={3} className="right-align active-opacity-text">
                  {' '}
                  Sub Total
                </Cell>
                <Cell col={1} className="right-align">
                  {' '}
                  {this.state.billSummary.invoiceGrossValue}
                </Cell>
              </Grid>

              <Grid>
                <Cell col={3}> </Cell>
                <Cell col={8} className="right-align">
                  <FormField id="cashDiscountCheckbox">
                    <Checkbox
                      checked={this.state.cashDiscountCheckBoxFlag}
                      onChange={({ target: { checked } }) => {
                        this.handleCheckBoxClick('cashDiscountCheckBoxFlag', checked);
                      }}
                    />
                    <label>Bill Discount</label>
                  </FormField>

                  <span className={cashDiscountCheckBoxFlag ? null : 'no-pointer-events'}>
                    <span>
                      <Select
                        className="display-inline-table"
                        name="form-field-name"
                        clearable={false}
                        value={this.state.billSummary.cashDiscountParam}
                        options={discountOptions}
                        onChange={e => this.onChangeBillSummary('cashDiscountParam', e.value)}
                      />
                    </span>
                    <span className="default-left-margin-9">
                      <Textfield
                        value={this.state.billSummary.cashDiscount}
                        className=""
                        onChange={e => this.onChangeBillSummary('cashDiscount', e.target.value)}
                      />
                    </span>
                  </span>
                </Cell>
                <Cell col={1} className="right-align default-top-padding-12">
                  {this.state.billSummary.cashDiscountValue}
                </Cell>
              </Grid>

              <Grid>
                <Cell col={3}> </Cell>
                <Cell col={8} className="right-align">
                  <FormField id="tradeDiscountCheckBox">
                    <Checkbox
                      checked={this.state.tradeDiscountCheckBoxFlag}
                      onChange={({ target: { checked } }) => {
                        this.handleCheckBoxClick('tradeDiscountCheckBoxFlag', checked);
                      }}
                    />
                    <label>Trade Discount</label>
                  </FormField>
                  <span className={tradeDiscountCheckBoxFlag ? null : 'no-pointer-events'}>
                    <span>
                      <Select
                        className="display-inline-table"
                        name="form-field-name"
                        clearable={false}
                        value={this.state.billSummary.tradeDiscountParam}
                        options={discountOptions}
                        onChange={e => this.onChangeBillSummary('tradeDiscountParam', e.value)}
                      />
                    </span>
                    <span className="default-left-margin-9">
                      <Textfield
                        value={this.state.billSummary.tradeDiscount}
                        className=""
                        onChange={e => this.onChangeBillSummary('tradeDiscount', e.target.value)}
                      />
                    </span>
                  </span>
                </Cell>
                <Cell col={1} className="right-align default-top-padding-12">
                  {this.state.billSummary.tradeDiscountValue}
                </Cell>
              </Grid>

              <Grid className="default-margin-top-12">
                <Cell col={8}></Cell>
                <Cell col={3} className="right-align active-opacity-text">
                  {' '}
                  VAT
                </Cell>
                <Cell col={1} className="right-align">
                  {' '}
                  {this.state.billSummary.vatAmount}
                </Cell>
              </Grid>
              <div className="hr"></div>
              <Grid>
                <Cell col={8}></Cell>
                <Cell col={3} className="right-align active-opacity-text">
                  Total
                </Cell>
                <Cell col={1} className="right-align font-bold">
                  {this.state.billSummary.invoiceNetValue}
                </Cell>
              </Grid>
            </div>

            {/*Begin of the dialog Body*/}
            <Dialog
              open={modalOpen || editModalOpen || deleteModalOpen}
              onClose={() => {
                this.closeModal();
              }}
            >
              <DialogHeader>
                {modalOpen && <DialogTitle>Select SKU</DialogTitle>}
                {editModalOpen && <DialogTitle>Edit Invoice</DialogTitle>}
                {deleteModalOpen && <DialogTitle> Delete Invoice</DialogTitle>}
              </DialogHeader>

              {(modalOpen || editModalOpen) && (
                <DialogBody>
                  <div className="dialog-upperpart">
                    <Grid className="grid-padding">
                      <Cell col={4} tablet={8}>
                        <label>Brand</label>
                        <Select
                          name="form-field-name"
                          clearable={false}
                          value={this.state.createSKU.brand}
                          valueKey="businessCatalogId"
                          labelKey="title"
                          options={this.state.brandList}
                          onChange={e => this.onChange('brand', e.businessCatalogId)}
                        />
                      </Cell>

                      <Cell col={8} tablet={8}>
                        <label>SKU</label>
                        <Select
                          name="form-field-name"
                          clearable={false}
                          value={this.state.createSKU.sku_id}
                          valueKey="business_sku_id"
                          labelKey="title"
                          options={this.state.skuList}
                          onChange={e => this.onChange('sku_id', e.business_sku_id, e.title, e.rate)}
                        />
                      </Cell>
                    </Grid>

                    <Grid className="grid-padding">
                      <Cell col={3} tablet={8}>
                        <Textfield
                          value={this.state.createSKU.batch}
                          className="right-side-gap"
                          floatingLabel="Batch"
                          onChange={e => this.onChange('batch', e.target.value)}
                        />
                      </Cell>

                      {/*<Cell col={3} tablet={8} className="relative-container default-margin-top-7">*/}
                      <Cell col={2} tablet={8}>
                        <Textfield
                          value={this.state.createSKU.quantity}
                          className="right-side-gap"
                          floatingLabel="Quantity"
                          onChange={e => this.onChange('quantity', e.target.value)}
                        />
                      </Cell>

                      <Cell col={2}>
                        <label>Rate</label>
                        <span className="accent-color dialog-detail">{this.state.createSKU.rate}</span>
                      </Cell>

                      <Cell col={2}>
                        <label>Amount</label>
                        <span className="accent-color dialog-detail">{this.state.createSKU.amount}</span>
                      </Cell>

                      <Cell col={3}>
                        <Textfield
                          value={this.state.createSKU.promotion_discount}
                          className="right-side-gap"
                          floatingLabel="Discount"
                          onChange={e => this.onChange('promotion_discount', e.target.value)}
                        />
                      </Cell>
                    </Grid>
                  </div>
                </DialogBody>
              )}

              {deleteModalOpen && <div>Are you sure you want to Delete ?</div>}

              <DialogFooter>
                <Button
                  accent
                  className="dialog-cancel modal-btn"
                  onClick={() => {
                    this.closeModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  accent
                  className="dialog-ok modal-btn"
                  onClick={() => {
                    this.handleModalOkClick();
                  }}
                >
                  Ok
                </Button>
              </DialogFooter>
            </Dialog>
          </div>
          <div className="newinvoice-btn-wrapper">
            <Grid>
              <Cell col={8}></Cell>
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleModalOkClick();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  accent
                  className="save-btn modal-btn"
                  onClick={() => {
                    this.setSalesInvoice();
                  }}
                >
                  Save
                </Button>
              </Cell>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

export default PrintSalesInvoice;
