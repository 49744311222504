import styled from 'styled-components';

const CreditNoteStyled = styled.div`
  .credit-note {
    .fixed-table-wrapper {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  
  }
  &.credit_note-details{
    .dialog-upperpart.dialog-view{
      &>.mdc-layout-grid{
        padding:0;
      }
          .select-css{
            margin-top:7px;
          }
        }
      .header-bar{
        .calendar-arrow-icon,
        .calendar-date,.calendar-status{
          display:none;
        }
            .date-range-picker-wrapper {
              position: relative;
              outline: none;
              position: absolute;
              right: 105px;
              top: -25px;
              .rdrCalendarWrapper{
                right: 0px;
              top: 48px;
              }
          }
      }
        .edit-cancel{
          text-align: right;
          margin-bottom:8px;
          i{
            border:1px solid rgba(0, 0, 0, 0.2);
            border-radius:4px;
            margin-left:10px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            &.active{
              color:#2196f3;
              border:1px solid #2196f3;
            }
          }
        }
  }

  .credit_note_print .footer .padding-r-4 {
    padding-right: 4px !important;
  }
  &.credit_note-details  ,
  .create-credit-note {
    .dialog-upperpart .mdc-textfield {
      height: 50px !important;
    }
    .total-section-wrapper {
      .mdc-textfield--upgraded {
        height: 15px;
      }
    }

    .documentDate.date-picker-wrapper ,
    .three-input-wrapper .date-picker-wrapper {
      float: unset;
      .rdrCalendarWrapper{
        right:0;
      }
      .default-vertical-padding-24 {
        padding: 0;
        padding-top: 4px;
      }
      .calendar-status {
        display: none;
      }
      .mdc-layout-grid__inner {
        display: flex;
        .date-picker-holder-active,
        .date-picker-holder {
          flex: 1;
          border-bottom: 1px solid #9f9f9f;
          padding-left: 0;
          .calendar-date {
            border-bottom: none;
          }
          .calendar-icon {
            border: none;
          }
          .calendar-arrow-icon {
            position: absolute;
            right: 0;
            border-bottom: none;
          }
        }
      }
    }
  }
  &.credit_note-details {
    .documentDate.date-picker-wrapper{
      .default-vertical-padding-24 {
        padding-top:10px;
      }
    }
    .VAT{
      display: flex;
      justify-content: end;
      align-items: center;
      .title{
        margin-right:4px;
      }
      }
  }
  .create-credit-note{
    .mdc-dialog__surface {
      max-width: 600px;
    }
  }

    /* print */
    .credit_note_print {
    .print_display_none {
      display: none !important;
    }
    .height-25,
    .jv-footer tr {
      height: 25px;
    }
    .sub-total-td span,
    .total-td span {
      &:first-child {
        margin-right: 8px;
      }
      display: inline-block;
      min-width: 78px;
    }
    .print-active {
      padding-bottom: 0;
      padding: 4px 0;
    }
    .total-in-words td div {
      padding-bottom: 4px;
    }
    .sub-total td {
      line-height: 16px;
      padding: 0;
      padding-right: 8px;
      padding-top: 3px;
      &:last-child {
        padding-right: 0;
      }
    }
    .printInfo td {
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.54);
      & > div {
        display: inline-block;
        width: 25%;
        box-sizing: border-box;
        text-align: center;
        &:nth-child(2),
        &:first-child {
          text-align: left;
        }
        &:nth-last-child(2) {
          padding-left: 3%;
        }
        &:nth-last-child(2),
        &:last-child {
          text-align: right;
        }
      }
    }
    .page-tfoot-end {
      border: 0 !important;
    }
    .narration {
      position: relative;
      top: -4px;
    }
    .total-in-words {
      border: 0 !important;
      td {
        text-align: right;
        border: 0 !important;
      }
    }
    .signature td {
      vertical-align: middle;
      text-align: center;
      .authorized {
        position: relative;
        right: -22px;
        top: -12px;
        p {
          border-bottom: 1px solid rgba(0, 0, 0, 0.54);
          width: 150px;
          margin-top: 20px;
          margin-bottom: 6px;
        }
      }
    }
  }
  @media print{
    &.credit_note-details{
      .custom-three-input-wrapper{
      .mdc-layout-grid__inner{
        &>div:last-child{
          display:none;
        }
      }
    }
      .credit_note_print{
        .print-body{
          height: 1180px !important;
        padding:0 !important;
        padding:0.28in 0.3in !important;
        page-break-after: always;
        .footer.total-section-wrapper.top{
              border-top:1px solid rgba(0, 0, 0, 0.54);
          }
          .footer.total-section-wrapper.top,
          .footer-block{
            position: unset;
          }
          .footer.total-section-wrapper{
            position:unset;
            padding:0 !important;
          }
        }
        .jv.card-body{
          .fixed-table-wrapper{
            &.fixed-table-height{
              height:985px !important;
              min-height:unset;
              table{
                height:100%;
              }
            }
            &.total-footer{
              height:785px !important;
              min-height:unset;
            }
            table{
              thead{
                th.narration{
                  position:unset;
                }
              }
              tbody{
                tr{
                  td{
                    line-height:14px;
                    &.title,
                    &.narration{
                      width:300px !important;
                      white-space:normal !important;
                      position:unset;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media print and (max-width:5.83in) {
    &.credit_note-details{
      .credit_note_print{
      .print-body{
        height:1170px !important;
        padding:0.4in 0.45in !important;
        .jv.card-body{
        .fixed-table-wrapper{
            &.fixed-table-height{
              min-height:unset;
            }
            &.total-footer{
              min-height:unset;
            }
          }
        }
      }
    }
    }
  }
`;
export default CreditNoteStyled;
