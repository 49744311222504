import styled from 'styled-components';

const NavbarStyled = styled.div`
  .nav-bar {
    background: var(--primary-color);
    top: 0;
    color: #fff;
    width: 100%;
    /* position: fixed; */
    z-index: 500;
    line-height: 64px;
    height: 64px;
    vertical-align: middle;
  }
  .nav-bar h1 {
    color: #fff;
  }
  .nav-bar i {
    color: #fff;
    opacity: var(--active-opacity);
    vertical-align: middle;
  }
  .nav-bar i:hover {
    color: #fff;
    opacity: var(--active-opacity);
  }
  .nav-right-item {
    display: flex;
    float: right;
    margin-right: 24px;
    line-height: 64px;
    height: 64px;
  }
  .nav-bar .mdc-layout-grid {
    padding: 0px !important;
  }
  .nav-bar-center-item {
    text-align: center;
  }
  .nav-bar-center-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      text-transform: unset;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin: 0;
    }
    h5 {
      font-size: 8px;
      line-height: 10px;
      margin: 0;
      color: #ffffff;
      margin-top: 2px;
    }
  }
  .nav-bar-center-item span {
    /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
    font-weight: 300;
    opacity: 0.9;
  }
  .logo-font {
    margin-left: 24px;
    font-size: 20px;
    font-weight: lighter;
    text-transform: uppercase;
  }
  .logo-font strong {
    font-weight: bolder;
    text-transform: none;
  }
  .flex {
    display: flex;
  }
  .nav-bar {
    .flex {
      justify-content: flex-end;
    }
  }
  .user {
    position: relative;
    display: inline-block;
    margin: 0 24px 0 30px;
    height: 66px;
    &__info {
      cursor: pointer;
      display: inline-block;
      height: 35px;
      width: 35px;
      text-align: center;
      line-height: 35px;
      background-color: #ffffff;
      border-radius: 50%;
      color: #37474f;
      font-size: 14px;
      letter-spacing: 1px;
    }
    ul {
      position: absolute;
      background-color: #f4f4f4;
      color: rgba(0, 0, 0, 0.54);
      line-height: 48px;
      font-size: 14px;
      font-weight: normal;
      right: 0;
      bottom: -60px;
      padding: 0;
      width: 135px;
      padding: 2px;
      z-index: 1;
      margin: 0;
      list-style-type: none;
      cursor: pointer;
      background-color: #ffffff;
      border-radius: 2px;
      box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.16);
      .arrow-up {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid rgb(256, 255, 282);
        position: absolute;
        right: 10px;
        top: -5px;
      }
      li {
        padding: 8px;
        font-size: 14px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.86);
      }
    }
    &__dialog {
      & > .mdc-dialog__surface {
        width: 443px;
        .mdc-dialog__header {
          padding: 14px 24px;
        }
        .dialog-upperpart {
          padding: 20px 24px;
          border-bottom: 0;
          .mdc-textfield {
            max-width: unset;
          }
          .password {
            position: relative;
          }
          .password .pw_display {
            position: absolute;
            top: -12px;
            margin: 0;
            color: rgb(40, 125, 60);
            font-size: 11px;
          }
        }
      }
    }
  }
  .message-snackbar .mdc-snackbar--active {
    bottom: 3%;
    left: 3%;
  }
`;
export default NavbarStyled;
