import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { formatToReadableDate } from '../../../utils/filterUtil';

const GRNTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <tr className='cursor-pointer' onClick={() => onRowClick(data)}>
        <td className='left-align'>{data.purchaseInvoiceNumber}</td>
        <td className='left-align'>{formatToReadableDate(data.documentDate)}</td>
        <td className='left-align'>{data.mitiTitle}</td>
        <td className='left-align'>{data.vendorInvoiceNumber}</td>
        <td className='left-align'>{(data.customer && data.customer.title) || ''}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.netAmount)}</td>
        <td className='left-align'>{formatToReadableDate(data.createdAt)}</td>
      </tr>
    ))}
  </tbody>
);

GRNTableBody.propTypes = {
  dataList: PropTypes.shape().isRequired,
  onRowClick: PropTypes.func.isRequired,
};

const grnHeader = {
  headerDetails: [
    { id: 1, label: 'purchase_invoice_number', title: 'GRN Number', className: 'cursor-pointer', sortable: true },
    { id: 2, label: 'date', title: 'Document Date', className: 'cursor-pointer left-align', sortable: true },
    { id: 6, label: 'miti_title', title: 'Miti', className: 'cursor-pointer', sortable: true },
    {
      id: 3,
      label: 'vendor_invoice_number',
      title: ' Vendor Invoice Number',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    { id: 4, label: 'ledgers.title', title: 'Vendor Name', className: 'cursor-pointer left-align', sortable: true },
    { id: 5, label: 'net_amount', title: 'GRN Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 7, label: 'created_at', title: 'Created Date', className: 'cursor-pointer', sortable: true },
  ],
};

export { grnHeader, GRNTableBody };
