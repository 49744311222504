import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appConstants from '../../config';
import * as httpUtils from '../../../utils/httpUtils';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
} from 'react-mdc-web';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import history from '../../../utils/history';
import '../../../styles/printStyle.css';
import { srnDetailConfig, SRNDetailTableBody } from './SRNDetailConfig';
import Header from '../../../components/PrintComponent/Header';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import BillTotalContent from '../../../components/PrintComponent/BillTotalContent';
import { salesInvoiceDetailConfig } from '../SalesInvoice/SalesInvoiceDetailConfig';
import { clone } from '../../../utils/arrayProcessor';
import { SALES_INVOICE_BASE, SALES_RETURN_BASE } from '../../../data/enums/Route';
import numberToWords from '../../../utils/numberToTextConverter';

const title = {
  original: 'SALES RETURN',
};
const signatureTitle = 'Prepared By';

let dataListLength = 0;

// invoice id is present in the list.
class SalesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      invoiceIdPresent: false,
      copyTaxInvoice: false,
      customerDetails: {
        invoiceId: '',
        customerName: '',
        customerAddress: '',
        panNo: '',
        salesReturnNumber: '',
      },
      billSummary: {
        subTotal: 0,
        billDiscount: 0,
        tradeDiscount: 0,
        taxableAmount: 0,
        totalValue: 210,
        vat: 0,
        remarks: '',
        totalInWords: '',
      },
      date: '',
      miti: '',
      headerTitleToView: srnDetailConfig.headerTitleToView,
      invoiceList: [],
      printButtonClicked: false,
      print: false,
      billTotalList: [
        { title: 'Sub Total', value: 0 },
        { title: 'Bill Discount', value: 0 },
        { title: 'Trade Discount', value: 0 },
        { title: 'VAT', value: 0 },
      ],
      dataMiscellaneousList: [
        { title: 'Invoice Number', value: '' },
        {
          title: 'Sales Return Number',
          value: '',
        },
        { title: 'PAN No', value: '' },
        { title: 'Customer Name', value: '' },
      ],
      printInfo: [],
      dataList: [],
      invoiceDetail: { title: 'Prepared By', value: '' },
    };
  }

  componentDidMount() {
    this.getDetail();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  getDetail = () => {
    this.setState({ loading: true });
    httpUtils.get(appConstants.baseUrl + `sales-return/detail?id=${this.props.match.params.id}`).then(response => {
      this.setState({ loading: false });
      if ((response.status == '200') & response.success) {
        const salesReturnDetail = response.data;
        const detailsLength = salesReturnDetail.details.length;
        const customerDetails = {
          invoiceId: detailsLength ? salesReturnDetail.details[0].invoiceNumber : response.data.refNumber,
          customerName: salesReturnDetail.customer.name,
          customerAddress: salesReturnDetail.customer.address,
          panNo: salesReturnDetail.customer.panNo,
          salesReturnNumber: salesReturnDetail.salesReturnInvoiceNumber,
        };
        let subTotal = salesReturnDetail.details.length
          ? this.calculateTotal(salesReturnDetail.details, 'netAmount')
          : 0;
        let taxableAmount = Number(
          (subTotal - (salesReturnDetail.billDiscount || 0) - (salesReturnDetail.tradeDiscount || 0)).toFixed(2),
        );
        const billSummary = {
          taxableAmount,
          billDiscount: salesReturnDetail.billDiscount,
          tradeDiscount: salesReturnDetail.tradeDiscount,
          totalValue: salesReturnDetail.netAmount,
          vat: salesReturnDetail.vat,
          remarks: salesReturnDetail.remarks,
          subTotal,
        };
        billSummary['totalInWords'] = numberToWords(billSummary.totalValue || 0);
        const dataMiscellaneousList = [
          {
            title: 'Invoice Number',
            value: customerDetails.invoiceId,
          },
          { title: 'Sales Invoice Number', value: salesReturnDetail.salesReturnInvoiceNumber },
          { title: 'Customer Name', value: customerDetails.customerName },
          {
            title: 'PAN No',
            value: salesReturnDetail.customer.panNo,
          },
        ];
        const billTotalList = [
          { title: 'Sub Total', value: subTotal },
          {
            title: 'Bill Discount',
            value: billSummary.billDiscount,
          },
          { title: 'Trade Discount', value: billSummary.tradeDiscount },
          { title: 'VAT', value: billSummary.vat },
        ];
        const invoiceList = salesReturnDetail.details;
        const date = moment(salesReturnDetail.date).format('DD MMM YYYY');
        const miti = salesReturnDetail.miti;
        let invoiceDetail = { title: 'Prepared By', value: '' };
        if (salesReturnDetail.user.hasOwnProperty('name')) {
          invoiceDetail = { title: 'Prepared By', value: salesReturnDetail.user.name };
        }
        this.setState(
          {
            date,
            miti,
            invoiceList,
            billSummary,
            invoiceDetail,
            billTotalList,
            customerDetails,
            dataMiscellaneousList,
          },
          () => {
            this.groupPrintDataset();
          },
        );
      } else {
      }
    });
  };

  calculateTotal = (detailList, key) => {
    let totalValue = 0;
    detailList.map(item => {
      totalValue += item[key];
    });

    return Number(Number(totalValue).toFixed(2));
  };

  handlePrintClick = () => {
    const printButtonStatus = this.state.printButtonClicked;
    const self = this;
    this.setState({ printButtonClicked: true }, () => {
      window.print();
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false });
    };
  };

  groupPrintDataset = () => {
    const { invoiceList } = this.state;
    const withoutFooterLimit = 36;
    const withFooterLimit = 26;
    let arrayList,
      tempWholeDataList = [];
    let dataList = [];
    let count = 0;
    arrayList = clone(invoiceList);
    const chunkAppropriateSize = arrayList.length >= withoutFooterLimit ? withoutFooterLimit : withFooterLimit;
    let chunk = chunkAppropriateSize;
    let indexChunk = chunkAppropriateSize;

    arrayList.forEach((item, index) => {
      item['index'] = index;
      arrayList[index] = item;
    });

    for (let index = 0, j = arrayList.length; index < j; index = indexChunk) {
      count++;
      let chunkArray = [];
      chunkArray = arrayList.slice(index, index + chunk);
      tempWholeDataList = tempWholeDataList.concat(chunkArray);
      chunk = arrayList.length - tempWholeDataList.length >= withoutFooterLimit ? withoutFooterLimit : withFooterLimit;
      if (arrayList.length - tempWholeDataList.length === 0) {
        chunkArray['footer'] = true;
        chunkArray['page'] = count;
      } else {
        chunkArray['footer'] = false;
        chunkArray['page'] = count;
      }

      indexChunk = tempWholeDataList.length;
      dataList.push(chunkArray);
    }
    dataListLength = dataList.length;
    this.setState({ dataList: dataList }, () => {
      //this.printInvoice();
    });
  };

  handleKeyDown = e => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  handleCancelClick = () => {
    history.push(`/${SALES_RETURN_BASE}`);
  };

  render() {
    const { loading, invoiceList, invoiceIdPresent, copyTaxInvoice, distributorDetails, dataList } = this.state;
    const { user, company } = this.props;
    const titleToDisplay = copyTaxInvoice ? title.copy : title.original;

    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className={this.state.printButtonClicked ? 'no-print' : 'display-block'}>
            <div className="card-body" ref="tableReference">
              <div className="header-bar">
                <Grid>
                  <Cell col={4} tablet={8}>
                    <div className="header-title">{company.title}</div>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Address: </span>
                      <span>{company.address}</span>
                    </span>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Phone: </span>
                      <span>{company.phone}</span>
                    </span>
                    <span className="no-margin-bottom header-title-content">
                      <span className="active-opacity-text">PAN No: </span>
                      <span>{company.panNumber}</span>
                    </span>
                  </Cell>
                  <Cell col={4} tablet={8}>
                    <div className="header-middle-title center-align">
                      <h2>{titleToDisplay}</h2>
                    </div>
                  </Cell>
                  <Cell col={4} tablet={8}>
                    <div className="right-align">
                      <span className="header-right-content">
                        <span className="">Date: </span>
                        <span>{this.state.date}</span>
                      </span>
                      {/* <span className="header-right-content">
                   <span className="">Sambat</span>
                   <span>1 Kartik, 2074</span>
                 </span>*/}
                    </div>
                  </Cell>
                </Grid>
              </div>
              <div className="print-invoice three-input-wrapper">
                <Grid>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap"
                      floatingLabel="Sales Return Number"
                      value={this.state.customerDetails.salesReturnNumber}
                      onChange={e => this.onInputFieldChange('salesReturnNumber', e.target.value)}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap"
                      floatingLabel="Invoice Number"
                      value={this.state.customerDetails.invoiceId}
                      onChange={e => this.onInputFieldChange('invoiceId', e.target.value)}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap"
                      floatingLabel="Customer Name"
                      value={this.state.customerDetails.customerName}
                      onChange={e => this.onInputFieldChange('customerName', e.target.value)}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap"
                      floatingLabel="Address"
                      value={this.state.customerDetails.customerAddress}
                      onChange={e => this.onInputFieldChange('customerAddress', e.target.value)}
                    />
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap"
                      floatingLabel="PAN No"
                      value={this.state.customerDetails.panNo}
                      onChange={e => this.onInputFieldChange('panNo', e.target.value)}
                    />
                  </Cell>
                  <Cell col={3}>
                    <Textfield
                      className="right-side-gap"
                      floatingLabel="Remarks"
                      value={this.state.billSummary.remarks}
                      onChange={e => this.onInputFieldChange('remarks', e.target.value)}
                    />
                  </Cell>
                </Grid>
              </div>
              <div ref="fixedTableBody" className="fixed-table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>SN</th>
                      {/* <th>SRN Detail ID</th>*/}
                      <th>SKU</th>
                      <th className="right-align">Rate</th>
                      <th className="right-align">Quantity (pcs)</th>
                      <th className="right-align">Promotion</th>
                      <th className="right-align">Net Amount</th>
                      {/*  <th></th>*/}
                    </tr>
                  </thead>
                  {invoiceList.map((data, key) => (
                    <tbody>
                      <SRNDetailTableBody data={data} index={key} />
                    </tbody>
                  ))}
                </table>
              </div>
              <div className="total-section-wrapper">
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Sub Total
                  </Cell>
                  <Cell col={1} className="right-align">
                    {' '}
                    {this.state.billSummary.subTotal}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Cash Discount
                  </Cell>
                  <Cell col={1} className="right-align">
                    {' '}
                    {this.state.billSummary.billDiscount}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Trade Discount
                  </Cell>
                  <Cell col={1} className="right-align">
                    {' '}
                    {this.state.billSummary.tradeDiscount}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Taxable Amount
                  </Cell>
                  <Cell col={1} className="right-align">
                    {' '}
                    {this.state.billSummary.taxableAmount}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    {' '}
                    VAT
                  </Cell>
                  <Cell col={1} className="right-align">
                    {' '}
                    {this.state.billSummary.vat}
                  </Cell>
                </Grid>
                <div className="hr" />
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Total
                  </Cell>
                  <Cell col={1} className="right-align font-bold">
                    {' '}
                    {this.state.billSummary.totalValue}
                  </Cell>
                </Grid>
              </div>
            </div>
            <div className="newinvoice-btn-wrapper">
              <Grid>
                <Cell col={8} />
                <Cell col={4} className="right-align">
                  <Button
                    accent
                    className="cancel-btn modal-btn"
                    onClick={() => {
                      this.handleCancelClick();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    accent
                    className="save-btn modal-btn"
                    onClick={() => {
                      this.handlePrintClick();
                    }}
                  >
                    Print
                  </Button>
                </Cell>
              </Grid>
            </div>
          </div>
          <div className={this.state.printButtonClicked ? 'display-block' : 'no-print'}>
            {dataList.map((data, key) => (
              <div className="print-body zoom-less-reduce">
                <div className="srn-card card-body" ref="t">
                  <Header
                    company={company}
                    divClassName={
                      key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'
                    }
                    date={this.state.date}
                    miti={this.state.miti}
                    pageTitle={<h2>{title.original}</h2>}
                    dataMiscellaneousList={this.state.dataMiscellaneousList}
                  />
                  <div
                    ref="fixedTableBody"
                    className={` fixed-table-wrapper ${!data.footer ? 'fixed-table-height ' : 'total-footer'}`}
                  >
                    <table className="data-table">
                      <thead>
                        <tr>
                          <th>SN</th>
                          {/* <th>SRN Detail ID</th>*/}
                          <th>SKU</th>
                          <th className="right-align">Rate</th>
                          <th className="right-align">Quantity (pcs)</th>
                          <th className="right-align">Promotion</th>
                          <th className="right-align">Net Amount</th>
                          {/*  <th></th>*/}
                        </tr>
                      </thead>
                      {data.map((salesReturn, keyTest) => (
                        <tbody>
                          <SRNDetailTableBody data={salesReturn} index={keyTest} />
                        </tbody>
                      ))}
                    </table>
                  </div>
                  {data.footer && (
                    <table className="print-active total-section-wrapper footer clearfix">
                      <BillTotalContent
                        totalList={this.state.billTotalList}
                        totalValue={this.state.billSummary.totalValue}
                        invoiceDetail={this.state.invoiceDetail}
                        print={this.state.print}
                        printInfo={this.state.printInfo}
                        totalInWords={this.state.billSummary.totalInWords}
                        signatureTitle={signatureTitle}
                        lineMargin="padding-r-24"
                        remarksStatus={true}
                        remarks={this.state.billSummary.remarks}
                      />
                    </table>
                  )}
                </div>
                <div className="footer-block">
                  <PageNumber value={data.page} totalPage={dataListLength} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

SalesDetails.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
  };
};
const SRNDetails = connect(mapStateToProps)(SalesDetails);
export default SRNDetails;
