import { compose } from 'recompose';

import apiInterceptor from '../../../../api/interceptor';

import CreateSalesInvoice from './CreateSalesInvoice';
import {
  buApi, catalogsApi, ledgerApi, salesInvoiceApi
} from '../../../common/base.api';

const composedCreateSalesInvoice = compose(
  apiInterceptor({
    ...buApi,
    ...catalogsApi,
    create: { ...salesInvoiceApi },
    getCustomerList: { ledgerApi },
  }),
)(CreateSalesInvoice);

export default composedCreateSalesInvoice;
