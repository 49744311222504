const getConfigData = footerData => {
  const columns = [
    [
      { content: 'SN', rowSpan: 2 },
      { content: 'Miti', rowSpan: 2 },
      { content: 'Purchase Bill Number', rowSpan: 2 },
      { content: 'Reference Number', rowSpan: 2 },
      { content: 'Vendor Name', rowSpan: 2, styles: { halign: 'center' } },
      { content: 'PAN No.', rowSpan: 2 },
      { content: 'Item', colSpan: 3, styles: { halign: 'center' } },
      { content: 'Purchase', colSpan: 4, styles: { halign: 'center' } },

      { content: 'Import', colSpan: 2, styles: { halign: 'center' } },
      { content: 'Capitalized Purchase', colSpan: 2, styles: { halign: 'center' } },
    ],
    [
      { content: 'Name', styles: { valign: 'middle' } },
      { content: 'Qty.', styles: { halign: 'right' } },
      { content: 'Unit' },

      { content: 'Total', styles: { halign: 'right' } },
      { content: 'Tax Exempted', styles: { halign: 'right' } },
      { content: 'Taxable', styles: { halign: 'right' } },
      { content: 'VAT', styles: { halign: 'right' } },

      { content: 'Amount', styles: { valign: 'middle' }, styles: { halign: 'right' } },
      { content: 'VAT', styles: { valign: 'middle' } },
      { content: 'Amount', styles: { valign: 'middle' }, styles: { halign: 'right' } },
      { content: 'VAT', styles: { valign: 'middle' } },
    ],
  ];

  JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && columns[0].push({ content: 'Excise', rowSpan: 2 });

  const footer = [
    [
      '',
      'Total',
      '',
      '',
      '',
      '',
      '',
      { content: footerData?.totalItemQuantity ?? '', styles: { lineWidth: 0.1 } },
      '',
      { content: footerData?.totalPurchase ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalTaxExemptedPurchase ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalTaxableAmount ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalVat ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalExportPurchase ?? '', styles: { lineWidth: 0.1 } },
      '',
      '',
      '',
      { content: footerData?.totalExcise ?? '', styles: { lineWidth: 0.1 } },
    ],
  ];

  const columnStyles = {
    0: { cellWidth: 10 },
    1: { cellWidth: 15 },
    2: { cellWidth: 31 },
    3: { cellWidth: 20 },
    5: { cellWidth: 15 },
    6: { cellWidth: 38 },
    9: { cellWidth: 15 },
    10: { cellWidth: 18 },
    11: { cellWidth: 18 },
  };

  const cellsToAlignRight = { 7: '7', 9: '9', 10: '10', 11: '11', 12: '12', 13: '13', 14: '14', 15: '15', 16: '16' };

  return {
    columns,
    footer,
    columnStyles,
    cellsToAlignRight,
  };
};

export default getConfigData;
