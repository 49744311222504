import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../../../common/Loading';
import DetailTableView from './DetailTableView';
import SummaryView from './SummaryView';
import DetailFooter from './DetailFooter';
import DetailHeader from './DetailHeader';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  display: PropTypes.shape({
    searchBox: PropTypes.bool,
    filter: PropTypes.filter,
    menu: PropTypes.menu,
  }),
  tableConfig: PropTypes.shape({
    header: PropTypes.instanceOf(Array),
    getTableBody: PropTypes.func,
  }),
  handleTableSorting: PropTypes.bool,
  totalConfig: PropTypes.object,
  changeTheadStatus:PropTypes.bool,
};

const defaultProps = {
  totalConfig: {},
  display: {
    header: false,
    summary: false,
    footer: false,
  },
  tableConfig: {
    header: [],
    getTableBody: () => null,
  },
  headerConfig: {
    company: {},
    title: '',
    date: new Date().toDateString(),
  },
  buttonConfigList: [],
  serverResponseWaiting: false,
  handleTableSorting: () => null,
  changeTheadStatus:false,
};

React.createRef();

const refs = { tableHeader: React.createRef(), };

const DetailView = ({
  display, // header, summary, footer
  serverResponseWaiting,
  handleTableSorting, // this.handleTableSorting,
  data, // list and total
  pageConfig,
  headerConfig,
  printInfoBill,
  onTableBodyClick,
  editInput,
  handleInputChange,
  handleDateChange,
  documentDate,
  mainNarration,
  changeTheadStatus,
  ledgerList,
  exciseNumber,
  exciseStatus,
  drList,
  crList,
  print,
  backDatedStatus,
  onChange,
  vatStatus,
  onChecked,
  checkStatus,
}) => (
  <div className='card-body' ref={refs.tableHeader}>
    <Loading display={serverResponseWaiting} />
    <div className={serverResponseWaiting ? 'clickable-false' : ''}>
      {display.header ? <DetailHeader headerConfig={headerConfig} handleDateChange ={handleDateChange} editInput={editInput} backDatedStatus={backDatedStatus} documentDate ={documentDate} exciseNumber={exciseNumber}exciseStatus ={exciseStatus} onChecked ={onChecked} checkStatus={checkStatus}/> : <div>{headerConfig.company.title}</div>}
      {display.summary && <SummaryView data={data.summary} config={pageConfig} editInput ={editInput} mainNarration={mainNarration} handleInputChange ={handleInputChange}/>}
      <DetailTableView
        data={data}
        pageConfig={pageConfig}
        changeTheadStatus={changeTheadStatus}
        handleTableSorting={handleTableSorting}
        serverResponseWaiting={serverResponseWaiting}
        printInfoBill={printInfoBill}
        onTableBodyClick={onTableBodyClick}
        ledgerList={ledgerList}
        drList={drList}
        crList={crList}
        print={print}
      />
      {display.footer && <DetailFooter data={data.summary} pageConfig={pageConfig} onChange ={onChange}vatStatus={vatStatus} />}
    </div>
  </div>
);

DetailView.propTypes = propTypes;

DetailView.defaultProps = defaultProps;

export default DetailView;
