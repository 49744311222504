import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Textfield,
  Fab,
  Grid,
  Cell,
  Menu,
  MenuAnchor,
  MenuItem,
} from 'react-mdc-web';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import * as httpUtils from '../../../utils/httpUtils';
import Pagination from '../../../components/Pagination';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import DateToRender from '../../../components/DateToRender';
import TableHeader from '../../../components/TableHeader';
import { getFixTableMeasurements } from '../../../utils/tableMetrics';
import * as appConstants from '../../../config';
import { purchaseReturnVatReportConfig, PurchaseReturnVatReportTableBody } from './PurchaseReturnVatReportConfig';
import * as filterUtil from '../../../utils/filterUtil';
import * as downloadService from '../../../views/common/download.service';
import { SORTING } from '../../../data/enums/enums';

// const businessUnitID = 1;
const downloadFormat = [{ id: 1, value: 'Download Purchase Book CSV' }];

class PurchaseReturnVatReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showMenu: false,
      totalData: 1,
      dataSet: [],
      dateClickCount: 0,
      businessUnitId: '',
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
        },
        filter: {},
      },
      nepaliDate: {
        start: '',
        end: '',
      },
    };
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.getTableDataForGrid();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    const table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
  }

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    this.setState({ loading: true });
    const { queryParameters } = this.state;
    queryParameters.date.start_date = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date.end_date = moment(queryParameters.date.end).format('YYYY-MM-DD');

    httpUtils
      .get(
        `${appConstants.baseUrl}reports/purchase-vat-return?start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          const nepaliDate = response.data.nepali_date
            ? {
                start: response.data.nepali_date.start_date_nepali,
                end: response.data.nepali_date.end_date_nepali,
              }
            : this.state.nepaliDate;
          self.setState({
            dataSet: response.data.list,
            totalData: response.data.total,
            nepaliDate,
            loading: false,
          });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters }, () => this.getTableDataForGrid());
  };

  /** on Date Range Change */
  handleDateRangeChange = (start, end) => {
    const queryParameters = { ...this.state.queryParameters };
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    const sorting = { ...this.state.sorting };
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting });
  };

  handleDownloadClick = id => {
    const type = 'csv';
    const { user } = this.props;
    const self = this;
    const schema = 'https://';
    const { queryParameters } = this.state;
    queryParameters.date.start_date = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date.end_date = moment(queryParameters.date.end).format('YYYY-MM-DD');
    httpUtils
      .get(
        `${appConstants.baseUrl}downloads?domain=purchase_return_vat&user_id=${user.idUsers}&start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&report_type=${type}`,
      )
      .then(response => downloadService.resolver(response.data))
      .catch(error => {
        console.error(error);
      });
  };

  getSortedDataSet = (labelName, sortingOrder) => {
    const { dataSet } = this.state;
    if (sortingOrder === SORTING.NO_SORT) {
      return dataSet;
    }
    return orderBy(dataSet, labelName, sortingOrder);
  };

  render() {
    const { loading, queryParameters, totalData, sorting, nepaliDate } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    const { company } = this.props;

    return (
      <div className="purchase-return-vat pad-b-24">
        <div className="card-body">
          <div>
            {loading && (
              <div className="linear-progress-wrapper temp-progress-wrapper">
                <LinearProgress accent indeterminate />
              </div>
            )}
            <div className={loading ? 'clickable-false' : ''}>
              <div className="header-bar" ref="tableReference">
                <Grid>
                  <Cell col={4}>
                    <div className="header-title">{company.title}</div>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Address: </span>
                      <span>{company.address}</span>
                    </span>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Phone: </span>
                      <span>{company.phone}</span>
                    </span>
                    <span className="header-title-content">
                      <span className="active-opacity-text">PAN No:</span>
                      <span>{company.panNumber}</span>
                    </span>
                  </Cell>
                  <Cell col={4}>
                    <div className="center-align">
                      <h2>Purchase Return Book</h2>
                      <div className="default-margin-top-12">
                        <h4>Value Added Tax Ledger</h4>
                        <span className="header-title-content no-margin-bottom">
                          <span className="active-opacity-text">
                            Report from{' '}
                            <span className="date-rendered">
                              <DateToRender date={queryParameters.date} />
                            </span>
                          </span>
                        </span>
                        <span className="header-title-content">
                          <span className="active-opacity-text">
                            Report from
                            <span className="date-rendered">
                              <span> {nepaliDate.start}</span>
                              <span> - {nepaliDate.end}</span>
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </Cell>
                  <Cell col={4}>
                    <div className="header-menu-right">
                      <span className="download-menu" onClick={() => this.setState({ showMenu: !this.state.showMenu })}>
                        <Icon name="get_app" />
                      </span>
                      <MenuAnchor>
                        <Menu
                          right
                          open={this.state.showMenu}
                          onClose={() => {
                            this.setState({ showMenu: false });
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              this.handleDownloadClick(downloadFormat[0].id);
                            }}
                          >
                            {downloadFormat[0].value}
                          </MenuItem>
                        </Menu>
                      </MenuAnchor>
                      <span onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                        <Icon name="filter_list" />
                      </span>
                    </div>
                    <div className="header-menu-right">
                      <span className="header-right-content">
                        <span className="">Date: </span>
                        <span>{this.state.queryParameters.date.end}</span>
                      </span>
                    </div>
                  </Cell>
                </Grid>
              </div>
            </div>

            <div ref="fixedTableBody" className="fixed-table-wrapper">
              <table className="prvr-table">
                <TableHeader
                  headerDetails={purchaseReturnVatReportConfig.headerDetails}
                  filterHeaderLabel={false}
                  handleSorting={this.handleTableSorting}
                />
                {sortedDataSet.map((data, key) => (
                  <tbody>
                    <PurchaseReturnVatReportTableBody data={data} />
                  </tbody>
                ))}
              </table>
            </div>

            {
              <div ref="fixedTableFooter">
                <Pagination
                  pageSize={queryParameters.pagination.limit}
                  currentPage={queryParameters.pagination.page}
                  orientation="top"
                  totalItems={totalData}
                  onPageSelect={this.onPageSelect}
                />
              </div>
            }
          </div>
          <div dir="rtl">
            <Drawer
              open={this.state.showFilter}
              className="right-drawer"
              onClose={() => {
                this.setState({ showFilter: false });
              }}
            >
              <div className="filter-wrapper">
                <h2 className="default-horizontal-padding-24">
                  <span>Filter</span>
                  <span className="float-right">
                    <Icon name="replay" onClick={() => this.resetFilter()} />
                    <Icon name="clear" onClick={() => this.setState({ showFilter: false })} />
                  </span>
                </h2>
                <div className="divider" />
                <DateRangePicker
                  startDate={moment(queryParameters.date.start)}
                  endDate={moment(queryParameters.date.end)}
                  onChange={this.handleDateRangeChange}
                />
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    );
  }
}

PurchaseReturnVatReport.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
});
const purchaseReturnVatReport = connect(mapStateToProps)(PurchaseReturnVatReport);

export default purchaseReturnVatReport;
