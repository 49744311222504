import PropTypes from 'prop-types';
import React, { Component } from 'react';
import tableConfig from './config';
import withBaseState from '../../common/withBaseState';
import * as queryService from '../../common/query.service';
import { DAY_BOOK_SUMMARY_CONFIG } from '../../common/domain.config';
import * as filterUtil from '../../../utils/filterUtil';
import * as downloadService from '../../common/download.service';
import { clone } from '../../../utils/arrayProcessor';
import IrdView from '../../common/irdReport/IrdView';
import { defaultHeaderConfig } from '../../common/irdReport/config';
import {groupPrintDataSet} from "../../common/print.service";
import DayBookStyled from './DayBookStyled';

const propTypes = {
  domainConfig: PropTypes.shape({
    title: PropTypes.string,
    downloadList: PropTypes.instanceOf(Array),
    domain: PropTypes.string,
  }).isRequired,
  serverResponseWaiting: PropTypes.bool,
  getDetails: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  getDetails: () => null,
};
class DayBookSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSet: [],
      display: {
        searchBox: false,
        drawer: false,
      },
      printButtonClicked: false,
      queryParameters: {
        ...queryService.queryParameters,
        date: null,
        singleDate: filterUtil.getCurrentDay(),
      },
      loading: false,
      columnTotal: {
        debit: 0,
        credit: 0,
      },
    };
    this.dataListLength = 0;
    const { downloadList } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getTableDataForGrid,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.getTableDataForGrid();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

  /** remove eventlistener on component unmount* */
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }
  getTableDataForGrid = () => {
    const self = this;
    const { company, getDetails } = this.props;
    const { queryParameters } = this.state;
    getDetails(queryParameters.singleDate)
      .then(response => {
        const columnTotal = {};
        const data = response.filter(a => a.name !== 'Total');
        columnTotal.credit = response ? response[response.length - 1].credit : 0;
        columnTotal.debit = response ? response[response.length - 1].debit : 0;
        self.setState(
          {
            dataSet: data,
            totalData: 1,
            columnTotal,
          },
          () => {
            self.groupPrintDataSetNew();
          },
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleDownloadClick = (type, label) => {
    const { queryParameters } = this.state;
    const { downloadList, downloadDetail } = this.props;

    if (label === 'day_book_detail') {
      downloadDetail({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    } else {
      downloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    }
  };

  handlePrintClick = () => {
    const self = this;
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false });
    };
  };
  groupPrintDataSetNew() {
    const { dataSet  } = this.state;
    const {company} =this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(dataSet);
    const dataList = groupPrintDataSet(orders, printInfoBill, tableConfig);
    this.setState({ dataList });
  }

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { getDetails } = this.props;
    const { singleDate, queryParameters } = this.state;

    getDetails(queryParameters.singleDate).then(response => {
      const { data } = this.state;
      data.list = response;
      data.total = response.length;
      this.setState({ data });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const { company, serverResponseWaiting } = this.props;
    const { queryParameters, display, dataSet, dataList, totalData, columnTotal, printButtonClicked } = this.state;
    return (
      <DayBookStyled className="daybook-summary">
        <IrdView
          company={company}
          dataSet={dataSet}
          display={display}
          printList={dataList}
          tableConfig={tableConfig}
          totalData={totalData}
          nepaliDateExist={false}
          columnTotal={columnTotal}
          printFlag={printButtonClicked}
          loading={serverResponseWaiting}
          queryParameters={queryParameters}
          controlDisplay={this.controlDisplay}
          domainConfig={DAY_BOOK_SUMMARY_CONFIG}
          handlePrintClick={this.handlePrintClick}
          handleDownloadClick={this.handleDownloadClick}
          resetFilter={this.basePaginationService.resetFilter}
          headerConfig={{ ...defaultHeaderConfig, print: true }}
          handlePageSelect={this.basePaginationService.onPageSelect}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          handleDateFilterChange={this.basePaginationService.handleDateChange}
          colSpan_t_l={9}
          colSpanTotal={3}
          singleDateExist
          printFooterEnable={false}
        />
      </DayBookStyled>
    );
  }
}

DayBookSummary.propTypes = propTypes;
DayBookSummary.defaultProps = defaultProps;

const DayBookSummaryWithState = withBaseState(DayBookSummary);

export default DayBookSummaryWithState;
