import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DateToRender, formatToReadableDate } from '../../../utils/filterUtil';

const printLedgerDetailPdfContent = (configData, printData, orientation = 'portrait', variation = 'normal') => {
  const doc = new jsPDF({
    orientation: orientation,
    unit: 'mm',
    format: 'a4',
  });

  let highestLength = 0;
  printData?.dataMiscellaneousList?.map(data => {
    doc.setFontSize(8);
    doc.setFont('Helvetica', 'bold');
    const splitTitle = doc.splitTextToSize(data.value ?? '', 36);
    highestLength = splitTitle?.length > highestLength ? splitTitle?.length : highestLength;
  });
  let SECOND_HEADER_RECTANGLE_HEIGHT = printData?.dataMiscellaneousList?.length > 0 ? 12 + highestLength * 3 : 0;

  const PAGE_WIDTH = doc.internal.pageSize.getWidth();
  const PAGE_HEIGHT = doc.internal.pageSize.getHeight();
  const SECTION_WIDTH = PAGE_WIDTH - 12;
  const HEADER_RECTANGLE_HEIGHT = 24;
  const TITLE_RECTANGLE_HEIGHT = printData?.statement ? 15.5 : 13.5;
  const BORDER_COLOR = '#272833';
  const OUTER_CORDINATE_X = 6;
  const OUTER_CORDINATE_Y = 6;

  // global font setting
  doc.setFont('Helvetica', '');

  const setTitleCenter = (title, alignTitleCenter, startTopCordinate) => {
    doc.text(title, alignTitleCenter, startTopCordinate, { align: 'left' });
  };

  const setTitle = (titleName, titleSize, startTopCordinate, leftSpace = false, concatText = '') => {
    const title = titleName;
    const fontSize = doc.setFontSize(titleSize);
    const middleCordinate = PAGE_WIDTH / 2;
    const alignTextCenter = (fontSize.getTextWidth(title) + fontSize.getTextWidth(concatText)) / 2;
    const alignTitleCenter = middleCordinate - alignTextCenter + (leftSpace ? fontSize.getTextWidth(concatText) : 0);
    setTitleCenter(title, alignTitleCenter, startTopCordinate);
  };

  const addTopHeaderSection = () => {
    // top Header
    doc.setLineWidth(0.2);
    doc.setDrawColor(BORDER_COLOR);
    doc.rect(OUTER_CORDINATE_X, OUTER_CORDINATE_Y, SECTION_WIDTH, HEADER_RECTANGLE_HEIGHT);

    // // left align section
    // doc.setFontSize(10);
    // doc.setFont('Helvetica', 'bold');
    // doc.text(`${printData.companyName}`, 9, 13);
    // // to remove bold font style
    // doc.setFont('Helvetica', '');

    // doc.setFontSize(7);
    // doc.text(`Address: ${printData.address}`, 9, 17);
    // doc.setFontSize(7);
    // doc.text(`Phone: ${printData.phone}`, 9, 20);
    // doc.setFontSize(7);
    // doc.text(`PAN No: ${printData.panNo}`, 9, 23);
    // doc.setFontSize(7);
    // doc.text(`Email: ${printData.email}`, 9, 26);

    // middle align section
    doc.setFont('Helvetica', 'bold');
    setTitle(`${printData.companyName}`, 12, 13);
    // to remove bold style
    doc.setFont('Helvetica', '');

    setTitle(`Address: ${printData.address}`, 7, 17);
    setTitle(`Phone: ${printData.phone}`, 7, 20);
    setTitle(`PAN No: ${printData.panNo}`, 7, 23);
    setTitle(`Email: ${printData.email}`, 7, 26);

    doc.setLineWidth(0.2);
    doc.rect(OUTER_CORDINATE_X, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y, SECTION_WIDTH, TITLE_RECTANGLE_HEIGHT);

    doc.setFont('Helvetica', 'bold');
    setTitle(`${printData.title}`, 12, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 6);

    if (printData?.date?.reportPeriod) {
      const date = DateToRender(printData.date.value, false) ?? '';

      doc.setFont('Helvetica', '');
      const dateText = date;
      setTitle(`Report Period: `, 7, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 10, 0, dateText);

      const dateTitleText = 'Report Period: ';
      doc.setFont('Helvetica', 'bold');
      setTitle(date, 7, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 10, true, dateTitleText);
    }

    if (printData?.date?.asOfDate) {
      doc.setFont('Helvetica', '');
      const dateText = formatToReadableDate(printData.date.value ?? '');
      setTitle(`As of date: `, 7, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 10, 0, dateText);

      const dateTitleText = 'As of date: ';
      doc.setFont('Helvetica', 'bold');
      setTitle(dateText, 7, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 10, true, dateTitleText);
    }

    printData?.statement &&
      setTitle(`${printData?.statement}`, 7, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 14, 0);

    doc.setLineWidth(0.2);

    // second header section
    if (printData?.dataMiscellaneousList?.length > 0) {
      doc.rect(
        OUTER_CORDINATE_X,
        HEADER_RECTANGLE_HEIGHT + TITLE_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y,
        SECTION_WIDTH,
        SECOND_HEADER_RECTANGLE_HEIGHT,
      );

      // doc.setFontSize(7);
      // doc.text(`Sales Person : 6test 12 `, 9, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 6);

      // doc.setFontSize(7);
      // doc.text(`Sales Person : 6test 12 `, SECTION_WIDTH + 3, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 6, {
      //   align: 'right',
      // });

      const getHeaderWidth = (SECTION_WIDTH - 3) / printData?.dataMiscellaneousList?.length;
      printData?.dataMiscellaneousList.map((data, index) => {
        doc.setFontSize(7);
        doc.setFont('Helvetica', '');
        doc.text(
          data.title ?? '',
          9 + index * getHeaderWidth,
          HEADER_RECTANGLE_HEIGHT + TITLE_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 6,
        );
        doc.setFontSize(8);
        doc.setFont('Helvetica', 'bold');
        const splitTitle = doc.splitTextToSize(data.value ?? '', 36);
        doc.text(
          splitTitle,
          9 + index * getHeaderWidth,
          HEADER_RECTANGLE_HEIGHT + TITLE_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 6 + 4,
        );
      });
    }
  };

  const addPaginationNumber = pageNUmber => {
    // @ts-ignore
    const pageStartY = PAGE_HEIGHT;
    doc.setFont('Helvetica', '');
    // middle align page number
    setTitle(`Page ${pageNUmber.toString()}`, 9, pageStartY - OUTER_CORDINATE_Y);
  };

  // Table section
  autoTable(doc, {
    theme: 'grid',
    bodyStyles: { lineColor: BORDER_COLOR },
    tableWidth: PAGE_WIDTH - 12,
    margin: { left: 6, right: 6, bottom: 11 },
    showFoot: 'lastPage',
    styles: {
      minCellHeight: 9,
      valign: 'middle',
      textColor: 'black',
      fontSize: 7,
      cellPadding: 1.2,
    },
    rowPageBreak: 'avoid',
    startY: HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + SECOND_HEADER_RECTANGLE_HEIGHT + TITLE_RECTANGLE_HEIGHT,
    headStyles: {
      lineWidth: 0.1,
      lineColor: BORDER_COLOR,
      fillColor: 'white',
      textColor: 'black',
      fontSize: 7.5,
      minCellHeight: 7,
    },
    footStyles: { textColor: 'black', fillColor: false },
    tableLineWidth: 0.2,
    tableLineColor: BORDER_COLOR,

    // @ts-ignore
    columnStyles: configData.columnStyles ?? {},
    head: configData.columns ?? [],
    // @ts-ignore
    body: printData.tableDataRows,
    // @ts-ignore
    foot: configData.footer ?? '',

    // using didParseCell for adding style to body cell and footer cell
    didParseCell(data) {
      // this conditional body is for multiple footer print for making
      // total section bold
      if (variation === 'multipleFooter' && data.section === 'body' && data.row.raw[0] === '') {
        for (const cell in data.row.cells) {
          data.row.cells[cell].styles.fontStyle = 'bold';
        }
      }
      const headerTargetCells = configData.cellsToAlignRight;
      if (headerTargetCells[data.column.dataKey] && (data.section == 'foot' || data.section == 'body')) {
        data.cell.styles.halign = 'right';
      }
      data.section == 'foot' && (data.cell.styles.lineColor = BORDER_COLOR);
    },
    // using didDrawPage to add topheader section to every page
    didDrawPage(data) {
      // data.settings.margin.top adds top space to every page after first page
      data.settings.margin.top =
        HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + SECOND_HEADER_RECTANGLE_HEIGHT + TITLE_RECTANGLE_HEIGHT;
      data.settings.margin.bottom = 11;
      addTopHeaderSection();
      const pageCount = doc.internal;

      // Adding pagination to every page
      doc.setTextColor(50);

      // @ts-ignore
      addPaginationNumber(pageCount.getNumberOfPages());
    },
  });

  // Final footer section
  // finalFooterSection();

  // adds pdf content to printer tab
  doc.autoPrint();

  const uri = doc.output('bloburl');
  return uri && uri;
  // window.open(doc.output("bloburl"), "_blank");
};

const useSimplePrintPdfContent = (configData, printData, orientation, variation) => {
  const uri = printLedgerDetailPdfContent(configData, printData, orientation, variation);
  return uri;
};

export default useSimplePrintPdfContent;
