import { compose } from 'recompose';

import apiInterceptor from '../../../api/interceptor';
import {
  buApi, ledgerApi, jvApi
} from '../../common/base.api';
import CreateJournalVoucher from './CreateJournalVoucher';

const composedCreateJournalVoucher = compose(
  apiInterceptor({
    ...buApi,
    create: { ...jvApi },
    getList: { ledgerApi },
  }),
)(CreateJournalVoucher);

export default composedCreateJournalVoucher;
