import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../components/TableHeader';
import * as appConfig from '../../config';
import { connect } from 'react-redux';

import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
  Snackbar,
} from 'react-mdc-web';
import Select from 'react-select';
import RadioButtonList from '../../../components/RadioButtonList/RadioButtonList';
import * as httpUtils from '../../../utils/httpUtils';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import _ from 'lodash';
import { CreatePurchaseReturnTableBody, createPurchaseReturnConfig } from './CreatePurchaseReturnConfig';
import * as filterUtil from '../../../utils/filterUtil';
import { isObjectEmpty } from '../../../utils/objectProcessor';
import { clone } from '../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { debouncer } from '../../../utils/handlers';
import history from '../../../utils/history';
import { JOURNAL_VOUCHER_BASE, PURCHASE_RETURN_BASE } from '../../../data/enums/Route';

const message = {
  serverError: '!Server Error',
  success: 'Purchase Return created successfully.',
  error: '!Error while creating Purchase Return',
  invoiceNumberNotFound: '!Vendor Invoice Number not found',
  invoiceListNotSelected: 'No item is selected from the list.',
  misMatchBusinessId: '!Vendor Invoice number belong to the other Business Unit.',
};

// invoice id is present in the list.
class CreatePR extends Component {
  constructor(props) {
    super(props);
    const { bu_id, user } = this.props;
    this.state = {
      loading: false,
      editModalOpen: false,
      formEmptyField: false,
      pageEmptyField: false,
      createModalOpen: false,
      deleteModalOpen: false,
      showMessage: false,
      success: true,
      message: message.success,
      businessUnitId: '',
      indexInInvoiceList: 0,
      invoiceIdPresent: false,
      invoiceIdTaken: false,
      vendorDetails: {
        id: '',
        vendorId: '',
        name: '',
        address: '',
        panNo: '',
      },
      createSKU: {
        batch: '',
        brand_id: '',
        brandTitle: '',
        sku_id: 0,
        skuTitle: '',
        vat: 0,
        // voucher_number:0,
        vendor_invoice_number: '0',
        quantity: 0,
        rate: 0,
        amount: 0,
        net_amount: 0,
        discount: 0,
      },
      billSummary: {
        remarks: '',
        net_amount: 0,
        entered_by: user.idUsers,
        vat: 0,
        gross_amount: 0,
        discount: 0,
        amount: 0,
        /* voucher_number:'',*/
        purchase_invoice_number: '',
      },
      checkBoxForSalesReturn: {
        primary: false,
        secondary: [],
      },
      invoiceList: [],
      pageValidateFieldArray: ['name', 'address', 'panNo', 'remarks', 'GRNNo'],
      formValidateFieldArray: ['brand', 'sku_id', 'rate', 'quantity', 'discount', 'net_amount'],
      editFormValidateFieldArray: ['rate', 'quantity', 'discount', 'net_amount'],
      date: {
        start: filterUtil.getStartOfCurrentMonth(),
        end: filterUtil.getCurrentDay(),
      },
      headerTitleToView: createPurchaseReturnConfig.headerTitleToView,
      customerList: [],
      brandList: [],
      skuList: [],
      buList: [],
      invoiceId: '',
    };
  }

  // after mounted get the customer list.
  componentDidMount() {
    // this.getDetail();
    this.getBUList();
    this.getVendorList();
  }

  // handle input change of customer Details. Fetch the list if invoice id is valid.
  onInputFieldChange = (field, value) => {
    const { billSummary } = this.state;
    billSummary[field] = value;
    this.setState({ billSummary: billSummary });
  };

  onPurchaseInvoiceNumberChange = (field, value) => {
    const { billSummary } = this.state;
    billSummary[field] = value;
    this.setState({ billSummary: billSummary }, () => {
      debouncer(this.getGRNIdDetails, 800)(this.state.billSummary.purchase_invoice_number);
    });
  };

  debounceInput = _.debounce((term, f) => {
    f(term);
  }, 700);

  getGRNIdDetails = id => {
    const { billSummary } = this.state;
    this.resetBillSummaryAmounts();
    this.resetVendorDetails();
    this.setState({ loading: true });
    httpUtils
      .get(appConfig.baseUrl + `purchase-return/available-list?invoice_number=${id}`)
      .then(response => {
        this.setState({ loading: false });
        if (response.success && !isObjectEmpty(response.data)) {
          if (response.data.businessId === this.state.businessUnitId) {
            const customerDetails = response.data.customerDetail;
            this.setState({ invoiceIdPresent: true, invoiceIdTaken: false });
            this.onCustomerDropDownChange('vendorId', customerDetails.idLedger, customerDetails);
            this.createListObject(response.data.purchaseInvoice);
          } else {
            // this.setState({invoiceIdPresent: false, invoiceList: [], invoiceIdTaken: true}, () => {
            // this.handleSRNItemUpdate(this.state.invoiceList)});
            this.setState({
              showMessage: true,
              success: false,
              message: message.misMatchBusinessId,
              invoiceIdPresent: false,
              invoiceList: [],
              invoiceIdTaken: true,
            });
          }
        } else {
          /* billSummary.voucher_number = '';*/
          const userMessage = response.message ? `!${response.message}` : '!Purchase Return already done.';
          this.setState({
            showMessage: true,
            success: false,
            message: userMessage,
            invoiceIdPresent: false,
            invoiceList: [],
            invoiceIdTaken: false,
          });
          // this.setState({invoiceIdPresent: false, invoiceList: [], invoiceIdTaken: false}, () => {this.handleSRNItemUpdate(this.state.invoiceList)});
        }
      })
      .catch(error => {
        /*      billSummary.voucher_number = '';*/
        // this.setState({invoiceIdPresent: false, invoiceList: [], invoiceIdTaken: false}, () => {this.handleSRNItemUpdate(this.state.invoiceList)});
        // this.resetVendorDetails();
        this.setState({
          loading: false,
          showMessage: true,
          success: false,
          message: message.invoiceNumberNotFound,
          invoiceIdPresent: false,
          invoiceList: [],
          invoiceIdTaken: false,
        });
      });
  };

  createListObject = salesArray => {
    const { invoiceIdPresent } = this.state;
    const invoiceList = salesArray.map((data, key) => {
      const createSkuObj = this.skuObjMapper(data);
      if (createSkuObj.quantity === 0) {
        createSkuObj.amount = 0;
        createSkuObj.net_amount = 0;
        createSkuObj.vat = 0;
      }
      /* else
       {
         createSkuObj.amount = Number(createSkuObj.quantity * createSkuObj.rate).toFixed(2);
         createSkuObj.net_amount = Number(createSkuObj.amount - ( createSkuObj.promotion_discount + createSkuObj.discount + createSkuObj.trade_discount )).toFixed(2);
         createSkuObj.vat = Number(createSkuObj.net_amount * 0.13).toFixed(2);
       }*/
      if (invoiceIdPresent) {
        createSkuObj['maxValue'] = data.quantity;
        createSkuObj['disabled'] = true;
      }

      return createSkuObj;
    });

    this.setState({ invoiceList });
  };

  skuObjMapper = data => {
    return {
      amount: data.amount,
      idPurchaseDetail: data.idPurchaseDetail,
      batch: data.batch || '',
      brand_id: '',
      brandTitle: '',
      sku_id: data.skuId,
      skuTitle: data.title,
      vat: data.vat || 0,
      vendor_invoice_number: data.vendorInvoiceNumber,
      quantity: data.quantity,
      rate: data.rate,
      discount: data.discount || 0,
      net_amount: data.netAmount || 0,
    };
  };

  onCustomerDropDownChange = (field, id, customer) => {
    const vendorDetails = {
      id: customer.idLedger,
      vendorId: customer.customerId,
      name: customer.name,
      address: customer.address,
      panNo: customer.panNo,
    };
    this.setState({ vendorDetails: vendorDetails });
  };

  // handle change of create sku events.
  onChange = (field, value, all = {}) => {
    const { createSKU } = this.state;
    createSKU[field] = value;

    if (field == 'quantity' || field == 'rate') {
      createSKU.discount = 0;
    }
    // update the changes in the field value.
    createSKU.amount = Number((Number(createSKU.quantity) * Number(createSKU.rate)).toFixed(2));
    createSKU.net_amount = Number((createSKU.quantity * createSKU.rate - Number(createSKU.discount)).toFixed(2));
    if (Number(createSKU.net_amount) < 0) {
      createSKU.net_amount = 0;
      createSKU.discount = 0;
    }
    createSKU.vat = (13 / 100) * createSKU.net_amount;
    if (field == 'sku_id') {
      createSKU.skuTitle = all['title'] || 'title';
      // createSKU.rate = all['rate'] || 0;
      // currently the sku list rate is disabled.
      // createSKU.rate = rate;
    }
    this.setState({ createSKU: createSKU });
    // update the gross amount upon the changes.
    // in case field is brand , set the sku list
    if (field == 'brand') {
      this.getSKUListUnderBrand(value);
    }
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data, index) => {
    const { invoiceIdPresent, createSKU } = this.state;
    this.setState({ editModalOpen: true, indexInInvoiceList: index });
    if (!invoiceIdPresent) this.getSKUListUnderBrand(data.brand);
    this.setState({ createSKU: clone(data) });
  };

  // create the sales Return, update the discount value
  createSalesReturn = () => {
    const { invoiceList, createSKU } = this.state;
    // createSKU.amount = (Number(createSKU.quantity) * Number(createSKU.rate)).toFixed(2);
    createSKU.vendor_invoice_number = this.state.billSummary.voucher_number;
    invoiceList.push(createSKU);
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
      // handle SRN item update
      this.handleSRNItemUpdate(invoiceList);
    });
  };

  // editSalesInvoice
  editSalesInvoice = (data, index) => {
    const { invoiceList, createSKU, invoiceIdPresent } = this.state;
    // update the discount value
    // createSKU.discount = createSKU.quantity * createSKU.rate - createSKU.price;
    invoiceList[index] = createSKU;
    this.setState({ invoiceList }, () => {
      this.closeModal();
    });
    if (invoiceIdPresent) {
      this.handleSecondaryCheckBoxClick(data.idPurchaseDetail, true, createSKU);
    } else {
      this.handleSRNItemUpdate(invoiceList);
    }
  };

  // delete the sales Return for particular sku, and update the total value.
  deleteSalesReturnItem = index => {
    const { invoiceList, indexInInvoiceList } = this.state;
    if (invoiceList.length >= indexInInvoiceList) {
      invoiceList.splice(indexInInvoiceList, 1);
      this.setState({ invoiceList: invoiceList }, () => {
        this.closeModal();
        this.handleSRNItemUpdate(invoiceList);
      });
    }
  };

  // when SRN item number changes, update the bill total value
  /* handleSRNItemUpdate = (invoiceList) => {
     //set the invoice total.
     //const {invoiceList} = this.state;
     let salesValue = 0;
     invoiceList.map(element => salesValue += Number(element.net_amount));
     const vat = (13/100 * Number(salesValue)).toFixed(2);
     const {billSummary} = this.state;
     billSummary.vat = vat;
     billSummary.net_amount = Number(salesValue).toFixed(2);
     billSummary.gross_amount = (Number(salesValue) + Number(vat)).toFixed(2);
     this.setState({billSummary: billSummary});
   };
 */

  handleSRNItemUpdate = invoiceList => {
    // set the invoice total.
    const { billSummary } = this.state;

    let amountInfo = {
      salesValue: 0,
      discount: 0,
      amount: 0,
    };
    invoiceList.map(element => {
      amountInfo = {
        amount: amountInfo.amount + Number(element.quantity || 0) * Number(element.rate || 0),
        salesValue: amountInfo.salesValue + Number(element.net_amount || 0),
        discount: amountInfo.discount + Number(element.discount || 0),
      };

      return amountInfo;
    });

    const taxableAmount = Number(amountInfo.salesValue);
    const vat = (13 / 100) * Number(taxableAmount);
    billSummary.vat = vat;
    billSummary.discount = amountInfo.discount;
    billSummary.net_amount = Number(taxableAmount.toFixed(2));
    billSummary.gross_amount = Number((taxableAmount + vat).toFixed(2));
    billSummary.amount = amountInfo.amount;
    this.setState({ billSummary });
  };

  // close the modal resetting  all the value
  closeModal = () => {
    this.setState({ createModalOpen: false, editModalOpen: false, deleteModalOpen: false });
    this.resetSKUDialog();
  };

  // reset SKU Dialog
  resetSKUDialog = () => {
    const createSKU = {
      batch: '',
      brand_id: '',
      brandTitle: '',
      sku_id: 0,
      skuTitle: '',
      vat: 0,
      vendor_invoice_number: '0',
      // voucher_number: 0,
      quantity: 0,
      rate: 0,
      discount: 0,
      amount: 0,
      net_amount: 0,
    };
    const skuList = [];
    this.setState({ createSKU: createSKU, formEmptyField: false, skuList: skuList });
  };

  resetVendorDetails = () => {
    const vendorDetails = {
      id: '',
      vendorId: '',
      name: '',
      address: '',
      panNo: '',
    };
    this.setState({ vendorDetails: vendorDetails });
  };

  resetBillSummaryAmounts = () => {
    const { billSummary } = this.state;
    billSummary.net_amount = 0;
    billSummary.vat = 0;
    billSummary.discount = 0;
    billSummary.gross_amount = 0;
    this.setState({ billSummary });
  };

  // handle on modal ok click
  handleModalOkClick = () => {
    // check edit or create config //close the dialog
    const {
      createModalOpen,
      editModalOpen,
      createSKU,
      formValidateFieldArray,
      invoiceIdPresent,
      editFormValidateFieldArray,
    } = this.state;
    if (createModalOpen || editModalOpen) {
      const flag = (createSKU.quantity && createSKU.rate) !== 0;
      const validateObject = invoiceIdPresent ? editFormValidateFieldArray : formValidateFieldArray;
      const valid = this.validateForm(createSKU, validateObject, 'formEmptyField', flag, false);
      if (valid) {
        if (createModalOpen) {
          this.createSalesReturn();
        } else {
          this.editSalesInvoice(this.state.createSKU, this.state.indexInInvoiceList);
        }
      }
    } else {
      this.deleteSalesReturnItem();
    }
  };

  validateForm = (formObject, config, flag, zeroFlag, invalidIdFlag) => {
    const data = Object.keys(formObject).some(k => {
      if (config.indexOf(k) > -1) {
        let value = formObject[k];
        const conditionParameter = zeroFlag ? value === '' : !value;
        if (conditionParameter) {
          this.setState({ [flag]: true });

          return true;
        }
      }
    });
    if (this.state.invoiceIdTaken && invalidIdFlag) {
      return false;
    } else {
      return !data;
    }
  };

  directToMainPage = () => {
    const { showMessage, success } = this.state;
    if (!showMessage && success) {
      history.push(`/${PURCHASE_RETURN_BASE}`);
    }
  };

  getSelectedInvoiceList = checkedList => {
    const { invoiceList } = this.state;
    const selectedInvoiceList = invoiceList.filter(item => checkedList.indexOf(item.idPurchaseDetail) > -1);

    return selectedInvoiceList;
  };

  /** handle the primary check box click. */
  handlePrimaryCheckBoxClick = flag => {
    const { checkBoxForSalesReturn, invoiceList } = this.state;
    checkBoxForSalesReturn.primary = flag;
    if (!flag) {
      checkBoxForSalesReturn.secondary = [];
      this.resetBillSummaryAmounts();
      this.setState({ checkBoxForSalesReturn: checkBoxForSalesReturn });
    } else {
      /** run through all the loop within the invoice list */
      const allCheckBox = invoiceList.map((element, key) => {
        return element.idSalesDetail;
      });
      checkBoxForSalesReturn.secondary = allCheckBox;
      const selectedInvoiceList = this.getSelectedInvoiceList(checkBoxForSalesReturn.secondary);
      this.setState({ checkBoxForSalesReturn }, () => this.handleSRNItemUpdate(selectedInvoiceList));
    }
  };

  /** handle the secondary checkBox click */
  handleSecondaryCheckBoxClick = (id, flag) => {
    const { checkBoxForSalesReturn, invoiceList } = this.state;
    let selectedInvoiceList = [];
    if (!flag) {
      // find the index in the list, and clear it.
      // reset the primary checkbox
      let indexInList = checkBoxForSalesReturn.secondary.indexOf(id);
      checkBoxForSalesReturn.secondary.splice(indexInList, 1);
      checkBoxForSalesReturn.primary = false;
      selectedInvoiceList = this.getSelectedInvoiceList(checkBoxForSalesReturn.secondary);
      this.setState({ checkBoxForSalesReturn }, () => this.handleSRNItemUpdate(selectedInvoiceList));
    } else {
      // check the condition if primary is to be set.
      let indexInList = checkBoxForSalesReturn.secondary.indexOf(id);
      if (indexInList === -1) checkBoxForSalesReturn.secondary.push(id);
      if (checkBoxForSalesReturn.secondary.length === invoiceList.length) {
        checkBoxForSalesReturn.primary = true;
      }
      selectedInvoiceList = this.getSelectedInvoiceList(checkBoxForSalesReturn.secondary);
      this.setState({ checkBoxForSalesReturn }, () => this.handleSRNItemUpdate(selectedInvoiceList));
    }
  };

  // get the customer list for the selection.
  getVendorList = () => {
    this.setState({ loading: true });
    httpUtils.get(appConfig.baseUrl + '/ledger/vendor-list').then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        let responseDataList = response.data;
        responseDataList.map(customer => {
          customer['label'] = customer.customerId + '. ' + customer.name;
        });
        this.setState({ customerList: responseDataList });
      }
    });
  };

  // get the brand list
  getBrandList = () => {
    httpUtils.get(appConfig.baseUrl + 'brand/list?bu_id=' + this.state.businessUnitId).then(response => {
      if (response.success) {
        this.setState({ brandList: response.data.brandList });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = brandId => {
    httpUtils
      .get(appConfig.baseUrl + 'sku-list?bu_id=' + this.state.businessUnitId + '&bu_brand_id=' + brandId)
      .then(response => {
        if (response.success) {
          this.setState({ skuList: response.data });
        }
      });
  };

  // get BU list
  getBUList = () => {
    httpUtils.get(appConfig.baseUrl + 'upload/business-company').then(response => {
      if (response.success) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  handleBUClick = id => {
    this.setState({ businessUnitId: id }, () => {
      this.getBrandList();
    });
  };

  handleSaveClick = () => {
    // generate the object to send to the server
    const {
      vendorDetails,
      billSummary,
      invoiceList,
      businessUnitId,
      pageValidateFieldArray,
      checkBoxForSalesReturn,
      invoiceIdPresent,
    } = this.state;
    const toValidObject = Object.assign({}, vendorDetails);
    toValidObject['remarks'] = billSummary.remarks;
    toValidObject['GRNNo'] = billSummary.purchase_invoice_number;
    const invalidIdFLag = this.state.invoiceIdTaken;
    const valid = this.validateForm(toValidObject, pageValidateFieldArray, 'pageEmptyField', false, invalidIdFLag);
    const invoiceListCheck = invoiceIdPresent ? checkBoxForSalesReturn.secondary.length > 0 : invoiceList.length > 0;
    if (!invoiceListCheck) {
      this.setState({ showMessage: true, success: false, message: message.invoiceListNotSelected });

      return false;
    }
    if (valid && invoiceListCheck) {
      let invoiceArray = [];
      if (checkBoxForSalesReturn.secondary.length > 0 && checkBoxForSalesReturn.primary === false) {
        invoiceArray = invoiceList.filter((item, key) => {
          return checkBoxForSalesReturn.secondary.indexOf(item.idPurchaseDetail) > -1;
        });
      } else {
        invoiceArray = invoiceList;
      }
      const newInvoiceList = invoiceArray;
      const object = {
        vendor_ref_id: vendorDetails.vendorId,
        business_id: businessUnitId,
        entered_by: billSummary.entered_by,
        net_amount: billSummary.gross_amount,
        purchase_invoice_number: billSummary.purchase_invoice_number,
        remarks: billSummary.remarks,
        /* voucher_number: billSummary.voucher_number,*/
        vat: billSummary.vat,
        amount: billSummary.amount,
        discount: billSummary.discount,
        promotion_discount: 0,
        trade_discount: 0,
      };
      this.setState({ loading: true });
      // todo : backEnd filters the unnecessary object attributes.
      object['skuList'] = newInvoiceList.map(sku => {
        const skuDetails = Object.assign({}, sku);
        delete skuDetails['brand_id'];
        delete skuDetails['brand'];
        delete skuDetails['brandTitle'];
        delete skuDetails['skuTitle'];
        delete skuDetails['maxValue'];
        delete skuDetails['disabled'];
        delete skuDetails['maxRate'];
        delete skuDetails['idPurchaseDetail'];

        return skuDetails;
      });

      httpUtils
        .post(appConfig.baseUrl + 'purchase-return', object)
        .then(response => {
          this.setState({ loading: false });
          if (response.success) {
            this.setState({ showMessage: true, success: true, message: message.success });
            /* alert('Purchase Return Created');
           history.push('/purchase-return');*/
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            showMessage: true,
            success: false,
            message: message.error,
          });
        });
    }
  };

  handleCancelClick = () => {
    history.push(`/${PURCHASE_RETURN_BASE}`);
  };

  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInInvoiceList: index });
  };

  render() {
    const {
      loading,
      invoiceList,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      vendorDetails,
      buList,
      showMessage,
      invoiceIdPresent,
      billSummary,
      invoiceIdTaken,
      pageEmptyField,
    } = this.state;

    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="message-snackbar">
            {
              <Snackbar
                className={this.state.success ? 'success-message' : ''}
                timeout={this.state.success ? 1000 : 5000}
                onTimeout={() => {
                  this.setState({ showMessage: false }, () => {
                    this.directToMainPage();
                  });
                }}
                open={showMessage}
              >
                {this.state.message}
              </Snackbar>
            }
          </div>
          <div className="card-header-bar clearfix" ref="tableReference">
            <div className="header-left">
              <h2>New Purchase Return</h2>
            </div>
            <div className="header-right">{this.state.date.end}</div>
          </div>
          <div className="card-body">
            <div className="radio-list-wrapper">
              {buList.length && <RadioButtonList data={buList} handleBuClick={this.handleBUClick} />}
            </div>
            <div className={this.state.businessUnitId ? '' : 'block-overlay'}>
              <div className="three-input-wrapper">
                <Grid className="padding-bottom-0">
                  <Cell col={4} className="input-field">
                    <BillingTextField
                      value={billSummary.purchase_invoice_number}
                      floatingLabel="GRN Number"
                      required={true}
                      className="billing-required"
                      param="purchase_invoice_number"
                      emptyField={pageEmptyField}
                      errorMessage={
                        invoiceIdTaken ? 'Invoice No. belongs to other Business Unit' : 'Should not be empty'
                      }
                      invalidError={invoiceIdTaken}
                      handleChange={this.onPurchaseInvoiceNumberChange}
                    />
                  </Cell>
                  {/* <Cell col={4} className="input-field">
                  <BillingTextField
                    value={this.state.billSummary.voucher_number}
                    floatingLabel="Vendor Invoice Number"
                    param="voucher_number"
                    required={true}
                    className={`billing-required ${invoiceIdPresent ? 'no-pointer-events':''}`}
                    emptyField={this.state.pageEmptyField}
                    handleChange={this.onInputFieldChange}
                  />
                </Cell>*/}
                  <Cell col={4} className="input-select">
                    <label>Vendor Name</label>
                    <BillingSelect
                      name="form-field-name"
                      param="name"
                      clearable={false}
                      value={vendorDetails.vendorId}
                      valueKey="customerId"
                      options={this.state.customerList}
                      handleChange={this.onCustomerDropDownChange}
                      required={true}
                      className={invoiceIdPresent ? 'no-pointer-events' : ''}
                      emptyField={pageEmptyField}
                      multipleParam={true}
                    />
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={4} className="input-field">
                    <BillingTextField
                      value={vendorDetails.address}
                      floatingLabel="Address"
                      required={true}
                      className="billing-required no-pointer-events"
                      emptyField={this.state.pageEmptyField}
                    />
                  </Cell>
                  <Cell col={4} className="input-field">
                    <BillingTextField
                      value={vendorDetails.panNo}
                      floatingLabel="PAN Number"
                      required={true}
                      type="number"
                      rule="isInt"
                      className="billing-required no-pointer-events"
                      emptyField={pageEmptyField}
                    />
                  </Cell>
                  <Cell col={4} className="input-field">
                    <BillingTextField
                      value={billSummary.remarks}
                      param="remarks"
                      floatingLabel="Remarks"
                      required={true}
                      className="billing-required"
                      emptyField={pageEmptyField}
                      handleChange={this.onInputFieldChange}
                    />
                  </Cell>
                </Grid>
              </div>
              <div className="table-wrapper">
                <div ref="fixedTableBody" className="fixed-table-wrapper">
                  <table>
                    {/* <TableHeader headerDetails={createPurchaseReturnConfig.headerDetails}
                                 filterHeaderLabel={false}
                                 handleSorting={this.handleTableSorting}/>*/}
                    <thead>
                      <tr>
                        <th>
                          {invoiceIdPresent && (
                            <Checkbox
                              checked={this.state.checkBoxForSalesReturn.primary}
                              onChange={({ target: { checked } }) => {
                                this.handlePrimaryCheckBoxClick(checked);
                              }}
                            />
                          )}
                          {!invoiceIdPresent && <span>SN</span>}
                        </th>
                        <th>SKU</th>
                        <th className="right-align">Quantity</th>
                        <th className="right-align">Rate</th>
                        <th className="right-align">Amount</th>
                        <th className="right-align">Discount</th>
                        <th className="right-align">Net Amount</th>
                        <th />
                      </tr>
                    </thead>
                    {invoiceList.map((data, key) => (
                      <tbody className="common-checkbox">
                        <CreatePurchaseReturnTableBody
                          data={data}
                          index={key}
                          deleteSalesInvoice={this.deleteSalesInvoice}
                          handleEditIconClick={this.handleEditIconClick}
                          handleDeleteIconClick={this.handleDeleteIconClick}
                          handleCheckBoxClick={this.handleSecondaryCheckBoxClick}
                          selectedCheckBox={this.state.checkBoxForSalesReturn.secondary}
                          showCheckBox={this.state.invoiceIdPresent}
                        />
                      </tbody>
                    ))}
                  </table>
                </div>

                {/* Fab Icon*/}
                {!invoiceIdPresent && (
                  <div className="mini-fab-button">
                    <Fab mini onClick={() => this.setState({ createModalOpen: true })}>
                      <Icon name="add" />
                    </Fab>
                  </div>
                )}
              </div>
              {/* Sales invoice table summary goes over here.*/}
              <div className="total-section-wrapper">
                {/* <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Discount
                  </Cell>
                  <Cell col={1} className="right-align">
                    {billSummary.discount}
                  </Cell>
                </Grid>*/}
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Gross
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(billSummary.net_amount)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    VAT
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(billSummary.vat || 0)}
                  </Cell>
                </Grid>
                <div className="hr" />
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Total
                  </Cell>
                  <Cell col={1} className="right-align" className="right-align font-bold">
                    {fixedFloatAndCommas(billSummary.gross_amount || 0)}
                  </Cell>
                </Grid>
              </div>
            </div>
          </div>
          <div className="newinvoice-btn-wrapper">
            <Grid>
              <Cell col={8} />
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  accent
                  className="save-btn modal-btn"
                  onClick={() => {
                    this.handleSaveClick();
                  }}
                >
                  Save
                </Button>
              </Cell>
            </Grid>
          </div>

          {/* Begin of the dialog Body*/}
          <Dialog
            open={createModalOpen || editModalOpen || deleteModalOpen}
            onClose={() => {
              this.closeModal();
            }}
          >
            <DialogHeader>
              {createModalOpen && <DialogTitle>Create SKU</DialogTitle>}
              {editModalOpen && <DialogTitle>Edit SKU</DialogTitle>}
              {deleteModalOpen && <DialogTitle>Delete SKU</DialogTitle>}
            </DialogHeader>
            {!deleteModalOpen && (
              <DialogBody>
                <div className="dialog-upperpart">
                  {!(editModalOpen && invoiceIdPresent) && (
                    <Grid className="grid-padding">
                      <Cell col={6} tablet={8}>
                        <label>Brand</label>
                        <BillingSelect
                          name="form-field-name"
                          param="brand"
                          clearable={false}
                          value={this.state.createSKU.brand}
                          valueKey="businessCatalogDetailId"
                          labelKey="title"
                          options={this.state.brandList}
                          handleChange={this.onChange}
                          required={true}
                          emptyField={this.state.formEmptyField}
                        />
                      </Cell>

                      <Cell col={6} tablet={8}>
                        <label>SKU</label>
                        <BillingSelect
                          name="form-field-name"
                          param="sku_id"
                          clearable={false}
                          value={this.state.createSKU.sku_id}
                          valueKey="business_sku_id"
                          labelKey="title"
                          options={this.state.skuList}
                          handleChange={this.onChange}
                          required={true}
                          emptyField={this.state.formEmptyField}
                          multipleParam={true}
                        />
                      </Cell>
                    </Grid>
                  )}

                  <Grid className="grid-padding">
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={this.state.createSKU.batch}
                        handleChange={this.onChange}
                        floatingLabel="Batch"
                        param="batch"
                        required={false}
                        /* className={`billing-required ${this.state.createSKU.hasOwnProperty('disabled') ? this.state.createSKU.disabled ? 'no-pointer-events' : '':''}`}*/
                        className={`billing-required ${invoiceIdPresent ? 'no-pointer-events' : ''}`}
                      />
                    </Cell>

                    <Cell col={4} tablet={8}>
                      {/* <label>Rate</label>
                    <span className="accent-color dialog-detail">{this.state.createSKU.rate}</span>*/}
                      <BillingTextField
                        value={this.state.createSKU.rate}
                        handleChange={this.onChange}
                        floatingLabel="Rate"
                        param="rate"
                        type="number"
                        required={true}
                        zeroError={true}
                        className={`billing-required ${
                          this.state.createSKU.hasOwnProperty('disabled')
                            ? this.state.createSKU.disabled
                              ? 'no-pointer-events'
                              : ''
                            : ''
                        }`}
                        emptyField={this.state.formEmptyField}
                        errorMessage={this.state.createSKU.rate === 0 ? 'Should not be 0' : 'Should not be empty'}
                      />
                    </Cell>
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={this.state.createSKU.quantity}
                        handleChange={this.onChange}
                        floatingLabel="Quantity"
                        param="quantity"
                        required={true}
                        zeroError={true}
                        type="number"
                        rule="isInt"
                        max={this.state.createSKU.hasOwnProperty('maxValue') ? this.state.createSKU.maxValue : '0'}
                        className="billing-required right-side-gap"
                        emptyField={this.state.formEmptyField}
                        errorMessage={this.state.createSKU.quantity === 0 ? 'Should not be 0' : 'Should not be empty'}
                      />
                    </Cell>
                  </Grid>

                  <Grid className="grid-padding">
                    <Cell col={6} tablet={8}>
                      <BillingTextField
                        value={this.state.createSKU.discount}
                        handleChange={this.onChange}
                        floatingLabel="Discount"
                        param="discount"
                        required={true}
                        zeroError={false}
                        type="number"
                        max={Number((this.state.createSKU.quantity * this.state.createSKU.rate).toFixed(2)).toString()}
                        className="billing-required"
                        emptyField={this.state.formEmptyField}
                        errorMessage="Should not be empty"
                      />
                    </Cell>

                    <Cell col={6} tablet={8}>
                      <BillingTextField
                        value={this.state.createSKU.net_amount}
                        handleChange={this.onChange}
                        floatingLabel="Net Amount"
                        param="net_amount"
                        required={true}
                        zeroError={true}
                        type="number"
                        className="billing-required no-pointer-events"
                        emptyField={this.state.formEmptyField}
                        errorMessage={this.state.createSKU.net_amount ? 'Should not be 0' : 'Should not be empty'}
                      />
                    </Cell>
                  </Grid>
                </div>
              </DialogBody>
            )}

            {deleteModalOpen && <div className="default-margin-24">Are you sure you want to delete ?</div>}

            <DialogFooter>
              <Button
                accent
                className="dialog-cancel modal-btn"
                onClick={() => {
                  this.closeModal();
                }}
              >
                Cancel
              </Button>
              <Button
                accent
                className="dialog-ok modal-btn"
                onClick={() => {
                  this.handleModalOkClick();
                }}
              >
                Ok
              </Button>
            </DialogFooter>
          </Dialog>
        </div>
      </div>
    );
  }
}

CreatePR.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
  };
};

const CreatePurchaseReturn = connect(mapStateToProps)(CreatePR);
export default CreatePurchaseReturn;
