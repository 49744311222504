import React from 'react';
import PropTypes from 'prop-types';
import { ACTION } from '../../../data/enums/enums';
import { Icon } from '../../../components/BillingMDC';
import { roundAndCommas } from '../../../utils/conversion';

const propTypes = {
  editHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  dataList: PropTypes.array,
  onRowClick: PropTypes.func,
};
const defaultProps = {};

const getCustomerData = (ledger = {}) => {
  return {
    title: ledger.title || '',
    ledger_ids: ledger.ledgerIds || [],
    idLedgerGroup: ledger.idLedgerGroup || null,
  };
};

const getApiTransformedData = (ledger, action = ACTION.ADD) => {
  const details = {
    title: ledger.title || '',
    ledger_ids: ledger.ledger_ids.map((a) => a) || [],
  };
  if (action === ACTION.ADD) {
    details.title = ledger.title || 0;
    details.ledger_ids = ledger.ledger_ids;
  }
  return details;
};

const AggregatedLedgerBody = ({ dataList, onRowClick, editHandler, deleteHandler }) => (
  <tbody>
    {dataList.map((data) => (
      <tr onClick={() => onRowClick(data)} style={{ cursor: 'pointer' }}>
        <td className='left-align'>{data.title}</td>
        <td className='left-align'>{Array.from(new Set(data.ledgers.map((ledger) => ledger.name))).join(', ')}</td>

        <td className='right-align'>{data.openingBalance ? roundAndCommas(data.openingBalance) : 0}</td>
        <td className='right-align'>{data.debit ? roundAndCommas(data.debit) : 0}</td>
        <td className='right-align'>{data.credit ? roundAndCommas(data.credit) : 0}</td>
        <td className='right-align'>{data.closingBalance ? roundAndCommas(data.closingBalance) : 0}</td>

        <td className='right-align'>
          <Icon
            name='edit'
            onClick={(e) => {
              e.stopPropagation();
              editHandler(data);
            }}
          />
          <Icon
            name='delete'
            onClick={(e) => {
              e.stopPropagation();
              deleteHandler(data);
            }}
          />
        </td>
      </tr>
    ))}
  </tbody>
);

const formValidationFieldList = [
  { title: 'title' },
  { title: 'ledger_ids' },
];

const AggregatedLedgerConfig = [
  {
    id: 2,
    label: 'title',
    title: 'Ledger Groups',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 3,
    label: 'ledgerNames',
    title: 'Ledgers',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 4,
    label: 'opening_balance',
    title: 'Opening',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 5,
    label: 'debit',
    title: 'Debit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 6,
    label: 'credit',
    title: 'Credit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'closing_balance',
    title: 'Closing',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 8,
    label: 'edit',
    title: '',
    className: 'right-align',
    sortable: false,
  },
];

const config = {
  header: AggregatedLedgerConfig,
  getTableBody: AggregatedLedgerBody,
  getDetail: getCustomerData,
  getApiTransformedData,
  formValidationFieldList,
};
AggregatedLedgerBody.propTypes = propTypes;

AggregatedLedgerBody.defaultProps = defaultProps;

export default config;
