import { fixedFloatAndCommas } from '../../../utils/conversion';

const getTableData = dataList => {
  let tableDataRows = [];
  if (dataList && dataList.length > 0) {
    tableDataRows = dataList?.map((data, index) => {
      return [
        index + 1,
        data.ledger_name ?? '',
        data.pan_no ?? '',
        fixedFloatAndCommas(data.taxable_sales_amount) ?? '0',
        fixedFloatAndCommas(data.vat) ?? '0',
        fixedFloatAndCommas(data.tax_exempted_amount) ?? '0',
      ];
    });
  }
  return {
    tableDataRows,
  };
};

const getFilteredData = (dataList, companyData, middleHeaderData, columnTotal) => {
  const tableData = getTableData(dataList);
  return {
    companyName: companyData?.title ?? '',
    address: companyData?.address ?? '',
    phone: companyData?.phone ?? '',
     panNo: companyData?.panNo || companyData?.panNumber || '',
    email: companyData?.email ?? '',
    title: middleHeaderData?.title ?? '',
    date: middleHeaderData?.date ?? '',
    tableDataRows: tableData?.tableDataRows ?? [],

    tableFooterData: {
      totalTaxableAmount: fixedFloatAndCommas(columnTotal?.totalTaxableAmount)?.toString() ?? '0',
      totalTaxableVat: fixedFloatAndCommas(columnTotal?.totalTaxableVat)?.toString() ?? '0',
      totalTaxExemptedSales: fixedFloatAndCommas(columnTotal.totalTaxExemptedSales)?.toString() ?? '0',
    },
  };
};

const getPrintData = (dataList, companyData, middleHeaderData, columnTotal) => {
  const ledgerData = getFilteredData(dataList, companyData, middleHeaderData, columnTotal);
  return ledgerData;
};

export default getPrintData;
