import { compose } from 'recompose';

import VATSummary from './VATSummary';

import apiInterceptor from '../../../api/interceptor';

import { vatSummaryReportApi } from '../../common/base.api';

const composedVATSummary = compose(apiInterceptor({ ...vatSummaryReportApi }))(VATSummary);

export default composedVATSummary;
