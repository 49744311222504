import styled from 'styled-components';

const DrawerFilterStyled = styled.div`
  .right-drawer {
    direction: ltr;
  }

  .ref-date-label {
    margin: 0px 18px 20px;
  }
  .right-drawer > .mdc-temporary-drawer__drawer {
    max-width: 354px !important;
  }

  .mdc-temporary-drawer__drawer {
    line-height: 0;
  }

  .mdc-temporary-drawer__drawer .filter-wrapper {
    margin-top: 24px;
  }
  .mdc-temporary-drawer__drawer {
    margin-top: 64px;
  }
  .mdc-temporary-drawer__drawer .default-horizontal-padding-24 {
    line-height: 21px;
  }

  .mdc-temporary-drawer__drawer {
    .drawer-filter {
      .filter-content-wrap {
        max-height: calc(100vh - 240px);
        overflow: auto;
      }
      .filter-content {
        padding: 12px 24px;
        .mdc-form-field {
          display: flex;
          flex-wrap: wrap;
          width: unset;
          padding: 0;
          margin-bottom: 4px;
          .mdc-checkbox {
            &:active {
              background-color: transparent;
              &::before {
                background-color: transparent;
              }
            }
            input:focus,
            &::after,
            &::before {
              background-color: transparent;
            }
            .mdc-checkbox__background {
              top: 8px;
            }
          }
          label {
            flex: 1;
            margin: 0;
            text-transform: uppercase;
            word-break: break-word;
          }
        }
      }
    }
  }
`;
export default DrawerFilterStyled;
