import moment from 'moment/moment';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogTitle,
  DialogFooter,
  Button,
  Grid,
  Cell,
  Checkbox,
} from '../../../components/BillingMDC';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import DatePicker from '../../../components/DatePicker/DatePicker';
import BillingTextField from '../../../components/TextField/BillingTextField';
import BillingSelect from '../../../components/Select/BillingSelect';
import { LEDGERS } from '../../../data/enums/enums';

const JvDialogView = (
  {
    onModalClose,
    modalOpen,
    editModalOpen,
    deleteModalOpen,
    createJV,
    handleInputChange,
    onModalSubmit,
    formEmptyField,
    handleDateChange,
    transactionDisabled,
    tagList,
    addTag,
    renderLedgerSelectOptions,
  },
) => (
  <Dialog
    open={modalOpen || editModalOpen || deleteModalOpen}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      {modalOpen && <DialogTitle>Create Journal Voucher</DialogTitle>}
      {editModalOpen && <DialogTitle>Edit Journal Voucher</DialogTitle>}
      {deleteModalOpen && <DialogTitle>Delete Journal Voucher</DialogTitle>}
    </DialogHeader>
    {!deleteModalOpen && (
      <DialogBody>
        <div className='dialog-upperpart'>
          <Grid className='grid-padding'>
            <Cell col={4} tablet={4}>
              <label>RefDate</label>
              <span className='dialog-date-picker-wrapper'>
                <DatePicker date={moment(createJV.ref_date)} onChange={(e) => handleDateChange('ref_date', e)} />
              </span>
            </Cell>
            <Cell col={4} tablet={4} className='input-field'>
              <BillingTextField
                value={createJV.ref_number}
                handleChange={handleInputChange}
                floatingLabel='RefNumber'
                param='ref_number'
                required={false}
                className='billing-required right-side-gap'
                emptyField={formEmptyField}
              />
            </Cell>
            <Cell col={4} tablet={8} className='input-select'>
              <label>Tag</label>
              <BillingSelect
                name='form-field-name'
                param='tagid'
                clearable
                value={createJV.tagid}
                valueKey='idTags'
                labelKey='title'
                options={tagList}
                handleChange={handleInputChange}
                multipleParam
                required={addTag && true}
                emptyField={addTag && formEmptyField}
              />
            </Cell>
          </Grid>

          <Grid className='grid-padding'>
            <Cell col={4} tablet={8}>
              <BillingTextField
                value={createJV.dr_amount}
                handleChange={handleInputChange}
                floatingLabel='Debit'
                param='dr_amount'
                type='number'
                required
                zeroError={!transactionDisabled.debit}
                disabled={transactionDisabled.debit}
                className='billing-required right-side-gap'
                emptyField={formEmptyField}
                errorMessage={
                  transactionDisabled.debit
                    ? 'Should not be empty'
                    : createJV.dr_amount === 0
                      ? 'Should not be 0'
                      : 'Should not be empty'
                }
              />
            </Cell>
            <Cell col={4} tablet={8}>
              <BillingTextField
                value={createJV.cr_amount}
                handleChange={handleInputChange}
                floatingLabel='Credit'
                param='cr_amount'
                type='number'
                required
                zeroError={!transactionDisabled.credit}
                className='billing-required right-side-gap'
                emptyField={formEmptyField}
                errorMessage={
                  transactionDisabled.credit
                    ? 'Should not be empty'
                    : createJV.cr_amount === 0
                      ? 'Should not be 0'
                      : 'Should not be empty'
                }
                disabled={transactionDisabled.credit}
              />
            </Cell>
            {renderLedgerSelectOptions()}
          </Grid>
          <Grid className='grid-padding'>
            <Cell col={8} tablet={8}>
              <BillingTextField
                value={createJV.narration}
                param='narration'
                floatingLabel='Narration'
                className='textbox billing-required'
                handleChange={handleInputChange}
              />
            </Cell>
          </Grid>
          {/* {createJV.ledger_id === LEDGERS.VAT.ledger_id && (
            <div className="cash-checkbox padding-b-12">
              <Checkbox
                checked={createJV.includePartyInfo}
                onChange={({ target: { checked } }) => this.handlePartyCheckboxClick(checked)}
              />
              Do You want to show these entry in VAT report ?
            </div>
          )} */}
        </div>
      </DialogBody>
    )}

    {deleteModalOpen && <div className='default-margin-24'>Are you sure you want to delete ?</div>}

    <DialogFooter>
      <Button
        accent
        className='dialog-cancel modal-btn'
        onClick={() => {
          onModalClose();
        }}
      >
        Cancel
      </Button>
      <Button
        accent
        className='dialog-ok modal-btn'
        onClick={() => {
          onModalSubmit();
        }}
      >
        Ok
      </Button>
    </DialogFooter>
  </Dialog>
);

export default JvDialogView;
