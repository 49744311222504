import { fixedFloatAndCommas } from '../../../utils/conversion';

const getTableData = dataList => {
  let tableDataRows = [];
  console.log('dataList',dataList)
  if (dataList && dataList.length > 0) {
    tableDataRows = dataList?.map((data, index) => {
      return [
        index + 1,
        data.transaction.purchase ? data.purchaseMitiTitle : '',
        data.transaction.purchase ? data.purchaseLedgerName : '',
        data.transaction.purchase ? (data.transaction.purchase.total_purchase ? fixedFloatAndCommas(data.purchaseTotal) : '0') : '',
        data.transaction.purchase ? (fixedFloatAndCommas(data.purchaseTEA) ?? '0') : '',
        data.transaction.purchase ? (fixedFloatAndCommas(data.purchaseTPA) ?? '0') : '',
        data.transaction.purchase ? (data.purchasePTR ? fixedFloatAndCommas(data.purchasePTR) : '13%') : '',
        data.transaction.purchase ? (data.transaction.purchase.taxable_purchase_vat ? fixedFloatAndCommas(data.purchasTPV) : '0') : '',
        data.transaction.sales ? data.salesMitiTitle : '',
        data.transaction.sales ? data.salesLedgerName : '',
        data.transaction.sales ? (data.transaction.sales.total_sales ? fixedFloatAndCommas(data.salesTotal) : '0') : '',
        data.transaction.sales ? (data.transaction.sales.tax_exempted_amount ? fixedFloatAndCommas(data.salesTEA) : '0') : '',
        data.transaction.sales ? (data.transaction.sales.taxable_sales_amount ? fixedFloatAndCommas(data.salesTSA) : '0') : '',
        data.transaction.sales ? (data.transaction.sales.sales_tax_rate ? fixedFloatAndCommas(data.salesSTR) : '13%') : '',
        data.transaction.sales ? (data.transaction.sales.taxable_sales_vat ? fixedFloatAndCommas(data.salesTSV) : '0') : '',
      ];
    });
  }
  return {
    tableDataRows,
  };
};

const getFilteredData = (dataList, companyData, middleHeaderData) => {
  const tableData = getTableData(dataList);
  return {
    companyName: companyData?.title ?? '',
    address: companyData?.address ?? '',
    phone: companyData?.phone ?? '',
     panNo: companyData?.panNo || companyData?.panNumber || '',
    email: companyData?.email ?? '',
    title: middleHeaderData?.title ?? '',
    date: middleHeaderData?.date ?? '',
    tableDataRows: tableData?.tableDataRows ?? [],
  };
};

const getPrintData = (dataList, companyData, middleHeaderData) => {
  const ledgerData = getFilteredData(dataList, companyData, middleHeaderData);
  return ledgerData;
};

export default getPrintData;
