import { compose } from 'recompose';

import EntryLog from './EntryLog';

import apiInterceptor from '../../../api/interceptor';

import { entryLogApi } from '../../common/base.api';

const composedEntryLog = compose(apiInterceptor({ ...entryLogApi }))(EntryLog);

export default composedEntryLog;
