import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../utils/conversion';
import { formatToReadableDate } from '../../utils/filterUtil';

const JournalVoucherReportTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <tr onClick={() => onRowClick(data.voucherNumber)}>
        <td className='left-align'>{data.voucherNumber}</td>
        <td className='left-align'>{formatToReadableDate(data.documentDate)}</td>
        <td className='left-align'>{data.mitiTitle}</td>
        {/*   <td className="left-align">{data.customer ? data.customer.title : null}</td>*/}
        <td className='right-align'>{data.drAmount ? fixedFloatAndCommas(data.drAmount) : 0}</td>
        <td className='right-align'>{data.crAmount ? fixedFloatAndCommas(data.crAmount) : 0}</td>
        <td className='left-align'>{data.narration}</td>
        <td className='left-align'>{formatToReadableDate(data.createdAt)}</td>
      </tr>
    ))}
  </tbody>
);

JournalVoucherReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const journalVoucherReportConfig = [
  { id: 1, label: 'voucher_number', title: 'VoucherNo.', className: 'cursor-pointer left-align', sortable: true },
  { id: 2, label: 'document_date', title: 'Document Date', className: 'cursor-pointer left-align', sortable: true },
  { id: 3, label: 'miti_title', title: 'Miti', className: 'cursor-pointer left-align', sortable: true },
  /*  {id: 4, label: 'ledgers.title', title: 'Ledger', className: ' left-align', sortable: true},*/
  { id: 5, label: 'drAmount', title: 'Debit', className: ' right-align', sortable: false },
  { id: 5, label: 'crAmount', title: 'Credit', className: ' right-align', sortable: false },
  { id: 5, label: 'narration', title: 'Narration', className: ' left-align', sortable: false },
  { id: 8, label: 'created_at', title: 'Created Date', className: '', sortable: true },
];

const config = {
  header: journalVoucherReportConfig,
  getTableBody: JournalVoucherReportTableBody,
};

export default config;
