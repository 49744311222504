import React from 'react';
import TableHeader from '../../../components/TableHeader';
import * as filterUtil from '../../../utils/filterUtil';

const View = ({ data, company, config, queryParameters, onRowClick, onTableClickData, activeView }) => {
  const displayDate = () => {
    if ((queryParameters.date.start || queryParameters.date.end) === filterUtil.getCurrentDay()) {
      return <b>Today</b>;
    } else {
      return (
        <>
          {'date : '}
          <b>{`${queryParameters.date.start}-${queryParameters.date.end}`}</b>
        </>
      );
    }
  };
  return (
    <div className='inner-view'>
      <div className='trial-view'>
        <div className='table-view'>
          <table>
            <caption>
              <b>{company.title}</b>
            </caption>
            <caption>
              <b>Trial Balance</b> as of {displayDate()}
            </caption>
            <thead className='ledger-header'>
              <tr>
                <th>Ledger</th>
                <th colSpan='2' scope='colgroup'>
                  Opening Balance
                </th>
                <th colSpan='2' scope='colgroup'>
                  Period Balance
                </th>
                <th colSpan='2' scope='colgroup'>
                  Closing Balance
                </th>
              </tr>
            </thead>
            <TableHeader headerDetails={config.header} />
            {config.getTableBody({
              dataList: data.groupedData,
              totalBalance: data.totalBalance,
              totalGroupBalance: data.totalGroupBalance,
              onRowClick,
              onTableClickData,
              activeView,
            })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default View;
