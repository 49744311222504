import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as httpUtils from '../../../utils/httpUtils';
import {
  CreateJournalVoucherTableBody,
  createJournalVoucherConfig,
  ledgerType,
  partyHeaderList,
  vatAccountLedger,
  message,
} from './CreateJournalVoucherConfig';
import TableHeader from '../../../components/TableHeader';
import BillingTextField from '../../../components/TextField/BillingTextField';
import BillingSelect from '../../../components/Select/BillingSelect';
import RadioButtonList from '../../../components/RadioButtonList/RadioButtonList';
import * as appConfig from '../../config';
import DatePicker from '../../../components/DatePicker/DatePicker';

import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
  Radio,
  RadioGroup,
  Snackbar,
} from 'react-mdc-web';
import * as filterUtil from '../../../utils/filterUtil';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import history from '../../../utils/history';
import { clone } from '../../../utils/arrayProcessor';
import { PAN_NO_VALID_DIGIT } from '../Configuration/Ledgers/LedgersConfig';
import { JOURNAL_VOUCHER_BASE, JOURNAL_VOUCHER_PRINT } from '../../../data/enums/Route';

class CreateJournalVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      createModalOpen: false,
      editModalOpen: false,
      formEmptyField: false,
      pageEmptyField: false,
      deleteModalOpen: false,
      indexInJVList: 0,
      businessUnitId: '',
      voucherNumber: '',
      createJV: {
        ledger_name: '',
        ledger_id: '',
        dr_amount: 0,
        cr_amount: 0,
        ref_number: '',
        ledger_type: '',
        transaction_type: 5,
        business_id: '',
        ref_date: new Date(),
        narration: '',
        entered_by: '',
        party_name: '',
        party_pan_no: '',
        party_taxable_amount: 0,
        includePartyInfo: false,
      },
      jvSummary: {
        remarks: '',
        narration: '',
        entered_by: '',
        business_id: '',
        net_dr_amount: 0,
        net_cr_amount: 0,
        transaction_type: 5,
        transaction_date: filterUtil.getCurrentDay(),
      },
      jvList: [],
      formValidateFieldArray: ['ledger_id', 'ledger_type', 'dr_amount', 'cr_amount', 'narration', 'party_pan_no'],
      pageValidateFieldArray: ['narration'],
      headerTitleToView: createJournalVoucherConfig.headerTitleToView,
      buList: [],
      vendorList: [],
      ledgerList: [],
      customerList: [],
      customerListFetched: false,
      vendorListFetched: false,
      creditDisabled: false,
      debitDisabled: false,
      showMessage: false,
      success: true,
      invalidPAN: false,
      message: message.success,
      partyHeaderIncluded: false,
    };
  }

  // after mounted get the customer list.
  componentDidMount() {
    // this.getVendorList();
    // this.getCustomerList();
    this.getBUList();
    this.updateTableHeaderList(false, partyHeaderList);
  }

  // get BU list
  getBUList = () => {
    httpUtils.get(appConfig.baseUrl + 'upload/business-company').then(response => {
      if (response.success) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  // get the customer list for the selection.
  getCustomerList = () => {
    this.setState({ loading: true });
    const type = 'all';
    httpUtils.get(appConfig.baseUrl + `ledger/customer-list?type=${type}`).then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        let responseDataList = response.data;
        responseDataList.map(customer => {
          customer['label'] = customer.idLedger + '. ' + customer.name;
        });
        this.setState({ customerList: responseDataList, customerListFetched: true }, () => {
          this.setLedgerList();
        });
      }
    });
  };

  // get the customer list for the selection.
  getVendorList = () => {
    this.setState({ loading: true });
    httpUtils.get(appConfig.baseUrl + '/ledger/vendor-list').then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        let responseDataList = response.data;
        responseDataList.map(customer => {
          customer['label'] = customer.customerId + '. ' + customer.name;
        });
        this.setState({ vendorList: responseDataList, vendorListFetched: true }, () => {
          this.setLedgerList();
        });
      }
    });
  };

  setLedgerList = () => {
    const { vendorList, customerList, ledgerList } = this.state;
    if (this.state.customerListFetched && this.state.vendorListFetched) {
      // ledgerList = vendorList.concat(customerList);
      const ledgerList = [...vendorList, ...customerList];
      this.setState({ ledgerList: ledgerList });
    }
  };

  handleBUClick = id => {
    this.setState({ businessUnitId: id }, () => {
      this.getCustomerList();
      this.getVendorList();
    });
  };

  onInputFieldChange = (field, value) => {
    const { jvSummary } = this.state;
    jvSummary[field] = value;
    this.setState({ jvSummary: jvSummary });
  };

  // handle change of create jv events.
  onChange = (field, value, all = {}) => {
    const { createJV } = this.state;
    createJV[field] = value;

    if (field === 'dr_amount' || field === 'cr_amount') {
      this.disableInput(createJV.dr_amount, createJV.cr_amount);
    }
    // update the changes in the field value.
    if (field === 'ledger_id') {
      createJV.ledger_name = all['name'] || 'name';
    }
    if (field === 'party_pan_no') createJV[field] = value === 0 ? '' : value;
    this.setState({ createJV: clone(createJV), invalidPAN: false });
  };

  disableInput = (dr, cr) => {
    const { createJV } = this.state;
    if (Number(dr) !== 0) {
      createJV.cr_amount = 0;
      this.setState({ creditDisabled: true, createJV: createJV });
    } else if (Number(cr) !== 0) {
      createJV.dr_amount = 0;
      this.setState({ debitDisabled: true, createJV: createJV });
    }
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (createJV, index) => {
    this.disableInput(createJV.dr_amount, createJV.cr_amount);
    this.setState({ createJV: clone(createJV), editModalOpen: true, indexInJVList: index });
  };

  // create the sales Return, update the discount value
  createJournalVoucher = () => {
    const { jvList, createJV, businessUnitId, partyHeaderIncluded } = this.state;
    createJV.business_id = businessUnitId;
    // createJV.ref_date = moment(createJV.ref_date).format('ll');
    if (createJV.party_name) {
      if (!partyHeaderIncluded)
        this.setState({ partyHeaderIncluded: true }, () => this.updateTableHeaderList(true, partyHeaderList));
    }
    jvList.push(createJV);
    this.setState({ jvList: jvList }, () => {
      this.closeModal();
      this.handleVoucherListUpdate();
    });
  };

  handleVoucherListUpdate = () => {
    const { jvList, jvSummary } = this.state;
    let net_dr_amount = 0;
    let net_cr_amount = 0;
    jvList.map(element => {
      net_dr_amount += Number(element.dr_amount);
      net_cr_amount += Number(element.cr_amount);
      // return obj;
    });
    jvSummary.net_dr_amount = Number(net_dr_amount).toFixed(2);
    jvSummary.net_cr_amount = Number(net_cr_amount).toFixed(2);
  };

  balanceDebitCredit = () => {
    const { jvSummary } = this.state;
    const debit = Number(jvSummary.net_dr_amount);
    const credit = Number(jvSummary.net_cr_amount);
    if (debit !== credit) {
      this.setState({ showMessage: true, success: false, message: message.balanceError });

      return false;
    } else return true;
  };
  // editStockJournal
  editJournalVoucher = (data, index) => {
    const { jvList, createJV, partyHeaderIncluded } = this.state;
    // createJV.ref_date = moment(createJV.ref_date).format('ll');
    if (createJV.party_name) {
      if (!partyHeaderIncluded)
        this.setState({ partyHeaderIncluded: true }, () => this.updateTableHeaderList(true, partyHeaderList));
    }
    jvList[index] = createJV;
    this.setState({ jvList: jvList }, () => {
      this.handleVoucherListUpdate();
      this.closeModal();
    });
  };

  // delete the particular journal voucher from jvList.
  deleteJVItem = index => {
    const { jvList, indexInJVList } = this.state;
    if (jvList.length >= indexInJVList) {
      jvList.splice(indexInJVList, 1);
      this.setState({ jvList: jvList }, () => {
        this.handleVoucherListUpdate();
        this.closeModal();
      });
    }
  };

  // close the modal resetting  all the value
  closeModal = () => {
    this.setState({ createModalOpen: false, editModalOpen: false, deleteModalOpen: false, invalidPAN: false });
    this.resetJVDialog();
  };

  // reset JV Dialog
  resetJVDialog = () => {
    const createJV = {
      ledger_id: '',
      ledger_name: '',
      dr_amount: 0,
      cr_amount: 0,
      ref_number: '',
      transaction_type: 5,
      ledger_type: '',
      entered_by: '',
      ref_date: new Date(),
      narration: '',
      party_name: '',
      party_pan_no: '',
      party_taxable_amount: 0,
      includePartyInfo: false,
    };
    this.setState({ createJV: createJV, formEmptyField: false, creditDisabled: false, debitDisabled: false });
  };

  handleDateChange = date => {
    const { createJV } = this.state;
    createJV.ref_date = date.toDate();
    this.setState({ createJV: createJV });
  };
  // handle on modal ok click
  handleModalOkClick = () => {
    // check edit or create config //close the dialog
    const { createModalOpen, editModalOpen, createJV, formValidateFieldArray } = this.state;
    if (createModalOpen || editModalOpen) {
      const flag = this.state.creditDisabled || this.state.debitDisabled;
      const valid = this.validateForm(createJV, formValidateFieldArray, 'formEmptyField', flag);
      if (valid) {
        if (createModalOpen) {
          this.createJournalVoucher();
        } else {
          this.editJournalVoucher(this.state.createJV, this.state.indexInJVList);
        }
      }
    } else {
      this.deleteJVItem();
    }
  };

  validateForm = (formObject, config, flag, zeroFlag) => {
    const data = Object.keys(formObject).some(k => {
      if (config.indexOf(k) > -1) {
        let value = formObject[k];
        const conditionParameter = this.computeCondition(k, value, zeroFlag);
        if (conditionParameter) {
          this.setState({ [flag]: true });

          return true;
        }
      }
    });

    return !data;
  };

  computeCondition = (item, value, zeroFlag) => {
    switch (item) {
      case 'party_pan_no':
        if (value.toString().length > 0) {
          if (value.toString().length === PAN_NO_VALID_DIGIT) return false;
          this.setState({ invalidPAN: true });

          return true;
        }

        return false;
      default:
        return zeroFlag ? value === '' : !value || value === '0';
    }
  };

  handleSaveClick = () => {
    // generate the object to send to the server
    const { jvList, jvSummary, businessUnitId, pageValidateFieldArray } = this.state;
    const { user } = this.props;
    const toValidObject = {
      narration: jvSummary.narration,
    };
    const valid = this.validateForm(toValidObject, pageValidateFieldArray, 'pageEmptyField', false);
    const jvListCheck = jvList.length > 0;
    if (!jvListCheck) {
      alert(' Journal Voucher List  is empty');
    }
    if (valid && jvListCheck) {
      const balanceCheck = this.balanceDebitCredit();
      if (balanceCheck) {
        this.setState({ loading: true });
        const filteredJvList = jvList.map(jvDetails => {
          delete jvDetails['ledger_name'];
          delete jvDetails['includePartyInfo'];
          jvDetails.entered_by = user.idUsers;

          return jvDetails;
        });

        const object = {
          jv: {
            summary: {
              business_id: businessUnitId,
              entered_by: user.idUsers,
              narration: jvSummary.narration,
              transaction_type: jvSummary.transaction_type,
            },

            detail: filteredJvList,
          },
          // remarks: jvSummary.remarks,
        };

        httpUtils
          .post(appConfig.baseUrl + 'journal-voucher', object)
          .then(response => {
            this.setState({ loading: false });
            if (response.success) {
              const { voucher_number = '' } = response.data.journalVoucherData;
              this.setState({
                showMessage: true,
                success: true,
                message: message.success,
                voucherNumber: voucher_number,
                partyHeaderIncluded: false,
              });
            }
          })
          .catch(error => {
            this.setState({ loading: false, showMessage: true, success: false, message: message.error });
          });
      }
    }
  };

  directToMainPage = () => {
    const { showMessage, success, voucherNumber } = this.state;
    if (!showMessage && success) {
      const voucherNo = voucherNumber.replace(/[/]/g, '%2F');
      history.push(`/${JOURNAL_VOUCHER_PRINT}/${voucherNo}`);
    }
  };

  handleCancelClick = () => {
    history.push(`/${JOURNAL_VOUCHER_BASE}`);
  };

  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInJVList: index });
  };

  onRadioChange = value => {
    const { createJV } = this.state;
    createJV.ledger_type = value;
    this.setState({ createJV: createJV });
  };

  updateTableHeaderList = (flag, list = []) => {
    createJournalVoucherConfig.headerDetails = createJournalVoucherConfig.headerDetails.map(item => {
      item.display = list.includes(item.label) ? flag : item.display;

      return item;
    });
  };

  handlePartyCheckboxClick = checked => {
    const { createJV } = this.state;
    createJV.party_name = '';
    createJV.party_pan_no = '';
    createJV.party_taxable_amount = '';
    createJV.includePartyInfo = checked;
    this.setState({ createJV });
  };

  render() {
    const {
      loading,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      buList,
      jvList,
      showMessage,
      createJV,
      partyHeaderIncluded,
    } = this.state;

    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="message-snackbar">
            {
              <Snackbar
                className={this.state.success ? 'success-message' : ''}
                timeout={this.state.success ? 1000 : 9000}
                onTimeout={() => {
                  this.setState({ showMessage: false }, () => {
                    this.directToMainPage();
                  });
                }}
                open={showMessage}
              >
                {this.state.message}
              </Snackbar>
            }
          </div>
          <div className="card-header-bar clearfix" ref="tableReference">
            <div className="header-left">
              <h2>New Journal Voucher</h2>
            </div>
            <div className="header-right">{this.state.jvSummary.transaction_date}</div>
          </div>
          <div className="card-body">
            <div className="radio-list-wrapper">
              {buList.length && <RadioButtonList data={buList} handleBuClick={this.handleBUClick} />}
            </div>
            <div className={this.state.businessUnitId ? '' : 'block-overlay'}>
              <div className="three-input-wrapper">
                <Grid>
                  <Cell col={4} className="input-field">
                    <BillingTextField
                      value={this.state.jvSummary.narration}
                      param="narration"
                      floatingLabel="Narration"
                      required={true}
                      className="billing-required"
                      emptyField={this.state.pageEmptyField}
                      handleChange={this.onInputFieldChange}
                    />
                  </Cell>
                </Grid>
              </div>
              <div className="table-wrapper">
                <div ref="fixedTableBody" className="overflow-scrollable fixed-table-wrapper">
                  <table>
                    <TableHeader
                      headerDetails={createJournalVoucherConfig.headerDetails}
                      filterHeaderLabel={false}
                      handleSorting={this.handleTableSorting}
                    />
                    {jvList.map((data, key) => (
                      <tbody>
                        <CreateJournalVoucherTableBody
                          data={data}
                          index={key}
                          deleteSalesInvoice={this.deleteSalesInvoice}
                          handleEditIconClick={this.handleEditIconClick}
                          handleDeleteIconClick={this.handleDeleteIconClick}
                          partyIncluded={partyHeaderIncluded}
                        />
                      </tbody>
                    ))}
                  </table>
                </div>

                {/* Fab Icon*/}
                <div className="mini-fab-button">
                  <Fab mini onClick={() => this.setState({ createModalOpen: true })}>
                    <Icon name="add" />
                  </Fab>
                </div>
              </div>
              {/* Sales invoice table summary goes over here.*/}
              <div className="total-section-wrapper">
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Total Debit:{' '}
                  </Cell>
                  <Cell col={1} className="right-align">
                    {this.state.jvSummary.net_dr_amount}
                  </Cell>
                </Grid>

                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Total Credit:
                  </Cell>
                  <Cell col={1} className="right-align">
                    {this.state.jvSummary.net_cr_amount}
                  </Cell>
                </Grid>
              </div>
            </div>
          </div>
          <div className="newinvoice-btn-wrapper ">
            <Grid>
              <Cell col={8} />
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  accent
                  className="save-btn modal-btn"
                  onClick={() => {
                    this.handleSaveClick();
                  }}
                >
                  Save
                </Button>
              </Cell>
            </Grid>
          </div>

          {/* Begin of the dialog Body*/}
          <Dialog
            open={createModalOpen || editModalOpen || deleteModalOpen}
            onClose={() => {
              this.closeModal();
            }}
          >
            <DialogHeader>
              {createModalOpen && <DialogTitle>Create Journal Voucher</DialogTitle>}
              {editModalOpen && <DialogTitle>Edit Journal Voucher</DialogTitle>}
              {deleteModalOpen && <DialogTitle>Delete Journal Voucher</DialogTitle>}
            </DialogHeader>
            {!deleteModalOpen && (
              <DialogBody>
                <div className="dialog-upperpart">
                  {/* <div>
                  <div>
                    <label>Ledger Type</label>
                  </div>
                  <div className="ledger-type-radio">
                    <RadioGroup
                      onChange={({target: {value}})=>{this.onRadioChange(value)}}
                      name="ledger-type"
                      className="ledger-type-radio-group"
                      value={this.state.createJV.ledger_type}>
                      <Radio value="1">Customer Ledger</Radio>
                      <Radio value="2">Sales Ledger</Radio>
                      <Radio value="3">Tax Ledger</Radio>
                      <Radio value="4">Vendor Ledger</Radio>
                      <Radio value="5">Purchase Ledger</Radio>
                    </RadioGroup>
                  </div>
                </div>*/}
                  <Grid className="grid-padding">
                    <Cell col={3} tablet={8}>
                      <label>RefDate</label>
                      <span className="dialog-date-picker-wrapper">
                        <DatePicker date={moment(createJV.ref_date)} onChange={this.handleDateChange} />
                      </span>
                    </Cell>
                    <Cell col={5} tablet={8} className="input-field">
                      <BillingTextField
                        value={createJV.ref_number}
                        handleChange={this.onChange}
                        floatingLabel="RefNumber"
                        param="ref_number"
                        required={false}
                        className="billing-required right-side-gap"
                        emptyField={this.state.formEmptyField}
                      />
                    </Cell>
                    <Cell col={4} tablet={8} className="input-select">
                      <label>Transaction Type</label>
                      <BillingSelect
                        name="form-field-name"
                        param="ledger_type"
                        clearable={false}
                        value={createJV.ledger_type}
                        valueKey="value"
                        options={ledgerType}
                        handleChange={this.onChange}
                        required={true}
                        emptyField={this.state.formEmptyField}
                      />
                    </Cell>
                  </Grid>

                  <Grid className="grid-padding">
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={createJV.dr_amount}
                        handleChange={this.onChange}
                        floatingLabel="Debit"
                        param="dr_amount"
                        type="number"
                        required={true}
                        zeroError={!this.state.debitDisabled}
                        disabled={this.state.debitDisabled}
                        className="billing-required right-side-gap"
                        emptyField={this.state.formEmptyField}
                        errorMessage={
                          this.state.debitDisabled
                            ? 'Should not be empty'
                            : this.state.createJV.dr_amount === 0
                            ? 'Should not be 0'
                            : 'Should not be empty'
                        }
                      />
                    </Cell>
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={createJV.cr_amount}
                        handleChange={this.onChange}
                        floatingLabel="Credit"
                        param="cr_amount"
                        type="number"
                        required={true}
                        zeroError={!this.state.creditDisabled}
                        className="billing-required right-side-gap"
                        emptyField={this.state.formEmptyField}
                        errorMessage={
                          this.state.creditDisabled
                            ? 'Should not be empty'
                            : this.state.createJV.cr_amount === 0
                            ? 'Should not be 0'
                            : 'Should not be empty'
                        }
                        disabled={this.state.creditDisabled}
                      />
                    </Cell>
                    <Cell col={4} tablet={8} className="input-select">
                      <label>Ledger</label>
                      <BillingSelect
                        name="form-field-name"
                        param="ledger_id"
                        clearable={false}
                        value={createJV.ledger_id}
                        valueKey="customerId"
                        options={this.state.ledgerList}
                        handleChange={this.onChange}
                        required={true}
                        emptyField={this.state.formEmptyField}
                        multipleParam={true}
                      />
                    </Cell>
                  </Grid>
                  <Grid className="grid-padding">
                    <Cell col={6} tablet={8}>
                      <BillingTextField
                        value={createJV.narration}
                        param="narration"
                        floatingLabel="Narration"
                        required={true}
                        className="billing-required"
                        emptyField={this.state.formEmptyField}
                        handleChange={this.onChange}
                      />
                    </Cell>
                  </Grid>
                  {createJV.includePartyInfo && (
                    <Grid className="grid-padding">
                      <Cell col={4} tablet={6}>
                        <BillingTextField
                          value={createJV.party_name}
                          param="party_name"
                          floatingLabel="Party Name"
                          required={false}
                          className="billing-required"
                          handleChange={this.onChange}
                        />
                      </Cell>
                      <Cell col={3} tablet={6}>
                        <BillingTextField
                          value={createJV.party_pan_no}
                          param="party_pan_no"
                          floatingLabel="PAN Number"
                          required={false}
                          rule="isInt"
                          type="number"
                          emptyField={this.state.formEmptyField}
                          invalidError={this.state.invalidPAN}
                          className="billing-required"
                          handleChange={this.onChange}
                          errorMessage="It should contain 10 digits"
                          disabled={!createJV.party_name}
                          min={''}
                        />
                      </Cell>
                      <Cell col={3} tablet={6}>
                        <BillingTextField
                          value={createJV.party_taxable_amount}
                          param="party_taxable_amount"
                          floatingLabel="Taxable Amount"
                          required={false}
                          type="number"
                          className="billing-required"
                          handleChange={this.onChange}
                          disabled={!createJV.party_name}
                        />
                      </Cell>
                    </Grid>
                  )}
                  {createJV.ledger_id === vatAccountLedger && (
                    <div className="cash-checkbox padding-b-12">
                      <Checkbox
                        checked={createJV.includePartyInfo}
                        onChange={({ target: { checked } }) => this.handlePartyCheckboxClick(checked)}
                      />
                      Do You want to show these entry in VAT report ?
                    </div>
                  )}
                </div>
              </DialogBody>
            )}

            {deleteModalOpen && <div className="default-margin-24">Are you sure you want to delete ?</div>}

            <DialogFooter>
              <Button
                accent
                className="dialog-cancel modal-btn"
                onClick={() => {
                  this.closeModal();
                }}
              >
                Cancel
              </Button>
              <Button
                accent
                className="dialog-ok modal-btn"
                onClick={() => {
                  this.handleModalOkClick();
                }}
              >
                Ok
              </Button>
            </DialogFooter>
          </Dialog>
        </div>
      </div>
    );
  }
}

CreateJournalVoucher.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
  };
};

const createJournalVoucher = connect(mapStateToProps)(CreateJournalVoucher);

export default createJournalVoucher;
