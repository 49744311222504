import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../utils/conversion';

const pickListConfig = [
  {
    id: 1,
    label: 'business_name',
    title: 'Business Name',
  },
  {
    id: 2,
    label: 'brand',
    title: 'Brand',
  },
  {
    id: 3,
    label: 'sku_id',
    title: 'SKU Id',
    className: 'print_display_none',
  },
  {
    id: 4,
    label: 'sku_name',
    title: 'SKU Name',
  },
  {
    id: 5,
    label: 'quantity',
    title: 'Quantity(pcs)',
    className:'text-right',
  },
  {
    id: 6,
    label: 'amount',
    title: 'Amount',
    className:'text-right',
  },
];

const pickListTableBody = ({ dataList = [],remainingLines=[],footer=false ,columnTotal={}}) => {
  return (
    <>
    <tbody>
      {dataList.map((data) => (
        <tr>
          <td className='left-align business'>{data.businessName}</td>
          <td className='left-align brand'>{data.brand}</td>
          <td className='left-align print_display_none'>{data.skuId}</td>
          <td className='left-align sku'>{data.skuName}</td>
          <td className='right-align qty'>{data.qtyPcs}</td>
          <td className='right-align total'>{fixedFloatAndCommas(data.total)}</td>
        </tr>
      ))}
      {footer && 
      <tr className='total-print'>
        <td className='left-align total font-bold' colSpan="3">Total</td>
        <td className='right-align quantity'>{columnTotal?.quantity}</td>
      <td className='right-align total'>{fixedFloatAndCommas((columnTotal?.amount))}</td>
      </tr>
      }
      {remainingLines && remainingLines.length
        ? remainingLines.map((i) => (
          <tr className='blank-tr'>
            <td></td>
            <td colSpan ='11' className=''></td>
          </tr>
        ))
        : null}
    </tbody>

        </>
  );

};

pickListTableBody.propTypes = { data: PropTypes.object.isRequired };
const title = 'Pick List';
const lengthyAttributes = ['skuName','businessName','brand'];
const printEnums = {
  withoutFooterLimit: 55,
  withFooterLimit: 55,
  skuName: 54,
  brand: 23,
  businessName: 23,
  lengthyAttributes,
};

const getTotalDetails = (data = {}) => {
  const totalDetails = [
    {
      id: 1,
      label: 'total',
      title: 'Total',
      className: 'total',
      sortable: false,
    },
    {
      id: 2,
      label: 'brand',
      title: '',
      className: '',
      sortable: false,
    },
    {
      id: 3,
      label: 'skuId',
      title: '',
      className: '',
      sortable: false,
    },
    {
      id: 4,
      label: 'sku',
      title: '',
      className: '',
      sortable: false,
    },
    {
      id: 5,
      label: 'quantity',
      title: 'Quantity',
      className: 'quantity text-right',
      classes:'text-right',
      sortable: false,
      value:fixedFloatAndCommas(data?.quantity) || 0,
    },
    {
      id: 6,
      label: 'amount',
      title: 'Amount',
      className: 'amount text-right',
      sortable: false,
      classes:'text-right',
      value:fixedFloatAndCommas(data?.amount) || 0,
    },
  ]
  return totalDetails;
  }

const config = {
  title,
  header: pickListConfig,
  getTableBody: pickListTableBody,
  printEnums,
  uniqueIdGetter: (i) => `${i?.skuId}_${i?.businessId}_${i?.qtyPcs}`,
  getTotalDetails,
};

export default config;
