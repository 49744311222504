import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Switch from '../../../../components/Switch';
import HorizontalLabelValueBar from '../../../common/horizontalLabellValueBar';

const propTypes = { data: PropTypes.instanceOf(Array), };

const defaultProps = { data: {}, };

const UserGroupChildView = ({ data, handleInputChange, childrenExist, parentIndex }) => {
  return (
    <Fragment>
      {data && childrenExist ? (
        <>
          {data.map((subdata, i) => (
            <Row
              key={i}
              data={subdata}
              handleInputChange={handleInputChange}
              childIndex={i}
              parentIndex={parentIndex}
            />
          ))}
        </>
      ) : (
        <span />
      )}
    </Fragment>
  );
};

const Row = ({ data, handleInputChange, parentIndex, childIndex }) => (
  <tr>
    <td>{data.name}</td>
    <td className='padding-l-18'>
      {data.length > 0 || (
        <div className='form-switchs'>
          <HorizontalLabelValueBar
            value={
              <Switch
                onToggle={(checked, event) => handleInputChange('status', checked, data, parentIndex, childIndex)}
                checked={data.status}
                id={`switch-${data.id}`}
              />
            }
          />
        </div>
      )}
    </td>
  </tr>
);

UserGroupChildView.propTypes = propTypes;

UserGroupChildView.defaultProps = defaultProps;

export default UserGroupChildView;
