import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button, Fab, Icon,
} from '../../../../components/BillingMDC';
import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import BillingAsyncSelect from '../../../../components/AsyncSelect';
import BatchDialog from "../../../fundamentals/grn/create/BatchDialog";

const LedgerDialog = ({
  createModalOpen,
  editModalOpen,
  deleteModalOpen,
  createSKU,
  skuList,
  onChange,
  formEmptyField,
  batchList,
  brandList,
  closeModal,
  handleModalOkClick,
  stockQuantity,
  loadOptions,
  brandSKUList,
  keyValue,
  batchDetails,
  onBatchInputChange,
  onBatchFormSubmit,
  onBatchFormCancel,
  batchFormOpen,
  batchDialogValidation,
  onBatchFabClick,
}) => (
  <Dialog
    open={createModalOpen || editModalOpen || deleteModalOpen}
    onClose={() => {
      closeModal();
    }}
    className='stock-journal-dialog'
  >
    <DialogHeader>
      {createModalOpen && <DialogTitle>Create Stock Journal</DialogTitle>}
      {editModalOpen && <DialogTitle>Edit Stock Journal</DialogTitle>}
      {deleteModalOpen && <DialogTitle>Delete Stock Journal</DialogTitle>}
    </DialogHeader>
    {!deleteModalOpen && (
      <DialogBody>
        <div className='dialog-upperpart'>
          <div className='row_one row'>
            <Grid className='grid-padding'>
              <Cell col={6} tablet={8}>
                <label>Brand</label>
                <BillingSelect
                  name='form-field-name'
                  param='brand'
                  clearable={true}
                  value={createSKU.brand}
                  valueKey='businessCatalogDetailId'
                  valueKey1='businessId'
                  keyValue={keyValue}
                  extraValueKey
                  labelKey='title'
                  options={brandList}
                  onChange={(e) => onChange('brand', e === null ? '' : e.businessCatalogDetailId, e)}
                />
              </Cell>
              <Cell col={8} tablet={8}>
                <label>SKU</label>
                <BillingAsyncSelect
                  param='sku_id'
                  placeholder='Search/Select...'
                  loadOptions={loadOptions}
                  handleChange={onChange}
                  options={skuList}
                  defaultOptions={brandSKUList}
                  getOptionLabel={({ title }) => title}
                  getOptionValue={({ businessSkuId }) => businessSkuId}
                  multipleParam
                  required
                  emptyField={formEmptyField}
                  value={skuList.filter(({ businessSkuId }) => businessSkuId === createSKU.sku_id)}
                  valueKey='businessSkuId'
                />
              </Cell>
              { !batchFormOpen ? (
                  <>
                    <Cell col={4} tablet={8}>
                      <label>Batch</label>
                      <div className='form-wrap'>
                        <BillingSelect
                            name='form-field-name'
                            param='batch_id'
                            clearable={false}
                            value={createSKU.batch_id}
                            valueKey='businessBatchId'
                            labelKey='title'
                            options={batchList}
                            handleChange={onChange}
                            multipleParam
                            required
                            emptyField={formEmptyField}
                        />
                        <span className='mini-fab-button'>
                    <Fab
                        mini
                        onClick={() => onBatchFabClick({ createModalOpen: true })}
                        disabled={!createSKU.sku_id}
                        className={`${!createSKU.sku_id ? 'disabled' : ''}`}
                    >
                    <Icon name='add' />
                    </Fab>
                  </span>
                      </div>
                    </Cell>
                  </>
              ): null}
            </Grid>
          </div>

          { !batchFormOpen ? (
              <>
                <div className='row_two row'>
                  <label htmlFor=''>Quantity(Pcs)</label>
                  <Grid className='grid-padding'>
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                          value={createSKU.normal}
                          handleChange={onChange}
                          floatingLabel='Sellable'
                          param='normal'
                          required={false}
                          zeroError={false}
                          type='number'
                          rule='isInt'
                          className='billing-required right-side-gap'
                      />
                      <span>{`Available Quantity: ${stockQuantity.normal}`}</span>
                    </Cell>
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                          value={createSKU.damage}
                          handleChange={onChange}
                          floatingLabel='Damage '
                          param='damage'
                          required={false}
                          zeroError={false}
                          type='number'
                          rule='isInt'
                          className='billing-required right-side-gap'
                      />
                      <span>{`Damage Stock: ${stockQuantity.damage}`}</span>
                    </Cell>
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                          value={createSKU.expiry}
                          handleChange={onChange}
                          floatingLabel='Expiry '
                          param='expiry'
                          required={false}
                          zeroError={false}
                          type='number'
                          rule='isInt'
                          className='billing-required right-side-gap'
                      />
                      <span>{`Expiry Stock: ${stockQuantity.expiry}`}</span>
                    </Cell>
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                          value={createSKU.shortage}
                          handleChange={onChange}
                          floatingLabel='Shortage'
                          param='shortage'
                          required={false}
                          zeroError={false}
                          type='number'
                          className='billing-required right-side-gap'
                      />
                      <span>{`Shortage Stock: ${stockQuantity.shortage}`}</span>
                    </Cell>
                    <Cell col={4} tablet={8} className='display_none'>
                      <BillingTextField
                          value={createSKU.quantity}
                          handleChange={onChange}
                          floatingLabel='Quantity (pcs)'
                          param='quantity'
                          required
                          zeroError
                          type='number'
                          rule='isInt'
                          className='billing-required right-side-gap'
                          emptyField={formEmptyField}
                          errorMessage={createSKU.quantity === 0 ? 'Should not be 0' : 'Should not be empty'}
                      />
                    </Cell>
                  </Grid>
                </div>
                <div className='row_three row'>
                  <Grid className='grid-padding'>
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                          value={createSKU.remarks}
                          param='remarks'
                          floatingLabel='Remarks'
                          required
                          className='billing-required'
                          emptyField={formEmptyField}
                          handleChange={onChange}
                      />
                    </Cell>
                  </Grid>
                </div>
              </>
          ): null }
          {batchFormOpen ? (
              <div className='row row_one'>
                <Grid className='grid-padding width-100'>
                  <Cell col={12} tablet={8}>
                    <BatchDialog
                        batch={batchDetails}
                        batchDialogValidation={!batchDialogValidation}
                        onInputChange={onBatchInputChange}
                        onFormCancel={onBatchFormCancel}
                        onFormSubmit={onBatchFormSubmit}
                        batchFormOpen={batchFormOpen}
                    />
                  </Cell>
                </Grid>
              </div>
          ) : null}
        </div>
      </DialogBody>
    )}

    {deleteModalOpen && <div className='default-margin-24'>Are you sure you want to delete ?</div>}

    <DialogFooter>
      <Button
        accent
        className='dialog-cancel modal-btn'
        onClick={() => {
          if (batchFormOpen) {
            onBatchFormCancel();
          } else closeModal();
        }}
      >
        Cancel
      </Button>
      <Button
        accent
        className='dialog-ok modal-btn'
        onClick={() => {
          if (batchFormOpen) {
            onBatchFormSubmit();
          } else handleModalOkClick();
        }}
      >
        Ok
      </Button>
    </DialogFooter>
  </Dialog>
);

export default LedgerDialog;
