import React, { Component } from 'react';
import * as httpUtils from '../../../utils/httpUtils';
import TableHeader from '../../../components/TableHeader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as appConfig from '../../config';

import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  FormField,
  Checkbox,
  Snackbar,
} from 'react-mdc-web';
import Select from 'react-select';
import history from '../../../utils/history';
import RadioButtonList from '../../../components/RadioButtonList/RadioButtonList';
import serverCall from '../../../utils/filterPromise';
import * as appConstants from '../../config';
import { createSalesInvoiceConfig, CreateSalesInvoiceTableBody } from './CreateSalesInvoiceConfig';
import * as filterUtil from '../../../utils/filterUtil';
import BillingTextField from '../../../components/TextField/BillingTextField';
import BillingSelect from '../../../components/Select/BillingSelect';
import InvoiceDetails from '../SalesInvoice/SalesInvoiceDetail';
import { compareList } from '../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { GRN_CREATE, SALES_INVOICE_BASE } from '../../../data/enums/Route';

// const businessUnitId = JSON.parse(localStorage.getItem('business_id'));
// const businessUnitId = 1;

const discountOptions = [
  { value: 'per', label: 'in %' },
  { value: 'rs', label: 'in Rs' },
];
const validCashInvoiceAmount = 50000;

const message = {
  success: 'Sales Invoice created successfully.',
  error: '!Error while creating Sales Invoice',
  panNoRequiredError: 'PAN number required!',
  cashBillError: `CASH bill cannot exceed ${validCashInvoiceAmount.toLocaleString()}`,
  invoiceListEmptyError: 'Invoice list is empty!',
};

const pageValidateFieldTypes = {
  cash: [],
  genuineCustomer: ['name', 'panNo'],
  customer: ['name'],
};

const customerType = {
  CUSTOMER: 'CUSTOMER',
  CASH: 'CASH',
};

const cashLedgerId = 105;

let salesId = '';
let firstCopy = false;

// inject customer ref id , and invoice_number in each of the details.

class CreateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalOpen: false,
      editModalOpen: false,
      formEmptyField: false,
      pageEmptyField: false,
      deleteModalOpen: false,
      indexInInvoiceList: 0,
      showMessage: false,
      success: true,
      message: message.success,
      cashDiscountCheckBoxFlag: false,
      tradeDiscountCheckBoxFlag: false,
      directToPrintFlag: false,
      businessUnitId: '',
      createSKU: {
        brand: '',
        sku: '',
        sales_id: 0,
        business_id: '',
        order_id: 0,
        sku_id: 0,
        skuTitle: '',
        rate: 0,
        quantity: 0,
        batch: '',
        amount: 0,
        promotion_title: '',
        promotion_discount: 0,
        bill_discount: 0,
        trade_discount: 0,
        net_amount: 0,
        vat: 0,
        promotion_discount_id: 0,
      },
      customerDetails: {
        id: '',
        customerId: '',
        name: '',
        address: '',
        panNo: '',
      },
      billSummary: {
        invoiceGrossValue: 0,
        cashDiscountParam: discountOptions[1].value,
        cashDiscount: 0,
        cashDiscountValue: 0,
        tradeDiscountParam: discountOptions[0].value,
        tradeDiscount: 0,
        tradeDiscountValue: 0,
        promotionDiscountValue: 0,
        vatAmount: 0,
        invoiceNetValue: 0,
        amount: 0,
        billAmount: 0,
        remarks: '',
      },
      date: {
        start: filterUtil.getStartOfCurrentMonth(),
        end: filterUtil.getCurrentDay(),
      },
      formValidateFieldArray: ['brand', 'sku_id', 'quantity'],
      pageValidateFieldArray: pageValidateFieldTypes.customer,
      invoiceList: [],
      customerList: [],
      brandList: [],
      skuList: [],
      buList: [],
      startPrint: false,
      promotionList: [],
      promotionByCriteria: [],
      printDetails: {
        transaction: 'SALES',
        invoiceNumber: '',
        printedBy: '',
      },
      cashStatus: false,
    };
  }

  // promotionList specifies all the promotion for given sku
  // promotionByCriteria specifies the promotion for given criteria.

  // return net value based upon parameters passed
  getDiscountedValue = (grossValue, parameter, parameterValue) => {
    let discountedValue = 0;
    switch (parameter) {
      case 'rs':
        discountedValue = parameterValue;
        break;
      case 'per':
        discountedValue = (parameterValue / 100) * grossValue;
        break;
      default:
        break;
    }

    return discountedValue;
  };

  // handle when invoice item number changes or updates.
  handleInvoiceItemUpdate = () => {
    // set the invoice total
    const { invoiceList } = this.state;
    // update the sales summary
    let sales = 0;
    let promotionValue = 0;
    let billAmount = 0;
    invoiceList.map(element => (sales += Number(element.net_amount)));
    invoiceList.map(element => (billAmount += Number(element.amount)));
    invoiceList.map(element => (promotionValue += Number(element.promotion_discount)));
    // set sales invoice Summary.
    const { billSummary } = this.state;
    billSummary.invoiceGrossValue = sales.toFixed(2);
    billSummary.amount = sales;
    billSummary.promotionDiscountValue = promotionValue;
    billSummary.billAmount = billAmount;
    this.setState({ billSummary: billSummary }, () => {
      this.setSalesInvoiceSummary();
    });
    // update the sales Invoice Summary
    this.setSalesInvoiceSummary();
  };

  // set the sales invoice summary
  setSalesInvoiceSummary = () => {
    // check the cash discount parameters
    const { billSummary, cashStatus } = this.state;
    const cashDiscountValue = Number(
      this.getDiscountedValue(billSummary.invoiceGrossValue, billSummary.cashDiscountParam, billSummary.cashDiscount),
    ).toFixed(2);
    billSummary.cashDiscountValue = cashDiscountValue;
    const tradeDiscountValue = Number(
      this.getDiscountedValue(billSummary.invoiceGrossValue, billSummary.tradeDiscountParam, billSummary.tradeDiscount),
    ).toFixed(2);
    billSummary.tradeDiscountValue = tradeDiscountValue;

    // to handle discountOption value selection changes
    if (
      cashDiscountValue === '0.00' &&
      tradeDiscountValue === '0.00' &&
      billSummary.amount !== billSummary.invoiceGrossValue
    ) {
      billSummary.amount = billSummary.invoiceGrossValue;
    }

    let invoiceWithoutVat = Number(billSummary.invoiceGrossValue - cashDiscountValue - tradeDiscountValue);
    // to handle negative amount and vat
    if (invoiceWithoutVat < 0) {
      invoiceWithoutVat = billSummary.invoiceGrossValue;
      billSummary.cashDiscountValue = 0;
      billSummary.cashDiscount = 0;
      billSummary.tradeDiscountValue = 0;
      billSummary.tradeDiscount = 0;
      billSummary.amount = invoiceWithoutVat;
    }
    billSummary.vatAmount = Number((13 / 100) * invoiceWithoutVat).toFixed(2);
    billSummary.invoiceNetValue = (Number(invoiceWithoutVat) + Number(billSummary.vatAmount)).toFixed(2);
    billSummary.amount = Number(invoiceWithoutVat).toFixed(2);

    // set the bill summary
    this.setState({ billSummary });

    // check the cash discount parameters
    /* const {billSummary} = this.state;
    const cashDiscountValue = Number(this.getDiscountedValue(billSummary.invoiceGrossValue, billSummary.cashDiscountParam, billSummary.cashDiscount)).toFixed(2);
    billSummary.cashDiscountValue = cashDiscountValue;
    const tradeDiscountValue = Number(this.getDiscountedValue(billSummary.invoiceGrossValue, billSummary.tradeDiscountParam, billSummary.tradeDiscount)).toFixed(2);
    billSummary.tradeDiscountValue = tradeDiscountValue;
    const invoiceWithoutVat = billSummary.invoiceGrossValue - cashDiscountValue - tradeDiscountValue;
    billSummary.vatAmount = Number((13 / 100 * invoiceWithoutVat)).toFixed(2);
    billSummary.invoiceNetValue = (Number(invoiceWithoutVat) + Number(billSummary.vatAmount)).toFixed(2);
    billSummary.invoiceGrossValue = invoiceGrossValue;
    //set the bill summary
    this.setState({billSummary: billSummary});*/
  };

  /** adjust table width and height according to screen **/
  componentDidMount() {
    // this.getDetail();

    this.getBUList();
    // this.getCustomerList();
    // this.getBrandList();
  }

  // get the valid promotion List.
  getPromotionListBySKU(bu_id, business_sku_id) {
    // to do , read the given parameters;
    const object = {
      business_sku_id: business_sku_id,
      business_id: this.state.businessUnitId,
    };
    httpUtils.post(appConfig.baseUrl + 'promotion/validList', object).then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        this.setState({ promotionList: response.data }, () => this.setPromotionListByCriteria());
      }
      // alert("sales invoice created");
    });
  }

  // get promotionList By Criteria
  setPromotionListByCriteria() {
    // visit through all the promotion List.
    // get quantity and Rate.
    const quantity = this.state.createSKU.quantity;
    const rate = this.state.createSKU.rate;
    const { promotionList } = this.state;
    const promotionByCriteria = promotionList.filter(
      promotion =>
        (promotion.promotionCriteriaType === 'QTY' && Number(quantity) >= Number(promotion.promotionCriteriaValue)) ||
        (promotion.promotionCriteriaType === 'AMT' &&
          Number(quantity) * Number(rate) >= Number(promotion.promotionCriteriaValue)),
    );
    this.setState({ promotionByCriteria: promotionByCriteria });
  }

  // reset the promotion Details
  resetPromotionDetails() {
    const { createSKU } = this.state;
    createSKU.promotion_discount_id = null;
    createSKU.promotion_discount = 0;
    createSKU.net_amount = 0;
    const d = this.state.promotionByCriteria;
    this.setState({ createSKU: createSKU, promotionList: [], promotionByCriteria: [] });
  }

  // on selection of promotion , calculate the promotion discount and net amount
  onPromotionSelect = (field, promotionId, promotionDetails) => {
    const { createSKU } = this.state;
    const quantity = Number(createSKU.quantity);
    const rate = Number(createSKU.rate);
    const promotionRuleValue = Number(promotionDetails.promotionRuleValue);
    createSKU.promotion_title = promotionDetails.promotionTitle;
    createSKU.promotion_discount_id = promotionDetails.businessPromotionId;
    // get according to the quantity rate or amount selected.
    if (promotionDetails.promotionRuleType == 'QTY') {
      // todo handle in future : 'buy 20 Rs and get 3 free -> Buy 40 Rs get 6 free.'
      createSKU.promotion_discount = promotionRuleValue.toFixed(2);
      createSKU.net_amount = (quantity * rate - createSKU.promotion_discount).toFixed(2);
    }
    if (promotionDetails.promotionRuleType == 'PER') {
      createSKU.promotion_discount = ((promotionRuleValue / 100) * quantity * rate).toFixed(2);
      createSKU.net_amount = (quantity * rate - createSKU.promotion_discount).toFixed(2);
    }
    this.setState({ createSKU: createSKU });
  };
  // handle change of create sku events
  onChange = (field, value, all = {}) => {
    const { createSKU, businessUnitId } = this.state;
    createSKU[field] = value;
    // set the title if title is configured.
    if (field == 'sku_id') {
      createSKU.skuTitle = all['title'] || 'title';
      createSKU.rate = all['rate'] || 0;
    }
    // update the amount with rate and amount.
    createSKU.amount = Number(createSKU.quantity * createSKU.rate).toFixed(2);
    const discount = Number(createSKU.promotion_discount) + Number(createSKU.trade_discount);
    createSKU.net_amount = createSKU.amount - discount;
    this.setState({ createSKU: createSKU }, () => {
      // set the brand if brand is not configured.
      if (field == 'brand') {
        this.getSKUListUnderBrand(value);
        // rest the promotion_id , promotionList , promotionCategoryList.
        this.resetPromotionDetails();
      }
      if (field == 'sku_id') {
        createSKU.promotion_discount_id = null;
        createSKU.promotion_discount = 0;
        // reset the amount to previous values.
        createSKU.net_amount = createSKU.amount;
        this.setState({ createSKU, promotionList: [], promotionByCriteria: [] }, () => {
          this.getPromotionListBySKU(businessUnitId, createSKU.sku_id);
        });
        // if sku is selected , get the valid promotion list for the given sku:
      }
      // if quantity changes, validate on the existing promotion Criteria.
      if (field == 'quantity') {
        createSKU.promotion_discount_id = null;
        createSKU.promotion_discount = 0;
        // reset the amount to previous values.
        createSKU.net_amount = createSKU.amount;
        this.setState({ createSKU });
        this.setPromotionListByCriteria();
      }
    });
  };

  // entire promotionList for the sku.
  // the promotion criteria list to be handled
  // on change of the bill summary & update bill summary details.
  onChangeBillSummary = (field, value) => {
    const { billSummary } = this.state;
    billSummary[field] = value;
    if (field === 'cashDiscountParam') {
      billSummary['cashDiscount'] = 0;
    }
    if (field === 'tradeDiscountParam') {
      billSummary['tradeDiscount'] = 0;
    }
    this.setState({ billSummary }, () => {
      this.setSalesInvoiceSummary();
    });
  };

  // creation of the new invoice
  // update the invoice grossValue
  createSalesInvoice = () => {
    const { invoiceList, createSKU } = this.state;
    // calculate the price
    createSKU.price = createSKU.quantity * createSKU.rate - createSKU.discount;
    invoiceList.push(createSKU);
    this.setState({ invoiceList }, () => {
      this.closeModal();
      // handle invoice item update
      this.handleInvoiceItemUpdate();
    });
  };

  // editSalesInvoice
  editSalesInvoice = (data, index) => {
    const { invoiceList, createSKU } = this.state;
    invoiceList[index] = createSKU;
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
      // handle invoice
      this.handleInvoiceItemUpdate();
    });
  };

  // close the modal resetting  all the value
  closeModal = () => {
    this.resetSKUDialog();
    this.setState({ modalOpen: false, editModalOpen: false, deleteModalOpen: false });
  };

  // reset SKU Dialog
  resetSKUDialog = () => {
    const createSKU = {
      brand: '',
      business_id: '',
      sku: '',
      sales_id: 0,
      order_id: 0,
      sku_id: 0,
      skuTitle: '',
      rate: 0,
      quantity: 0,
      batch: '',
      amount: 0,
      promotion_discount_id: 0,
      promotion_discount: 0,
      promotion_title: '',
      bill_discount: 0,
      trade_discount: 0,
      net_amount: 0,
      vat: 0,
    };
    const skuList = [];
    this.setState({ createSKU: createSKU, formEmptyField: false, skuList: skuList, promotionByCriteria: [] });
  };

  // delete the sales invoice
  deleteSalesInvoice = () => {
    const { invoiceList, indexInInvoiceList } = this.state;
    invoiceList.splice(indexInInvoiceList, 1);
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
      this.handleInvoiceItemUpdate();
    });
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data, index) => {
    const { businessUnitId } = this.state;
    this.getSKUListUnderBrand(data.brand);
    this.setState({ editModalOpen: true, indexInInvoiceList: index });
    const createSKU = {
      brand: data.brand,
      sku: data.sku,
      skuTitle: data.skuTitle,
      sales_id: 0,
      order_id: 0,
      business_id: businessUnitId,
      sku_id: data.sku_id,
      rate: data.rate,
      quantity: data.quantity,
      batch: data.batch,
      amount: data.amount,
      promotion_discount: data.promotion_discount,
      promotion_discount_id: data.promotion_discount_id,
      promotion_title: data.promotion_title,
      bill_discount: data.bill_discount,
      trade_discount: data.trade_discount,
      net_amount: data.net_amount,
      vat: data.vat,
    };
    this.setState({ createSKU: createSKU });
  };

  // handle delete icon click.
  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInInvoiceList: index });
  };

  // handle cancel button click
  handleCancelClick = () => {
    history.push(`/${SALES_INVOICE_BASE}`);
  };

  handleSaveClick = () => {
    const valid = this.handleInvoiceValidation();
    if (valid) {
      this.setSalesInvoice();
    }
  };

  handleInvoiceValidation = () => {
    const { customerDetails, invoiceList, cashStatus, pageValidateFieldArray } = this.state;
    let validationFields = pageValidateFieldArray;
    const checkForValidCashParty = validCashInvoiceAmount >= this.state.billSummary.invoiceNetValue;

    /* todo: need to handle  displaying error message from common section */

    if (invoiceList.length < 1) {
      this.setState({
        showMessage: true,
        success: false,
        message: message.invoiceListEmptyError,
        directToPrintFlag: false,
      });

      return false;
    }

    if (!checkForValidCashParty) {
      if (!cashStatus && !customerDetails.panNo) {
        this.setState({
          showMessage: true,
          success: false,
          message: message.panNoRequiredError,
          directToPrintFlag: false,
        });

        return false;
      } else if (cashStatus) {
        this.setState({
          showMessage: true,
          success: false,
          message: message.cashBillError,
          directToPrintFlag: false,
        });

        return false;
      } else {
        return this.validateForm(customerDetails, validationFields, 'pageEmptyField');
      }
    } else {
      validationFields = cashStatus ? pageValidateFieldTypes.cash : pageValidateFieldTypes.customer;
      this.updatePageValidateFields(validationFields);

      return this.validateForm(customerDetails, validationFields, 'pageEmptyField');
    }
  };

  // on okay click for the modal
  handleModalOkClick = () => {
    const { modalOpen, editModalOpen, deleteModalOpen, createSKU, formEmptyField, formValidateFieldArray } = this.state;
    if (modalOpen || editModalOpen) {
      const valid = this.validateForm(createSKU, formValidateFieldArray, 'formEmptyField');
      if (valid) {
        if (modalOpen) {
          this.createSalesInvoice();
        } else {
          this.editSalesInvoice(this.state.createSKU, this.state.indexInInvoiceList);
        }
      }
    } else {
      this.deleteSalesInvoice();
    }
  };

  validateForm = (formObject, config, flag) => {
    const data = Object.keys(formObject).some(k => {
      if (config.indexOf(k) > -1) {
        let value = formObject[k];
        if (!value) {
          this.setState({ [flag]: true });

          return true;
        }
      }
    });

    return !data;
  };

  handleCheckBoxClick = (fieldName, flag) => {
    this.setState({ [fieldName]: flag });
    const { billSummary } = this.state;
    // if the flag is set off reset value to 0 and disable
    if (fieldName === 'cashDiscountCheckBoxFlag') {
      billSummary.cashDiscountValue = 0;
      billSummary.cashDiscount = 0;
      billSummary.cashDiscountParam = discountOptions[1].value;
    } else if (fieldName === 'tradeDiscountCheckBoxFlag') {
      billSummary.tradeDiscountValue = 0;
      billSummary.tradeDiscount = 0;
      billSummary.tradeDiscountParam = discountOptions[0].value;
    }
    this.setState({ billSummary: billSummary }, () => this.setSalesInvoiceSummary());
  };

  handleCashCheckClick = flag => {
    const { pageValidateFieldArray, customerDetails } = this.state;
    const validationType = flag ? pageValidateFieldTypes.cash : pageValidateFieldArray;
    customerDetails.customerId = flag ? cashLedgerId : '';
    customerDetails.address = '';
    customerDetails.panNo = '';
    customerDetails.id = '';
    customerDetails.name = '';
    this.setState({ cashStatus: flag, customerDetails }, () => this.updatePageValidateFields(validationType));
  };

  handleBUClick = id => {
    this.setState({ businessUnitId: id }, () => {
      this.getCustomerList();
      this.getBrandList();
    });
  };

  updatePageValidateFields = list => {
    const { pageValidateFieldArray } = this.state;
    if (!compareList(list, pageValidateFieldArray)) this.setState({ pageValidateFieldArray: list });
  };

  // get the customer list for the selection.
  getCustomerList = () => {
    this.setState({ loading: true });
    // const type = 'cash';
    httpUtils.get(appConfig.baseUrl + 'ledger/customer-list?type=').then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        let responseDataList = response.data;
        responseDataList.map(customer => {
          customer['label'] = customer.customerId + '. ' + customer.name;
        });
        this.setState({ customerList: responseDataList });
      }
    });
  };

  // get the brand list
  getBrandList = () => {
    const { businessUnitId } = this.state;
    httpUtils.get(appConfig.baseUrl + 'brand/list?bu_id=' + businessUnitId).then(response => {
      if (response.success) {
        this.setState({ brandList: response.data.brandList });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = brandId => {
    const { businessUnitId } = this.state;
    // const brandId = this.state.createSKU.brand;
    // bu_id=1&bu_brand_id=3
    httpUtils.get(appConfig.baseUrl + 'sku-list?bu_id=' + businessUnitId + '&bu_brand_id=' + brandId).then(response => {
      if (response.success) {
        this.setState({ skuList: response.data });
      }
    });
  };

  // get BU list
  getBUList = () => {
    httpUtils.get(appConfig.baseUrl + 'upload/business-company').then(response => {
      if (response.success) {
        this.setState({ buList: response.data.list });
      }
    });
  };
  // handle the customer drop down change
  onCustomerDropDownChange = (field, id, customer) => {
    const customerDetails = {
      id: customer.idLedger || '',
      customerId: customer.customerId || '',
      name: customer.name || '',
      address: customer.address || '',
      panNo: customer.panNo || '',
    };
    this.setState({ customerDetails });
  };

  directToPrint = () => {
    const { showMessage, success } = this.state;
    if (!showMessage && success) {
      this.setState({ startPrint: true });
      // history.push(`/sales-invoice-details/${salesId}/${firstCopy}`)
    }
  };

  afterPrint = userId => {
    const { printDetails } = this.state;
    printDetails.printedBy = userId;
    const self = this;
    window.onafterprint = function () {
      // printCount++;
      self.setState({ startPrint: false });
      httpUtils
        .post(appConfig.baseUrl + 'print', printDetails)
        .then(response => {
          if (response.success) {
            history.push(`/${SALES_INVOICE_BASE}`);
          }
        })
        .catch(error => {
          {
            console.log('error', error);
          }
        });
    };
  };

  getInvoiceList = () => {
    return this.state.invoiceList;
  };

  // set the sales invoice calling to the server. //also set custome ref id
  setSalesInvoice = () => {
    const { billSummary, customerDetails, businessUnitId, printDetails, cashStatus } = this.state;
    const { user } = this.props;
    const invoiceList = this.getInvoiceList();
    const filteredInvoiceList = invoiceList.map(skuDetails => {
      delete skuDetails['sku'];
      delete skuDetails['skuTitle'];
      delete skuDetails['brand'];
      // delete skuDetails['rate'];
      delete skuDetails['price'];
      delete skuDetails['promotion_title'];
      skuDetails['customer_ref_id'] = customerDetails.customerId;

      return skuDetails;
    });
    const objectDetail = {
      business_id: businessUnitId,
      customer_ref_id: customerDetails.customerId,
      entered_by: user.idUsers,
      amount: billSummary.billAmount,
      taxable_amount: billSummary.amount,
      bill_discount: billSummary.cashDiscountValue,
      trade_discount: billSummary.tradeDiscountValue,
      promotion_discount: billSummary.promotionDiscountValue,
      vat: billSummary.vatAmount,
      remarks: billSummary.remarks,
      net_amount: billSummary.invoiceNetValue,
      skuDetail: filteredInvoiceList,
      customer_type: cashStatus ? customerType.CASH : customerType.CUSTOMER,
    };
    // const firstCopy = true;
    this.setState({ loading: true });
    httpUtils
      .post(appConfig.baseUrl + 'sales-invoice', objectDetail)
      .then(response => {
        this.setState({ loading: false });
        // todo direct to print page.
        if (response.success) {
          printDetails.invoiceNumber = response.data.detail.invoice_number;
          salesId = response.data.detail.sales_id;
          firstCopy = true;

          this.setState({
            showMessage: true,
            success: true,
            message: message.success,
            printDetails: printDetails,
            directToPrintFlag: true,
          });

          /* this.setState({showMessage: true, success:true, message:message.success })
        salesId = response.data.detail.sales_id;
        firstCopy = true;*/
          // alert('sales invoice created');
        }
        // history.push(`/sales-invoice-details/${salesId}/${firstCopy}`);
        // alert("sales invoice created");
      })
      .catch(error => {
        {
          this.setState({
            loading: false,
            showMessage: true,
            success: false,
            message: message.error,
            directToPrintFlag: false,
          });
        }
      });
  };

  render() {
    const {
      loading,
      invoiceList,
      modalOpen,
      cashStatus,
      editModalOpen,
      deleteModalOpen,
      cashDiscountCheckBoxFlag,
      tradeDiscountCheckBoxFlag,
      showMessage,
      customerDetails,
      buList,
    } = this.state;

    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        {!this.state.startPrint && (
          <div className={loading ? 'clickable-false' : ''}>
            <div className="message-snackbar">
              {
                <Snackbar
                  className={this.state.success ? 'success-message' : ''}
                  timeout={this.state.success ? 1000 : 4000}
                  onTimeout={() => {
                    this.setState({ showMessage: false }, () => {
                      if (this.state.directToPrintFlag) {
                        this.directToPrint();
                      }
                    });
                  }}
                  open={showMessage}
                >
                  {this.state.message}
                </Snackbar>
              }
            </div>
            <div className="card-header-bar clearfix" ref="tableReference">
              <div className="header-left">
                <h2>New Invoice</h2>
              </div>
              <div className="header-right">{this.state.date.end}</div>
            </div>
            <div className="card-body">
              <div className="radio-list-wrapper clearfix">
                <div className="float-left">
                  {buList.length && <RadioButtonList data={buList} handleBuClick={this.handleBUClick} />}
                </div>
                <div className="float-right cash-checkbox">
                  <Checkbox
                    checked={cashStatus}
                    onChange={({ target: { checked } }) => {
                      this.handleCashCheckClick(checked);
                    }}
                  />
                  <label>Cash</label>
                </div>
              </div>
              <div className={this.state.businessUnitId ? '' : 'block-overlay'}>
                <div className="three-input-wrapper">
                  <Grid>
                    <Cell col={3} className="input-select">
                      <label>Customer Name</label>
                      <BillingSelect
                        name="form-field-name"
                        param="name"
                        clearable={false}
                        value={this.state.customerDetails.customerId}
                        valueKey="customerId"
                        options={this.state.customerList}
                        handleChange={this.onCustomerDropDownChange}
                        required
                        emptyField={this.state.pageEmptyField}
                        multipleParam={true}
                      />
                    </Cell>
                    <Cell col={3}>
                      <BillingTextField
                        value={this.state.customerDetails.address}
                        floatingLabel="Address"
                        required={false}
                        className="billing-required no-pointer-events right-side-gap"
                        emptyField={this.state.pageEmptyField}
                      />
                    </Cell>
                    <Cell col={3}>
                      <BillingTextField
                        value={this.state.customerDetails.panNo}
                        floatingLabel="PAN Number"
                        type="number"
                        rule="isInt"
                        required
                        className="billing-required no-pointer-events right-side-gap"
                      />
                    </Cell>
                    <Cell col={3} className="input-field">
                      <BillingTextField
                        value={this.state.billSummary.remarks}
                        param="remarks"
                        floatingLabel="Remarks"
                        required={false}
                        className="billing-required"
                        handleChange={(param, value) => {
                          const { billSummary } = this.state;
                          billSummary[param] = value;
                          this.setState({ billSummary });
                        }}
                      />
                    </Cell>
                  </Grid>
                </div>
                <div className="table-wrapper">
                  <div ref="fixedTableBody" className="fixed-table-wrapper">
                    <table>
                      <TableHeader
                        headerDetails={createSalesInvoiceConfig.headerDetails}
                        filterHeaderLabel={false}
                        handleSorting={this.handleTableSorting}
                      />
                      {invoiceList.map((data, key) => (
                        <tbody>
                          <CreateSalesInvoiceTableBody
                            data={data}
                            index={key}
                            deleteSalesInvoice={this.deleteSalesInvoice}
                            handleEditIconClick={this.handleEditIconClick}
                            handleDeleteIconClick={this.handleDeleteIconClick}
                          />
                        </tbody>
                      ))}
                    </table>
                  </div>

                  {/* Fab Icon*/}
                  <div className="mini-fab-button">
                    <Fab mini onClick={() => this.setState({ modalOpen: true })}>
                      <Icon name="add" />
                    </Fab>
                  </div>
                </div>
                {/* Sales invoice table summary goes over here.*/}
                <div className="total-section-wrapper">
                  <Grid>
                    <Cell col={8} />
                    <Cell col={3} className="right-align active-opacity-text">
                      {' '}
                      Sub Total
                    </Cell>
                    <Cell col={1} className="right-align">
                      {' '}
                      {this.state.billSummary.invoiceGrossValue}
                    </Cell>
                  </Grid>

                  <Grid>
                    <Cell col={3}> </Cell>
                    <Cell col={8} className="right-align">
                      <FormField id="cashDiscountCheckbox">
                        <Checkbox
                          checked={this.state.cashDiscountCheckBoxFlag}
                          onChange={({ target: { checked } }) => {
                            this.handleCheckBoxClick('cashDiscountCheckBoxFlag', checked);
                          }}
                        />
                        <label>Bill Discount</label>
                      </FormField>

                      <span className={cashDiscountCheckBoxFlag ? null : 'no-pointer-events'}>
                        <span>
                          <Select
                            className="display-inline-table"
                            name="form-field-name"
                            clearable={false}
                            value={this.state.billSummary.cashDiscountParam}
                            options={discountOptions}
                            onChange={e => this.onChangeBillSummary('cashDiscountParam', e.value)}
                          />
                        </span>
                        <span className="default-left-margin-9">
                          <BillingTextField
                            value={this.state.billSummary.cashDiscount}
                            handleChange={this.onChangeBillSummary}
                            type="number"
                            param="cashDiscount"
                            max={
                              this.state.billSummary.cashDiscountParam === 'rs'
                                ? this.state.billSummary.invoiceGrossValue
                                : '99'
                            }
                          />
                        </span>
                      </span>
                    </Cell>
                    <Cell col={1} className="right-align default-top-padding-12">
                      {fixedFloatAndCommas(this.state.billSummary.cashDiscountValue)}
                    </Cell>
                  </Grid>

                  <Grid>
                    <Cell col={3}> </Cell>
                    <Cell col={8} className="right-align">
                      <FormField id="tradeDiscountCheckBox">
                        <Checkbox
                          checked={this.state.tradeDiscountCheckBoxFlag}
                          onChange={({ target: { checked } }) => {
                            this.handleCheckBoxClick('tradeDiscountCheckBoxFlag', checked);
                          }}
                        />
                        <label>Trade Discount</label>
                      </FormField>
                      <span className={tradeDiscountCheckBoxFlag ? null : 'no-pointer-events'}>
                        <span>
                          <Select
                            className="display-inline-table"
                            name="form-field-name"
                            clearable={false}
                            value={this.state.billSummary.tradeDiscountParam}
                            options={discountOptions}
                            onChange={e => this.onChangeBillSummary('tradeDiscountParam', e.value)}
                          />
                        </span>
                        <span className="default-left-margin-9">
                          <BillingTextField
                            value={this.state.billSummary.tradeDiscount}
                            handleChange={this.onChangeBillSummary}
                            type="number"
                            param="tradeDiscount"
                            max={
                              this.state.billSummary.tradeDiscountParam === 'rs'
                                ? this.state.billSummary.invoiceGrossValue
                                : '99'
                            }
                          />
                        </span>
                      </span>
                    </Cell>
                    <Cell col={1} className="right-align default-top-padding-12">
                      {fixedFloatAndCommas(this.state.billSummary.tradeDiscountValue)}
                    </Cell>
                  </Grid>
                  <Grid className="padding-top-24">
                    <Cell col={8} />
                    <Cell col={3} className="right-align active-opacity-text">
                      {' '}
                      Taxable Amount
                    </Cell>
                    <Cell col={1} className="right-align">
                      {' '}
                      {fixedFloatAndCommas(this.state.billSummary.amount)}
                    </Cell>
                  </Grid>
                  <Grid className="default-margin-top-12">
                    <Cell col={8} />
                    <Cell col={3} className="right-align active-opacity-text">
                      {' '}
                      VAT
                    </Cell>
                    <Cell col={1} className="right-align">
                      {' '}
                      {fixedFloatAndCommas(this.state.billSummary.vatAmount)}
                    </Cell>
                  </Grid>
                  <div className="hr" />
                  <Grid>
                    <Cell col={8} />
                    <Cell col={3} className="right-align active-opacity-text">
                      Total
                    </Cell>
                    <Cell col={1} className="right-align font-bold">
                      {fixedFloatAndCommas(this.state.billSummary.invoiceNetValue)}
                    </Cell>
                  </Grid>
                </div>
              </div>
              {/* Begin of the dialog Body*/}
              <Dialog
                open={modalOpen || editModalOpen || deleteModalOpen}
                onClose={() => {
                  this.closeModal();
                }}
              >
                <DialogHeader>
                  {modalOpen && <DialogTitle>Select SKU</DialogTitle>}
                  {editModalOpen && <DialogTitle>Edit Invoice</DialogTitle>}
                  {deleteModalOpen && <DialogTitle> Delete Invoice</DialogTitle>}
                </DialogHeader>

                {(modalOpen || editModalOpen) && (
                  <DialogBody>
                    <div className="dialog-upperpart">
                      <Grid className="grid-padding">
                        <Cell col={4} tablet={8}>
                          <label>Brand</label>
                          <BillingSelect
                            name="form-field-name"
                            param="brand"
                            clearable={false}
                            value={this.state.createSKU.brand}
                            valueKey="businessCatalogDetailId"
                            labelKey="title"
                            options={this.state.brandList}
                            handleChange={this.onChange}
                            required={true}
                            emptyField={this.state.formEmptyField}
                          />
                        </Cell>

                        <Cell col={8} tablet={8}>
                          <label>SKU</label>
                          <BillingSelect
                            name="form-field-name"
                            param="sku_id"
                            clearable={false}
                            value={this.state.createSKU.sku_id}
                            valueKey="business_sku_id"
                            labelKey="title"
                            options={this.state.skuList}
                            handleChange={this.onChange}
                            required={true}
                            emptyField={this.state.formEmptyField}
                            multipleParam={true}
                          />
                        </Cell>
                      </Grid>

                      <Grid className="grid-padding">
                        <Cell col={3} tablet={8}>
                          <BillingTextField
                            value={this.state.createSKU.batch}
                            handleChange={this.onChange}
                            floatingLabel="Batch"
                            param="batch"
                            required={false}
                            className="billing-required right-side-gap"
                            /* emptyField={this.state.formEmptyField}*/
                          />
                        </Cell>

                        {/* <Cell col={3} tablet={8} className="relative-container default-margin-top-7">*/}
                        <Cell col={2} tablet={8}>
                          <BillingTextField
                            value={this.state.createSKU.quantity}
                            handleChange={this.onChange}
                            floatingLabel="Quantity (pcs)"
                            param="quantity"
                            required={true}
                            zeroError={true}
                            type="number"
                            rule="isInt"
                            className="billing-required right-side-gap"
                            emptyField={this.state.formEmptyField}
                            errorMessage={
                              this.state.createSKU.quantity === 0 ? 'Should not be 0' : 'Should not be empty'
                            }
                          />
                        </Cell>

                        <Cell col={2}>
                          <label>Rate</label>
                          <span className="accent-color dialog-detail">{this.state.createSKU.rate}</span>
                        </Cell>

                        <Cell col={2}>
                          <label>Amount</label>
                          <span className="accent-color dialog-detail">{this.state.createSKU.amount}</span>
                        </Cell>

                        <Cell col={3}>
                          <label>Discount</label>
                          <span className="accent-color dialog-detail">{this.state.createSKU.promotion_discount}</span>
                        </Cell>
                      </Grid>
                      {this.state.promotionByCriteria.length > 0 && (
                        <Grid className="grid-padding">
                          <Cell col={12} tablet={12}>
                            <label>Promotion</label>
                            <BillingSelect
                              name="form-field-name"
                              param="test"
                              clearable={false}
                              value={this.state.createSKU.promotion_discount_id}
                              valueKey="businessPromotionId"
                              labelKey="promotionTitle"
                              options={this.state.promotionByCriteria}
                              handleChange={this.onPromotionSelect}
                              required={true}
                              emptyField={this.state.formEmptyField}
                              multipleParam={true}
                            />
                          </Cell>
                        </Grid>
                      )}
                    </div>
                  </DialogBody>
                )}

                {deleteModalOpen && <div className="default-margin-24">Are you sure you want to Delete ?</div>}

                <DialogFooter>
                  <Button
                    accent
                    className="dialog-cancel modal-btn"
                    onClick={() => {
                      this.closeModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    accent
                    className="dialog-ok modal-btn"
                    onClick={() => {
                      this.handleModalOkClick();
                    }}
                  >
                    Ok
                  </Button>
                </DialogFooter>
              </Dialog>
            </div>
            <div className="newinvoice-btn-wrapper">
              <Grid>
                <Cell col={8} />
                <Cell col={4} className="right-align">
                  <Button
                    accent
                    className="cancel-btn modal-btn"
                    onClick={() => {
                      this.handleCancelClick();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    accent
                    className="save-btn modal-btn"
                    onClick={() => {
                      this.handleSaveClick();
                    }}
                  >
                    Save
                  </Button>
                </Cell>
              </Grid>
            </div>
          </div>
        )}
        {this.state.startPrint && <InvoiceDetails id={salesId} firstCopy={firstCopy} afterPrint={this.afterPrint} />}
      </div>
    );
  }
}

CreateInvoice.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
  };
};

const CreateSalesInvoice = connect(mapStateToProps)(CreateInvoice);

export default CreateSalesInvoice;
