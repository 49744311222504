import React from 'react';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const StockLedgerBody = ({ dataList, onRowClick ,remainingLines }) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr onClick={() => onRowClick(data)}>
        <td className='left-align title'><div className='title_div'><p>{data.title}</p> 
        {data?.skuStatus === false && <span className={`status-label ${data?.skuStatus ?  'active':'passive'}`}>{data?.skuStatus ?'active':'Inactive'}</span>}
        </div></td>
        <td className='left-align bu'>{data.bu && data.bu.title}</td>
        <td className='right-align ob-sellable'>
          {data.openingBalance ? data.openingBalance.toLocaleString() : data.openingBalance}
        </td>
        <td className='right-align'>
          {data.obDamage}
        </td>
        <td className='right-align'>
          {data.obExpired}
        </td>
        <td className='right-align shortage'>
          {data.obShortage}
        </td>
        <td className='right-align'>{data.inward ? data.inward.toLocaleString() : data.inward}</td>
        <td className='right-align'>{data.outward ? data.outward.toLocaleString() : data.outward}</td>
        <td className='right-align print_display_none'>{fixedFloatAndCommas(data.adjustment) || null}</td>
        <td className='right-align'>
          {data.damage}
        </td>
        <td className='right-align'>
          {data.expired}
        </td>
        <td className='right-align shortage'>
          {data.shortage}
        </td>
        <td className='right-align'>
          {data.closingBalance ? data.closingBalance.toLocaleString() : data.closingBalance}
        </td>
        <td className='right-align'>{data.closingDamage}</td>
        <td className='right-align'>{data.closingExpired}</td>
        <td className='right-align'>{data.closingShortage}</td>
      </tr>
    ))}
  </tbody>
);

const StockLedgerPrintBody = ({ dataList, onRowClick, remainingLines }) => (
  <tbody>
    {
      dataList
        .map((data, index) => (
          <tr onClick={() => onRowClick(data,index)}>
            <td>{data.index ? data.index + 1 : index + 1}</td>
            <td className='left-align title'>{data.title}</td>
            <td className='left-align bu'>{data.bu && data.bu.title}</td>
            <td
              className='right-align'
            >
              {data.openingBalance ? data.openingBalance.toLocaleString() : data.openingBalance}
            </td>
            <td className='right-align'>{data.inward ? data.inward.toLocaleString() : data.inward}</td>
            <td className='right-align'>{data.outward ? data.outward.toLocaleString() : data.outward}</td>
            <td
              className='right-align'
            >
              {data.closingBalance ? data.closingBalance.toLocaleString() : data.closingBalance}
            </td>
            <td className='right-align'>{data.closingDamage ? data.closingDamage.toLocaleString() : data.closingDamage}</td>
            <td className='right-align'>{data.closingExpired ? data.closingExpired.toLocaleString() : data.closingExpired}</td>
            <td className='right-align'>{data.closingShortage ? data.closingShortage.toLocaleString() : data.closingShortage}</td>
          </tr>

        ))
    }
    {remainingLines && remainingLines.length
      ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td colSpan ='11' className=''></td>
        </tr>
      ))
      : null}
  </tbody>
);

const stockLedgerConfig = {
  headerDetails: [
    {
      id: 1, label: 'skus.title', title: 'SKU', className: '', sortable: false,
    },
    {
      id: 2, label: 'bu.title', title: 'BU', className: '', sortable: false,
    },
    {
      id: 3, label: 'openingBalance', title: 'Sellable', className: ' right-align', sortable: false,
    },
    {
      id: 4, label: 'obDamage', title: 'Damage', className: ' right-align', sortable: false,
    },
    {
      id: 5, label: 'obExpired', title: 'Expired', className: ' right-align', sortable: false,
    },
    {
      id: 6, label: 'obShortage', title: 'Shortage', className: ' right-align', sortable: false,
    },
    {
      id: 7, label: 'inward', title: 'Inward', className: ' right-align print_display_none', sortable: false,
    },
    {
      id: 8, label: 'outward', title: 'Outward', className: ' right-align', sortable: false,
    },
    {
      id: 9, label: 'adjustment', title: 'Adjustment', className: ' right-align', sortable: false,
    },
    {
      id: 10, label: 'damage', title: 'Damage', className: ' right-align', sortable: false,
    },
    {
      id: 11, label: 'expired', title: 'Expired', className: ' right-align', sortable: false,
    },
    {
      id: 12, label: 'shortage', title: 'Shortage', className: ' right-align', sortable: false,
    },
    {
      id: 13, label: 'closing', title: 'Sellable', className: ' right-align', sortable: false,
    },
    {
      id: 14, label: 'closingDamage', title: 'Damage', className: ' right-align', sortable: false,
    },
    {
      id: 15, label: 'closingExpired', title: 'Expired', className: ' right-align', sortable: false,
    },
    {
      id: 16, label: 'closingShortage', title: 'Shortage', className: ' right-align', sortable: false,
    },
  ],
  upperHeaderDetails: [
    {
      id: 1, label: '', title: '', className: 'table-cell center-align', columns: 2, sortable: false,
    },
    {
      id: 2, label: 'openingBalance', title: 'Opening Balance', className: 'table-2-cell center-align', columns: 4, sortable: false,
    },
    {
      id: 3, label: 'periodBalance', title: 'Period Balance', className: 'table-2-cell center-align', columns: 6, sortable: false,
    },
    {
      id: 4, label: 'closingBalance', title: 'Closing Balance', className: 'table-2-cell center-align', columns: 4, sortable: false,
    },
  ],
  printHeader: [
    {
      id: 1, label: 'index', title: 'S.N', className: '', sortable: false,
    },
    {
      id: 2, label: 'skus.title', title: 'SKU', className: '', sortable: false,
    },
    {
      id: 3, label: 'bu.title', title: 'BU', className: '', sortable: false,
    },
    {
      id: 4, label: 'opening_balance', title: 'Opening', className: ' right-align', sortable: false,
    },
    {
      id: 5, label: 'inward', title: 'Inward', className: ' right-align', sortable: false,
    },
    {
      id: 6, label: 'outward', title: 'Outward', className: ' right-align', sortable: false,
    },
    {
      id: 8, label: 'closing_balance', title: 'Closing', className: ' right-align', sortable: false,
    },
    {
      id: 9, label: 'damaged_sum', title: 'Damaged', className: ' right-align', sortable: false,
    },
    {
      id: 10, label: 'expired_sum', title: 'Expired', className: ' right-align', sortable: false,
    },
    {
      id: 11, label: 'shortage', title: 'Shortage', className: ' right-align', sortable: false,
    },
  ],
};
const getData = (data, label) => {
  switch(label) {
  case 'title':return(data.title) || null;
  case 'buTitle': return (data.bu && data.bu.title) || null;
  default:
    return null;
  }
}
const lengthyAttributes = ['title', 'buTitle'];
const printEnums = {
  title: 50,
  buTitle:14,
  withoutFooterLimit: 42,
  withFooterLimit: 42,
  lengthyAttributes,
};


const config = {
  printEnums,
  header: {
    upperHeaders: [stockLedgerConfig.upperHeaderDetails],
    tableHeader: stockLedgerConfig.headerDetails,
    printHeader: stockLedgerConfig.printHeader,
  },
  getTableBody: StockLedgerBody,
  getTablePrintBody: StockLedgerPrintBody,
  multipleTableHeader: true,
  getData,
  lengthyAttributes,
  uniqueIdGetter: (i) => `${i.skuId}_${i.bu && i.bu.id}`,
};

export default config;
