import React from 'react';
import { roundAndCommas } from '../../../utils/conversion';

const replaceText = (string) => {
  if (string) {
    return string.match(/^.{0}(?:0\.|-0|0)/, '') ? null : string;
  }
  return string;
};

const StockAgeingReportBody = ({ dataList = [] }) => (
  <tbody>
    {dataList.map((data) => (
      <tr>
        <td className='left-align'>{data.sku_id}</td>
        {/* <td className='left-align'>{data.business_group}</td> */}
        <td className='left-align'>{data.title}</td>
        <td className='left-align'>{data.balance === null ? 0 : data.balance}</td>
        <td className='right-align'>{data.slab1 === null ? 0 : replaceText(roundAndCommas(data.slab1))}</td>
        <td className='right-align'>{data.slab2 === null ? 0 : replaceText(roundAndCommas(data.slab2))}</td>
        <td className='right-align'>{data.slab3 === null ? 0 : replaceText(roundAndCommas(data.slab3))}</td>
        <td className='right-align'>{data.slab4 === null ? 0 : replaceText(roundAndCommas(data.slab4))}</td>
        <td className='right-align'>{data.slab5 === null ? 0 : replaceText(roundAndCommas(data.slab5))}</td>
      </tr>
    ))}
  </tbody>
);

const TOTAL_SLAB = 5;
const DEFAULT_SLAB = 2;
const PRECEEDING_HEADER = 3;

const SLAB_INTERVAL = [
  { id: 1, title: '2 days', value: 2 },
  { id: 2, title: '5 days', value: 5 },
  { id: 3, title: '7 days', value: 7 },
  { id: 4, title: '15 days', value: 15 },
  { id: 5, title: '30 days', value: 30 },
  { id: 6, title: '60 days', value: 60 },
];

const slabHeaderGenerator = (slab = 2, totalSlab = TOTAL_SLAB) => {
  const stringList = [`0-${slab}`];
  for (let count = 1; count < totalSlab - 1; count += 1) {
    const string = `${count * slab + 1} - ${count * slab + slab}`;
    stringList.push(string);
  }
  stringList.push(`> ${slab * (totalSlab - 1)}`);
  return stringList;
};

const stockAgeingReportConfig = [
  {
    id: 1,
    label: 'sku_id',
    title: 'SKU ID',
    className: '',
    sortable: false,
  },
  // {
  //   id: 2,
  //   label: 'business_group',
  //   title: 'Business Group',
  //   className: '',
  //   sortable: false,
  // },
  {
    id: 2,
    label: 'title',
    title: 'Title',
    className: '',
    sortable: false,
  },
  {
    id: 3,
    label: 'balance',
    title: 'Balance',
    className: '',
    sortable: false,
  },

  {
    id: 4,
    label: 'slab1',
    title: 'Slab 1',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 5,
    label: 'slab2',
    title: 'Slab 2',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 6,
    label: 'slab3',
    title: 'Slab 3',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'slab4',
    title: 'Slab 4',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 8,
    label: 'slab5',
    title: 'Slab 5',
    className: 'right-align',
    sortable: false,
  },
];

const getHeaderWithDynamicSlab = (slab = DEFAULT_SLAB) => {
  const slabHeader = slabHeaderGenerator(slab);
  return stockAgeingReportConfig.map((element, index) => {
    if (index < PRECEEDING_HEADER) {
      return element;
    }
    // eslint-disable-next-line no-param-reassign
    element.title = slabHeader[index - PRECEEDING_HEADER];
    return element;
  });
};

const config = {
  headerGenerator: getHeaderWithDynamicSlab,
  header: stockAgeingReportConfig,
  getTableBody: StockAgeingReportBody,
  slabInterval: SLAB_INTERVAL,
};

export default config;
