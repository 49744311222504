import React from 'react';
import { Icon } from '../../../components/BillingMDC';

const tagBody = ({ dataList, onRowClick, deleteHandler }) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr>
        <td className='left-align'>{data.title}</td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => onRowClick(data, index)} />
          <Icon name='delete' onClick={() => deleteHandler(data.idTags)} />
        </td>
      </tr>
    ))}
  </tbody>
);

const tagConfig = [
  {
    id: 1,
    label: 'title',
    title: 'Title',
    className: '',
    sortable: false,
  },
  {
    id: 3,
    label: '',
    title: '',
    className: '',
    sortable: false,
  },
];

const formValidationFieldList = [{ title: 'title' }];

const config = {
  header: tagConfig,
  getTableBody: tagBody,
  formValidationFieldList,
};

export default config;
