import { compose } from 'recompose';
import AggregateDetail from './AggregateDetail';
import apiInterceptor from '../../../../api/interceptor';
import { ledgerGroupApi } from '../../../common/base.api';

const composedAggregateDetail = compose(
  apiInterceptor({ downloadDetailList: ledgerGroupApi.downloadDetailList, }),
)(AggregateDetail);

export default composedAggregateDetail;
