import React, { Component, Fragment } from 'react';
import moment from 'moment/moment';
import DetailView from '../../../common/detail/DetailView';
import PageButtonWrapper from '../../../common/PageButtonWrapper';
import config from './config';
import { checkIfApiCallSuccess, prnApi } from '../../../common/base.api';
import { isObjectEmpty } from '../../../../utils/objectProcessor';
import withBaseState from '../../../common/withBaseState';
import { PURCHASE_RETURN_BASE } from '../../../../data/enums/Route';
import { Button, LinearProgress } from '../../../../components/BillingMDC';
import history from '../../../../utils/history';
import numberToWords from '../../../../utils/numberToTextConverter';
import { getPercentage, voucherDecoder } from '../../../../utils/miscellaneous';
import { CLIENT, VAT_PERCENTAGE, GET_BATCH_TH } from '../../../../data/enums/enums';
import { has } from '../../../../utils/hasOwnProperty';
import Header from '../../../../components/PrintComponent/Header';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import BillTotalContent from '../../../../components/PrintComponent/BillTotalContent';
import PageNumber from '../../../../components/PrintComponent/PageNumber';
import { clone } from '../../../../utils/arrayProcessor';
import ReportView from '../../../../components/PrintComponent/ReportView';
import { fixedFloat } from '../../../../utils/conversion';
import * as filterUtil from '../../../../utils/filterUtil';
import InvoiceDetailsStyled from '../../sales/detail/InvoiceDetailsStyled';
import { groupPrintDataSet } from '../../../common/print.service';
import getPrintData from './getPrintData';
import getConfigData from './getConfigData';
import ComplexPrint from '../../../../components/PrintComponent/complexPrint/complexPrint';

const propTypes = {};
const defaultProps = {};

class PRNDetail extends Component {
  constructor(props) {
    super(props);
    this.box = React.createRef();
    this.state = {
      client: CLIENT.DBS,
      id: props.match.params.id || '',
      data: {
        list: [],
        summary: {},
      },
      loading: false,
      billSummary: config.billSummaryMapper({}),
      date: filterUtil.getCurrentDay(),
      miti: '',
      // invoiceList: [],
      printButtonClicked: false,
      billTotalList: [
        { title: 'Sub Total', value: 0 },
        { title: 'Bill Discount', value: 0 },
        {
          title: 'Trade Discount',
          value: 0,
        },
        { title: 'VAT', value: 0 },
      ],
      company: isObjectEmpty(props.company) ? { printInfo: {} } : props.company,
      dataMiscellaneousList: [
        { title: 'PRN Invoice Number', value: '' },
        {
          title: 'GRN Number',
          value: '',
        },
        { title: 'Vendor Name', value: '' },
        { title: 'Address', value: '' },
        { title: 'PAN No', value: '' },
      ],
      dataList: [],
      totalQuantity: 0,
      invoiceDetail: { title: 'Prepared By', value: '' },
    };
    this.pageConfig = this.getPageConfig();
  }
  getDetail = () => {
    const { match, company, location } = this.props;
    const { invoiceDetail, client } = this.state;
    const prnNumber = voucherDecoder(match.params.id);
    const userId = match.params.userId || null;
    this.setState({ loading: true, client: userId ? CLIENT.ROSIA : CLIENT.DBS });
    prnApi
      .getPrintDetails(prnNumber)
      .then(response => {
        this.setState({ loading: false });
        const { details, ...summary } = { ...response.data };
        let companyInfo = { ...company, printInfo: { count: company?.printInfo?.count,batchEnabled:company?.printInfo?.batchEnabled } };
        summary.subTotal = summary.amount - (summary.promotionDiscount || 0) + (summary.oExciseAmount || 0);
        summary.taxableAmount =
          summary.subTotal + summary.excise - (summary.billDiscount || 0) - (summary.tradeDiscount || 0);
        summary.actualTaxableAmount = fixedFloat(summary.taxableAmount - (summary.taxExemptedAmount || 0));
        const data = {
          list: details,
          summary,
        };

        summary.totalInWords = numberToWords(summary.netAmount || 0);
        const billPercent = getPercentage(summary.billDiscount, summary.subTotal);
        const tradePercent = getPercentage(summary.tradeDiscount, summary.subTotal - summary.billDiscount);

        const billSummary = config.billSummaryMapper({
          ...summary,
          billPercent,
          tradePercent,
        });
        const customerDetails = config.customerDetailMapper(summary);
        const dataMiscellaneousList = [
          {
            title: 'PRN Invoice Number',
            value: summary.purchaseReturnInvoiceNumber,
          },
          {
            title: 'GRN Number',
            value: summary.refNumber,
          },
          { title: 'Vendor', value: customerDetails.customerName },
          {
            title: 'Address',
            value: summary.address,
          },
          { title: 'PAN No', value: summary.customer.panNo },
        ];
        const billTotalList = [
          { title: 'Sub Total', value: summary.subTotal },
          {
            title: `Bill Discount${billPercent ? ` (${billPercent}%)` : ''}`,
            value: summary.billDiscount,
          },
          {
            title: `Trade Discount${tradePercent ? ` (${tradePercent}%)` : ''}`,
            value: summary.tradeDiscount,
          },
          // JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise
          //   ? { title: 'Excise Duty', value: summary.excise }
          //   : {},
          { title: 'Taxable Amount', value: summary.actualTaxableAmount },
          { title: 'VAT', value: summary.vat },
        ];
        if (JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise) {
          billTotalList.splice(3, 0, { title: 'Excise Duty', value: data.summary.excise });
        }
        if (has.call(summary.user || {}, 'name')) {
          invoiceDetail.value = summary.user.name;
        }
        const totalQuantity = details.reduce((acc, curr) => {
          return acc + curr.quantity;
        }, 0);
        if (client === CLIENT.ROSIA) {
          companyInfo = response.data.company || company;
        }
        this.setState(
          {
            date: moment(summary?.documentDate).format('DD MMM YYYY'),
            miti: summary.miti,
            billSummary,
            invoiceDetail,
            billTotalList,
            dataMiscellaneousList,
            data,
            totalQuantity,
            company: companyInfo,
          },
          () => {
            if ((location && location.state && location.state.printEnable) || this.state.client === CLIENT.ROSIA) {
              console.log('this.state.client === CLIENT.ROSIA', this.state.client === CLIENT.ROSIA);
              this.handlePrintClick();
            }
          },
        );
      })
      .catch(err => this.setState({ loading: false }));
  };
  setupConfig() {
    const { match, location } = this.props;
    const invoiceHash = match.params.invoiceHash || '';
    if (!location.state && !!invoiceHash.length) {
      document.getElementsByClassName('content-drawer')[0].classList.add('visibility-hidden');
      document.getElementsByClassName('nav-bar')[0].classList.add('visibility-hidden');
    }
    // if (this.state.client === CLIENT.ROSIA) {
    //   document.getElementsByClassName('scrollbar-container')[0].classList.add('visibility-hidden');
    //   document.getElementsByClassName('nav-bar')[0].classList.add('visibility-hidden');
    // }
  }
  groupPrintDataSetNew() {
    const { data, company } = this.state;
    const printInfoBill = company?.printInfo || {};

    const orders = clone(data.list);

    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }
  calculateTotal = (detailList, key) => {
    let totalValue = 0;
    detailList.map(item => {
      totalValue += item[key];
    });
    return Number(Number(totalValue).toFixed(2));
  };
  handlePrintClick = () => {
    // const { client } = this.state;
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ printButtonClicked: true });

    // self.setState({ printButtonClicked: true }, () => {
    //   setTimeout(() => {
    //     window.print();
    //   }, 500);
    // });
    // window.onafterprint = function () {
    //   self.setState({ printButtonClicked: false }, () => {
    //     if (client === CLIENT.ROSIA) window.close();
    //   });
    // };
  };

  btnWrapper = () => (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        accent
        className="margin-right-0 cancel-btn modal-btn"
        onClick={() => history.push(`/${PURCHASE_RETURN_BASE}`)}
      >
        Cancel
      </Button>
      <div ref={this.box}>
        <Button
          accent
          className="save-btn modal-btn"
          onClick={() => {
            this.handlePrintClick();
          }}
        >
          Print
        </Button>
      </div>
    </div>
  );


  getPageConfig = () => {
    const {
      company: {
        printInfo: { batchEnabled },
      },
    } = this.state;
    const header = [...config.header];
    if (batchEnabled) {
      header.splice(2, 0, GET_BATCH_TH);
      return { ...config, header };
    }
    return config;
  };

  componentDidMount() {
    this.getDetail();
    window.addEventListener('keydown', this.handleKeyDown);
    this.setupConfig();
    document.addEventListener('mousedown', this.handleOutsideClick);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleOutsideClick = event => {
    const { client } = this.state;
    if (this.box && !this.box.current.contains(event.target)) {
      this.state.printButtonClicked === true &&
        this.setState({ printButtonClicked: false }, () => {
          if (client === CLIENT.ROSIA) window.close();
        });
    }
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  render() {
    const { serverResponseWaiting } = this.props;
    const {
      data,
      loading,
      dataList,
      billSummary,
      billTotalList,
      invoiceDetail,
      date,
      miti,
      totalQuantity,
      printButtonClicked,
      dataMiscellaneousList,
      company,
    } = this.state;

    const headerData = {
      // printCountInfo: 1,
      title: config.title,
      date,
      miti,
      dataMiscellaneousList,
    };

    const addToBillTotal = [...billTotalList, { title: 'Total', value: billSummary?.totalValue ?? 0 }];

    const totalDatas = {
      billTotalList: addToBillTotal,
      paymentMode: billSummary.paymentMode,
      invoiceDetail,
      print,
      batchEnabled: company.printInfo.batchEnabled,
      // printInfo,
      totalInWords: billSummary.totalInWords,
      // remarksStatus,
      remarks: billSummary.remarks,
      // agentInfo,
      totalQuantity,
      // printFooterNote,
    };

    const printData = getPrintData([...data.list], { ...company }, headerData, totalDatas);
    const configData = getConfigData(printData?.tableFooterData, company?.printInfo?.batchEnabled);
    return (
      <InvoiceDetailsStyled>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div style={{ display: `${this.state.client === CLIENT.ROSIA ? 'none' : ''}` }}>
          <div className={`sales-invoice srn srn-detail prn-detail${loading ? 'clickable-false' : ''}`}>
            <div className="display-block">
              <DetailView
                display={{
                  header: true,
                  footer: true,
                  summary: true,
                }}
                serverResponseWaiting={serverResponseWaiting}
                headerConfig={{
                  company,
                  title: config.title.original,
                  date,
                }}
                printInfoBill={company?.printInfo}
                pageConfig={this.pageConfig}
                data={data}
              />
              <PageButtonWrapper renderBtn={this.btnWrapper} />
            </div>
            {printButtonClicked && (
              <div className="display-block portrait">
                {/* <ReportView
                billTotalList={billTotalList}
                billSummary={billSummary}
                config={config}
                dataList={dataList}
                invoiceDetail={invoiceDetail}
                dataMiscellaneousList={dataMiscellaneousList}
                date={date}
                company={company}
                miti={miti}
                pageConfig={this.pageConfig}
                batchControl
                totalQuantity={totalQuantity}
              /> */}
                <ComplexPrint configData={configData} printData={printData} orientation={'portrait'} />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: `${this.state.client === CLIENT.ROSIA ? 'flex' : 'none'}`,
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 74px)',
            width: '100%',
          }}
        >
          {/* the translate part is width of drawer minus content-body left padding, height of nav minus content-body top padding */}
          <p style={{ fontSize: '24px', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.54)' , transform: 'translate(-44px,-54px)'}}>
            Click anywhere to close this tab
          </p>
        </div>
      </InvoiceDetailsStyled>
    );
  }
}

PRNDetail.propTypes = propTypes;
PRNDetail.defaultProps = defaultProps;

const PurchaseReturnDetailWithState = withBaseState(PRNDetail);

export default PurchaseReturnDetailWithState;
