import { compose } from 'recompose';
import SalesPersonOutstandingReport from './SalesPersonOutstandingReport';
import apiInterceptor from '../../../api/interceptor';
import { salesPersonOutstandingApi } from '../../common/base.api';

const composedSalesPersonOutstandingReport = compose(apiInterceptor({ ...salesPersonOutstandingApi }))(
  SalesPersonOutstandingReport,
);

export default composedSalesPersonOutstandingReport;
