import styled from 'styled-components';

const InvoiceDetailsStyled = styled.div`
@media print {
    @page {
        margin:0 !important;
    }
    
    .print-body {
      padding:0 !important;
      page-break-after: always;
      height:100% !important;
      margin:0.35in !important;
      overflow:unset;
    }

    .portrait .footer.total-section-wrapper,
    .footer-totalList{
        position:absolute;
        width:99.8%;
        padding:0 !important;
        bottom:-15px;
        left:0;
        tr:nth-child(3){
        border-top:1px solid rgba(0,0,0,0.54);
        }
    }
    .sales-invoice .portrait .footer.total-section-wrapper .note td,
    .sales-invoice .portrait .footer.total-section-wrapper .total_quantity_td{
        padding:0 !important;
    }
    .sales-invoice .three-input-wrapper .mdc-layout-grid .input-print:last-child{
        display:none;
    }
    .sales-invoice.srn-detail .three-input-wrapper .mdc-layout-grid .input-print:last-child{
        display:flex;
    }
    .sales-invoice .print-body table th{
    white-space: nowrap !important;
}
.sales-invoice.srn-detail .print-body .fixed-table-wrapper table ,
.sales-invoice .print-body .fixed-table-wrapper table {
thead{
    th{
            &:last-child{
                padding-right:10px !important;
            }
        }
}
tbody {
    tr{
        td{
            vertical-align:top;
            &:last-child{
                padding-right:10px !important;
            }
            &.srn{
                white-space: nowrap !important;
            }
        }
        &:first-child{
        td{
            padding-top:12px !important;
        }
    }
        &:last-child{
        td{
            padding-bottom:12px !important;
        }
    }
}
}
}
.sales-invoice .print-body table td,
.sales-invoice .print-body table th{
    line-height:14px !important;
    padding:0 !important;
    padding-right:8px !important;
}
.sales-invoice .print-body table td:last-child,
.sales-invoice .print-body table th:last-child{
    padding-right:2px !important;
}
.sales-invoice .print-body .total-section-wrapper td.pad-r-2{
    padding-right:2px !important;
}
.sales-invoice .print-body table td{
    vertical-align: top !important;
    white-space: normal !important;
    word-break: break-word !important;
    text-overflow: unset !important;
    overflow:unset !important;
}
.sales-invoice .print-body table th{
    white-space: nowrap !important;
}
.sales-invoice .print-body table td,
.sales-invoice .print-body table th{
    line-height:14px !important;
    padding:0 !important;
    padding-right:8px !important;
}
.sales-invoice .print-body table td:last-child,
.sales-invoice .print-body table th:last-child{
    padding-right:2px !important;
}
.sales-invoice .print-body .total-section-wrapper td.pad-r-2{
    padding-right:2px !important;
}
.sales-invoice .print-body table td{
    vertical-align: top !important;
    white-space: normal !important;
    word-break: break-word !important;
    text-overflow: unset !important;
    overflow:unset !important;
    padding-top:3px !important;
}
.sales-invoice .print-body table th,
.sales-invoice .print-body table td{
         box-sizing:border-box;
     }
     .sales-invoice .print-body .fixed-table-wrapper{
         border-bottom:1px solid rgba(0,0,0,0.54) !important;
     }
            /* grn-details styling */
        .total_quantity_td{
            position: relative;
        }
        .batch-disable{
            position: absolute;
            left: 39%;
            top: 6px;
        }
        .sales-invoice .batch-enable{
            position: absolute;
            left: 50px;
            top: 6px;
            width: 320px;
            display: flex;
            justify-content:space-between;
        }
        .sales-invoice.srn .batch-enable{
            width: 390px;
        }

        .invoice_value{
            text-transform: capitalize;
        }
        /* grn-details styling */
        /* extra styling */
        .footer-block{
            bottom: -40px;
        }
        .continue-nxt{
            bottom: -20px;
        }
        .note_p{
            position: absolute;
            bottom: 30px;
            left: 0;
            font-weight:600;
            width:600px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            }
            .abbreviate-invoice-detail .note_p{
                bottom: 30px !important;
            }
            .srn-detail .note_p{
                bottom: 30px !important;
            }
            .td-wrap.print-info{
                &>span{
                    flex:2;
                    &:last-child{
                        text-align:right;
                    }
                }
                &>span:last-child{
                    flex:1;
                }
            }
            .agent_sub-wrap{
                display:flex;
                gap:16px;
                align-items:center;
                .agent_info{
                    min-width:446px;
                }
                .sub-email{
                    display:flex;
                    gap:6px;
                }
            }
}
             .abbreviate-invoice-detail .print-body .fixed-table-wrapper.fixed-table-height {
                min-height: 980px;
                height: 980px;
                border-bottom: 1px solid rgba(0,0,0,0.54);
             }
            .sales-invoice-detail.abbreviate-invoice-detail .print-body .fixed-table-wrapper.total-footer{
                height: 1080px !important;
                min-height: 200px !important;
                max-height: 1080px;
                overflow: hidden;
                tbody tr
                {
                    height:24px;
                    &:last-child{
                        td{
                            border-bottom: 1px solid rgba(0,0,0,0.54);
                        }
                    }
                    &.blank-tr{
                    height:50px !important;
                }
            }
                }
            }

@media print and (max-width: 8.27in){
       /* styling for A4 */
  
     .sales-invoice .print-body{
        height:1160px !important;
     }
.sales-invoice .fixed-table-wrapper.fixed-table-height {
        /* min-height: 945px !important;
        height: 945px; */
        min-height: 200px !important;
        height:945px !important;
        max-height: 945px !important;
        border:0;
       table{
           height:100%;
       }
    }
    .sales-invoice .fixed-table-wrapper.total-footer {
        min-height: 200px !important;
        /* height: 800px; */
        max-height: 800px !important;
    }
    
     .sales-invoice .print-body table td.sn {
        width:30px !important;
        min-width:30px !important;
        max-width:30px !important;
     }
     .sales-invoice .print-body table td.sku{
        width:240px !important;
        min-width:240px !important;
        max-width:240px !important;
        display: table-cell !important;
     }
     .sales-invoice .print-body table td.batch{
        width:80px !important;
        min-width:80px !important;
        max-width:80px !important;
        line-height:14px !important;
        word-break: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
         overflow:hidden;
         text-overflow: ellipsis;
     }
     .sales-invoice .print-body table td.quantity
    {
        width:60px !important;
        min-width:60px !important;
        max-width:60px !important;
        white-space:nowrap !important;
     }
     .sales-invoice .print-body table td.discount,
     .sales-invoice .print-body table td.rate{
        width:80px;
        min-width:80px !important;
        max-width:80px !important;
        white-space:nowrap !important;
     }
     .sales-invoice .print-body table td.amount {
        width:80px;
        min-width:80px !important;
        max-width:80px !important;
        white-space:nowrap !important;
    }
    .sales-invoice .print-body table td.netAmount{
        width:80px;
        min-width:80px !important;
        max-width:80px !important;
        white-space:nowrap !important;
     }

     /* batch not exist  */
     .sales-invoice .print-body .batch_not_exist td.sku{
        width:calc(216px + 113px - 20px) !important;
        min-width:calc(216px + 113px - 20px) !important;
        max-width:calc(216px + 113px - 20px) !important;
     }
     .sales-invoice .print-body .batch_not_exist td.amount {
        width:calc(80px + 10px) !important;
        min-width:calc(80px + 10px) !important;
        max-width:calc(80px + 10px) !important;
    }
    .sales-invoice .print-body .batch_not_exist td.netAmount{
        width:calc(80px + 10px) !important;
        min-width:calc(80px + 10px) !important;
        max-width:calc(80px + 10px) !important;
     }
        /* .sales-invoice .fixed-table-wrapper table, */
        .sales-invoice.srn-detail .fixed-table-wrapper table{
            height:100%;
        }
        .sales-invoice.srn-detail .print-body{
         height:1160px !important;
     }
    .sales-invoice .blank-tr,.sales-invoice.srn-detail .blank-tr{
        height:40px !important;
        &:last-child{
        }
    }
    .sales-invoice .header-bar,
    .sales-invoice .first-header-bar,
    .sales-invoice.srn-detail .header-bar,
    .sales-invoice.srn-detail .first-header-bar{
        border:1px solid rgba(0,0,0,0.54) !important;
        padding:12px !important;
    }
   .sales-invoice .three-input-wrapper{
        padding:15px;
        border-right:1px solid rgba(0,0,0,0.54) !important;
        border-left:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .fixed-table-wrapper,.sales-invoice.srn-detail .fixed-table-wrapper{
        margin-top:0 !important;
        overflow:auto !important;
        border-bottom:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .fixed-table-wrapper table,.sales-invoice.srn-detail .fixed-table-wrapper table{
        border-left:1px solid rgba(0,0,0,0.54) !important;
        border-right:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper table td,.sales-invoice.srn-detail .print-body .fixed-table-wrapper table td{
        vertical-align: middle !important;
    }
    .sales-invoice table.print-active tfoot ,.sales-invoice.srn-detail table.print-active tfoot {
        border-top:1px solid (0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper thead{
        border:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper thead th:not(:last-child),.sales-invoice.srn-detail .print-body .fixed-table-wrapper thead th:not(:last-child),.grn-detail .print-body .fixed-table-wrapper thead th:not(:last-child){
        height:17.5px !important;
        border-right:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .footer .in-word,.sales-invoice.srn-detail .footer .in-word{
        border:1px solid rgba(0,0,0,0.54) !important;
        border-top:0 !important;
    }
    .sales-invoice .footer .in-word td,.sales-invoice.srn-detail .footer .in-word td{
        padding-top:6px !important;
        padding-bottom:6px !important;
    }
    .sales-invoice .print-body .footer tr:first-child td ,
    .sales-invoice .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer tr:first-child td{
        padding-top:6px !important;
    }
    .sales-invoice .print-body .footer tr:nth-child(5) td ,
    .sales-invoice .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer td ,
    .sales-invoice.srn-detail .print-body .footer tr:nth-child(5) td {
        padding-bottom:6px !important;
    }
    /* .sales-invoice .footer .remark,.sales-invoice.srn-detail .footer .remark{
        padding-top:10px !important;
    } */
    .sales-invoice .footer .total td:first-child,
    .sales-invoice .footer .total-info td:first-child,
    .sales-invoice .footer .in-word td:first-child,
    .sales-invoice.srn-detail .footer .total td:first-child,
    .sales-invoice.srn-detail .footer .total-info td:first-child,
    .sales-invoice.srn-detail .footer .in-word td:first-child {
        padding-left:6px !important;
        padding-top:16px;
    }
    .sales-invoice .footer .total td,
    .sales-invoice .footer .total-info{
        .total-content-data.text-right{
            white-space:nowrap !important;
        }
    }
    .sales-invoice .footer .total td:last-child,
    .sales-invoice .footer .total-info td:last-child,
    .sales-invoice .footer .in-word td:last-child,
    .sales-invoice.srn-detail .footer .total td:last-child,
    .sales-invoice.srn-detail .footer .total-info td:last-child,
    .sales-invoice.srn-detail .footer .in-word td:last-child {
        padding-right:6px !important;
    }
    
    .sales-invoice .footer .total_quantity_td,
    .sales-invoice .footer .total-info,
    .sales-invoice.srn-detail .footer .total_quantity_td,
    .sales-invoice.srn-detail .footer .total-info{
        border-right:1px solid rgba(0,0,0,0.54) !important;
        border-left:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice,.sales-invoice.srn-detail .footer .total td{
        padding-top:6px !important;
        padding-bottom:6px !important;
    }
    .sales-invoice .total,.sales-invoice.srn-detail .total{
        border:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .footer .total-info:last-child,.sales-invoice.srn-detail .footer .total-info:last-child{
        border-bottom:1px solid rgba(0,0,0,0.54) !important;
    }
    .sales-invoice .print-body .fixed-table-wrapper td:not(:last-child),
    .sales-invoice .print-body .fixed-table-wrapper th:not(:last-child),
    .sales-invoice.srn-detail .print-body .fixed-table-wrapper td:not(:last-child),
    .sales-invoice.srn-detail .print-body .fixed-table-wrapper th:not(:last-child){
        border-right:1px solid rgba(0,0,0,0.54) !important;
        padding:4px 8px !important;
    }
    .sales-invoice.srn-detail .print-body table td.sku{
        width:300px !important;
        min-width:300px !important;
        max-width:300px !important;
     }
     .sales-invoice.srn-detail .print-body .batch_not_exist td.sku{
        width:calc(270px + 130px) !important;
        min-width:calc(270px + 130px) !important;
        max-width:calc(270px + 130px) !important;
     }
     .sales-invoice.srn-detail.srn .print-body table td.batch{
        width:80px !important;
        min-width:80px !important;
        max-width:80px !important;
     }
     .sales-invoice.srn-detail .print-body table td.batch{
        width:150px !important;
        min-width:150px !important;
        max-width:150px !important;
     }
    .sales-invoice.srn-detail .fixed-table-wrapper.fixed-table-height {
        /* min-height: 980px !important;
        height: 980px; */
        min-height: 200px !important;
        max-height: 980px !important;
        border:0;
    }
    .sales-invoice.srn-detail .fixed-table-wrapper.total-footer {
        /* min-height: 730px !important;
        height: 800px; */
        /* max-height: 730px; */
    }
     .abbreviate-invoice-detail .print-body .fixed-table-wrapper  table td {
        vertical-align: middle !important;
    }
    .abbreviate-invoice-detail .print-body .fixed-table-wrapper .blank-tr{
        height:25px !important;
    }
}
@media print and (max-width:5.83in) {
    .portrait .print-body{
         height:1160px !important;
     }
    .sales-invoice .fixed-table-wrapper{
         /* min-height:875px ; */
     }
     .sales-invoice .fixed-table-wrapper.total-footer {
        /* min-height: 660px !important;
        height: 800px; */
        }
     .sales-invoice.srn-detail .fixed-table-wrapper.total-footer {
        /* min-height: 735px !important;
        height: 800px; */
        min-height: 200px !important;
        max-height: 800px !important;
    }
     .sales-invoice .fixed-table-wrapper.fixed-table-height {
         /* min-height:800px ; */
     }
     .abbreviate-invoice-detail .print-body .fixed-table-wrapper.total-footer{
        /* min-height: 740px;
        height: 800px; */
    border-bottom: 1px solid rgba(0,0,0,0.54);
    }
/* batch not exist */
     .note_p{
            position: absolute;
            bottom: 25px !important;
            left: 0;
            font-weight:600;
            width:600px;
            }
            .abbreviate-invoice-detail .note_p{
                bottom: 30px !important;
            }
           .srn-detail .note_p{
            bottom: 30px !important;
            }
}
`;
export default InvoiceDetailsStyled;
