let interval;

const debouncer = (callback, time) => {
  return (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
};

const handleBatchSelect = (order = {}, batchObj = {}, domain = 'sales', batchProperty = 'batch_id') => {
  const activeOrder = { ...order };
  activeOrder[batchProperty] = batchObj.businessBatchId || '';
  activeOrder.rate = domain === 'sales' ? order.rate || 0 : batchObj.dlp || 0;
  activeOrder.promotion_discount = 0;
  activeOrder.batchTitle = batchObj.title || '';

  return activeOrder;
};

const getDiscountedValue = (grossValue, parameter = 0, parameterValue = 0) => {
  let discountedValue = 0;
  switch (parameter) {
    case 'rs':
      discountedValue = parameterValue;
      break;
    case 'per':
      discountedValue = (parameterValue / 100) * grossValue;
      break;
    default:
      break;
  }

  return discountedValue;
};

const getIrdSalesColumnTotals = (response) => {
  console.log('response',response)
  const columnTotal = {};
  columnTotal.totalQuantity = response.total_quantity ? response.total_quantity : 0;
  columnTotal.grossTotalTaxableAmount = response.gross_total_taxable_sales ? response.gross_total_taxable_sales : 0;
  columnTotal.totalTaxableAmount = response.total_taxable_amount ? response.total_taxable_amount : 0;
  columnTotal.totalSales = response.total_sales ? response.total_sales : 0;
  columnTotal.taxExemptedSales = response.total_tax_exempted_sales ? response.total_tax_exempted_sales : 0;
  columnTotal.exportSales = response.total_export_sales ? response.total_export_sales : 0;
  columnTotal.discount = response.total_discount ? response.total_discount : 0;
  columnTotal.vat = response.total_taxable_vat ? response.total_taxable_vat : 0;
  columnTotal.excise = response.total_excise? response.total_excise : 0;

  return columnTotal;
};

const getIrdPurchaseColumnTotals = (response) => {
  const columnTotal = {};
  columnTotal.totalQuantity = response.total_quantity ? response.total_quantity : 0;
  columnTotal.grossTotalTaxableAmount = response.gross_total_taxable_purchase ? response.gross_total_taxable_purchase : 0;
  columnTotal.totalTaxableAmount = response.total_taxable_amount ? response.total_taxable_amount : 0;
  columnTotal.totalPurchase = response.total_purchase ? response.total_purchase : 0;
  columnTotal.taxExemptedPurchase = response.total_tax_exempted_purchase ? response.total_tax_exempted_purchase : 0;
  columnTotal.exportPurchase = response.total_export_purchase ? response.total_export_purchase : 0;
  columnTotal.discount = response.total_discount ? response.total_discount : 0;
  columnTotal.vat = response.total_taxable_vat ? response.total_taxable_vat : 0;
  columnTotal.excise = response.total_excise? response.total_excise : 0;
  columnTotal.total_capitalized_purchase_amount = response.total_capitalized_purchase_amount ? response.total_capitalized_purchase_amount : 0;
  return columnTotal;
};

export {
  debouncer, handleBatchSelect, getDiscountedValue, getIrdSalesColumnTotals, getIrdPurchaseColumnTotals
};
