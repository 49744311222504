import PropTypes from 'prop-types';
import React from 'react';

import { Snackbar } from 'react-mdc-web';
import { RESPONSE } from '../../data/enums/enums';

const propTypes = {
  config: PropTypes.shape({
    display: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  closeSnack: PropTypes.func.isRequired,
};

const BillingSnackBar = ({ config, closeSnack }) => (
  <div className='message-snackbar'>
    <Snackbar
      action='RETRY'
      timeout={3000}
      onTimeout={() => closeSnack()}
      open={config.display}
      className={config.type === RESPONSE.SUCCESS ? 'success-message' : ''}
    >
      {config.message}
    </Snackbar>
  </div>
);

BillingSnackBar.propTypes = propTypes;

export default BillingSnackBar;
