import PropTypes from 'prop-types';
import React from 'react';
import TableHeader from '../../../components/TableHeader';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  display: PropTypes.shape({
    searchBox: PropTypes.bool,
    filter: PropTypes.filter,
    menu: PropTypes.menu,
  }),
  pagination: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
  }),
  handleTableSorting: PropTypes.func,
  onTableBodyClick: PropTypes.func,
  data: PropTypes.shape({
    list: PropTypes.instanceOf(Array),
    total: PropTypes.number,
  }),
  onPageSelect: PropTypes.func,
  changeTheadStatus:PropTypes.bool,
  config: PropTypes.shape({
    header: PropTypes.instanceOf(Array),
    getTableBody: PropTypes.func,
  }).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  handleTableSorting: () => null,
  changeTheadStatus:false,
  data: {
    list: [],
    total: 0,
  },
};

React.createRef();

const refs = { tableHeader: React.createRef(), };

const DetailTableView = ({
  serverResponseWaiting,
  handleTableSorting, // this.handleTableSorting,
  data, // list and total
  pageConfig,
  printInfoBill,
  onTableBodyClick,
  changeTheadStatus,
  ledgerList,
  print,
  drList,
  crList,
}) => (
  <div>
    <div className={serverResponseWaiting ? 'clickable-false' : ''}>
      <div className='fixed-table-wrapper hr-scrollable-table'>
        <table ref={refs.tableHeader}>
          <TableHeader headerDetails={changeTheadStatus ? pageConfig.newHeader:pageConfig.header} handleSorting={handleTableSorting} />

          {pageConfig.getTableBody({ dataList: data.list ,summary:data.summary, printInfoBill,onRowClick: onTableBodyClick,ledgerList,print,drList,crList })}
        </table>
      </div>
    </div>
  </div>
);

DetailTableView.propTypes = propTypes;

DetailTableView.defaultProps = defaultProps;

export default DetailTableView;
