import React, { Component } from 'react';
import Dialog from './Dialog';
import tableConfig from './config';
import history from '../../../utils/history';
import UserGroupStyled from './UserGroupStyled';
import {ACTION, ROLES} from '../../../data/enums/enums';
import { validateForm } from '../../common/validation';
import withBaseState from '../../common/withBaseState';
import { USER_GROUP } from '../../../data/enums/Route';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { BillingSnackBar } from '../../../components/BillingMDC';
import { USER_GROUP_CONFIG } from '../../common/domain.config';

class UserGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
      },
      dialogType: ACTION.NULL,
      snack: { ...snackService.snackParameters },
      queryParameters: {
        search: queryService.queryParameters.search,
        sort: queryService.queryParameters.sort,
        pagination: queryService.queryParameters.pagination,
        date: queryService.queryParameters.date,
      },
      data: {
        list: [],
        total: 0,
      },
      userGroup: {
        name: '',
      },
      validation: {
        flag: false,
        fieldList: [{ title: 'name' }],
      },
    };

    this.reference = {
      tableHeader: React.createRef(),
      tableFooter: React.createRef(),
      tableBody: React.createRef(),
      fileRef: React.createRef(),
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getUserGroupList } = this.props;
    const { queryParameters } = this.state;

    getUserGroupList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.list
      data.list = response.list.filter((group)=> group.idRoles !== ROLES.SUPER_ADMIN);
      data.total = response.total;
      this.setState({ data });
    });
  };

  handleEditIconClick = data => {
    const sku = tableConfig.getDetail(data);
    this.setState({ dialogType: ACTION.UPDATE, sku });
  };

  handleFabButtonClick = () => {
    const { userGroup } = this.state;
    this.setState({ dialogType: ACTION.ADD, userGroup });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  handleInputChange = (field, value) => {
    const { userGroup } = this.state;
    userGroup[field] = value;
    this.setState({ userGroup });
  };

  handleModalClose = () => {
    const { validation, userGroup } = this.state;
    validation.flag = false;
    userGroup.name = '';
    this.setState({ dialogType: ACTION.NULL, validation, userGroup });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  handleModalSubmit = () => {
    const { userGroup, validation } = this.state;
    const formValid = validateForm(userGroup, validation, valid => this.setState({ validation: valid }));
    if (!formValid) return false;
    this.createUserGroup();
    this.handleModalClose();
  };
  createUserGroup = () => {
    const { userGroup } = this.state;
    const { createUserGroup } = this.props;

    createUserGroup({ name: userGroup.name })
      .then(response => {
        const snack = snackService.generateSuccessMessage();
        this.setState({
          snack,
        });
        this.loadTableData();
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage(err.message);
        this.setState({ snack });
      });
  };
  handleRowClick = userGroup => {
    history.push(`/${USER_GROUP}/${userGroup.idRoles}`);
  };

  render() {
    const { queryParameters, data, display, snack, dialogType, validation, userGroup } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <UserGroupStyled className="user-group">
        <PageView
          domainConfig={USER_GROUP_CONFIG}
          headerConfig={{
            search: true,
            upload: false,
            download: false,
            date: false,
            filter: false,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          handleUploadClick={this.handleUploadIconClick}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.basePaginationService.resetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleDownloadClick={this.basePaginationService.handleDownloadClick}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleRowClick}
          onPageSelect={this.basePaginationService.onPageSelect}
          createHandler={this.handleFabButtonClick}
        />

        <Dialog
          userGroup={userGroup}
          validation={validation}
          actionType={dialogType}
          onInputChange={this.handleInputChange}
          onModalClose={this.handleModalClose}
          onModalSubmit={this.handleModalSubmit}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </UserGroupStyled>
    );
  }
}

const UserGroupWithState = withBaseState(UserGroup);

export default UserGroupWithState;
