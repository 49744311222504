import React from 'react';
import { formatToReadableDate } from '../../../utils/filterUtil';
import { fixedFloatAndCommas, roundAndCommas } from '../../../utils/conversion';

const numberConfig = { maximumFractionDigits: 2 };

const AgeingReportBody = ({ dataList }) => (
  <tbody>
    {dataList.map((data) => (
      <tr className='cursor-pointer'>
        <td className='left-align'>{data.voucher_number}</td>
        <td className='left-align'>{data.ledger_title}</td>
        <td className='left-align'>{formatToReadableDate(data.transaction_date)}</td>
        <td className='left-align'>{formatToReadableDate(data.due_date)}</td>
        <td className='left-align'>{data.mitiTitle}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.dr_amount)}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.clearance_amount)}</td>
        <td className='right-align'>{roundAndCommas(data.balance_amount)}</td>
        <td className='right-align'>{parseFloat(data.balance_amount) ? fixedFloatAndCommas(data.due_age) : 'N/A'}</td>
      </tr>
    ))}
  </tbody>
);

const outstandingReportConfig = [
  { id: 1, label: 'voucher_number', title: 'Voucher Number', className: 'cursor-pointer left-align', sortable: true },
  { id: 2, label: 'customer.customerName', title: 'Customer', className: 'cursor-pointer left-align', sortable: false },
  { id: 5, label: 'transaction_date', title: 'Bill Date', className: 'cursor-pointer left-align', sortable: true },
  { id: 6, label: 'due_date', title: 'Due Date', className: 'cursor-pointer left-align', sortable: true },
  { id: 11, label: 'miti_title', title: 'Due Miti', className: 'cursor-pointer left-align', sortable: false },
  { id: 7, label: 'dr_amount', title: 'Bill Amount', className: 'cursor-pointer right-align', sortable: true },
  { id: 8, label: 'clearance_amount', title: 'Adjusted', className: 'cursor-pointer right-align', sortable: true },
  { id: 9, label: 'balance_amount', title: 'Balance', className: 'cursor-pointer right-align', sortable: true },
  { id: 10, label: 'due_age', title: 'Due Age', className: 'cursor-pointer right-align', sortable: true },
];

const config = {
  header: outstandingReportConfig,
  getTableBody: AgeingReportBody,
};

export default config;
