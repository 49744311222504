import { compose } from 'recompose';

import TagWiseDetail from './TagWiseDetail';

import apiInterceptor from '../../../../api/interceptor';

import { tagReportApi } from '../../../common/base.api';

const composedLedgerDetailReport = compose(apiInterceptor({ ...tagReportApi }))(TagWiseDetail);

export default composedLedgerDetailReport;
