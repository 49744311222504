import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const StockJournalDetailTableBody = ({ data, index }) => {
  return (
    <tr>
      <td className='left-align'>{index + 1}</td>
      <td className='left-align'>{data.title}</td>
      <td className='left-align'>{data.batch}</td>
      <td className='right-align'>{data.quantity}</td>
      <td className='left-align'>{data.type == 0 ? <span>Inward</span> : <span>Outward</span>}</td>
      <td className='left-align'>{data.remarks}</td>
    </tr>
  );
};

StockJournalDetailTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const stockJournalDetailConfig = {
  headerDetails: [
    { id: 1, label: 'index', title: 'SN', className: 'cursor-pointer', sortable: true },
    { id: 2, label: 'title', title: 'SKU', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'batch', title: 'Batch', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'quantity', title: 'Quantity (pcs)', className: 'right-align cursor-pointer', sortable: true },
    { id: 5, label: 'type', title: 'Status', className: 'cursor-pointer', sortable: false },
    { id: 6, label: 'remarks', title: 'Remarks', className: 'cursor-pointer', sortable: true },
  ],
};

export { stockJournalDetailConfig, StockJournalDetailTableBody };
