import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const GRNTableBody = ({ data, onRowClick }) => {
  return (
    <tr className='cursor-pointer' onClick={() => onRowClick(data)}>
      {/* <td className="left-align">{data.idPurchase}</td>*/}
      <td className='left-align'>{data.purchaseInvoiceNumber}</td>
      <td className='left-align'>{moment(data.date).format('ll')}</td>
      <td className='left-align'>{data.vendorInvoiceNumber}</td>
      <td className='left-align'>{data.customer.name}</td>
      <td className='right-align'>{data.netAmount.toLocaleString()}</td>
    </tr>
  );
};

GRNTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const grnConfig = {
  headerDetails: [
    /*{id: 1, label: 'idPurchase', title: 'GRN ID', className: 'cursor-pointer', sortable: true},*/
    { id: 1, label: 'purchaseInvocieNumber', title: 'GRN Number', className: 'cursor-pointer', sortable: true },
    { id: 2, label: 'date', title: 'Date', className: 'cursor-pointer left-align', sortable: true },
    {
      id: 3,
      label: 'vendorInvoiceNumber',
      title: ' Vendor Invoice Number',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    { id: 4, label: 'customer.name', title: 'Vendor Name', className: 'cursor-pointer left-align', sortable: true },
    { id: 5, label: 'netAmount', title: 'GRN Amount', className: 'cursor-pointer right-align', sortable: true },
  ],
};

export { grnConfig, GRNTableBody };
