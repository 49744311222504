import styled from 'styled-components';

const SettingStyled = styled.div`
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .pannel-section {
    margin: 0;
    margin: 18px 24px;
    .pannel-header h1 {
      font-size: 20px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 40px;
    }
    .pannel-card {
      padding: 18px 30px;
      .radio-list-wrapper {
        border: 0;
        padding: 0;
        padding-top: 3px;
      }
      .custom-checkbox {
        label {
          position: relative;
          top: 2px;
        }
        white-space: nowrap;
      }
      .select-custom-label {
        font-size: 13px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.54);
      }
      .switch_radio {
        display: flex;
        flex-wrap: wrap;
      }
      .select-css {
        border: 0;
        width: 262px;
        .zindex-2__menu {
          z-index: 2;
        }
        .zindex-2__menu-list {
          max-height: 150px;
          overflow: auto;
        }
        .zindex-2__control {
          background-color: rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          .zindex-2__indicators {
            min-height: 40px;
          }
          .zindex-2__single-value {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.54);
          }
        }
      }
      .form-switchs {
        margin-right: 24px;
        .label {
          font-size: 13px;
          line-height: 14px;
          color: rgba(0, 0, 0, 0.54);
          margin-bottom: 8px;
          display: block;
        }
        .switch {
          margin-top:8px;
        }
      }
      .excise-switch{
        margin-bottom:28px;
      }
      .form-input{
        .mdc-layout-grid{
          padding:0;
        }
      input{
        background-color: rgba(0,0,0,0.08);
        border-radius: 4px;
        min-width:230px;
        height:36px;
        line-height:36px;
        border-bottom:0;
        padding:0 12px;
        color: rgba(0, 0, 0, 0.54);
        font-size:12px;
      }
    }
    .info{
      display:flex;
      font-size:10px;
      color:rgba(0, 0, 0, 0.6);
      margin-top:12px;
      position: relative;
      right: 6px;
      i{
        font-size: 15px;
        color: rgba(0,0,0,0.8);
        margin-left:0;
      }
      p{
        margin:0;
        margin-top:1px;
      }
    }
      .excise-number{
        label{
          top: 10px;
            }
        }
      .print-remarks{
        position:relative;
        label{
          display:none;
        }
        .mdc-textfield{
          max-width:unset !important;
        }
      }
      &.credit{
      .limit{
        margin-bottom:24px;
      }
    }
    }
  }
`;
export default SettingStyled;
