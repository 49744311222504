import * as httpUtils from './httpUtils';
import * as appConstants from '../v1/config';
const rosiaUrl = appConstants.rosiaUrl;
const baseUrl = appConstants.baseUrl;

const serverCall = {
  getSubDList: () => httpUtils.post(baseUrl + 'getDistributors', {}),
  subDListAttr: { idLabel: 'id_distributor', titleLabel: 'title' },

  getASMList: () => httpUtils.post(baseUrl + 'getUserGroup', { filter: { user_group_id: [6] } }),
  asmListAttr: { idLabel: 'id_user', titleLabel: 'user_name' },

  getSTLList: () => httpUtils.post(baseUrl + 'getUserGroup', { filter: { user_group_id: [7] } }),
  stlListAttr: { idLabel: 'id_user', titleLabel: 'user_name' },

  getDSEList: () => httpUtils.post(baseUrl + 'getUserGroup', { filter: { user_group_id: [9] } }),
  dseListAttr: { idLabel: 'id_user', titleLabel: 'user_name' },

  getSKUList: () => httpUtils.post(baseUrl + 'getSKU', {}),
  skuListAttr: { idLabel: 'id_sku', titleLabel: 'title' },

  getTownWithRoute: () => httpUtils.post(rosiaUrl + 'town/route/list', {}),

  getUserGroup: () => httpUtils.get(rosiaUrl + 'user-group'),

  getBusinessUnit: () => httpUtils.get(rosiaUrl + 'business-unit'),

  getChannelList: () => httpUtils.get(rosiaUrl + 'channel'),
  channelListAttr: { idLabel: 'id_channel', titleLabel: 'title' },

  getCategoryList: () => httpUtils.post(baseUrl + 'getCategoryList', {}),
  categoryListAttr: { idLabel: 'id_category', titleLabel: 'category_title' },

  getTownList: () => httpUtils.get(rosiaUrl + 'town'),

  getBrandList: () => httpUtils.post(rosiaUrl + 'brand-list', { principal_id: 1, catalog_id: 3 }),
  brandListAttr: { idLabel: 'id_catalog_detail', titleLabel: 'title' },

  getSubBrandFormList: () => httpUtils.post(rosiaUrl + 'brand-list', { principal_id: 1, catalog_id: 6 }),
  subBrandFormAttr: { idLabel: 'id_catalog_detail', titleLabel: 'title' },

  getMRProductCategory: () =>
    httpUtils.post(baseUrl + 'getMrBrandAndProductCat', { filter: { product_category: true } }),
  mrProductCategoryAttr: { idLabel: 'product_category_id', titleLabel: 'product_category' },

  getMRBrand: () => httpUtils.post(baseUrl + 'getMrBrandAndProductCat', { filter: { mr_brand: true } }),
  mrBrandAttr: { idLabel: 'mr_brand_id', titleLabel: 'mr_brand' },
};

export default serverCall;
