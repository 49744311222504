import React, { Fragment } from 'react';
import LedgerWiseChildView from './childView';
import AccordionTable from '../../../components/CollapsibleTable/AccordionTable';
import { roundAndCommas } from '../../../utils/conversion';

const TableBody = ({ data, onRowClick  }) => (
  <>
    <td className={`left-align ledger ${data.tags.length > 0 ? 'ledgerExist' : 'ledgerNotExist'}`} onClick={() => onRowClick(data)}>
      <span>{data.title}</span>
    </td>
    <td className='right-align opening' onClick={() => onRowClick(data)}>
      {data.opening ? roundAndCommas(data.opening) : data.opening}
    </td>
    <td className='right-align openingStatus' onClick={() => onRowClick(data)}>
      {data.openingType}
    </td>
    <td className='right-align debit' onClick={() => onRowClick(data)}>
      {data.debit ? roundAndCommas(data.debit) : data.debit }
    </td>
    <td className='right-align credit' onClick={() => onRowClick(data)}>
      {data.credit ? roundAndCommas(data.credit) : data.credit}
    </td>
    <td className='right-align closing' onClick={() => onRowClick(data)}>
      {data.closing ? roundAndCommas(data.closing) : data.closing}
    </td>
    <td className='right-align closingStatus' onClick={() => onRowClick(data)}>
      {data.closingType}
    </td>
  </>
);

const LedgerTableBody = ({ dataList, onRowClick, onTableClickData = [] ,onSpanClick }) => (
  <tbody>
    <AccordionTable>
      {dataList.map((data, index) => (
       data.tags.length > 0 && < tr
          key={index}
          trigger={TableBody({
            data,
            onRowClick,
          })}
          accordionStatus={data.tags.length > 0}
        >
          {LedgerWiseChildView({ data: onTableClickData,onSpanClick })}
        </tr>
      ))}
    </AccordionTable>
  </tbody>
);

const customerLedgerConfig = [
  {
    id: 1,
    label: 'ledger',
    title: 'Ledger',
    className: '',
    sortable: false,
  },
  {
    id: 2,
    label: 'opening',
    title: 'Opening',
    className: ' text-right',
    sortable: false,
  },
  {
    id: 3,
    label: 'openingStatus',
    title: '',
    className: '',
    sortable: false,
  },
  {
    id: 4,
    label: 'debit',
    title: 'Debit',
    className: ' text-right',
    sortable: false,
  },
  {
    id: 5,
    label: 'credit',
    title: 'Credit',
    className: ' text-right',
    sortable: false,
  },
  {
    id: 6,
    label: 'closing',
    title: 'Closing',
    className: ' text-right',
    sortable: false,
  },
  {
    id: 6,
    label: 'closingStatus',
    title: '',
    className: '',
    sortable: false,
  },
];

const config = {
  header: customerLedgerConfig,
  getTableBody: LedgerTableBody,
};

export default config;
