import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const SRNDetailTableBody = ({ data, index }) => {
  return (
    <tr className='cursor-pointer'>
      <td className='left-align'>{index + 1}</td>
      {/*  <td className="left-align">{data.idSalesReturnDetail}</td>*/}
      <td className='left-align'>{data.title}</td>
      <td className='right-align'>{data.rate}</td>
      <td className='right-align'>{data.quantity}</td>
      <td className='right-align'>{fixedFloatAndCommas(data.promotionDiscount)}</td>
      <td className='right-align'>{fixedFloatAndCommas(data.netAmount)}</td>
    </tr>
  );
};

SRNDetailTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const srnDetailConfig = {
  headerDetails: [
    { id: 1, label: 'idSalesReturnDetail', title: 'SRN ID', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'skuId', title: 'Customer Name', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'rate', title: 'Rate', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'quantity', title: 'Quantity (pcs)', className: 'cursor-pointer right-align', sortable: false },
    { id: 5, label: 'promotionDiscount', title: 'Discount', className: 'cursor-pointer right-align', sortable: false },
    { id: 5, label: 'netAmount', title: 'Net Amount', className: 'cursor-pointer right-align', sortable: false },
  ],
};

export { srnDetailConfig, SRNDetailTableBody };
