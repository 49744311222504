import React, { Fragment } from 'react';
import { Cell, Grid } from '../../../components/BillingMDC';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { VAT_PERCENTAGE } from '../../../data/enums/enums';
import { getPercentage } from '../../../utils/miscellaneous';

const DiscountPresenter = ({ billSummary }) => {
  const billDiscountPer = getPercentage(billSummary.cashDiscountValue, billSummary.invoiceGrossValue);
  const tradeDiscountPer = getPercentage(
    billSummary.tradeDiscountValue,
    billSummary.invoiceGrossValue - billSummary.cashDiscountValue || 0,
  );
  return (
    <Fragment>
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          Sub Total
        </Cell>
        <Cell col={1} className='right-align'>
          {fixedFloatAndCommas(billSummary.invoiceGrossValue)}
        </Cell>
      </Grid>
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          Bill Discount {billDiscountPer ? `(${billDiscountPer}%)` : ''}
        </Cell>
        <Cell col={1} className='right-align'>
          {fixedFloatAndCommas(billSummary.cashDiscountValue)}
        </Cell>
      </Grid>
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          Trade Discount {tradeDiscountPer ? `(${tradeDiscountPer}%)` : ''}
        </Cell>
        <Cell col={1} className='right-align'>
          {fixedFloatAndCommas(billSummary.tradeDiscountValue)}
        </Cell>
      </Grid>
      { JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && <Grid>
                  <Cell col={8} />
                  <Cell col={3} className='right-align active-opacity-text'>
                    Excise Duty
                  </Cell>
                  <Cell col={1} className='right-align'>
                    {fixedFloatAndCommas(billSummary.excise)}
                  </Cell>
                </Grid>
}
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          Taxable Amount
        </Cell>
        <Cell col={1} className='right-align'>
          {fixedFloatAndCommas(billSummary.actualTaxableAmount || 0)}
        </Cell>
      </Grid>
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          VAT
        </Cell>
        <Cell col={1} className='right-align'>
          {fixedFloatAndCommas(billSummary.vatAmount)}
        </Cell>
      </Grid>
      <div className='hr' />
      <Grid>
        <Cell col={8} />
        <Cell col={3} className='right-align active-opacity-text'>
          Total
        </Cell>
        <Cell col={1} className='right-align font-bold'>
          {fixedFloatAndCommas(billSummary.invoiceNetValue || 0)}
        </Cell>
      </Grid>
    </Fragment>
  );
};

export default DiscountPresenter;
