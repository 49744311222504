import React from 'react';
import TableHeader from '../../../components/TableHeader';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const View = ({ data, company, config, queryParameters, activeView, onRowClick, activeIncomes, activeExpenses }) => {
  return (
    <div className='inner-view'>
      <div className='trial-view'>
        <div className='top-header'>
          <h3>{company.title}</h3>
          <span>
            <b>Profit and Loss A/C</b> as of date <b>{queryParameters.singleDate}</b>
          </span>
        </div>
        <div className='table-view'>
          <div className='cards one'>
            <div className='table-wrap'>
              <table>
                <thead className='one'>
                  <tr>
                    <th colSpan='2'>Expenditure</th>
                  </tr>
                </thead>
                <TableHeader headerDetails={config.header} />
                {config.getProfitBody({
                  dataList: data.expenseLedgers,
                  activeView,
                  onRowClick,
                  activeData: activeExpenses,
                  label: 'activeExpenses',
                })}
              </table>
            </div>
            <footer >
              <p className='left-align'>Total</p>
              <p className='right-align'>{fixedFloatAndCommas(data.totalBalance.expense)}</p>
            </footer>
          </div>
          <div className='cards two'>
            <div className='table-wrap'>
              <table>
                <thead className='one'>
                  <tr>
                    <th colSpan='2'>Income</th>
                  </tr>
                </thead>
                <TableHeader headerDetails={config.header} />
                {config.getLossBody({
                  dataList: data.incomeLedgers,
                  activeView,
                  activeData: activeIncomes,
                  onRowClick,
                  label: 'activeIncomes',
                })}
              </table>
            </div>
            <footer >
              <p className='left-align'>Total</p>
              <p className='right-align'>{fixedFloatAndCommas(data.totalBalance.income)}</p>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
