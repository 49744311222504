import React from 'react';
import { Cell, Grid } from '../../../components/BillingMDC';
import BillingTextField from '../../../components/TextField/BillingTextField';

const SummaryView = ({ data, config ,editInput,handleInputChange,mainNarration }) => {
  const summaryList = config.getSummaryViewList(data);
  return (
    <div className='print-invoice three-input-wrapper'>
      <Grid>
        {summaryList.map((item, index) => {
          return (
            <Cell col={3}>
              <BillingTextField
                param='mainNarration'
                floatingLabel={item.title}
                value={editInput ? mainNarration : item.value}
                className={`right-side-gap  ${item.className} ${!editInput && 'no-pointer-events'}`}
                handleChange={editInput ? handleInputChange: () => null}
              />
            </Cell>
          );
        })}
      </Grid>
    </div>
  );
};

export default SummaryView;
