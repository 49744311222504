import { compose } from 'recompose';
import apiInterceptor from '../../../api/interceptor';
import FiscalYearMigration from './FiscalYearMigration';
import { fiscalYearApi, ledgerApi } from '../../common/base.api';

const composedFYM = compose(apiInterceptor({ ...fiscalYearApi, getGroupList: ledgerApi.getAccountGroupList }))(
  FiscalYearMigration,
);

export default composedFYM;
