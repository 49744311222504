import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Textfield,
  Fab,
  Grid,
  Cell,
  Menu,
  MenuAnchor,
  MenuItem,
} from 'react-mdc-web';
import cloneDeep from 'lodash/cloneDeep';
import * as httpUtils from '../../../utils/httpUtils';
import Pagination from '../../../components/Pagination';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import DateToRender from '../../../components/DateToRender';
import TableHeader from '../../../components/TableHeader';
import { downloadFormat, salesVatReportConfig, SalesVatReportTableBody } from './SalesVatReportConfig';
import ReportHeader from '../../../components/PrintComponent/ReportHeader';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { getFixTableMeasurements } from '../../../utils/tableMetrics';
import * as filterUtil from '../../../utils/filterUtil';
import * as appConstants from '../../../config';
import '../../../styles/printStyle.css';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import { REPORT_TYPE, SORTING } from '../../../data/enums/enums';
import * as downloadService from '../../../views/common/download.service';
import { fixedFloatAndCommas } from '../../../utils/conversion';

class SalesVatReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showMenu: false,
      totalData: 1,
      dataSet: [],
      dateClickCount: 0,
      businessUnitId: '',
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
        },
        filter: {},
      },
      nepaliDate: {
        start: '',
        end: '',
      },
      columnTotal: {
        totalSales: 0,
        taxExemptedSales: 0,
        exportSales: 0,
        discount: 0,
        totalTaxableAmount: 0,
        vat: 0,
      },
      dataList: [],
      printButtonClicked: false,
      headerContent: {
        headerLeft: [
          { title: '', value: '' },
          { title: 'Address', value: '' },
          { title: 'Phone', value: '' },
          {
            title: 'PAN No',
            value: '',
          },
        ],
        headerMiddle: [
          { id: 1, title: 'heading', value: 'Sales Book' },
          {
            id: 2,
            title: 'sub-heading',
            value: 'Value Added Tax Ledger',
          },
          { id: 3, title: 'paragraph', value: { start: '', end: '' } },
          { id: 4, title: 'paragraph', value: { start: '', end: '' } },
        ],
        headerRight: [{ title: 'Date', value: '' }],
      },
      totalTaxableAmount: '',
    };
    this.dataListLength = 0;
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.getTableDataForGrid();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
    const table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
  }

  /** remove eventlistener on component unmount* */
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    const { company } = this.props;
    this.setState({ loading: true });
    const { queryParameters, headerContent } = this.state;
    queryParameters.date.start_date = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date.end_date = moment(queryParameters.date.end).format('YYYY-MM-DD');
    httpUtils
      .get(
        `${appConstants.baseUrl}report/sales-vat?start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          const nepaliDate = {
            start: response.data.nepali_date.start_date_nepali,
            end: response.data.nepali_date.end_date_nepali,
          };
          const amount = response.data.total_taxable_amount
            ? response.data.total_taxable_amount
            : response.data.total_taxable_amount;
          const columnTotal = {};
          columnTotal.totalTaxableAmount = response.data.total_taxable_amount ? response.data.total_taxable_amount : 0;
          columnTotal.totalSales = response.data.total_sales ? response.data.total_sales : 0;
          columnTotal.taxExemptedSales = response.data.total_tax_exempted_sales
            ? response.data.total_tax_exempted_sales
            : 0;
          columnTotal.exportSales = response.data.total_export_sales ? response.data.total_export_sales : 0;
          columnTotal.discount = response.data.total_discount ? response.data.total_discount : 0;
          columnTotal.vat = response.data.total_taxable_vat ? response.data.total_taxable_vat : 0;
          columnTotal.excise = response.total_excise? response.total_excise : 0;
          headerContent.headerLeft = [
            { title: '', value: company.title },
            { title: 'Address', value: company.address },
            { title: 'Phone', value: company.phone },
            { title: 'PAN No', value: company.panNumber },
          ];
          headerContent.headerMiddle = [
            { id: 1, title: 'heading', value: 'Sales Book' },
            {
              id: 2,
              title: 'sub-heading',
              value: 'Value Added Tax Ledger',
            },
            {
              id: 3,
              title: 'paragraph',
              value: { start: this.state.queryParameters.date.start, end: this.state.queryParameters.date.end },
            },
            { id: 4, title: 'paragraph', value: { start: nepaliDate.start, end: nepaliDate.end } },
          ];
          headerContent.headerRight = [{ title: 'Date', value: this.state.queryParameters.date.end }];
          const data = response.data.list;
          self.setState(
            {
              dataSet: data,
              totalData: response.data.total,
              loading: false,
              nepaliDate,
              totalTaxableAmount: amount,
              columnTotal,
              headerContent,
            },
            () => {
              self.groupPrintDataset();
            },
          );
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters }, () => this.getTableDataForGrid());
  };

  /** on Date Range Change */
  handleDateRangeChange = (start, end) => {
    const queryParameters = { ...this.state.queryParameters };
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    const sorting = { ...this.state.sorting };
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting });
  };

  handleDownloadClick = id => {
    let type = '';
    const { user } = this.props;
    const { queryParameters } = this.state;
    queryParameters.date.start_date = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date.end_date = moment(queryParameters.date.end).format('YYYY-MM-DD');
    if (id === 1) {
      type = REPORT_TYPE.PDF;
    } else if (id === 2) {
      type = REPORT_TYPE.CSV;
    } else if (id === 3) {
      type = REPORT_TYPE.XLS;
    } else if (id === 5) {
      type = REPORT_TYPE.CSV;
    } else {
      type = REPORT_TYPE.XML;
    }
    // const d = 'qa.billing.rosia.one';
    httpUtils
      .get(
        `${appConstants.baseUrl}downloads?domain=${id === 5 ? 'net_' : ''}sales_vat&user_id=${
          user.idUsers
        }&start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&report_type=${type}`,
      )
      .then(response => downloadService.resolver(response.data))
      .catch(error => {
        console.error(error);
      });
  };

  getSortedDataSet = (labelName, sortingOrder) => {
    const { dataSet } = this.state;
    if (sortingOrder === SORTING.NO_SORT) {
      return dataSet;
    }
    return orderBy(dataSet, labelName, sortingOrder);
  };

  handlePrintClick = () => {
    const printButtonStatus = this.state.printButtonClicked;
    const self = this;
    this.setState({ printButtonClicked: true }, () => {
      window.print();
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false });
    };
  };

  groupPrintDataset = () => {
    const { dataSet } = this.state;
    let chunk = 11;
    const dataList = [];
    let arrayList = [];
    let count = 0;
    arrayList = cloneDeep(dataSet);
    const lastArrayIndex = dataSet.length ? Math.ceil(dataSet.length / chunk) : 0;
    const listLeftForLastPage = dataSet.length ? dataSet.length % chunk : 0;
    arrayList.forEach((item, index) => {
      item.index = index;
      arrayList[index] = item;
    });
    for (let index = 0, j = arrayList.length; index < j; index += chunk) {
      count++;
      let chunkArray = [];
      const d = lastArrayIndex - 1;
      if (dataList.length >= d) {
        if (dataList.length === d && listLeftForLastPage > 11) {
          chunk = 11;
          chunkArray = arrayList.slice(index, index + chunk);
          chunkArray.footer = false;
          chunkArray.page = count;
        } else {
          chunkArray = arrayList.slice(index, index + chunk);
          chunkArray.footer = true;
          chunkArray.page = count;
        }
      } else {
        chunk = 11;
        chunkArray = arrayList.slice(index, index + chunk);
        chunkArray.footer = false;
        chunkArray.page = count;
      }
      dataList.push(chunkArray);
    }
    this.dataListLength = dataList.length;
    this.setState({ dataList }, () => {
      // this.printInvoice();
    });
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  render() {
    const { loading, queryParameters, totalData, sorting, columnTotal, dataList, nepaliDate } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    const { company } = this.props;

    return (
      <div className="srn-vat-report pad-b-24">
        <div className="card-body">
          <div>
            {loading && (
              <div className="linear-progress-wrapper temp-progress-wrapper">
                <LinearProgress accent indeterminate />
              </div>
            )}
            <div className={this.state.printButtonClicked ? 'no-print' : 'display-block'}>
              <div className={loading ? 'clickable-false' : ''}>
                <div className="header-bar" ref="tableReference">
                  <Grid>
                    <Cell col={4}>
                      <div className="header-title">{company.title}</div>
                      <span className="header-title-content">
                        <span className="active-opacity-text">Address: </span>
                        <span>{company.address}</span>
                      </span>
                      <span className="header-title-content">
                        <span className="active-opacity-text">Phone: </span>
                        <span>{company.phone}</span>
                      </span>
                      <span className="header-title-content">
                        <span className="active-opacity-text">PAN No:</span>
                        <span>{company.panNumber}</span>
                      </span>
                    </Cell>
                    <Cell col={4}>
                      <div className="center-align">
                        <h2>Sales Book</h2>
                        <div className="default-margin-top-12">
                          <h4>Value Added Tax Ledger</h4>
                          <span className="header-title-content no-margin-bottom">
                            <span className="active-opacity-text">
                              Report from
                              <span className="date-rendered">
                                <DateToRender date={queryParameters.date} />
                              </span>
                            </span>
                          </span>
                          <span className="header-title-content">
                            <span className="active-opacity-text">
                              Report from
                              <span className="date-rendered">
                                <span> {nepaliDate.start}</span>
                                <span> - {nepaliDate.end}</span>
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </Cell>
                    <Cell col={4}>
                      <div className="header-menu-right">
                        <span
                          className="download-menu"
                          onClick={() => this.setState({ showMenu: !this.state.showMenu })}
                        >
                          <Icon name="get_app" />
                        </span>
                        <MenuAnchor>
                          <Menu
                            right
                            open={this.state.showMenu}
                            onClose={() => {
                              this.setState({ showMenu: false });
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                this.handleDownloadClick(downloadFormat[0].id);
                              }}
                            >
                              {downloadFormat[0].value}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleDownloadClick(downloadFormat[1].id);
                              }}
                            >
                              {downloadFormat[1].value}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleDownloadClick(downloadFormat[2].id);
                              }}
                            >
                              {downloadFormat[2].value}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                this.handleDownloadClick(downloadFormat[4].id);
                              }}
                            >
                              {downloadFormat[4].value}
                            </MenuItem>
                            {/* <MenuItem onClick={ () => {this.handleDownloadClick(downloadFormat[3].id)}}>{downloadFormat[3].value}</MenuItem> */}
                          </Menu>
                        </MenuAnchor>
                        <span className="download-menu" onClick={() => this.handlePrintClick()}>
                          <Icon name="print" />
                        </span>
                        <span onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                          <Icon name="filter_list" />
                        </span>
                      </div>
                      <div className="header-menu-right">
                        <span className="header-right-content">
                          <span className="">Date: </span>
                          <span>{this.state.queryParameters.date.end}</span>
                        </span>
                      </div>
                    </Cell>
                  </Grid>
                </div>
              </div>

              <div ref="fixedTableBody" className="fixed-table-wrapper end-list-table">
                <table className="svr-table">
                  <thead className="table-border-bottom">
                    {salesVatReportConfig.upperHeaderDetails.map((data, key) => {
                      if (key === 0) {
                        return (
                          <th className={data.className} colSpan={8}>
                            {data.title}
                          </th>
                        );
                      }
                      return (
                        <th className={data.className} colSpan={2}>
                          {data.title}
                        </th>
                      );
                    })}
                  </thead>
                  {/* <thead>
                {
                  salesVatReportConfig.headerDetails.map((data,key) => <th className={data.className}>
                  {data.title}
                </th>)
                }

              </thead> */}

                  <TableHeader
                    headerDetails={salesVatReportConfig.headerDetails}
                    filterHeaderLabel={false}
                    handleSorting={this.handleTableSorting}
                  />
                  {sortedDataSet.map((data, key) => (
                    <tbody>
                      <SalesVatReportTableBody data={data} />
                    </tbody>
                  ))}
                  <tfoot className="right-content-footer">
                    <tr>
                      <td colSpan="3" />
                      <td>Total</td>
                      <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.totalSales)}</td>
                      <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.taxExemptedSales)}</td>
                      <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.exportSales)}</td>
                      <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.discount)}</td>
                      <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.totalTaxableAmount)}</td>
                      <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.vat)}</td>
                    </tr>
                  </tfoot>
                  {/* <tfoot className='right-content-footer'>
                <tr>
                  <td colSpan="7"></td>
                  <td className='right-align'>Total Amount</td>
                  <td className='font-bold right-align'>{this.state.totalTaxableAmount}</td>
                </tr>
              </tfoot> */}
                </table>
              </div>
              {
                <div ref="fixedTableFooter">
                  <Pagination
                    pageSize={queryParameters.pagination.limit}
                    currentPage={queryParameters.pagination.page}
                    orientation="top"
                    totalItems={totalData}
                    onPageSelect={this.onPageSelect}
                  />
                </div>
              }
            </div>
            <div className={this.state.printButtonClicked ? 'display-block' : 'no-print'}>
              {dataList.map((data, key) => (
                <div className="print-body zoom-reduce">
                  <div className="pdf-body card-body" ref="t">
                    <ReportHeader divClassName="first-header-bar" headerContent={this.state.headerContent} />
                    <div
                      ref="fixedTableBody"
                      className={` fixed-table-wrapper ${
                        !data.footer ? 'report-table-wrapper ' : 'report-table-footer'
                      }`}
                    >
                      <table className="pdf-table">
                        {/* <thead className="table-border-bottom">
                        {SRNVatReportConfig.upperHeaderDetails.map((headerData, key) => {
                          if(key === 0){return(<th className={headerData.className} colSpan={10}>
                            {headerData.title}
                          </th>)}
                          else {
                            return(<th className={headerData.className} colSpan={2}>
                              {headerData.title}
                            </th>)
                          }
                        })
                        }
                        </thead> */}
                        <TableHeader
                          headerDetails={salesVatReportConfig.headerDetails}
                          filterHeaderLabel={false}
                          handleSorting={this.handleTableSorting}
                        />
                        {data.map((salesData, keyTest) => (
                          <tbody>
                            <SalesVatReportTableBody data={salesData} />
                          </tbody>
                        ))}
                        {data.footer && (
                          <tfoot className="">
                            <tr>
                              <td colSpan="3" />
                              <td>Total</td>
                              <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.totalSales)}</td>
                              <td className="font-bold right-align">
                                {fixedFloatAndCommas(columnTotal.taxExemptedSales)}
                              </td>
                              <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.exportSales)}</td>
                              <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.discount)}</td>
                              <td className="font-bold right-align">
                                {fixedFloatAndCommas(columnTotal.totalTaxableAmount)}
                              </td>
                              <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.vat)}</td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="footer-block">
                    <PageNumber value={data.page} totalPage={this.dataListLength} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div dir="rtl">
            <Drawer
              open={this.state.showFilter}
              className="right-drawer"
              onClose={() => {
                this.setState({ showFilter: false });
              }}
            >
              <div className="filter-wrapper">
                <h2 className="default-horizontal-padding-24">
                  <span>Filter</span>
                  <span className="float-right">
                    <Icon name="replay" onClick={() => this.resetFilter()} />
                    <Icon name="clear" onClick={() => this.setState({ showFilter: false })} />
                  </span>
                </h2>
                <div className="divider" />
                <DateRangePicker
                  startDate={moment(queryParameters.date.start)}
                  endDate={moment(queryParameters.date.end)}
                  onChange={this.handleDateRangeChange}
                />
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    );
  }
}

SalesVatReport.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
});
const salesVatReport = connect(mapStateToProps)(SalesVatReport);

export default salesVatReport;
