import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'react-mdc-web';
import moment from 'moment';

const PromotionTableBody = ({ data, onSkuButtonClick }) => {
  return (
    <tr>
      <td className='left-align'>{data.idPromotions}</td>
      <td className='left-align'>{data.businessCompany.businessAlias}</td>
      <td className='left-align'>{data.promotionTitle}</td>
      <td className='right-align'>
        {data.promotionCriteriaType}/{data.promotionCriteriaValue}
      </td>
      <td className='right-align'>
        {data.promotionRuleType}/{data.promotionRuleValue}
      </td>
      <td className='right-align'>{moment(data.promotionStartDate).format('ll')}</td>
      <td className='right-align'>{moment(data.promotionEndDate).format('ll')}</td>
      <td className='center-align'>
        <Button onClick={() => onSkuButtonClick(data)}>
          <Icon name='launch' />
        </Button>
      </td>
    </tr>
  );
};

PromotionTableBody.propTypes = { data: PropTypes.object.isRequired, };

const promotionConfig = {
  headerDetails: [
    { id: 1, label: 'idPromotions', title: 'Promotion ID', className: 'cursor-pointer left-align', sortable: true },
    { id: 2, label: 'businessUnit', title: 'BU', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'title', title: 'Promotion', className: 'cursor-pointer left-align', sortable: true },
    {
      id: 4,
      label: 'promotionCriteriaType',
      title: 'Promotion (Criteria/Value)',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 5,
      label: 'promotionRuleType',
      title: 'Promotion (Rule/Value)',
      className: 'cursor-pointer right-align',
      sortable: false,
    },
    {
      id: 6,
      label: 'promotionStartDate',
      title: 'Start Date',
      className: 'cursor-pointer right-align',
      sortable: false,
    },
    { id: 7, label: 'promotionEndDate', title: 'End Date', className: 'cursor-pointer right-align', sortable: false },
    { id: 8, label: 'assignSku', title: 'Assign SKU', className: 'cursor-pointer center-align', sortable: false },
  ],
};

const message = {
  success: 'Promotion uploaded successfully.',
  error: '!Error while uploading Promotion',
  skuSuccess: 'SKU successfully assigned',
  skuError: '!Error while assigning SKU',
  downloadError: '!Error while downloading sample template',
};

const downloadFormat = [{ id: 1, value: 'Download Promotion upload template' }];

export {
  downloadFormat, message, promotionConfig, PromotionTableBody
};
