import React from 'react';
import TrialBalanceChildView from './childView';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import AccordionTable from '../../../components/CollapsibleTable/AccordionTable';
import { accountReportViewOptions } from '../../common/DrawerFilter/config';

const TableBody = ({ data, onRowClick, index }) => (
  <>
    <td className='left-align' onClick={() => onRowClick(data.list, index)}>
      <span>{data.name}</span>
    </td>
    <td className='right-align' onClick={() => onRowClick(data.list, index)}>
      {fixedFloatAndCommas(data.openingBalanceDebit)}
    </td>
    <td className='right-align' onClick={() => onRowClick(data.list, index)}>
      {fixedFloatAndCommas(data.openingBalanceCredit)}
    </td>
    <td className='right-align' onClick={() => onRowClick(data.list, index)}>
      {fixedFloatAndCommas(data.periodBalanceDebit)}
    </td>
    <td className='right-align' onClick={() => onRowClick(data.list, index)}>
      {fixedFloatAndCommas(data.periodBalanceCredit)}
    </td>
    <td className='right-align' onClick={() => onRowClick(data.list, index)}>
      {fixedFloatAndCommas(data.debit)}
    </td>
    <td className='right-align' onClick={() => onRowClick(data.list, index)}>
      {fixedFloatAndCommas(data.credit)}
    </td>
  </>
);

const AccountGroupTableBody = ({ data }) => (
  <>
    <td className='left-align'>
      <span>{data.name}</span>
    </td>
    <td className='right-align'>{fixedFloatAndCommas(data.agObDebit)}</td>
    <td className='right-align'>{fixedFloatAndCommas(data.agObCredit)}</td>
    <td className='right-align'>{fixedFloatAndCommas(data.periodBalanceDebit)}</td>
    <td className='right-align'>{fixedFloatAndCommas(data.periodBalanceCredit)}</td>
    <td className='right-align'>{fixedFloatAndCommas(data.agDebit)}</td>
    <td className='right-align'>{fixedFloatAndCommas(data.agCredit)}</td>
  </>
);

const TrialBalanceBody = ({ dataList, totalBalance, onRowClick, onTableClickData, activeView, totalGroupBalance }) => (
  <>
    <tbody>
      {activeView === accountReportViewOptions[0].label ? (
        <AccordionTable>
          {dataList.map((data, index) => (
            <tr
              key={data.accountGroupId}
              trigger={TableBody({
                data,
                onRowClick,
                index,
              })}
            >
              {TrialBalanceChildView({ data: onTableClickData })}
            </tr>
          ))}
        </AccordionTable>
      ) : (
        dataList.map((data) => (
          <tr className='collaspsible-disabled'>
            {AccountGroupTableBody({
              data,
              onRowClick: () => null,
            })}
          </tr>
        ))
      )}
    </tbody>
    <tfoot>
      {
        <tr>
          <td className='left-align'>Grand Total</td>
          <td />
          <td />
          <td />
          <td />
          <td className='right-align'>
            {activeView === accountReportViewOptions[0].label
              ? fixedFloatAndCommas(totalBalance.drBalance)
              : fixedFloatAndCommas(totalGroupBalance.agDrBalance)}
          </td>
          <td className='right-align'>
            {activeView === accountReportViewOptions[0].label
              ? fixedFloatAndCommas(totalBalance.crBalance)
              : fixedFloatAndCommas(totalGroupBalance.agCrBalance)}
          </td>
        </tr>
      }
    </tfoot>
  </>
);

const trialBalanceConfig = [
  { id: 1, label: 'name', title: '', className: 'left-align' },
  { id: 2, label: 'debit', title: 'Debit', className: 'right-align' },
  { id: 3, label: 'credit', title: 'Credit', className: 'right-align' },
  { id: 4, label: 'period-debit', title: 'Debit', className: 'right-align' },
  { id: 5, label: 'period-credit', title: 'Credit', className: 'right-align' },
  { id: 6, label: 'open-debit', title: 'Debit', className: 'right-align' },
  { id: 7, label: 'open-credit', title: 'Credit', className: 'right-align' },
];

const config = {
  header: trialBalanceConfig,
  getTableBody: TrialBalanceBody,
};

export default config;
