import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import Icon from 'react-mdc-web/lib/Icon';
import BillingSelectStyled from '../Select/BillingSelectStyled';

const propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  emptyField: PropTypes.bool,
  multipleParam: PropTypes.bool,
  value: PropTypes.string,
  param: PropTypes.string,
  valueKey: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  loadOptions: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
};

const defaultProps = {
  required: false,
  disabled: false,
  emptyField: false,
  multipleParam: false,
  value: '',
  param: '',
  valueKey: '',
  className: '',
  placeholder: '',
  errorMessage: 'Should not be empty',
  options: [],
  getOptionLabel: () => null,
  getOptionValue: () => null,
  loadOptions: () => null,
};

class BillingAsyncSelect extends Component {
  onChange = e => {
    const { param, multipleParam, handleChange, valueKey } = this.props;
    const value = e[valueKey];
    handleChange(param, value, multipleParam ? e : '');
  };

  render() {
    const {
      required,
      disabled,
      emptyField,
      value,
      className,
      placeholder,
      errorMessage,
      options,
      loadOptions,
      getOptionValue,
      getOptionLabel,
      defaultOptions,
    } = this.props;
    const DropdownIndicator = props => (
      <components.DropdownIndicator {...props}>
        <Icon className="no-margin" name="arrow_drop_down" />
      </components.DropdownIndicator>
    );
    return (
      <BillingSelectStyled>
        <AsyncSelect
          value={value}
          placeholder={placeholder}
          options={options}
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          onChange={this.onChange}
          isDisabled={disabled}
          components={{ DropdownIndicator }}
          classNamePrefix="zindex-2"
          className={`${required && emptyField && !value ? 'select-css invalid' : 'select-css'} ${className}`}
          getOptionValue={element => getOptionValue(element)}
          getOptionLabel={element => getOptionLabel(element)}
        />
        {required && emptyField  && value.length === 0 ? <span className="error-message">{errorMessage}</span> : ''}
      </BillingSelectStyled>
    );
  }
}

BillingAsyncSelect.defaultProps = defaultProps;

BillingAsyncSelect.propTypes = propTypes;

export default BillingAsyncSelect;
