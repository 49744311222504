import { compose } from 'recompose';

import MaterializedView from './MaterializedView';

import apiInterceptor from '../../../../api/interceptor';

import { salesMVApi } from '../../../common/base.api';

const composedMaterializedView = compose(apiInterceptor({ ...salesMVApi }))(MaterializedView);

export default composedMaterializedView;
