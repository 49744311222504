import { connect } from 'react-redux';
import React, { Component } from 'react';
import tableConfig from './config';
import LedgerWiseStyled from './LedgerWiseStyled';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { LEDGER_WISE_CONFIG } from '../../common/domain.config';
import { TAG_WISE_DETAILS } from '../../../data/enums/Route';
import { BillingSnackBar } from '../../../components/BillingMDC';
import history from '../../../utils/history';
import {tagDetails} from "../../../actions/customerDetail";

class LedgerWise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
      },
      data: {
        list: [],
        total: 0,
      },
    };

    const { downloadList, sampledownloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }
  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getLedgerWiseTag } = this.props;
    const { queryParameters } = this.state;
    getLedgerWiseTag(queryParameters)
      .then(response => {
        const { data } = this.state;
        data.list = response.list;
        data.total = response.total;
        this.setState({ data });
      })
      .catch(err => {
        console.log(err);
      });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleRowClick = data => {
    this.setState({
      ledgerWiseDetail: data,
    });
  };
  handleSpanClick = data => {
    const { storeTagWise } = this.props;
    const { queryParameters } = this.state;
    this.setState({
        tagWiseDetail: data,
    });
    storeTagWise(tagDetails({ date: queryParameters.date }));
    history.push(`/${TAG_WISE_DETAILS}/${data.tagid}`, { title: data.tag.title });
};

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  render() {
    const {
      queryParameters,
      data,
      display,
      ledgerWiseDetail,
      snack,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <LedgerWiseStyled>
        <div className="ledger-wise">
          <PageView
            domainConfig={LEDGER_WISE_CONFIG}
            headerConfig={{
              search: true,
              date: true,
              upload: false,
              download: false,
              filter: true,
              create: false,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            resetFilter={this.basePaginationService.resetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.basePaginationService.handleDownloadClick}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            data={data}
            onTableClickData={ledgerWiseDetail}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.handleRowClick}
            onTableSpanClick={this.handleSpanClick}
            onPageSelect={this.basePaginationService.onPageSelect}
          />
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </div>
      </LedgerWiseStyled>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeTagWise: action => dispatch(action),
  };
}

const LedgerWiseWithState = withBaseState(LedgerWise);

export default connect(null, mapDispatchToProps)(LedgerWiseWithState);
