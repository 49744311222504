import '../../../styles/printStyle.css';
import React, { Component } from 'react';
import { config } from './VatReportConfig';
import { DOMAIN } from '../../../data/enums/enums';
import IrdView from '../../common/irdReport/IrdView';
import withBaseState from '../../common/withBaseState';
import PropTypes from '../../../../node_modules/prop-types';
import * as queryService from '../../common/query.service';
import * as downloadService from '../../common/download.service';
import { VAT_REPORT_CONFIG } from '../../common/domain.config';
import { defaultHeaderConfig } from '../../common/irdReport/config';
import { clone } from '../../../utils/arrayProcessor';
import VatReportStyle from '../NewSRNVatReport/VatReportStyle';
import { groupPrintDataSet } from '../../common/print.service';
import getPrintData from './getPrintData';
import getConfigData from './getConfigData';
import SimplePrint from '../../../components/PrintComponent/simplePrint/simplePrint';

class VatReport extends Component {
  constructor(props) {
    super(props);
    this.box = React.createRef();
    this.state = {
      salesTotalData: '',
      purchaseTotalData: '',
      salesDataSet: [],
      purchaseDataSet: [],
      dataSet: [],
      dataList: [],
      display: {
        searchBox: false,
        filter: false,
      },
      printButtonClicked: false,
      queryParameters: { ...queryService.queryParameters },
      loading: false,
    };

    this.dataListLength = 0;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getTableDataForGrid,
      this.downloadList,
    );
    this.basePaginationService.resetFilter();
  }
  componentDidMount() {
    this.getTableDataForGrid();
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  /** remove eventlistener on component unmount* */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleOutsideClick = event => {
    if (this.box && !this.box.current.contains(event.target)) {
      this.state.printButtonClicked === true && this.setState({ printButtonClicked: false });
    }
  };
  mergeData = (print = false) => {
    const { purchaseDataSet, salesDataSet, purchaseTotalData, salesTotalData } = this.state;
    const max = purchaseDataSet.length > salesDataSet.length;
    const totalData = max ? purchaseTotalData : salesTotalData;
    const arrayToLoop = max ? purchaseDataSet : salesDataSet;
    const mergedDataSet = arrayToLoop.map((data, key) => {
      const trnobj = {
        sales: salesDataSet[key] || null,
        purchase: purchaseDataSet[key] || null,
      };
      const transaction = { transaction: trnobj };
      return transaction;
    });
    const formattedList = this.flattenDataPrintAttributeWise(
      mergedDataSet,
      config.lengthyAttributesTd,
      config.getDataList,
    );
    this.setState({ dataSet: formattedList, totalData }, () => {
      print && this.groupPrintDataSetNew();
    });
  };

  flattenDataPrintAttributeWise = (list, lengthyAttributesTd, getDataList) => {
    const formattedList = list.map((d, i) => {
      lengthyAttributesTd.forEach(l => {
        d[l] = getDataList(d, l, i);
      });
      return d;
    });

    return formattedList;
  };

  getTableDataForGrid = async (print = false) => {
    const self = this;
    const { getList } = this.props;
    const { queryParameters, totalData } = this.state;
    let query = clone(queryParameters);
    query.pagination.limit = 2000;
    query.pagination.page=1;
    await getList(print ? query : queryParameters)
      .then(response => {
        const nepaliDate = {
          start: response.nepali_date.start_date_nepali,
          end: response.nepali_date.end_date_nepali,
        };
        self.setState(
          {
            totalData: Number(response.sales_report_total + response.purchase_report_total),
            nepaliDate,
            purchaseDataSet: response.purchase_report,
            purchaseTotalData: response.purchase_report_total,
            salesDataSet: response.sales_report,
            salesTotalData: response.sales_report_total,
            loading: false,
          },
          () => self.mergeData(print),
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleDownloadClick = (type, index) => {
    const { user, downloadList } = this.props;
    const { queryParameters } = this.state;
    const domain = DOMAIN.VAT_REPORT;
    downloadList({
      type,
      query: queryParameters,
      extraString: `&user_id=${user.idUsers}`,
      domain,
    }).then(response => downloadService.resolver(response));
  };

  handlePrintClick = async () => {
    await this.getTableDataForGrid(true);
    const self = this;
    self.setState({ printButtonClicked: true });
  };

  groupPrintDataSetNew() {
    const { dataSet } = this.state;
    const orders = clone(dataSet);
    const { company } = this.props;
    const printInfoBill = { ...company, printInfo: { ...company.printInfo, batchEnabled: false } } || {};
    const dataListGrouped = groupPrintDataSet(orders, printInfoBill, config, false, true);
    this.setState({ dataList: dataListGrouped });
  }

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;

    this.setState(display);
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };
  render() {
    const { dataSet, display, printButtonClicked, loading, queryParameters, totalData, dataList, nepaliDate } =
      this.state;
    const { company, serverResponseWaiting } = this.props;

    const middleHeaderData = {
      date: {
        reportPeriod: true,
        value: queryParameters?.date,
      },
      title: VAT_REPORT_CONFIG?.title,
    };

    const printData = getPrintData([...dataSet], company, middleHeaderData);
    const configData = getConfigData(printData?.tableFooterData);

    return (
      <VatReportStyle>
        <div className="vat">
          <IrdView
            company={company}
            dataSet={dataSet}
            display={display}
            printList={dataList}
            tableConfig={config}
            totalData={totalData}
            nepaliDate={nepaliDate}
            // printFlag={printButtonClicked}
            loading={serverResponseWaiting}
            queryParameters={queryParameters}
            controlDisplay={this.controlDisplay}
            domainConfig={VAT_REPORT_CONFIG}
            handlePrintClick={this.handlePrintClick}
            printReference={this.box}
            handleDownloadClick={this.handleDownloadClick}
            resetFilter={this.basePaginationService.resetFilter}
            headerConfig={{ ...defaultHeaderConfig, print: true }}
            handlePageSelect={this.basePaginationService.onPageSelect}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            colSpan_t_l={7}
            colSpan_t_r={7}
            disableUpperHeaderDetails={false}
            enableTableFooter={false}
            printFooterEnable={false}
          />
        </div>
        {printButtonClicked && (
          <div className="display-block portrait-type">
            <SimplePrint configData={configData} printData={printData} orientation={'landscape'} />
          </div>
        )}
      </VatReportStyle>
    );
  }
}

VatReport.contextTypes = {
  router: PropTypes.object,
};

const CustomerWithState = withBaseState(VatReport);
export default CustomerWithState;
