import React, { Fragment } from 'react';
import { ACTION } from '../../../data/enums/enums';
import * as filterUtil from '../../../utils/filterUtil';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const getSKUData = (sku = {}) => {
  const details = { businessId: sku.businessId || '', };

  return details;
};

const getBatchData = (batch = {}) => {
  const details = {
    businessId: batch.businessId || '',
    businessSkuId: batch.businessSkuId || '',
    title: batch.title,
    dlp: batch.dlp,
    mfgDate: filterUtil.getCurrentDay(),
    expiryDate: filterUtil.getCurrentDay(),
  };

  return details;
};

const getApiTransformedBatchData = (batch) => ({
  business_id: batch.businessId,
  business_sku_id: batch.businessSkuId,
  title: batch.title,
  dlp: batch.dlp,
  mfg_date: batch.mfgDate,
  expiry_date: batch.expiryDate,
});

const getApiTransformedData = (sku, action = ACTION.ADD) => {
  const details = { business_id: sku.businessId || '', };

  if (action === ACTION.ADD) {
    // TODO
  }

  return details;
};

const skuValidationFields = [
  { title: 'businessId', },
];

const batchFormValidationFields = [
  { title: 'businessId', },
  { title: 'businessSkuId', },
  { title: 'title', },
  { title: 'dlp', },
  { title: 'mfgDate', },
  { title: 'expiryDate', },
];

const SKUTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <>
        <tr onClick={() => onRowClick(data)}>
          <td className='left-align'>{data.businessSkuId}</td>
          <td className='left-align'>{data.title}</td>
          <td className='left-align'>{data.businessCompany ? data.businessCompany.businessAlias : ''}</td>
          {/* <td>{data.batchList[0].title}</td> */}
          <td>{fixedFloatAndCommas(data.rlp)}</td>
          {/* <td>{fixedFloatAndCommas(data.batchList[0].dlp)}</td> */}
        </tr>
      </>
    ))}
  </tbody>
);

const customerLedgerConfig = [
  {
    id: 1,
    label: 'business_sku_id',
    title: 'ID',
    className: 'cursor-pointer left-align',
    sortable: true,
  },
  {
    id: 2,
    label: 'skus.title',
    title: 'SKU',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 3,
    label: 'businessCompany.businessAlias',
    title: 'BU',
    className: 'cursor-pointer',
    sortable: true,
  },
  // {
  //   id: 4,
  //   label: 'sku_batch_price.title',
  //   title: 'Batch',
  //   className: 'cursor-pointer',
  //   sortable: true,
  // },
  {
    id: 5,
    label: 'rlp',
    title: 'RLP',
    className: 'cursor-pointer',
    sortable: true,
  },
  // {
  //   id: 6,
  //   label: 'dlp',
  //   title: 'DLP',
  //   className: 'cursor-pointer',
  //   sortable: true,
  // },
];

const config = {
  header: customerLedgerConfig,
  getTableBody: SKUTableBody,
  getDetail: getSKUData,
  getBatchDetail: getBatchData,
  getApiTransformedData,
  getApiTransformedBatchData,
  skuValidationFields,
  batchFormValidationFields,
};

export default config;
