import React from 'react';
import PropTypes from 'prop-types';
import CreateBtnStyled from './CreateBtnStyled';
import { Icon, Fab } from '../../../../components/BillingMDC/';
const propTypes = {
  /**
   * @default null
   * children passed within button
   */
  children: PropTypes.node,
  /**
   * @default ''
   * children passed along with the button
   */
  btnDisplay: PropTypes.bool,
};

const defaultProps = {
  children: null,
  btnDisplay: true,
};

const CreateBtn = ({ createHandler, btnDisplay }) => {
  return (
    <CreateBtnStyled className={`${btnDisplay ? 'display-btn' : 'display-btn-none '}`}>
      <span>..End of list</span>
      <div className='create-fab-button'>
        <Fab onClick={() => createHandler()}>
          <Icon name='add' />
        </Fab>
      </div>
    </CreateBtnStyled>
  );
};

CreateBtn.propTypes = propTypes;

CreateBtn.defaultProps = defaultProps;

export default CreateBtn;
