import { compose } from 'recompose';

import Promotion from './Promotion';

import apiInterceptor from '../../../api/interceptor';

import { jvApi } from '../../common/base.api';

const composedPromotion = compose(apiInterceptor({ ...jvApi }))(Promotion);

export default composedPromotion;
