import styled from 'styled-components';

const PromotionDetailStyled = styled.div`
  .card-body {
    padding: 24px;
    .form-wrap {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
      h6 {
        color: rgba(0, 0, 0, 0.54);
        margin-bottom: 4px;
        font-weight: 500;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 24px;
      }
      .form-switchs,
      .form-inputs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 530px;
        width: 49%;
        margin-bottom: 24px;
        .label {
          flex: 1;
          font-size: 16px;
          margin-right: 16px;
        }
        .input {
          width: 328px;
          width: 62%;
          .mdc-textfield {
            margin: 0;
          }
          .mdc-textfield {
            height: unset;
          }
          label {
            display: none;
          }
          input {
            background: rgba(0, 0, 0, 0.087);
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
            padding: 0;
            border: 0;
            padding: 0 10px;
            font-size: 14px;
            color: hsl(0, 0%, 50%);
          }
          .select-css {
            border-bottom: none;
            max-width: 341px;
            margin-top: 5px;
            .zindex-2__control {
              max-height: 40px;
              background: rgba(0, 0, 0, 0.087);
              border-radius: 4px;
              line-height: 40px;

              .zindex-2__value-container {
                padding-left: 10px;
                height: 40px;
                > div {
                  margin: 0;
                  padding-bottom: 0;
                  padding-top: 0;
                }
              }

              .zindex-2__indicator-separator {
                background: rgba(0, 0, 0, 0.087);
              }
            }
          }
          .filter-dropdown-card {
            left: 0;
            min-width: 341px;
            .autocomplete-list {
              overflow-y: scroll;
              max-height: 200px;
              padding: 4px 0;
              > div {
                cursor: pointer;
                padding: 10px 16px;
                font-size: 14px;
              }
            }
          }
        }
        .suggested-autocomplete-list {
          display: flex;
          align-items: center;
          margin-top: 8px;
          .text {
            min-width: 320px;
            height: 40px;
            background: rgba(0, 0, 0, 0.087);
            border-radius: 4px;
            line-height: 40px;
            padding: 0 10px;
          }
          .material-icons {
            margin-left: 8px;
          }
        }
      }
      .condition-input {
        label {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.88);
        }
        input {
          background: rgba(0, 0, 0, 0.087);
          height: 40px;
          line-height: 40px;
          border-radius: 4px;
          padding: 0;
          border: 0;
          padding-left: 10px;
          padding-right: 2px;
        }
        .select-css {
          margin-top: 8px;
          border-bottom: none;
          max-width: 341px;
          .zindex-2__control {
            max-height: 40px;
            background: rgba(0, 0, 0, 0.087);
            border-radius: 4px;
            line-height: 40px;
            .zindex-2__value-container {
              padding-left: 10px;
              height: 40px;
              > div {
                margin: 0;
                padding-bottom: 0;
                padding-top: 0;
              }
            }
            .zindex-2__indicator-separator {
              background: rgba(0, 0, 0, 0.087);
            }
          }
        }
      }
    }
  }
  .mini-fab-button .mdc-fab--mini {
    height: 32px;
    width: 32px;
    line-height: 32px;
    position: unset;
    margin-left: 20px;
    margin-top: 12px;
    .material-icons {
      font-size: 16px;
      margin-top: 8px;
    }
  }
  .form-multi-select-wrap {
    .mini-fab-button .mdc-fab--mini {
      margin: 0;
      font-size: 24px;
    }
  }

  .btns {
    text-align: right;
    margin-top: 24px;
  }

  .date-range-picker-wrapper {
    float: unset;
    max-width: 333px;
    background: rgba(0, 0, 0, 0.087);
    height: 32px;
    border-radius: 4px;
    padding-top: 8px;
    padding-left: 8px;
    .default-vertical-padding-24 {
      padding: 0;
      padding-top: 4px;
    }
    .calendar-status {
      display: none;
    }
    .mdc-layout-grid__inner {
      display: flex;
      .date-picker-holder-active,
      .date-picker-holder {
        flex: 1;
        padding-left: 0;
        .calendar-date {
          border-bottom: none;
        }
        .calendar-icon {
          border: none;
        }
        .calendar-arrow-icon {
          position: absolute;
          right: 0;
          border-bottom: none;
        }
      }
    }
    #date-range-picker-id {
      display: unset;
      width: unset;
    }
  }

  @media only screen and (max-device-width: 767px) {
    .card-body .form-multi-select-wrap .form-multi-select {
      width: 100%;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .card-body .form-multi-select-wrap .form-multi-select {
      width: 100%;
    }
  }
  @media only screen and (min-width: 1824px) {
    .card-body .form-multi-select-wrap .form-multi-select {
      width: 42%;
    }
  }
  &.promotion_create {
    .batch-li {
      display: none !important;
    }
  }
  &.promotion_create,
  &.promotion_details {
    .su,
    .promotion_code,
    .psku {
      display: none !important;
    }
  }
`;
export default PromotionDetailStyled;
