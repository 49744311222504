import React from 'react';
import { fixedFloatAndCommas, roundAndCommas } from '../../../../utils/conversion';
import { formatToReadableDate  } from '../../../../utils/filterUtil';

const TagWiseDetailBody = ({ dataList,columnTotal }) => (
  <tbody>
    {dataList.map((data, index) => {
      return (
        <tr>
          <td className='left-align document-date'>
            {data.documentDate ? formatToReadableDate(data.documentDate) : null}
          </td>
          <td className='left-align customer'>{data.voucherNumber}</td>
          <td className='left-align voucher'>{data.customer && data.customer.title}</td>
          <td className='left-align created-date print_display_none'>{data.createdAt ? formatToReadableDate(data.createdAt) : null}</td>
          <td className='left-align miti'>{data.miti}</td>
          <td className='left-align narration'>{data.narration}</td>
          <td className='right-align'>{data.debit ? fixedFloatAndCommas(data.debit) : 0}</td>
          <td className='right-align'>{data.credit ? fixedFloatAndCommas(data.credit) : 0}</td>
          <td className='right-align'>{data.balance ? roundAndCommas(Math.abs(data.balance)) : 0}</td>
          <td className='right-align'>{data.balanceType}</td>
        </tr>
      );
    })}
    <tr>
      <td colSpan='5' />
      <td className='left-align font-bold pad-l-10'>
    Total
      </td>
      <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.debit)}</td>
      <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.credit)}</td>
      <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.balance)}</td>
      <td className='font-bold right-align'>{columnTotal.balanceType}</td>
    </tr>
  </tbody>
);

const TagWiseDetailConfig = [
  {
    id: 1,
    label: 'date',
    title: 'Date',
    className: 'cursor-pointer document-date',
    sortable: false,
  },
  {
    id: 10,
    label: 'voucherNumber',
    title: 'Voucher Number',
    className: 'cursor-pointer ',
    sortable: false,
  },
  {
    id: 10,
    label: 'customer',
    title: 'Ledger Name',
    className: 'cursor-pointer ',
    sortable: false,
  },
  {
    id: 2,
    label: 'createdAt',
    title: 'Created Date',
    className: 'cursor-pointer created-date print_display_none',
    sortable: false,
  },
  {
    id: 3,
    label: 'miti',
    title: 'Miti',
    className: 'cursor-pointer miti',
    sortable: false,
  },
  {
    id: 5,
    label: 'narration',
    title: 'Narration',
    className: 'left-align narration',
    sortable: false,
  },
  {
    id: 6,
    label: 'debit',
    title: 'Debit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'credit',
    title: 'Credit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 8,
    label: 'balance',
    title: 'Balance',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 9,
    label: 'dr',
    title: '',
    className: 'right-align',
    sortable: false,
  },
];
const config = {
  header: TagWiseDetailConfig,
  getTableBody: TagWiseDetailBody,
};

export default config;
