import { compose } from 'recompose';

import TrialBalance from './TrialBalance';

import apiInterceptor from '../../../api/interceptor';

import { trialBalanceApi } from '../../common/base.api';

const composedTrialBalance = compose(apiInterceptor({ ...trialBalanceApi }))(TrialBalance);

export default composedTrialBalance;
