import styled from 'styled-components';

const FYIStyled = styled.div`
  .select-css {
    .zindex-2__menu-list {
      max-height: 160px;
    }
    .zindex-2__value-container {
      padding: 3px 8px;
      padding-left: 0;
    }
  }
  .errorColor {
    border: 1px solid rgba(218, 20, 20, 0.4);
    background-color: #feefef;
    color: #da1414 !important;
  }
  .table__textIcon {
    background-color: var(--accent-color);
    box-shadow: 0 6px 6px 0 rgb(33 150 243 / 24%), 0 0 6px 0 rgb(33 150 243 / 12%);
    color: #fff;
    padding: 0.5em 0.9em;
    cursor: pointer;
  }
  .migrate_date {
    position: relative;
    span > .mdc-textfield {
      max-width: unset;
    }
    .material-icons {
      position: absolute;
      right: 0;
      bottom: 8px;
      cursor: unset;
    }
  }
  .confirm-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    i {
      position: relative;
      top: 2px;
    }
    .download-menu + .mdc-menu-anchor {
      .mdc-simple-menu {
        left: unset !important;
        right: 0;
        .mdc-list-item {
          height: unset;
          font-size: 13px;
        }
      }
    }
  }
  .fym {
    padding: 24px;
    border-top: 1px solid #eeeeee;
    &.table-wrap {
      caption {
        text-align: left;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      thead {
        th {
          background: #dff3ff;
          color: #2196f3;
          font-size: 12px;
          font-weight: 600;
          line-height: 40px;
        }
      }
      tbody {
        td {
          font-size: 12px;
          line-height: 40px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.88);
        }
      }
      tfoot {
        td {
          background: #f4f4f4;
          color: rgba(0, 0, 0, 0.64);
          font-weight: 600;
          font-size: 12px;
          line-height: 40px;
        }
      }
    }
  }
  .fym-dialog-body {
    max-height: 60vh;
    overflow: auto;
    padding: 0;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &:hover {
      &::-webkit-scrollbar-track {
        background: transparent;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--accent-color);
        height: 25vh;
      }
    }
    .stock-blnc-switch {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 24px;
      &.two {
        border-bottom: 0;
        padding-top: 0;
      }
      .label {
        display: block;
        font-size: 14px;
        flex-basis: 40%;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.87);
        margin-right: 24px;
      }
      .input {
        position: relative;
        top: 2px;
      }
    }
    .fym-error {
      margin: 8px 22px;
      border: 1px solid rgba(218, 20, 20, 0.4);
      border-radius: 4px;
      background-color: #feefef;
      padding: 18px 16px;
      display: flex;
      justify-content: space-between;
      i {
        color: #da1414;
        opacity: 1;
      }
      span {
        font-size: 14px;
        line-height: 16px;
        color: #da1414;
        strong {
          font-weight: 500;
          padding-left: 4px;
        }
      }
    }
  }
`;
export default FYIStyled;
