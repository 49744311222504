import { compose } from 'recompose';

import AccountGroup from './AccountGroup';

import apiInterceptor from '../../../api/interceptor';

import { accountGroupApi } from '../../common/base.api';

const composedAgent = compose(apiInterceptor({ ...accountGroupApi }))(AccountGroup);

export default composedAgent;
