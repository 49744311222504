// TODO: Include Prop Types and Use for Composition Pattern.

import React from 'react';

import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Fab,
  Icon,
} from '../../../../components/BillingMDC';
import { fixedFloat } from '../../../../utils/conversion';
import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import BillingAsyncSelect from '../../../../components/AsyncSelect/AsyncSelect';
import { discountOptions } from '../../sales/create/config';
import BatchDialog from '../../grn/create/BatchDialog';

const CreateSrnDialog = ({
  onModalClose,
  modalOpen,
  editModalOpen,
  deleteModalOpen,
  createSKU,
  handleInputChange,
  onModalSubmit,
  skuList,
  brandList,
  batchList,
  brandSKUList,
  formEmptyField,
  invoiceIdStatus,
  qtyValidation,
  loadOptions,
  batchDetails,
  onBatchInputChange,
  onBatchFormSubmit,
  onBatchFormCancel,
  batchFormOpen,
  onBatchFabClick,
  batchDialogValidation,
}) => (
  <Dialog
    open={modalOpen || editModalOpen || deleteModalOpen}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      {modalOpen && <DialogTitle>Select SKU</DialogTitle>}
      {editModalOpen && <DialogTitle>Edit SKU</DialogTitle>}
      {deleteModalOpen && <DialogTitle> Delete SKU</DialogTitle>}
    </DialogHeader>

    {(modalOpen || editModalOpen) && (
      <DialogBody>
        <div className='dialog-upperpart'>
          {!(editModalOpen && invoiceIdStatus) && (
            <div className='row_one row '>
              <Grid className='grid-padding '>
                <Cell col={6} tablet={8}>
                  <label>Brand</label>
                  <BillingSelect
                    name='form-field-name'
                    param='brand'
                    clearable={true}
                    value={createSKU?.brand}
                    valueKey='indexArr'
                    labelKey='title'
                    options={brandList}
                    onChange={(e) => handleInputChange('brand', e?.indexArr, e)}
                    multipleParam
                  />
                </Cell>
                <Cell col={6} tablet={8}>
                  <label>SKU</label>
                  <BillingAsyncSelect
                    param='sku_id'
                    placeholder='Search/Select...'
                    loadOptions={loadOptions}
                    handleChange={handleInputChange}
                    options={skuList}
                    defaultOptions={brandSKUList}
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ businessSkuId }) => businessSkuId}
                    multipleParam
                    required
                    emptyField={formEmptyField}
                    value={skuList.filter(({ businessSkuId }) => businessSkuId === createSKU.sku_id)}
                    valueKey='businessSkuId'
                  />
                </Cell>
                {!(editModalOpen && invoiceIdStatus) && !batchFormOpen ? (
                  <Cell col={4} tablet={8}>
                    <label>Batch</label>
                    <div className='form-wrap fab-div'>
                      <BillingSelect
                        name='form-field-name'
                        param='batch_id'
                        clearable={false}
                        value={createSKU.batch_id}
                        valueKey='businessBatchId'
                        labelKey='title'
                        options={batchList}
                        handleChange={handleInputChange}
                        multipleParam
                        required={true}
                        emptyField={formEmptyField}
                      />
                      <span className='mini-fab-button'>
                        <Fab
                          mini
                          onClick={() => onBatchFabClick({ createModalOpen: true })}
                          disabled={!createSKU.sku_id}
                          className={`${!createSKU.sku_id ? 'disabled' : ''}`}
                        >
                          <Icon name='add' />
                        </Fab>
                      </span>
                    </div>
                  </Cell>
                ) : null}
                {!batchFormOpen && (
                  <Cell col={4} tablet={8}>
                    <BillingTextField
                      value={fixedFloat(createSKU.rate)}
                      handleChange={handleInputChange}
                      floatingLabel='Rate'
                      param='rate'
                      required={true}
                      zeroError={false}
                      type='number'
                      className={`billing-required ${invoiceIdStatus ? 'inp-disabled' : ''}`}
                      emptyField={formEmptyField}
                      errorMessage={createSKU.rate === 0 ? 'Should not be 0' : 'Should not be empty'}
                      disabled
                    />
                  </Cell>
                )}
              </Grid>
            </div>
          )}
          {!batchFormOpen ? (
            <>
              <div className='row_two row'>
                <Grid className='grid-padding'>
                  {/* {!invoiceIdStatus && ( */}
                  <Cell className='uom' col={4} tablet={8}>
                    <label>UOM</label>
                    <BillingSelect
                      name='form-field-name'
                      param='uom'
                      clearable={false}
                      value={createSKU.uom_list?.filter((list) => list.value === createSKU?.uom?.value)}
                      multipleParam
                      options={createSKU.uom_list}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ value }) => value}
                      onChange={(e) => handleInputChange('uom', e.value, e)}
                    />
                    {createSKU?.uom?.value && createSKU?.uom?.pcs !== 1 && (
                      <span className='upc-value'>{`1 ${createSKU?.uom?.value} = ${createSKU?.uom?.pcs} pcs`}</span>
                    )}
                  </Cell>
                  {/* )} */}
                  {invoiceIdStatus&&
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={fixedFloat(createSKU.rate)}
                        handleChange={handleInputChange}
                        floatingLabel='Rate'
                        param='rate'
                        required={true}
                        zeroError={false}
                        type='number'
                        className={'billing-required '}
                        emptyField={formEmptyField}
                        errorMessage={createSKU.rate === 0 ? 'Should not be 0' : 'Should not be empty'}
                        disabled
                      />
                    </Cell>
                  }
                </Grid>
                <label>Quantity</label>
                <Grid className='grid-padding row'>
                  <Cell col={4} tablet={8}>
                    <BillingTextField
                      value={createSKU.normal}
                      handleChange={handleInputChange}
                      floatingLabel='Sellable'
                      param='normal'
                      required={false}
                      zeroError={true}
                      type='number'
                      max={createSKU.hasOwnProperty('maxValue') ? createSKU.maxValue : '0'}
                      errorMessage={qtyValidation ? 'Should not be greater than available quantity' : ''}
                      invalidError={qtyValidation}
                    />
                  </Cell>

                  <Cell col={4} tablet={8}>
                    <BillingTextField
                      value={createSKU.damage}
                      handleChange={handleInputChange}
                      floatingLabel='Damage'
                      param='damage'
                      required={true}
                      zeroError={true}
                      type='number'
                      max={createSKU.hasOwnProperty('maxValue') ? createSKU.maxValue : '0'}
                      errorMessage={qtyValidation ? 'Should not be greater than available quantity' : ''}
                      invalidError={qtyValidation}
                    />
                  </Cell>
                  <Cell col={4} tablet={8}>
                    <BillingTextField
                      value={createSKU.expiry}
                      handleChange={handleInputChange}
                      floatingLabel='Expiry'
                      param='expiry'
                      required={true}
                      zeroError={true}
                      type='number'
                      max={createSKU.hasOwnProperty('maxValue') ? createSKU.maxValue : '0'}
                      errorMessage={qtyValidation ? 'Should not be greater than available quantity' : ''}
                      invalidError={qtyValidation}
                    />
                  </Cell>
                </Grid>
              </div>
              <div className='row_three row'>
                <Grid className='grid-padding'>
                  {!invoiceIdStatus && (
                    <>
                      <Cell col={2}>
                        <label className='label'>Discount Type</label>
                        <BillingSelect
                          name='form-field-name'
                          param='discount_label'
                          clearable={false}
                          value={discountOptions.filter((list) => list.value === createSKU.discount_label)}
                          options={discountOptions}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ value }) => value}
                          disabled={createSKU.freeSku || createSKU.selectedPromotionId}
                          onChange={(e) => handleInputChange('discount_label', e.value)}
                        />
                      </Cell>
                      <Cell col={4}>
                        <BillingTextField
                          disabled={createSKU.freeSku || createSKU.selectedPromotionId}
                          value={createSKU.discount_input}
                          handleChange={handleInputChange}
                          floatingLabel={createSKU.discount_label === 'rs' ? 'Discount' : 'Discount (%)'}
                          param='discount_input'
                          type='number'
                          className='billing-required right-side-gap'
                          max={createSKU.discount_label === 'rs' ? createSKU.quantity * createSKU.rate : 100}
                        />
                      </Cell>
                    </>
                  )}
                  <Cell col={6} tablet={8} className='discount'>
                    <BillingTextField
                      value={fixedFloat(createSKU?.promotion_discount) || 0}
                      handleChange={handleInputChange}
                      floatingLabel='Discount'
                      param='promotion_discount'
                      required={true}
                      zeroError={false}
                      disabled={true}
                      type='number'
                      max={fixedFloat(createSKU?.quantity * createSKU?.rate).toString()}
                      className={'billing-required inp-disabled\''}
                      emptyField={formEmptyField}
                      errorMessage='Should not be empty'
                    />
                  </Cell>
                  <Cell col={6} tablet={8}>
                    <BillingTextField
                      value={createSKU.net_amount}
                      handleChange={handleInputChange}
                      floatingLabel='Net Amount'
                      param='net_amount'
                      required={true}
                      zeroError={true}
                      type='number'
                      className='billing-required inp-disabled'
                      emptyField={formEmptyField}
                      errorMessage={createSKU.net_amount === 0 ? 'Should not be 0' : 'Should not be empty'}
                    />
                  </Cell>
                </Grid>
              </div>
            </>
          ) : null}
          {batchFormOpen ? (
            <div className='row row_one'>
              <Grid className='grid-padding width-100'>
                <Cell col={12} tablet={8}>
                  <BatchDialog
                    batch={batchDetails}
                    batchDialogValidation={!batchDialogValidation}
                    onInputChange={onBatchInputChange}
                    onFormCancel={onBatchFormCancel}
                    onFormSubmit={onBatchFormSubmit}
                    batchFormOpen={batchFormOpen}
                  />
                </Cell>
              </Grid>
            </div>
          ) : null}
        </div>
      </DialogBody>
    )}

    {deleteModalOpen && <div className='default-margin-24'>Are you sure you want to Delete ?</div>}

    <DialogFooter>
      <Button
        accent
        className='dialog-cancel modal-btn'
        onClick={() => {
          if (batchFormOpen) {
            onBatchFormCancel();
          } else onModalClose();
        }}
      >
        Cancel
      </Button>
      <Button
        accent
        className='dialog-ok modal-btn'
        onClick={() => {
          if (batchFormOpen) {
            onBatchFormSubmit();
          } else onModalSubmit();
        }}
      >
        Ok
      </Button>
    </DialogFooter>
  </Dialog>
);

export default CreateSrnDialog;
