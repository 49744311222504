import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Textfield } from 'react-mdc-web';
import { has } from '../../utils/hasOwnProperty';
import { DISCARD_INPUT_TYPE, TEXT_TYPE } from './config';

const propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
  floatingLabel: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  param: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  emptyField: PropTypes.bool,
  errorMessage: PropTypes.string,
  max: PropTypes.string,
  zeroError: PropTypes.bool,
  invalidError: PropTypes.bool,
  multipleParam: PropTypes.bool,
  name: PropTypes.string,
};

const defaultProps = {
  max: '0',
  type: 'text',
  rule: '',
  name: '',
  className: '',
  maxLength: null,
  minLength: null,
  emptyField: false,
  disabled: false,
  required: false,
  zeroError: true,
  invalidError: false,
  multipleParam: false,
  errorMessage: 'Should not be empty',
};

class BillingTextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: props.value,
    });
  }

  onChange = e => {
    const { handleChange, param, multipleParam } = this.props;
    const value = this.validateInp(e);
    this.setState({ value }, () => handleChange(param, value, multipleParam ? e : ''));
  };

  validateInp = e => {
    const { type, max, rule } = this.props;
    let value = '';
    if (has.call(e.nativeEvent, 'inputType') ? !DISCARD_INPUT_TYPE[e.nativeEvent.inputType] : true) {
      if (type === TEXT_TYPE.NUMBER) {
        if (max !== '0' && e.nativeEvent.data !== '.') {
          if (this.validateMax(e.target.value, max)) {
            value = e.target.valueAsNumber || 0;
          } else {
            value = Number(max || 0);
          }
        } else {
          value = e.target.valueAsNumber || 0;
        }
      } else {
        value = e.target.value;
      }
    } else {
      value = type === TEXT_TYPE.NUMBER ? e.target.valueAsNumber || 0 : e.target.value;
    }
    if (rule) value = this.validateRule(e, value);

    return value;
  };

  validateMax = (data, max) => {
    const value = parseFloat(data);
    if (max === '0' || value < max || isNaN(value)) {
      return true;
    }

    return false;
  };

  validateInput = event => {
    const { type } = this.props;
    if (type === 'number') {
      return (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46;
    }

    return true;
  };

  validateRule = (e, value) => {
    const { rule } = this.props;
    switch (rule) {
      case 'isInt':
        return Math.abs(Number.isInteger(Number(e.target.value || 0)) ? value : parseInt(value || 0)) || 0;
      default:
        return value;
    }
  };

  handleOnKeyPress = event => {
    const isValid = this.validateInput(event);
    if (!isValid) event.preventDefault();
  };

  getModifiedValue = (value = 0) => {
    const { type, zeroError, allowMultiZeroBeforeNumber } = this.props;
    let modifiedValue = value;
    if (type === 'number' && zeroError === true) {
      modifiedValue = value !== null && value !== undefined && value.toString().replace(/^0+/, '');
      if (allowMultiZeroBeforeNumber) {
        return parseFloat(value);
      } else {
        return parseFloat(modifiedValue);
      }
    }
    return modifiedValue;
  };

  render() {
    const { value } = this.state;
    const {
      emptyField,
      errorMessage,
      required,
      zeroError,
      max,
      invalidError,
      type,
      param,
      floatingLabel,
      pattern,
      placeholder,
    } = this.props;
    const invalidCondition = invalidError
      ? required || emptyField
      : zeroError
      ? required && emptyField && !value
      : required && emptyField && value === '';
    const numberValidationProps = {};
    if (type === TEXT_TYPE.NUMBER) {
      numberValidationProps.min = 0;
      if (max !== '0') numberValidationProps.max = max;
    }
    const modifiedValue = this.getModifiedValue(value);
    return (
      <span>
        <Textfield
          id={`textfield-${floatingLabel || param}`}
          floatingLabel={this.props.floatingLabel}
          value={modifiedValue}
          type={type}
          pattern={pattern}
          placeholder={placeholder}
          disabled={this.props.disabled}
          required={this.props.required}
          className={`${invalidCondition ? 'invalid' : ''} ${this.props.className}`}
          onKeyPress={this.handleOnKeyPress}
          onChange={this.onChange}
          onFocus={e => (e.target.value = e.target.value === '0' ? '' : e.target.value)}
          {...numberValidationProps}
        />
        {invalidCondition ? <span className="error-message">{errorMessage}</span> : ''}
      </span>
    );
  }
}

BillingTextField.defaultProps = defaultProps;

BillingTextField.propTypes = propTypes;

export default BillingTextField;
