import React, { Component } from 'react';
import moment from 'moment/moment';
import DetailView from '../../common/detail/DetailView';
import PageButtonWrapper from '../../common/PageButtonWrapper';
import * as snackService from '../../common/snack.service';
import config from './config';
import { checkIfApiCallSuccess, debitNoteAPi ,ledgerApi,jvApi} from '../../common/base.api';
import withBaseState from '../../common/withBaseState';
import { DEBIT_NOTE } from '../../../data/enums/Route';
import { Button, Grid, Cell ,Icon} from '../../../components/BillingMDC';
import history from '../../../utils/history';
import { voucherDecoder } from '../../../utils/miscellaneous';
import BillingSnackBar from '../../../components/BillingMDC/BillingSnackbar';
import { clone } from '../../../utils/arrayProcessor';
import Header from '../../../components/PrintComponent/Header';
import TableHeader from '../../../components/TableHeader/TableHeader';
import VoucherFooter from '../../../components/PrintComponent/voucherFooter';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import numberToWords from '../../../utils/numberToTextConverter';
import { validateForm } from '../../common/validation';
import { LEDGERS,VAT_PER_DERIVE, VAT_ZERO_PERCENTAGE } from '../../../data/enums/enums';
import DebitNoteStyled from '../DebitNoteStyled';
import * as filterUtil from '../../../utils/filterUtil';
import {flattenDataPrintAttributeWise, groupPrintDataSet} from "../../common/print.service";
import { getBackDatedStatus ,getValidationErrorMsg,getCustomerList} from '../../../views/fundamentals/common/helpers';
import CustomerSelect from '../../../common/CustomerSelect';
import {DNDialogView,DNDialogViewTop} from './DialogView';

const propTypes = {};
const defaultProps = {};

class ContraEntryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        summary: {},
      },
      snack: { ...snackService.snackParameters },
      backUpDataList:[],
      createDN: config.formMapper({}),
      createDNBackUp:{},
      userDetail: { id: 'userId', title: '' },
      preparedBy: { title: 'Prepared By', value: '' },
      dataList: [],
      print: false,
      dateTime: {
        date: filterUtil.getCurrentDay(),
        time: filterUtil.getCurrentTime(),
      },
      printInfo: [],
      editModalOpen:false,
      readModalOpen: false,
      idJournalVoucherDetail:0,
      ledgerList: [],
      ledgerListPartial:[],
      partyLedgerList:[],
      partyLedgerListPartial:[],
      validation: {
        flag: false,
        fieldList: config.formValidationFieldList,
      },
      showUpdateBtn:false,
      updateBtnSummary:false,
      summary:{
        totalDrAmount:0,
        totalCrAmount:0,
        documentDate: filterUtil.getCurrentDay(),
      },
      partyLedgerId:0,
      partyLedgerCloned:0,
      editInput:false,
      editModalOpenLedger:false,
      documentDate: filterUtil.getCurrentDay(),
      crList:[],
      backDatedStatus:getBackDatedStatus(),
      vatStatus:true,
      vatStatusBack:true,
    };

  }

  componentDidMount() {
    this.getData();
    this.mapPrintInfo();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }
    // get the customer list for the selection.
    getLedgerList = async () => {
      const {ledgerListApi} =this.props
      const ledgerListAll = await ledgerListApi({},``);
      this.getOtherListList(ledgerListAll?.list)
    }
    getOtherListList = (ledgerListAll) =>{
      const ledgerList =ledgerListAll.filter(a=>!([LEDGERS.VAT.type].includes(a.typeId)))
      const ledgerListPartial = ledgerList.slice(0,300);
      this.setState({partyLedgerListPartial:ledgerListPartial,ledgerListPartial,partyLedgerList:ledgerList,ledgerList}) 
    }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }
  mapPrintInfo = () => {
    const { dateTime } = this.state;
    const { user } = this.props;
    const printInfo = [
      { title: 'Printed On', value: dateTime.date },
      { title: 'Printed Time', value: dateTime.time },
      { title: 'Printed By', value: user.name },
    ];
    this.setState({ printInfo });
  };

  getData = () => {
    const { match } = this.props;
    const { preparedBy ,summary} = this.state;
    const voucher_number = voucherDecoder(match.params.id);

    debitNoteAPi.getDetails({ voucher_number, user_id: match.params.userId || '' }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const { journalVoucherDetail, journalVoucher } = response.data;
        const {
          narration,
          drAmount,
          crAmount,
          date = journalVoucher.transactionDate,
          mitiTitle,
          voucherNumber,
          idJournalVoucher,
          type,
          documentDate,
        } = journalVoucher;

        const formattedList = flattenDataPrintAttributeWise(journalVoucherDetail, config.lengthyAttributes, config.getData);
        const customerIds = journalVoucherDetail.map(x => x.customer.customerId );
        const drCrAmountZero =journalVoucherDetail?.some(a =>a.drAmount === 0 && a.crAmount===0);
        const partyLedgerIdentical = customerIds.length !== _.uniq(customerIds).length;
        const idJvDetailMaxList =journalVoucherDetail.sort((a, b) => b.idJournalVoucherDetail - a.idJournalVoucherDetail)[0];
        const vatList = config.getFilteredList(config.VAT_LIST,journalVoucherDetail);
        let drList=[],crList=[];
         if(drCrAmountZero && partyLedgerIdentical){
           drList = [idJvDetailMaxList];
           crList= _.differenceWith(journalVoucherDetail, [idJvDetailMaxList,vatList[0]], _.isEqual);
        }
        else if(drCrAmountZero){
          const vatPartyId = config.getFilteredList(config.VAT_PARTY_ID,journalVoucherDetail);
           drList=config.getFilteredList(config.DR_LIST,journalVoucherDetail,vatPartyId);
           crList=config.getFilteredList(config.CR_LIST,journalVoucherDetail,vatPartyId)
          }
        else{
          crList = journalVoucherDetail?.filter(
            (a) => a?.customer.customerId !== LEDGERS?.VAT?.ledger_id && a?.drAmount === 0,
          );
          drList =journalVoucherDetail?.filter(a => a?.crAmount === 0 && a?.customer?.customerId !== LEDGERS?.VAT?.ledger_id);
        }

        const data = {
          list: formattedList,
          summary: {
            narration,
            drAmount,
            crAmount: crList.reduce((prev, cur) => prev + cur.crAmount, 0),
            date,
            mitiTitle,
            voucherNumber,
            type,
            idJournalVoucher,
            totalInWords: numberToWords(drAmount || 0),
            partyLedger: drList[0].customer.title,
            panNo: drList[0].customer.panNo,
            vatAmount: vatList[0].crAmount,
            refDate: drList[0].refDate,
            refNumber: drList[0].refNumber,
          },
        };
        const userDetail = config.userDetailMapper(response.data);
        const documentDateUpdated =filterUtil.formatToDateMonthYear(documentDate);
        const backUpDataListing= clone(crList);
        const partyLedgerIdMapped=drList[0].customer.customerId;
        summary.mainNarration = narration;
        summary.documentDate = documentDateUpdated;
        preparedBy.value = userDetail.title;
        const vatStatusCheck =vatList[0]?.crAmount > 0 ? true : false
        this.setState({ data, userDetail, backUpDataList:clone(backUpDataListing),preparedBy,summary ,documentDate: clone(documentDateUpdated),partyLedgerId:partyLedgerIdMapped,partyLedgerCloned:clone(partyLedgerIdMapped),crList:crList,vatStatus:vatStatusCheck,vatStatusBack:clone(vatStatusCheck)});
      }
    });
  };
  handleUpdate = () => {
    this.setState({readModalOpen:true});
    }

  handlePrintClick = () => {
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ print: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.setState({ print: false });
    };
  };

  btnWrapper = () => {
    const { showUpdateBtn,updateBtnSummary} = this.state;
     return(
    <>
      <Button accent className="cancel-btn modal-btn" onClick={() => history.push(`/${DEBIT_NOTE}`)}>
        Cancel
      </Button>
      { (updateBtnSummary || showUpdateBtn) || 
      <Button
        accent
        className="margin-right-0 save-btn modal-btn"
        onClick={() => {
          this.handlePrintClick();
        }}
      >
        Print
      </Button>
  }
      { (updateBtnSummary || showUpdateBtn) && 
      <Button
        accent
        className="margin-right-0 save-btn modal-btn"
        onClick={() => {
          this.handleUpdate();
        }}
      >
        Update
      </Button>
  }
    </>
  )};
  groupPrintDataSetNew() {
    const { crList} = this.state;
    const { company  } = this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(crList);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }
  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  handleEditIconClick = async(list) => {   
    const {ledgerList} =this.state;
    ledgerList?.length === 0 && await this.getLedgerList();
    this.setState({
      createDN:config.formMapper(list),
      createDNBackUp:clone({list}),
      editModalOpen:true,
      idJournalVoucherDetail:list.idJournalVoucherDetail,
    }
     )
  };
  onChange = (field, value, all = {}) => {
    const { createDN} = this.state;
    if (field === 'document_date') {
      const  date = filterUtil.formatToNumericDate(value);
      this.setState({ summary:{documentDate:date} });
    }
    else if(field ==='partyLedgerId'){
      this.setState({partyLedgerId:value})
    }
    else if(field==='vatStatus'){
      this.setState({vatStatus:value},()=>{
        this.updateDataSummary();
      });
    }
    else if(field ==='customerId'){
      const createDNmerged={...createDN,customerId:value}
      this.setState({createDN:createDNmerged})
    }
    else{
      createDN[field] =value;
      this.setState({createDN});
    }
  };
  handleModalClose = () => {
    const {editModalOpenLedger,data,backUpDataList,idJournalVoucherDetail,readModalOpen,editModalOpen} =this.state;
    this.resetJVDialog();
    if(readModalOpen){
      this.getData();
      this.resetBtn();
      return;
    }
    if(editModalOpenLedger){
      this.onEditSummaryClose();
      this.resetJVDialog();
      return;
    }
    if(editModalOpen){
    const dataList= backUpDataList.find(a=>a.idJournalVoucherDetail ===idJournalVoucherDetail);
    let dataIndex = data?.list?.findIndex((obj => obj.idJournalVoucherDetail == idJournalVoucherDetail));
    data?.list?.splice(dataIndex, 1, dataList);
   this.setState({data,showUpdateBtn:false},()=>{
     this.updateDataSummary();
    this.getChangedDataList();
  });
  }
  };
  resetJVDialog = () => {
    this.setState({ editModalOpen: false,readModalOpen: false,formEmptyField: false,editModalOpenLedger:false});
  }
  resetSummary =() =>{
    const {documentDate,summary,partyLedgerCloned,data,partyLedgerList,partyLedgerId} =this.state;
    summary.documentDate=documentDate;
    data.summary.partyLedger =partyLedgerList.find(a=>a.customerId===partyLedgerCloned)?.title;
    const customerIds = data?.list.map(x => x.customer.customerId);
    const drCrAmountZero =data?.list?.some(a =>a.drAmount === 0 && a.crAmount===0);
    const partyLedgerIdentical = customerIds.length !== _.uniq(customerIds).length;
    const idJvDetailMaxList =data?.list.sort((a, b) => b.idJournalVoucherDetail - a.idJournalVoucherDetail)[0];
    const vatListPartyId = config.getFilteredList(config.VAT_PARTY_ID,data?.list);
    const vatListIndex = config.getFilteredListIndex(config.VAT_INDEX,data?.list,vatListPartyId);
    let drListIndex=[];
     if(drCrAmountZero && partyLedgerIdentical){
      const drListIndexId = idJvDetailMaxList?.idJournalVoucherDetail;
      drListIndex =data?.list?.findIndex(a =>a?.idJournalVoucherDetail === drListIndexId);
    }
    else if(drCrAmountZero){
     drListIndex = config.getFilteredListIndex(config.DR_INDEX,data?.list,vatListPartyId);
    }
    else{
      drListIndex =data?.list.findIndex(a => a?.crAmount === 0 && a?.customer?.customerId !== LEDGERS?.VAT?.ledger_id);
    }
    data.list[drListIndex].customer.customerId= clone(partyLedgerCloned) ;
    data.list[vatListIndex].partyLedgerId= clone(partyLedgerCloned );
   this.setState({partyLedgerId:clone(partyLedgerCloned),summary,data})
  }
  resetBtn = () => {
    this.setState({showUpdateBtn:false,editInput:false,updateBtnSummary:false});
  };
  getSummaryData =(crAmountSum,vat) =>{
    const {data,summary,partyLedgerId,partyLedgerCloned,documentDate,vatStatusBack,vatStatus} =this.state;
    const totalCrAmount = data.list.reduce((a, b) => ({crAmount: a.crAmount + b.crAmount})) || 0;
    const totalDrAmount = data.list.reduce((a, b) => ({drAmount: a.drAmount + b.drAmount})) || 0;
    data.summary.crAmount = crAmountSum|| 0;
    data.summary.vatAmount = crAmountSum * vat || 0;
    data.summary.drAmount = crAmountSum+  crAmountSum * vat || 0;
    summary.totalCrAmount =totalCrAmount.crAmount;
    summary.totalDrAmount =totalDrAmount.drAmount;
    if( clone(vatStatusBack) ===vatStatus){
      this.setState({updateBtnSummary:false})
    }
    if(documentDate !==summary?.documentDate || partyLedgerId !==partyLedgerCloned || clone(vatStatusBack) !==vatStatus ){
      this.setState({updateBtnSummary:true})
    }
    this.setState({data,summary});
  }
  updateDataSummary = () => {
    const {data,summary} =this.state;
    const customerIds = data?.list.map(x => x?.customer?.customerId);
    const drCrAmountZero =data?.list?.some(a =>a.drAmount === 0 && a.crAmount===0);
    const partyLedgerIdentical = customerIds.length !== _.uniq(customerIds).length;
    const idJvDetailMaxList =data?.list.sort((a, b) => b.idJournalVoucherDetail - a.idJournalVoucherDetail)[0];
    const vatPartyId = config.getFilteredList(config.VAT_PARTY_ID,data?.list);
    const vatListIndex = config.getFilteredListIndex(config.VAT_INDEX,data?.list,vatPartyId);
    let drListIndex=[],crList=[];
     if(drCrAmountZero && partyLedgerIdentical){
      const drListIndexId = idJvDetailMaxList?.idJournalVoucherDetail;
      drListIndex =data?.list?.findIndex(a =>a.idJournalVoucherDetail === drListIndexId);
      crList = data?.list?.filter(a =>  a.customer?.customerId !== LEDGERS.VAT.ledger_id && a?.idJournalVoucherDetail !== drListIndexId);
    }
    else if(drCrAmountZero){
      drListIndex = config.getFilteredListIndex(config.DR_INDEX,data?.list,vatPartyId);
      crList=config.getFilteredList(config.CR_LIST,data?.list,vatPartyId);
   }
    else{
      crList = data?.list.filter(
        (a) => a.customer.customerId !== LEDGERS.VAT.ledger_id && a?.drAmount === 0
      );
      drListIndex =data?.list.findIndex(a => a?.crAmount === 0 && a?.customer?.customerId !== LEDGERS?.VAT?.ledger_id);
    }
    const vat =this.getVatPer();
    const crAmountSum =crList.reduce( ( sum, { crAmount } ) => sum + crAmount , 0)
    data.list[vatListIndex].crAmount = crAmountSum * vat || 0;
    data.list[drListIndex].drAmount = crAmountSum + crAmountSum * vat || 0;
    this.setState({data,summary,crList},()=>{
      this.getSummaryData(crAmountSum,vat);
    })
  }
  getVatPer = () =>{
    const {vatStatus} =this.state;
    const vat =vatStatus ? VAT_PER_DERIVE : VAT_ZERO_PERCENTAGE;
    return vat;
  }
  
    handleModalOk = () => {
      const { editModalOpen, createDN ,data,validation,summary,partyLedgerId,editModalOpenLedger,partyLedgerList,ledgerList} = this.state;
      const customerIds = data?.list.map(x => x.customer.customerId);
      const drCrAmountZero =data?.list?.some(a =>a.drAmount === 0 && a.crAmount===0);
      const partyLedgerIdentical = customerIds.length !== _.uniq(customerIds).length;
      const idJvDetailMaxList =data?.list.sort((a, b) => b.idJournalVoucherDetail - a.idJournalVoucherDetail)[0];
      const vatListPartyId = config.getFilteredList(config.VAT_PARTY_ID,data?.list);
      const vatListIndex = config.getFilteredListIndex(config.VAT_INDEX,data?.list,vatListPartyId);
      let drListIndex=[],crIdListIndex=[];
       if(drCrAmountZero && partyLedgerIdentical){
        const drListIndexId = idJvDetailMaxList?.idJournalVoucherDetail;
        drListIndex =data?.list?.findIndex(a =>a.idJournalVoucherDetail === drListIndexId);
        crIdListIndex = data?.list?.findIndex(a =>  a.customer?.customerId !== LEDGERS.VAT.ledger_id && a?.idJournalVoucherDetail !== drListIndexId && a?.idJournalVoucherDetail === this.state.idJournalVoucherDetail);
      }
      else if(drCrAmountZero){
       drListIndex = config.getFilteredListIndex(config.DR_INDEX,data?.list,vatListPartyId);
       crIdListIndex = config.getFilteredListIndex(config.CR_WITH_ID_INDEX,data?.list,vatListPartyId, this.state.idJournalVoucherDetail);
      }
      else{
        crIdListIndex = data?.list.findIndex(
          (a) => a.customer.customerId !== LEDGERS.VAT.ledger_id && a.drAmount === 0 && a?.idJournalVoucherDetail === this.state.idJournalVoucherDetail,
        );
        drListIndex =data?.list.findIndex(a => a?.crAmount === 0 && a?.customer?.customerId !== LEDGERS?.VAT?.ledger_id);
      }
      if ( editModalOpen) {
        const formValid = validateForm(createDN, validation, valid => this.setState({ validation: valid }));
        if (formValid) {
          data.list[crIdListIndex].crAmount = createDN.crAmount || 0;
          data.list[crIdListIndex].narration = createDN.narration || '';
          data.list[crIdListIndex].customer.customerId = createDN?.customerId;
          data.list[crIdListIndex].customerTitle =ledgerList.filter((a)=>a?.customerId === createDN.customerId)[0]?.title;
          data.list[drListIndex].drAmount = createDN?.crAmount + (createDN?.vatAmount || 0);
          this.setState({data,summary},()=>{
            this.getChangedDataList();
            this.updateDataSummary();
          })
          this.resetJVDialog();
      } else {
        this.setState({data})
        // this.resetJVDialog();
      }
    }
    else if(editModalOpenLedger){
      data.list[drListIndex].customer.customerId= partyLedgerId ;
      data.list[vatListIndex].partyLedgerId= partyLedgerId ;
      data.summary.partyLedger = partyLedgerList.find(a=>a.customerId ===partyLedgerId).title;
      this.setState({data},()=>{
        this.updateDataSummary();
      })
      this.resetJVDialog();
    }
    else{
      this.getUpdateApi();
      this.resetJVDialog();
    }
    };
    compareDataList =(otherArray) =>{
      return function(c){
        return otherArray.filter(function(o){
          return o.drAmount == c.drAmount && o.crAmount == c.crAmount && o.narration == c.narration && o.customer.customerId == c.customer.customerId 
        }).length == 0;
      }
    }
    getChangedDataList =() =>{
      const {data,backUpDataList}= this.state;
      let result = data.list.filter(o1 => backUpDataList.some(o2 => o1.idJournalVoucherDetail === o2.idJournalVoucherDetail));
      const filteredData = result.filter(this.compareDataList(backUpDataList))
      this.setState({showUpdateBtn: !!filteredData.length,backUpDataList:clone(backUpDataList)})
      return filteredData;
    }
    getUpdateApi = () => {
      const {data,summary,documentDate} =this.state;
      const {updateApi} =this.props;
      const apiTransformedData =config.updateApiTransformedData(data.list,summary,documentDate);
        updateApi({
          id: data.summary.idJournalVoucher,
          query: apiTransformedData,
        })
          .then(response => {
            const snack = snackService.generateUpdateMessage();
            this.setState({ snack,showUpdateBtn:false,updateBtnSummary:false });
            this.handleModalClose();
            this.getData();
          })
          .catch(err => {
            const snack = snackService.generateFailureMessage(getValidationErrorMsg(err) ||'Error while updating DN Details');
            this.setState({ snack });
          });
      }
  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };
  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  handleDateChange = (field, value) => {
    const {summary } = this.state
      summary.documentDate =filterUtil.formatToDateMonthYear(value);
      this.setState({ documentDate: summary.documentDate,summary});
  };
  handleEdit= async() =>{
    const {ledgerList} =this.state;
    ledgerList?.length === 0 && await this.getLedgerList();
    this.setState({editInput:true,editModalOpenLedger:true});
  }
  onEditSummaryClose=()=>{
    this.resetSummary();
    this.setState({editInput:false,updateBtnSummary:false});
  }

  getLedgerSelectOptions = (field,value,type='') => {
    const {validation,ledgerList,ledgerListPartial,partyLedgerList,partyLedgerListPartial} =this.state;
    return(
      <CustomerSelect
      value={value}
        param={field}
        valueKey='customerId'
        clearable
        onHandleChange={this.onChange}
        required
        pageValidation={validation.flag}
        ledgerList={field ==='partyLedgerId' ? partyLedgerList : ledgerList}
        ledgerListPartial={field==='partyLedgerId'? partyLedgerListPartial: ledgerListPartial}
        type={type}
        />
    )
  }

  render() {
    const { serverResponseWaiting, company, user } = this.props;
    const { data, print, dataMiscellaneousList, dataList, printInfo ,editModalOpen,ledgerList,validation,readModalOpen,createDN,snack,editInput,editModalOpenLedger,partyLedgerId,crList,summary,backDatedStatus,vatStatus} = this.state;
    return (
      <DebitNoteStyled className={`debit_note-details ${serverResponseWaiting ? 'clickable-false' : ''}`}>
        <div className="edit-cancel print_display_none">
        <Icon name='edit' className ={`${editInput && 'active'}`} onClick={this.handleEdit} />
        <Icon name='close' onClick={this.onEditSummaryClose}/>
        </div>
        <div className={print ? 'no-print' : 'display-block'}>
          <DetailView
            display={{
              header: true,
              footer: true,
              summary: true,
            }}
            serverResponseWaiting={serverResponseWaiting}
            headerConfig={{
              company,
              title: config.title,
              miti: data.summary.mitiTitle,
              date: moment(summary?.documentDate || new Date()).format('DD MMM YYYY'),
            }}
            pageConfig={config}
            data={data}
            onTableBodyClick={this.handleEditIconClick}
            ledgerList={ledgerList}
            handleDateChange={this.handleDateChange}
            crList={crList}
            onChange={this.onChange}
            vatStatus={vatStatus}
          />
          <DNDialogView
            onModalClose={this.handleModalClose}
            renderLedgerSelectOptions={this.getLedgerSelectOptions}
            createDN ={createDN}
            editModalOpen={editModalOpen}
            readModalOpen={readModalOpen}
            handleInputChange={this.onChange}
            onModalSubmit={this.handleModalOk}
            formEmptyField={validation.flag}
          />
            <DNDialogViewTop
            onModalClose={this.handleModalClose}
            renderLedgerSelectOptions={this.getLedgerSelectOptions}
            handleInputChange={this.onChange}
            onModalSubmit={this.handleModalOk}
            editModalOpenLedger={editModalOpenLedger}
            partyLedgerId={partyLedgerId}
            documentDate={summary?.documentDate}
            backDatedStatus={backDatedStatus}
          />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
          <PageButtonWrapper renderBtn={this.btnWrapper} />
        </div>
        <div className={print ? 'display-block portrait debit_note_print' : 'no-print'}>
          {dataList.map((ceItem, key) => (
            <div className="print-body zoom-less-reduce voucher-print">
              <div className="jv card-body">
                <Header
                  company={company}
                  divClassName={key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'}
                  date={moment(summary?.documentDate || new Date()).format('DD MMM YYYY')}
                  miti={data.summary.mitiTitle}
                  pageTitle={<h2>{config.title}</h2>}
                  dataMiscellaneousList={dataMiscellaneousList}
                />
                <div className="custom-three-input-wrapper padding-bottom-0 print-invoice three-input-wrapper">
                  <Grid>
                    {config.getSummaryViewList(data.summary).map((item, key) => {
                      return (
                        <Cell col={4} key={key} className="input-print">
                          <label htmlFor="">{item.title}</label>
                          <p className="text-area print">{item.value}</p>
                        </Cell>
                      );
                    })}
                  </Grid>
                </div>
                <div className={` fixed-table-wrapper ${!ceItem.footer ? 'fixed-table-height ' : 'total-footer'}`}>
                  <table>
                    <TableHeader headerDetails={config.header} filterHeaderLabel={false} />
                    {config.getTableBody({ dataList: ceItem.list ,remainingLines:ceItem.remainingLines,ledgerList,print})}
                  </table>
                </div>
                <footer>
                  {ceItem.footer && (
                    <table className="print-active total-section-wrapper footer clearfix top">
                      <tfoot className="table-tfoot sub-total">
                        {config.getFooterContent(data.summary).map((item, index) => (
                          <tr className={`text-right height-25 font-bold ${item.className}`}>
                            <td className="total-content-data text-right item_title font-bold">{item.title}</td>
                            <td className="total-content-data text-right item_value padding-r-4">
                              {fixedFloatAndCommas(item.value || 0)}
                            </td>
                          </tr>
                        ))}
                      </tfoot>
                    </table>
                  )}
                  {ceItem.footer && (
                    <table className="left-footer print-active total-section-wrapper footer clearfix">
                      <>
                        <VoucherFooter
                          totalInWords={data.summary.totalInWords}
                          lineMargin="padding-r-24"
                          remarksStatus
                          remarks={data.summary.narration}
                          total={data?.summary?.drAmount}
                          authorizeSign
                          preparedByStatus={false}
                          printAllInfo
                          invoicedBy
                          print
                          printInfo={printInfo}
                          user={user}
                        />
                      </>
                    </table>
                  )}
                </footer>
              </div>
              <div className="footer-block">
                <PageNumber value={ceItem.page} totalPage={dataList.length} />
              </div>
            </div>
          ))}
        </div>
      </DebitNoteStyled>
    );
  }
}

ContraEntryDetail.propTypes = propTypes;
ContraEntryDetail.defaultProps = defaultProps;

const ContraEntryDetailWithState = withBaseState(ContraEntryDetail);

export default ContraEntryDetailWithState;
