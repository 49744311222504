
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogTitle,
  DialogFooter,
  Button,
  Grid,
  Cell,
} from '../../../components/BillingMDC';
import { connect } from 'react-redux';
import BillingTextField from '../../../components/TextField/BillingTextField';
import * as filterUtil from '../../../utils/filterUtil';
import DateRangePicker from '../../../components/DatePicker/DatePicker';


const DNDialogViewTop = ({
  onModalClose,
  handleInputChange,
  onModalSubmit,
  editModalOpenLedger,
  partyLedgerId,
  documentDate,
  backDatedStatus,
  renderLedgerSelectOptions,
  fiscalYearRange,
}) =>{
  const minDate = fiscalYearRange?.previous_fiscal_year_closed ? fiscalYearRange?.start_date : fiscalYearRange?.previous_fiscal_year_start;
  return(
    <Dialog
    open={editModalOpenLedger}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
    <DialogTitle>Edit Debit Note</DialogTitle>
    </DialogHeader>
    <DialogBody>
        <div className='dialog-upperpart dialog-view'>
          
          <Grid>
            <Cell col={6} tablet={8} >
              <label className='select-custom-label'>Party Ledger</label>
              {renderLedgerSelectOptions('partyLedgerId',partyLedgerId,'')}
            </Cell>
            { backDatedStatus &&
            <Cell col={6}>
              <span className='date-picker-wrapper documentDate'>
                <label>Document Date</label>
                <DateRangePicker
                  date={filterUtil.getMomentDate(documentDate)}
                  onChange={(e) =>handleInputChange('document_date' ,e)}
                  minDate={filterUtil.getMomentDate(minDate)}
                  maxDate={filterUtil.getCurrentDate()}
                />
              </span>
            </Cell>
              }
          </Grid>
        </div>
      </DialogBody>
    <DialogFooter>
      <Button
        accent
        className='dialog-cancel modal-btn'
        onClick={() => {
          onModalClose();
        }}
      >
      Cancel
      </Button>
      <Button
        accent
        className='dialog-ok modal-btn'
        onClick={() => {
          onModalSubmit();
        }}
      >
      Ok
      </Button>
    </DialogFooter>
  </Dialog>
  )
}


const DNDialogView = (
  {
    onModalClose,
    modalOpen,
    editModalOpen,
    readModalOpen,
    createDN,
    handleInputChange,
    onModalSubmit,
    formEmptyField,
    renderLedgerSelectOptions,
  },
) =>{
  return(
    <Dialog
      open={modalOpen || editModalOpen || readModalOpen}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        {(editModalOpen) && <DialogTitle>Edit Debit Note</DialogTitle>}
        {readModalOpen && <DialogTitle>Confirmation Dialog</DialogTitle>}
      </DialogHeader>
      <>
      {readModalOpen ?<div className='dialog-upperpart'>Are you sure you want to progress ?</div>:
          <DialogBody>
            <div className='dialog-upperpart table-view'>
              <Grid className='grid-padding'>
                <Cell col={4} tablet={8}>
                  <label className='select-custom-label'>Ledger</label>
                  {renderLedgerSelectOptions('customerId',createDN.customerId,'')}
                </Cell>
                <Cell col={4} tablet={8}>
                  <BillingTextField
                    value={createDN.crAmount}
                    handleChange={handleInputChange}
                    floatingLabel='Amount'
                    param='crAmount'
                    type='number'
                    required
                    zeroError={false}
                    className='billing-required right-side-gap'
                    emptyField={formEmptyField}
                  />
                </Cell>
                <Cell  col={4} tablet={8}>
                  <BillingTextField
                    value={createDN.narration}
                    param='narration'
                    floatingLabel='Narration'
                    className='textbox billing-required'
                    handleChange={handleInputChange}
                  />
                </Cell>
              </Grid>
            </div>
          </DialogBody>
}
      </>
      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            onModalClose();
          }}
        >
        Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
        Ok
        </Button>
      </DialogFooter>
    </Dialog>
  )};
const mapStateToProps = (state) => {
  return {
    fiscalYearRange: state.billing.fiscalYearRange || null,
  };
};

const dnDialogViewTop = connect(mapStateToProps)(DNDialogViewTop);

export  { DNDialogView ,dnDialogViewTop as DNDialogViewTop};
