import React, { Component } from 'react';
import { getFormValues } from 'redux-form';
import Loading from '../../../../../common/Loading';
import { UOMList } from '../config';
import SkuPackageStyled from './SkuPackageStyled';
import View from './View';

const propTypes = {};

const defaultProps = {};

class SkuPacking extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.uomList?.length > 0) {
      return {
        uom_list: props.uomList,
      };
    }
    return {
      uom_list: state.uom_list,
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      uom_list: props.id?[]:[{value: 'pcs', label: 'Pcs',pcs:1,is_basic:true}],
      removedUOM:[],
    };
  }
  handleInputChange = (field, value, index, list) => {
    const { uom_list } = this.state;
    const { allUOMList } = this.props;
    uom_list[index][field] = value;
    if (field === 'value') {
      uom_list[index]['label'] = allUOMList.filter(a => a.value === value)?.[0]?.label;
      uom_list[index].flag = false;
      uom_list[index].uomflag = false;
    }
    this.setState({ uom_list });
  };

  getValidation = () => {
    const { uom_list } = this.state;
    let valid = true;

    const msgEnabledUOM = uom_list.map(uom => {
      if (!uom.pcs) {
        valid = false;
        uom.flag = true;
      }
      if (!uom.value) {
        valid = false;
        uom.uomflag = true;
      }
      return uom;
    });
    this.setState({
      uom_list: msgEnabledUOM,
    });

    return valid;
  };

  handleAddClick = (action, index = 0) => {
    const { uom_list,removedUOM } = this.state;
    const baseObj = {
      id: '',
      value: '', // value
      label: '', // label
      pcs: '', //pcs
    };
    if (action === 'remove') {
     if(uom_list[index].id){
      uom_list[index].status=false;
      removedUOM.push({...uom_list[index]})
      this.setState(removedUOM);
    }
      uom_list?.splice(index, 1);
    }
    if (action === 'add') {
      uom_list?.push({ ...baseObj });
    }

    this.setState({ uom_list });
  };

  exportData = () => {
    const { uom_list,removedUOM } = this.state;
    if(removedUOM?.length>0){
    return [...uom_list,...removedUOM]
    }else{
      return uom_list;
    }
  };
  render() {
    const { crudMode, serverResponseWaiting, allUOMList } = this.props;
    const { uom_list } = this.state;

    return (
      <SkuPackageStyled>
        <Loading display={serverResponseWaiting} />
        <View
          uom_list={uom_list}
          UOMList={allUOMList}
          handleAddClick={this.handleAddClick}
          handleInputChange={this.handleInputChange}
        />
      </SkuPackageStyled>
    );
  }
}

SkuPacking.propTypes = propTypes;

SkuPacking.defaultProps = defaultProps;

export default SkuPacking;
