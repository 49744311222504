import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';

import BillingTextField from '../../../components/TextField/BillingTextField';
import { ACTION } from '../../../data/enums/enums';
import { EMPTY } from '../../../data/enums/errorMessage';

const TagDialog = ({ tag, actionType, onModalClose, onModalSubmit, onInputChange, validationFlag }) => {
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>{actionType}</DialogTitle>
      </DialogHeader>

      {actionType !== ACTION.DELETE && (
        <DialogBody>
          <div className='dialog-upperpart'>
            <Grid className='grid-padding'>
              <Cell col={6} tablet={12}>
                <BillingTextField
                  value={tag.title}
                  handleChange={onInputChange}
                  floatingLabel='Title'
                  param='title'
                  required
                  className='right-side-gap'
                  emptyField={validationFlag}
                />
              </Cell>
            </Grid>
          </div>
        </DialogBody>
      )}
      {actionType === ACTION.DELETE && <div className='default-margin-24'>Are you sure you want to delete ?</div>}
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default TagDialog;
