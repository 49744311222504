import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableHeader from '../../../components/TableHeader';
import * as appConstants from '../../config';
import * as appConfig from '../../config';
import * as httpUtils from '../../../utils/httpUtils';
import moment from 'moment';
import * as filterUtil from '../../../utils/filterUtil';
import history from '../../../utils/history';
import cloneDeep from 'lodash/cloneDeep';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import BillTotalContent from '../../../components/PrintComponent/BillTotalContent';
import Header from '../../../components/PrintComponent/Header';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
} from 'react-mdc-web';
import { srnDetailConfig, SRNDetailTableBody } from './SRNDetailConfig';
import PropTypes from 'prop-types';
import '../../../styles/printStyle.css';
import '../../../styles/printDetailStyle.css';
import { SALES_INVOICE_BASE } from '../../../data/enums/Route';
import numberToWords from '../../../utils/numberToTextConverter';

const message = {
  nextPage: 'Continued on next page ...',
};
const title = 'SALES RETURN';
const signatureTitle = 'Prepared By';
let dataListLength = 0;

// invoice id is present in the list.
class SRNPrintDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      indexInInvoiceList: 0,
      firstCopyTaxInvoice: '',
      cashDiscountCheckBoxFlag: false,
      tradeDiscountCheckBoxFlag: false,
      customerDetails: {
        invoiceId: '',
        customerName: '',
        customerAddress: '',
      },
      billSummary: {
        invoiceGrossValue: 0,
        cashDiscountParam: '',
        cashDiscount: 0,
        cashDiscountValue: 0,
        tradeDiscountParam: '',
        tradeDiscount: 0,
        tradeDiscountValue: 0,
        vatAmount: 0,
        invoiceNetValue: 0,
        taxableAmount: 0,
        totalInWords: '',
      },
      company: {
        title: '',
        address: '',
        phone: '',
        panNumber: '',
      },
      SRNInvoiceId: '',
      salesId: '',
      date: '',
      miti: '',
      skuList: [],
      dataList: [],
      printButtonClicked: false,
      headerTitleToView: srnDetailConfig.headerTitleToView,
      billTotalList: [
        { title: 'Sub Total', value: 0 },
        { title: 'Bill Discount', value: 0 },
        {
          title: 'Trade Discount',
          value: 0,
        },
        { title: 'VAT', value: 0 },
      ],
      dataMiscellaneousList: [
        { title: 'Invoice Number', value: '' },
        {
          title: 'Sales Return Number',
          value: '',
        },
        { title: 'PAN No', value: '' },
        { title: 'Customer Name', value: '' },
      ],
      printInfo: [],
      printDetails: {
        invoiceId: '',
        printedBy: '',
      },
      dateTime: {
        date: filterUtil.getCurrentDay(),
        time: filterUtil.getCurrentTime(),
      },
      print: false,
      entered_by: '',
      userDetail: {
        id: '',
        title: '',
      },
      invoiceDetail: { title: 'Prepared By', value: '' },
      paymentDetail: {
        status: false,
        mode: '',
      },
    };
  }

  componentDidMount() {
    let root = document.getElementsByTagName('html')[0];
    root.setAttribute('class', 'printDetailClass');
    const id = this.props.params.id.replace(/%2F/g, '/');
    const { userDetail } = this.state;
    userDetail.id = this.props.params.userId;
    this.setState({ SRNInvoiceId: id, userDetail: userDetail, loading: true }, () => {
      setTimeout(
        function () {
          this.getDetail();
        }.bind(this),
        800,
      );
      window.addEventListener('keydown', this.handleKeyDown);
      window.addEventListener('contextmenu', this.handleMouseClick);
    });
  }

  componentWillUnmount() {
    let root = document.getElementsByTagName('html')[0];
    root.classList.remove('printDetailClass');
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  groupPrintDataset() {
    const { skuList } = this.state;
    let chunk = 24;
    let dataList = [];
    let arrayList = [];
    let count = 0;
    arrayList = cloneDeep(skuList);
    const lastArrayIndex = skuList.length ? Math.ceil(skuList.length / chunk) : 0;
    const listLeftForLastPage = skuList.length ? skuList.length % chunk : 0;
    arrayList.forEach((item, index) => {
      item['index'] = index;
      arrayList[index] = item;
    });
    for (let index = 0, j = arrayList.length; index < j; index += chunk) {
      count++;
      let chunkArray = [];
      let d = lastArrayIndex - 1;
      if (dataList.length >= d) {
        if (dataList.length === d && listLeftForLastPage > 24) {
          chunk = 24;
          chunkArray = arrayList.slice(index, index + chunk);
          chunkArray['footer'] = false;
          chunkArray['page'] = count;
        } else {
          chunkArray = arrayList.slice(index, index + chunk);
          chunkArray['footer'] = true;
          chunkArray['page'] = count;
        }
      } else {
        chunk = 24;
        chunkArray = arrayList.slice(index, index + chunk);
        chunkArray['footer'] = false;
        chunkArray['page'] = count;
      }
      dataList.push(chunkArray);
    }
    dataListLength = dataList.length;
    this.setState({ dataList: dataList }, () => {
      this.printInvoice();
    });
  }

  handleCancelClick = () => {
    history.push(`/${SALES_INVOICE_BASE}`);
  };
  printInvoice = () => {
    const { user } = this.props;
    const { printDetails } = this.state;
    const self = this;
    self.setState({ printButtonClicked: true }, () => {
      window.print();
    });
    window.onafterprint = function () {};
    // this.props.afterPrint(user.idUsers);
    // todo handle if error while notifying server
  };

  // fetch the detail from the server.
  getDetail = () => {
    const { userDetail } = this.state;
    // const invoiceId = props.params.id;
    this.setState({ loading: true });
    httpUtils
      .get(
        appConstants.baseUrl +
          `sales-return/print-detail?sales_return_invoice_number=${this.state.SRNInvoiceId}&user_id=${this.state.userDetail.id}`,
      )
      .then(response => {
        this.setState({ loading: false });
        if ((response.status == '200') & response.success) {
          const { user } = this.props;
          const salesReturnDetail = response.data.salesInvoice;
          // const paymentMode = salesInvoiceDetail.paymentMode;
          let paymentDetail = {
            status: false,
            mode: '',
          };
          // let entered_by = salesInvoiceDetail.userDetail.hasOwnProperty('name') ? salesInvoiceDetail.userDetail.name : '';
          let entered_by = '';
          const customerDetails = {
            customerId: salesReturnDetail.customerDetail.customerId,
            customerName: salesReturnDetail.customerDetail.name,
            customerAddress: salesReturnDetail.customerDetail.address,
            panNo: salesReturnDetail.customerDetail.panNo,
          };
          const billDetails = response.data;
          const billSummary = {
            invoiceGrossValue: Number(billDetails.subTotal).toFixed(2),
            netValue: salesReturnDetail.amount,
            grossValue: salesReturnDetail.netAmount,
            promotionDiscount: salesReturnDetail.promotionDiscount || 0,
            tradeDiscount: salesReturnDetail.tradeDiscount || 0,
            cashDiscount: salesReturnDetail.billDiscount || 0,
            vat: salesReturnDetail.vat,
            remarks: salesReturnDetail.remarks,
          };
          billSummary['taxableAmount'] = Number(
            billSummary.netValue -
              (billSummary.promotionDiscount + billSummary.tradeDiscount + billSummary.cashDiscount),
          ).toFixed(2);
          billSummary['totalInWords'] = numberToWords(billSummary.grossValue || 0);
          const skuList = salesReturnDetail.salesReturnDetail;
          const billTotalList = [
            { title: 'Sub Total', value: billSummary.invoiceGrossValue },
            { title: 'Bill Discount', value: billSummary.cashDiscount },
            { title: 'Trade Discount', value: billSummary.tradeDiscount },
            /* {title:'Taxable Amount', value: billSummary.taxableAmount},*/
            { title: 'VAT', value: billSummary.vat },
          ];
          const dataMiscellaneousList = [
            { title: 'Invoice Number', value: salesReturnDetail.refNumber },
            { title: 'Sales Return Number', value: this.state.SRNInvoiceId },
            { title: 'Customer Name', value: customerDetails.customerName },
            { title: 'PAN No', value: customerDetails.panNo },
          ];

          const date = moment(salesReturnDetail.date).format('DD MMM YYYY');

          const company = {
            title: billDetails.distributorDetail.title,
            address: billDetails.distributorDetail.address,
            phone: billDetails.distributorDetail.phone,
            panNumber: billDetails.distributorDetail.pan_number,
          };

          let invoiceDetail = {
            title: 'Prepared By',
            value: salesReturnDetail.userDetail.name,
          };
          if (response.data.hasOwnProperty('actionUserDetail')) {
            userDetail.title = response.data.actionUserDetail.name;
            entered_by = response.data.actionUserDetail.name;
            invoiceDetail = {
              title: 'Prepared By',
              value: response.data.actionUserDetail.name,
            };
          }
          /* if(paymentMode === 'CASH') {
          paymentDetail = {
            status: true,
            mode:'CASH'
          }
        }*/
          this.setState({
            customerDetails: customerDetails,
            billSummary: billSummary,
            skuList: skuList,
            // invoiceId: salesInvoiceDetail.invoiceNumber,
            date: date,
            miti: salesReturnDetail.mitiTitle,
            entered_by: entered_by,
            billTotalList: billTotalList,
            dataMiscellaneousList: dataMiscellaneousList,
            company: company,
            userDetail: userDetail,
            invoiceDetail: invoiceDetail,
          });
          // this.mapPrintInfo();
          this.groupPrintDataset();
        } else {
          alert('error in loading');
        }
      });
  };

  handleKeyDown = e => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.printInvoice();
    }
  };

  handleMouseClick = e => {
    // e.preventDefault();
    // alert('Default menu stopped from poping up');
  };

  render() {
    const { loading, skuList, invoiceIdPresent, copyTaxInvoice, dataList, printButtonClicked } = this.state;
    const { company } = this.props;

    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="display-block">
            {dataList.map((data, key) => (
              <div className="print-body zoom-less-reduce">
                <div className="rosia-srn-card card-body" ref="t">
                  <Header
                    company={this.state.company}
                    divClassName={
                      key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'
                    }
                    date={this.state.date}
                    miti={this.state.miti}
                    pageTitle={<h2>{title}</h2>}
                    dataMiscellaneousList={this.state.dataMiscellaneousList}
                    paymentModeStatus={this.state.paymentDetail.status}
                    paymentMode={this.state.paymentDetail.mode}
                  />
                  <div
                    ref="fixedTableBody"
                    className={` fixed-table-wrapper ${!data.footer ? 'fixed-table-height ' : 'total-footer'}`}
                  >
                    <table className="data-table">
                      <TableHeader
                        headerDetails={srnDetailConfig.headerDetails}
                        filterHeaderLabel={false}
                        handleSorting={this.handleTableSorting}
                      />
                      {data.map((salesReturn, keyTest) => (
                        <tbody>
                          <SRNDetailTableBody data={salesReturn} index={keyTest} enableUpdate={false} />
                        </tbody>
                      ))}
                    </table>
                  </div>
                  {data.footer && (
                    <table className="print-active total-section-wrapper footer clearfix">
                      <BillTotalContent
                        totalList={this.state.billTotalList}
                        totalValue={this.state.billSummary.grossValue}
                        invoiceDetail={this.state.invoiceDetail}
                        print={this.state.print}
                        printInfo={this.state.printInfo}
                        totalInWords={this.state.billSummary.totalInWords}
                        signatureTitle={signatureTitle}
                        lineMargin="padding-r-24"
                        remarksStatus={true}
                        remarks={this.state.billSummary.remarks}
                      />
                    </table>
                  )}
                  {!data.footer && (
                    <div className="continue-nxt padding-top-8 default-horizontal-margin-24">
                      <span>{message.nextPage}</span>
                    </div>
                  )}
                </div>
                <div className="footer-block">
                  <PageNumber value={data.page} totalPage={dataListLength} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

SRNPrintDetails.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
  };
};
const SalesReturnPrintDetail = connect(mapStateToProps)(SRNPrintDetails);

export default SalesReturnPrintDetail;
