import React from '../../../../node_modules/react';
import PropTypes from '../../../../node_modules/prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const SRNVatReportTableBody = ({
  dataSet = [],
  remainingLines,
  columnTotal = {},
  footer = false,
  onTableBodyClick,
}) => {
  return (
    <>
      {dataSet.map(data => {

        return (
          <tr className="cursor-pointer">
            <td className="left-align miti">{data.miti_title}</td>
            <td className="left-align bill-no link-voucher" onClick={() => onTableBodyClick(data)}>
              {data.bill_no}
            </td>
            <td className="left-align ledger-name">{data.ledger_name}</td>
            <td className="left-align pan-no">{data.pan_no}</td>
            <td className="left-align sku">{data.sku}</td>
            <td className="right-align quantity">{data.quantity}</td>
            <td className="right-align unit">{data.unit > 1 ? 'PCs' : 'PC'}</td>
            <td className="right-align total-sales">{fixedFloatAndCommas(data.total_taxable_sales) ?? '0'}</td>
            <td className="right-align tax">
              {data.tax_exempted_amount ? fixedFloatAndCommas(data.tax_exempted_amount) : data.tax_exempted_amount}
            </td>
            <td className="right-align tax-amount">
              {data.taxable_sales_amount ? fixedFloatAndCommas(data.taxable_sales_amount) : data.taxable_sales_amount}
            </td>
            <td className="right-align tax-vat">
              {data.taxable_sales_vat ? fixedFloatAndCommas(data.taxable_sales_vat) : data.taxable_sales_vat}
            </td>
            <td className="right-align export">{data.export_sales ? fixedFloatAndCommas(data.export_sales) : 0}</td>
            <td className="left-align export-country">{data.export_country}</td>
            <td className="left-align export-bill-no">{data.export_bill_number}</td>
            <td className="left-align export-date">{data.export_date}</td>
            {JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && (
              <td className="right-align">{data.excise ? fixedFloatAndCommas(data.excise) : data.excise}</td>
            )}
          </tr>
        );
      })}
      {footer && <Total columnTotal={columnTotal} />}
      {remainingLines && remainingLines.length
        ? remainingLines.map(i => (
            <tr className="blank-tr">
              <td></td>
              <td colSpan="14" className=""></td>
            </tr>
          ))
        : null}
    </>
  );
};

SRNVatReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};
const Total = ({ columnTotal = {} }) => (
  <tr className="total">
    <td colSpan="4" />
    <td className="font-bold right-align">Total</td>
    <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.totalQuantity)}</td>
    <td />
    <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.taxExemptedSales)}</td>
    <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.totalTaxableAmount)}</td>
    <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.vat)}</td>
    <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.exportSales)}</td>
    <td colSpan="3"></td>
    {JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && (
      <td className="right-align font-bold">
        {columnTotal.excise ? fixedFloatAndCommas(columnTotal.excise) : columnTotal.excise}
      </td>
    )}
  </tr>
);
const SVRTableFooter = ({ columnTotal = {} }) => (
  <tfoot className="right-content-footer">
    <Total columnTotal={columnTotal} />
  </tfoot>
);

const SRNVatReportConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'miti_title',
      title: 'SRN Miti',
      className: 'cursor-pointer left-align miti',
      sortable: true,
    },
    {
      id: 2,
      label: 'sales_return_invoice_number',
      title: 'SRN Bill Number',
      className: 'cursor-pointer left-align bill-no',
      sortable: true,
    },
    {
      id: 3,
      label: 'buyer_name',
      title: 'Buyers Name',
      className: 'cursor-pointer left-align ledger-name',
      sortable: true,
    },
    {
      id: 4,
      label: 'buyer_pan_no',
      title: 'Buyers PAN No.',
      className: 'cursor-pointer left-align pan-no',
      sortable: true,
    },
    {
      id: 5,
      label: 'item_name',
      title: 'Item Name',
      className: 'cursor-pointer left-align sku',
      sortable: true,
    },
    {
      id: 6,
      label: 'quantity',
      title: 'Item Quantity',
      className: 'cursor-pointer left-align quantity',
      sortable: true,
    },
    {
      id: 7,
      label: 'unit',
      title: 'Unit',
      className: 'cursor-pointer left-align quantity',
      sortable: false,
    },
    {
      id: 20,
      label: 'total_sales',
      title: 'Total Sales',
      className: 'cursor-pointer right-align tax',
      sortable: false,
    },
    {
      id: 10,
      label: 'tax_exempted_sales',
      title: 'Tax Exempted Sales Return',
      className: 'cursor-pointer right-align tax',
      sortable: false,
    },
    {
      id: 11,
      label: 'taxable_sales_amount',
      title: 'Total Sales Return',
      className: 'cursor-pointer right-align tax-amount',
      sortable: true,
    },
    {
      id: 12,
      label: 'taxable_sales_vat',
      title: 'VAT',
      className: 'cursor-pointer right-align tax-vat',
      sortable: true,
    },
    {
      id: 13,
      label: 'export_sales',
      title: 'Exported Sales Return',
      className: 'cursor-pointer right-align export',
      sortable: true,
    },
    {
      id: 14,
      label: 'exported_country',
      title: 'Exported Country',
      className: 'cursor-pointer left-align export-country',
      sortable: true,
    },
    {
      id: 15,
      label: 'exported_bill_number',
      title: 'Exported Bill Number',
      className: 'cursor-pointer left-align export-bill-no',
      sortable: true,
    },
    {
      id: 16,
      label: 'exported_date',
      title: 'Exported Date',
      className: 'cursor-pointer left-align export-date',
      sortable: true,
    },
    { id: 17, label: 'excise', title: 'Excise', className: 'cursor-pointer right-align', sortable: true },
  ],
};
const lengthyAttributes = ['sku', 'ledger_name'];
const printEnums = {
  withoutFooterLimit: 33,
  withFooterLimit: 32,
  sku: 42,
  ledger_name: 29,
  lengthyAttributes,
};

const config = {
  header: SRNVatReportConfig,
  getTableBody: SRNVatReportTableBody,
  getTableFooter: SVRTableFooter,
  printEnums,
  uniqueIdGetter: i => i.id,
};
const getTotalDetails = (data = {}) => {
  const totalDetails = [
    {
      id: 1,
      label: 'miti_title',
      title: 'Total',
      className: 'left-align total',
      sortable: true,
    },
    {
      id: 2,
      label: 'sales_return_invoice_number',
      title: '',
      className: 'left-align bill-no',
      sortable: true,
    },
    {
      id: 3,
      label: 'buyer_name',
      title: '',
      className: 'left-align ledger-name',
      sortable: true,
    },
    {
      id: 4,
      label: 'buyer_pan_no',
      title: '',
      className: 'left-align pan-no',
      sortable: true,
    },
    {
      id: 5,
      label: 'item_name',
      title: '',
      className: 'left-align sku',
      sortable: true,
    },
    {
      id: 6,
      label: 'quantity',
      title: 'Item Quantity',
      className: 'right-align quantity',
      sortable: true,
      value: data?.totalQuantity || 0,
    },
    {
      id: 7,
      label: 'unit',
      title: '',
      className: 'left-align quantity',
      sortable: false,
    },
    {
      id: 20,
      label: 'total_sales',
      title: 'Total Sales',
      className: 'right-align',
      sortable: true,
      value: fixedFloatAndCommas(data?.grossTotalTaxableAmount) || 0,
    },
    {
      id: 10,
      label: 'tax_exempted_sales',
      title: 'Tax Exempted Sales Return',
      className: 'right-align tax',
      sortable: false,
      value: fixedFloatAndCommas(data?.taxExemptedSales),
    },
    {
      id: 11,
      label: 'taxable_sales_amount',
      title: 'Total Sales Return',
      className: 'right-align tax-amount',
      sortable: true,
      value: fixedFloatAndCommas(data?.totalTaxableAmount) || 0,
    },
    {
      id: 12,
      label: 'taxable_sales_vat',
      title: 'VAT',
      className: 'right-align tax-vat',
      sortable: true,
      value: fixedFloatAndCommas(data?.vat) || 0,
    },
    {
      id: 13,
      label: 'export_sales',
      title: '',
      className: 'right-align export',
      sortable: true,
    },
    {
      id: 14,
      label: 'exported_country',
      title: '',
      className: 'left-align export-country',
      sortable: true,
    },
    {
      id: 15,
      label: 'exported_bill_number',
      title: '',
      className: 'left-align export-bill-no',
      sortable: true,
    },
    {
      id: 16,
      label: 'exported_date',
      title: '',
      className: 'left-align export-date',
      sortable: true,
    },
    {
      id: 11,
      label: 'total_excise',
      title: 'Excise',
      className: 'right-align',
      sortable: true,
      value: fixedFloatAndCommas(data?.excise) || 0,
    },
  ];
  if (!JSON.parse(localStorage.getItem('rosiaCompany'))?.enableExcise) {
    totalDetails.pop();
  }
  return totalDetails;
};
export { SRNVatReportConfig, SRNVatReportTableBody, SVRTableFooter, config, getTotalDetails };
