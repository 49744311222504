import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Icon } from '../../../../components/BillingMDC';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { VALIDATION_TYPES } from '../../../common/validation';
import { discountOptions } from '../../abi/create/config';

const CreateSalesReturnTableBody = ({
  data,
  index,
  selectedCheckBox,
  showCheckBox,
  handleEditIconClick,
  handleDeleteIconClick,
  handleCheckBoxClick,
}) => (
  <tr>
    <td>
      {!showCheckBox && <span>{index + 1}</span>}
      {showCheckBox && (
        <Checkbox
          checked={selectedCheckBox.includes(data?.sales_detail_id)}
          onChange={({ target: { checked } }) => {
            handleCheckBoxClick(data?.sales_detail_id, checked, data);
          }}
        />
      )}
    </td>
    <td>{data?.skuTitle || ''}</td>
    <td>{data?.batchTitle || ''}</td>
    <td className='right-align'>{fixedFloatAndCommas(data?.rate || 0)}</td>
    <td>{data?.uom?.label}</td>
    <td className='right-align'>{data?.normal || 0}</td>
    <td className='right-align'>{data?.damage || 0}</td>
    <td className='right-align'>{data?.expiry || 0}</td>
    <td className='right-align'>{data?.quantity || 0}</td>
    <td className='right-align'>{fixedFloatAndCommas(data?.promotion_discount) || 0}</td>
    <td className='right-align'>{fixedFloatAndCommas(data?.net_amount) || 0}</td>
    <td className='right-align'>
      <Icon name='edit' onClick={() => handleEditIconClick(data, index)} />
      <Icon name='delete' onClick={() => handleDeleteIconClick(index)} />
    </td>
     {/*todo::hemant  remove*/}
    {/*<td className='right-align'>{data?.bill_discount || 0}</td>*/}
    {/*<td className='right-align'>{data?.trade_discount || 0}</td>*/}
    {/*<td className='right-align'>{data?.excise || 0}</td>*/}
    {/*<td className='right-align'>{data?.vat || 0}</td>*/}
  </tr>
);

CreateSalesReturnTableBody.defaultProps = {
  selectedCheckBox: [],
  showCheckBox: false,
};

CreateSalesReturnTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  handleDeleteIconClick: PropTypes.func,
  selectedCheckBox: PropTypes.array,
  handleCheckBoxClick: PropTypes.func,
  showCheckBox: PropTypes.bool,
};

const createSalesReturnConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'test',
      title: 'SN',
      className: null,
      sortable: false,
    },
    {
      id: 2,
      label: 'sku',
      title: 'SKU',
      className: null,
      sortable: false,
    },
    {
      id: 3,
      label: 'batch',
      title: 'Batch',
      className: null,
      sortable: false,
    },
    {
      id: 4,
      label: 'rate',
      title: 'Rate',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 5,
      label: 'normal',
      title: 'Sellable (Pcs)',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 6,
      label: 'damage',
      title: 'Damage (Pcs)',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 7,
      label: 'expiry',
      title: 'Expiry (Pcs)',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 8,
      label: 'quantity',
      title: 'Quantity (Pcs)',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 9,
      label: 'discount',
      title: 'Discount',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 10,
      label: 'amount',
      title: 'Amount',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 11,
      label: 'test',
      title: '',
      className: 'right-align',
      sortable: false,
    },
  ],
  headerTitleToView: ['test', 'sku', 'rate', 'qty', 'promotion', 'discount', 'amount'],
};

const createSkuMapper = (data) => ({
  sales_detail_id: data.idSalesDetail || null,
  batch_id: data.batchId || null,
  batchTitle: data.batch || '',
  brand: '',
  brandTitle: '',
  sku_id: data.skuId || '',
  skuTitle: data.sku || '',
  vat: data.vat || 0,
  return_order_id: data.idSalesDetail || null,
  invoice_number: data.invoiceNumber || '',
  damage: data.damage || 0,
  expiry: data.expiry || 0,
  normal: data.normal || data.quantity || 0,
  quantity: data.quantity || 0,
  rate: data.rate || 0,
  rlp:data.rlp||0,
  uomRate: data.uomRate || 0,
  business_id: data.businessId || '',
  promotion_discount: data.promotionDiscount || 0,
  bill_discount: data.billDiscount || 0,
  trade_discount: data.tradeDiscount || 0,
  net_amount: data.netAmount || 0,
  amount: data.amount || data.quantity * data.rate,
  actual_quantity: data.actualQuantity ? data.actualQuantity : data.quantity || 0,
  actual_promotion_discount: data.originalPromotionDiscount || 0,
  compare_quantity: data.quantity,
  vat_percent: data.vatPercent || 0,
  tax_exempted_amount: data.taxExemptedAmount || data.tax_exempted_amount || 0,
  discount_label: data.discount_label ? data.discount_label : discountOptions[0].value,
  discount_input: data.discount_input ? data.discount_input : null,
  excise: (data.excise / (data.actualQuantity ? data.actualQuantity : data.quantity)) * data.quantity,
  uom: data.uom || {
    value: 'pcs',
    pcs: 1,
  },
  uomId: data.uomId || '',
  remainingQuantity : data.remainingQuantity||0,
  uom_list: data.uom_list || [],
  compare_quantity_basic:0,
  quantity_basic: 0
});

const customerMapper = (customer) => ({
  id: customer.idLedger || '',
  customerId: customer.customerId || '',
  title: customer.title || '',
  address: customer.address || '',
  panNo: customer.panNo || '',
});

const agentMapper = (agent) => ({
  id: agent.idAgent || '',
  name: agent.name || '',
  phone: agent.phone || '',
});

const quantityTypeOptions = [
  { value: 'pcs', label: 'Pcs' },
  { value: 'case', label: 'Case' },
];

const message = {
  serverError: '!Server Error',
  success: 'Sales Return created successfully.',
  error: '!Error while creating Sales Return',
  invoiceNumberNotFound: '!Invoice Number not found',
  invoiceListNotSelected: 'Sales Invoice not selected.',
  misMatchBusinessId: '!Invoice number belong to the other Business Unit.',
};

const formValidateFieldArray = [
  { title: 'sku_id' },
  { title: 'batch_id' },
  { title: 'rate', type: VALIDATION_TYPES.NULL_ERROR },
  { title: 'quantity', type: VALIDATION_TYPES.ZERO_ERROR },
  { title: 'promotion_discount', type: VALIDATION_TYPES.ZERO_VALID },
];
const pageValidateFieldArray = [
  { title: 'title' },
  { title: 'remarks' },
  { title: 'invoiceId' },
  // { title: 'panNo', type: VALIDATION_TYPES.PAN_NO },
];

const pageValidateCashFieldArray = [
  { title: 'remarks' },
  { title: 'purchase_invoice_number' },
  // { title: 'panNo', type: VALIDATION_TYPES.PAN_NO },
];

const editFormValidateFieldArray = [
  { title: 'rate', type: VALIDATION_TYPES.NULL_ERROR },
  { title: 'quantity', type: VALIDATION_TYPES.ZERO_ERROR },
  { title: 'promotion_discount', type: VALIDATION_TYPES.ZERO_VALID },
];

const cashLedger = {
  label: '105. CASH',
  customerId: 105,
  title: 'CASH',
};

export {
  createSkuMapper,
  customerMapper,
  agentMapper,
  editFormValidateFieldArray,
  formValidateFieldArray,
  message,
  pageValidateFieldArray,
  quantityTypeOptions,
  createSalesReturnConfig,
  CreateSalesReturnTableBody,
  pageValidateCashFieldArray,
  cashLedger,
};
