import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';

import BillingTextField from '../../../components/TextField/BillingTextField';
import { ACTION, LEDGER_GROUP_TYPE } from '../../../data/enums/enums';
import { EMPTY } from '../../../data/enums/errorMessage';
import BillingSelect from '../../../components/Select/BillingSelect';

const AgentDialog = ({
  accountGroup,
  actionType,
  onModalClose,
  onModalSubmit,
  onInputChange,
  validationFlag,
  accountGroupList,
}) => (
  <Dialog
    open={actionType !== ACTION.NULL}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      <DialogTitle>{actionType}</DialogTitle>
    </DialogHeader>

    <DialogBody>
      <div className='dialog-upperpart'>
        <Grid className='grid-padding'>
          <Cell col={6} tablet={12}>
            <BillingTextField
              value={accountGroup.title}
              handleChange={onInputChange}
              floatingLabel='Title'
              param='title'
              required
              className='right-side-gap'
              emptyField={validationFlag}
            />
          </Cell>
          <Cell>
            <label>Parent</label>
            <BillingSelect
              name='form-field-name'
              param='parent'
              clearable={true}
              value={accountGroup.parent}
              valueKey='idAccountGroup'
              labelKey='title'
              options={
                actionType === ACTION.UPDATE
                  ? accountGroupList.filter((i) => i.idAccountGroup !== accountGroup.id)
                  : accountGroupList
              }
              handleChange={onInputChange}
            />
          </Cell>
          <Cell>
            <label>Ledger Type</label>
            <BillingSelect
              name='form-field-name'
              param='ledgerType'
              clearable={false}
              value={accountGroup.ledgerType}
              valueKey='id'
              labelKey='title'
              options={LEDGER_GROUP_TYPE}
              handleChange={onInputChange}
              required
              emptyField={validationFlag}
            />
          </Cell>
        </Grid>
      </div>
    </DialogBody>
    <DialogFooter>
      <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
        Cancel
      </Button>
      <Button
        accent
        className='dialog-ok modal-btn'
        onClick={() => {
          onModalSubmit();
        }}
      >
        Save
      </Button>
    </DialogFooter>
  </Dialog>
);

export default AgentDialog;
