import PropTypes from 'prop-types';
import React from 'react';
import { fixedFloatAndCommas } from '../../utils/conversion';

const VoucherFooter = ({
  print,
  printInfo,
  totalList,
  totalInWords,
  signatureList,
  lineMargin,
  remarksStatus,
  remarks,
  colSpanValue,
  preparedBy,
  total,
  printAllInfo,
  agentInfo,
  authorizeSign,
  invoicedBy,
  preparedByStatus,
  user,
}) => {
  return (
    <tfoot className='jv-footer'>
      {total > 0 && (
        <tr className='total invoice paddint-b-8'>
          {invoicedBy ? (
            <td>
              <div className='invoice'>
                <span className='invoice__title '>Prepared By:</span>
                <span className='invoice__by padding-l-2'> {user.name}</span>
              </div>
            </td>
          ) : (
            <td />
          )}
          <td className='text-right total-td'>
            <span className='font-bold '>Total</span>
            <span className='padding-r-4 '>{fixedFloatAndCommas(total)}</span>
          </td>
        </tr>
      )}
      {totalList.length > 0 && (
        <tr>
          {totalList.map((item, key) => {
            return (
              <td className='padding-r-4 ' key={key} colSpan={key === 0 ? colSpanValue : ''}>
                {item.value}
              </td>
            );
          })}
        </tr>
      )}
      <tr className='total-in-words'>
        <td className='page-tfoot' colSpan={2}>
          <div className='display-inline-block jv-max-width'>
            <span className='padding-l-2 padding-r-4 padding-t-4'>In Words:</span>
            <span className='padding-r-4 '>{totalInWords}</span>
          </div>
        </td>
      </tr>

      {print && (
        <tr className='printInfo'>
          <td className='tfoot-td' colSpan='2'>
            {printInfo.map((item, key) => {
              return (
                <div className='span-wrapper padding-t-4' key={key}>
                  <span className='padding-l-2 padding-r-4 '>{item.title}:</span>
                  <span>{item.value}</span>
                </div>
              );
            })}
            <div className='padding-t-4'>
              <span className='title'>Print Count:</span>
              <span className='value padding-r-4 '>1</span>
            </div>
          </td>
        </tr>
      )}
      {preparedByStatus && (
        <tr className='prepared-by '>
          <td colSpan='2'>
            <div className=''>
              <span className='padding-l-2  padding-r-4'>{preparedBy['title']}:</span> {preparedBy.value}
            </div>
          </td>
        </tr>
      )}
      {agentInfo && (
        <tr>
          <td colSpan='2'>
            <div className='agent_info'>
              <span className='agent_title padding-l-2'>Agent:</span>
              <span className='agent_value font-bold padding-l-2'></span>
            </div>
          </td>
        </tr>
      )}
      {remarksStatus && (
        <tr className='narration'>
          <td className='page-tfoot-end narration' colSpan='2'>
            <div className=''>
              <span className='padding-l-2 padding-r-8 padding-l-2'> Narration:</span>
              {remarks}
            </div>
          </td>
        </tr>
      )}

      {signatureList.length > 0 && (
        <tr className='not-full-width-row signature'>
          <td colSpan='2'>
            <div className='sign'>
              {signatureList.map((item, key) => {
                return (
                  <div className='signature-content' key={key}>
                    <span>{item.title}</span>
                  </div>
                );
              })}
            </div>
          </td>
        </tr>
      )}
      {authorizeSign && (
        <>
          <tr className='signature'>
            <td colSpan='2' className='text-right'>
              <div className={`authorized float-right right ${lineMargin}`}>
                <p></p>
                <span>Authorized Signature</span>
              </div>
            </td>
          </tr>
        </>
      )}
    </tfoot>
  );
};

VoucherFooter.defaultProps = {
  totalList: [],
  signatureList: [],
  printInfo: [],
  lineMargin: 'padding-r-8',
  preparedBy: { title: '', value: '' },
  colSpanValue: 2,
  total: 0,
  agentInfo: false,
  authorizeSign: false,
  preparedByStatus: true,
  printAllInfo: false,
  invoicedBy: false,
};

VoucherFooter.propTypes = {
  totalList: PropTypes.array,
  signatureList: PropTypes.array,
  total: PropTypes.number,
  agentInfo: PropTypes.bool,
  authorizeSign: PropTypes.bool,
  preparedByStatus: PropTypes.bool,
  printAllInfo: PropTypes.bool,
  invoicedBy: PropTypes.bool,
};

export default VoucherFooter;
