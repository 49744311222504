import React, { Fragment } from 'react';
import UserGroupChildView from './childView';
import Switch from '../../../../components/Switch';
import HorizontalLabelValueBar from '../../../common/horizontalLabellValueBar';
import AccordionTable from '../../../../components/CollapsibleTable/AccordionTable';

const TableBody = ({ data, onRowClick, handleInputChange, parentIndex }) => {
  const childrenExist = data.is_child_exists;
  return (
    <Fragment>
      <td className={`${childrenExist ? 'accordion-open' : ' '}`} onClick={() => onRowClick(data)}>
        <span data-icon='keyboard_arrow_down'>{data.permission_group}</span>
      </td>
      <td className={`${childrenExist ? 'accordion-open' : ''}`} onClick={() => onRowClick(data)}>
        {childrenExist || (
          <div className='form-switchs'>
            <HorizontalLabelValueBar
              value={
                <Switch
                  onToggle={(checked, event) => handleInputChange('status', checked, data, parentIndex)}
                  checked={data.child[0].status}
                  id={`switch-${data.child[0].id}`}
                />
              }
            />
          </div>
        )}
      </td>
    </Fragment>
  );
};

const TableUserGroupBody = ({ dataList, onRowClick, onTableClickData, handleInputChange }) => {
  return (
    <tbody>
      {
        <AccordionTable>
          {dataList &&
            dataList.length > 0 &&
            dataList.map((data, index) => (
              <tr
                key={index}
                trigger={TableBody({
                  data,
                  onRowClick,
                  handleInputChange,
                  parentIndex: index,
                })}
                accordionStatus={data.is_child_exists}
              >
                {UserGroupChildView({
                  data: onTableClickData,
                  handleInputChange,
                  childrenExist: data.is_child_exists,
                  parentIndex: index,
                })}
              </tr>
            ))}
        </AccordionTable>
      }
    </tbody>
  );
};
const userGroupDetailsConfig = [
  {
    id: 1,
    label: 'entity',
    title: 'Entity',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 2,
    label: '"access"',
    title: 'Permission Access',
    className: 'cursor-pointer',
    sortable: true,
  },
];

const config = {
  header: userGroupDetailsConfig,
  getTableBody: TableUserGroupBody,
};

export default config;
