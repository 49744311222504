import React from 'react';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import moment from 'moment/moment';
import { Icon } from '../../../components/BillingMDC';
import * as filterUtil from '../../../utils/filterUtil';
import { has } from '../../../utils/hasOwnProperty';
import { TRANSACTION_TYPE } from '../../../data/enums/enums';

const title = 'Contra Entry';
const getSummaryViewList = (data = {}) => {
  const summaryList = [
    {
      title: 'Narration',
      value: data.narration || '',
    },
  ];

  return summaryList;
};

const getPrintSummaryDetails = (data = {}) => {
  const printSummaryDetails = [{ title: 'Voucher Number', value: data.voucherNumber }];

  return printSummaryDetails;
};
const formValidationFieldList = [{ title: 'drAmount'  }, { title: 'crAmount' } ,
// { title:'narration' }
];

const formMapper = (element) => ({
  drAmount: element?.drAmount || 0,
  crAmount: element?.crAmount ||0,
  narration: element?.narration || null,
  customerId:element?.customer?.customerId ||0,
  partyLedgerId:element?.partyLedgerId ||null,
  partyTaxableAmount:element?.partyTaxableAmount || null,
});

const CeDetailBody = ({ dataList,remainingLines,dataSummary,footer=false,onRowClick,ledgerList }) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr className=''>
        <td className='left-align title'>     {ledgerList?.filter((a)=>a?.customerId === data?.customer?.customerId)[0]?.title}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.drAmount) || data.dramount}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.crAmount) || data.crAmount}</td>
        <td className='left-align ref-no'>{data.refNumber}</td>
        <td className='left-align voucher-num'>{data.voucherNumber}</td>
        <td className='left-align narration'>{data.narration}</td>
        <td className='right-align print_display_none'>
          <Icon name='edit' onClick={() => onRowClick(data, index)} />
        </td>
      </tr>
    ))}
    {
      footer &&
      <tr className='total'>
        {config.getFooterContent(dataSummary).map((item, index) => (
          <td className={`font-bold ${index !== 0 ? 'right-align' : ''}`}>
            {fixedFloatAndCommas(item.value)}
          </td>
        ))}
        <td colSpan='5'></td>
      </tr>
    }
    {remainingLines && remainingLines.length
      ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td colSpan ='11' className=''></td>
        </tr>
      ))
      : null}
  </tbody>
);

const getFooterContent = (data) => {
  const footerList = [
    { title: 'Total', value: 'Total', display: false },
    { title: 'Total Debit:', value: data.drAmount },
    { title: 'Total Credit:', value: data.crAmount },
  ];
  return footerList;
};

const userDetailMapper = (data = {}) => {
  let userDetail = { id: 'userId', title: '' };
  if (has.call(data, 'actionUserDetail') && data.actionUserDetail) {
    userDetail.title = data.actionUserDetail.name || '';
  } else userDetail.title = data.journalVoucher ? (data.journalVoucher.user ? data.journalVoucher.user.name : '') : '';
  return userDetail;
};

const ceDetailConfig = [
  { id: 2, label: 'ledgers.title', title: 'Ledger', className: ' left-align', sortable: false },
  { id: 3, label: 'dr_amount', title: 'Debit', className: ' right-align', sortable: false },
  { id: 4, label: 'cr_amount', title: 'Credit', className: ' right-align', sortable: false },
  { id: 5, label: 'ref_number', title: 'Ref No', className: ' left_align', sortable: false },
  { id: 7, label: 'voucher_number', title: 'Voucher No.', className: ' left_align', sortable: false },
  { id: 6, label: 'narration', title: 'Narration', className: ' left-align', sortable: false },
  {
    id: 11,
    label: '',
    title: '',
    className: 'cursor-pointer print_display_none',
    sortable: false,
  },
];
const getData = (data, label) => {
  switch(label) {
  case 'customerTitle': return (data.customer && data.customer.title) || null;
  case 'narration': return (data.narration) || null;
  default:
    return null;
  }
}
const getApiTransformedData =(data) => {
  const transformedJVList  = data.map((d) => {
    return {
      id_journal_voucher_detail: d?.idJournalVoucherDetail || 0,
      dr_amount :d?.drAmount || 0,
      cr_amount :d?.crAmount || 0,
      narration:d?.narration || '',
      ledger_id:d?.customer?.customerId || '',
    }
  })
  return transformedJVList;
}
const updateApiTransformedData = (data,summary) => {
  const transformedJV = {
    inputDetails:[...getApiTransformedData(data)],
    total_credit_amount:summary.totalCrAmount,
    total_debit_amount:summary.totalDrAmount,
    narration:summary.mainNarration,
    transaction_type: TRANSACTION_TYPE.CONTRA_ENTRY,
    document_date: filterUtil.formatToNumericDate(summary.documentDate),
  };
  return transformedJV;

};

const signatureTitleList = [
  { id: 0, title: 'Received By', value: '' },
  {
    id: 1,
    title: 'Prepared By',
    value: '',
  },
  { id: 3, title: 'ApprovedBy', value: '' },
];
const lengthyAttributes = ['customerTitle', 'narration' ];
const printEnums = {
  customerTitle: 20,
  narration: 20,
  lengthyAttributes,
  withoutFooterLimit: 44,
  withFooterLimit: 41,
};

const config = {
  title,
  header: ceDetailConfig,
  getTableBody: CeDetailBody,
  getFooterContent,
  getSummaryViewList,
  userDetailMapper,
  getPrintSummaryDetails,
  signatureTitleList,
  printEnums,
  lengthyAttributes,
  getData,
  uniqueIdGetter: (i) => i.idJournalVoucherDetail,
  updateApiTransformedData,
  formMapper,
  formValidationFieldList,
};

export default config;
