import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import LinearProgress from 'react-mdc-web/lib/LinearProgress';
import '../../../styles/printStyle.css';
import * as appConstants from '../../config';
import history from '../../../utils/history';
import { clone } from '../../../utils/arrayProcessor';
import * as httpUtils from '../../../utils/httpUtils';
import * as filterUtil from '../../../utils/filterUtil';
import { TITLE, MESSAGE, PRINT_COPY } from './config';
import PrintBody from '../../../components/PrintComponent/PrintBody';
import { GRN_CREATE, SALES_INVOICE_BASE } from '../../../data/enums/Route';
import numberToWords from '../../../utils/numberToTextConverter';

let dataListLength = 0;

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      miti: '',
      salesId: '',
      entered_by: '',
      firstCopyTaxInvoice: '',
      print: true,
      loading: false,
      printButtonClicked: false,
      paymentDetail: {
        status: false,
        mode: '',
      },
      billSummary: {
        remarks: '',
        grossValue: 0,
        totalInWords: '',
        taxableAmount: 0,
      },
      dateTime: {
        date: filterUtil.getCurrentDay(),
        time: filterUtil.getCurrentTime(),
      },
      invoiceDetail: { title: 'Invoiced By', value: '' },
      skuList: [],
      dataList: [],
      printInfo: [],
      billTotalList: [
        { title: 'VAT', value: 0 },
        { title: 'Sub Total', value: 0 },
        { title: 'Bill Discount', value: 0 },
        { title: 'Trade Discount', value: 0 },
        { title: 'Taxable Amount', value: 0 },
      ],
      dataMiscellaneousList: [
        { title: 'PAN No', value: '' },
        { title: 'Address', value: '' },
        { title: 'Invoice Id', value: '' },
        { title: 'Customer Name', value: '' },
      ],
    };
  }

  componentDidMount() {
    this.setState({ salesId: this.props.id, firstCopyTaxInvoice: JSON.parse(this.props.firstCopy) }, () => {
      this.mapPrintInfo();
      this.getDetail();
      window.addEventListener('keydown', this.handleKeyDown);
      window.addEventListener('contextmenu', this.handleMouseClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  /*groupPrintDataset() {
    const { skuList } = this.state;
    let chunk = 36;
    let maxchunk = 36;
    let dataList = [];
    let arrayList = [];
    let count = 0;
    arrayList = cloneDeep(skuList);

    const lastArrayIndex = skuList.length ? Math.ceil(skuList.length / chunk) : 0;
    const listLeftForLastPage = skuList.length ? skuList.length % chunk : 0;

    arrayList.forEach((item, index) => {
      item['index'] = index;
      arrayList[index] = item;
    });
    //maxchunk = lastArrayIndex > 1 && listLeftForLastPage > 7 ? 24 : 17;
    for (let index = 0, j = arrayList.length; index < j; index += chunk) {
      count++;
      let chunkArray = [];
      let d = lastArrayIndex - 1;
      if (dataList.length >= d) {
        if ((dataList.length === d) && (listLeftForLastPage > 36)) {
          chunk = 36;
          chunkArray = arrayList.slice(index, index + chunk);
          chunkArray['footer'] = false;
          chunkArray['page'] = count;
        }
        else {
          chunkArray = arrayList.slice(index, index + chunk);
          chunkArray['footer'] = true;
          chunkArray['page'] = count;
        }
      }
      else {
        chunk = 36;
        chunkArray = arrayList.slice(index, index + chunk);
        chunkArray['footer'] = false;
        chunkArray['page'] = count;
      }
      dataList.push(chunkArray);
    }
    dataListLength = dataList.length;
    this.setState({ dataList: dataList }, () => {
      this.printInvoice();
    });
  }*/
  groupPrintDataset() {
    const { skuList } = this.state;
    const withoutFooterLimit = 36;
    const withFooterLimit = 26;
    let arrayList,
      tempWholeDataList = [];
    let dataList = [];
    let count = 0;
    arrayList = clone(skuList);
    const chunkAppropriateSize = arrayList.length >= withoutFooterLimit ? withoutFooterLimit : withFooterLimit;
    let chunk = chunkAppropriateSize;
    let indexChunk = chunkAppropriateSize;

    arrayList.forEach((item, index) => {
      item['index'] = index;
      arrayList[index] = item;
    });

    for (let index = 0, j = arrayList.length; index < j; index = indexChunk) {
      count++;
      let chunkArray = [];
      chunkArray = arrayList.slice(index, index + chunk);
      tempWholeDataList = tempWholeDataList.concat(chunkArray);
      chunk = arrayList.length - tempWholeDataList.length >= withoutFooterLimit ? withoutFooterLimit : withFooterLimit;
      if (arrayList.length - tempWholeDataList.length === 0) {
        chunkArray['footer'] = true;
        chunkArray['page'] = count;
      } else {
        chunkArray['footer'] = false;
        chunkArray['page'] = count;
      }

      indexChunk = tempWholeDataList.length;
      dataList.push(chunkArray);
    }
    dataListLength = dataList.length;
    this.setState({ dataList: dataList }, () => {
      this.printInvoice();
    });
  }

  mapPrintInfo = () => {
    if (!this.props.firstCopy) {
      this.setState({ printInfo: this.props.printInfo });
    } else {
      const date = this.state.dateTime.date;
      const time = this.state.dateTime.time;
      const { user } = this.props;
      const printInfo = [
        { title: 'Printed On', value: date },
        { title: 'Printed Time', value: time },
        { title: 'Printed By', value: user.name },
      ];
      this.setState({ printInfo: printInfo });
    }
  };

  handleCancelClick = () => {
    history.push(`/${SALES_INVOICE_BASE}`);
  };

  printInvoice = () => {
    const { user } = this.props;
    const self = this;
    self.setState({ printButtonClicked: true }, () => {
      window.print();
    });

    this.props.afterPrint(user.idUsers);
    //todo handle if error while notifying server
  };

  // fetch the detail from the server.
  getDetail = () => {
    this.setState({ loading: true });
    httpUtils.get(appConstants.baseUrl + `sales-invoice/detail?sales_id=${this.state.salesId}`).then(response => {
      this.setState({ loading: false });
      if ((response.status == '200') & response.success) {
        const { billSummary, invoiceDetail, paymentDetail } = this.state;

        const { salesInvoice = {} } = response.data;
        const taxableAmount = this.getTaxableAmount(
          salesInvoice.amount || 0,
          salesInvoice.promotionDiscount || 0,
          salesInvoice.tradeDiscount || 0,
          salesInvoice.billDiscount || 0 + 
          salesInvoice.excise || 0  ,
        );
        const billTotalList = [
          { title: 'Sub Total', value: Number(Number(response.data.subTotal).toFixed(2)) },
          { title: 'Bill Discount', value: salesInvoice.billDiscount || 0 },
          { title: 'Trade Discount', value: salesInvoice.tradeDiscount || 0 },
          { title: 'Taxable Amount', value: taxableAmount },
          { title: 'VAT', value: salesInvoice.vat },
        ];

        const dataMiscellaneousList = [
          { title: 'Invoice Id', value: salesInvoice.invoiceNumber },
          { title: 'Customer Name', value: salesInvoice.customerDetail.name },
          { title: 'Address', value: salesInvoice.customerDetail.address },
          { title: 'PAN No', value: salesInvoice.customerDetail.panNo },
        ];

        if (salesInvoice.paymentMode === 'CASH') {
          paymentDetail.status = true;
          paymentDetail.mode = 'CASH';
        }

        billSummary.grossValue = salesInvoice.hasOwnProperty('netAmount')
          ? Number(Number(salesInvoice.netAmount).toFixed(0))
          : salesInvoice.netAmount;
        billSummary.remarks = salesInvoice.remarks;
        billSummary.totalInWords = numberToWords(billSummary.grossValue || 0);
        invoiceDetail.value = salesInvoice.userDetail.name;

        this.setState(
          {
            billSummary,
            skuList: salesInvoice.salesDetail,
            invoiceId: salesInvoice.invoiceNumber,
            date: moment(salesInvoice.date).format('DD MMM YYYY'),
            miti: salesInvoice.mitiTitle,
            entered_by: salesInvoice.userDetail.name,
            billTotalList,
            dataMiscellaneousList,
            invoiceDetail,
            paymentDetail,
          },
          () => this.groupPrintDataset(),
        );
      } else {
        alert('error in loading');
      }
    });
  };

  getTaxableAmount = (netValue = 0, promotionDiscount = 0, tradeDiscount = 0, cashDiscount = 0, excise = 0) => {
    return Number(Number(netValue - (promotionDiscount + tradeDiscount + cashDiscount - excise)).toFixed(2));
  };

  handleKeyDown = e => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.printInvoice();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  renderPrintContent = () => {
    const {
      date,
      miti,
      print,
      dataList,
      printInfo,
      billSummary,
      invoiceDetail,
      paymentDetail,
      billTotalList,
      firstCopyTaxInvoice,
      dataMiscellaneousList,
    } = this.state;
    const { company } = this.props;
    let printBody = [];
    for (let count = 0; count < PRINT_COPY; count++) {
      const title = firstCopyTaxInvoice ? (count === 0 ? TITLE.ORIGINAL : TITLE.ORIGINAL2) : TITLE.COPY;
      printBody.push(
        <PrintBody
          date={date}
          miti={miti}
          print={print}
          title={title}
          company={company}
          dataList={dataList}
          printInfo={printInfo}
          billSummary={billSummary}
          message={MESSAGE.NEXT_PAGE}
          invoiceDetail={invoiceDetail}
          paymentDetail={paymentDetail}
          billTotalList={billTotalList}
          dataListLength={dataListLength}
          dataMiscellaneousList={dataMiscellaneousList}
        />,
      );
    }
    return printBody;
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="display-block">{this.renderPrintContent()}</div>
        </div>
      </div>
    );
  }
}

InvoiceDetails.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
  };
};
const SalesInvoiceDetail = connect(mapStateToProps)(InvoiceDetails);

export default SalesInvoiceDetail;
