import styled from 'styled-components';

const ReceiptStyled = styled.div`
  .receipt {
    .end-list-table {
      max-height: calc(100vh - 318px) !important;
    }
    .dialog-wrapper {
      .dialog-upperpart {
        overflow: auto;
        max-height: 60vh;
      }
      .mdc-textfield {
        height: 50px !important;
        max-width: unset !important;
      }
      .input-select {
        .zindex-2__menu-list {
          max-height: 150px;
          overflow-y: auto;
        }
      }
      .date-picker-wrapper {
        float: unset;
        .default-vertical-padding-24 {
          padding: 0;
          padding-top: 4px;
        }
        .calendar-status {
          display: none;
        }
        .mdc-layout-grid__inner {
          display: flex;
          .date-picker-holder-active,
          .date-picker-holder {
            flex: 1;
            border-bottom: 1px solid #9f9f9f;
            padding-left: 0;
            .calendar-date {
              border-bottom: none;
            }
            .calendar-icon {
              border: none;
            }
            .calendar-arrow-icon {
              position: absolute;
              right: 0;
              border-bottom: none;
            }
          }
        }
        #date-range-picker-id {
          display: unset;
          width: unset;
        }
      }
    }

    .reconcile-checkbox {
      align-items: center;
      font-size: 16px;

      .mdc-checkbox {
        width: 18px;
        height: 16px;
      }
    }
    .cursor-not-allow{
      cursor: not-allowed;
    }
    .confirm-dialog{
      .mdc-dialog{
        z-index:20;
        &>.mdc-dialog__surface{
          width:400px;
        }
      }
    }
  }
  @media print{
    .receipt_print{
      &.portrait-type{
        .print-body{
          height: 100% !important;
        padding:0 !important;
        margin:0.28in 0.3in !important;
        page-break-after: always;
          .mdc-layout-grid__inner{
                display:flex;
            }
          .mdc-layout-grid__cell{
                width:32.2%;
                overflow:hidden;
            }
          .fixed-table-wrapper{
            height:1420px !important;
            table{
              height:100%;
              .blank-tr{
                height:24px !important;
                border:0;
              }
              td,th{
                width:60px !important;
                padding:0 !important;
                padding:6px 10px 2px 0 !important;
                color:rgba(0,0,0,1) !important;
                &.reference-num,
                  &.invoice-num{
                    width:135px !important;
                  }
                  &.amount{
                        width:50px !important;
                        min-width:50px !important;
                        max-width:50px !important;
                        white-space: normal !important;
                    }
              }
              thead{
                th{
                  font-size: 14px !important;
                }
              }
              tbody{
                tr{
                  border-bottom: 1px solid #c5c5c5  !important;
                }
                tr.last-tr,
                tr.blank-tr{
              border-bottom:0 !important;
                  }
                td{
                  font-size: 14px !important;
                  &.customer,
                  &.narration{
                    width:180px !important;
                    min-width:180px !important;
                    max-width:180px !important;
                    white-space: normal !important;
                    overflow: hidden;
                    word-break: break-word;
                  }
                  &.amount{
                        width:90px !important;
                        min-width:90px !important;
                        max-width:90px !important;
                        white-space: normal !important;
                    }
                  &:last-child{
                    font-weight:500;
                  }
                }
              }
            }
          }
          .footer-block{
            position:unset;
          }
        }
      }
    }
  }
  @media print and (max-width:5.83in) {
    .receipt_print{
      &.portrait-type{
        .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
            height:1405px !important;
        }
      }
    }
  }
}
`;
export default ReceiptStyled;
