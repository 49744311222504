import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import tableConfig from './config';
import history from '../../../../utils/history';
import withBaseState from '../../../common/withBaseState';
import PageView from '../../../common/pagination/PageView';
import { customerDetails } from '../../../../actions/customerDetail';
import * as downloadService from '../../../common/download.service';
import { CUSTOMER_LEDGER_DETAILS } from '../../../../data/enums/Route';
import { AGGREGATE_REPORT_JV_DETAILS_CONFIG } from '../../../common/domain.config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  ledgerGroupDetails: PropTypes.shape({
    detail: PropTypes.object,
    date: PropTypes.object,
  }),
  domainConfig: PropTypes.shape({
    title: PropTypes.string,
    downloadDetailList: PropTypes.instanceOf(Array),
    domain: PropTypes.string,
  }).isRequired,
  onLedgerRowClick: PropTypes.func,
};
const defaultProps = {
  serverResponseWaiting: false,
};

class AggregateDetail extends Component {
  componentDidMount() {}

  handleTableBodyClick = data => {
    const { onLedgerRowClick, ledgerGroupDetails } = this.props;

    onLedgerRowClick(
      customerDetails({
        detail: { ...data },
        date: { ...ledgerGroupDetails.date },
      }),
    );

    history.push(`/${CUSTOMER_LEDGER_DETAILS}/${data.ledgerId}`);
  };

  handleDownloadClick = () => {
    const { ledgerGroupDetails, downloadDetailList } = this.props;
    const queryParameter = {
      date: { ...ledgerGroupDetails.date },
    };
    downloadDetailList({
      type: 'csv',
      query: queryParameter,
      queryString: `&group_id=${ledgerGroupDetails.detail.idLedgerGroup}`,
    }).then(response => downloadService.resolver(response));
  };

  render() {
    // const { data, display, queryParameters } = this.state;
    const { serverResponseWaiting, ledgerGroupDetails } = this.props;
    // const { detail, date } = ledgerDetails;
    const queryParameters = {
      date: { ...ledgerGroupDetails.date },
    };

    const { ledgers, ...summary } = ledgerGroupDetails.detail;

    const listWithSummary = [...ledgers, summary];
    return (
      <div className="ledger-report">
        <PageView
          domainConfig={{
            ...AGGREGATE_REPORT_JV_DETAILS_CONFIG,
            title: ledgerGroupDetails.detail.title || '',
          }}
          headerConfig={{
            search: false,
            date: true,
            download: true,
            filter: false,
            create: false,
          }}
          footerConfig={{
            pagination: false,
          }}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={null}
          data={{
            list: listWithSummary,
            total: 0,
          }}
          handleDownloadClick={this.handleDownloadClick}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          onTableBodyClick={this.handleTableBodyClick}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ledgerGroupDetails: state.ledgerGroupDetails || {
    detail: {},
    date: {},
  },
});

function mapDispatchToProps(dispatch) {
  return {
    onLedgerRowClick: action => dispatch(action),
  };
}

AggregateDetail.protoTypes = propTypes;
AggregateDetail.defaultProps = defaultProps;

const AggregateDetailWithReduxProps = connect(mapStateToProps, mapDispatchToProps)(AggregateDetail);

const AggregateDetailWithState = withBaseState(AggregateDetailWithReduxProps);

export default AggregateDetailWithState;
