import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BatchView from './View';
import { ACTION } from '../../../../../data/enums/enums';
import { validateForm } from '../../../../common/validation';
import * as snackService from '../../../../common/snack.service';
import { config as tableConfig, formValidationFields } from './config';
import BillingSnackBar from '../../../../../components/BillingMDC/BillingSnackbar';
import SKUDetailStyled from '../SKUDetailStyled';

const propTypes = {
  cancelFlag: PropTypes.bool,
  crudMode: PropTypes.string,
  batchList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  crudMode: ACTION.ADD,
  batchList: [],
  cancelFlag: false,
};

class Batch extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      batchList: props.batchList || [],
      crudMode: props.crudMode,
      cancelFlag: props.cancelFlag,
      batch: tableConfig.getDetail(),
      dialogType: ACTION.NULL,
      validation: {
        flag: false,
        fieldList: formValidationFields,
      },
      snack: { ...snackService.snackParameters },
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { batchList, cancelFlag } = state;
    if ((props.batchList.length > 0 && batchList.length === 0) || props.cancelFlag !== cancelFlag) {
      return {
        batchList: props.batchList,
        crudMode: props.crudMode,
        cancelFlag: props.cancelFlag,
      };
    }
    return {
      crudMode: props.crudMode,
      batchList,
      cancelFlag,
    };
  }

  componentDidMount() {}

  handleIconClick = (batch = {}, dialogType = ACTION.ADD) => {
    this.setState({ batch: { ...tableConfig.getDetail(batch) }, dialogType });
  };

  handleInputChange = (field, value) => {
    const { batch } = this.state;
    batch[field] = value;
    this.setState({ batch });
  };

  handleModalSubmit = () => {
    const { batch, validation, dialogType, batchList } = this.state;
    const valid = validateForm(batch, validation, valid => this.setState({ validation: valid }));
    if (!valid) return false;

    this.handleModalClose();

    switch (dialogType) {
      case ACTION.ADD:
        batch.id = 1000 + batchList.length + 1;
        this.setState({ batchList: [...batchList, batch] });
        break;
      case ACTION.UPDATE:
        this.setState({
          batchList: batchList.map(item => {
            if (item.id === batch.id) return batch;
            return item;
          }),
        });
        break;
      default:
        break;
    }
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({ dialogType: ACTION.NULL, validation });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  getValidation = () => {
    const { batchList } = this.state;
    if (batchList.length === 0) {
      this.setState({ snack: { ...snackService.generateFailureMessage('SKU rate not added') } });

      return false;
    }
    return true;
  };

  exportData = () => {
    const { batchList } = this.state;

    return batchList;
  };

  render() {
    const { batchList, dialogType, batch, validation, snack } = this.state;
    const { crudMode } = this.props;

    return (
      <SKUDetailStyled>
        <BatchView
          batch={batch}
          onIconClick={this.handleIconClick}
          handleInputChange={this.handleInputChange}
          batchList={batchList}
          handleModalClose={this.handleModalClose}
          validation={validation}
          tableConfig={tableConfig}
          handleModalSubmit={this.handleModalSubmit}
          crudMode={crudMode}
          dialogType={dialogType}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </SKUDetailStyled>
    );
  }
}

Batch.propTypes = propTypes;

Batch.defaultProps = defaultProps;

export default Batch;
