import APP_SETTINGS from './settings';

export const APP = APP_SETTINGS;

export {
  JWT, AUTH, CLIENT_AUTH_REQUEST_TYPE, CLIENT_AUTHENTICATION_METHOD
} from './auth';

export { VERSION, RESPONSE_CODE } from './enums';

const LOGIN_URL = '/login';

const ERROR_URL = '/err';

const LOGIN_SUCCESS_BASE_URL = 'sales';

const IMAGE_SERVER = `${APP.IMAGE.ENDPOINT.PROTOCOL}://${APP.IMAGE.ENDPOINT.HOST}`;

const IMAGE_SERVER_TOKEN = `${APP.IMAGE.TOKEN}`;

const FILE_SERVER = `${APP.FILE.ENDPOINT.PROTOCOL}://${APP.FILE.ENDPOINT.HOST}`;

export { IMAGE_SERVER, FILE_SERVER };
