import { fixedFloatAndCommas } from '../../../../utils/conversion';

const getTableData = (dataList, companyData) => {
  // console.log('dataList',dataList)
  let tableDataRows = [];
  const getUOM = (list, field) => list?.uom_list?.find(a => a?.id === list?.uomId)[field];
  if (dataList && dataList.length > 0) {
    tableDataRows = dataList?.map((data, index) => {
      let columnRow = [
        index + 1,
        data.sku ?? '',
        (data.quantity ?? 0) / getUOM(data, 'pcs') || data?.quantity || '',
        // (data.quantity ?? 0) / getUOM(data, 'pcs') ?? '',
        getUOM(data, 'label') ?? 'Pcs',
        // isAbbreviatedInvoice
        //   ? fixedFloatAndCommas(data.uomRate * data.exciseFactor * (1 + data.vatPercent / 100)) ?? '0' :
        fixedFloatAndCommas(data.uomRate) ?? '0',
        fixedFloatAndCommas(data.discount) ?? '0',
        fixedFloatAndCommas(data.netAmount) ?? '0',
      ];
      companyData?.printInfo?.batchEnabled && columnRow.splice(2, 0, data.batch ?? '');
      return columnRow;
    });
  }
  return {
    tableDataRows,
  };
};

const getFilteredData = (dataList, companyData, headerData, totalDatas) => {
  const tableData = getTableData(dataList, companyData);
  const agentInfo = [{ title: 'Sub-D Email', value: companyData?.email ?? '' }];
  const footerMiscellaneousList = [{ data: agentInfo, partition: 1 }];
  const copyOfMiscellaneous = [...headerData.dataMiscellaneousList];
  // copyOfMiscellaneous.pop();
  const getInvoicedata = copyOfMiscellaneous.shift();
  return {
    companyName: companyData?.title ?? '',
    address: companyData?.address ?? '',
    phone: companyData?.phone ?? '',
    panNo: companyData?.panNo || companyData?.panNumber || '',
    // email: companyData?.email ?? '',
    title: headerData?.title ?? '',
    // printCount: headerData?.printCountInfo?.value ?? '',
    date: headerData?.date ?? '',
    miti: headerData?.miti ?? '',
    paymentDetail: headerData?.paymentDetail ?? '',
    dataMiscellaneousList: copyOfMiscellaneous?.length > 0 ? copyOfMiscellaneous : [],
    invoicedata: getInvoicedata,
    agentInfo: agentInfo ?? [],
    footerMiscellaneousList: footerMiscellaneousList ?? [],
    tableDataRows: [...tableData?.tableDataRows] ?? [],
    tableFooterData: totalDatas,
  };
};

const getPrintData = (dataList, companyData, headerData, totalDatas) => {
  const ledgerData = getFilteredData(dataList, companyData, headerData, totalDatas);
  return ledgerData;
};

export default getPrintData;
