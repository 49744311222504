import React, { Component } from 'react';
import Dialog from './Dialog';
import tableConfig from './config';
import { validateForm } from '../../common/validation';
import PageView from '../../common/pagination/PageView';
import { TAG_CONFIG } from '../../common/domain.config';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import { ACTION } from '../../../data/enums/enums';
import { BillingSnackBar } from '../../../components/BillingMDC';

export class Tag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: { ...queryService.queryParameters },
      dialogType: ACTION.NULL,
      data: {
        list: [],
        total: 0,
      },
      tag: {
        title: '',
      },
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
      deleteId: '',
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { getTagList } = this.props;
    const { queryParameters } = this.state;

    getTagList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total;
      this.setState({ data });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleEditIconClick = data => {
    const { tag } = this.state;
    tag.id_tags = data.idTags;
    tag.title = data.title;

    this.setState({
      dialogType: ACTION.UPDATE,
      tag,
    });
  };

  handleFabButtonClick = () => {
    const { tag } = this.state;
    tag.title = '';
    this.setState({
      dialogType: ACTION.ADD,
      tag,
    });
  };

  handleModalSubmit = () => {
    const { tag, validation, dialogType } = this.state;
    const formValid = validateForm(tag, validation, valid => this.setState({ validation: valid }));

    if (!formValid && dialogType !== ACTION.DELETE) return false;

    this.handleModalClose();

    switch (dialogType) {
      case ACTION.ADD:
        this.createTag();
        break;
      case ACTION.UPDATE:
        this.editTag();
        break;
      case ACTION.DELETE:
        this.deleteTag();
      default:
        break;
    }
  };

  createTag = () => {
    const { create } = this.props;
    const { tag, data } = this.state;

    const tagForRequest = {
      title: tag.title,
    };
    create(tagForRequest)
      .then(response => {
        data.list.push(response.tags);
        data.list.total += 1; // (data.list.total + 1);
        const snack = snackService.generateSuccessMessage();
        this.setState({
          data,
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage('Error while creating Tag!!');
        this.setState({
          data,
          snack,
        });
      });
  };

  editTag = () => {
    const { update } = this.props;
    const { tag, data } = this.state;

    update({
      id: tag.id_tags,
      query: {
        title: tag.title,
      },
    })
      .then(res => {
        this.loadTableData();
        const snack = snackService.generateUpdateMessage();
        this.setState({
          data,
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage('Error while updating Tag!!');
        this.setState({
          data,
          snack,
        });
      });
  };

  deleteTag = () => {
    const { deleteTag } = this.props;
    const { tag, data, deleteId } = this.state;

    deleteTag({
      id: deleteId,
    })
      .then(res => {
        this.loadTableData();
        const snack = snackService.generateUpdateMessage();
        this.setState({
          data,
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage('Error while deleting Tag!!');
        this.setState({
          data,
          snack,
        });
      });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({ dialogType: ACTION.NULL });
  };

  handleInputChange = (field, value) => {
    const { tag } = this.state;
    tag[field] = value;
    this.setState({ tag });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  handleDeleteIconClick = id => {
    this.setState({
      dialogType: ACTION.DELETE,
      deleteId: id,
    });
  };

  render() {
    const { data, display, snack, dialogType, tag, validation, queryParameters } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <div className="tags">
        <PageView
          domainConfig={TAG_CONFIG}
          headerConfig={{
            search: true,
            date: false,
            download: false,
            filter: false,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          queryParameters={queryParameters}
          data={data}
          tableConfig={tableConfig}
          onTableBodyClick={this.handleEditIconClick}
          createHandler={this.handleFabButtonClick}
          deleteHandler={this.handleDeleteIconClick}
        />

        <Dialog
          actionType={dialogType}
          onModalSubmit={this.handleModalSubmit}
          onModalClose={this.handleModalClose}
          onInputChange={this.handleInputChange}
          tag={tag}
          validationFlag={validation.flag}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

export default Tag;
