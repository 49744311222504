import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const SalesInvoiceTableBody = ({ data, onRowClick }) => {
  return (
    <tr className='cursor-pointer' onClick={() => onRowClick(data)}>
      <td className='left-align'>{moment(data.date).format('D MMM YYYY')}</td>
      <td className='left-align'>{data.invoiceNumber}</td>
      <td className='left-align'>{data.customerDetail.name}</td>
      <td className='left-align'>{data.customerDetail.address}</td>
      <td className='right-align'>{data.netAmount.toLocaleString()}</td>
    </tr>
  );
};

SalesInvoiceTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const salesInvoiceConfig = {
  headerDetails: [
    { id: 1, label: 'date', title: 'Date', className: 'cursor-pointer', sortable: true },
    { id: 2, label: 'invoiceNumber', title: 'Invoice Number', className: 'cursor-pointer left-align', sortable: true },
    { id: 3, label: 'customerDetail.name', title: 'Customer Name', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'customerDetail.address', title: 'Address', className: 'cursor-pointer', sortable: true },
    { id: 18, label: 'netAmount', title: 'Net Amount', className: 'cursor-pointer right-align', sortable: true },
  ],
};

export { salesInvoiceConfig, SalesInvoiceTableBody };
