import React, { Component } from 'react';
import Dialog from './Dialog';
import tableConfig from './config';
import history from '../../../utils/history';
import { ACTION, STATUS } from '../../../data/enums/enums';
import * as downloadService from '../../common/download.service';
import { validateForm } from '../../common/validation';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { SKU_CONFIG } from '../../common/domain.config';
import { SKU_CREATE, SKU_DETAILS } from '../../../data/enums/Route';
import { AddFab, BillingSnackBar } from '../../../components/BillingMDC';
import { catalogsApi, checkIfApiCallSuccess } from '../../common/base.api';

class SKU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
      },
      dialogType: ACTION.NULL,
      snack: { ...snackService.snackParameters },
      queryParameters: {
        search: queryService.queryParameters.search,
        sort: queryService.queryParameters.sort,
        pagination: queryService.queryParameters.pagination,
        filter: {
          business_id: [],
        },
      },
      data: {
        list: [],
        total: 0,
      },
      sku: tableConfig.getDetail(),
      batch: tableConfig.getBatchDetail(),
      validation: {
        flag: false,
        fieldList: tableConfig.batchFormValidationFields,
      },
      skuValidation: {
        flag: false,
        fieldList: tableConfig.skuValidationFields,
      },
      buList: [],
      brandList: [],
      skuList: [],
    };

    this.reference = {
      tableHeader: React.createRef(),
      tableFooter: React.createRef(),
      tableBody: React.createRef(),
      fileRef: React.createRef(),
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadBUList();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };
    updateQueryFilter =() =>{
    const {buList,queryParameters} =this.state;
    const activeBUIds = buList.map(({businessId})=>businessId);
    this.setState({queryParameters:{...queryParameters,filter:{...queryParameters?.filter,business_id:activeBUIds}}},()=>this.loadTableData())
  }

  loadBUList = () => {
    const { getBUList } = this.props;
    getBUList().then(response => {
      const filteredBUList = response.list.filter(list => list.status === STATUS.ACTIVE);
      this.setState({ buList: filteredBUList },()=> this.updateQueryFilter());
    });
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getSKUList } = this.props;
    const { queryParameters } = this.state;
    getSKUList({ query: queryParameters, extraQuery: '&with_inactive=true' }).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total;
      this.setState({ data });
    });
  };

  handleUploadIconClick = () => {
    this.setState({ dialogType: ACTION.UPLOAD });
  };

  handleEditIconClick = data => {
    const sku = tableConfig.getDetail(data);
    this.setState({ dialogType: ACTION.UPDATE, sku });
  };

  handleFabButtonClick = () => {
    const sku = tableConfig.getDetail();
    history.push(`/${SKU_CREATE}`);
    // this.setState({ dialogType: ACTION.ADD, sku });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  // get the brand list
  getBrandList = (businessUnitId = this.state.businessUnitId) => {
    catalogsApi.getBrandList({ business_id: businessUnitId }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ brandList: response.data.list });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = (brandId, businessId) => {
    // const brandId = this.state.createSKU.brand;
    // bu_id=1&bu_brand_id=3

    catalogsApi
      .getSkuUnderBrand({
        business_id: businessId,
        brand_id: brandId,
      })
      .then(response => {
        if (checkIfApiCallSuccess(response)) {
          this.setState({
            skuList: response.data.list,
          });
        }
      });
  };

  handleInputChange = (field, value) => {
    const { sku, batch } = this.state;
    sku[field] = value;
    this.setState({ sku });

    if (field === 'businessId') {
      this.getBrandList(value);
    }

    batch[field] = value;
    if (field === 'brand') {
      this.getSKUListUnderBrand(value, batch.businessId);
      batch.skuId = '';
    }

    this.setState({ batch });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({ dialogType: ACTION.NULL, validation });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  uploadSKU = () => {
    const { file, businessId } = this.state.sku;
    const formData = new FormData();
    formData.append('file', file);

    // formData.business_id = businessId;
    formData.append('business_id', businessId);

    const { upload } = this.props;
    upload(formData)
      .then(response => {
        const totalLength = response.length;
        const snack = snackService.generateSuccessMessage(`${totalLength} Entry Created`);
        this.setState({ snack }, () => this.loadTableData());
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({ snack });
      });
  };

  createSKU = () => {
    const { sku, data } = this.state;
    const { create } = this.props;

    const apiTransformedData = tableConfig.getApiTransformedData(sku);

    create(apiTransformedData).then(response => {
      const { skuData } = response;
      data.list.push(skuData);
      data.list.total += 1; // (data.list.total + 1);
      const snack = snackService.generateSuccessMessage();
      this.setState({ data, snack });
    });
  };

  editSKU = () => {
    const { sku, data } = this.state;
    const { update } = this.props;
    const apiTransformedData = tableConfig.getApiTransformedData(sku, ACTION.UPDATE);

    update({ id: sku.idLedger, query: apiTransformedData }).then(res => {
      const updatedDataList = data.list.map(item => (item.idLedger === Number(res.sku.idLedger) ? res.sku : item));
      data.list = updatedDataList;
      const snack = snackService.generateUpdateMessage();
      this.setState({ data, snack });
    });
  };

  createSKUBatch = () => {
    const { batch } = this.state;
    const { createSkuBatch } = this.props;

    const apiTransformedData = tableConfig.getApiTransformedBatchData(batch);

    createSkuBatch(apiTransformedData).then(response => {
      const snack = snackService.generateSuccessMessage();
      this.setState({ snack });
    });
  };

  handleModalSubmit = () => {
    // upload or set data from the form.
    const { sku, batch, validation, skuValidation, dialogType } = this.state;

    if (dialogType === ACTION.ADD) {
      const batchValidation = validateForm(batch, validation, valid => this.setState({ validation: valid }));
      if (!batchValidation) {
        return false;
      }
    } else {
      const skuDialogValid = validateForm(sku, skuValidation, valid => this.setState({ validation: valid }));
      if (!skuDialogValid) {
        return false;
      }
    }

    this.handleModalClose();

    switch (dialogType) {
      case ACTION.UPLOAD:
        this.uploadSKU();
        break;
      case ACTION.ADD:
        this.createSKUBatch();
        break;
      case ACTION.UPDATE:
        this.editSKU();
        break;
      default:
        break;
    }
  };

  handleFileUpload = event => {
    event.preventDefault();
    const { sku } = this.state;
    sku.file = event.target.files[0];

    this.setState({ sku });
  };

  handleRowClick = sku => {
    history.push(`/${SKU_DETAILS}/${sku.idSku}`);
  };
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      search: queryService.queryParameters.search,
      sort: queryService.queryParameters.sort,
      pagination: queryService.queryParameters.pagination,
      filter: {
        business_id: [],
      },
    });
    this.updateQueryFilter();
  }

  handleDownloadClick = (type, label) => {
    const { queryParameters } = this.state;
    const { downloadList } = this.props;
    queryParameters.filter['business_id'] = queryParameters.filter['business_id_thirdParty'];
    delete queryParameters.filter['business_id_thirdParty'];
      downloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response));
  };

  render() {
    const { queryParameters, data, display, sku, snack, dialogType, validation, buList, batch, brandList, skuList } =
      this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <div className="sku">
        <PageView
          domainConfig={SKU_CONFIG}
          headerConfig={{
            search: true,
            upload: true,
            download: true,
            date: false,
            filter: true,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          handleUploadClick={this.handleUploadIconClick}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.handleResetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          handleDownloadClick={this.basePaginationService.handleDownloadClick}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleRowClick}
          onPageSelect={this.basePaginationService.onPageSelect}
          createHandler={this.handleFabButtonClick}
        />

        {/* <AddFab handleFabClick={this.handleFabButtonClick} /> */}

        <Dialog
          sku={sku}
          batch={batch}
          buList={buList}
          brandList={brandList}
          skuList={skuList}
          validation={validation}
          actionType={dialogType}
          invlidPAN={false}
          formEmptyField={false}
          handleFileUpload={this.handleFileUpload}
          onInputChange={this.handleInputChange}
          onModalClose={this.handleModalClose}
          onModalSubmit={this.handleModalSubmit}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

const SKUWithState = withBaseState(SKU);

export default SKUWithState;
