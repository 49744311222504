import React from 'react';
import { formatToReadableDate } from '../../../utils/filterUtil';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const salesOutstandingBody = ({ dataList = [], print = false }) => (
  <tbody>
    {dataList.map((list, i) => (
      <>
        {list.total ? (
          <tr className='border-btm'>
            <td colSpan={print ? '3' : '2'}></td>
            <td className='right-align font-bold' colSpan='2'>
              Total
            </td>
            <td className='right-align font-bold'>{fixedFloatAndCommas(list.total)}</td>
            <td colSpan='2'></td>
          </tr>
        ) : (
          <tr className='cursor-pointer'>
            <td className='page_display_none'>{list.index + 1}</td>
            <td className='bold-font customer-name'>{list.groupIndex === 0 ? list.customer_name : ''}</td>
            <td>{list.voucher_number}</td>
            <td>{formatToReadableDate(list.document_date)}</td>
            <td className='right-align'>{fixedFloatAndCommas(list.bill_amount)}</td>
            <td className='right-align'>{fixedFloatAndCommas(Number(list.outstanding_amount))}</td>
            <td className='print_display_none'>{formatToReadableDate(list.due_date)}</td>
            <td className='right-align'>{fixedFloatAndCommas(list.bill_age)}</td>
          </tr>
        )}
      </>
    ))}
  </tbody>
);

const salesOutstandingConfig = [
  { id: 1, label: 'test', title: 'SN', className: 'page_display_none' },
  { id: 1, label: 'customer.customerName', title: 'Customer Name' },
  { id: 2, label: 'voucher_number', title: 'Voucher No.' },
  { id: 3, label: 'doc_date', title: 'Doc Date' },
  { id: 4, label: 'bill_amount', title: 'Bill Amount', className: 'right-align' },
  { id: 5, label: 'outstanding_amount', title: ' Outstanding Balance', className: 'right-align' },
  { id: 6, label: 'due_date', title: 'Due Date', className: 'print_display_none' },
  { id: 10, label: 'bill_age', title: 'Bill Age', className: 'right-align' },
];

const printEnums = { titleOffset: 100 };

const config = {
  header: salesOutstandingConfig,
  getTableBody: salesOutstandingBody,
  printEnums,
};

export default config;
