import PropTypes from 'prop-types';
import React from 'react';
import Header from './Header';
import PageNumber from './PageNumber';
import BillTotalContent from './BillTotalContent';
import TableHeader from '../TableHeader/TableHeader';
import {
  salesInvoiceDetailConfig,
  SalesInvoiceDetailTableBody,
} from '../../v1/views/SalesInvoice/SalesInvoiceDetailConfig';
import { AbiDetailRow } from '../../views/fundamentals/abi/detail/config';

const PrintBody = ({
  date,
  miti,
  print,
  title,
  company,
  message,
  dataList,
  printInfo,
  billSummary,
  invoiceDetail,
  paymentDetail,
  billTotalList,
  dataMiscellaneousList,
  agentInfo,
  agentExist,
  printInfoBill,
  pageConfig,
  isAbbreviatedInvoice,
  totalQuantity,
  printCountInfo,
  printFooterNote,
  exciseNumber,
  exciseStatus,
  invoicedData,
}) => {
 
  return (
    dataList.length && dataList.map((data, key) => {
      return (
        <div className='print-body zoom-less-reduce'>
          <div className='card-body'>
            <Header
              company={company}
              divClassName={key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'}
              date={date}
              miti={miti}
              pageTitle={<h2>{title}</h2>}
              dataMiscellaneousList={dataMiscellaneousList}
              paymentModeStatus={paymentDetail.status}
              paymentMode={paymentDetail.mode}
              printCountInfo={printCountInfo}
              exciseNumber={exciseNumber}
              exciseStatus={exciseStatus}
            />
            <div
              className={` fixed-table-wrapper ${!data.footer ? 'fixed-table-height ' : 'total-footer'}`}
            >
              <table
                className='batch_exist table data-table'>
                <TableHeader
                  headerDetails={pageConfig.header}
                  filterHeaderLabel={false}
                />
                <tbody>
                  {
                    data.list.map((salesInvoice, keyTest) => (
                      <SalesInvoiceDetailTableBody data={salesInvoice} index={keyTest} enableUpdate={false}
                        printInfoBill={printInfoBill}
                        isAbbreviatedInvoice={isAbbreviatedInvoice}/>
                    ))
                  }
                  {data.remainingLines.length
                    ? data.remainingLines.map((i,index) => (
                      <tr className={`${index} blank-tr`}>
                        <td></td>
                        <td className='sku'></td>
                        <td className='batch'></td>
                        <td className='quantity'></td>
                        <td className='rate'></td>
                        <td className='amount'></td>
                        <td className='discount'></td>
                        <td className='netAmount'></td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
            </div>
            {data.footer
            && (
              <div className='footer-totalList'>
                <table className='print-active total-section-wrapper footer clearfix'>
                  <BillTotalContent
                    totalList={billTotalList}
                    totalValue={billSummary.grossValue}
                    paymentMode={billSummary.paymentMode}
                    invoiceDetail={invoiceDetail}
                    print={print}
                    batchEnabled ={company.printInfo.batchEnabled}
                    company={company}
                    printInfo={printInfo}
                    totalInWords={billSummary.totalInWords}
                    remarksStatus
                    remarks={billSummary.remarks}
                    agentInfo={agentInfo}
                    totalQuantity={totalQuantity}
                    printFooterNote={printFooterNote}
                    invoicedData={invoicedData}
                  />
                </table>
              </div>
            )}
            {
              !data.footer && (
                <div className='padding-top-8 continue-nxt default-horizontal-margin-24'>
                  <span>{message}</span>
                </div>
              )
            }
          </div>
          <div className='footer-block'>
            <PageNumber value={data.page} totalPage={dataList.length}/>
          </div>
        </div>
      );
    })
  );
};

PrintBody.defaultProps = {
  signatureTitle: 'Authorized Signature',
  lineMargin: 'padding-r-8',
  invoiceDetail: { title: '', value: '' },
  printFooterNote:'',
  exciseStatus:false,
};

PrintBody.propTypes = {
  totalList: PropTypes.array,
  printFooterNote:PropTypes.string,
  exciseStatus:PropTypes.bool,
  agentInfo: PropTypes.instanceOf(Object),
};

export default PrintBody;
