import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
  Checkbox,
} from '../../../components/BillingMDC';
import tableConfig from './config';
import * as filterUtil from '../../../utils/filterUtil';
import { ACTION } from '../../../data/enums/enums';
import BillingSelect from '../../../components/Select/BillingSelect';
import DatePicker from '../../../components/DatePicker/DatePicker';
import BillingTextField from '../../../components/TextField/BillingTextField';
import GRNStyled from '../../fundamentals/grn/GRNStyled';
import moment from 'moment';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const propTypes = {
  fiscalYearRange: PropTypes.shape({
    start_date: PropTypes.number,
    end_date: PropTypes.number,
    previous_fiscal_year_closed: PropTypes.bool,
  }),
};
const defaultProps = {
  fiscalYearRange: {
    start_date: filterUtil.getCurrentDate(),
    end_date: filterUtil.getCurrentDate(),
    previous_fiscal_year_closed: false,
  },
};
const ReceiptDialog = ({
  receipt,
  actionType,
  onModalClose,
  onModalSubmit,
  onInputChange, // onChange
  ledgerList,
  validationFlag,
  tagList,
  paymentType,
  cashBank,
  chequeValidation,
  fiscalYearRange,
  from_ledger,
  addTag,
  activeTab,
  confirmStatus,
  agentList,
  serverResponseWaiting,
  backDatedStatus,
  renderLedgerSelectOptions,
}) => {
  const crudModeRead = actionType === ACTION.READ;
  const checkRefStatus = [2, 3].includes(receipt.payment_type);
  const minDate = fiscalYearRange.previous_fiscal_year_closed ? fiscalYearRange.start_date : fiscalYearRange.previous_fiscal_year_start;
  const reconileTabStatus = activeTab === tableConfig.RECEIPT_TAB.RECONCILE;
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
      className='dialog-wrapper'
    >
      <DialogHeader>
        <DialogTitle>
          {crudModeRead
            ? `#${receipt.invoice_number}`
            : `${actionType} Receipt ${
              actionType === ACTION.UPDATE || actionType === ACTION.DELETE ? `#${receipt.id}` : ''
            }`}
        </DialogTitle>
      </DialogHeader>

      {actionType === ACTION.DELETE ? (
        <div className='default-margin-24'>Are you sure you want to delete ?</div>
      ):
        (
          <DialogBody>
            <div
              className={`dialog-upperpart ${
                reconileTabStatus ? 'cursor-not-allow' : ''}`}
            >
              <div>
                <Grid className='grid-padding'>
                  <Cell col={4} tablet={4} className={`input-select ${
                    reconileTabStatus ? 'no-pointer-events' : ''}`}>
                    <label>Receipt Type</label>
                    <BillingSelect
                      name='form-field-name'
                      param='payment_type'
                      clearable={false}
                      value={receipt.payment_type}
                      labelKey='title'
                      valueKey='id'
                      disable={reconileTabStatus}
                      options={paymentType}
                      handleChange={onInputChange}
                      required
                      emptyField={validationFlag}
                      multipleParam={true}
                    />
                  </Cell>
                  <Cell col={4} tablet={4} className={`input-select ${backDatedStatus || 'disabled-opacityFull'} `}>
                    <GRNStyled>
                      <span className='date-picker-wrapper document_date'>
                        <label>Document Date</label>
                        <DatePicker
                          date={moment(receipt.document_date)}
                          onChange={(e) => onInputChange('document_date', e)}
                          maxDate={filterUtil.getCurrentDate()}
                          minDate={filterUtil.getMomentDate(minDate)}
                        />
                      </span>
                    </GRNStyled>
                  </Cell>
                  <Cell col={4} tablet={4} className={`input-select ${
                    confirmStatus ? 'no-pointer-events' : ''}`}>
                    <BillingTextField
                      value={receipt.amount}
                      handleChange={onInputChange}
                      floatingLabel='Amount'
                      param='amount'
                      required
                      zeroError={false}
                      type='number'
                      className='billing-required right-side-gap'
                      emptyField={validationFlag}
                    />
                  </Cell>
                </Grid>
                {checkRefStatus && (
                  <Grid className='grid-padding'>
                    <Cell col={4} tablet={4} className={`input-select ${
                      reconileTabStatus ? 'no-pointer-events' : ''}`}>
                      <BillingTextField
                        value={receipt.reference_number}
                        handleChange={onInputChange}
                        floatingLabel='Ref Number / Cheque Number'
                        param='reference_number'
                        className='right-side-gap'
                        required={receipt.payment_type}
                        emptyField={
                          receipt.payment_type !== 1 ? chequeValidation : false
                        }
                      />
                    </Cell>
                    <Cell col={4} tablet={4} className={`date-picker ${
                      reconileTabStatus ? 'no-pointer-events' : ''}`}>
                      <GRNStyled>
                        <span className='date-picker-wrapper document_date'>
                          <label>Ref Date</label>
                          <DatePicker
                            date={moment(receipt.reference_date)}
                            onChange={(e) => onInputChange('reference_date', e)}
                          />
                        </span>
                      </GRNStyled>
                    </Cell>
                  </Grid>
                )}
                {renderLedgerSelectOptions()}
                <Grid className='grid-padding'>
                  <Cell col={4} tablet={6} className='input-select'>
                    <label>Tag</label>
                    <BillingSelect
                      name='form-field-name'
                      param='tagid'
                      clearable={true}
                      value={receipt.tagid}
                      valueKey='idTags'
                      labelKey='title'
                      options={tagList}
                      handleChange={onInputChange}
                      multipleParam={true}
                      required={addTag && true}
                      emptyField={addTag && validationFlag}
                    />
                  </Cell>
                  <Cell col={4} tablet={4}className='agent'>
                    <label>Salesperson</label>
                    <BillingSelect
                      name='form-field-name'
                      param='agent_id'
                      clearable
                      value={receipt?.agent_id || null}
                      labelKey='name'
                      valueKey='idAgent'
                      options={agentList}
                      handleChange={onInputChange}
                    />
                  </Cell>
                  <Cell col={8} tablet={6} className='narration'>
                    <BillingTextField
                      value={receipt.narration}
                      handleChange={onInputChange}
                      floatingLabel='Narration'
                      param='narration'
                      required
                      className='right-side-gap'
                      emptyField={validationFlag}
                    />
                  </Cell>
                </Grid>
                {!reconileTabStatus ? (
                  <div className={`reconcile-checkbox ${
                    reconileTabStatus ? 'no-pointer-events' : ''}`}>
                    <Checkbox
                      checked={receipt.reconciled}
                      onChange={({ target: { checked } }) => {
                        onInputChange('reconciled', checked);
                      }}
                      name='Reconcile Now'
                    />
                    <label>Reconcile Now</label>
                  </div>
                ) : null}
              </div>
            </div>
          </DialogBody>
        )}
      <DialogFooter>
        <>
          <Button
            accent
            className='dialog-cancel modal-btn'
            onClick={() => onModalClose()}
          >
            Cancel
          </Button>
          <Button
            accent
            disabled={crudModeRead || serverResponseWaiting}
            className='dialog-ok modal-btn'
            onClick={() => {
              onModalSubmit();
            }}
          >
              Save
          </Button>
        </>
      </DialogFooter>
    </Dialog>
  );
};
ReceiptDialog.propTypes = propTypes;

ReceiptDialog.defaultProps = defaultProps;

const ConfirmationDialog = ({ actionType,   onModalConfirmClose, data, handleConfirmModalSubmit,cashBank,ledgerList  }) => {
  const toSelectedTitle=cashBank.filter((a) =>a.customerId === data.to_ledger)[0].title || '';
  const fromBankSelectedTitle =ledgerList.filter((a) =>a.customerId === data.from_ledger)[0].title || '';
  return(
    <div className='confirm-dialog'>
      <Dialog
        open={actionType == ACTION.READ}
        onClose={() => {
          onModalConfirmClose();
        }}
      >
        <DialogHeader>
          <DialogTitle>Confirmation</DialogTitle>
        </DialogHeader>

        <DialogBody>
          <div className='dialog-upperpart confirmation'>
            <p>Are you sure you want to update amount of {data.amount} from {fromBankSelectedTitle} to {toSelectedTitle}?</p>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button accent className='dialog-cancel modal-btn' onClick={() => onModalConfirmClose()}>
        Cancel
          </Button>
          <Button
            accent
            className='dialog-ok modal-btn'
            onClick={() => {
              handleConfirmModalSubmit();
            }}
          >
        Ok
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  )};

ConfirmationDialog.propTypes = propTypes;

ConfirmationDialog.defaultProps = defaultProps;

export { ReceiptDialog,ConfirmationDialog };
