import { compose } from 'recompose';

import Area from './Area';

import apiInterceptor from '../../../api/interceptor';

import { areaApi } from '../../common/base.api';

const composedArea = compose(apiInterceptor({ ...areaApi }))(Area);

export default composedArea;
