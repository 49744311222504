import React from 'react';
import PropTypes from 'prop-types';
import * as filterUtil from '../../../../utils/filterUtil';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { getPercentage } from '../../../../utils/miscellaneous';
import { VAT_PERCENTAGE } from '../../../../data/enums/enums';

const title = { original: 'Goods Received Note', };
const signatureTitle = 'Prepared By';
const getSummaryViewList = (data = {}) => [
  {
    id: 1,
    title: 'GRN Number',
    value: data?.purchaseInvoiceNumber || '',
  },
  {
    id: 1,
    title: 'Vendor Invoice Date',
    value: filterUtil.formatToReadableDate(data?.vendorInvoiceDate) || '',
  },
  {
    id: 2,
    title: 'Vendor Invoice Number',
    value: data?.vendorInvoiceNumber || '',
  },
  {
    id: 3,
    title: 'Vendor',
    value: data?.customer ? data?.customer.title : '',
  },
  {
    id: 4,
    title: 'Address',
    value: data?.address ||  '',
  },
  {
    id: 5,
    title: 'PAN No',
    value: data?.customer ? data?.customer?.panNo : '',
  },
  {
    id: 6,
    title: 'Remarks',
    value: data?.remarks || '',
  },
];

const getTotalContent = (data) => ({
  title: 'Total',
  value: data.netAmount || 0,
});
const customerDetailMapper = (detail) => ({
  invoiceId: detail.refNumber || '',
  invoiceDate: detail.vendorInvoiceDate || '',
  customerName: detail.customer ? detail.customer.title : '',
  customerAddress: detail.customer ? detail.customer.address : '',
  panNo: detail.customer ? detail.customer.panNo : '',
  salesReturnNumber: detail.salesReturnInvoiceNumber || '',
});
const billSummaryMapper = (data) => ({
  totalValue: data.netAmount || 0,
  remarks: data.remarks || '',
  totalInWords: (data.netAmount >= 1) ? data.totalInWords : '',
});

const DetailTableBody = ({ dataList = [] ,  remainingLines = [],printInfoBill=false }) =>{
  const getUOM =(list,field) =>list?.uom_list?.find((a) =>a?.id ===list?.uomId)[field];
  return (
    <tbody>
      {dataList.map((data, index) => (
        <tr>
          <td className='left-align sn'>{data.index ? data.index + 1 : index + 1}</td>
          <td className='left-align title sku'>{data.sku}</td>
          {printInfoBill.batchEnabled && <td className='batch'>{data.batch}</td>}
          <td className='right-align quantity'>{(data?.quantity/(getUOM(data,'pcs'))) || data?.quantity || ''}</td>
          <td className='right-align batch'>{getUOM(data,'label') || 'Pcs'}</td>
          <td className='right-align rate'>{fixedFloatAndCommas(data.uomRate)}</td>
          <td className='right-align print_display_none'>{data.normal/(getUOM(data,'pcs'))}</td>
          <td className='right-align print_display_none'>{data.damage/(getUOM(data,'pcs'))}</td>
          <td className='right-align print_display_none'>{data.shortage/(getUOM(data,'pcs'))}</td>
          <td className='right-align discount'>{fixedFloatAndCommas(data.discount)}</td>
          <td className='right-align netAmount'>{fixedFloatAndCommas(data.netAmount)}</td>
        </tr>
      ))}
      {remainingLines.length ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      )):null}
    </tbody>
  )};

DetailTableBody.propTypes = { dataList: PropTypes.instanceOf(Array).isRequired, };

const headerConfig = [
  {
    id: 1,
    label: 'idPurchaseDetail',
    title: 'SN',
    className: 'cursor-pointer',
    sortable: false,
  },
  // {
  //   id: 1,
  //   label: 'idPurchaseDetail',
  //   title: 'GRN Detail ID',
  //   className: 'cursor-pointer',
  //   sortable: false,
  // },
  {
    id: 2,
    label: 'sku',
    title: 'SKU',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 7,
    label: 'quantity',
    title: 'Quantity',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
  {
    id: 2,
    label: 'unit',
    title: 'Unit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 3,
    label: 'rate',
    title: 'Rate',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
  {
    id: 4,
    label: 'normal',
    title: 'Sellable',
    className: 'cursor-pointer right-align print_display_none',
    sortable: false,
  },
  {
    id: 5,
    label: 'damage',
    title: 'Damage',
    className: 'cursor-pointer right-align print_display_none',
    sortable: false,
  },
  {
    id: 6,
    label: 'shortage',
    title: 'Shortage',
    className: 'cursor-pointer right-align print_display_none',
    sortable: false,
  },
  {
    id: 8,
    label: 'promotionDiscount',
    title: 'Discount',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
  {
    id: 9,
    label: 'netAmount',
    title: 'Net Amount',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
];
const lengthyAttributes = ['sku'];
const printEnums = {
  withoutFooterLimit: 43,
  withFooterLimit: 33,
  sku: 50,
  batch: 20,
  lengthyAttributes,
};
const printEnumsBatch = {
  withoutFooterLimit: 43,
  withFooterLimit: 33,
  sku: 50,
  batch: 20,
  lengthyAttributes: [...lengthyAttributes, 'batch'],
};

const config = {
  title,
  header: headerConfig,
  getTableBody: DetailTableBody,
  getSummaryViewList,
  getTotalContent,
  customerDetailMapper,
  billSummaryMapper,
  signatureTitle,
  printEnums,
  printEnumsBatch,
  uniqueIdGetter: (i) => i.idPurchaseDetail,
};

export default config;
