import React, { Component } from 'react';
import tableConfig from './config';
import SalesOutstandingStyle from './SalesOutstandingStyle';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import * as downloadService from '../../common/download.service';
import PageHeader from '../../common/pagination/PageHeader';
import { SALES_PERSON_OUTSTANDING_REPORT_CONFIG } from '../../common/domain.config';
import * as filterUtil from '../../../utils/filterUtil';
import Loading from '../../../common/Loading';
import { Grid, Cell } from '../../../components/BillingMDC';
import BillingSelect from '../../../components/Select/BillingSelect';
import TableView from '../../common/pagination/TableView';
import withBaseState from '../../common/withBaseState';
import TableHeader from '../../../components/TableHeader';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { clone } from '../../../utils/arrayProcessor';
import { agentApi, checkIfApiCallSuccess } from '../../common/base.api';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import getPrintData from './getPrintData';
import getConfigData from './getConfigData';
import SimplePrint from '../../../components/PrintComponent/simplePrint/simplePrint';

class SalesPersonOutstandingReport extends Component {
  constructor(props) {
    super(props);
    this.box = React.createRef();
    this.state = {
      display: {
        drawer: true,
      },
      queryParameters: {
        ...queryService.queryParameters,
        date: null,
        singleDate: filterUtil.getCurrentDay(),
      },
      data: {
        list: [],
        total: '',
        grand_outstanding_total: '',
      },
      idAgent: '',
      salesPerson: [],
      snack: { ...snackService.snackParameters },
      printButtonClicked: false,
      dataList: [],
      salesPersonName: '',
      date: filterUtil.getCurrentDay(),
      miti: '',
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  componentDidMount() {
    this.getSalesPersonList();
    window.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('mousedown', this.handleOutsideClick);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleOutsideClick = event => {
    if (this.box && !this.box.current.contains(event.target)) {
      this.state.printButtonClicked === true && this.setState({ printButtonClicked: false });
    }
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.getDataForPrint();
    }
  };

  getSalesPersonList = () => {
    agentApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const salesPerson = response.data.list;
        salesPerson.push({ name: 'All', idAgent: 'all' });
        this.setState({ salesPerson });
      }
    });
  };

  loadPrintData = extraQueryString => {
    const { getList } = this.props;
    const { queryParameters, data } = this.state;
    let query = clone(queryParameters);
    query.pagination.limit = 1000;
    query.pagination.page = 1;
    getList({
      query,
      extraQueryString,
    }).then(response => {
      const groupedData = this.groupBy(response.list, 'customer_name');
      const dataWithTotal = this.getTotal(groupedData);
      data.list = Object.values(dataWithTotal).flat();
      this.setState(
        {
          data,
        },
        () => {
          this.groupPrintDataset();
        },
      );
    });
  };
  getTotal = dataObj => {
    for (const dataObjProps in dataObj) {
      dataObj[dataObjProps].forEach((y, index) => {
        y.groupIndex = index;
      });
      const totalSum = dataObj[dataObjProps].reduce(
        (sum, { outstanding_amount }) => sum + Number(outstanding_amount),
        0,
      );
      dataObj[dataObjProps].push({ total: totalSum, customer_name: 'Total' });
    }
    return dataObj;
  };
  /** get data for grid, adjust loading flag */
  loadTableData = async (print = false) => {
    const { getList } = this.props;
    const { queryParameters, idAgent, data } = this.state;
    const extraQueryString = `&agent_id=${idAgent}`;
    let query = clone(queryParameters);
    query.pagination.limit = 2000;
    query.pagination.page = 1;
    if (idAgent) {
      await getList({
        query: print ? query : queryParameters,
        extraQueryString,
      }).then(response => {
        const groupedData = this.groupBy(response.list, 'customer_name');
        const dataWithTotal = this.getTotal(groupedData);
        data.list = Object.values(dataWithTotal).flat();
        data.total = response.totalCount;
        data.grand_outstanding_total = response.grand_outstanding_total;
        this.setState(
          {
            data,
            miti: response.reportMiti,
          },  
        );
      });
    }
  };

  getDataForPrint = async () => {
    await this.loadTableData(true);
    const self = this;
    self.setState({ printButtonClicked: true });
  };

  groupPrintDataset() {
    const { data } = this.state;
    const orders = clone(data.list) || [];
    const { printEnums } = tableConfig;
    const derivedChunkSize = 41;
    const { ordinaryList, lengthyList } = this.separateOrderIntoOrdinaryAndLengthyList(orders);
    let dataList = this.derivePrintDataSet(orders, ordinaryList, lengthyList, derivedChunkSize, printEnums);
    dataList = this.addIndexInPrintDataSet(dataList);
    this.setState({ dataList });
  }
  separateOrderIntoOrdinaryAndLengthyList = orders => {
    const l1 = [];
    const l2 = [];
    orders.forEach(i => {
      const { printEnums } = tableConfig;
      if (i.customer_name && i.customer_name.length <= printEnums.titleOffset) {
        l1.push(i);
      } else {
        l2.push(i);
      }
    });
    return { ordinaryList: l1, lengthyList: l2 };
  };
  addIndexInPrintDataSet = dataList => {
    const group = [...dataList];
    let index = 0;
    for (let i = 0; i < group.length; i++) {
      for (let j = 0; j < group[i].list.length; j++) {
        if (group[i].list[j].agent_id) {
          group[i].list[j].index = index;
          index += 1;
        }
      }
    }
    return [...group];
  };

  // addIndexInPrintDataSet = (dataList) => {
  //   let  flattened= [];
  //   let flattenedList = [];
  //   dataList.forEach(d => {
  //     flattened = [...flattened, ...d.list]
  //   });
  //   const groupedList = this.groupBy(flattened,'customer_name');
  //   Object.keys(groupedList).map((d, i ) => {
  //     groupedList[d].forEach(e => {
  //       e.index = i;
  //       flattenedList.push(e);
  //     });
  // })
  //   return dataList.map(d => {
  //     d.list = d.list.map(element => {
  //       const data = flattenedList.find(dt =>
  //           dt.customer_name === element.customer_name
  //           && dt.voucher_number === element.voucher_number
  //       )
  //       element.index = data.index;
  //       return element;
  //     });
  //     return d;
  //   });
  // };

  derivePrintDataSet = (orders, ordinaryList, lengthyLists, chunkSize, printEnum) => {
    const dataList = [];
    let count = 0;
    let tempDataList = [];
    let indexChunk = chunkSize;
    let remainingOrdinary = ordinaryList.length;
    let lengthyList = [...lengthyLists];
    for (let index = 0, i = orders.length; index < i; index = indexChunk) {
      const chunkObj = {
        list: [],
        footer: false,
        page: 0,
      };
      let lengthyListAddedItems = [];
      count += 1;
      if (remainingOrdinary >= chunkSize) {
        chunkObj.list = ordinaryList.splice(0, chunkSize);
        remainingOrdinary = ordinaryList.length;
      } else {
        chunkObj.list = ordinaryList.splice(0, ordinaryList.length);
        remainingOrdinary = ordinaryList.length;
        let availableLines = chunkSize - chunkObj.list.length;
        let lengthySpliceIndex = 0;
        lengthyList.forEach((o, i) => {
          let requiredLines = 0;
          requiredLines = Math.max(o.customer_name && o.customer_name.length / printEnum.titleOffset);
          if (requiredLines <= availableLines) {
            chunkObj.list = [...chunkObj.list, o];
            availableLines -= requiredLines;
            lengthySpliceIndex += 1;
          }
        });
        lengthyList.splice(0, lengthySpliceIndex);
      }
      tempDataList = tempDataList.concat(chunkObj.list);
      if (orders.length - tempDataList.length === 0) {
        chunkObj.footer = true;
        chunkObj.page = count;
      } else {
        chunkObj.footer = false;
        chunkObj.page = count;
      }
      indexChunk = tempDataList.length;
      dataList.push(chunkObj);
    }
    return dataList;
  };

  handleInputChange = (field, value, detail) => {
    this.setState({ [field]: value, salesPersonName: detail.name }, () => this.loadTableData());
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleDownloadClick = reportType => {
    const queryParameter = this.basePaginationService.stateGetter();
    const { idAgent } = this.state;
    const { downloadList } = this.props;
    if (idAgent) {
      downloadList({
        type: reportType,
        query: queryParameter,
        extraQueryString: `&agent_id=${idAgent}`,
      }).then(response => downloadService.resolver(response));
    } else {
      this.setState({ snack: { ...snackService.generateFailureMessage('Customer not selected!') } });
    }
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  groupBy = (list, property) =>
    list.reduce((acc, x) => {
      let key = x[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(x);
      return acc;
    }, {});

  render() {
    const {
      data,
      display,
      idAgent,
      queryParameters,
      salesPerson,
      salesPersonName,
      printButtonClicked,
      dataList,
      date,
      miti,
    } = this.state;
    const { serverResponseWaiting, company } = this.props;

    const middleHeaderData = {
      date: {
        asOfDate: true,
        value: queryParameters.singleDate,
      },
      title: SALES_PERSON_OUTSTANDING_REPORT_CONFIG?.title,
    };

    const extraHeaderData = {
      salesPersonName,
      grandTotal: fixedFloatAndCommas(data.grand_outstanding_total) ?? '',
    };

    const printData = getPrintData([...data.list], company, middleHeaderData, extraHeaderData);
    const configData = getConfigData();

    return (
      <SalesOutstandingStyle>
        <div className={'display-block sales-outstanding'}>
          <PageHeader
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            handleDownloadClick={this.handleDownloadClick}
            controlDisplay={this.controlDisplay}
            clearSearchText={this.basePaginationService.clearSearchText}
            queryParameters={queryParameters}
            domainConfig={SALES_PERSON_OUTSTANDING_REPORT_CONFIG}
            display={display}
            config={{
              search: true,
              date: false,
              upload: false,
              download: true,
              filter: true,
              create: false,
              singleDate: true,
              print: true,
            }}
            handlePrintClick={this.getDataForPrint}
            printReference={this.box}
            resetFilter={this.basePaginationService.resetFilter}
            handleDateChange={this.basePaginationService.handleDateChange}
            serverResponseWaiting={serverResponseWaiting}
          />
          <div className="sales-header">
            <BillingSelect
              name="form-field-name"
              placeholder="Select Sales Person..."
              param="idAgent"
              clearable={false}
              value={idAgent}
              labelKey="name"
              valueKey="idAgent"
              options={salesPerson}
              handleChange={this.handleInputChange}
              multipleParam
            />
            <p className="total-amount right-align">
              Grand Total Outstanding
              {data.grand_outstanding_total && <span>{fixedFloatAndCommas(data.grand_outstanding_total)}</span>}
            </p>
          </div>
          <TableView
            config={tableConfig}
            data={data}
            display={display}
            pagination={queryParameters.pagination}
            serverResponseWaiting={serverResponseWaiting}
            onPageSelect={this.basePaginationService.onPageSelect}
            handleTableSorting={this.handleTableSorting}
            btnDisplay={false}
          />
        </div>
        {printButtonClicked && (
          <div className="display-block portrait-type">
            <SimplePrint
              configData={configData}
              printData={printData}
              orientation="portrait"
              variation="multipleFooter"
            />
          </div>
        )}
      </SalesOutstandingStyle>
    );
  }
}

const SalesPersonOutstandingReportState = withBaseState(SalesPersonOutstandingReport);

export default SalesPersonOutstandingReportState;
