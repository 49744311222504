import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';

import CatalogDetailsView from './View';
import { formValidationFields } from './config';
import Loading from '../../../../../common/Loading';
import { ACTION, STATUS } from '../../../../../data/enums/enums';
import { validateForm } from '../../../../common/validation';
import * as snackService from '../../../../common/snack.service';
import { filterListTransformer } from '../../../../common/DrawerFilter/config';
import { CATALOG_LEVEL_MAPPER, CATALOGS } from '../../../../../data/enums/catalog';
import BillingSnackBar from '../../../../../components/BillingMDC/BillingSnackbar';
import { getBUFilterList } from '../../../../common/common';

const propTypes = {
  crudMode: PropTypes.string,
  cancelFlag: PropTypes.bool,
  serverResponseWaiting: PropTypes.bool,
  getBUList: PropTypes.func.isRequired,
  getCatalogDetails: PropTypes.func.isRequired,
  catalogDetailList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  crudMode: ACTION.ADD,
  cancelFlag: false,
  catalogDetailList: [],
  serverResponseWaiting: false,
};

class CatalogDetails extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(props, state) {
    const { catalogBars, cancelFlag } = state;
    const { catalogDetailList } = props;
    if (props.cancelFlag !== cancelFlag || (catalogDetailList.length > 0 && catalogBars.length === 0)) {
      return {
        catalogBars: props.catalogDetailList,
        crudMode: props.crudMode,
        cancelFlag: props.cancelFlag,
      };
    }
    return {
      crudMode: props.crudMode,
      catalogBars,
      cancelFlag,
    };
  }

  state = {
    catalogs: CATALOGS,
    crudMode: ACTION.ADD,
    catalogBars: [],
    businessId: 0,
    snack: { ...snackService.snackParameters },
    cancelFlag: false,
    dialogType: ACTION.NULL,
    addSkuCatalogs: {
      title: '',
      catalogId: 0,
      businessId: 0,
      parentCatalogDetailId: 0,
    },
    selectedId: 0,
    selectedCatalog: [],
    parentGroupList: [],
    validation: {
      flag: false,
      fieldList: formValidationFields,
    },
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { catalogDetailList } = this.props;
    const { catalogBars, cancelFlag } = prevState;
    if (this.props.cancelFlag !== cancelFlag || (catalogDetailList.length > 0 && catalogBars.length === 0)) {
      this.fetchCatalogDetailsListInCatalogBar(catalogDetailList);
    }
  }

  fetchCatalogDetailsListInCatalogBar = catalogBar => {
    catalogBar.forEach((catalog, index, arr) => {
      const title = catalog.catalogDetail ? catalog.catalogDetail.title : '';
      if (index === 0) {
        this.setState({ businessId: catalog.businessId }, () => this.getBUList(catalog.businessCatalogId, title));
      } else {
        this.fetchCatalogDetails(
          catalog.businessCatalogId,
          arr[index - 1].businessCatalogDetailId,
          arr[0].businessId,
          title,
        );
      }
    });
  };

  getBUList = (catalogId, title = '') => {
    const { crudMode } = this.state;
    const { getBUList } = this.props;
    getBUList().then(response => {
      const filteredBUList = getBUFilterList(response.list);
      const transformedResponse = filterListTransformer(crudMode === ACTION.ADD ? filteredBUList : response.list, {
        title: 'businessAlias',
        business_catalog_detail_id: 'businessId',
        status: 'status',
      });
      this.setState(({ catalogBars }) => ({
        catalogBars: this.getUpdatedCatalogBars(catalogBars, catalogId, transformedResponse, title),
      }));
    });
  };

  defaultCatalogBarItem = (catalogId = 0) => ({
    businessCatalogId: catalogId,
    businessCatalogDetailId: 0,
    catalogDetailTitle: '',
    catalogDetailList: [],
    flag: false,
  });

  generateCatalogBar = () => {
    const { catalogs } = this.state;
    const list = catalogs.map(catalog => this.defaultCatalogBarItem(catalog.LEVEL));

    this.setState({ catalogBars: [...list] }, () => this.getBUList(CATALOG_LEVEL_MAPPER.PRINCIPAL));
  };

  fetchCatalogDetails = (catalogId, detailId, buId = null, title = '') => {
    const { getCatalogDetails } = this.props;
    const { businessId } = this.state;
    const catalogDetailId = catalogId === 2 ? 1 : detailId;
    getCatalogDetails({ businessId: buId || businessId, catalogDetailId }).then(response => {
      this.setState(({ catalogBars }) => ({
        catalogBars: this.getUpdatedCatalogBars(catalogBars, catalogId, response, title),
      }));
    });
  };

  getUpdatedCatalogBars = (catalogBars, updatedCatalogId, catalogDetailsList, title = '') =>
    catalogBars.map(catalog => {
      if (catalog.businessCatalogId === updatedCatalogId) {
        return {
          ...catalog,
          catalogDetailTitle: title,
          catalogDetailList: [...catalogDetailsList],
        };
      }
      return catalog;
    });

  handleAddClick = () => {
    this.generateCatalogBar();
  };

  handleCatalogSelect = () => {};

  handleCatalogDetailsSelect = (param, value, event, catalogDetail) => {
    const { catalogBars } = this.state;
    let { businessId } = this.state;
    const catalogDetailRow = { ...catalogDetail };

    catalogDetailRow[param] = event.title;
    catalogDetailRow.businessCatalogDetailId = value;
    if (value) catalogDetailRow.flag = false;
    if (catalogDetailRow.businessCatalogId === CATALOG_LEVEL_MAPPER.PRINCIPAL) {
      businessId = value;
    }
    this.setState(
      {
        catalogBars: catalogBars.map(catalog => {
          if (catalog.businessCatalogId === catalogDetailRow.businessCatalogId) {
            return catalogDetailRow;
          }
          if (catalog.businessCatalogId > catalogDetailRow.businessCatalogId) {
            return this.defaultCatalogBarItem(catalog.businessCatalogId);
          }
          return catalog;
        }),
        businessId,
      },
      () => this.fetchCatalogDetails(catalogDetailRow.businessCatalogId + 1, catalogDetailRow.businessCatalogDetailId),
    );
  };

  handleCancelClick = catalogDetailRow => {
    const { catalogBars } = this.state;
    this.setState({
      catalogBars: catalogBars.map(catalog => {
        if (catalog.businessCatalogId === catalogDetailRow.businessCatalogId) {
          catalog.businessCatalogDetailId = '';
        }
        if (catalog.businessCatalogId > catalogDetailRow.businessCatalogId) {
          return this.defaultCatalogBarItem(catalog.businessCatalogId);
        }

        return catalog;
      }),
    });
  };

  checkAddDisplay = () => {
    const { crudMode } = this.props;
    const { catalogBars, catalogs } = this.state;
    if (crudMode !== ACTION.READ && catalogBars.length !== catalogs.length) {
      return true;
    }

    return false;
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  getValidation = () => {
    const { catalogBars, snack } = this.state;
    let valid = true;

    if (catalogBars.length === 0) {
      this.setState({ snack: { ...snackService.generateFailureMessage('Catalog Details not added') } });
      return false;
    }
    const msgEnabledCatalogBar = catalogBars.map(catalog => {
      if (!catalog.businessCatalogDetailId) {
        valid = false;
        catalog.flag = true;
      }
      return catalog;
    });
    this.setState({
      catalogBars: msgEnabledCatalogBar,
      snack: valid ? snack : { ...snackService.generateFailureMessage('Catalog Details not added') },
    });

    return valid;
  };

  exportData = () => {
    const { catalogBars, businessId } = this.state;

    return { catalogBars, businessId };
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({
      dialogType: ACTION.NULL,
      validation,
      addSkuCatalogs: {
        title: '',
        catalogId: 0,
        businessId: 0,
        parentCatalogDetailId: 0,
      },
    });
  };

  handleIconClick = (dialogType = ACTION.ADD, id) => {
    const { catalogs, catalogBars, addSkuCatalogs } = this.state;

    const selectedCatalog = catalogs.filter(catalog => catalog.LEVEL === id);
    const parent = catalogBars.filter(list => list.businessCatalogId === id - 1);

    if (parent.length > 0) {
      const parentGroupList = parent[0].catalogDetailList;
      this.setState({
        parentGroupList,
        addSkuCatalogs: {
          ...addSkuCatalogs,
          catalogId: id,
          businessId: parentGroupList.length > 0 && parentGroupList[0].business_id,
          parentCatalogDetailId: parent.length > 0 && parent[0].businessCatalogDetailId,
        },
      });
    }
    this.setState({ dialogType, selectedCatalog, selectedId: id });
  };

  handleInputChange = (field, value, event) => {
    const { addSkuCatalogs } = this.state;
    addSkuCatalogs[field] = value;
    this.setState({ addSkuCatalogs });
  };

  onModalSubmit = () => {
    const { addSkuCatalogs, validation, selectedCatalog, selectedId } = this.state;
    const valid = validateForm(addSkuCatalogs, validation, valid => this.setState({ validation: valid }));
    if (!valid) return false;
    this.handleModalClose();
    // businessId and parentCatalogDetailId for PRODUCT_CATEGORY
    if (CATALOG_LEVEL_MAPPER.PRODUCT_CATEGORY === selectedId) {
      addSkuCatalogs.businessId = addSkuCatalogs.parentCatalogDetailId;
      addSkuCatalogs.parentCatalogDetailId = 1;
    }
    const { addCatalogs } = this.props;

    addCatalogs(addSkuCatalogs)
      .then(response => {
        if (response) {
          this.setState(
            {
              snack: {
                ...snackService.generateSuccessMessage(
                  `${selectedCatalog.length > 0 && selectedCatalog[0].TITLE} added`,
                ),
              },
            },
            this.fetchCatalogDetails(addSkuCatalogs.catalogId, addSkuCatalogs.parentCatalogDetailId),
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          snack: {
            ...snackService.generateFailureMessage('error'),
          },
        });
      });
  };

  render() {
    const {
      catalogs,
      catalogBars,
      snack,
      businessId,
      dialogType,
      addSkuCatalogs,
      selectedId,
      selectedCatalog,
      parentGroupList,
      validation,
    } = this.state;

    const { crudMode, serverResponseWaiting } = this.props;
    const displayAdd = this.checkAddDisplay();
    return (
      <>
        <Loading display={serverResponseWaiting} />
        <CatalogDetailsView
          businessId={businessId}
          crudMode={crudMode}
          catalogs={catalogs}
          onAddClick={this.handleAddClick}
          displayAdd={displayAdd}
          catalogBars={catalogBars}
          handleCatalogDetailSelect={this.handleCatalogDetailsSelect}
          handleCatalogSelect={this.handleCatalogSelect}
          handleCancelClick={this.handleCancelClick}
          selectedId={selectedId}
          dialogType={dialogType}
          validation={validation}
          addSkuCatalogs={addSkuCatalogs}
          selectedCatalog={selectedCatalog}
          parentGroupList={parentGroupList}
          onIconClick={this.handleIconClick}
          onModalSubmit={this.onModalSubmit}
          handleModalClose={this.handleModalClose}
          handleInputChange={this.handleInputChange}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </>
    );
  }
}

CatalogDetails.propTypes = propTypes;

CatalogDetails.defaultProps = defaultProps;

export default CatalogDetails;
