import { compose } from 'recompose';

import PRNDetail from './PRNDetail';

import apiInterceptor from '../../../../api/interceptor';

import { prnApi } from '../../../common/base.api';

const composedPurchaseReturnDetail = compose(apiInterceptor({ ...prnApi }))(PRNDetail);

export default composedPurchaseReturnDetail;
