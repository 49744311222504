import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as httpUtils from '../../../utils/httpUtils';
import Pagination from '../../../components/Pagination';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import DatePicker from '../../../components/DatePicker/DatePicker';
import BaseFilter from '../../../components/FilterComponent/BaseFilter';
import CustomPopUp from '../../../components/CustomPopUp';
import * as appConfig from '../../config';
import TableHeader from '../../../components/TableHeader';
import DateToRender from '../../../components/DateToRender';
import ReactDOM from 'react-dom';
import moment from 'moment';
//import {Router, Route, IndexRoute, browserHistory} from 'react-router';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Textfield,
  Fab,
  Menu,
  MenuAnchor,
  MenuItem,
} from 'react-mdc-web';
import { getFixTableMeasurements } from '../../../utils/tableMetrics';
import * as appConstants from '../../config';
import { salesInvoiceConfig, SalesInvoiceTableBody } from './SalesInvoiceConfig';
import orderBy from 'lodash/orderBy';
import * as filterUtil from '../../../utils/filterUtil';
import InvoiceDetails from '../SalesInvoice/SalesInvoiceDetail';
import _ from 'lodash';
import { connect } from 'react-redux';
import { GRN_CREATE, SALES_CREATE } from '../../../data/enums/Route';
import history from '../../../utils/history';
//const businessUnitID = JSON.parse(localStorage.getItem('business_id'));
//const businessUnitID = 1;
const downloadFormat = [
  { id: 1, value: 'Download Sales Invoice PDF' },
  { id: 2, value: 'Download Sales Invoice CSV' },
  { id: 3, value: 'Download Sales Invoice XLS' },
];

let salesId = '';
let firstCopy = false;
class SalesInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      showMenu: false,
      loading: false,
      showSearchBox: false,
      totalData: '',
      searchValue: '',
      dateClickCount: 0,
      businessUnitId: '',
      dataSet: [],
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        filter: {
          dse_user_id: [],
          asm_user_id: [],
          stl_user_id: [],
          distributor_id: [],
          category_id: [],
          brand_id: [],
          sub_brand_form_id: [],
          sku_id: [],
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
        },
      },
      invoiceNumber: '',
      printDetails: {
        transaction: '',
        invoiceNumber: '',
        printedBy: '',
      },
      startPrint: false,
      printInfo: [
        { title: 'Printed On', value: '' },
        { title: 'Printed Time', value: '' },
        { title: 'Print Count', value: '' },
        { title: 'Printed By', value: '' },
      ],
    };
  }

  /** adjust table width and height according to screen **/
  componentDidMount() {
    this.getTableDataForGrid();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    let table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
  }

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    this.setState({ loading: true });
    const { queryParameters, businessUnitId } = this.state;
    const filterDetailsWithAssignedAttributes = filterUtil.getObjectWithAssignedAttributes(queryParameters.filter);
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    queryParameters.filter = filterDetailsWithAssignedAttributes;
    httpUtils
      .get(
        appConstants.baseUrl +
          `sales-invoice?start_date=${queryParameters.date.start_date.toString()}&end_date=${queryParameters.date.end_date.toString()}&bu=${businessUnitId}&page=${
            queryParameters.pagination.page
          }&limit=${queryParameters.pagination.limit}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data, totalData: response.totalCount, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        self.setState({ loading: false });
        console.error(error);
      });
  };

  resetFilter = () => {
    const { queryParameters } = this.state;
    Object.keys(queryParameters.filter).map(attribute => {
      queryParameters.filter[attribute] = [];
    });
    queryParameters.pagination.page = 1;
    queryParameters.date.start = filterUtil.getStartOfCurrentMonth();
    queryParameters.date.end = filterUtil.getCurrentDay();
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getTableDataForGrid());
  };

  handleDateRangeChange = (start, end) => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };
  handleDateChange = date => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = date.format('DD MMM YYYY');
    this.setState({ queryParameters: queryParameters }, () => {
      //this.getTableDataForGrid();
    });
  };

  handleDownloadClick = id => {
    let type = '';
    const { user } = this.props;
    const self = this;
    const schema = 'https://';
    const { queryParameters } = this.state;
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    if (id === 1) {
      type = 'PDF';
    } else if (id === 2) {
      type = 'CSV';
    } else {
      type = 'XLS';
    }
    httpUtils
      .get(
        appConstants.baseUrl +
          `download/sales-invoice-report?start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&bu=${this.state.businessUnitId}&page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}&user_id=${user.idUsers}&type=${type}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ loading: false });
          const url = response.data.url;
          const newWin = window.open(schema + window.location.hostname + url);
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            alert('Please enable pop for this site');
          }
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleFilterChange = (fieldValue, selectedValue) => {
    const queryParameter = Object.assign({}, this.state.queryParameters);
    queryParameter.filter[fieldValue] = selectedValue;
    queryParameter.pagination.page = 1;
    this.setState({ queryParameters: queryParameter }, () => {
      this.getTableDataForGrid();
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.dataSet;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';
    const data = orderBy(this.state.dataSet, labelName, sortingName);
    return orderBy(this.state.dataSet, labelName, sortingName);
  };

  handleFabButtonClick = () => {
    history.push(`/${SALES_CREATE}`);
  };

  onSalesInvoiceItemClick = data => {
    salesId = data.idSales;
    firstCopy = false;
    const printInfo = [
      {
        title: 'Printed On',
        value: data.printDetail.hasOwnProperty('localPrintDate')
          ? moment(data.printDetail.localPrintDate).format('ll')
          : '',
      },
      {
        title: 'Printed Time',
        value: data.printDetail.hasOwnProperty('localPrintTime') ? data.printDetail.localPrintTime : '',
      },
      {
        title: 'Printed By',
        value: data.printDetail.hasOwnProperty('printedBy')
          ? data.printDetail.printedBy.hasOwnProperty('name')
            ? data.printDetail.printedBy.name
            : ''
          : '',
      },
      {
        title: 'Print Count',
        value: data.printDetail.hasOwnProperty('printCount') ? parseInt(data.printDetail.printCount) + 1 : '',
      },
    ];
    const printDetails = {
      transaction: 'SALES',
      invoiceNumber: data.invoiceNumber,
      printedBy: '',
    };
    this.setState({ startPrint: true, printInfo: printInfo, printDetails: printDetails });
    //history.push(`/sales-invoice-details/${salesId}/${firstCopy}`);
  };

  onSearchInputChange = value => {
    const self = this;
    const searchText = value;
    httpUtils
      .get(
        appConstants.baseUrl +
          `sales-invoice?&bu=${this.state.businessUnitId}&page=${this.state.queryParameters.pagination.page}&limit=${this.state.queryParameters.pagination.limit}&search_text=${searchText}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data, totalData: response.totalCount, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  inputSearch = value => {
    const searchValue = value;
    this.setState({ searchValue: searchValue }, () => {
      this.changeSearch(this.state.searchValue);
    });
  };

  afterPrint = userId => {
    const self = this;
    const { printDetails } = this.state;
    printDetails.printedBy = userId;

    window.onafterprint = function () {
      self.setState({ startPrint: false });
      httpUtils
        .post(appConfig.baseUrl + 'print', printDetails)
        .then(response => {
          if (response.success) {
            window.location.reload();
          }
        })
        .catch(error => {
          {
            console.log('error', error);
          }
        });
    };
  };

  clearInputText = () => {
    const value = '';
    const { showSearchBox } = this.state;
    this.setState({ searchValue: '', showSearchBox: !showSearchBox }, () => {
      this.onSearchInputChange(value);
    });
  };
  changeSearch = _.debounce(term => {
    this.onSearchInputChange(term);
  }, 700);

  render() {
    const { loading, queryParameters, totalData, showSearchBox, sorting, searchValue } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        {!this.state.startPrint && (
          <div className={loading ? 'clickable-false' : ''}>
            <div className="card-header-bar" ref="tableReference">
              <h2>Sales Invoice</h2>
              <div className="header-menu">
                <div className="header-menu-left ">
                  <span
                    className="search-icon-wrapper"
                    onClick={() =>
                      this.setState({
                        showSearchBox: !showSearchBox,
                      })
                    }
                  >
                    <Icon name="search" className="search-icon material-icons" />
                  </span>
                  <span className={`top-search-input-wrapper ${showSearchBox ? 'input-active' : 'input-inactive'}`}>
                    {showSearchBox && (
                      <Textfield
                        value={this.state.searchValue}
                        onChange={e => {
                          this.inputSearch(e.target.value);
                        }}
                      ></Textfield>
                    )}
                    {searchValue && (
                      <Icon name="close" className="cross-icon material-icons" onClick={this.clearInputText} />
                    )}
                  </span>
                </div>
                <div className="header-menu-right">
                  {
                    //<span className="date-picker-wrapper">
                    //<DatePicker
                    //  date={moment(queryParameters.date.start)}
                    //onChange={this.handleDateChange}
                    // />
                    //</span>
                  }
                  <span className="date-rendered">
                    <Icon name="date_range" className="material-icons" />
                    <DateToRender date={queryParameters.date} />
                  </span>
                  <span className="download-menu" onClick={() => this.setState({ showMenu: !this.state.showMenu })}>
                    <Icon name="get_app" />
                  </span>
                  <MenuAnchor>
                    <Menu
                      open={this.state.showMenu}
                      onClose={() => {
                        this.setState({ showMenu: false });
                      }}
                      className="transaction-download"
                    >
                      <MenuItem
                        onClick={() => {
                          this.handleDownloadClick(downloadFormat[0].id);
                        }}
                      >
                        {downloadFormat[0].value}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          this.handleDownloadClick(downloadFormat[1].id);
                        }}
                      >
                        {downloadFormat[1].value}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          this.handleDownloadClick(downloadFormat[2].id);
                        }}
                      >
                        {downloadFormat[2].value}
                      </MenuItem>
                    </Menu>
                  </MenuAnchor>
                  <span onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                    <Icon name="filter_list" />
                  </span>
                </div>
              </div>
            </div>

            <div ref="fixedTableBody" className="fixed-table-wrapper">
              <table>
                <TableHeader headerDetails={salesInvoiceConfig.headerDetails} handleSorting={this.handleTableSorting} />
                {sortedDataSet.map((data, key) => (
                  <tbody>
                    <SalesInvoiceTableBody data={data} onRowClick={this.onSalesInvoiceItemClick} />
                  </tbody>
                ))}
              </table>
            </div>

            {
              <div ref="fixedTableFooter">
                <Pagination
                  pageSize={queryParameters.pagination.limit}
                  currentPage={queryParameters.pagination.page}
                  orientation="top"
                  totalItems={totalData}
                  onPageSelect={this.onPageSelect}
                />
              </div>
            }

            <div className="create-fab-button">
              <Fab onClick={() => this.handleFabButtonClick()}>
                <Icon name="add" />
              </Fab>
            </div>
          </div>
        )}
        {this.state.startPrint && (
          <InvoiceDetails
            id={salesId}
            firstCopy={firstCopy}
            afterPrint={this.afterPrint}
            printInfo={this.state.printInfo}
          />
        )}
        <div dir="rtl">
          <Drawer
            open={this.state.showFilter}
            className="right-drawer"
            onClose={() => {
              this.setState({ showFilter: false });
            }}
          >
            <div className="filter-wrapper">
              <h2 className="default-horizontal-padding-24">
                <span>Filter</span>
                <span className="float-right">
                  <Icon name="replay" onClick={() => this.resetFilter()} />
                  <Icon name="clear" onClick={() => this.setState({ showFilter: false })} />
                </span>
              </h2>
              <div className="divider"></div>
              <DateRangePicker
                startDate={moment(queryParameters.date.start)}
                endDate={moment(queryParameters.date.end)}
                onChange={this.handleDateRangeChange}
              />
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

SalesInvoice.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
  };
};
const salesInvoice = connect(mapStateToProps)(SalesInvoice);

export default salesInvoice;
