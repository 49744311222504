import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import * as appConstants from '../../../config';
import { entryLogConfig, EntryLogTableBody } from './EntryLogConfig';
import * as httpUtils from '../../../../utils/httpUtils';
import Pagination from '../../../../components/Pagination/index';
import TableHeader from '../../../../components/TableHeader/index';
import DateRangePicker from '../../../../components/DateRangePicker';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Textfield,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogTitle,
  Grid,
  Cell,
  Button,
} from 'react-mdc-web';
import { getFixTableMeasurements } from '../../../../utils/tableMetrics';
import PageNumber from '../../../../components/PrintComponent/PageNumber';
import * as appConfig from '../../../config';
import * as filterUtil from '../../../../utils/filterUtil';
import DateToRender from '../../../../components/DateToRender';
import cloneDeep from 'lodash/cloneDeep';
import '../../../../styles/printStyle.css';
import orderBy from 'lodash/orderBy';

//const businessUnitID = 1;
let dataListLength = 0;
class EntryLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showSearchBox: false,
      dataSet: [],
      totalData: 1,
      dateClickCount: 0,
      businessUnitId: '',
      createModalOpen: false,
      showFilter: false,
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
        },
      },
      dataList: [],
      printButtonClicked: false,
    };
  }

  /** adjust table width and height according to screen **/
  componentDidMount() {
    this.getTableDataForGrid();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
    let table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
  }

  /** remove eventlistener on component unmount**/
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    this.setState({ loading: true });
    const { queryParameters } = this.state;
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    httpUtils
      .get(
        appConstants.baseUrl +
          `report/audit?start_date=${queryParameters.date.start}&end_date=${queryParameters.date.end_date}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          // self.setState({dataSet: response.data.list, totalData: response.data.total, loading: false});
          self.setState({ dataSet: response.data.list, totalData: response.data.total, loading: false }, () => {
            self.groupPrintDataset();
          });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getTableDataForGrid());
  };

  onSearchInputChange = inputValue => {
    //handle search value here
  };

  /** on Date Range Change */
  handleDateRangeChange = (start, end) => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.dataSet;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';
    return orderBy(this.state.dataSet, labelName, sortingName);
  };

  handlePrintClick = () => {
    const printButtonStatus = this.state.printButtonClicked;
    const self = this;
    this.setState({ printButtonClicked: true }, () => {
      window.print();
    });
    window.onafterprint = function () {
      //self.setState({printButtonClicked: false})
    };
  };

  groupPrintDataset = () => {
    const { dataSet } = this.state;
    let chunk = 17;
    let dataList = [];
    let arrayList = [];
    let count = 0;
    arrayList = cloneDeep(dataSet);
    arrayList.forEach((item, index) => {
      item['index'] = index;
      arrayList[index] = item;
    });
    for (let index = 0, j = arrayList.length; index < j; index += chunk) {
      count++;
      let chunkArray = [];
      chunkArray = arrayList.slice(index, index + chunk);
      chunkArray['page'] = count;
      dataList.push(chunkArray);
    }
    dataListLength = dataList.length;
    this.setState({ dataList: dataList });
  };

  handleKeyDown = e => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.getDataForPrint();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  render() {
    const { loading, queryParameters, totalData, createModalOpen, dataSet, sorting, dataList } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    return (
      <div>
        <div>
          {loading && (
            <div className="linear-progress-wrapper temp-progress-wrapper">
              <LinearProgress accent indeterminate />
            </div>
          )}
          <div className={this.state.printButtonClicked ? 'no-print' : 'display-block'}>
            <div className={loading ? 'clickable-false' : ''}>
              <div className="card-header-bar" ref="tableReference">
                <h2>Entry Log</h2>
                <div className="header-menu">
                  <div className="header-menu-right">
                    <span className="date-rendered">
                      <Icon name="date_range" className="material-icons" />
                      <DateToRender date={queryParameters.date} />
                    </span>
                    <span className="download-menu" onClick={() => this.handlePrintClick()}>
                      <Icon name="print" />
                    </span>
                    <span onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                      <Icon name="filter_list" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div ref="fixedTableBody" className="fixed-table-wrapper">
              <table>
                <TableHeader
                  headerDetails={entryLogConfig.headerDetails}
                  filterHeaderLabel={false}
                  handleSorting={this.handleTableSorting}
                />
                {sortedDataSet.map((data, key) => (
                  <tbody>
                    {' '}
                    <EntryLogTableBody data={data} />{' '}
                  </tbody>
                ))}
              </table>
            </div>

            {
              <div ref="fixedTableFooter">
                <Pagination
                  pageSize={queryParameters.pagination.limit}
                  currentPage={queryParameters.pagination.page}
                  orientation="top"
                  totalItems={totalData}
                  onPageSelect={this.onPageSelect}
                />
              </div>
            }
          </div>
          <div className={this.state.printButtonClicked ? 'display-block' : 'no-print'}>
            {dataList.map((data, key) => {
              return (
                <div className="lg-pdf-body print-body zoom-reduce">
                  <h2>Entry Log</h2>
                  <div ref="fixedTableBody" className="fixed-table-wrapper pdf-table-wrapper">
                    <table className="pdf-table">
                      <TableHeader
                        headerDetails={entryLogConfig.headerDetails}
                        filterHeaderLabel={false}
                        handleSorting={this.handleTableSorting}
                      />
                      {data.map((data, key) => (
                        <tbody>
                          <EntryLogTableBody data={data} />
                        </tbody>
                      ))}
                    </table>
                  </div>
                  <div className="footer-block">
                    <PageNumber value={data.page} totalPage={dataListLength} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div dir="rtl">
          <Drawer
            open={this.state.showFilter}
            className="right-drawer"
            onClose={() => {
              this.setState({ showFilter: false });
            }}
          >
            <div className="filter-wrapper">
              <h2 className="default-horizontal-padding-24">
                <span>Filter</span>
                <span className="float-right">
                  <Icon name="replay" onClick={() => this.resetFilter()} />
                  <Icon name="clear" onClick={() => this.setState({ showFilter: false })} />
                </span>
              </h2>
              <div className="divider"></div>
              <DateRangePicker
                startDate={moment(queryParameters.date.start)}
                endDate={moment(queryParameters.date.end)}
                onChange={this.handleDateRangeChange}
              />
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

EntryLog.contextTypes = {
  router: PropTypes.object,
};

export default EntryLog;
