import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import TableHeader from '../../../components/TableHeader';
import * as appConfig from '../../config';
import DatePicker from '../../../components/DatePicker/DatePicker';

import {
  Icon,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Snackbar,
} from '../../../components/BillingMDC';
import Select from 'react-select';
import history from '../../../utils/history';
import * as httpUtils from '../../../utils/httpUtils';
import BillingSelect from '../../../components/Select/BillingSelect';
import { CreateGRNTableBody, createGRNConfig } from './CreateGRNConfig';
import BillingTextField from '../../../components/TextField/BillingTextField';
import RadioButtonList from '../../../components/RadioButtonList/RadioButtonList';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { INVENTORY_BASE } from '../../../data/enums/Route';

const message = {
  success: 'GRN created successfully.',
  error: '!Error while creating GRN',
};

//invoice id is present in the list.
class CreateGoodsReceivedNote extends Component {
  constructor(props) {
    super(props);
    const { bu_id, user } = props;
    this.state = {
      loading: false,
      editModalOpen: false,
      formEmptyField: false,
      pageEmptyField: false,
      createModalOpen: false,
      deleteModalOpen: false,
      indexInInvoiceList: 0,
      showMessage: false,
      success: true,
      message: message.success,
      businessUnitId: '',
      vendorDetails: {
        id: '',
        vendorId: '',
        name: '',
        address: '',
        panNo: '',
      },
      createSKU: {
        batch: '',
        brand: '',
        brandTitle: '',
        sku_id: 0,
        skuTitle: '',
        vat: 0,
        vendor_invoice_number: '0',
        quantity: 0,
        rate: 0,
        discount: 0,
        amount: 0,
        net_amount: 0,
      },
      billSummary: {
        remarks: '',
        net_amount: 0,
        entered_by: user.idUsers,
        vat: 0,
        gross_amount: 0,
        voucher_number: 1,
      },
      invoiceId: '',
      skuList: [],
      buList: [],
      brandList: [],
      invoiceList: [],
      customerList: [],
      formValidateFieldArray: ['brand', 'sku_id', 'rate', 'quantity', 'discount', 'net_amount'],
      pageValidateFieldArray: ['name', 'address', 'panNo', 'remarks', 'invoiceId'],
      date: new Date(),
      headerTitleToView: createGRNConfig.headerTitleToView,
    };
  }

  // after mounted get the customer list.
  componentDidMount() {
    // this.getDetail();
    this.getVendorList();
    this.getBUList();
    //this.getBrandList();
  }

  //handle input change of customer Details. Fetch the list if invoice id is valid.
  onInputFieldChange = (field, value) => {
    const { billSummary } = this.state;
    billSummary[field] = value;
    this.setState({ billSummary: billSummary });
  };

  onInvoiceNumberChange = (field, invoiceId) => {
    this.setState({ invoiceId: invoiceId });
  };

  onVendorDropDownChange = (field, id, customer) => {
    const vendorDetails = {
      id: customer.idLedger,
      vendorId: customer.customerId,
      name: customer.name,
      address: customer.address,
      panNo: customer.panNo,
    };
    this.setState({ vendorDetails: vendorDetails });
  };

  //handle change of create sku events.
  onChange = (field, value, all = {}) => {
    const { createSKU } = this.state;

    createSKU[field] = value;
    // discount can not be greater than qty*rate
    if (field == 'discount' && value > createSKU.quantity * createSKU.rate) {
      createSKU.discount = 0;
      return;
    }

    if (field == 'quantity' || field == 'rate') {
      createSKU.discount = 0;
    }
    createSKU.net_amount = Number(createSKU.quantity * createSKU.rate - createSKU.discount).toFixed(2);
    if (Number(createSKU.net_amount) < 0) {
      createSKU.net_amount = '0.00';
      createSKU.discount = 0;
    }
    createSKU.amount = Number(Number(createSKU.quantity * createSKU.rate).toFixed(2));
    createSKU.vat = ((13 / 100) * createSKU.net_amount).toFixed(2);
    if (field == 'sku_id') {
      createSKU.skuTitle = all['title'] || 'title';
      //createSKU.rate = all['rate'] || 0;
      // currently the sku list rate is disabled.
      // createSKU.rate = rate;
    }
    this.setState({ createSKU: createSKU });
    //update the gross amount upon the changes.
    // in case field is brand , set the sku list
    if (field == 'brand') {
      this.getSKUListUnderBrand(value);
    }
  };

  //on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data, index) => {
    this.getSKUListUnderBrand(data.brand);
    const createSKU = {
      batch: data.batch,
      brand: data.brand,
      brandTitle: data.brandTitle,
      vendor_invoice_number: data.vendor_invoice_number,
      discount: data.discount,
      rate: data.rate,
      quantity: data.quantity,
      sku: data.sku,
      skuTitle: data.skuTitle,
      sku_id: data.sku_id,
      vat: data.vat,
      amount: data.amount,
      net_amount: data.net_amount,
    };
    // this.setState({editModalOpen: true, indexInInvoiceList: index});
    this.setState({ createSKU: createSKU, editModalOpen: true, indexInInvoiceList: index });
  };

  //create the sales Return, update the discount value
  createGRN = () => {
    const { invoiceList, createSKU } = this.state;
    //createSKU.amount = (Number(createSKU.quantity) * Number(createSKU.rate)).toFixed(2);
    invoiceList.push(createSKU);
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
      //handle SRN item update
      this.handleGRNItemUpdate();
    });
  };

  //editGRN
  editGRN = (data, index) => {
    const { invoiceList, createSKU } = this.state;
    //update the discount value
    // createSKU.discount = createSKU.quantity * createSKU.rate - createSKU.price;
    createSKU.amount = (Number(createSKU.quantity) * Number(createSKU.rate)).toFixed(2);
    invoiceList[index] = createSKU;
    this.setState({ invoiceList: invoiceList }, () => {
      this.closeModal();
      this.handleGRNItemUpdate();
    });
  };

  //delete the sales Return for particular sku, and update the total value.
  deleteGRNItem = index => {
    const { invoiceList, indexInInvoiceList } = this.state;
    if (invoiceList.length >= indexInInvoiceList) {
      invoiceList.splice(indexInInvoiceList, 1);
      this.setState({ invoiceList: invoiceList }, () => {
        this.closeModal();
        this.handleGRNItemUpdate();
      });
    }
  };

  //when SRN item number changes, update the bill total value
  handleGRNItemUpdate = () => {
    //set the invoice total.
    const { invoiceList } = this.state;
    let salesValue = 0;
    invoiceList.map(element => (salesValue += Number(element.net_amount)));
    const vat = Number(((13 / 100) * Number(salesValue)).toFixed(2));
    const { billSummary } = this.state;
    billSummary.vat = vat;
    billSummary.net_amount = Number(salesValue);
    billSummary.gross_amount = Number((Number(salesValue) + Number(vat)).toFixed(2));
    this.setState({ billSummary: billSummary });
  };

  //close the modal resetting  all the value
  closeModal = () => {
    this.setState({ createModalOpen: false, editModalOpen: false, deleteModalOpen: false });
    this.resetSKUDialog();
  };

  //reset SKU Dialog
  resetSKUDialog = () => {
    const createSKU = {
      batch: '',
      brand: '',
      brandTitle: '',
      sku_id: 0,
      skuTitle: '',
      vat: 0,
      vendor_invoice_number: '0',
      quantity: 0,
      rate: 0,
      discount: 0,
      amount: 0,
      net_amount: 0,
    };
    const skuList = [];
    this.setState({ createSKU: createSKU, formEmptyField: false, skuList: skuList });
  };

  //handle on modal ok click
  handleModalOkClick = () => {
    //check edit or create config //close the dialog
    const { createModalOpen, editModalOpen, createSKU, formValidateFieldArray } = this.state;
    if (createModalOpen || editModalOpen) {
      const flag = (createSKU.quantity && createSKU.rate) !== 0;
      const valid = this.validateForm(createSKU, formValidateFieldArray, 'formEmptyField', flag);
      if (valid) {
        if (createModalOpen) {
          this.createGRN();
        } else {
          this.editGRN(this.state.createSKU, this.state.indexInInvoiceList);
        }
      }
    } else {
      this.deleteGRNItem();
    }
  };

  validateForm = (formObject, config, flag, zeroFlag) => {
    const data = Object.keys(formObject).some(k => {
      if (config.indexOf(k) > -1) {
        let value = formObject[k];
        const conditionParameter = zeroFlag ? value === '' : !value;
        if (conditionParameter) {
          this.setState({ [flag]: true });
          return true;
        }
      }
    });
    return !data;
  };

  directToMainPage = () => {
    const { showMessage, success } = this.state;
    if (!showMessage && success) {
      history.push(`/${INVENTORY_BASE}`);
    }
  };

  // get the customer list for the selection.
  getVendorList = () => {
    this.setState({ loading: true });
    httpUtils.get(appConfig.baseUrl + '/ledger/vendor-list').then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        let responseDataList = response.data;
        responseDataList.map(customer => {
          customer['label'] = customer.customerId + '. ' + customer.name;
        });
        this.setState({ customerList: responseDataList });
      }
    });
  };

  // get the brand list
  getBrandList = () => {
    httpUtils.get(appConfig.baseUrl + 'brand/list?bu_id=' + this.state.businessUnitId).then(response => {
      if (response.success) {
        this.setState({ brandList: response.data.brandList });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = brandId => {
    httpUtils
      .get(appConfig.baseUrl + 'sku-list?bu_id=' + this.state.businessUnitId + '&bu_brand_id=' + brandId)
      .then(response => {
        if (response.success) {
          this.setState({ skuList: response.data });
        }
      });
  };

  //get BU list
  getBUList = () => {
    httpUtils.get(appConfig.baseUrl + 'upload/business-company').then(response => {
      if (response.success) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  handleBUClick = id => {
    this.setState({ businessUnitId: id }, () => {
      this.getBrandList();
    });
  };

  handleSaveClick = () => {
    //generate the object to send to the server
    const { vendorDetails, billSummary, businessUnitId, pageValidateFieldArray, invoiceId, invoiceList } = this.state;
    const toValidObject = Object.assign({}, vendorDetails);
    toValidObject['remarks'] = billSummary.remarks;
    toValidObject['invoiceId'] = invoiceId;
    const valid = this.validateForm(toValidObject, pageValidateFieldArray, 'pageEmptyField', false);
    const invoiceListCheck = invoiceList.length > 0;
    const newInvoiceList = this.state.invoiceList;
    let amountTotal = 0,
      discountTotal = 0;
    newInvoiceList.map(invoice => {
      amountTotal += Number(invoice.amount);
      discountTotal += Number(invoice.discount);
    });
    if (!invoiceListCheck) {
      alert('Purchase List  is empty');
    }
    if (valid && invoiceListCheck) {
      const object = {
        //customer_ref_id: vendorDetails.id,
        business_id: businessUnitId,
        entered_by: billSummary.entered_by,
        net_amount: billSummary.gross_amount,
        remarks: billSummary.remarks,
        vat: billSummary.vat,
        vendor_invoice_number: this.state.invoiceId,
        vendor_ref_id: vendorDetails.vendorId,
        vendor_invoice_date: this.state.date,
        amount: amountTotal,
        promotion_discount: discountTotal,
        bill_discount: 0,
        trade_discount: 0,
      };
      this.setState({ loading: true });
      // todo : backEnd filters the unnecessary object attributes.
      object['skuList'] = newInvoiceList.map(sku => {
        const skuDetails = Object.assign({}, sku);
        delete skuDetails['brand'];
        delete skuDetails['brandTitle'];
        delete skuDetails['skuTitle'];
        skuDetails['vendor_ref_id'] = vendorDetails.vendorId;
        skuDetails['vendor_invoice_number'] = this.state.invoiceId;
        return skuDetails;
      });

      httpUtils
        .post(appConfig.baseUrl + 'purchase', object)
        .then(response => {
          this.setState({ loading: false });
          if (response.success) {
            this.setState({ showMessage: true, success: true, message: message.success });
            //alert('GRN created');
            //history.push('/grn');
          }
        })
        .catch(error => {
          this.setState({ loading: false, showMessage: true, success: false, message: message.error });
        });
    }
  };

  handleDateChange = date => {
    const formattedDate = date.toDate();
    this.setState({ date: formattedDate });
  };

  handleCancelClick = () => {
    history.push(`/${INVENTORY_BASE}`);
  };

  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInInvoiceList: index });
  };

  render() {
    const {
      loading,
      invoiceList,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      vendorDetails,
      buList,
      showMessage,
    } = this.state;

    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="message-snackbar">
            {
              <Snackbar
                className={this.state.success ? 'success-message' : ''}
                timeout={this.state.success ? 1000 : 4000}
                onTimeout={() => {
                  this.setState({ showMessage: false }, () => {
                    this.directToMainPage();
                  });
                }}
                open={showMessage}
              >
                {this.state.message}
              </Snackbar>
            }
          </div>
          <div className="card-header-bar clearfix" ref="tableReference">
            <div className="header-left">
              <h2>New GRN</h2>
            </div>

            <div className="header-right">
              <span className="date-picker-wrapper">
                <DatePicker date={moment(this.state.date)} onChange={this.handleDateChange} />
              </span>
            </div>
          </div>
          <div className="card-body">
            <div className="radio-list-wrapper">
              {buList.length && <RadioButtonList data={buList} handleBuClick={this.handleBUClick} />}
            </div>
            <div className={this.state.businessUnitId ? '' : 'block-overlay'}>
              <div className="three-input-wrapper">
                <Grid>
                  <Cell col={2} className="input-select">
                    <BillingTextField
                      value={this.state.invoiceId}
                      param="invoiceId"
                      floatingLabel="Vendor Invoice Number"
                      required={true}
                      className="billing-required"
                      emptyField={this.state.pageEmptyField}
                      handleChange={this.onInvoiceNumberChange}
                    />
                    {/* <Textfield
                    className=""
                    floatingLabel="Invoice Number"
                    value={this.state.invoiceId}
                    onChange={(e) => this.onInvoiceNumberChange(e.target.value)}
                  />*/}
                  </Cell>
                  <Cell col={3} className="input-select">
                    <label>Vendor Name</label>
                    <BillingSelect
                      name="form-field-name"
                      param="name"
                      clearable={false}
                      value={this.state.vendorDetails.vendorId}
                      valueKey="customerId"
                      options={this.state.customerList}
                      handleChange={this.onVendorDropDownChange}
                      required={true}
                      emptyField={this.state.pageEmptyField}
                      multipleParam={true}
                    />
                    {/* <Select
                      name="form-field-name"
                      clearable={false}
                      value={this.state.vendorDetails.id}
                      valueKey="idLedger"
                      options={this.state.customerList}
                      onChange={(e) => this.onVendorDropDownChange('name', e)}
                    />*/}
                  </Cell>
                  <Cell col={2} className="input-field">
                    <BillingTextField
                      value={this.state.vendorDetails.address}
                      floatingLabel="Address"
                      required={true}
                      className="billing-required no-pointer-events"
                      emptyField={this.state.pageEmptyField}
                    />
                    {/*<Textfield
                      className="no-pointer-events"
                      floatingLabel="Address"
                      value={this.state.vendorDetails.address}
                    />*/}
                  </Cell>
                  <Cell col={2} className="input-field">
                    <BillingTextField
                      value={this.state.vendorDetails.panNo}
                      floatingLabel="PAN Number"
                      required={true}
                      type="number"
                      rule="isInt"
                      className="billing-required no-pointer-events"
                      emptyField={this.state.pageEmptyField}
                    />
                    {/* <Textfield
                      className="no-pointer-events"
                      floatingLabel="PAN Number"
                      value={this.state.vendorDetails.panNo}
                    />*/}
                  </Cell>
                  <Cell col={3} className="input-field">
                    <BillingTextField
                      value={this.state.billSummary.remarks}
                      param="remarks"
                      floatingLabel="Remarks"
                      required={true}
                      className="billing-required"
                      emptyField={this.state.pageEmptyField}
                      handleChange={this.onInputFieldChange}
                    />
                    {/*<Textfield
                    className=""
                    floatingLabel="Remarks"
                    value={this.state.billSummary.remarks}
                    onChange={(e) => this.onInputFieldChange('remarks', e.target.value)}
                  />*/}
                  </Cell>
                </Grid>
              </div>
              <div className="table-wrapper">
                <div ref="fixedTableBody" className="fixed-table-wrapper">
                  <table>
                    <TableHeader
                      headerDetails={createGRNConfig.headerDetails}
                      filterHeaderLabel={false}
                      handleSorting={this.handleTableSorting}
                    />
                    {invoiceList.map((data, key) => (
                      <tbody>
                        <CreateGRNTableBody
                          data={data}
                          index={key}
                          deleteSalesInvoice={this.deleteSalesInvoice}
                          handleEditIconClick={this.handleEditIconClick}
                          handleDeleteIconClick={this.handleDeleteIconClick}
                        />
                      </tbody>
                    ))}
                  </table>
                </div>

                {/*Fab Icon*/}
                <div className="mini-fab-button">
                  <Fab mini onClick={() => this.setState({ createModalOpen: true })}>
                    <Icon name="add" />
                  </Fab>
                </div>
              </div>
              {/* Sales invoice table summary goes over here.*/}
              <div className="total-section-wrapper">
                <Grid>
                  <Cell col={8}></Cell>
                  <Cell col={3} className="right-align active-opacity-text">
                    Gross
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(this.state.billSummary.net_amount)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8}></Cell>
                  <Cell col={3} className="right-align active-opacity-text">
                    VAT
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(this.state.billSummary.vat)}
                  </Cell>
                </Grid>
                <div className="hr"></div>
                <Grid>
                  <Cell col={8}></Cell>
                  <Cell col={3} className="right-align active-opacity-text">
                    Total
                  </Cell>
                  <Cell col={1} className="right-align font-bold">
                    {fixedFloatAndCommas(this.state.billSummary.gross_amount)}
                  </Cell>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div className="newinvoice-btn-wrapper">
          <Grid>
            <Cell col={8}></Cell>
            <Cell col={4} className="right-align">
              <Button
                accent
                className="cancel-btn modal-btn"
                onClick={() => {
                  this.handleCancelClick();
                }}
              >
                Cancel
              </Button>

              <Button
                accent
                className="save-btn modal-btn"
                onClick={() => {
                  this.handleSaveClick();
                }}
              >
                Save
              </Button>
            </Cell>
          </Grid>
        </div>

        {/*Begin of the dialog Body*/}
        <Dialog
          open={createModalOpen || editModalOpen || deleteModalOpen}
          onClose={() => {
            this.closeModal();
          }}
        >
          <DialogHeader>
            {createModalOpen && <DialogTitle>Create SKU</DialogTitle>}
            {editModalOpen && <DialogTitle>Edit SKU</DialogTitle>}
            {deleteModalOpen && <DialogTitle>Delete SKU</DialogTitle>}
          </DialogHeader>
          {!deleteModalOpen && (
            <DialogBody>
              <div className="dialog-upperpart">
                <Grid className="grid-padding">
                  <Cell col={6} tablet={8}>
                    <label>Brand</label>
                    <BillingSelect
                      name="form-field-name"
                      param="brand"
                      clearable={false}
                      value={this.state.createSKU.brand}
                      valueKey="businessCatalogDetailId"
                      labelKey="title"
                      options={this.state.brandList}
                      handleChange={this.onChange}
                      required={true}
                      emptyField={this.state.formEmptyField}
                    />
                  </Cell>

                  <Cell col={6} tablet={8}>
                    <label>SKU</label>
                    <BillingSelect
                      name="form-field-name"
                      param="sku_id"
                      clearable={false}
                      value={this.state.createSKU.sku_id}
                      valueKey="business_sku_id"
                      labelKey="title"
                      options={this.state.skuList}
                      handleChange={this.onChange}
                      required={true}
                      emptyField={this.state.formEmptyField}
                      multipleParam={true}
                    />
                  </Cell>
                </Grid>

                <Grid className="grid-padding">
                  <Cell col={4} tablet={8}>
                    <BillingTextField
                      value={this.state.createSKU.batch}
                      handleChange={this.onChange}
                      floatingLabel="Batch"
                      param="batch"
                      required={false}
                      className="billing-required right-side-gap"
                      /* emptyField={this.state.formEmptyField}*/
                    />
                  </Cell>

                  <Cell col={4} tablet={8}>
                    {/*<label>Rate</label>
                    <span className="accent-color dialog-detail">{this.state.createSKU.rate}</span>*/}
                    <BillingTextField
                      value={this.state.createSKU.rate}
                      handleChange={this.onChange}
                      floatingLabel="Rate"
                      param="rate"
                      type="number"
                      required={true}
                      zeroError={true}
                      className="billing-required right-side-gap"
                      emptyField={this.state.formEmptyField}
                      errorMessage={this.state.createSKU.rate === 0 ? 'Should not be 0' : 'Should not be empty'}
                    />
                  </Cell>

                  <Cell col={4} tablet={8} className="relative-container default-margin-top-7">
                    <BillingTextField
                      value={this.state.createSKU.quantity}
                      handleChange={this.onChange}
                      floatingLabel="Quantity (pcs)"
                      param="quantity"
                      required={true}
                      zeroError={true}
                      type="number"
                      rule="isInt"
                      className="billing-required right-side-gap"
                      emptyField={this.state.formEmptyField}
                      errorMessage={this.state.createSKU.quantity === 0 ? 'Should not be 0' : 'Should not be empty'}
                    />
                  </Cell>
                </Grid>

                <Grid className="grid-padding">
                  <Cell col={6} tablet={8}>
                    <BillingTextField
                      value={this.state.createSKU.discount}
                      handleChange={this.onChange}
                      floatingLabel="Discount"
                      param="discount"
                      required={true}
                      zeroError={false}
                      type="number"
                      max={this.state.createSKU.amount.toString() || '0'}
                      className="billing-required"
                      emptyField={this.state.formEmptyField}
                      errorMessage="Should not be empty"
                    />
                  </Cell>

                  <Cell col={6} tablet={8}>
                    <BillingTextField
                      value={this.state.createSKU.net_amount}
                      handleChange={this.onChange}
                      floatingLabel="Net Amount"
                      param="net_amount"
                      required={true}
                      zeroError={true}
                      type="number"
                      className="billing-required no-pointer-events"
                      emptyField={this.state.formEmptyField}
                      errorMessage={this.state.createSKU.net_amount ? 'Should not be 0' : 'Should not be empty'}
                    />
                  </Cell>
                </Grid>
              </div>
            </DialogBody>
          )}

          {deleteModalOpen && <div className="default-margin-24">Are you sure you want to delete ?</div>}

          <DialogFooter>
            <Button
              accent
              className="dialog-cancel modal-btn"
              onClick={() => {
                this.closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              accent
              className="dialog-ok modal-btn"
              onClick={() => {
                this.handleModalOkClick();
              }}
            >
              Ok
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

CreateGoodsReceivedNote.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
  };
};
const CreateGRN = connect(mapStateToProps)(CreateGoodsReceivedNote);

export default CreateGRN;
