import React from 'react';

const HorizontalLabelValueBar = ({ label, value, key }) => {
  return (
    <React.Fragment>
      <span className='label'>{label}</span>
      <span key={key} className='input'>
        {value}
      </span>
    </React.Fragment>
  );
};

export default HorizontalLabelValueBar;
