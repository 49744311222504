import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CollapsibleTag, MENUS, LinkTag } from './config';
import LeftDrawerStyled from './LeftDrawerStyled';
import { Drawer, Navigation } from 'react-mdc-web/lib/Drawer';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Collapsible } from '../../components/Collapsible/index';

class LeftDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeState: '',
      openedState: '',
    };
  }

  /**
   * Function for toggling the view of the menu list inside of a menu item in the navigation drawer
   * @param menu
   * @param e
   * @param link[by default null]
   */

  componentDidMount() {
    this.checkIfSuperUser();
  }

  checkIfSuperUser = () => {
    const { user } = this.props;
    if (user.group === '1') {
      // linkList.push({ url: '/database-log', acronym: 'DL', title: 'Database Log' });
    }
  };
  // eslint-disable-next-line max-len
  getPermissionEnabledList = (linkList, permission) => {
    return linkList.filter(config => {
      if (config.children && config.children.length > 0) {
        config.children = config.children.filter(c => permission.includes(c.program));

        return config.children.length > 0;
      }

      return permission.includes(config.program);
    });
  };

  render() {
    const { onDrawerStateChange, drawerMiniState, path, permission } = this.props;
    const permissionEnabledList = this.getPermissionEnabledList(MENUS, permission);

    return (
      <LeftDrawerStyled>
        <Drawer permanent className={`left-drawer ${drawerMiniState ? 'mini-drawer' : 'max-drawer'}`}>
          <div className="navigation-menu">
            <Navigation>
              {permissionEnabledList.map(link => (
                <>
                  {link.url ? (
                    <LinkTag link={link} drawerMiniState={drawerMiniState} />
                  ) : (
                    <CollapsibleTag
                      link={link}
                      drawerMiniState={drawerMiniState}
                      onDrawerStateChange={onDrawerStateChange}
                      path={path.pathname.substring(1)}
                    />
                  )}
                </>
              ))}
            </Navigation>
          </div>
        </Drawer>
      </LeftDrawerStyled>
    );
  }
}

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
  permission: state.billing.permission || [],
});

LeftDrawer.contextTypes = {
  router: PropTypes.object,
};

const leftDrawer = connect(mapStateToProps)(LeftDrawer);

export default leftDrawer;
