import { compose } from 'recompose';

import Company from './Company';

import apiInterceptor from '../../../api/interceptor';

import { companyApi, buApi } from '../../common/base.api';

const composedCompany = compose(
  apiInterceptor({
    ...companyApi,
    getBUList: buApi.getList,
  }),
)(Company);

export default composedCompany;
