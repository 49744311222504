import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const PurchaseReturnTableBody = ({ data, onRowClick }) => {
  return (
    <tr className='cursor-pointer' onClick={() => onRowClick(data)}>
      <td className='left-align'>{data.purchaseReturnInvoiceNumber}</td>
      <td className='left-align'>{data.refNumber}</td>
      <td className='left-align'>{moment(data.date).format('ll')}</td>
      <td className='left-align'>{data.customer.name}</td>
      <td className='right-align'>{data.netAmount}</td>
      <td className='left-align'>{data.remarks}</td>
    </tr>
  );
};

PurchaseReturnTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const purchaseReturnConfig = {
  headerDetails: [
    { id: 1, label: 'purchaseReturnInvoiceNumber', title: 'PR Number', className: 'cursor-pointer', sortable: true },
    { id: 1, label: 'refNumber', title: 'GRN Number', className: 'cursor-pointer', sortable: true },
    { id: 2, label: 'date', title: 'Date', className: 'cursor-pointer left-align', sortable: true },
    { id: 3, label: 'customer.name', title: 'Vendor Name', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'netAmount', title: 'PR Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'remarks', title: 'Remarks', className: 'cursor-pointer left-align', sortable: true },
  ],
};

export { purchaseReturnConfig, PurchaseReturnTableBody };
