import React from 'react';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const DamageExpiryValuationBody = ({ dataList ,remainingLines }) => (
  <tbody>
    {dataList.map((data) => (
      <tr>
        <td className='left-align title'>{data.title}</td>
        <td className='left-align'>{data.damageOpening}</td>
        <td className='left-align'>{data.damageOpeningValue}</td>
        <td className='right-align damage'>{data.damage}</td>
        <td className='right-align damage-value'>{data.damageValue}</td>
        <td className='right-align damage'>{data.damageClosing}</td>
        <td className='right-align damage'>{data.damageClosingValue}</td>
        <td className='right-align expiry'>{data.expiredOpening}</td>
        <td className='right-align expiry'>{data.expiredOpeningValue}</td>
        <td className='right-align expiry'>{data.expired}</td>
        <td className='right-align expiry-value'>{data.expiredValue}</td>
        <td className='right-align expiry'>{data.expiredClosing}</td>
        <td className='right-align expiry'>{data.expiredClosingValue}</td>
      </tr>
    ))}
    {remainingLines && remainingLines.length
      ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td colSpan ='14' className=''></td>
        </tr>
      ))
      : null}
  </tbody>
);

const DamageExpiryValuationConfig = [
  { id: 1, label: 'skus.title', title: 'SKU', className: 'cursor-pointer', sortable: false },
  { id: 2, label: 'damageOpening', title: 'Damage Opening', className: 'cursor-pointer right-align', sortable: false },
  {
    id: 3,
    label: 'damageOpeningValue',
    title: 'Damage Opening Value',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
  { id: 4, label: 'damage', title: 'Damage', className: 'cursor-pointer right-align', sortable: false },
  { id: 5, label: 'damage_value', title: 'Damage Value', className: 'cursor-pointer right-align', sortable: false },
  { id: 6, label: 'damageClosing', title: 'Damage Closing', className: 'cursor-pointer right-align', sortable: false },
  {
    id: 7,
    label: 'damageClosingValue',
    title: 'Damage Closing Value',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
  { id: 8, label: 'expiryOpening', title: 'Expiry Opening', className: 'cursor-pointer right-align', sortable: false },
  {
    id: 9,
    label: 'expiryOpeningValue',
    title: 'Expiry Opening Value',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
  { id: 10, label: 'expiry', title: 'Expiry', className: 'cursor-pointer right-align', sortable: false },
  { id: 11, label: 'expiryValue', title: 'Expiry Value', className: 'cursor-pointer right-align', sortable: false },
  { id: 12, label: 'expiryClosing', title: 'Expiry Closing', className: 'cursor-pointer right-align', sortable: false },
  {
    id: 13,
    label: 'expiryClosingValue',
    title: 'Expiry Closing Value',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
];
const lengthyAttributes = ['title'];
const printEnums = {
  withoutFooterLimit: 44,
  withFooterLimit: 44,
  title: 44,
  lengthyAttributes,
};

const config = {
  header: DamageExpiryValuationConfig,
  getTableBody: DamageExpiryValuationBody,
  printEnums,
  uniqueIdGetter: (i) => i.skuId,
};

export default config;
