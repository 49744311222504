import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../components/BillingMDC';
import { DOMAIN } from '../../data/enums/enums';
import { SALES_INVOICE_CONFIG } from './domain.config';
import DownloadStyled from './DownloadStyle';
import Menu from '../../components/Menu';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  config: PropTypes.shape({
    domain: PropTypes.string,
    title: PropTypes.string,
    downloadList: PropTypes.arrayOf(PropTypes.string),
    downloadListTitle: PropTypes.arrayOf(PropTypes.string),
    detailDownloadList: PropTypes.arrayOf(Object),
  }),
};

const defaultProps = {
  config: {
    domain: '',
    title: '',
    downloadList: [],
    downloadListTitle: [],
    detailDownloadList: {},
  },
};

class Download extends Component {
  constructor(props) {
    super(props);
    this.reference = { autoComplete: React.createRef(), };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { config, onClick } = this.props;
    config.detailDownloadList = config.detailDownloadList || [];
    const title = config.domain === DOMAIN.ABBREVIATED_INVOICE ? SALES_INVOICE_CONFIG.title : config.title;
    const menuHeader = <Icon name='get_app' />;
    return (
      <DownloadStyled>
        <Menu block header={menuHeader} hideOnClick ref={this.reference.autoComplete}>
          <ul>
            {config.downloadList.map((reportType, index) =>
              config.downloadListTitle && config.downloadListTitle.length === config.downloadList.length ? (
                <li onClick={() => onClick(reportType, index)}>{config.downloadListTitle[index]}</li>
              ) : (
                <li onClick={() => onClick(reportType, index)}>{`Download ${title} ${reportType}`}</li>
              ),
            )}
            {config.detailDownloadList.map((download, index) => (
              <li onClick={() => onClick(download.type, download.label)}>
                {`Download ${download.title} ${download.type}`}
              </li>
            ))}
          </ul>
        </Menu>
      </DownloadStyled>
    );
  }
}

Download.propTypes = propTypes;
Download.defaultProps = defaultProps;

export default Download;
