// TODO: Include Prop Types and Use for Composition Pattern.

import React from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
} from '../../../../components/BillingMDC';
import { fixedFloat } from '../../../../utils/conversion';
import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import BillingAsyncSelect from '../../../../components/AsyncSelect/AsyncSelect';

const CreatePrnDialog = ({
  onModalClose,
  modalOpen,
  editModalOpen,
  deleteModalOpen,
  createSKU,
  handleInputChange,
  onModalSubmit,
  skuList,
  brandList,
  batchList,
  formEmptyField,
  invoiceIdStatus,
  brandSKUList,
  stockQuantity,
  loadOptions,
}) => (
  <PRNDialogStyle>
    <Dialog
      open={modalOpen || editModalOpen || deleteModalOpen}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        {modalOpen && <DialogTitle>Select SKU</DialogTitle>}
        {editModalOpen && <DialogTitle>Edit SKU</DialogTitle>}
        {deleteModalOpen && <DialogTitle> Delete SKU</DialogTitle>}
      </DialogHeader>

      {(modalOpen || editModalOpen) && (
        <DialogBody>
          <div className='dialog-upperpart'>
            {!(editModalOpen && invoiceIdStatus) && (
              <div className='row_one row '>
                <Grid className='grid-padding'>
                  <Cell col={6} tablet={8}>
                    <label>Brand</label>
                    <BillingSelect
                        name='form-field-name'
                        param='brand'
                        clearable={true}
                        value={createSKU?.brand}
                        valueKey='indexArr'
                        labelKey='title'
                        options={brandList}
                        onChange={(e) => handleInputChange('brand', e?.indexArr, e)}
                    />
                  </Cell>

                  <Cell col={6} tablet={8}>
                    <label>SKU</label>
                    <BillingAsyncSelect
                      param='sku_id'
                      placeholder='Search/Select...'
                      loadOptions={loadOptions}
                      handleChange={handleInputChange}
                      options={skuList}
                      defaultOptions={brandSKUList}
                      getOptionLabel={({ title }) => title}
                      getOptionValue={({ businessSkuId }) => businessSkuId}
                      multipleParam
                      required
                      emptyField={formEmptyField}
                      value={skuList.filter(({ businessSkuId }) => businessSkuId === createSKU.sku_id)}
                      valueKey='businessSkuId'
                    />
                  </Cell>
                </Grid>
              </div>
            )}
            <div className='row_two row'>
              <Grid className='grid-padding'>
                {!(editModalOpen && invoiceIdStatus) && (
                  <Cell col={4} tablet={8}>
                    <label>Batch</label>
                    <BillingSelect
                      name='form-field-name'
                      param='batch_id'
                      clearable={false}
                      value={createSKU.batch_id}
                      valueKey='businessBatchId'
                      labelKey='title'
                      options={batchList}
                      handleChange={handleInputChange}
                      multipleParam
                    />
                  </Cell>
                )}
                <Cell col={4} tablet={8}>
                  <BillingTextField
                    value={fixedFloat(createSKU.rate)}
                    handleChange={handleInputChange}
                    floatingLabel='Rate'
                    param='rate'
                    required={true}
                    zeroError={false}
                    type='number'
                    className={`billing-required ${invoiceIdStatus ? 'inp-disabled' : ''}`}
                    emptyField={formEmptyField}
                    errorMessage={createSKU.rate === 0 ? 'Should not be 0' : 'Should not be empty'}
                  />
                </Cell>
                <Cell col={4} tablet={8}>
                  <label>UOM</label>
                  <BillingSelect
                    name='form-field-name'
                    param='uom'
                    clearable={false}
                    value={createSKU.uom_list?.filter((list) => list.value === createSKU?.uom?.value)}
                    multipleParam
                    options={createSKU.uom_list}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    onChange={(e) => handleInputChange('uom', e.value, e)}
                  />
                  {createSKU?.uom?.value && createSKU?.uom?.pcs !== 1 && (
                    <span className='upc-value'>{`1 ${createSKU?.uom?.value} = ${createSKU?.uom?.pcs} pcs`}</span>
                  )}
                </Cell>
              </Grid>
            </div>
            <div className='row_two three '>
              <label>Quantity</label>
              <Grid className='grid-padding'>
                <Cell col={3} tablet={8} className='relative-container'>
                  <label htmlFor=''>Sellable</label>
                  <div className='form-wrap'>
                    <BillingTextField
                      value={createSKU.normal}
                      handleChange={handleInputChange}
                      /*handleChange={handleInputChange}*/
                      param='normal'
                      required={false}
                      zeroError
                      type='number'
                      rule='isInt'
                      className='billing-required right-side-gap'
                    />
                  </div>
                  {!invoiceIdStatus && <span>{`Available Quantity: ${stockQuantity.normal}`}</span>}
                </Cell>
                <Cell col={3} tablet={8} className='relative-container'>
                  <label htmlFor=''>Shortage</label>
                  <div className='form-wrap'>
                    <BillingTextField
                      value={createSKU.shortage}
                      handleChange={handleInputChange}
                      param='shortage'
                      required={false}
                      zeroError
                      type='number'
                      rule='isInt'
                      className='billing-required right-side-gap'
                    />
                  </div>
                  {!invoiceIdStatus && <span>{`Shortage Stock: ${stockQuantity.shortage}`}</span>}
                </Cell>
                <Cell col={3} tablet={8} className='relative-container'>
                  <label htmlFor=''>Damage</label>
                  <div className='form-wrap'>
                    <BillingTextField
                      value={createSKU.damage}
                      handleChange={handleInputChange}
                      param='damage'
                      required={false}
                      zeroError
                      type='number'
                      rule='isInt'
                      className='billing-required right-side-gap'
                    />
                  </div>
                  {!invoiceIdStatus && <span>{`Damage Stock: ${stockQuantity.damage}`}</span>}
                </Cell>
                {!invoiceIdStatus && (
                  <Cell col={3} tablet={8} className='relative-container'>
                    <label htmlFor=''>Expiry</label>
                    <div className='form-wrap'>
                      <BillingTextField
                        value={createSKU.expiry}
                        handleChange={handleInputChange}
                        param='expiry'
                        required={false}
                        zeroError
                        type='number'
                        rule='isInt'
                        className='billing-required right-side-gap'
                      />
                    </div>
                    {!invoiceIdStatus && <span>{`Expiry Stock: ${stockQuantity.expiry}`}</span>}
                  </Cell>
                )}
              </Grid>
            </div>
            {/*<Cell col={4} tablet={8} className="relative-container">
              <BillingTextField
                value={createSKU.quantity}
                handleChange={handleInputChange}
                floatingLabel="Quantity (pcs)"
                param="quantity"
                required={true}
                zeroError={true}
                type="number"
                rule="isInt"
                max={createSKU.actual_quantity > 0 ? createSKU.actual_quantity:  undefined}
                className="billing-required right-side-gap"
                emptyField={formEmptyField}
                errorMessage={
                    createSKU.quantity === 0
                      ? 'Should not be 0'
                      : Number(createSKU.quantity || 0) > Number(stockQuantity)
                      ? 'Should not be greater than available quantity'
                      : ''
                  }
                  invalidError={
                    formEmptyField && (createSKU.quantity || 0)  > stockQuantity
                  }
              />
            </Cell>
*/}
            <div className='row_two four '>
              <Grid className='grid-padding'>
                <Cell col={6} tablet={8}>
                  <BillingTextField
                    value={createSKU.promotion_discount}
                    handleChange={handleInputChange}
                    floatingLabel='Discount'
                    param='discount_input'
                    required={true}
                    zeroError={false}
                    type='number'
                    max={fixedFloat(createSKU.quantity * createSKU.rate).toString()}
                    className={`billing-required ${invoiceIdStatus ? 'inp-disabled' : ''}`}
                    emptyField={formEmptyField}
                    errorMessage='Should not be empty'
                  />
                </Cell>
                <Cell col={6} tablet={8}>
                  <BillingTextField
                    value={createSKU.net_amount}
                    handleChange={handleInputChange}
                    floatingLabel='Net Amount'
                    param='net_amount'
                    required={true}
                    zeroError={true}
                    type='number'
                    className='billing-required inp-disabled'
                    emptyField={formEmptyField}
                    errorMessage={createSKU.net_amount === 0 ? 'Should not be 0' : 'Should not be empty'}
                  />
                </Cell>
              </Grid>
            </div>
          </div>
        </DialogBody>
      )}

      {deleteModalOpen && <div className='default-margin-24'>Are you sure you want to Delete ?</div>}

      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            onModalClose();
          }}
        >
          Cancel
        </Button>
        <Button accent className='dialog-ok modal-btn' onClick={() => onModalSubmit()}>
          Ok
        </Button>
      </DialogFooter>
    </Dialog>
  </PRNDialogStyle>
);

const PRNDialogStyle = styled.div`
  .row_two {
    .select-css {
      margin-top: -2px;
    }
  }
`;

export default CreatePrnDialog;
