import { RESPONSE } from '../../data/enums/enums';

const snackParameters = {
  display: false,
  message: '',
  type: '',
};

const resetSnack = () => {
  const snack = {
    display: false,
    message: '',
    type: '',
  };

  return snack;
};

const generateSuccessMessage = (message) => {
  const snack = {
    display: true,
    message: message || 'Entity Created',
    type: RESPONSE.SUCCESS,
  };

  return snack;
};

const generateUpdateMessage = (message) => {
  const snack = {
    display: true,
    message: message || 'Entity Updated',
    type: RESPONSE.SUCCESS,
  };

  return snack;
};

const generateFailureMessage = (message) => {
  const snack = {
    display: true,
    message: message || 'Operation Failed',
    type: RESPONSE.FAILURE,
  };

  return snack;
};

export default snackParameters;

export {
  resetSnack, snackParameters, generateFailureMessage, generateSuccessMessage, generateUpdateMessage
};
