import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';
const numberConfig = {
  /*minimumFractionDigits: 2,*/
  maximumFractionDigits: 2,
};
const downloadFormat = [{ id: 1, value: 'Download Ledger Report CSV' }];
const CustomerLedgerTableBody = ({ data, onRowClick }) => {
  return (
    <tr className='cursor-pointer' onClick={() => onRowClick(data)}>
      <td className='left-align'>{data.customer_id}</td>
      <td className='left-align'>{data.customer_name}</td>
      <td className='right-align'>{data.Total.openingBalance}</td>
      <td className='right-align'>
        {data.Total.DebitTotal
          ? Number(data.Total.DebitTotal).toLocaleString(undefined, numberConfig)
          : data.Total.DebitTotal}
      </td>
      <td className='right-align'>
        {data.Total.creditTotal
          ? Number(data.Total.creditTotal).toLocaleString(undefined, numberConfig)
          : data.Total.creditTotal}
      </td>
      <td className='right-align'>
        {data.Total.Balance ? Number(data.Total.Balance).toLocaleString(undefined, numberConfig) : data.Total.Balance}
      </td>
    </tr>
  );
};

CustomerLedgerTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const customerLedgerConfig = {
  headerDetails: [
    { id: 1, label: 'customer_id', title: 'Id', className: 'cursor-pointer', sortable: true },
    { id: 2, label: 'customer_name', title: 'Ledger', className: 'cursor-pointer left-align', sortable: true },
    {
      id: 3,
      label: 'Total.openingBalance',
      title: 'Opening Balance',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    { id: 4, label: 'Total.DebitTotal', title: 'Debit', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'Total.creditTotal', title: 'Credit', className: 'cursor-pointer right-align', sortable: true },
    {
      id: 6,
      label: 'Total.Balance',
      title: 'Closing Balance',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
  ],
};

export {
  customerLedgerConfig, CustomerLedgerTableBody, downloadFormat
};
