import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../utils/conversion';
import { formatToReadableDate } from '../../utils/filterUtil';

const creditNoteTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <tr onClick={() => onRowClick(data.voucherNumber)}>
        <td className='left-align'>{data.voucherNumber}</td>
        <td className='left-align'>{formatToReadableDate(data.documentDate)}</td>
        <td className='left-align'>{data.mitiTitle}</td>
        <td className='right-align'>{data.drAmount ? fixedFloatAndCommas(data.drAmount) : 0}</td>
        <td className='left-align'>{data.refNumber}</td>
        <td className='left-align'>{data.narration}</td>
      </tr>
    ))}
  </tbody>
);

creditNoteTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const creditNoteConfig = [
  { id: 1, label: 'voucher_number', title: 'Credit Note Number',sortable:true,className:'cursor-pointer' },
  { id: 2, label: 'document_date', title: 'Date',sortable:true,className:'cursor-pointer' },
  { id: 3, label: 'miti_title', title: 'Miti',sortable:true,className:'cursor-pointer' },
  { id: 4, label: 'amount', title: 'Amount', className: 'right-align' },
  { id: 5, label: 'orginal_invoice_number', title: 'Ref Invoice Number' },
  { id: 6, label: 'narration', title: 'Narration' },
];

const config = {
  header: creditNoteConfig,
  getTableBody: creditNoteTableBody,
};

export default config;
