import { compose } from 'recompose';

import apiInterceptor from '../../../api/interceptor';

import SalesReturn from './SalesReturn';
import { srnApi } from '../../common/base.api';

const composedSalesReturn = compose(apiInterceptor({ ...srnApi }))(SalesReturn);

export default composedSalesReturn;
