import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'react-date-range';
import { Grid, Cell, Icon } from 'react-mdc-web';
import * as ReactDOM from 'react-dom';
import DateRangeStyled from './DateRangeStyled';
import { focusInCurrentTargetWithReference } from '../../utils/focusOutside';
import { getMomentDate } from '../../utils/filterUtil';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { isObjectEmpty } from '../../utils/objectProcessor';

const propTypes = {
  onChange: PropTypes.func,
  date: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  maxDate: PropTypes.instanceOf(Object),
  minDate: PropTypes.instanceOf(Object),
};

const defaultProps = {
  onChange: () => null,
  maxDate: {},
  minDate: {},
};

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      date: props.date,
      displayDateView: false,
    };
    this.dateInputRef = '';
  }

  componentDidMount() {}

  // componentWillReceiveProps(props) {
  //   const { date } = this.props;
  //   this.setState({
  //     date,
  //   });
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    return { date: prevState.date };
  }

  handleDateSelect = date => {
    const { onChange } = this.props;
    this.setState(
      {
        displayDateView: '',
      },
      () => {
        onChange(date);
      },
    );
  };

  onBlur = e => {
    // eslint-disable-next-line react/no-find-dom-node
    if (!focusInCurrentTargetWithReference(e, ReactDOM.findDOMNode(this.dateInputRef))) {
      this.setState({ displayDateView: false });
    }
  };

  handleMenuClicked = () => {
    this.setState(
      {
        displayDateView: true,
      },
      () => {
        if (this.dateInputRef) {
          this.dateInputRef.focus();
        }
      },
    );
  };

  render() {
    const { displayDateView } = this.state;
    const { minDate, maxDate, label, date } = this.props;
    return (
      <DateRangeStyled>
        <div className="date-range-picker-wrapper">
          <Grid className="default-vertical-padding-24 no-margin">
            {date ? (
              <Cell
                className={displayDateView ? 'date-picker-holder-active' : 'date-picker-holder'}
                col={12}
                tablet={12}
                onClick={() => this.handleMenuClicked()}
              >
                <span className="calendar-icon">
                  <Icon name="event_note" className={displayDateView ? 'fixed-calendar-active' : 'fixed-calendar'} />
                </span>
                <span id="date-range-picker-id" className="calendar-date">
                  <span className="calendar-status">{label}</span>
                  {date.format('MMM D, YYYY')}
                </span>
                <span className="calendar-arrow-icon">
                  <Icon className="no-margin" name="arrow_drop_down" />
                </span>
              </Cell>
            ) : (
              <span id="date-range-picker-id">Date</span>
            )}
          </Grid>
          {displayDateView ? (
            <div
              tabIndex="1"
              onBlur={e => this.onBlur(e)}
              ref={ref => {
                this.dateInputRef = ref;
              }}
            >
              <Calendar
                date={getMomentDate(date).toDate()}
                onChange={selectedDate => this.handleDateSelect(selectedDate)}
                maxDate={!isObjectEmpty(maxDate) ? getMomentDate(maxDate).toDate() : undefined}
                minDate={!isObjectEmpty(minDate) ? getMomentDate(minDate).toDate() : undefined}
              />
            </div>
          ) : null}
        </div>
      </DateRangeStyled>
    );
  }
}

DateRangePicker.defaultProps = defaultProps;

DateRangePicker.propTypes = propTypes;

export default DateRangePicker;
