import { compose } from 'recompose';

import Agent from './Agent';

import apiInterceptor from '../../../api/interceptor';

import { agentApi } from '../../common/base.api';

const composedAgent = compose(apiInterceptor({ ...agentApi }))(Agent);

export default composedAgent;
