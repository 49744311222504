import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import {
  Cell, Checkbox, FormField
} from '../../../components/BillingMDC';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import { DISCOUNT_OPTIONS } from '../../../data/enums/enums';

const propTypes = {
  checkBoxParam: PropTypes.string,
  discountAmount: PropTypes.number,
  discountLabel: PropTypes.string,
  discountOptionParam: PropTypes.string,
  discountParam: PropTypes.string,
  invoiceAmount: PropTypes.number,
  onCheckBoxClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  //onSelect: PropTypes.func.isRequired,
  discountOptionValue: PropTypes.string,
  showCheckBox: PropTypes.bool,
};

const defaultProps = {
  checkBoxParam: 'checkBoxParam',
  discountAmount: 0,
  discountLabel: 'Discount',
  discountOptionParam: 'discountOptionParam',
  discountParam: 'discountParam',
  invoiceAmount: 0,
  showCheckBox: false,
  discountOptionValue: 0,
};
const DiscountBarWithCheckBox = ({
  discountLabel,
  showCheckBox,
  onCheckBoxClick,
  checkBoxParam,
  discountOptionParam,
  onInputChange,
  onSelect,
  discountAmount,
  discountParam,
  invoiceAmount,
  discountOptionValue,
}) => (
  <>
    <FormField id={`discountCheckbox-${discountLabel}`}>
      <Checkbox
        checked={showCheckBox}
        onChange={({ target: { checked } }) => {
          onCheckBoxClick(checkBoxParam, checked);
        }}
      />
      <label>{discountLabel}</label>
    </FormField>

    <span className={showCheckBox ? 'sub-total-wrap' : 'sub-total-wrap no-pointer-events'}>
      <span>
        <BillingSelect
          className='display-inline-table'
          name='form-field-name'
          clearable={false}
          value={DISCOUNT_OPTIONS.filter((option) => option.value === discountOptionValue)}
          options={DISCOUNT_OPTIONS}
          getOptionLabel={({ label }) => label}
          getOptionValue={({ value }) => value}
          onChange={(e) => onInputChange(discountOptionParam, e.value)}
        />
      </span>
      <span className='default-left-margin-9'>
        <BillingTextField
          value={discountAmount}
          handleChange={onInputChange}
          type='number'
          param={discountParam}
          max={discountOptionValue === 'rs' ? invoiceAmount.toString() : '99'}
        />
      </span>
    </span>
  </>
);

DiscountBarWithCheckBox.propTypes = propTypes;
DiscountBarWithCheckBox.defaultProps = defaultProps;

export default DiscountBarWithCheckBox;
