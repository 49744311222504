import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const propTypes = { data: PropTypes.instanceOf(Array), };

const defaultProps = { data: [] };

const TrialBalanceChildView = ({ data }) => (
  <Fragment>
    <tr>
      <th>Ledger</th>
      <th colSpan='2' className='text-center'>
        Opening Balance
      </th>
      <th colSpan='2' className='text-center'>
        Period Balance
      </th>
      <th colSpan='2' className='text-center'>
        Closing Balance
      </th>
    </tr>
    <tr>
      <th></th>
      <th className='text-right'>Debit</th>
      <th className='text-right'>Credit</th>
      <th className='text-right'>Debit</th>
      <th className='text-right'>Credit</th>
      <th className='text-right'>Debit</th>
      <th className='text-right'>Credit</th>
    </tr>
    {data.map((list) => (
      <tr>
        <td>{list.name}</td>
        <td className='text-right'>{fixedFloatAndCommas(list.openingBalanceDebit)}</td>
        <td className='text-right'>{fixedFloatAndCommas(list.openingBalanceCredit)}</td>
        <td className='text-right'>{fixedFloatAndCommas(list.periodBalanceDebit)}</td>
        <td className='text-right'>{fixedFloatAndCommas(list.periodBalanceCredit)}</td>
        <td className='text-right'>{fixedFloatAndCommas(list.debit)}</td>
        <td className='text-right'>{fixedFloatAndCommas(list.credit)}</td>
      </tr>
    ))}
  </Fragment>
);

TrialBalanceChildView.propTypes = propTypes;

TrialBalanceChildView.defaultProps = defaultProps;

export default TrialBalanceChildView;
