import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import hoistNonReactStatic from 'hoist-non-react-statics';

import { getDisplayName } from '../common/component';

// TODO : generate single request tracker for multiple requests avoiding the state.
// See: https://facebook.github.io/react/docs/higher-order-components.html
export default function withRequestTracker(apiList = {}) {
  return SourceComponent => {
    class RequestTracker extends Component {
      constructor() {
        super();
        this.state = {
          loadingCounter: 0,
          error: '',
        };
        this.sourceComponent = React.createRef();
      }

      onApiRequestSuccess = () => {
        this.setState(state => ({
          loadingCounter: state.loadingCounter - 1,
        }));
      };

      onApiRequestFailure = error => {
        this.setState(state => ({
          loadingCounter: state.loadingCounter - 1,
          error,
        }));
      };

      interceptHttpRequest = requestName => {
        const apiRequest = (variables, { onSuccess, onFailure } = {}, event = null) => {
          if (event) {
            event.preventDefault();
          }

          if (!requestName) {
            return;
          }

          this.setState(state => ({
            loadingCounter: state.loadingCounter + 1,
            error: '',
          }));

          // eslint-disable-next-line consistent-return
          return apiList[requestName](variables)
            .then(response => {
              this.onApiRequestSuccess();

              if (onSuccess) {
                onSuccess(response);
              }

              return Promise.resolve(response.data);
            })
            .catch(error => {
              this.onApiRequestFailure(error);

              if (onFailure) {
                onFailure(error);
              }

              return Promise.reject(error);
            });
        };

        return apiRequest;
      };

      updateState = (loadingCounter = 0, error = '') => {
        this.setState({
          loadingCounter,
          error,
        });
      };

      getAPIRequestWithInterceptor = () => {
        const request = {};

        Object.keys(apiList).forEach(api => {
          request[api] = this.interceptHttpRequest(api);
        });

        return request;
      };

      removeObjectProperty = (object, propertyList) => {
        const newObject = {};
        const attributeList = Object.keys(object);

        attributeList.forEach(attribute => {
          if (propertyList.indexOf(attribute) < 0) {
            newObject[attribute] = object[attribute];
          }
        });

        return newObject;
      };

      accessWrappedComponentMethod = methodName => this.sourceComponent.current[methodName];

      render() {
        const { loadingCounter, error } = this.state;
        const { ...oldProps } = this.props;
        // const oldPropsWithOutRequestList = this.removeObjectProperty(oldProps, requestList);
        const requestObjectWithTracker = this.getAPIRequestWithInterceptor();

        const newProps = {
          serverResponseWaiting: loadingCounter >= 1,
          serverResponseError: error,
          ...requestObjectWithTracker,
        };

        return <SourceComponent ref={this.sourceComponent} {...oldProps} {...newProps} />;
      }
    }

    RequestTracker.displayName = `LoadingAndError(${getDisplayName(SourceComponent)})`;
    hoistNonReactStatic(RequestTracker, SourceComponent);
    return RequestTracker;
  };
}
