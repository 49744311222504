import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import tableConfig from './config';
import history from '../../../utils/history';
import React, { Component } from 'react';
import * as filterUtil from '../../../utils/filterUtil';
import { clone } from '../../../utils/arrayProcessor';
import { LEDGERS } from '../../../data/enums/enums';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { resetQueryParameters } from '../../common/query.service';
import { customerDetails } from '../../../actions/customerDetail';
import { Grid, Cell, Icon } from '../../../components/BillingMDC';
import { BillingSnackBar } from '../../../components/BillingMDC';
import TableHeader from '../../../components/TableHeader/TableHeader';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { CUSTOMER_LEDGER_DETAILS } from '../../../data/enums/Route';
import { VAT_SUMMARY_REPORT_CONFIG } from '../../common/domain.config';
import * as downloadService from '../../common/download.service';
import { groupPrintDataSet } from '../../common/print.service';
import VATSummaryStyled from './VATSummaryStyled';
import getPrintData from './getPrintData';
import getConfigData from './getConfigData';
import SimplePrint from '../../../components/PrintComponent/simplePrint/simplePrint';

const propTypes = {};
const defaultProps = {};

class VATSummary extends Component {
  constructor(props) {
    super(props);
    this.box = React.createRef();
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      taxableAmount: 100000,
      queryParameters: {
        ...queryService.queryParameters,
      },
      columnTotal: {
        totalTaxExemptedSales: 0,
        totalTaxableAmount: 0,
        totalTaxableVat: 0,
      },
      data: {
        list: [],
        total: 0,
      },
      printData: [],
      dataList: [],
      activeView: tableConfig.ledgerTypeViewOptions[0].label,
      activeLdegerTypeId: LEDGERS.CUSTOMER.type,
    };
    this.dataListLength = 0;

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    window.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('mousedown', this.handleOutsideClick);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleOutsideClick = event => {
    if (this.box && !this.box.current.contains(event.target)) {
      this.state.printButtonClicked === true && this.setState({ printButtonClicked: false });
    }
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  /** get data for grid, adjust loading flag */
  loadTableData = async (print = false) => {
    const { getList } = this.props;
    const { queryParameters, taxableAmount, activeLdegerTypeId, data } = this.state;
    const extraData = `&ledger_type=[${activeLdegerTypeId}]&threshold_amount=${taxableAmount}`;
    let query = clone(queryParameters);
    query.pagination.limit = 2000;
    query.pagination.page = 1;
    await getList({ query: print ? query : queryParameters, extraQuery: extraData }).then(response => {
      data.list = response.list || [];
      data.total = response.total || 0;
      data.miti = response.reportMiti;
      data.nepaliDateRange = response.nepali_date;
      const columnTotal = {};
      columnTotal.totalTaxableAmount = response.total_taxable_amount ? response.total_taxable_amount : 0;
      columnTotal.totalTaxExemptedSales = response.total_tax_exempted_sales ? response.total_tax_exempted_sales : 0;
      columnTotal.totalTaxableVat = response.total_taxable_vat ? response.total_taxable_vat : 0;
      columnTotal.excise = response.total_excise ? response.total_excise : 0;
      this.setState({ data, columnTotal });
    });
  };

  handleViewChange = activeView => {
    const ids = tableConfig.ledgerTypeViewOptions.find(a => a.label === activeView).id;
    this.setState({ activeView, activeLdegerTypeId: ids }, () => {
      this.loadTableData();
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  handlePrintClick = async () => {
    await this.loadTableData(true);
    const self = this;
    self.setState({ printButtonClicked: true });
  };

  handleInputChange = (field, value) => {
    const updatedData = value;
    this.setState({ taxableAmount: updatedData }, () => this.loadTableData());
  };
  resetFilter = () => {
    const { queryParameters } = this.state;
    const queryParams = queryParameters;
    const newQueryParams = resetQueryParameters(queryParams);
    this.setState(
      { querParameters: newQueryParams, taxableAmount: 100000, activeView: tableConfig.ledgerTypeViewOptions[0].label },
      () => this.loadTableData(),
    );
  };
  groupPrintDataSetNew() {
    const { data } = this.state;
    const { company } = this.props;
    const printInfoBill = { ...company, printInfo: { ...company.printInfo, batchEnabled: false } } || {};
    const orders = clone(data.list);
    const dataList = groupPrintDataSet(orders, printInfoBill, tableConfig);
    this.setState({ dataList });
  }

  handleDownloadClick = reportType => {
    const queryParameter = this.basePaginationService.stateGetter();
    const { downloadList } = this.props;
    const { taxableAmount, activeLdegerTypeId } = this.state;
    downloadList({
      type: reportType,
      query: queryParameter,
      extraQueryString: `&ledger_type=[${activeLdegerTypeId}]&threshold_amount=${taxableAmount}`,
    }).then(response => downloadService.resolver(response));
  };

  render() {
    const {
      queryParameters,
      data,
      display,
      dataList,
      printButtonClicked,
      date,
      columnTotal,
      taxableAmount,
      snack,
      activeView,
    } = this.state;
    const { serverResponseWaiting, company } = this.props;

    const middleHeaderData = {
      date: {
        reportPeriod: true,
        value: queryParameters?.date,
      },
      title: VAT_SUMMARY_REPORT_CONFIG?.title,
    };

    const printData = getPrintData([...data.list], company, middleHeaderData, columnTotal);
    const configData = getConfigData(printData?.tableFooterData);

    return (
      <VATSummaryStyled className="vat-summary">
        <div>
          <PageView
            domainConfig={VAT_SUMMARY_REPORT_CONFIG}
            headerConfig={{
              search: true,
              date: true,
              download: true,
              filter: true,
              create: false,
              print: true,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.handleDownloadClick}
            data={data}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onPageSelect={this.basePaginationService.onPageSelect}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            handlePrintClick={this.handlePrintClick}
            printReference={this.box}
            enableTableFooter
            columnTotal={columnTotal}
            showInputOption
            inputTitle="Taxable Amount"
            onInputChange={this.handleInputChange}
            inputData={taxableAmount}
            resetFilter={this.resetFilter}
            viewOptions={{
              list: tableConfig.ledgerTypeViewOptions,
              handler: this.handleViewChange,
              selectedValue: activeView,
            }}
          />
        </div>
        {/* <div className={printButtonClicked ? 'display-block portrait-type' : 'no-print'}>
          {dataList.map((chunk, key) => {
            const { list = [], page = 0, remainingLines = [], footer } = chunk;
            return (
              <div className="print-body zoom-reduce">
                <div className="pickList-body card-body">
                  <header>
                    <Grid className="flex">
                      <Cell col={2} tablet={2} phone={2} className="left">
                        <div className="header-title">{company.title}</div>
                        <span className="header-title-content">
                          <span className="active-opacity-text">Address: </span>
                          <span className="font-bold">{company.address}</span>
                        </span>
                        <span className="header-title-content">
                          <span className="active-opacity-text">Phone: </span>
                          <span className="font-bold">{company.phone}</span>
                        </span>
                        <span className="no-margin-bottom header-title-content">
                          <span className="active-opacity-text">PAN No: </span>
                          <span className="font-bold">{company.panNumber}</span>
                        </span>
                      </Cell>
                      <Cell col={4} tablet={4} phone={4} className="center">
                        <div className="center-align">
                          <h2>{VAT_SUMMARY_REPORT_CONFIG.title}</h2>
                          <div className="default-margin-top-3">
                            <span className="header-title-content no-margin-bottom">
                              <span className="active-opacity-text">
                                Report from
                                <span className="date-rendered">
                                  {` ${filterUtil.formatToReadableDate(
                                    queryParameters.date.start,
                                  )} - ${filterUtil.formatToReadableDate(queryParameters.date.end)}`}
                                </span>
                              </span>
                            </span>
                            {data.nepaliDateRange && (
                              <span className="header-title-content no-margin-bottom">
                                <span className="active-opacity-text">
                                  Report from
                                  <span className="date-rendered">
                                    {` ${data.nepaliDateRange.start_date_nepali} - ${data.nepaliDateRange.end_date_nepali}`}
                                  </span>
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      </Cell>
                      <Cell col={2} tablet={2} phone={2} className="right">
                        <div className="header-menu-right">
                          <p>
                            <span className="">Date: </span>
                            <span className="">{filterUtil.formatToReadableDate(date)}</span>
                          </p>
                          {data.miti && (
                            <p>
                              <span className="">Miti: </span>
                              <span className="">{data.miti}</span>
                            </p>
                          )}
                        </div>
                      </Cell>
                    </Grid>
                  </header>
                  <div className="fixed-table-wrapper">
                    <table className="pdf-table">
                      <TableHeader headerDetails={tableConfig.header} filterHeaderLabel={false} />
                      {tableConfig.getTableBody({
                        dataList: list,
                        remainingLines: remainingLines,
                        columnTotal: columnTotal,
                        print: printButtonClicked,
                        footerStatus: footer,
                      })}
                    </table>
                  </div>
                </div>
                <div className="footer-block">
                  <PageNumber value={page} totalPage={dataList.length} />
                </div>
              </div>
            );
          })}
        </div> */}
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        {printButtonClicked && (
          <div className="display-block portrait-type">
            <SimplePrint configData={configData} printData={printData} />
          </div>
        )}
      </VATSummaryStyled>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLedgerRowClick: action => dispatch(action),
  };
}

VATSummary.protoTypes = propTypes;
VATSummary.defaultProps = defaultProps;

const VATSummaryWithState = withBaseState(VATSummary);

export default connect(null, mapDispatchToProps)(VATSummaryWithState);
