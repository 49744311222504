import React from 'react';
import { Icon } from '../../../components/BillingMDC';

const BusinessGroupBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr>
        <td className='left-align'>{data.businessId}</td>
        <td className='left-align'>{data.businessAlias}</td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => onRowClick(data, index)} />
        </td>
      </tr>
    ))}
  </tbody>
);

const businessGroupConfig = [
  { id: 2, label: 'business_id', title: 'ID', sortable: false },
  { id: 3, label: 'business_alias', title: 'Business Group', sortable: false },
  { id: 3, label: '', title: '', className: 'cursor-pointer', sortable: false },
];

const formValidationFieldList = [
  { title: 'business_alias', },
];

const config = {
  header: businessGroupConfig,
  getTableBody: BusinessGroupBody,
  formValidationFieldList,
};

export default config;
