import { TRANSACTION_TYPE } from '../../../data/enums/enums';

const filterListTransformer = (list, itemMapper = { id: 'id', title: 'title', businessId: 'businessId' }) =>
  list.map((data) =>
    Object.keys(itemMapper).reduce((acc, item) => {
      acc[item] = data[itemMapper[item]];
      return acc;
    }, {}),
  );

const filterThirdPartyBU = (list) => [
  {
    id: list.map((data) => data.businessId),
    title: `Other (${list.map((data) => data.businessAlias)})`,
  },
];

const OUTSTANDING_AMOUNT = [
  {
    id: 'equal_0',
    title: 'Equals to Zero',
  },
  {
    id: 'greater_0',
    title: 'Greater than Zero',
  },
];

const JOURNAL_VOUCHER_TRANSACTION_TYPE = [
  {
    id: 1,
    title: 'Sales Invoice',
  },
  {
    id: 2,
    title: 'Sales Return',
  },
  {
    id: 3,
    title: 'GRN',
  },
  {
    id: 4,
    title: 'Purchase Return',
  },
  {
    id: 5,
    title: 'Stock Journal',
  },
  {
    id: 7,
    title: 'Payment/Receipt',
  },
  {
    id: 8,
    title: 'Journal Voucher',
  },
  {
    id: TRANSACTION_TYPE.CREDIT_NOTE,
    title: 'Credit Note',
  },
  {
    id: TRANSACTION_TYPE.DEBIT_NOTE,
    title: 'Debit Note',
  },
  {
    id: TRANSACTION_TYPE.CONTRA_ENTRY,
    title: 'Contra Entry',
  },
];

const PAYMENT_TYPE = [
  {
    id: 1,
    title: 'CASH',
    value: 'CASH',
  },
  {
    id: 2,
    title: 'CHEQUE',
    value: 'CHEQUE',
  },
  {
    id: 3,
    title: 'POST DATED CHEQUE',
    value: 'POST_DATED_CHEQUE',
  },
];

const filterConfig = {
  jv_transaction_type: {
    // title: 'Transaction Type',
    selectionList: JOURNAL_VOUCHER_TRANSACTION_TYPE,
  },
  outstanding_range: {
    // title: 'Transaction Range',
    selectionList: OUTSTANDING_AMOUNT,
  },
  payment_type: { selectionList: PAYMENT_TYPE, },
};

const accountReportViewOptions = [
  {
    label: 'agL',
    title: 'Account Group With Ledger',
  },
  {
    label: 'accountGroup',
    title: 'Account Group',
  },
];

export {
  filterConfig, filterListTransformer, filterThirdPartyBU, accountReportViewOptions,
};
