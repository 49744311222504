import ReactDOM from 'react-dom';

import { getFixTableMeasurements } from '../../utils/tableMetrics';

const adjustToScreen = (tableRef, tableFooterRef, fixTableBody) => {
  const table = ReactDOM.findDOMNode(fixTableBody);
  const tableSize = getFixTableMeasurements(tableRef, tableFooterRef);

  table.style.maxHeight = tableSize.tableHeight;
  table.style.maxWidth = tableSize.tableWidth;
  table.style.overflow = 'auto';
};

export { adjustToScreen };
