import React, { Component } from 'react';
import moment from 'moment';
import Header from './Header';
import tableConfig from './config';
import Loading from '../../../common/Loading';
import DatePicker from '../../../components/DatePicker';
import withBaseState from '../../common/withBaseState';
import * as filterUtil from '../../../utils/filterUtil';
import DrawerWithFilter from '../../common/DrawerFilter';
import TableView from '../../common/pagination/TableView';
import * as queryService from '../../common/query.service';
import { OUTSTANDING_REPORT_CONFIG } from '../../common/domain.config';
import * as downloadService from '../../common/download.service';
import BillingSnackBar from '../../../components/BillingMDC/BillingSnackbar';
import * as snackService from '../../common/snack.service';
import { getCustomerList} from '../../../views/fundamentals/common/helpers';

class OutstandingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        drawer: true,
      },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          outstanding_range: [],
        },
      },
      data: {
        list: [],
        total: 0,
      },
      customerId: '',
      reportDate: filterUtil.getCurrentDay(),
      customerList: [],
      customerListPartial:[],
      snack: { ...snackService.snackParameters },
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    // this.loadTableData();
    this.loadCustomerList();
  }

  loadCustomerList = async () => {
    const ledgerListAll =await getCustomerList('customerVendor');
    this.getOtherListList(ledgerListAll)
  }

  getOtherListList = (ledgerListAll) =>{
    const customerListPartial = ledgerListAll.slice(0,300) ;
    this.setState({customerListPartial,customerList:ledgerListAll}) 
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters, reportDate, customerId } = this.state;

    const formattedReportDate = filterUtil.formatToNumericDate(reportDate);

    const extraQueryString = `&report_date=${formattedReportDate}&customer_id=${customerId}`;

    if (customerId) {
      getList({
        query: queryParameters,
        extraQueryString,
      }).then(response => {
        const { data } = this.state;
        data.list = response.list;
        data.total = response.totalCount;
        this.setState({ data });
      });
    }
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleInputChange = (field, value) => {
    this.setState({ [field]: value }, () => this.loadTableData());
  };

  handleDateChange = date => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = 1;
    const reportDate = date.format('D MMM YYYY');

    this.setState(
      {
        queryParameters,
        reportDate,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  handleTableSorting = (labelName, sortingOrder) => {
    const { queryParameters } = this.state;

    queryParameters.sort.label = labelName;
    queryParameters.sort.order = sortingOrder;

    this.setState({ queryParameters }, () => this.loadTableData());
  };

  handleDownloadClick = reportType => {
    const queryParameter = this.basePaginationService.stateGetter();
    const { reportDate, customerId } = this.state;
    const { downloadList } = this.props;
    if (customerId) {
      downloadList({
        type: reportType,
        query: queryParameter,
        extraQueryString: `&report_date=${reportDate}&customer_id=${customerId}`,
      }).then(response => downloadService.resolver(response));
    } else {
      this.setState({ snack: { ...snackService.generateFailureMessage('Customer not selected!') } });
    }
  };
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        outstanding_range: [],
      },
    });
  }

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { data, display, customerId, reportDate, queryParameters, customerList,customerListPartial, snack } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <div className="outstanding-report">
        <Loading display={serverResponseWaiting} />

        <Header
          domainConfig={OUTSTANDING_REPORT_CONFIG}
          customerId={customerId}
          reportDate={reportDate}
          onInputChange={this.handleInputChange}
          queryParameters={queryParameters}
          customerList={customerList}
          customerList={customerListPartial}
          onDrawerIconClick={this.controlDisplay}
          onDownloadClick={this.handleDownloadClick}
        />

        <TableView
          config={tableConfig}
          data={data}
          display={display}
          pagination={queryParameters.pagination}
          serverResponseWaiting={serverResponseWaiting}
          onPageSelect={this.basePaginationService.onPageSelect}
          handleTableSorting={this.handleTableSorting}
          btnDisplay={false}
        />

        <DrawerWithFilter
          display={display.filter}
          queryParameters={queryParameters}
          displayController={this.controlDisplay}
          resetFilter={this.handleResetFilter}
          onDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleFilterChange={this.basePaginationService.handleFilterChange}
        >
          <div className="padding-top-24">
            <div className="">
              <DatePicker
                date={moment(reportDate)}
                showLabel
                showIcon
                label="As on Date"
                onChange={this.handleDateChange}
              />
            </div>
          </div>
        </DrawerWithFilter>
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

const OutstandingReportWithState = withBaseState(OutstandingReport);

export default OutstandingReportWithState;
