import axios from 'axios';
import { JWT } from '../environment';
import history from "../utils/history";

axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  error => {
    if (error && error.response && error.response.status === 401) {
      history.push('/login');
    }
    return Promise.reject(error);
  })

const getAccessToken = async () => `Bearer ${localStorage.getItem(JWT.LOCAL_STORAGE.TOKEN.NAME)}`;

const get = async (url) =>
  axios
    .get(url, {
      headers: {
        Authorization: await getAccessToken(),
        Accept: 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.message;
    });

const all = async (requests) =>
  axios
    .all(requests)
    .then((response) => response)
    .catch((error) => {
      throw error.message;
    });

const post = async (url, body) => axios
  .post(url, body, {
    headers: {
      Authorization: await getAccessToken(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  .then((response) => response.data)
  .catch((error) => {
    const serverError = error;
    if (serverError.response) {
      const { error, data } = serverError.response;

      throw error ? error : data
    }

    throw error.message;
  });

const postReq = async (url, body) =>
  axios
    .post(url, body, {
      headers: {
        Authorization: await getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw error.response.data.error;
      }
      throw error.message;
    });

const multipartPost = async (url, body) =>
  axios
    .post(url, body, {
      headers: {
        Authorization: await getAccessToken(),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });

const put = async (url, id, body) => {
  const putUrl = `${url}/${id}`;

  return axios
    .put(putUrl, body, {
      headers: {
        Authorization: await getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw error.response.data.error;
      }
      throw error.message;
    });
};
const putWithoutID = async (url, body) => {
  const putUrl = `${url}`;

  return axios
    .put(putUrl, body, {
      headers: {
        Authorization: await getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw error.response.data.error;
      }
      throw error.message;
    });
};

const deleteapi = async (url, id) => {
  const deleteUrl = `${url}/${id}`;

  return axios
    .delete(deleteUrl, {
      headers: {
        Authorization: await getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.message;
    });
};

const storeAccessToken = async (user, accessToken, refreshToken) => {
  localStorage.setItem(JWT.LOCAL_STORAGE.APP_STORAGE.NAME, JSON.stringify(user));
  localStorage.setItem(JWT.LOCAL_STORAGE.TOKEN.NAME, accessToken);
  localStorage.setItem(JWT.LOCAL_STORAGE.REFRESH_TOKEN.NAME, refreshToken);
};

export {
  get, post, multipartPost, put, all, deleteapi, putWithoutID, postReq
};
