import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as httpUtils from '../../../utils/httpUtils';
import Pagination from '../../../components/Pagination';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import DatePicker from '../../../components/DatePicker/DatePicker';
import DateToRender from '../../../components/DateToRender';
import TableHeader from '../../../components/TableHeader';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Textfield,
  Fab,
  Grid,
  Cell,
  Menu,
  MenuAnchor,
  MenuItem,
} from 'react-mdc-web';
import Select from 'react-select';
import {
  CarriedBody,
  outstandingReportConfig,
  OutstandingReportTableBody,
  OutstandingTotal,
} from './OutstandingReportConfig';
import { getFixTableMeasurements } from '../../../utils/tableMetrics';
import { has } from '../../../utils/hasOwnProperty';
import * as filterUtil from '../../../utils/filterUtil';
import * as appConstants from '../../config';
import cloneDeep from 'lodash/cloneDeep';
import '../../../styles/printStyle.css';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

let grandTotal = 0;
const AS_ON_DATE = 'As on Date';
const downloadFormat = [
  { id: 1, value: 'Download Outstanding Report CSV with adjustment' },
  { id: 2, value: 'Download Outstanding Report CSV without adjustment' },
];
class OutstandingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showMenu: false,
      totalData: 1,
      dataSet: {},
      carriedSet: {},
      businessUnitId: '',
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
          reportDate: filterUtil.getCurrentDay(),
        },
        filter: {},
      },
      buTotal: {
        grandTotal: { title: 'Grand Total', value: 0 },
      },
      customerDetails: {
        customerId: '',
        customerTitle: '',
      },
    };
  }

  /** adjust table width and height according to screen **/
  componentDidMount() {
    this.getFiscalYear();
    this.getCustomerList();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    let table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
  }

  // get the customer list for the selection.
  getCustomerList = () => {
    this.setState({ loading: true });
    const type = 'customer';
    httpUtils.get(`${appConstants.baseUrl}ledger/customer-list?type=${type}`).then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        let responseDataList = response.data;
        responseDataList.map(customer => {
          customer['label'] = customer.customerId + '. ' + customer.name;
        });
        this.setState({ customerList: responseDataList });
      }
    });
  };

  getFiscalYear = () => {
    this.setState({ loading: true });
    const { queryParameters } = this.state;
    httpUtils.get(`${appConstants.baseUrl}period/single`).then(response => {
      this.setState({ loading: false });
      if (response.status == 200 && response.success) {
        queryParameters.date.start = has.call(response.data.list, 'startDate')
          ? response.data.list.startDate
          : this.state.queryParameters.date.start;
        this.setState({ queryParameters });
      }
    });
  };

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    const { queryParameters, customerDetails } = this.state;
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    queryParameters.date['report_date'] = moment(queryParameters.date.reportDate).format('YYYY-MM-DD');
    if (customerDetails.customerId) {
      this.setState({ loading: true });
      let urlString = `${appConstants.baseUrl}outstanding-report/paginated-list/with-adj?page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}&start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&customer_id=${customerDetails.customerId}&report_date=${queryParameters.date.report_date}`;
      httpUtils
        .get(urlString)
        .then(response => {
          if (response.status == 200 && response.success) {
            //self.modifyData(response.data.list);
            self.modifyData(response.data.list, response.data.total);
          } else {
            self.setState({ loading: false });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  onCustomerDropDownChange = (field, customer) => {
    const customerDetails = {
      customerId: customer.customerId,
      customerTitle: customer.name,
    };
    this.setState({ customerDetails: customerDetails }, () => this.getTableDataForGrid());
  };

  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getTableDataForGrid());
  };

  /** on Date Range Change */
  handleDateRangeChange = (start, end) => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  handleDateChange = date => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.reportDate = date.format('D MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  calculateTotal = list => {
    let totalValue = 0;
    list.map(item => {
      totalValue = totalValue + item.balance;
    });
    return totalValue;
  };

  modifyData = (dataObj, totalData) => {
    let buTotal = { grandTotal: { title: 'Grand Total', value: 0 } };
    let carriedSet = {};
    let dataSet = {};
    if (!isEmpty(dataObj)) {
      Object.keys(dataObj).map(item => {
        if (dataObj[item][0].carriedAmount !== 0) {
          dataObj[item][0]['title'] = 'Opening Balance';
          carriedSet[item] = dataObj[item][0];
        }
        dataObj[item][0]['balance'] = dataObj[item][0].carriedAmount;
        const totalValue = this.calculateTotal(dataObj[item]);
        buTotal[item] = { title: 'Total', value: totalValue };
        buTotal.grandTotal.value = buTotal.grandTotal.value + totalValue;
        dataObj[item].shift();
      });
      dataSet = { ...dataObj };
    }
    this.setState({ buTotal, loading: false, dataSet, carriedSet, totalData });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  handleDownloadClick = () => {
    let downloadUrl = 'download-outstanding-report/with-adj?';
    const type = 'CSV';
    const self = this;
    const schema = 'https://';
    const { queryParameters, customerDetails } = this.state;
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    queryParameters.date['report_date'] = moment(queryParameters.date.reportDate).format('YYYY-MM-DD');
    //const d = 'dev.billing.rosia.one';
    httpUtils
      .get(
        appConstants.baseUrl +
          `${downloadUrl}&start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&report_date=${queryParameters.date.report_date}&customer_id=${customerDetails.customerId}&type=${type}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ loading: false });
          const url = response.data.url;
          const newWin = window.open(schema + window.location.hostname + url, 'Download');
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            alert('Please enable pop for this site');
          }
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    const { dataSet } = this.state;
    let data = {};
    if (!isEmpty(dataSet)) {
      Object.keys(dataSet).map(item => {
        if (sortingOrder == 2) {
          data[item] = dataSet[item];
          return data[item];
        }
        const sortingName = sortingOrder == 0 ? 'asc' : 'desc';
        data[item] = orderBy(dataSet[item], labelName, sortingName);
        return data[item];
      });
      return data;
    } else return dataSet;
  };

  render() {
    const { loading, queryParameters, totalData, sorting, dataSet } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    return (
      <div>
        <div>
          {loading && (
            <div className="linear-progress-wrapper temp-progress-wrapper">
              <LinearProgress accent indeterminate />
            </div>
          )}
          <div className={this.state.printButtonClicked ? 'no-print' : 'display-block'}>
            <div className={loading ? 'clickable-false' : ''}>
              <div className="header-bar" ref="tableReference">
                <Grid>
                  <Cell col={4} className="input-select">
                    <label>Customer Name</label>
                    <Select
                      name="form-field-name"
                      clearable={false}
                      value={this.state.customerDetails.customerId}
                      valueKey="customerId"
                      options={this.state.customerList}
                      onChange={e => this.onCustomerDropDownChange('name', e)}
                    />
                  </Cell>
                  <Cell col={4}>
                    <div className="center-align">
                      <h2>Outstanding Report</h2>
                      <div className="default-margin-top-12">
                        <span className="header-title-content no-margin-bottom">
                          <span className="active-opacity-text">
                            Report from:{' '}
                            <span className="date-rendered">
                              <DateToRender date={queryParameters.date} />
                            </span>
                          </span>
                        </span>
                        <span className="header-title-content">
                          <span className="active-opacity-text">
                            As on Date:{' '}
                            <span className="date-rendered">
                              <span>{queryParameters.date.reportDate}</span>
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </Cell>
                  <Cell col={4}>
                    <div className="header-menu-right">
                      <span className="download-menu" onClick={() => this.handleDownloadClick()}>
                        <Icon name="get_app" />
                      </span>
                      <span onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                        <Icon name="filter_list" />
                      </span>
                    </div>
                    <div className="header-menu-right">
                      {/*<span className="header-right-content">
                     <span className="">Date: </span>
                     <span>{this.state.queryParameters.date.end}</span>
                    </span>*/}
                    </div>
                  </Cell>
                </Grid>
              </div>
            </div>

            <div ref="fixedTableBody" className="fixed-table-wrapper">
              <table>
                <TableHeader
                  headerDetails={outstandingReportConfig.headerDetails}
                  filterHeaderLabel={false}
                  handleSorting={this.handleTableSorting}
                />
                {isEmpty(sortedDataSet)
                  ? Object.keys(this.state.carriedSet).map(data => {
                      return (
                        <tbody>
                          <CarriedBody carriedSet={this.state.carriedSet[data]} />
                        </tbody>
                      );
                    })
                  : Object.keys(sortedDataSet).map(data => {
                      return (
                        <tbody>
                          {has.call(this.state.carriedSet, data) && (
                            <CarriedBody carriedSet={this.state.carriedSet[data]} />
                          )}
                          <OutstandingReportTableBody dataList={sortedDataSet[data]} />
                          <OutstandingTotal totalData={this.state.buTotal[data]} />
                        </tbody>
                      );
                    })}
                <tfoot className="right-content-footer">
                  {<OutstandingTotal totalData={this.state.buTotal.grandTotal} />}
                </tfoot>
              </table>
            </div>

            {
              <div ref="fixedTableFooter">
                <Pagination
                  pageSize={queryParameters.pagination.limit}
                  currentPage={queryParameters.pagination.page}
                  orientation="top"
                  totalItems={totalData}
                  onPageSelect={this.onPageSelect}
                />
              </div>
            }
          </div>
        </div>
        <div dir="rtl">
          <Drawer
            open={this.state.showFilter}
            className="right-drawer"
            onClose={() => {
              this.setState({ showFilter: false });
            }}
          >
            <div className="filter-wrapper">
              <h2 className="default-horizontal-padding-24">
                <span>Filter</span>
                <span className="float-right">
                  <Icon name="replay" onClick={() => this.resetFilter()} />
                  <Icon name="clear" onClick={() => this.setState({ showFilter: false })} />
                </span>
              </h2>
              <div className="divider"></div>
              <DateRangePicker
                startDate={moment(queryParameters.date.start)}
                endDate={moment(queryParameters.date.end)}
                onChange={this.handleDateRangeChange}
              />
              <div className="padding-top-24">
                <div className="">
                  <DatePicker
                    date={moment(queryParameters.date.reportDate)}
                    showLabel={true}
                    showIcon={true}
                    label={AS_ON_DATE}
                    onChange={this.handleDateChange}
                  />
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

OutstandingReport.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
  };
};
const outstandingReport = connect(mapStateToProps)(OutstandingReport);

export default outstandingReport;
