import React, { Component } from 'react';
import tableConfig from './config';
import withBaseState from '../../common/withBaseState';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import { Grid, Cell } from '../../../components/BillingMDC';
import PageView from '../../common/pagination/PageView';
import { PDC_CONFIG } from '../../common/domain.config';
import { BillingSnackBar } from '../../../components/BillingMDC';
import { getCurrentDate, getStartOfCurrentMonth } from '../../../utils/filterUtil';
import { clone } from '../../../utils/arrayProcessor';
import * as filterUtil from '../../../utils/filterUtil';
import TableHeader from '../../../components/TableHeader/TableHeader';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import {groupPrintDataSet} from "../../common/print.service";
import PostDatedStyled from './PostDatedStyled';

class PostDatedCheque extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        filter: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        date: null,
        reference_date: {
          start: getCurrentDate(),
          end: getCurrentDate(),
        },
      },
      data: {
        list: [],
        total: 0,
      },
      printButtonClicked: false,
      dataList: [],
      date: filterUtil.getCurrentDay(),
    };
    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  componentDidMount() {
    this.loadTableData();
    window.addEventListener('keydown', this.handleKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.getDataForPrint();
    }
  };

  loadTableData = () => {
    const { queryParameters } = this.state;
    const { getReceiptList } = this.props;
    const extraQueryString = '&payment_type=[3]&status=0&transaction_type=0';
    this.loadPrintData(extraQueryString);
    getReceiptList({ query: queryParameters, extraQueryString })
      .then(response => {
        const { data } = this.state;
        data.list = response.list || [];
        data.total = response.total || 0;
        this.setState({ data });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage();
      });
  };
  loadPrintData = extraQueryString => {
    const { queryParameters } = this.state;
    const { getReceiptList } = this.props;
    let query = clone(queryParameters);
    query.pagination.limit = 1000;
    query.pagination.page=1;
    getReceiptList({
      query,
      extraQueryString,
    })
      .then(response => {
        const printData = response.list || [];
        this.setState({ printData }, () => {
          this.groupPrintDataSetNew(printData);
        });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage('Error while fetching print data set');
      });
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  groupPrintDataSetNew() {
    const { printData  } = this.state;
    const {company} =this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(printData);
    const dataList = groupPrintDataSet(orders, printInfoBill, tableConfig);
    this.setState({ dataList });
  }

  getDataForPrint = () => {
    const self = this;
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.loadTableData()
      self.setState({ printButtonClicked: false });
    };
  };

  render() {
    const { display, queryParameters, data, snack, dataList, printButtonClicked, date } = this.state;
    const { serverResponseWaiting, company } = this.props;
    return (
      <PostDatedStyled className="post-date">
        <div className={printButtonClicked ? 'no-print' : 'display-block sales-outstanding'}>
          <PageView
            domainConfig={PDC_CONFIG}
            headerConfig={{
              search: true,
              date: false,
              upload: false,
              download: true,
              filter: true,
              create: false,
              reference_date: true,
              print: true,
            }}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            resetFilter={this.basePaginationService.resetFilter}
            handlePrintClick={this.getDataForPrint}
            handleDateRangeChange={this.basePaginationService.handleRefDateRangeChange}
            handleDownloadClick={this.basePaginationService.handleDownloadClick}
            display={display}
            data={data}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.handleTableBodyClick}
            onPageSelect={this.basePaginationService.onPageSelect}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
          />
        </div>
        <div className={printButtonClicked ? 'display-block receipt_print portrait-type' : 'no-print'}>
          {dataList.map((ceItem, key) => (
            <div className="print-body zoom-reduce voucher-print">
              <div className="jv card-body">
                <Grid>
                  <Cell col={2} tablet={2}>
                    <div className="header-title">{company.title}</div>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Address: </span>
                      <span>{company.address}</span>
                    </span>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Phone: </span>
                      <span>{company.phone}</span>
                    </span>
                    <span className="no-margin-bottom header-title-content">
                      <span className="active-opacity-text">PAN No: </span>
                      <span>{company.panNumber}</span>
                    </span>
                  </Cell>
                  <Cell col={4} tablet={4}>
                    <div className="center-align">
                      <h2>{PDC_CONFIG.title}</h2>
                      <div className="default-margin-top-2">
                        <span className="header-title-content no-margin-bottom">
                          <span className="active-opacity-text fs-14">
                            Report from
                            <span className="date-rendered">
                              {` ${filterUtil.formatToReadableDate(
                                queryParameters.reference_date.start,
                              )} - ${filterUtil.formatToReadableDate(queryParameters.reference_date.end)}`}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </Cell>
                  <Cell col={2} tablet={2}>
                    <div className="header-menu-right date text-right">
                      <span>
                        {' '}
                        <span className="">Date: </span>
                        <span>{date}</span>
                      </span>
                    </div>
                  </Cell>
                </Grid>
                <div className={` fixed-table-wrapper ${!data.footer ? 'fixed-table-height ' : 'total-footer'}`}>
                  <table>
                    <TableHeader headerDetails={tableConfig.header} filterHeaderLabel={false} />
                    {tableConfig.getTableBody({
                      dataList: ceItem.list,
                      remainingLines: ceItem.remainingLines,
                    })}
                  </table>
                </div>
              </div>
              <div className="footer-block">
                <PageNumber value={ceItem.page} totalPage={dataList.length} />
              </div>
            </div>
          ))}
        </div>
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </PostDatedStyled>
    );
  }
}

const PostDatedChequeWithState = withBaseState(PostDatedCheque);

export default PostDatedChequeWithState;
