import styled from 'styled-components';

const VATSummaryStyled = styled.div`

@media print{
    &.vat-summary{
      .header-menu-right{
        flex-direction: column;
        p{
          text-align: right;
        }
      }
      header{
        .mdc-layout-grid__inner{
          display:inline-block!important;
          width:100%;
      }
      .mdc-layout-grid__cell{
         width:33.33%;
         display:inline-block;
         vertical-align: text-top;
         &:nth-child(2) h2{
         text-align:center;
         font-size:18px;
     }
     &:nth-child(2) h5{
         text-align:center;
         font-size:14px;
         font-weight:400;
     }
     }
      }
      .portrait-type{
        .print-body{
          height: 100% !important;
          page-break-after: always;
          padding:0 !important;
          margin:0.28in 0.3in !important;
          .fixed-table-wrapper{
            height:1420px !important;
            .total{
              td{
                font-weight:600;
                border-top:1px solid #c5c5c5  !important;
              border-bottom:1px solid #c5c5c5  !important;
              color:#000000;
              vertical-align: middle;
              padding:0 !important;
              }
            }
            table{
              height:100%;
              .blank-tr{
                height:24px !important;
                border:0;
              }
              thead{
                th{
                  font-size: 14px !important;
                  width:80px !important;
                min-width:80px !important;
                max-width:80px !important;
                }
              }
              tbody{
                td{
                  font-size: 14px !important;
                  width:80px !important;
                  min-width:80px !important;
                  max-width:80px !important;
                  &.sn{
                    width:10px !important;
                    min-width:10px !important;
                    max-width:10px !important;
                  }
                  &.ledger-name{
                    width:250px !important;
                    min-width:250px !important;
                    max-width:250px !important;
                    white-space:normal !important;
                  }
                  &:last-child{
                    font-weight:500;
                  }
                }
              }
            }
          }
          .footer-block{
            position:unset;
          }
        }
      }
    }
  }

  @media print and (max-width:5.83in) {
    &.vat-summary .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
          height:1405px !important;
        }
    }
  }
`;
export default VATSummaryStyled;
