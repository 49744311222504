import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as snackService from '../common/snack.service';
import * as queryService from '../common/query.service';
import history from '../../utils/history';
import { JOURNAL_VOUCHER_CREATE, JOURNAL_VOUCHER_DETAILS } from '../../data/enums/Route';
import tableConfig from './config';
import { JV_CONFIG } from '../common/domain.config';
import PageView from '../common/pagination/PageView';
import AddFab from '../../components/BillingMDC/AddFab';
import * as downloadService from '../common/download.service';
import BillingSnackBar from '../../components/BillingMDC/BillingSnackbar';
import { checkIfApiCallSuccess, jvApi } from '../common/base.api';
import withBaseState from '../common/withBaseState';
import { voucherEncoder } from '../../utils/miscellaneous';
import {storeInLocalStorage ,fetchFromLocalStorage} from "../common/common";

const propTypes = {};
const defaultProps = {};

class JournalVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        filter: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          jv_transaction_type: [8],
        },
      },
      data: {
        list: [],
        total: 0,
      },
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        jv_transaction_type: [8],
      },
    });
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
    /* tableLayoutService.adjustToScreen(
      this.refs.tableReference,
      this.refs.fixedTableFooter,
      this.refs.fixedTableBody,
      ); */
  }
  setFilterStateInStorage = () => {
    const { queryParameters} = this.state;
    
    storeInLocalStorage(
      'FILTER',
      {
       queryParameters,
      },
      "JV"
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters} =this.state;
    const localData = fetchFromLocalStorage('FILTER', "JV");
    this.setState(
      { queryParameters:localData?.queryParameters || queryParameters ,},
      () => {
        this.loadTableData();
      },
    );
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { queryParameters } = this.state;
    this.setFilterStateInStorage();
    jvApi.getList(queryParameters).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const { data } = this.state;
        data.list = response.list;
        data.total = response.total;
        this.setState({ data });
      }
    });
  };

  handleFabButtonClick = () => {
    history.push(`/${JOURNAL_VOUCHER_CREATE}`);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  handleClearSearch =() =>{
    const {display}=this.state;
    this.setState({display:{...display,searchBox:false,search:false}});
    this.basePaginationService.clearSearchText();
  }

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  handleTableBodyClick = id => {
    const voucherNumber = voucherEncoder(id) || '';
    history.push(`/${JOURNAL_VOUCHER_DETAILS}/${voucherNumber}`);
  };
  
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        jv_transaction_type: [8],
      },
    });
  }
  handleDownloadClick = (type, label) => {
    const { queryParameters } = this.state;
    const { downloadList, detailDownloadList } = this.props;
    if (label === 'jv_details') {
      detailDownloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    } else {
      downloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    }
  };

  render() {
    const { display, queryParameters, data, snack } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <div className="journal-voucher">
        <PageView
          domainConfig={JV_CONFIG}
          headerConfig={{
            search: true,
            date: true,
            upload: false,
            download: true,
            filter: true,
            create: true,
          }}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          resetFilter={this.handleResetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleDownloadClick={this.handleDownloadClick}
          display={display}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleTableBodyClick}
          onPageSelect={this.basePaginationService.onPageSelect}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          createHandler={this.handleFabButtonClick}
          showCollapsibleList
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.handleClearSearch}
        />

        {/* <AddFab handleFabClick={this.handleFabButtonClick} /> */}

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

JournalVoucher.propTypes = propTypes;
JournalVoucher.defaultProps = defaultProps;

const JournalVoucherWithState = withBaseState(JournalVoucher);

export default JournalVoucherWithState;
