import styled from 'styled-components';

const PostDatedStyled = styled.div`
  @media print{
    &.post-date{
      .portrait-type{
        .print-body{
          height: 100% !important;
        padding:0 !important;
        margin:0.28in 0.3in !important;
        page-break-after: always;
          .mdc-layout-grid__inner{
                display:flex;
            }
          .mdc-layout-grid__cell{
                width:32.2%;
                overflow:hidden;
            }
          .fixed-table-wrapper{
            height:1420px !important;
            table{
              height:100%;
              .blank-tr{
                height:24px !important;
                border:0;
              }
              td,th{
                width:60px !important;
                padding:0 !important;
                padding:6px 10px 2px 0 !important;
                color:rgba(0,0,0,1) !important;
                &.reference-num,
                  &.invoice-num{
                    width:135px !important;/
                  }
                  &.amount{
                        width:50px !important;
                        min-width:50px !important;
                        max-width:50px !important;
                        white-space: normal !important;
                    }
                    &.id{
                    width:20px !important;
                    min-width:20px !important;
                    max-width:20px !important;
                }
              }
              thead{
                th{
                  font-size: 14px !important;
                }
              }
              tbody{
                tr{
                  border-bottom: 1px solid #c5c5c5  !important;
                }
                tr.last-tr,
                tr.blank-tr{
              border-bottom:0 !important;
                  }
                td{
                  font-size: 14px !important;
                  &.customer,
                  &.narration{
                    width:200px !important;
                    min-width:200px !important;
                    max-width:200px !important;
                    white-space: normal !important;
                    overflow: hidden;
                    word-break: break-word;
                  }
                  &:last-child{
                    font-weight:500;
                  }
                }
              }
            }
          }
          .footer-block{
            position:unset;
          }
        }
      }
    }
  }
  @media print and (max-width:5.83in) {
    &.post-date{
      .portrait-type{
        .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
          height:1405px !important;
        }
      }
    }
  }
}
`;
export default PostDatedStyled;
