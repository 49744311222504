import styled from 'styled-components';

const DebitNoteStyled = styled.div`
  .text-center {
    text-align: center;
  }
  .status-label {
    border: 1px solid transparent;
    padding: 4px 8px 5px;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    border-radius: 2px;
    min-width: 73px;
    height: 14px;
    line-height: 15px;
    text-align: center;
    white-space: nowrap;
    &.active {
      border-color: #47c464;
      color: #287d3c;
    }
    &.passive {
      border-color: #ff8f39;
      color: #b95000;
    }
  }
  .debit-note {
    .fixed-table-wrapper {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }
  .create-debit-note {
    .dialog-upperpart .mdc-textfield {
      height: 50px !important;
    }
    .total-section-wrapper {
      .mdc-textfield--upgraded {
        height: 15px;
      }
    }

    .date-picker-wrapper {
      float: unset;
      .default-vertical-padding-24 {
        padding: 0;
        padding-top: 4px;
      }
      .calendar-status {
        display: none;
      }
      .mdc-layout-grid__inner {
        display: flex;
        .date-picker-holder-active,
        .date-picker-holder {
          flex: 1;
          border-bottom: 1px solid #9f9f9f;
          padding-left: 0;
          .calendar-date {
            border-bottom: none;
          }
          .calendar-icon {
            border: none;
          }
          .calendar-arrow-icon {
            position: absolute;
            right: 0;
            border-bottom: none;
          }
        }
      }
    }
    .mdc-dialog__surface {
      max-width: 450px;
    }
  }

  /* print */
  .debit_note_print {
    .print_display_none {
      display: none !important;
    }
    .height-25,
    .jv-footer tr {
      height: 25px;
    }
    .sub-total-td span,
    .total-td span {
      &:first-child {
        margin-right: 8px;
      }
      display: inline-block;
      min-width: 78px;
    }
    .print-active {
      border-top: 1px solid rgba(0, 0, 0, 0.54);
      padding-bottom: 0;
      padding: 4px 0;
    }
    .total-in-words td div {
      padding-bottom: 4px;
    }
    .sub-total td {
      line-height: 16px;
      padding: 0;
      padding-right: 8px;
      padding-top: 3px;
      &:last-child {
        padding-right: 0;
      }
    }
    .printInfo td {
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.54);
      & > div {
        display: inline-block;
        width: 25%;
        box-sizing: border-box;
        text-align: center;
        &:nth-child(2),
        &:first-child {
          text-align: left;
        }
        &:nth-last-child(2) {
          padding-left: 3%;
        }
        &:nth-last-child(2),
        &:last-child {
          text-align: right;
        }
      }
    }
    .page-tfoot-end {
      border: 0 !important;
    }
    .narration {
      position: relative;
      top: -4px;
    }
    .total-in-words {
      border: 0 !important;
      td {
        text-align: right;
        border: 0 !important;
      }
    }
    .signature td {
      vertical-align: middle;
      text-align: center;
      .authorized {
        position: relative;
        right: -22px;
        p {
          border-bottom: 1px solid rgba(0, 0, 0, 0.54);
          width: 150px;
          margin-top: 20px;
          margin-bottom: 6px;
        }
      }
    }
  }
`;
export default DebitNoteStyled;
