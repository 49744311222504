import { compose } from 'recompose';

import apiInterceptor from '../../../../api/interceptor';

import CreateSalesReturn from './CreateSalesReturn';
import {
  buApi, catalogsApi, ledgerApi, srnApi
} from '../../../common/base.api';

const composedCreateSalesReturn = compose(
  apiInterceptor({
    ...buApi,
    ...catalogsApi,
    create: { ...srnApi },
    getAvailableSales: { ...srnApi },
    getCustomerList: { ...ledgerApi },
  }),
)(CreateSalesReturn);

export default composedCreateSalesReturn;
