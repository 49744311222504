import React, { Component } from 'react';

import tableConfig from './config';
import { ACTION } from '../../../data/enums/enums';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import { AddFab, BillingSnackBar } from '../../../components/BillingMDC';
import * as snackService from '../../common/snack.service';
import Dialog from './Dialog';
import { validateForm } from '../../common/validation';
import { BUSINESS_GROUPS_CONFIG } from '../../common/domain.config';
import { keysToCamel } from '../../../utils/objectProcessor';

class BusinessGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      dialogType: ACTION.NULL,
      data: {
        list: [],
        total: 0,
      },
      businessGroup: {
        business_alias: '',
      },
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
    };
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    /** tableLayoutService.adjustToScreen(
     this.refs.tableReference,
     this.refs.fixedTableFooter,
     this.refs.fixedTableBody,
     ); */
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const extraQuery = 'status=1';
    getList({ extraQuery }).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.list.length;
      this.setState({ data });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleEditIconClick = data => {
    const { businessGroup } = this.state;
    businessGroup.business_alias = data.businessAlias;
    businessGroup.id = data.idBusinessCompany;
    this.setState({
      dialogType: ACTION.UPDATE,
      businessGroup,
    });
  };

  handleFabButtonClick = () => {
    const { businessGroup } = this.state;
    businessGroup.business_alias = '';
    this.setState({
      dialogType: ACTION.ADD,
      businessGroup,
    });
  };

  handleModalSubmit = () => {
    // to do submit all the critera.
    const { businessGroup, validation, dialogType } = this.state;
    const formValid = validateForm(businessGroup, validation, valid => this.setState({ validation: valid }));

    if (formValid) {
      this.handleModalClose();

      switch (dialogType) {
        case ACTION.UPLOAD:
        case ACTION.ADD:
          this.createBusinessGroup();
          break;
        case ACTION.UPDATE:
          this.editBusinessGroup();
          break;
        default:
          break;
      }
    }
  };

  createBusinessGroup = () => {
    const { create } = this.props;
    const { businessGroup, data } = this.state;
    create({ bu: businessGroup.business_alias })
      .then(response => {
        const { company } = response;
        const updatedCompany = keysToCamel(company);
        data.list.push(updatedCompany);
        data.list.total += 1; // (data.list.total + 1);
        const snack = snackService.generateSuccessMessage();
        this.setState({
          data,
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage(err?.error?.message);
        this.setState({
          data,
          snack,
        });
      });
  };

  editBusinessGroup = () => {
    const { update } = this.props;
    const { businessGroup, data } = this.state;

    update({
      id: businessGroup.id,
      query: { bu: businessGroup.business_alias },
    }).then(res => {
      const indexInDataList = data.list.findIndex(el => el.idBusinessCompany === Number(res.data.id_business_company));
      data.list[indexInDataList].businessAlias = res.data.business_alias;
      const snack = snackService.generateUpdateMessage();
      this.setState({
        data,
        snack,
      });
    });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({ dialogType: ACTION.NULL });
  };

  handleInputChange = (field, value) => {
    const { businessGroup } = this.state;
    businessGroup[field] = value;
    this.setState({ businessGroup });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { data, display, snack, dialogType, businessGroup, validation } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <div>
        <PageView
          domainConfig={BUSINESS_GROUPS_CONFIG}
          headerConfig={{
            search: false,
            date: false,
            download: false,
            filter: false,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          data={data}
          tableConfig={tableConfig}
          onTableBodyClick={this.handleEditIconClick}
          createHandler={this.handleFabButtonClick}
        />

        {/* <AddFab handleFabClick={this.handleFabButtonClick}/> */}

        <Dialog
          actionType={dialogType}
          onModalSubmit={this.handleModalSubmit}
          onModalClose={this.handleModalClose}
          onInputChange={this.handleInputChange}
          businessGroup={businessGroup}
          validation={validation}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

const BusinessGroupsWithState = withBaseState(BusinessGroups);

export default BusinessGroupsWithState;
