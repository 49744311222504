import React, { Component } from 'react';
import Dialog from './../salesReport/Dialog';
import tableConfig from './config';
import { ACTION } from '../../../data/enums/enums';
import { validateForm } from '../../common/validation';
import withBaseState from '../../common/withBaseState';
import { getCSVHeader } from '../../common/csv.service';
import PageView from '../../common/pagination/PageView';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import { BillingSnackBar } from '../../../components/BillingMDC';
import { SRN_REPORT_CONFIG } from '../../common/domain.config';
import { handleArchiveDownload } from '../../common/download.service';

class SRNReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        menu: false,
        searchBox: false,
      },
      validation: {
        flag: false,
        fieldList: tableConfig.uploadFields,
      },
      csvHeaderOptions: [],
      upload: tableConfig.getUploadInput(),
      dialogType: ACTION.NULL,
      snack: { ...snackService.snackParameters },
      queryParameters: { ...queryService.queryParameters },
      data: {
        list: [],
        total: 1,
      },
      isDateFilterChanged: false,
    };
    this.reference = {
      tableHeader: React.createRef(),
      tableFooter: React.createRef(),
      tableBody: React.createRef(),
      fileRef: React.createRef(),
    };
    const { downloadList } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
    );
    this.basePaginationService.resetFilter();
  }
  componentDidMount() {
    const queryDateRemove = true;
    this.loadTableData(queryDateRemove);
  }
  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  loadTableData = (queryDateRemove = false) => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    const queryUpdate = {
      ...queryParameters,
      date: { ...queryParameters.date, start: '', end: '' },
    };
    getList(queryDateRemove ? queryUpdate : queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total;
      this.setState({ data });
    });
  };
  handleUploadIconClick = () => {
    this.setState({ dialogType: ACTION.UPLOAD });
  };

  handleInputChange = (field, value) => {
    const { upload } = this.state;
    upload[field] = value;
    this.setState({ upload });
  };
  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    document.getElementById('myFile').value = '';
    this.setState({
      dialogType: ACTION.NULL,
      validation,
      upload: {
        customer_name: '',
        date: '',
        invoice_number: '',
      },
      csvHeaderOptions: [],
    });
  };
  handleModalSubmit = () => {
    const { upload, validation } = this.state;
    const formValid = validateForm(upload, validation, valid => this.setState({ validation: valid }));
    if (!formValid) return false;
    this.upload();
    this.handleModalClose();
  };
  onPageSelect = (page, limit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = page;
    queryParameters.pagination.limit = limit;
    const queryDateRemove = true;
    this.setState({ queryParameters }, () => {
      this.loadTableData(queryDateRemove);
    });
  };

  upload = () => {
    const { file, customer_name, date, invoice_number } = this.state.upload;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('customer_name', customer_name);
    formData.append('date', date);
    formData.append('invoice_number', invoice_number);
    const { upload } = this.props;
    upload(formData)
      .then(response => {
        const snack = snackService.generateSuccessMessage();
        const queryDateRemove = true;
        this.setState({ snack }, () => this.loadTableData(queryDateRemove));
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({ snack });
      });
  };

  handleFileUpload = event => {
    event.preventDefault();
    const { upload } = this.state;
    upload.file = event.target.files[0];
    const e = event.target;
    this.setState({ upload }, () => {
      this.processFile(e);
    });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  processFile = async event => {
    const csvHeaderInfo = await getCSVHeader(event);
    const snack = csvHeaderInfo.isError && snackService.generateFailureMessage(csvHeaderInfo.message);
    this.setState({ csvHeaderOptions: csvHeaderInfo.data, snack });
  };

  handleDownloadClick = (reportType, index) => {
    const { queryParameters } = this.state;
    const { downloadList } = this.props;
    const { isDateFilterChanged } = this.state;
    return handleArchiveDownload({
      downloadList,
      reportType,
      queryParameters,
      isDateFilterChanged,
    });
  };

  handleDateRangeChange = (start, end) => {
    const { queryParameters } = this.state;
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    this.setState({ isDateFilterChanged: true });
    if (queryParameters.pagination) queryParameters.pagination.page = 1;
    this.basePaginationService.setQueryParamsAndLoadData(queryParameters);
  };

  render() {
    const { queryParameters, data, display, snack, validation, dialogType, upload, csvHeaderOptions } = this.state;
    const { serverResponseWaiting } = this.props;
    const dynamicHeaderData = Object.keys((data.list.length && data.list[0].details) || []);
    return (
      <div className={`srn-report ${!dynamicHeaderData.length > 0 && 'no-data'}`}>
        <PageView
          domainConfig={SRN_REPORT_CONFIG}
          headerConfig={{
            search: true,
            date: true,
            upload: true,
            download: true,
            filter: true,
            create: false,
          }}
          display={display}
          handleUploadClick={this.handleUploadIconClick}
          controlDisplay={this.controlDisplay}
          handleDownloadClick={this.handleDownloadClick}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.basePaginationService.resetFilter}
          handleDateRangeChange={this.handleDateRangeChange}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onPageSelect={this.onPageSelect}
          dynamicHeaderData={dynamicHeaderData}
          dynamicHeaderExist
        />
        <Dialog
          validation={validation}
          upload={upload}
          actionType={dialogType}
          formEmptyField={false}
          handleFileUpload={this.handleFileUpload}
          onInputChange={this.handleInputChange}
          onModalClose={this.handleModalClose}
          onModalSubmit={this.handleModalSubmit}
          csvHeaderOptions={csvHeaderOptions}
          title="SRN"
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}
const SRNReportWithState = withBaseState(SRNReport);

export default SRNReportWithState;
