import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { paymentTypes } from '../../../data/enums/enums';
import { formatToReadableDate } from '../../../utils/filterUtil';

const postDatedChequeConfig = [
  {
    id: 1,
    label: 'id',
    title: 'ID',
    className: 'id',
  },
  {
    id: 2,
    label: 'ref_date',
    title: 'Ref. Date',
  },
  {
    id: 3,
    label: 'ref_number',
    title: 'Ref. Number',
  },
  {
    id: 4,
    label: 'customer_ledger',
    title: 'Customer Ledger',
    className: 'customer',
  },
  {
    id: 5,
    label: 'payment_type',
    title: 'Payment Type',
  },
  {
    id: 6,
    label: 'tag',
    title: 'Tag',
  },
  {
    id: 6,
    label: 'amount ',
    title: 'Amount ',
    className: 'right-align',
  },
  {
    id: 6,
    label: 'narration',
    title: 'Narration',
    className: 'narration',
  },
];

const postDatedChequeTableBody = ({ dataList,remainingLines=[] }) => (
  <tbody>
    {dataList.map((data,index) => (
      <tr className={`cursor-pointer ${index === dataList.length - 1 && 'last-tr'}`}>
        <td className='left-align id'>{data.id}</td>
        <td className='left-align'>{formatToReadableDate(data.reference_date)}</td>
        <td className='left-align'>{data.reference_number}</td>
        <td className='left-align customer'>{data.customer}</td>
        <td className='left-align'>
          {data.payment_type ? paymentTypes.filter((list) => list.id === data.payment_type)[0].title : ''}
        </td>
        <td className='left-align'>{data.title}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.amount)}</td>
        <td className='left-align narration'>{data.narration}</td>
      </tr>
    ))}
    {remainingLines && remainingLines.length
      ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td colSpan ='14' className=''></td>
        </tr>
      ))
      : null}
  </tbody>
);
const lengthyAttributes = ['customer','narration'];
const printEnums = {
  withoutFooterLimit: 42,
  withFooterLimit: 42,
  customer: 32,
  narration: 32,
  lengthyAttributes,
};

postDatedChequeTableBody.propTypes = { data: PropTypes.object.isRequired };

const config = {
  header: postDatedChequeConfig,
  getTableBody: postDatedChequeTableBody,
  printEnums,
  uniqueIdGetter: (i) => i.id
};

export default config;
