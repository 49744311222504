import { compose } from 'recompose';

import LedgerReportDetail from './LedgerReportDetail';

import apiInterceptor from '../../../../api/interceptor';

import { ledgerApi } from '../../../common/base.api';

const composedLedgerDetailReport = compose(apiInterceptor({ ...ledgerApi }))(LedgerReportDetail);

export default composedLedgerDetailReport;
