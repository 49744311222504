import moment from 'moment';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const getTableData = dataList => {
  let tableDataRows = [];
  if (dataList && dataList.length > 0) {
    tableDataRows = dataList?.map((data, index) => {
      return [
        index + 1,
        data.source ?? '',
        data.voucher_number ?? '',
        data.action_type ?? '',
        moment(data.action_date).format('D MMM YYYY') ?? '',
        data.action_miti ?? '',
        moment(data.action_time).format('D MMM YYYY HH:mm'),
        data.action_user ?? '0',
        data.ledger_name ?? '0',
        fixedFloatAndCommas(data.net_amount) ?? '0',
      ]
    });
  }
  return {
    tableDataRows,
  };
};

const getFilteredData = (dataList, companyData, middleHeaderData) => {
  const tableData = getTableData(dataList);
  return {
    companyName: companyData?.title ?? '',
    address: companyData?.address ?? '',
    phone: companyData?.phone ?? '',
     panNo: companyData?.panNo || companyData?.panNumber || '',
    email: companyData?.email ?? '',
    title: middleHeaderData?.title ?? '',
    date: middleHeaderData?.date ?? '',
    tableDataRows: tableData?.tableDataRows ?? [],
  };
};

const getPrintData = (dataList, companyData, middleHeaderData) => {
  const ledgerData = getFilteredData(dataList, companyData, middleHeaderData);
  return ledgerData;
};

export default getPrintData;
