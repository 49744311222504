import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { filterConfig } from './config';
import { has } from '../../../utils/hasOwnProperty';
import BaseFilter from '../../../components/FilterComponent/BaseFilter';
import { FilterItems } from './filterItems';

const propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  filterValue: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {};

class FilterAggregator extends Component {
  handleFilterChange = (fieldName, value) => {
    const { handleFilterChange } = this.props;

    handleFilterChange(fieldName, value);
  };

  getFilterList = label => {
    const { filterDetails } = this.props;
    if (has.call(filterConfig, label)) {
      return filterConfig[label].selectionList || [];
    }

    return filterDetails[label] || [];
  };

  render() {
    const { filterValue, showCollapsibleList} = this.props;
    const filterLabels = Object.keys(filterValue);
    return (
      <div className="row drawer-filter">
        <div className="col-sm-12">
          <div className="filter-content-wrap">
            {filterLabels.map(label => (
              <BaseFilter
                filterList={this.getFilterList(label)}
                selectedElementList={filterValue[label] || []}
                filterTitle={FilterItems[label].title || ''}
                filterLabel={label}
                handleFilterChange={this.handleFilterChange}
                showCollapsibleList={showCollapsibleList}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

FilterAggregator.propTypes = propTypes;

FilterAggregator.defaultProps = defaultProps;

const mapStateToProps = state => ({
  filterDetails: state.filterDetails.metaData || {},
});

const filterAggregator = connect(mapStateToProps)(FilterAggregator);

export default filterAggregator;
