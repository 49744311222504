import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from './AccordionTableItem';

const propTypes = {
  /**
   * @default null
   * children passed within button
   */
  children: PropTypes.node,
  /**
   * @default true
   * enable error display mode in the form
   */
  onOpen: PropTypes.func,
  /**
   * @default true
   * enable validation in the form
   */
  onClose: PropTypes.func,
  /**
   * default true.
   * close on other collapsible click
   */
  allowMultipleOpen: PropTypes.bool,
};

const defaultProps = {
  children: null,
  onOpen: () => null,
  onClose: () => null,
  allowMultipleOpen: false,
};

class Accordion extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    const openSections = {};
    const childrenList = this.getChildrenList(props.children) || [];
    childrenList.forEach(child => {
      if (child.props && child.props.isOpen) {
        openSections[child.props.trigger] = true;
      }
    });

    this.state = { openSections };
  }

  getChildrenList = child => {
    if (child !== null) {
      if (Array.isArray(child)) {
        return child;
      }
      return [child];
    }
  };

  onChange = (opened, trigger) => {
    const {
      props: { onOpen, onClose },
    } = this;

    if (opened) {
      onClose(trigger);
    } else {
      onOpen(trigger);
    }
  };

  onClick = trigger => {
    const {
      props: { allowMultipleOpen },
      state: { openSections },
    } = this;

    const isOpen = !!openSections[trigger];

    if (allowMultipleOpen) {
      this.setState(
        {
          openSections: {
            ...openSections,
            [trigger]: !isOpen,
          },
        },
        () => this.onChange(!isOpen, trigger),
      );
    } else {
      this.setState({
        openSections: {
          [trigger]: !isOpen,
        },
      });
    }
  };

  render() {
    const {
      onClick,
      props: { children, colSpan },
      state: { openSections },
    } = this;
    const childrenList = this.getChildrenList(children) || [];
    return (
      <Fragment>
        {childrenList.map(child =>
          child.props && !child.props.isChildPresent ? (
            child.props.trigger ? (
              <AccordionItem
                key={`accordion-item-${child.key}`}
                isOpen={!!openSections[child.key]}
                trigger={child.props.trigger}
                onClick={onClick}
                id={child.key}
                colSpan={colSpan}
                accordionStatus={child.props.accordionStatus}
              >
                {child.props.children}
              </AccordionItem>
            ) : (
              <Fragment key={`children-${child.key}`}>{child.props.children}</Fragment>
            )
          ) : (
            <Fragment key={`children-${child.key}`}>{child.props && child.props.simpleChild}</Fragment>
          ),
        )}
      </Fragment>
    );
  }
}

export default Accordion;
