import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const EntryLogTableBody = ({ data }) => (
  <tr>
    <td className='left-align'>{data.source}</td>
    <td className='left-align'>{data.voucher_number}</td>
    <td className='left-align'>{data.action_type}</td>
    <td className='left-align'>{moment(data.action_date).format('D MMM YYYY')}</td>
    <td className='left-align'>{data.action_miti}</td>
    <td className='left-align'>{moment(data.action_time).format('D MMM YYYY HH:mm')}</td>
    <td className='left-align'>{data.action_user}</td>
    <td className='left-align'>{data.ledger_name}</td>
    <td className='left-align'>{data.net_amount ? data.net_amount.toLocaleString() : data.net_amount}</td>
  </tr>
);

EntryLogTableBody.propTypes = { data: PropTypes.object.isRequired, };

const entryLogConfig = {
  headerDetails: [
    { id: 1, label: 'source', title: 'Source', className: 'cursor-pointer left-align', sortable: true },
    { id: 2, label: 'voucher_number', title: 'Voucher Number', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'action_type', title: 'Action Type', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'action_date', title: 'Action Date', className: 'cursor-pointer', sortable: true },
    { id: 5, label: 'action_miti', title: 'Action Miti', className: 'cursor-pointer', sortable: true },
    { id: 6, label: 'action_time', title: 'Action Time', className: 'cursor-pointer', sortable: true },
    { id: 7, label: 'action_user', title: 'Action User', className: 'cursor-pointer', sortable: true },
    { id: 8, label: 'ledger_name', title: 'Ledger', className: 'cursor-pointer', sortable: true },
    { id: 9, label: 'net_amount', title: 'Net Amount', className: 'cursor-pointer', sortable: true },
  ],
};

export { entryLogConfig, EntryLogTableBody };
