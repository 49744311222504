import { fixedFloatAndCommas } from '../../../utils/conversion';

const getTableData = dataList => {
  let tableDataRows = [];
  if (dataList && dataList.length > 0) {
    let counter = 0;
    tableDataRows = dataList?.map(data => {
      if (data.hasOwnProperty('groupIndex')) {
        counter += 1;
      }
      return [
        !data.hasOwnProperty('total') ? counter : '',
        data.groupIndex === 0 ? data.customer_name : '',
        data.voucher_number ?? '',
        data.document_date ?? '',
        !data.hasOwnProperty('total') ? fixedFloatAndCommas(data.bill_amount) ?? '0' : 'Total',
        !data.hasOwnProperty('total')
          ? fixedFloatAndCommas(Number(data.outstanding_amount)) ?? '0'
          : fixedFloatAndCommas(data.total),
        !data.hasOwnProperty('total') ? fixedFloatAndCommas(data.due_date) ?? '0' : '',
        !data.hasOwnProperty('total') ? fixedFloatAndCommas(data.bill_age) ?? '0' : '',
      ];
    });
  }
  return {
    tableDataRows,
  };
};

const getFilteredData = (dataList, companyData, middleHeaderData, extraHeaderData) => {
  const tableData = getTableData(dataList);
  const extraHeader = [
    { title: 'Sales Person', value: extraHeaderData?.salesPersonName ?? '' },
    { title: 'Grand Total Outstanding', value: extraHeaderData?.grandTotal ?? '0' },
  ];
  return {
    companyName: companyData?.title ?? '',
    address: companyData?.address ?? '',
    phone: companyData?.phone ?? '',
     panNo: companyData?.panNo || companyData?.panNumber || '',
    email: companyData?.email ?? '',
    title: middleHeaderData?.title ?? '',
    date: middleHeaderData?.date ?? '',
    tableDataRows: tableData?.tableDataRows ?? [],
    dataMiscellaneousList: extraHeader,
    // tableFooterData: {
    //   total: fixedFloatAndCommas(columnTotal?.totalItemQuantity)?.toString() ?? '0',
    // },
  };
};

const getPrintData = (dataList, companyData, middleHeaderData, extraHeaderData) => {
  const ledgerData = getFilteredData(dataList, companyData, middleHeaderData, extraHeaderData);
  return ledgerData;
};

export default getPrintData;
