import React, { Component } from 'react';
import View from './View';
import tableConfig from './config';
import TrialBalanceStyle from './TrialBalanceStyle';
import withBaseState from '../../common/withBaseState';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import PageHeader from '../../common/pagination/PageHeader';
import { TRIAL_BALANCE_CONFIG } from '../../common/domain.config';
import Loading from '../../../common/Loading';
import * as filterUtil from '../../../utils/filterUtil';
import { accountReportViewOptions } from '../../common/DrawerFilter/config';
import * as downloadService from '../../common/download.service';

class TrialBalance extends Component {
  constructor(props) {
    super(props);
    const { pagination, ...queryParameter } = queryService.queryParameters;
    this.state = {
      display: {
        drawer: true,
      },
      queryParameters: {
        queryParameter,
        date: {
          start: filterUtil.formatToDateMonthYear(props.fiscalYearRange.start_date),
          end: filterUtil.getCurrentDay(),
        },
        viewType: accountReportViewOptions[0].label,
      },
      data: {
        list: [],
        groupedData: [],
        totalBalance: {},
        total: 0,
      },
      activeRow: 0,
      trialBalanceDetail: [],
      snack: { ...snackService.snackParameters },
      activeView: accountReportViewOptions[0].label,
    };
    const { downloadList } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { getTrialList } = this.props;
    const { queryParameters, activeRow } = this.state;
    getTrialList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.data;
      data.groupedData = response.groupedData || [];
      data.totalBalance = response.totalBalance;
      data.totalGroupBalance = response.totalGroupBalance;
      data.total = response.total ? response.total : response.data.length;
      const detail = (data.groupedData.length && data.groupedData[activeRow].list) || [];
      this.setState({ data, trialBalanceDetail: detail });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  resetFilter = () => {
    this.setState(
      {
        queryParameters: {
          ...queryService.queryParameters,
          date: {
            start: filterUtil.formatToDateMonthYear(this.props.fiscalYearRange.start_date),
            end: filterUtil.getCurrentDay(),
          },
          viewType: accountReportViewOptions[0].label,
        },
      },
      () => {
        this.loadTableData();
      },
    );
  };

  handleRowClick = (data, index) => {
    this.setState({
      trialBalanceDetail: data,
      activeRow: index,
    });
  };

  handleViewChange = activeView => {
    this.setState({ activeView });
  };

  handleDownloadClick = reportType => {
    const { downloadList } = this.props;
    const { queryParameters, activeView } = this.state;
    const extraQueryString = `&view_type=${activeView}`;
    downloadList({
      type: reportType,
      query: queryParameters,
      extraQueryString,
    }).then(response => downloadService.resolver(response));
  };

  render() {
    const { data, display, queryParameters, snack, trialBalanceDetail, activeView } = this.state;
    const { serverResponseWaiting, company } = this.props;
    return (
      <TrialBalanceStyle>
        <div className="trial-balance">
          <PageHeader
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            handleDownloadClick={this.handleDownloadClick}
            controlDisplay={this.controlDisplay}
            clearSearchText={this.basePaginationService.clearSearchText}
            queryParameters={queryParameters}
            domainConfig={TRIAL_BALANCE_CONFIG}
            display={display}
            config={{
              search: false,
              filter: true,
              date: true,
              create: false,
              download: true,
            }}
            handleUploadClick={this.basePaginationService.handleUploadClick}
            resetFilter={this.resetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handlePrintClick={this.basePaginationService.handlePrintClick}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            handleDateChange={this.basePaginationService.handleDateFilterChange}
            viewOptions={{
              list: accountReportViewOptions,
              handler: this.handleViewChange,
              selectedValue: activeView,
            }}
            serverResponseWaiting={serverResponseWaiting}
          />
          <View
            data={data}
            company={company}
            config={tableConfig}
            queryParameters={queryParameters}
            onTableClickData={trialBalanceDetail}
            onRowClick={this.handleRowClick}
            activeView={activeView}
          />
        </div>
      </TrialBalanceStyle>
    );
  }
}

const TrialBalanceWithState = withBaseState(TrialBalance);

export default TrialBalanceWithState;
