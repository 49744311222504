import moment from 'moment';

/**
 * @returns {Array} {title: week 1, id: 1}
 */
const getWeekList = () => {
  const weekArray = [];
  for (let count = 1; count <= 52; count++) {
    weekArray.push({ title: `Week ${count}`, id: count });
  }
  return weekArray;
};

// return the object with assigned attributes
const getObjectWithAssignedAttributes = object => {
  Object.keys(object).map(attribute => {
    if (object[attribute].length === 0) {
      delete object[attribute];
    }
  });
  return object;
};

const getStartOfCurrentMonth = () => moment().startOf('month').format('DD MMM YYYY');

const getEndOfCurrentMonth = () => moment().endOf('month').format('DD MMM YYYY');

const getCurrentDay = () => moment().format('DD MMM YYYY');

const getCurrentTime = () => moment().format('hh:mm:ss a');

const formatToNumericDate = date => moment(date).format('YYYY-MM-DD');

const formatToReadableDate = date => moment(date).format('ll');

const formatToDateMonthYear = date => moment(date).format('DD MMM YYYY');

const formatToMonthDateYear = date => moment(date).format('MMM D, YYYY');

const getCurrentDate = () => moment();

const getMomentDate = date => moment(date);

const getTime = (date) => moment(date).format('hh:mm:ss a');

const DateToRender = date => {
  const startDate = moment(date?.start ?? '');
  const endDate = moment(date?.end ?? '');
  const today = moment(new Date());
  let dateToRender = '';
  if (startDate.isSame(endDate)) {
    if (startDate.year() == today.year() && startDate.month() == today.month() && startDate.day() == today.day()) {
      dateToRender = 'Today';
    } else if (startDate.year() == today.year()) {
      dateToRender = startDate.format('MMM D');
    } else {
      dateToRender = startDate.format('MMM D, YYYY');
    }
  } else if (startDate.year() == endDate.year() && endDate.year() == today.year()) {
    dateToRender = `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`;
  } else {
    dateToRender = `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`;
  }

  return dateToRender;
};

export {
  getCurrentDate,
  getWeekList,
  getObjectWithAssignedAttributes,
  getStartOfCurrentMonth,
  getEndOfCurrentMonth,
  getCurrentDay,
  getCurrentTime,
  formatToNumericDate,
  formatToReadableDate,
  formatToDateMonthYear,
  getMomentDate,
  formatToMonthDateYear,
  DateToRender,
  getTime
};
