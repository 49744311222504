import React from 'react';

const UserGroupTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <>
        <tr onClick={() => onRowClick(data)}>
          <td className='left-align'>{data.name}</td>
          <td className='left-align'>{data.Users.length}</td>
        </tr>
      </>
    ))}
  </tbody>
);

const UserGroupTableHeader = [
  {
    id: 1,
    label: 'title',
    title: 'Title',
    className: 'cursor-pointer left-align',
    sortable: true,
  },
  {
    id: 1,
    label: 'users',
    title: 'Users',
    className: 'cursor-pointer left-align',
    sortable: true,
  },
];

const config = {
  header: UserGroupTableHeader,
  getTableBody: UserGroupTableBody,
};

export default config;
