import PropTypes from 'prop-types';
import React from 'react';
import {
  Grid, Cell, Textfield
} from 'react-mdc-web';

const Header = ({
  customerList,
  company,
  pageTitle,
  divClassName,
  date,
  miti,
  dataMiscellaneousList,
  paymentModeStatus,
  paymentMode,
  printCountInfo,
  exciseNumber,
  exciseStatus,
}) => {
  return (
    <div>
      <div className={divClassName}>
        <Grid className='padding-b-12'>
          <Cell col={3}>
            <div className='margin-b-2 header-title'>{company.title}</div>
            <span className='margin-b-2 header-title-content'>
              <span className='active-opacity-text'>Address: </span>
              <span>{company.address}</span>
            </span>
            <span className='margin-b-2 header-title-content'>
              <span className='active-opacity-text'>Phone: </span>
              <span>{company.phone}</span>
            </span>
            <span className={`header-title-content ${!!exciseNumber && exciseStatus && 'no-margin-bottom'}`}>
              <span className='active-opacity-text'>PAN No: </span>
              <span>{company.panNumber}</span>
            </span>
            {!!exciseNumber && exciseStatus &&
            <span className='no-margin-bottom header-title-content'>
              <span className='active-opacity-text'>Excise No: </span>
              <span>{exciseNumber}</span>
            </span>
            }
          </Cell>
          <Cell col={3}>
            <div className='header-middle-content'>
              <h2>{pageTitle}</h2>
              {printCountInfo && <h5>Print Count : {printCountInfo.value}</h5>}
            </div>
          </Cell>
          <Cell col={2}>
            <div className='right-align'>
              <span className='header-right-content'>
                <span className=''>Date: </span>
                <span className='font-bold'>{date}</span>
              </span>
              <span className='header-right-content'>
                <span className=''>Miti: </span>
                <span className='font-bold'>{miti}</span>
              </span>
              {
                paymentModeStatus &&  <span className='no-margin-bottom header-title-content'>
                  <span className='active-opacity-text'>Payment Mode: </span>
                  <span className='font-bold'>{paymentMode}</span>
                </span>
              }

            </div>
          </Cell>
        </Grid>
      </div>
      {/*<div className="header-middle-content">
          <h2>{pageTitle}</h2>
        </div>*/}
      {dataMiscellaneousList.length > 0 && (
        <div className='custom-three-input-wrapper padding-bottom-0 print-invoice three-input-wrapper'>
          <Grid>
            {
              dataMiscellaneousList.map((item,key) => {
                return (
                  <Cell col={ key%2 === 0 ? 3 : 5} key={key} className='input-print'>

                    <label htmlFor=''>{item.title}</label>
                    <p className='text-area print font-bold'>{item.value}</p>
                    {/* <Textfield
                      className="ss"
                      floatingLabel={item.title}
                      value={item.value}
                      onChange={({target : {value : state}}) => {
                      }}
                    /> */}
                  </Cell>
                );
              })}
          </Grid>
        </div>
      )}
    </div>
  );
};
Header.defaultProps = {
  dataMiscellaneousList: [],
  paymentModeStatus: false,
};
Header.propTypes = { company: PropTypes.object, };

export default Header;
