import React from 'react';
import { fixedFloatAndCommas, roundAndCommas } from '../../../utils/conversion';

const DayBookSummaryBody = ({ dataSet = [],remainingLines=[] ,columnTotal={},footer=false, }) => (
  <tbody>
    {
      dataSet.map((data, index) => {
        return (
          <tr>
            <td className='left-align'>{data.name}</td>
            <td className='right-align'>{data.debit ? roundAndCommas(data.debit) : 0}</td>
            <td className='right-align'>{data.credit ? roundAndCommas(data.credit) : 0}</td>
          </tr>
        );
      })}
    { footer &&
  <Total columnTotal ={columnTotal}/>
    }
    {remainingLines && remainingLines.length
      ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td colSpan ='11' className=''></td>
        </tr>
      ))
      : null}
  </tbody>
);
const Total = ({ columnTotal = {} }) => (
  <tr className='total'>
    <td className='font-bold left-align'>Total</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.debit)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.credit)}</td>
  </tr>
);


const DayBookSummaryConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'name',
      title: 'Title',
      className: '',
      sortable: false,
    },
    {
      id: 2,
      label: 'debit',
      title: 'Debit',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 3,
      label: 'credit',
      title: 'Credit',
      className: 'right-align',
      sortable: false,
    },
  ],
};

const DayBookTableFooter = ({ columnTotal = {} }) => (
  <tfoot className='right-content-footer'>
    <Total columnTotal ={columnTotal}/>
  </tfoot>
);
const lengthyAttributes = ['name'];
const printEnums = {
  withoutFooterLimit: 50,
  withFooterLimit: 50,
  name: 100 ,
  lengthyAttributes,
};

const config = {
  header: DayBookSummaryConfig,
  getTableBody: DayBookSummaryBody,
  getTableFooter: DayBookTableFooter,
  printEnums,
  uniqueIdGetter: (i) => i.name
};

export default config;
