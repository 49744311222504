import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';
import { LEDGERS } from '../../../../data/enums/enums';
import { fixedFloatAndCommas } from '../../../../utils/conversion';

class CreateAbbreviatedInvoiceTableBody extends Component {
  render() {
    const { data, index } = this.props;
    const discount = Number(data.promotion_discount);
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{data.skuTitle}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.rateWithVat)}</td>
        <td className='right-align'>{data.quantity}</td>
        <td>{data.uom?.label}</td>
        <td className='right-align'>{fixedFloatAndCommas(discount)}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.net_amount)}</td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => this.props.handleEditIconClick(data, index)} />
          <Icon name='delete' onClick={() => this.props.handleDeleteIconClick(index)} />
        </td>
      </tr>
    );
  }
}

CreateAbbreviatedInvoiceTableBody.defaultProps = { enableUpdate: true, };

CreateAbbreviatedInvoiceTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteIconClick: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  enableUpdate: PropTypes.bool,
};

const createAbbreviatedInvoiceConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'test',
      title: 'SN',
      className: null,
      sortable: false,
    },
    {
      id: 2,
      label: 'sku',
      title: 'SKU',
      className: null,
      sortable: false,
    },

    {
      id: 3, label: 'rate', title: 'Rate (inc. VAT)', className: 'right-align', sortable: false,
    },
    {
      id: 4,
      label: 'quantity',
      title: 'Quantity (pcs)',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 5,
      label: 'unit',
      title: 'Unit',
      className: null,
      sortable: false,
    },
    {
      id: 6,
      label: 'discount',
      title: 'Discount',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 7,
      label: 'amount',
      title: 'Amount',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 8,
      label: 'test',
      title: '',
      className: 'right-align',
      sortable: false,
    },
  ],
};

const discountOptions = [
  { value: 'per', label: 'in %' },
  { value: 'rs', label: 'in Rs' },
];

const message = {
  success: 'Abbreviated Invoice created successfully.',
  error: '!Error while creating Abbreviated Invoice',
  panNoRequiredError: 'PAN number required!',
  abiAmountLimitError: `Abbreviated invoice amount should not exceed ${LEDGERS.CASH.validAbiAmount}`,
  cashBillError: `CASH bill cannot exceed ${LEDGERS.CASH.validInvoiceAmount.toLocaleString()}`,
  invoiceListEmptyError: 'Abbreviated invoice list is empty!',
  agent: 'Salesperson required!',
};

const pageValidateFieldTypes = {
  cash: [],
  genuineCustomer: ['name', 'panNo'],
  customer: [],
};

const customerType = {
  CUSTOMER: 'CUSTOMER',
  CASH: 'CASH',
};

export {
  createAbbreviatedInvoiceConfig,
  CreateAbbreviatedInvoiceTableBody,
  customerType,
  pageValidateFieldTypes,
  message,
  discountOptions,
};
