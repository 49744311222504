import React, { Fragment } from 'react';
import OpeningBalanceChildView from './childView';
import { ACTION } from '../../../data/enums/enums';
import AccordionTable from '../../../components/CollapsibleTable/AccordionTable';

const getSKUData = (sku = {}) => {
  const details = { businessId: sku.businessId || '', };
  return details;
};

const getApiTransformedData = (sku, action = ACTION.ADD) => {
  const details = { business_id: sku.businessId || '', };
  if (action === ACTION.ADD) {
    // TODO
  }
  return details;
};

const TableBody = ({ data, onRowClick }) => (
  <Fragment>
    <td className='left-align' onClick={() => onRowClick(data)}>
      <span>{data.skuTitle}</span>
    </td>
    <td className='right-align' onClick={() => onRowClick(data)}>
      <span>{data.openingBalance}</span>
    </td>
  </Fragment>
);

const SKUTableBody = ({ dataList, onRowClick, onTableClickData ,handleInputChange ,dialogType ,activeIndex  }) => (
  <tbody>
    {
      <AccordionTable>
        {dataList.map((data, index) => (
          <tr
            key={index}
            trigger={TableBody({
              data,
              onRowClick,
            })}
          >
            {OpeningBalanceChildView({ data,handleInputChange ,onRowClick ,dialogType ,activeIndex  ,index })}
          </tr>
        ))}
      </AccordionTable>
    }
  </tbody>
);

const customerLedgerConfig = [
  {
    id: 2,
    label: 'skus.title',
    title: 'SKU',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 4,
    label: '"openingBalance"',
    title: 'Opening Balance',
    className: 'cursor-pointer',
    sortable: true,
  },
];

const config = {
  header: customerLedgerConfig,
  getTableBody: SKUTableBody,
  getDetail: getSKUData,
  getApiTransformedData,
};

export default config;
