import { compose } from 'recompose';

import apiInterceptor from '../../api/interceptor';

import { loginApi, buApi } from '../common/base.api';

import Login from './Login';

const composedLogin = compose(apiInterceptor({ ...loginApi, getBuList: buApi.getList }))(Login);

export default composedLogin;
