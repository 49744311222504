import { compose } from 'recompose';

import apiInterceptor from '../../../../api/interceptor';

import CreatePurchaseReturn from './CreatePurchaseReturn';
import {
  buApi, catalogsApi, ledgerApi, prnApi
} from '../../../common/base.api';

const composedCreatePurchaseReturn = compose(
  apiInterceptor({
    ...buApi,
    ...catalogsApi,
    create: { ...prnApi },
    getAvailablePurchase: { ...prnApi },
    getVendorList: { ...ledgerApi },
  }),
)(CreatePurchaseReturn);

export default composedCreatePurchaseReturn;
