import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';

import BillingTextField from '../../../components/TextField/BillingTextField';
import { ACTION } from '../../../data/enums/enums';

const BusinessGroup = ({
  businessGroup,
  actionType,
  onModalClose,
  onModalSubmit,
  onInputChange, // onChange
  validation,
}) => {
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>{actionType}</DialogTitle>
      </DialogHeader>

      <DialogBody>
        <div className='dialog-upperpart'>
          <Grid className='grid-padding'>
            <Cell col={5} tablet={8}>
              <BillingTextField
                value={businessGroup.business_alias}
                handleChange={onInputChange}
                floatingLabel='Name'
                param='business_alias'
                required={true}
                className='right-side-gap'
                emptyField={validation.flag}
              />
            </Cell>
          </Grid>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default BusinessGroup;
