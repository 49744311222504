import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { formatToReadableDate } from '../../../../utils/filterUtil';
import { fixedFloatAndCommas } from '../../../../utils/conversion';

const SalesMaterializedViewTableBody = ({ dataList, onRowClick,remainingLines }) => (
  <tbody>
    {dataList.map((data) => (
      <tr className='cursor-default'>
        <td className='left-align fiscal-year'>{data.fiscalYear}</td>
        <td className='left-align bill-no'>{data.billNo}</td>
        <td className='left-align customer-name'>{data.customerName}</td>
        <td className='left-align customer-pan'>{data.customerPan}</td>
        <td className='left-align bill-date'>{moment(data.billDate).format('D MMM YYYY')}</td>
        <td className='left-align miti'>{data.mitiTitle}</td>
        <td className='right-align amount'>{data.amount ? fixedFloatAndCommas(data.amount) : data.amount}</td>
        <td className='right-align discount'>{fixedFloatAndCommas(data.discount)}</td>
        <td className='right-align tax-exempt-amount'>{fixedFloatAndCommas(data.taxExemptedAmount) || 0}</td>
        <td className='right-align taxable-amount'>
          {data.taxableAmount ? fixedFloatAndCommas(data.taxableAmount) : data.taxableAmount}
        </td>
        <td className='right-align tax-amount'>{fixedFloatAndCommas(data.taxAmount)}</td>
        <td className='right-align total-amount'>
          {data.totalAmount ? fixedFloatAndCommas(data.totalAmount) : data.totalAmount}
        </td>
        <td className='center-align sync'>{data.syncWithIrd}</td>
        <td className='center-align bill-print '>{data.isBillPrinted}</td>
        <td className='center-align bill-active'>{data.isBillActive}</td>
        <td className='left-align print-time print_display_none_A4'>
          {data.printedTime}
          {/* {data.printedTime ? moment(moment(data.printedTime, 'YYYY-MM-DD:hh:mm:ss').toDate()).format('YYYY-MM-DD:hh:mm') : data.printedTime} */}
        </td>
        <td className='left-align entered-by print_display_none_A4'>{data.enteredBy}</td>
        <td className='left-align print-by print_display_none_A4'>{data.printedBy}</td>
        <td className='center-align real-time print_display_none_A4 '>{data.isRealtime}</td>
        <td className='center-align payment-method print_display_none_A4'>{data.paymentMethod}</td>
        <td className='right-align vat-refund print_display_none_A4'>{data.vatRefundAmount}</td>
        <td className='right-align transaction print_display_none_A4'>{data.transactionId}</td>
      </tr>
    ))}
         {remainingLines && remainingLines.length
                    ? remainingLines.map((i) => (
                        <tr className='blank-tr'>
                          <td></td>
                          <td colSpan ="14" className=''></td>
                        </tr>
                      ))
      : null}
  </tbody>
);

SalesMaterializedViewTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const salesMaterializedViewConfig = [
  {
    id: 1,
    label: 'fiscal_year',
    title: 'Fiscal Year',
    className: 'cursor-pointer fiscal-year',
    sortable: true,
  },
  {
    id: 2,
    label: 'bill_no',
    title: 'Bill No',
    className: 'cursor-pointer left-align bill-no',
    sortable: true,
  },
  {
    id: 3,
    label: 'customer_name',
    title: 'Customer',
    className: 'cursor-pointer customer-name',
    sortable: true,
  },
  {
    id: 4,
    label: 'customer_pan',
    title: 'PAN No.',
    className: 'cursor-pointer customer-pan',
    sortable: true,
  },
  {
    id: 5,
    label: 'bill_date',
    title: 'Bill Date',
    className: 'cursor-pointer left-align bill-date',
    sortable: true,
  },
  {
    id: 5,
    label: 'miti_title',
    title: 'Bill Miti',
    className: 'cursor-pointer left-align miti ',
    sortable: true,
  },
  {
    id: 6,
    label: 'amount',
    title: 'Amount',
    className: 'cursor-pointer right-align amount',
    sortable: true,
  },
  {
    id: 7,
    label: 'discount',
    title: 'Discount',
    className: 'cursor-pointer right-align discount',
    sortable: true,
  },
  {
    id: 8,
    label: 'tax_exempted_amount',
    title: 'Tax Exempted Amount',
    className: 'cursor-pointer right-align tax-exempt-amount',
    sortable: true,
  },
  {
    id: 9,
    label: 'taxable_amount',
    title: 'Taxable Amount',
    className: 'cursor-pointer right-align taxable-amount',
    sortable: true,
  },
  {
    id: 10,
    label: 'tax_amount',
    title: 'Tax Amount',
    className: 'cursor-pointer right-align tax-amount',
    sortable: true,
  },
  {
    id: 11,
    label: 'total_amount',
    title: 'Total Amount',
    className: 'cursor-pointer right-align total-amount',
    sortable: true,
  },
  {
    id: 12,
    label: 'sync_with_ird',
    title: 'Sync with IRD',
    className: 'cursor-pointer left-align sync',
    sortable: true,
  },
  {
    id: 13,
    label: 'is_bill_printed',
    title: 'Bill Printed',
    className: 'cursor-pointer left-align bill-print',
    sortable: true,
  },
  {
    id: 14,
    label: 'is_bill_active',
    title: 'Bill Active',
    className: 'cursor-pointer left-align bill-active',
    sortable: true,
  },
  {
    id: 15,
    label: 'printed_time',
    title: 'Printed Time',
    className: 'cursor-pointer left-align print-time print_display_none_A4',
    sortable: true,
  },
  {
    id: 16,
    label: 'entered_by',
    title: 'Entered By',
    className: 'cursor-pointer left-align entered-by print_display_none_A4',
    sortable: true,
  },
  {
    id: 17,
    label: 'printed_by',
    title: 'Printed By',
    className: 'cursor-pointer left-align print-by print_display_none_A4',
    sortable: true,
  },
  {
    id: 18,
    label: 'is_realtime',
    title: 'Real Time',
    className: 'cursor-pointer left-align real-time print_display_none_A4',
    sortable: true,
  },
  {
    id: 19,
    label: 'paymentMethod',
    title: 'Payment Method',
    className: 'center-align cursor-pointer payment-method print_display_none_A4 ',
    sortable: true,
  },
  {
    id: 20,
    label: 'vatRefundAmount',
    title: 'VAT Refund Amount',
    className: 'cursor-pointer right-align vat-refund print_display_none_A4',
    sortable: true,
  },
  {
    id: 21,
    label: 'transactionId',
    title: 'Transaction ID',
    className: 'cursor-pointer right-align transaction print_display_none_A4',
    sortable: true,
  },
];
const lengthyAttributes = ['customerName','enteredBy'];
const printEnums = {
  withoutFooterLimit: 60,
  withFooterLimit: 60,
  customerName: 26,
  enteredBy: 28,
  lengthyAttributes,
};

const config = {
  header: salesMaterializedViewConfig,
  getTableBody: SalesMaterializedViewTableBody,
  printEnums,
  uniqueIdGetter: i => i.id
};

export default config;
