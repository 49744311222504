import { compose } from 'recompose';
import apiInterceptor from '../../../api/interceptor';
import AggregateLedgerReport from './AggregateLedgerReport';
import { ledgerGroupApi, ledgerApi } from '../../common/base.api';

const composedAggregateLedgerReport = compose(
  apiInterceptor({
    ...ledgerGroupApi,
    getCustomerList: ledgerApi.getCustomerList,
  }),
)(AggregateLedgerReport);

export default composedAggregateLedgerReport;
