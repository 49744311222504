const FilterItems = {
  business_id: {
    title: 'Business Unit',
    value: 'id',
    label: 'business_id',
    objMapper: { id: 'businessId', title: 'businessAlias' },
  },
  catalog_detail_id: {
    title: 'Brand',
    value: 'id',
    label: 'catalog_detail_id',
    objMapper: { id: 'idCatalogDetail', title: 'title', businessId: 'businessId' },
  },
  jv_transaction_type: {
    title: 'Transaction Type',
    label: 'jv_transaction_type',
    value: 'id',
  },
  outstanding_range: {
    title: 'Outstanding Balance',
    label: 'outstanding_range',
    value: 'id',
  },
  period_id: {
    title: 'Fiscal Year',
    value: 'id',
    label: 'period_id',
    objMapper: { id: 'idFiscalYear', title: 'title', businessId: 'businessId' },
  },
  payment_type: {
    title: 'Payment Type',
    label: 'payment_type',
    value: 'id',
  },
  business_id_thirdParty: {
    title: 'Business Unit',
    value: 'id',
    label: 'business_id_thirdParty',
    objMapper: { id: 'businessId', title: 'businessAlias' },
  },
  agent_ids: {
    title: 'Sales Person',
    label: 'agent_ids',
    value: 'id',
    objMapper: { id: 'idAgent', title: 'name', businessId: 'businessId', status:'status' },
  },
  account_groups: {
    title: 'Account Group',
    label: 'account_groups',
    value: 'id',
    objMapper: { id: 'idAccountGroup', title: 'title' },
  },
};

const MAX_LIMIT_OFFSET_FILTER = {
  limit: 10000,
  offset: 0,
};

export { FilterItems, MAX_LIMIT_OFFSET_FILTER };
