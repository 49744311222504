import React from 'react';
import { has } from '../../../utils/hasOwnProperty';
import { formatToReadableDate } from '../../../utils/filterUtil';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { TRANSACTION_TYPE } from '../../../data/enums/enums';
import { jvTypeList } from '../create/config';
import { Icon } from '../../../components/BillingMDC';
import { LEDGERS } from '../../../data/enums/enums';

const title = 'Credit Note';
const getType = (data = '') => {
  const type = data && jvTypeList.find((v) => v.title === data).label;
  return type;
};
const getSummaryViewList = (data = {}) => {
  const summaryList = [
    {
      title: 'Credit Note ID',
      value: data.voucherNumber || '',
    },
    {
      title: 'Type',
      value: getType(data.type) || '',
      className: 'print_display_none',
    },
    {
      title: 'Ref. Date',
      value: formatToReadableDate(data.refDate) || '',
      className: 'print_display_none',
    },
    {
      title: 'Party Ledger',
      value: data.partyLedger || '',
    },
    {
      title: 'PAN',
      value: data?.panNo==='0'?  '' :data?.panNo || '',
    },
    {
      title: 'Orginal Invoice Number',
      value:  data.refNumber,
    },
    {
      title: 'Narration',
      value: data.narration,
      className: 'print_display_none',
    },
  ];

  return summaryList;
};

const getPrintSummaryDetails = (data = {}) => {
  const printSummaryDetails = [
    { title: 'Voucher Number', value: data.voucherNumber },
  ];

  return printSummaryDetails;
};

const CNDetailBody = ({ dataList,remainingLines,onRowClick,ledgerList=[],drList ,print=false }) => {
  const list =print ? dataList :drList;
  return(
    <tbody>
      {list?.map((data, index) => (
        <tr className='cursor-pointer'>
          <td>{data.index ? data.index + 1 : index + 1}</td>
          <td className='left-align title'>{data?.customerTitle}</td>
          <td className='print_display_none left-align'>{formatToReadableDate(data.documentDate)}</td>
          <td className='right-align amount'>{fixedFloatAndCommas(data.drAmount)}</td>
          <td className='left-align narration'>{data.narration}</td>
          <td className='right-align print_display_none'>
            <Icon name='edit' onClick={() => onRowClick(data, index)} />
          </td>
        </tr>
      ))}
      {remainingLines && remainingLines.length
        ? remainingLines.map((i) => (
          <tr className='blank-tr'>
            <td></td>
            <td colSpan ='11' className=''></td>
          </tr>
        ))
        : null}
    </tbody>
  )};

const getFooterContent = (data) => {
  const footerList = [
    { title: 'Sub Total', value: data.drAmount },
    { title: 'VAT', value: data.vatAmount },
    { title: 'Total', value: data.crAmount, className: 'print_display_none' },
  ];
  return footerList;
};

const userDetailMapper = (data = {}) => {
  const userDetail = { id: 'userId', title: '' };
  if (has.call(data, 'actionUserDetail') && data.actionUserDetail) {
    userDetail.title = data.actionUserDetail.name || '';
  } else userDetail.title = data.journalVoucher ? (data.journalVoucher.user ? data.journalVoucher.user.name : '') : '';
  return userDetail;
};

const CNDetailConfig = [
  { id: 1, label: 'SN', title: 'SN' },
  { id: 2, label: 'ledgers.title', title: 'Ledger' ,className:'title' },
  { id: 3, label: 'createdAt', title: 'Date', className: 'print_display_none' },
  { id: 5, label: 'amount', title: 'Amount', className: 'right-align amount' },
  { id: 6, label: 'narration', title: 'Narration', className: 'narration' },
  {
    id:7,
    label: '',
    title: '',
    className: 'print_display_none',
    sortable: false,
  },
];

const signatureTitleList = [
  { id: 0, title: 'Received By', value: '' },
  {
    id: 1,
    title: 'Prepared By',
    value: '',
  },
  { id: 3, title: 'ApprovedBy', value: '' },
];
const getData = (data, label) => {
  switch(label) {
  case 'narration': return (data.narration) || null;
  case 'customerTitle': return (data.customer && data.customer.title) || null;
  default:
    return null;
  }
}
const lengthyAttributes = ['customerTitle','narration'];
const printEnums = {
  customerTitle:46,
  narration:46,
  withoutFooterLimit: 42,
  withFooterLimit: 32,
  lengthyAttributes,
};

const formValidationFieldList = [{ title: 'drAmount' } ,
];
const formMapper = (element) => ({
  drAmount: element?.drAmount || 0,
  crAmount: element?.crAmount ||0,
  narration: element?.narration || '',
  customerId:element?.customer?.customerId ||0,
});

const getApiTransformedData =(data) => {
  const transformedJVList  = data.map((d) => {
    return {
      id_journal_voucher_detail: d.idJournalVoucherDetail || 0,
      dr_amount :d.drAmount || 0,
      cr_amount :d.crAmount || 0,
      narration:d.narration || '',
      ledger_id:d.customer.customerId || '',
      party_id :d.partyLedgerId || 0,
    }
  })
  return transformedJVList;
}
const updateApiTransformedData = (data,summary) => {
  const transformedJV = {
    inputDetails:[...getApiTransformedData(data)],
    total_credit_amount:summary.totalCrAmount,
    total_debit_amount:summary.totalDrAmount,
    document_date: summary?.documentDate,
    transaction_type: TRANSACTION_TYPE.CREDIT_NOTE,
  };
  return transformedJV;

};

const VAT_PARTY_ID ='vatPartyId'
const VAT_INDEX ='vatIndex';
const CR_INDEX ='crIndex';
const DR_INDEX='drIndex';
const DR_WITH_ID_INDEX='drWithIdIndex';
const VAT_LIST='vatList';
const CR_LIST ='crList';
const DR_LIST='drList';

const getFilteredList = (field,list,id) => {
  switch (field) {
  case VAT_PARTY_ID:
    return (list?.find((a) => a.customer.customerId === LEDGERS.VAT.ledger_id)?.partyLedgerId);
  case VAT_LIST:
    return (list?.filter((a) => a.customer.customerId === LEDGERS.VAT.ledger_id));
  case DR_LIST:
    return(list.filter(({ customer }) =>{ return (customer?.customerId !==id  && customer?.customerId !== LEDGERS.VAT.ledger_id) }));
  case CR_LIST:
    return(list.filter((a) => a?.customer?.customerId === id));
  default:
    break;
  }
};
const getFilteredListIndex = (field,list,id,jvId) => {
  switch (field) {
  case CR_INDEX:
    return  (list.findIndex((a) => a?.customer?.customerId === id));
  case VAT_INDEX:
    return(list.findIndex((a) => a.customer.customerId === LEDGERS.VAT.ledger_id));
  case DR_INDEX:
    return(list.findIndex(({ customer }) =>{ return (customer?.customerId !==id  && customer?.customerId !== LEDGERS.VAT.ledger_id) }));
  case DR_WITH_ID_INDEX:
    return(list.findIndex(({ customer,idJournalVoucherDetail }) =>{ return (customer?.customerId !==id  && customer?.customerId !== LEDGERS.VAT.ledger_id && idJournalVoucherDetail=== jvId) }));
  default:
    break;
  }
};
const config = {
  title,
  header: CNDetailConfig,
  getTableBody: CNDetailBody,
  getFooterContent,
  getSummaryViewList,
  userDetailMapper,
  getPrintSummaryDetails,
  signatureTitleList,
  printEnums,
  getData,
  lengthyAttributes,
  uniqueIdGetter: (i,index) =>  `${i.journalVoucherId}_${i.idJournalVoucherDetail}_${index}`,
  formMapper,
  formValidationFieldList,
  updateApiTransformedData,
  VAT_PARTY_ID,
  CR_INDEX,
  VAT_INDEX,
  DR_INDEX,
  DR_WITH_ID_INDEX,
  CR_LIST,
  DR_LIST,
  VAT_LIST,
  getFilteredList,
  getFilteredListIndex,
};

export default config;
