import styled from 'styled-components';

const SkuPackageStyled = styled.div`
  .form-multi-select {
    width: 48%;
  }
  /* .upc-wrap{ */
  .mdc-textfield {
    height: 41px !important;
    padding: 0 16px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.087);
    .mdc-textfield__input {
      border-bottom: unset;
    }
    label{
      display: none;
    }
  }
  /* } */
`;
export default SkuPackageStyled;
