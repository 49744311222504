import React from 'react'
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Button,
} from '../../../../components/BillingMDC';

const WarningDialog = ({ warningModalOpen, handleModalClose, onModalSubmit,saveDisable,warningMsg1,warningMsg2 }) => {
  return (
    <Dialog
      open={warningModalOpen}
      onClose={() => {
        handleModalClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>Warning</DialogTitle>
      </DialogHeader>
      {
        <div className='default-margin-24'>
          {`${warningMsg1}. ${warningMsg2}`}
        </div>
      }
      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            handleModalClose();
          }}
        >
          No
        </Button>
        <Button
          accent
          className={saveDisable ? 'dialog-ok modal-btn btn-disabled' : 'dialog-ok modal-btn'}
          disabled={saveDisable}
          onClick={() => {
            onModalSubmit();
          }}
        >
          Yes
        </Button>
      </DialogFooter>
    </Dialog>
  )
};

export default WarningDialog;
