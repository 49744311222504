const getConfigData = footerData => {
  const columns = [
    [
      { content: 'SN', rowSpan: 2 },
      { content: 'Purchase', colSpan: 7, styles: { halign: 'center' } },
      { content: 'Sales', colSpan: 7, styles: { halign: 'center' } },
    ],
    [
      { content: 'Miti', styles: { valign: 'middle' } },
      { content: 'Description', styles: { valign: 'middle' } },
      { content: 'Purchase', styles: { valign: 'middle', halign: 'right' } },
      { content: 'Tax Exempted', styles: { valign: 'middle', halign: 'right' } },
      { content: 'Taxable Amount', styles: { valign: 'middle', halign: 'right' } },
      { content: 'Tax Rate', styles: { valign: 'middle', halign: 'right' } },
      { content: 'Tax Amount', styles: { valign: 'middle', halign: 'right' } },
      { content: 'Miti', styles: { valign: 'middle' } },
      { content: 'Description', styles: { valign: 'middle' } },
      { content: 'Sales', styles: { valign: 'middle', halign: 'right' } },
      { content: 'Tax Exempted', styles: { valign: 'middle', halign: 'right' } },
      { content: 'Taxable Amount', styles: { valign: 'middle', halign: 'right' } },
      { content: 'Tax Rate', styles: { valign: 'middle', halign: 'right' } },
      { content: 'Tax Amount', styles: { valign: 'middle', halign: 'right' } },
    ],
  ];

  const columnStyles = {
    0: { cellWidth: 10 },
    1: { cellWidth: 15 },
    3: { cellWidth: 18 },
    4: { cellWidth: 15 },
    5: { cellWidth: 18 },
    7: { cellWidth: 15 },
    8: { cellWidth: 15 },
    11: { cellWidth: 15 },
    12: { cellWidth: 18 },
    14: { cellWidth: 15 },
  };

  const cellsToAlignRight = {
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
    14: '14',
    15: '15',
  };

  return {
    columns,
    columnStyles,
    cellsToAlignRight,
  };
};

export default getConfigData;
