import React, { Component } from 'react';
import { buApi, checkIfApiCallSuccess } from '../../common/base.api';
import Loading from '../../../common/Loading';
import CreateTemplate from '../../common/create/CreateTemplate';
import config from './config';
import SummaryView from '../../common/detail/SummaryView';
import ReconcileTableView from './TableView';
import { addNewFieldsInList, clone, getTotal } from '../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { Button, Cell, Grid } from '../../../components/BillingMDC';
import { PAYMENT_BASE } from '../../../data/enums/Route';
import history from '../../../utils/history';
import { TRANSACTION_NATURE } from '../../../data/enums/enums';
import BillingSnackBar from '../../../components/BillingMDC/BillingSnackbar';
import * as snackService from '../../common/snack.service';
import { voucherDecoder } from '../../../utils/miscellaneous';

const propTypes = {};
const defaultProps = {};

class Reconcile extends Component {
  fetchData = () => {
    const { match, getOutstandingInvoices } = this.props;
    const voucherNumber = voucherDecoder(match.params.id);
    this.setState({ voucherNumber });

    getOutstandingInvoices({ voucher_number: voucherNumber })
      .then(response => {
        const data = response;
        const invoiceList =
          clone(
            addNewFieldsInList(data.invoiceList, {
              reconcileAmount: 0,
              pendingAmount: 0,
              type: TRANSACTION_NATURE.PARTIAL,
            }),
          ) || [];
        const summaryReconcile = config.getSummaryReconcileData(data);

        this.setState({ data, invoiceList, summaryReconcile });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage('Error while fetching data');
        console.log(err);
        this.setState({ loading: false, snack });
      });
  };

  getBUList = () => {
    buApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  handleInputChange = (index, value) => {
    const { summaryReconcile, invoiceList } = this.state;
    const intermediateData = clone(invoiceList[index]);
    const intermediateList = clone(invoiceList);
    if (value <= intermediateData.amount) {
      intermediateData.reconcileAmount = value;
      intermediateData.pendingAmount = intermediateData.amount - intermediateData.reconcileAmount;
      intermediateData.type =
        intermediateData.pendingAmount === 0 ? TRANSACTION_NATURE.PAID : TRANSACTION_NATURE.PARTIAL;
      intermediateList[index] = intermediateData;
      const total = getTotal(intermediateList, 'reconcileAmount');

      if (total <= summaryReconcile.payment) {
        invoiceList[index] = intermediateData;
        summaryReconcile.reconcileTotal = total;
        summaryReconcile.remaining = summaryReconcile.outstanding - total;
      }
    }
    this.setState({ summaryReconcile, invoiceList });
  };

  handleSaveClick = () => {
    const { invoiceList, summaryReconcile, voucherNumber } = this.state;
    const { match, reconcilePayment } = this.props;
    const postObj = {
      payment_detail: {
        voucher_number: voucherNumber,
        total_payment: summaryReconcile.reconcileTotal,
      },
      sales_detail: config.getApiTransformedList(invoiceList),
    };

    reconcilePayment(postObj).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const snack = snackService.generateSuccessMessage();
        this.setState({ snack }, () => this.handleCancelClick());
      }
    });
  };

  handleCancelClick = () => {
    history.push(`/${PAYMENT_BASE}`);
  };

  getButtonWrapper = () => (
    <>
      <Button accent className="cancel-btn modal-btn" onClick={() => this.handleCancelClick()}>
        Cancel
      </Button>
      <Button accent className="margin-right-0 save-btn modal-btn" onClick={() => this.handleSaveClick()}>
        Save
      </Button>
    </>
  );

  getRenderBody = () => {
    const { data, editStatus, invoiceList, summaryReconcile } = this.state;
    return (
      <>
        <SummaryView data={summaryReconcile} config={config} />
        <ReconcileTableView
          editStatus={editStatus}
          invoiceList={invoiceList}
          config={config}
          handleInputChange={this.handleInputChange}
        />

        <div className="total-section-wrapper">
          <Grid>
            <Cell col={5}> Total Reconcile Amount</Cell>
            <Cell col={4} className="right-align font-bold">
              {fixedFloatAndCommas(summaryReconcile.reconcileTotal)}
            </Cell>
            <Cell col={3} />
          </Grid>
        </div>
      </>
    );
  };

  constructor(props) {
    super(props);
    this.state = {
      buList: [],
      data: {},
      editStatus: true,
      summaryReconcile: config.getSummaryReconcileData,
      invoiceList: [],
      snack: { ...snackService.snackParameters },
      voucherNumber: '',
    };
  }

  componentDidMount() {
    this.getBUList();
    this.fetchData();
  }

  render() {
    const { serverResponseWaiting } = this.props;
    const { buList, data, snack } = this.state;
    return (
      <div className="reconcile">
        <Loading display={serverResponseWaiting} />
        <CreateTemplate
          render={{ body: this.getRenderBody, btnWrapper: this.getButtonWrapper }}
          buConfig={{ list: buList, status: false, defaultValue: data.businessId }}
          loading={serverResponseWaiting}
          headerConfig={{ title: config.title }}
          display={{
            header: true,
            btnWrapper: true,
          }}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

Reconcile.propTypes = propTypes;
Reconcile.defaultProps = defaultProps;

export default Reconcile;
