import { compose } from 'recompose';

import DebitNoteDetail from './DebitNoteDetail';

import apiInterceptor from '../../../api/interceptor';

import { debitNoteAPi,jvApi ,ledgerApi} from '../../common/base.api';

const DebitNoteVoucherDetail = compose(apiInterceptor({ ...debitNoteAPi, updateApi: jvApi.update,ledgerListApi:ledgerApi.getList }))(DebitNoteDetail);

export default DebitNoteVoucherDetail;
