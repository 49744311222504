import { compose } from 'recompose';

import apiInterceptor from '../../../api/interceptor';

import SalesInvoice from './SalesInvoice';
import { salesInvoiceApi } from '../../common/base.api';

const composedSalesInvoice = compose(apiInterceptor({ ...salesInvoiceApi }))(SalesInvoice);

export default composedSalesInvoice;
