import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { roundAndCommas } from '../../../utils/conversion';

const propTypes = { data: PropTypes.instanceOf(Array) };

const defaultProps = { data: {} };

const TagWiseChildView = ({ data }) => (
  <>
    {data.ledgers && data.ledgers.length > 0 && data.ledgers.map((ledger, i) => <Row key={i} rowData={ledger} />)}
  </>
);

const Row = ({ rowData }) => (
  <tr>
    <td className="ledger">{rowData.ledger}</td>
    <td className="text-right opening">{rowData.opening ? roundAndCommas(rowData.opening) : rowData.opening}</td>
    <td className="openingStatus">{rowData.openingType}</td>
    <td className="text-right debit">{rowData.debit ? roundAndCommas(rowData.debit) : rowData.debit }</td>
    <td className="text-right credit">{rowData.credit ? roundAndCommas(rowData.credit) : rowData.credit}</td>
    <td className="text-right closing">{rowData.closing ? roundAndCommas(rowData.closing) : rowData.closing}</td>
    <td className="closingStatus">{rowData.closingType}</td>
  </tr>
);

TagWiseChildView.propTypes = propTypes;

TagWiseChildView.defaultProps = defaultProps;

export default TagWiseChildView;
