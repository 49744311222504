import { compose } from 'recompose';

import BusinessGroups from './BusinessGroups';

import apiInterceptor from '../../../api/interceptor';

import { businessGroupApi } from '../../common/base.api';

const composedBusinessGroups = compose(apiInterceptor({ ...businessGroupApi }))(BusinessGroups);

export default composedBusinessGroups;
