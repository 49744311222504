import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import BillingSelectStyled from './BillingSelectStyled';
import Icon from 'react-mdc-web/lib/Icon';

const propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  param: PropTypes.string,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  emptyField: PropTypes.bool,
  className: PropTypes.string,
  multipleParam: PropTypes.bool,
  multipleSelect: PropTypes.bool,
  errorMessage: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  handleChange: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
};

const defaultProps = {
  value: '',
  param: '',
  options: [],
  className: '',
  required: false,
  disabled: false,
  clearable: true,
  emptyField: false,
  name: 'select-comp',
  multipleParam: false,
  multipleSelect: false,
  errorMessage: 'Should not be empty',
  getOptionLabel: () => null,
  getOptionValue: () => null,
};

class BillingSelect extends Component {
  componentDidMount() {}

  onChange = e => {
    const { param, valueKey, multipleParam, handleChange } = this.props;
    const value = e === null ? null : e[valueKey];
    handleChange(param, value, multipleParam ? e : '');
  };

  checkAccodingToValueKey = value => {
    const { options, extraValueKey, valueKey, valueKey1, keyValue } = this.props;
    if (extraValueKey) {
      return options.filter(list => list[valueKey] === value && list[valueKey1] === keyValue);
    } else {
      return options.filter(list => list[valueKey] === value);
    }
  };

  render() {
    const {
      value,
      valueKey,
      emptyField,
      errorMessage,
      labelKey,
      options,
      name,
      disabled,
      clearable,
      className,
      required,
      multipleSelect,
      placeholder,
      getOptionValue,
      getOptionLabel,
      onChange,
      isSearchable,
      valueKey1,
      onInputChange,
      filterOption,
    } = this.props;

    const DropdownIndicator = props => (
      <components.DropdownIndicator {...props}>
        <Icon className="no-margin" name="arrow_drop_down" />
      </components.DropdownIndicator>
    );
    return (
      <BillingSelectStyled>
        <Select
          isMulti={multipleSelect}
          name={name}
          isClearable={clearable}
          isSearchable={isSearchable}
          value={valueKey ? this.checkAccodingToValueKey(this.props.value) : value}
          options={options}
          className={`${required && emptyField && !value ? 'select-css invalid' : 'select-css'} ${className}`}
          classNamePrefix="zindex-2"
          onChange={onChange ? e => onChange(e) : this.onChange}
          isDisabled={disabled}
          placeholder={placeholder}
          getOptionLabel={element => (labelKey ? element[labelKey] : getOptionLabel(element))}
          getOptionValue={element => (valueKey ? element[valueKey] : getOptionValue(element))}
          components={{ DropdownIndicator }}
          onInputChange={onInputChange}
          filterOption={filterOption}
        />
        {required && emptyField && (multipleSelect ? value && value.length === 0 : !value) ? (
          <span className="error-message">{errorMessage}</span>
        ) : (
          ''
        )}
      </BillingSelectStyled>
    );
  }
}
BillingSelect.defaultProps = defaultProps;

BillingSelect.propTypes = propTypes;

export default BillingSelect;
