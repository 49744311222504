const getConfigData = footerData => {
  //   const ledgerData = getFilteredData(ledgerList, companyData, ledgerDetails);

  //   return ledgerData;
  const columns = [
    [
      { content: 'SN', rowSpan: 2 },
      { content: 'Miti', rowSpan: 2 },
      { content: 'Bill Number', rowSpan: 2, styles: { halign: 'center' } },
      { content: 'Buyers', colSpan: 2, styles: { halign: 'center' } },
      // { content: 'Buyers' },
      { content: 'Item', colSpan: 3, styles: { halign: 'center' } },
      { content: 'Sales', colSpan: 4, styles: { halign: 'center' } },
      { content: 'Exported', colSpan: 4, styles: { halign: 'center' } },
      // { content: 'Excise',rowSpan: 2 },
    ],
    [
      { content: 'Name', styles: { valign: 'middle' } },
      { content: 'PAN No.', styles: { valign: 'middle' } },
      { content: 'Name', styles: { valign: 'middle' } },
      { content: 'Qty.', styles: { halign: 'right' } },
      { content: 'Unit' },
      { content: 'Total', styles: { halign: 'right' } },
      { content: 'Tax Exempted', styles: { halign: 'right' } },
      { content: 'Taxable', styles: { halign: 'right' } },
      { content: 'VAT', styles: { halign: 'right' } },
      { content: 'Amount', styles: { valign: 'middle' }, styles: { halign: 'right' } },
      { content: 'Country', styles: { valign: 'middle' } },
      { content: 'Bill No.', styles: { valign: 'middle' } },
      { content: 'Date', styles: { valign: 'middle' } },
    ],
  ];

  JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && columns[0].push({ content: 'Excise', rowSpan: 2 });

  const footer = [
    [
      '',
      'Total',
      '',
      '',
      '',
      '',
      { content: footerData?.totalItemQuantity ?? '', styles: { lineWidth: 0.1 } },
      '',
      { content: footerData?.totalSales ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalTaxExempted ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalTaxableAmount ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalVat ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalExportSales ?? '', styles: { lineWidth: 0.1 } },
      '',
      '',
      '',
      { content: footerData?.totalExcise ?? '123', styles: { lineWidth: 0.1 } },
    ],
  ];

  const columnStyles = {
    0: { cellWidth: 7 },
    1: { cellWidth: 15 },
    2: { cellWidth: 31 },
    4: { cellWidth: 15 },
    5: { cellWidth: 48 },
    8: { cellWidth: 18 },
    9: { cellWidth: 18 },
  };

  const cellsToAlignRight = { 6: '6', 8: '8', 9: '9', 10: '10', 11: '11', 15: '15' };

  return {
    columns,
    footer,
    columnStyles,
    cellsToAlignRight,
  };
};

export default getConfigData;
