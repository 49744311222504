import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  trigger: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  accordionStatus: PropTypes.string.isRequired,
  colSpan: PropTypes.number,
};
const defaultProps = {
  accordionStatus: true,
  colSpan: '75%',
};

class AccordionSection extends Component {
  static propTypes = propTypes;

  onClick = () => {
    const { onClick, id } = this.props;
    onClick(id);
  };

  render() {
    const {
      onClick,
      props: { isOpen, trigger, children, accordionStatus, colSpan },
    } = this;

    return (
      <Fragment>
        <tr
          onClick={onClick}
          style={{ cursor: 'pointer' }}
          className={`${isOpen ? 'active' : 'inActive'} ${accordionStatus ? 'accordionExist' : ' '} `}
        >
          {trigger}
        </tr>
        {isOpen && (
          <tr className="collapsible-body-tr">
            <td colSpan={colSpan} className="collapsible-body-td">
              <table>
                <tbody>{children}</tbody>
              </table>
            </td>
          </tr>
        )}
      </Fragment>
    );
  }
}

AccordionSection.propTypes = propTypes;

AccordionSection.defaultProps = defaultProps;
AccordionSection.displayName = 'AccordionSection';

export default AccordionSection;
