import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Cell } from '../BillingMDC';
import { fixedFloatAndCommas } from '../../utils/conversion';


const BillTotalContent = ({
  totalList,totalValue,invoiceDetail,print,printInfo,totalInWords,
  signatureTitle,lineMargin,remarksStatus,remarks,agentInfo ,
  paymentMode,totalQuantity,batchEnabled,printFooterNote,company,
}) => {
  const paymentVia = paymentMode ==='CUSTOMER' ? 'Credit' :'Cash'

  return(
    <tfoot>
      <tr className='note'>
        <td colSpan='2'>
          <p className='note_p'>{printFooterNote.length ?`"${printFooterNote}"`:''}</p>
        </td>
      </tr>
      {totalQuantity>0 && (
        <tr>
          <td colSpan='2' className='total_quantity_td'>
            <p className={`float-left total_quantity batch-enable`}>
              <strong className='invoice_title'>Total Quantity (Pcs)</strong>
              <span className='invoice_value'>{totalQuantity}</span>
            </p>
          </td>
        </tr>
      )}
      {totalList.map((item,key) => {
        return (

          item.title && <tr key = {key} className='total-info'>
            <td className='total-content-data text-right item_title'>
              {item.title}
            </td>
            <td className='total-content-data text-right item_value pad-r-2 font-bold'>{fixedFloatAndCommas(item.value || 0)}</td>
          </tr> );
      })
      }
      <tr className='tfoot-td total '>
        <td>
          <p className='float-left'>
            <span className='invoice_title'>{invoiceDetail.title} </span>
            <span className='invoice_value'>{invoiceDetail.value}</span></p>
          <p className='float-right total_title right-align font-bold'>Total</p>
        </td>
        <td className='total_value right-align pad-r-2 font-bold'>
          {fixedFloatAndCommas(totalValue)}</td>
      </tr>
      <tr className='in-word'>
        <td className='tfoot-td total-word right-align ' colSpan='2'>
          <span className='left'>In Words:</span>
          <span className='right'>{totalInWords}</span>
        </td>
      </tr>
      {print &&
      <tr>
        <td className='tfoot-td' colSpan='2'>
          <div className='td-wrap print-info'>
            {
              printInfo?.map((item,key) => {
                return (
                  <span className='span-wrapper' key={key}>
                    aa
                    <span className='padding-r-8'>{item.title}:</span>
                    <span className='pad-r-2'>{item.value}</span>
                  </span>
                );
              })
            }
          </div>
        </td>
      </tr>
      }

      <tr><td colSpan='2'>
        <div className="agent_sub-wrap">
        {
        agentInfo &&
        <div className='agent_info'>
          <span className='agent_title'>{agentInfo.title}:</span>
          <span className='agent_value font-bold'>{agentInfo.value}</span>
        </div>
          }
        <div className="sub-email">
        <span className='agent_title'> Sub-D Email:</span>
          <span className='agent_value font-bold'>{company?.email || 'N/A'}</span>
        </div>
        </div>
      </td></tr>

      {
        remarksStatus && <tr>
          <td colSpan='2'>
            <div className='padding-top-8 remark'>
            Remarks: {remarks}
            </div>
          </td></tr>
      }
      <tr><td colSpan='2'>
        <div className='float-right right right-align signature-line'>
        </div>
      </td></tr>
      <tr>
        <td colSpan='2'>
          <div className={`authorized float-right right ${lineMargin}`}>
            {signatureTitle}
          </div>
        </td></tr>
    </tfoot>
  );
};

BillTotalContent.defaultProps = {
  signatureTitle: 'Authorized Signature',
  lineMargin: 'padding-r-8',
  invoiceDetail: { title : '', value:'' },
  agentInfo: null,
  printFooterNote:'',
};

BillTotalContent.propTypes = {
  totalList: PropTypes.array,
  printFooterNote: PropTypes.string,
  agentInfo: PropTypes.instanceOf(Object),
};

export default BillTotalContent;
