import { getCurrentDay, getEndOfCurrentMonth } from '../../../../utils/filterUtil';
import {
  ACTION, VAT_PERCENTAGE, VAT_ZERO_PERCENTAGE
} from '../../../../data/enums/enums';

const skuDetailsMapper = (data = {}) => ({
  title: data.title || '',
  description: data.description || '',
  rlp: data.rlp || 0,
  status: data.status || 0,
  psku: Number(data.psku) || 0,
  su: data.su || '',
  upc: data.upc || null,
  skucode: data.skucode || '',
  catalogSkusList: data.catalogSkusList || [],
  batchList: data.batchList || [],
  businessId: data.businessId || '',
  businessSkuId: data.businessSkuId || null,
  excisePercent: data.excisePercent || 0,
  vatPercent: data.vatPercent !== undefined ? data.vatPercent : VAT_PERCENTAGE,
  uom: data.uom || '',
  uomList: data.uom_list?.filter((a)=>a.status) || [],
  allUOMList: data.all_uom_list || [],
});

const formValidationFields = [{ title: 'title' }, { title: 'description' }, { title: 'rlp' }];

const getTransformedCatalogDetails = (catalogDetails) =>
  catalogDetails.map((catalog) => ({
    businessCatalogId: catalog.businessCatalogId,
    catalogDetailTitle: catalog.catalogDetailTitle || 'Default',
    catalogDetailId: catalog.businessCatalogDetailId,
  }));
const VATList = [
  { value: VAT_ZERO_PERCENTAGE, label: '0 %' },
  { value: VAT_PERCENTAGE, label: '13 %' },
];

const UOMList = [
  { value: 'pcs', label: 'Pcs' },
  { value: 'bora', label: 'Bora' },
  { value: 'ctn', label: 'Cartoon' },
  { value: 'M', label: 'M' },
  { value: 'btl', label: 'Bottles' },
];

const getTransformedBatchList = (batchList, businessId) =>
  batchList.map((batch) => ({
    businessBatchId: batch.businessBatchId || null,
    businessId: businessId || null,
    businessSkuId: batch.businessSkuId || null,
    // updatedAt: batch.updatedAt || getCurrentDay(),
    title: batch.title || '',
    dlp: batch.dlp || 0,
    vat: batch.vat || 13,
    mfgDate: batch.mfgDate || getCurrentDay(),
    expiryDate: batch.expiryDate || getEndOfCurrentMonth(),
  }));

const getApiTransformedData = (data, eventType) => {
  let formattedData = { ...data };
  if (eventType === ACTION.ADD) {
    const { businessSkuId, ...modifiedData } = data;
    formattedData = { ...modifiedData };
  }

  return {
    ...formattedData,
    catalogSkusList: getTransformedCatalogDetails(data.catalogSkusList),
    batchList: getTransformedBatchList(data.batchList, data.businessId),
  };
};

const responseUOMMapper = (list) =>
  list.map((a) => ({
    short_name: a.value||a.short_name,
    title: a.label||a.title,
    unit_per_case: a.pcs||a.unit_per_case,
    id: a.id || null,
    status: a.status===undefined?true:a.status,
    is_basic: (a.value==='pcs'||a.short_name==='pcs')||a.is_basic
  }));

const message = {
  ADD: 'SKU created successfully!',
  UPDATE: 'SKU updated successfully!',
  SERVER_ERROR: '!Server Error',
  CREATE_ERROR: '!Error while creating SKU',
  UPDATE_ERROR: '!Error while updating SKU',
};

export {
  skuDetailsMapper, formValidationFields, getApiTransformedData, message, VATList, UOMList, responseUOMMapper
};
