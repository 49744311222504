import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import { ACTION } from '../../../../../data/enums/enums';
import {
  Fab, Icon, MiniAddFab
} from '../../../../../components/BillingMDC';
import BillingSelect from '../../../../../components/Select/BillingSelect';
import { CATALOG_LEVEL_MAPPER } from '../../../../../data/enums/catalog';

const propTypes = {
  catalogBars: PropTypes.instanceOf(Array),
  catalogs: PropTypes.instanceOf(Array),
  displayAdd: PropTypes.bool,
  handleCatalogDetailSelect: PropTypes.func.isRequired,
  handleCatalogSelect: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  validation: PropTypes.shape({
    fieldList: PropTypes.instanceOf(Array),
    flag: PropTypes.bool,
  }),
  crudMode: PropTypes.string,
  businessId: PropTypes.number,
  parentGroupList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  businessId: 0,
  crudMode: ACTION.ADD,
  catalogBars: [],
  catalogs: [],
  displayAdd: false,
  validation: { flag: false, fieldList: [] },
  parentGroupList: [],
};

const CatalogDetailsView = ({
  catalogBars,
  onAddClick,
  catalogs,
  displayAdd,
  businessId,
  handleCatalogSelect,
  handleCatalogDetailSelect,
  crudMode,
  handleCancelClick,
  handleModalClose,
  dialogType,
  addSkuCatalogs,
  validation,
  onIconClick,
  handleInputChange,
  parentGroupList,
  selectedId,
  selectedCatalog,
  onModalSubmit,
}) => {
  const getCatalogList = (level) => catalogs.filter((catalog) => catalog.LEVEL === level);
  return (
    <Fragment>
      {catalogBars.map((catalogRow, index) => (
        <div className='form-multi-select' key={catalogRow.businessCatalogId}>
          <BillingSelect
            name='form-field-name'
            param='businessCatalogId'
            clearable={false}
            value={catalogRow.businessCatalogId}
            valueKey='LEVEL'
            labelKey='TITLE'
            options={getCatalogList(catalogRow.businessCatalogId)}
            handleChange={handleCatalogSelect}
            required
            className={`${getCatalogList(catalogRow.businessCatalogId).length ===1 && 'disabled_class'}`}
            disabled ={getCatalogList(catalogRow.businessCatalogId).length ===1}
          />
          <BillingSelect
            name='form-field-name'
            param='catalogDetailTitle'
            clearable={false}
            value={index === 0 ? businessId : catalogRow.businessCatalogDetailId}
            valueKey='business_catalog_detail_id'
            labelKey='title'
            options={catalogRow.catalogDetailList}
            emptyField={catalogRow.flag}
            multipleParam
            handleChange={(param, value, event) => handleCatalogDetailSelect(param, value, event, catalogRow)}
            required
          />
          {catalogRow.businessCatalogId !== CATALOG_LEVEL_MAPPER.PRINCIPAL && (
            <div className='mini-fab-button'>
              <Fab mini onClick={() => onIconClick(ACTION.ADD, catalogRow.businessCatalogId)}>
                <Icon name='add' />
              </Fab>
            </div>
          )}
          {catalogRow.businessCatalogDetailId && index !== 0 && crudMode !== ACTION.READ ? (
            <Icon name='close' onClick={() => handleCancelClick(catalogRow)} />
          ) : null}
          <Dialog
            actionType={dialogType}
            selectedId={selectedId}
            validation={validation}
            catalogRow={catalogRow}
            onModalSubmit={onModalSubmit}
            onModalClose={handleModalClose}
            addSkuCatalogs={addSkuCatalogs}
            selectedCatalog={selectedCatalog}
            parentGroupList={parentGroupList}
            onInputChange={handleInputChange}
          />
        </div>
      ))}
      {displayAdd && <MiniAddFab handleFabClick={onAddClick} />}
    </Fragment>
  );
};

CatalogDetailsView.propTypes = propTypes;

CatalogDetailsView.defaultProps = defaultProps;

export default CatalogDetailsView;
