import React, { Component } from 'react';
import { connect } from 'react-redux';
import InvoiceDetails from './detail';
import history from '../../../utils/history';
import * as queryService from '../../common/query.service';
import * as printService from '../../common/print.service';
import { ABBREVIATED_INVOICE_CONFIG } from '../../common/domain.config';
import { ABBREVIATED_INVOICE_CREATE, ABBREVIATED_INVOICE_DETAILS } from '../../../data/enums/Route';
import PageView from '../../common/pagination/PageView';
import tableConfig from './config';
import * as snackService from '../../common/snack.service';
import { BillingSnackBar } from '../../../components/BillingMDC';
import withBaseState from '../../common/withBaseState';
import { voucherEncoder } from '../../../utils/miscellaneous';
import * as downloadService from '../../common/download.service';
import { changeBUFilterQueryTitle } from '../../common/common';
import {storeInLocalStorage ,fetchFromLocalStorage} from "../../common/common";

class AbbreviatedInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        filter: false,
        menu: false,
        searchBox: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          business_id_thirdParty: [],
        },
      },
      data: {
        list: [],
        total: 1,
      },
      print: {
        id: '',
        start: false,
        info: [
          { title: 'Printed On', value: '' },
          { title: 'Printed Time', value: '' },
          {
            title: 'Print Count',
            value: '',
          },
          { title: 'Printed By', value: '' },
        ],
        details: {
          transaction: '',
          invoiceNumber: '',
          printedBy: '',
        },
      },
    };
    const { downloadList } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }
  componentDidMount() {
    this.getFilterStateFromStorage();
  }
  setFilterStateInStorage = () => {
    const { queryParameters,display} = this.state;
    storeInLocalStorage(
      'DISPLAY',
      display,
      'ABBREVIATED'
    );
    storeInLocalStorage(
      'FILTER',
      {
       queryParameters,
      },
      "ABBREVIATED"
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters,display} =this.state;
    const localData = fetchFromLocalStorage('FILTER', "ABBREVIATED");
    const localDisplay = fetchFromLocalStorage('DISPLAY', 'ABBREVIATED');
    this.setState(
      { queryParameters:localData?.queryParameters || queryParameters ,
        display:{...localDisplay,filter:false} || display},
      () => {
        this.loadTableData();
      },
    );
  };
  handleDownloadClick = (type, label) => {
    const { queryParameters } = this.state;
    const { downloadList, detailDownloadList } = this.props;

    // renaming key ,business_id_thirdParty to business_id
    queryParameters.filter['business_id'] = queryParameters.filter['business_id_thirdParty'];
    delete queryParameters.filter['business_id_thirdParty'];

    if (label === 'psr') {
      detailDownloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    } else {
      downloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    }
  };
  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  loadTableData = () => {
    const { getList } = this.props;
    let { queryParameters } = this.state;
    this.setFilterStateInStorage();
    const extraQuery = '&isAbbreviatedInvoice=true';
    getList(changeBUFilterQueryTitle(queryParameters, extraQuery)).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.totalCount;
      this.setState({ data });
    });
  };
  handleFabButtonClick = () => {
    history.push(`/${ABBREVIATED_INVOICE_CREATE}`);
  };
  handleTableBodyClick = id => {
    history.push(`/${ABBREVIATED_INVOICE_DETAILS}/${id}`, {});
  };

  onSalesInvoiceItemClick = data => {
    const { print } = this.state;
    const { user } = this.props;
    print.firstCopy = false;
    print.id = data.invoiceNumber;
    const modifiedId = voucherEncoder(print.id);
    const printServiceResponse = printService.dataProcessor(data, user.idUsers);
    print.details = printServiceResponse.printDetails;
    print.info = printServiceResponse.printInfo;
    history.push(`/${ABBREVIATED_INVOICE_DETAILS}/${modifiedId}`, { ...print });
    // this.setState({ print });
  };

  handlePostPrint = () => {
    const { print } = this.state;
    print.start = false;
    this.setState(print);
  };
  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  handleClearSearch =() =>{
    const {display}=this.state;
    this.setState({display:{...display,searchBox:false,search:false}});
    this.basePaginationService.clearSearchText();
  }
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        business_id_thirdParty: [],
      },
    });
  }

  render() {
    const { queryParameters, data, display, snack, print } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <>
        {/* {!print.start && ( */}
        <>
          <PageView
            domainConfig={ABBREVIATED_INVOICE_CONFIG}
            headerConfig={{
              search: true,
              date: true,
              upload: false,
              download: true,
              filter: true,
              create: true,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.handleClearSearch}
            resetFilter={this.handleResetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.handleDownloadClick}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            data={data}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.onSalesInvoiceItemClick}
            onPageSelect={this.basePaginationService.onPageSelect}
            createHandler={this.handleFabButtonClick}
          />
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </>
      </>
    );
  }
}
const AbbreviatedInvoiceWithState = withBaseState(AbbreviatedInvoice);

export default AbbreviatedInvoiceWithState;
