import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  apiTransformedCNSummary,
  createCreditNoteConfig,
  CreateCreditNoteTableBody,
  formValidateFieldArray,
  getApiTransformedData,
  initializeCreateCN,
  message,
  pageValidateFieldArray,
  enumVar,
  jvTypeList,
} from './config';
import DialogView from './Dialog';
import { Button, Cell, Fab, Grid, Icon, LinearProgress, Snackbar,Checkbox } from '../../../components/BillingMDC';
import history from '../../../utils/history';
import * as filterUtil from '../../../utils/filterUtil';
import CreditNoteStyled from '../CreditNoteStyled';
import { clone } from '../../../utils/arrayProcessor';
import { validateForm } from '../../common/validation';
import { LEDGERS } from '../../../data/enums/enums';
import TableHeader from '../../../components/TableHeader';
import { voucherEncoder } from '../../../utils/miscellaneous';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import DateRangePicker from '../../../components/DatePicker/DatePicker';
import { checkIfApiCallSuccess, creditNoteAPi, ledgerApi, tagApi } from '../../common/base.api';
import { CREDIT_NOTE, CREDIT_NOTE_DETAILS } from '../../../data/enums/Route';
import { VAT_PERCENTAGE ,VAT_ZERO_PERCENTAGE,VAT_PER_DERIVE} from '../../../data/enums/enums';
import { JWT } from '../../../environment';
import { formatToNumericDate } from '../../../utils/filterUtil';
import DatePicker from '../../../components/DatePicker/DatePicker';
import GRNStyled from '../../fundamentals/grn/GRNStyled';
import { getBackDatedStatus,getCustomerList } from '../../../views/fundamentals/common/helpers';
import CustomerSelect from '../../../common/CustomerSelect';

class CreateCreditNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexInCN: 0,
      loading: false,
      success: false,
      showMessage: false,
      saveDisable: false,
      createModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      voucherNumber: '',
      tagList: [],
      cnList: [],
      ledgerList: [],
      ledgerListPartial:[],
      partyLedgerList:[],
      partyLedgerListPartial:[],
      message: message.success,
      createCN: initializeCreateCN(),
      creditNoteSummary: {
        ledger_id: '',
        ledger_name: '',
        panNo: '',
        ref_invoice_number: '',
        narration: '',
        ref_date: filterUtil.getCurrentDay(),
        amount: '',
        vat: 13,
        vat_amount: '',
        net_amount: '',
        type:'',
        document_date: filterUtil.getCurrentDay(),
      },
      pageValidation: {
        flag: false,
        fieldList: pageValidateFieldArray,
      },
      formValidation: {
        flag: false,
        fieldList: formValidateFieldArray,
      },
      addTag: false,
      backDatedStatus:getBackDatedStatus(),
      vatStatus:true,
    };
  }

  componentDidMount = () => {
    this.getLedgerList();
    this.getTagList();
    this.setAddTagStatus();
  };

  setAddTagStatus() {
    const settings = localStorage.getItem(JWT.LOCAL_STORAGE.SETTINGS.NAME);
    if (settings) {
      let parsedSettings = JSON.parse(settings);
      if (parsedSettings) {
        this.setState({ addTag: !!parsedSettings.addTag });
      }
    }
  }

  directToMainPage = () => {
    const { showMessage, success, voucherNumber } = this.state;
    if (!showMessage && success) {
      const modifiedVoucherNumber = voucherEncoder(voucherNumber) || '';
      history.push(`/${CREDIT_NOTE_DETAILS}/${modifiedVoucherNumber}`);
    }
  };

  handleCancelClick = () => {
    history.push(`/${CREDIT_NOTE}`);
  };

  addPartyAndVatToCNList(cnList, user, creditNoteSummary) {
    const jvListItems = [
      ...cnList,
      {
        ledger_id: LEDGERS.VAT.ledger_id,
        dr_amount: creditNoteSummary.vat_amount,
        cr_amount: 0,
        narration: '',
        entered_by: user.idUsers,
        ref_date: formatToNumericDate(creditNoteSummary.ref_date),
        type_id: LEDGERS.VAT.type,
        party_taxable_amount: creditNoteSummary.amount,
        party_id: creditNoteSummary.ledger_id,
        party_name: creditNoteSummary.ledger_name,
        business_id: null,
      },
    ];
    jvListItems.push({
      ledger_name: creditNoteSummary.ledger_name,
      ledger_id: creditNoteSummary.ledger_id,
      cr_amount: creditNoteSummary.net_amount,
      dr_amount: 0,
      narration: creditNoteSummary.narration,
      entered_by: user.idUsers,
      ref_date: formatToNumericDate(creditNoteSummary.ref_date),
      ref_number: creditNoteSummary.ref_invoice_number,
      type_id: creditNoteSummary.type_id,
      party_taxable_amount: 0,
      party_id: '',
      party_name: '',
      business_id: null,
    });
    return jvListItems;
  }

  handleSaveClick = () => {
    // generate the object to send to the server
    const { cnList, creditNoteSummary, pageValidation } = this.state;
    const { user } = this.props;

    let valid = validateForm(creditNoteSummary, pageValidation, validStatus =>
      this.setState({ pageValidation: validStatus }),
    );
    const cnListCheck = cnList.length > 0;

    if (valid && cnListCheck) {
      this.setState({ loading: true });
      const summaryList = apiTransformedCNSummary(creditNoteSummary, user);
      const updatedCNList = this.addPartyAndVatToCNList(cnList, user, creditNoteSummary);
      const filteredJvList = getApiTransformedData(clone(updatedCNList), user);
      const object = {
        jv: {
          summary: summaryList,
          detail: filteredJvList,
        },
      };

      this.setState({ saveDisable: true });
      creditNoteAPi
        .create(object)
        .then(response => {
          this.setState({ loading: false });
          if (response.success) {
            const { voucher_number = '' } = response.data.journalVoucherData;
            this.setState({
              showMessage: true,
              success: true,
              message: message.success,
              voucherNumber: voucher_number,
              partyHeaderIncluded: false,
              saveDisable: true,
            });
          }
        })
        .catch(err => {
          this.setState({
            loading: false,
            showMessage: true,
            success: false,
            message: err?.error?.message || message.error,
            saveDisable: false,
          });
        });
    } else {
      this.setState({
        showMessage: true,
        message: 'Credit Note List is empty',
      });
    }
  };

  // close the modal resetting  all the value
  handleModalClose = () => {
    this.setState({
      createModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
    });
    this.resetCNDialog();
  };

  // reset CN Dialog
  resetCNDialog = () => {
    this.setState({
      createCN: initializeCreateCN(),
      formEmptyField: false,
    });
  };

  getLedgerList = async () => {
    const ledgerListAll =await getCustomerList('all');
    this.getOtherListList(ledgerListAll)
  }
  getOtherListList = (ledgerListAll) =>{
    const ledgerList =ledgerListAll.filter(a=>!([LEDGERS.VAT.type].includes(a.typeId)))
    const ledgerListPartial = ledgerList.slice(0,300);
    this.setState({partyLedgerListPartial:ledgerListPartial,ledgerListPartial,partyLedgerList:ledgerList,ledgerList}) 
  }

  getTagList = () => {
    tagApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ tagList: response.data.list });
      }
    });
  };

  // create the sales Return, update the discount value
  createCreditNote = () => {
    const { cnList, createCN } = this.state;
    cnList.push(createCN);

    this.setState({ cnList: cnList }, () => {
      this.handleCreditListUpdate();
      this.handleModalClose();
    });
  };

  // edut the particular credit note from cnList.
  editCreditNote = (data, index) => {
    const { cnList, createCN } = this.state;
    cnList[index] = createCN;
    this.setState({ cnList: cnList }, () => {
      this.handleCreditListUpdate();
      this.handleModalClose();
    });
  };

  // delete the particular credit note from cnList.
  deleteCNItem = index => {
    const { cnList, indexInCN } = this.state;
    if (cnList.length >= indexInCN) {
      cnList.splice(indexInCN, 1);
      this.setState({ cnList: cnList }, () => {
        this.handleCreditListUpdate();
        this.handleModalClose();
      });
    }
  };

  handleCreditListUpdate = () => {
    const { cnList, creditNoteSummary } = this.state;
    let dr_amount = 0;
    cnList.map(element => {
      dr_amount += Number(element.dr_amount);
    });

    creditNoteSummary.amount = Number(Number(dr_amount).toFixed(2));
    creditNoteSummary.vat_amount = creditNoteSummary.amount * (creditNoteSummary.vat / 100);
    creditNoteSummary.net_amount = creditNoteSummary.amount + creditNoteSummary.vat_amount;
    this.setState({ creditNoteSummary });
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (createCN, index) => {
    this.setState({
      createCN: clone(createCN),
      editModalOpen: true,
      indexInCN: index,
    });
  };

  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInCN: index });
  };

  // handle on modal ok click
  handleModalOk = () => {
    // check edit or create config //close the dialog
    const {
      createModalOpen,
      editModalOpen,
      createCN,
      formValidation,
      indexInCN,
      addTag
    } = this.state;

    if (createModalOpen || editModalOpen) {
      const valid = validateForm(createCN, this.getFormValidationConfig(), (valid) =>
        this.setState({ formValidation: valid })
      );
      if (valid) {
        if (createModalOpen) {
          this.createCreditNote();
        } else {
          this.editCreditNote(createCN, indexInCN);
        }
      }
    } else {
      this.deleteCNItem();
    }
  };
  getFormValidationConfig = () => {
    const { formValidation, addTag } = this.state;
    let validationConfig = {
      flag: formValidation.flag,
      fieldList:formValidation.fieldList
    }
    validationConfig = addTag ? {
      flag: formValidation.flag,
      fieldList: [...validationConfig.fieldList, { title: 'tagid' }]
    } : formValidation;
    return validationConfig;
  };

  handleDateChange = (field, value) => {
    const { creditNoteSummary } = this.state;
    const formattedDate = filterUtil.formatToNumericDate(value);
    creditNoteSummary[field] = formattedDate;
    this.setState({ creditNoteSummary });
  };

  handleInputChange = (field, value, all) => {
    const { creditNoteSummary } = this.state;
    creditNoteSummary[field] = value;
    if (field === 'vat') {
      creditNoteSummary.vat_amount = creditNoteSummary.amount * (value / 100);
      creditNoteSummary.net_amount =
        creditNoteSummary.amount + creditNoteSummary.vat_amount;
    }
    if (field === 'ledger_id') {
      creditNoteSummary.ledger_name = all['title'] || 'title';
      creditNoteSummary.type_id = all.accountGroup;
      creditNoteSummary.party_id = '';
      creditNoteSummary.party_name = '';
      creditNoteSummary.panNo = all.panNo;
      creditNoteSummary.party_taxable_amount = 0;
      this.getType();
    }
    this.setState({ creditNoteSummary });
  };
  getType =() =>{
    const { creditNoteSummary,partyLedgerList } = this.state;
    const filteredList = partyLedgerList.find((a)=>a.customerId === creditNoteSummary.ledger_id)
    if( filteredList.accountGroup === 4 ){
      creditNoteSummary.type = enumVar.salesRelated;
    } else  {creditNoteSummary.type = enumVar.purchaseRelated;}
    this.setState({ creditNoteSummary});
  }

  handleCreate =() =>{
    this.setState({createModalOpen: true});
  }
  updateTotal = () =>{
    const { creditNoteSummary,vatStatus} = this.state;
    creditNoteSummary.vat = vatStatus ? VAT_PERCENTAGE :VAT_ZERO_PERCENTAGE;
    this.setState({ creditNoteSummary},()=>{
      this.handleInputChange('vat', creditNoteSummary?.vat)
    });
  }

  onChange = (field, value, all) => {
    const { createCN } = this.state;
    if(field ==='vatStatus'){
      this.setState({ vatStatus:value },()=>{
        this.updateTotal();
      });
      return;
    }
    if (field === 'ledger_id') {
      createCN.ledger_name = all.title;
      createCN.type_id = all.accountGroup;
    }
    createCN[field] = value;
    this.setState({ createCN });
  };
  getLedgerSelectOptions = () => {
    const {createCN,formValidation,ledgerList,ledgerListPartial} =this.state;
    return(
      <Cell col={6} tablet={6}>
              <label className='select-custom-label'>Ledger</label>
              <CustomerSelect
                value ={createCN.ledger_id}
                param='ledger_id'
                valueKey='customerId'
                clearable
                onHandleChange={this.onChange}
                required
                pageValidation={formValidation.flag}
                ledgerList={ledgerList}
                ledgerListPartial={ledgerListPartial}
                />
            </Cell>
    )
  }

  render() {
    const {
      loading,
      success,
      message,
      showMessage,
      createCN,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      ledgerList,
      cnList,
      pageValidation,
      formValidation,
      creditNoteSummary,
      saveDisable,
      tagList,
      addTag,
      backDatedStatus,
      vatStatus,
      partyLedgerList,
      partyLedgerListPartial,
    } = this.state;
    const { fiscalYearRange } = this.props;
    const minDate = fiscalYearRange?.previous_fiscal_year_closed ? fiscalYearRange?.start_date : fiscalYearRange?.previous_fiscal_year_start;
    return (
      <CreditNoteStyled>
        <div className='create-credit-note'>
          {loading && (
            <div className='linear-progress-wrapper temp-progress-wrapper'>
              <LinearProgress accent indeterminate />
            </div>
          )}
          <div className={loading ? 'clickable-false' : ''}>
            <div className='message-snackbar'>
              {
                <Snackbar
                  className={success ? 'success-message' : ''}
                  timeout={success ? 100 : 4000}
                  onTimeout={() => {
                    this.setState({ showMessage: false }, () => {
                      this.directToMainPage();
                    });
                  }}
                  open={showMessage}
                >
                  {message}
                </Snackbar>
              }
            </div>
            <div className='card-header-bar clearfix' ref='tableReference'>
              <div className='header-left'>
                <h2>New Credit Note</h2>
              </div>
              <GRNStyled>
              <div className={`${backDatedStatus || 'disabled-opacityFull'} header-right document_date`}>
                <span className="date-picker-wrapper">
                  <label>Document Date:</label>
                  <DatePicker
                    date={filterUtil.getMomentDate(creditNoteSummary.document_date)}
                    onChange={e => this.handleDateChange('document_date', e)}
                    maxDate={filterUtil.getCurrentDate()}
                    minDate={filterUtil.getMomentDate(minDate)}
                  />
                </span>
              </div>
            </GRNStyled>
            </div>
            <div className='card-body'>
              <div>
                <div className='three-input-wrapper'>
                  <Grid>
                  <Cell col={3} >
                    <label className="select-custom-label">Party Ledger</label>
                    <CustomerSelect
                    value ={creditNoteSummary.ledger_id}
                    param='ledger_id'
                    valueKey='customerId'
                    clearable
                    onHandleChange={this.handleInputChange}
                    required
                    pageValidation={pageValidation.flag}
                    ledgerList={partyLedgerList}
                    ledgerListPartial={partyLedgerListPartial}
                    />
                  </Cell>
                    <Cell col={3} >
                  <label className="select-custom-label">Type</label>
                  <BillingSelect
                    name="form-field-name"
                    param="type"
                    clearable
                    value={creditNoteSummary.type}
                    valueKey="title"
                    labelKey="label"
                    options={jvTypeList}
                    floatingLabel="Type"
                    handleChange={this.handleInputChange}
                    multipleParam
                    emptyField={pageValidation.flag}
                    required
                  />
                </Cell>

                    <Cell col={3} className='input-field'>
                      <BillingTextField
                        value={creditNoteSummary.panNo || ''}
                        param='panNo'
                        floatingLabel='PAN Number'
                        type='number'
                        className='billing-required no-pointer-events'
                      />
                    </Cell>

                    <Cell col={3}>
                      <span className='date-picker-wrapper refDate'>
                        <label>Ref Date</label>
                        <DateRangePicker
                          date={filterUtil.getMomentDate(
                            creditNoteSummary.ref_date
                          )}
                          onChange={(e) => this.handleDateChange('ref_date', e)}
                          maxDate={filterUtil.getCurrentDate()}
                        />
                      </span>
                    </Cell>

                    <Cell col={3} className='input-field'>
                      <BillingTextField
                        value={creditNoteSummary.ref_invoice_number}
                        param='ref_invoice_number'
                        floatingLabel='Ref Invoice Number'
                        required={false}
                        className='billing-required'
                        handleChange={this.handleInputChange}
                      />
                    </Cell>
                    <Cell col={3} className='input-field'>
                      <BillingTextField
                        value={creditNoteSummary.narration}
                        param='narration'
                        floatingLabel='Narration'
                        required={true}
                        className='billing-required'
                        emptyField={pageValidation.flag}
                        handleChange={this.handleInputChange}
                      />
                    </Cell>
                  </Grid>
                </div>
                <div className='table-wrapper'>
                  <div
                    ref='fixedTableBody'
                    className='overflow-scrollable fixed-table-wrapper'
                  >
                    <table>
                      <TableHeader
                        headerDetails={createCreditNoteConfig.headerDetails}
                        filterHeaderLabel={false}
                        handleSorting={this.handleTableSorting}
                      />
                      <CreateCreditNoteTableBody
                        dataList={cnList}
                        handleEditIconClick={this.handleEditIconClick}
                        handleDeleteIconClick={this.handleDeleteIconClick}
                        tagList={tagList}
                      />
                    </table>
                  </div>

                  {/* Fab Icon*/}
                  <div className='mini-fab-button'>
                  <Fab mini onClick={this.handleCreate}>
                      <Icon name='add' />
                    </Fab>
                  </div>
                </div>
              </div>
              <div className='total-section-wrapper'>
                <Grid>
                  <Cell col={8} />
                  <Cell col={2} className='right-align active-opacity-text'>
                    Sub Total
                  </Cell>
                  <Cell col={1} />
                  <Cell col={1} className='right-align'>
                    {fixedFloatAndCommas(creditNoteSummary.amount)}
                  </Cell>
                </Grid>

                <Grid>
                  <Cell col={8} />
                  <Cell col={2} className='right-align active-opacity-text'>
                    <span> VAT % </span>
                    <Checkbox
                  checked={vatStatus}
                  onChange={({ target: { checked } }) => {
                    this.onChange('vatStatus', checked);
                  }}
                />
                  </Cell>
                  <Cell col={1}>
                    <BillingTextField
                      value={vatStatus ? VAT_PERCENTAGE : VAT_ZERO_PERCENTAGE}
                      handleChange={this.handleInputChange}
                      type='number'
                      param='vat'
                      disabled
                      zeroError={false}
                    />
                  </Cell>
                  <Cell col={1} className='right-align'>
                    {fixedFloatAndCommas(
                      Number(creditNoteSummary.amount) *
                        Number(vatStatus ?VAT_PER_DERIVE : VAT_ZERO_PERCENTAGE)
                    )}
                  </Cell>
                </Grid>
                <div className='hr' />
                <Grid>
                  <Cell col={8} />
                  <Cell col={2} className='right-align active-opacity-text'>
                    Total
                  </Cell>
                  <Cell col={1} />
                  <Cell col={1} className='right-align'>
                    {fixedFloatAndCommas(Number(creditNoteSummary.net_amount))}
                  </Cell>
                </Grid>
              </div>
            </div>
            <div className='newinvoice-btn-wrapper '>
              <Grid>
                <Cell col={8} />
                <Cell col={4} className='right-align'>
                  <Button
                    accent
                    className='cancel-btn modal-btn'
                    onClick={() => {
                      this.handleCancelClick();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    accent
                    className={
                      saveDisable
                        ? 'save-btn modal-btn btn-disabled'
                        : 'save-btn modal-btn'
                    }
                    disabled={saveDisable}
                    onClick={() => {
                      this.handleSaveClick();
                    }}
                  >
                    Save
                  </Button>
                </Cell>
              </Grid>
            </div>

            <DialogView
              renderLedgerSelectOptions={this.getLedgerSelectOptions}
              addTag={addTag}
              onModalClose={this.handleModalClose}
              modalOpen={createModalOpen}
              editModalOpen={editModalOpen}
              deleteModalOpen={deleteModalOpen}
              createCN={createCN}
              handleInputChange={this.onChange}
              onModalSubmit={this.handleModalOk}
              formEmptyField={formValidation.flag}
              handleDateChange={this.handleDateChange}
              tagList={tagList}
            />
          </div>
        </div>
      </CreditNoteStyled>
    );
  }
}

CreateCreditNote.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
    fiscalYearRange: state.billing.fiscalYearRange || null,
  };
};

const createCreditNote = connect(mapStateToProps)(CreateCreditNote);

export default createCreditNote;
