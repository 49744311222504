import React from 'react';
import PropTypes from 'prop-types';

const GRNDetailTableBody = ({ list }) => (
  <tbody>
    {list.map((data, index) => (
      <tr>
        <td className='left-align'>{index + 1}</td>
        <td className='left-align'>{data.idPurchaseDetail}</td>
        <td className='left-align'>{data.title}</td>
        <td className='right-align'>{data.rate}</td>
        <td className='right-align'>{data.quantity}</td>
        <td className='right-align'>{data.discount}</td>
        <td className='right-align'>{data.netAmount.toLocaleString()}</td>
      </tr>
    ))}
  </tbody>
);

GRNDetailTableBody.propTypes = { list: PropTypes.object.isRequired, };

const grnDetailConfig = {
  headerDetails: [
    { id: 1, label: 'idPurchaseDetail', title: 'SN', className: 'cursor-pointer', sortable: false },
    { id: 1, label: 'idPurchaseDetail', title: 'GRN Detail ID', className: 'cursor-pointer', sortable: false },
    { id: 2, label: 'skuId', title: 'SKU', className: 'cursor-pointer', sortable: false },
    { id: 3, label: 'rate', title: 'Rate', className: 'cursor-pointer right-align', sortable: false },
    { id: 4, label: 'quantity', title: 'Quantity (pcs)', className: 'cursor-pointer right-align', sortable: false },
    { id: 5, label: 'promotionDiscount', title: 'Discount', className: 'cursor-pointer right-align', sortable: false },
    { id: 6, label: 'netAmount', title: 'Net Amount', className: 'cursor-pointer right-align', sortable: false },
  ],
};

export { grnDetailConfig, GRNDetailTableBody };
