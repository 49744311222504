import PropTypes from 'prop-types';
import React from 'react';

import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../../../components/BillingMDC';

import { ACTION } from '../../../../../data/enums/enums';
import DatePicker from '../../../../../components/DatePicker';
import BillingTextField from '../../../../../components/TextField/BillingTextField';
import BillingSelect from '../../../../../components/Select/BillingSelect';

const propTypes = {
  actionType: PropTypes.string,
  subBrand: PropTypes.instanceOf(Array),
  onInputChange: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onModalSubmit: PropTypes.func.isRequired,
  validation: PropTypes.shape({
    fieldList: PropTypes.instanceOf(Array),
    flag: PropTypes.bool,
  }),
};

const defaultProps = {
  actionType: null,
  subBrand: {},
  validation: { flag: false, fieldList: [] },
};

const SubBrandDialog = ({
  actionType,
  selectedId,
  validation,
  onModalSubmit,
  onModalClose,
  addSkuCatalogs,
  selectedCatalog,
  parentGroupList,
  onInputChange, // onChange
}) => {
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>Add {selectedCatalog.length > 0 && selectedCatalog[0].TITLE}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <div className='dialog-upperpart'>
          <div>
            <Grid className='grid-padding'>
              <Cell col={6} tablet={8}>
                <BillingTextField
                  value={addSkuCatalogs.title}
                  handleChange={onInputChange}
                  floatingLabel='Title'
                  param='title'
                  required
                  emptyField={validation.flag}
                />
              </Cell>
              {selectedId !== 1 && (
                <Cell col={6} tablet={8}>
                  <label>Parent Group</label>
                  <BillingSelect
                    name='form-field-name'
                    param='parentCatalogDetailId'
                    clearable={false}
                    value={addSkuCatalogs.parentCatalogDetailId}
                    valueKey='business_catalog_detail_id'
                    labelKey='title'
                    options={parentGroupList}
                    handleChange={(param, value, event) => onInputChange(param, value, event)}
                    multipleParam
                  />
                </Cell>
              )}
            </Grid>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

SubBrandDialog.propTypes = propTypes;

SubBrandDialog.defaultProps = defaultProps;

export default SubBrandDialog;
