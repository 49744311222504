import styled from 'styled-components';

const PageHeaderStyled = styled.div`
  .card-header-bar {
    display: flex;
    align-items: center;
    padding: 0 24px;
    background: #fff;
    line-height: 64px;
  }

  .card-header-bar input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: white;
  }

  .card-header-bar input::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
  }

  .card-header-bar input:-ms-input-placeholder {
    /* IE 10+ */
    color: white;
  }

  .card-header-bar input:-moz-placeholder {
    /* Firefox 18- */
    color: white;
  }
  .header-menu-right,
  .header-menu {
    display: flex;
  }
  .date-rendered {
    color: rgba(0, 0, 0, 0.54);
  }
  .search-icon-wrapper {
    background: #f6f6f6;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    -webkit-transition: 5s ease-in-out;
    -moz-transition: 5s ease-in-out;
    -o-transition: 5s ease-in-out;
    transition: 5s ease-in-out;
    transition-delay: 1s;
  }
  .search-icon-wrapper .search-icon {
    position: absolute;
    top: 7px;
    left: -19.5px;
    font-size: 20px !important;
  }

  .cross-icon {
    margin-left: 0px;
    position: absolute;
    top: 20px;
    right: -12px;
  }
  .download-menu {
    position: relative;
  }
  .header-menu-right .mdc-menu-anchor {
    position: unset;
  }
  .header-menu-right .mdc-menu-anchor .transaction-download {
    left: unset !important;
    top: 48px !important;
    right: 0 !important;
  }
  .header-menu-right {
    position: relative;
    .drawer_filter{
      position: relative;
      span{
        position: absolute;
        top: -4px;
        right: -2px;
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        font-size: 8px;
        text-align: center;
        line-height: 12px;
        background-color: #2196f3;
        color: #FFFFFF;
      }
    }
  }
  .header-menu-right .mdc-simple-menu .mdc-list,
  .transaction-download {
    transform: unset !important;
  }
  .right-drawer {
    .form-input.switch {
      padding: 20px 24px;
      display: flex;
      flex-direction: column;
      & > label span {
        font-size: 14px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.54);
        margin-bottom:18px;
        display:block;
        font-weight:500;
      }
    }
    & > nav {
      .view-by-wrap {
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        padding:20px 0;
        & > label {
          padding-left: 24px;
          font-size: 14px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.54);
          font-weight: 500;
        }
        & > ul {
          padding-top:8px;
          display: flex;
          li {
            flex: 1;
            label {
              font-size: 12px;
            }
            &:first-child {
              label {
                display: inline-block;
                white-space: normal;
                width: 153px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
`;
export default PageHeaderStyled;
