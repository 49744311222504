import React from 'react';
import { Icon } from '../../../components/BillingMDC';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { TRANSACTION_TYPE } from '../../../data/enums/enums';
import { formatToNumericDate } from '../../../utils/filterUtil';

const pageValidateFieldArray = [{ title: 'party_ledger' }, { title: 'narration' }, { title: 'type' }];

const formValidateFieldArray = [
  { title: 'ledger_id' },
  { title: 'dr_amount' },
];
const enums = {
  salesRelated: 'SALES_RELATED',
  purchaseRelated: 'PURCHASE_RELATED',
};
const jvTypeList = [
  { id: 1, title: enums.salesRelated, label: 'Sales Related' },
  { id: 2, title: enums.purchaseRelated, label: 'Purchase Related' },
];

const message = {
  success: 'Credit Note created.',
  error: '!Error while creating Credit Note',
  balanceError: '!Error Debit and Credit amount must be equal',
};

const initializeCreateCN = () => ({
  ledger_name: '',
  ledger_id: '',
  cr_amount: 0,
  dr_amount: '',
  narration: '',
  type_id: '',
  tagid: '',
});

const getApiTransformedData = (ceList, user) => ceList.map((ceItem) => {
  delete ceItem.includePartyInfo;
  delete ceItem.ledger_name;
  delete ceItem.party_pan_no;
  ceItem.business_id = null;
  ceItem.entered_by = user.idUsers;

  return ceItem;
});

const apiTransformedCNSummary = (dnSummary, user) => ({
  business_id: null,
  entered_by: user.idUsers,
  transaction_type: TRANSACTION_TYPE.CREDIT_NOTE,
  narration: dnSummary.narration,
  ref_number: dnSummary.ref_invoice_number,
  ref_date: formatToNumericDate(dnSummary.ref_date),
  net_cr_amount: dnSummary.net_amount,
  net_dr_amount: dnSummary.net_amount,
  type: dnSummary.type,
  document_date:dnSummary.document_date,
});

const createCreditNoteConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'index',
      title: 'SN',
      className: null,
      sortable: false,
      display: true,
    },
    {
      id: 2,
      label: 'ledger',
      title: 'Ledger',
      className: null,
      sortable: false,
      display: true,
    },
    {
      id: 3,
      label: 'amount',
      title: 'Amount',
      className: 'right-align',
      sortable: false,
      display: true,
    },
    {
      id: 6,
      label: 'tag',
      title: 'Tag',
      className: '',
      sortable: false,
      display: true,
    },
    {
      id: 4,
      label: 'narration',
      title: 'Narration',
      className: 'left-align',
      sortable: false,
      display: true,
    },
    {
      id: 5,
      label: 'action',
      title: '',
      className: 'right-align',
      sortable: false,
      display: true,
    },
  ],
};

const CreateCreditNoteTableBody = ({
  dataList,
  handleEditIconClick,
  handleDeleteIconClick,
  tagList,
}) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr>
        <td>{index + 1}</td>
        <td>{data.ledger_name}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.dr_amount)}</td>
        <td className='left-align'>{data.tagid ? tagList.find((v) => v.idTags === data.tagid).title || '' : ''}</td>
        <td className='left-align'>{data.narration}</td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => handleEditIconClick(data, index)} />
          <Icon name='delete' onClick={() => handleDeleteIconClick(index)} />
        </td>
      </tr>
    ))}
  </tbody>
);

export {
  message,
  pageValidateFieldArray,
  formValidateFieldArray,
  initializeCreateCN,
  createCreditNoteConfig,
  CreateCreditNoteTableBody,
  getApiTransformedData,
  apiTransformedCNSummary,
  jvTypeList,
  enums as enumVar,
};
