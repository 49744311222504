import { compose } from 'recompose';

import NetSalesReport from './NetSalesReport';

import apiInterceptor from '../../../api/interceptor';

import { netSalesReportApi } from '../../common/base.api';

const composedNetSalesReport = compose(apiInterceptor({ ...netSalesReportApi }))(NetSalesReport);

export default composedNetSalesReport;
