import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const PRNReportTableBody = ({ dataList = [], queryParameters = {} }) => (
  <>
    {dataList.length > 0 ? (
      <tbody>
        {dataList.map((data) => (
          <tr className='cursor-pointer'>
            {Object.values(data.details).map((d) => (
              <td className='left-align'>{d}</td>
            ))}
          </tr>
        ))}
      </tbody>
    ) : (
      <>
        {queryParameters.search.length > 0 || (
          <tbody>
            <td>Upload CSV File...</td>
          </tbody>
        )}
      </>
    )}
  </>
);

PRNReportTableBody.propTypes = { dataList: PropTypes.instanceOf(Array).isRequired, };
const getUploadInput = (upload = {}) => {
  const details = {
    invoice_number: upload.invoice_number || '',
    customer_name: upload.customer_name || '',
    date: upload.date || '',
  };

  return details;
};

const uploadFields = [{ title: 'invoice_number' }, { title: 'customer_name' }];

const config = {
  getTableBody: PRNReportTableBody,
  uploadFields,
  getUploadInput,
};
export default config;
