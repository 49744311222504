import { compose } from 'recompose';

import apiInterceptor from '../../../api/interceptor';

import AbbreviatedInvoice from './AbbreviatedInvoice';
import { salesInvoiceApi } from '../../common/base.api';

const composedAbbreviatedInvoice = compose(apiInterceptor({ ...salesInvoiceApi }))(AbbreviatedInvoice);

export default composedAbbreviatedInvoice;
