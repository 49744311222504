import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../../utils/conversion';

const SRNDetailTableBody = ({
  dataList = [],
  printInfoBill = false,
  remainingLines = [],
}) => {
  const getUOM =(list,field) =>list?.uom_list?.find((a) =>a?.id ===list?.uomId)[field];
  return (
    <tbody>
      {dataList.map((data, index) => (
        <tr className='cursor-pointer'>
          <td className='left-align sn'>{data.index ? data.index + 1 : index + 1}</td>
          {/*  <td className="left-align">{data.idSalesReturnDetail}</td> */}
          <td className='left-align sku'>{data.sku}</td>
          {printInfoBill.batchEnabled && <td className='batch'>{data.batch}</td>}
          <td className='right-align quantity'>{(data?.quantity/(getUOM(data,'pcs'))) || data?.quantity || ''}</td>
          <td className='batch right-align'>{getUOM(data,'label') || 'Pcs'}</td>
          <td className='right-align rate'>{fixedFloatAndCommas(data?.uomRate)}</td>
          <td className='right-align print_display_none'>{data.normal/(getUOM(data,'pcs'))}</td>
          <td className='right-align print_display_none'>{data.damage/(getUOM(data,'pcs'))}</td>
          <td className='right-align print_display_none'>{data.expiry/(getUOM(data,'pcs'))}</td>
          <td className='right-align discount'>
            {fixedFloatAndCommas(data.promotionDiscount)}
          </td>
          <td className='right-align netAmount font-bold' >
            {fixedFloatAndCommas(data.netAmount)}
          </td>
        </tr>
      ))}
      {remainingLines.length ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      )):null}
    </tbody>
  );
};

SRNDetailTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const headerConfig = [
  {
    id: 1,
    label: 'idSalesReturnDetail',
    title: 'SN',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 3,
    label: 'sku',
    title: 'SKU',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 8,
    label: 'quantity',
    title: 'Quantity',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 3,
    label: 'unit',
    title: 'Unit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 4,
    label: 'rate',
    title: 'Rate',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
  {
    id: 5,
    label: 'normal',
    title: 'Sellable',
    className: 'cursor-pointer right-align print_display_none',
    sortable: false,
  },
  {
    id: 6,
    label: 'damage',
    title: 'Damage',
    className: 'right-align print_display_none',
    sortable: false,
  },
  {
    id: 7,
    label: 'expiry',
    title: 'Expiry',
    className: 'right-align print_display_none',
    sortable: false,
  },
  {
    id: 9,
    label: 'promotionDiscount',
    title: 'Discount',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
  {
    id: 10,
    label: 'netAmount',
    title: 'Net Amount',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
];

const title = {
  original: 'SALES RETURN',
};
const signatureTitle = 'Prepared By';

const customerDetailMapper = (detail) => ({
  invoiceId: detail.refNumber || '',
  customerName: detail.customerName
    ? detail.customerName
    : detail.customer
      ? detail.customer.title
      : '',
  address: detail.customer ? detail.customer.address : '',
  panNo: detail.customer ? detail.customer.panNo : '',
  salesReturnNumber: detail.salesReturnInvoiceNumber || '',
});

const billSummaryMapper = (data) => ({
  subTotal: data.subTotal || 0,
  billDiscount: data.billDiscount || 0,
  tradeDiscount: data.tradeDiscount || 0,
  taxableAmount: data.taxableAmount || 0,
  totalValue: data.netAmount || 0,
  vat: data.vat || 0,
  remarks: data.remarks || '',
  totalInWords: (data.netAmount >= 1) ? data.totalInWords : '',
  billPercent: data.billPercent || 0,
  tradePercent: data.tradePercent || 0,
  agentName: data?.agentData?.name,
  excise: data?.excise
});
const lengthyAttributes = ['sku'];
const printEnums = {
  withoutFooterLimit: 54,
  withFooterLimit: 40,
  sku: 40,
  batch: 24,
  lengthyAttributes,
};
const printEnumsBatch = {
  withoutFooterLimit: 54,
  withFooterLimit: 40,
  sku: 40,
  batch: 24,
  lengthyAttributes: [...lengthyAttributes, 'batch'],
};

const config = {
  header: headerConfig,
  getTableBody: SRNDetailTableBody,
  billSummaryMapper,
  customerDetailMapper,
  signatureTitle,
  title,
  printEnums,
  printEnumsBatch,
  uniqueIdGetter: (i) => i.idSalesReturnDetail,
};
export default config;
