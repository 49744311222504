import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import moment from 'moment';
import axios, { post } from 'axios';
import {
  Fab,
  Grid,
  Icon,
  Cell,
  Menu,
  Drawer,
  Dialog,
  Button,
  MenuItem,
  Checkbox,
  Snackbar,
  Textfield,
  FormField,
  Navigation,
  DialogBody,
  MenuAnchor,
  DialogTitle,
  DrawerHeader,
  DialogHeader,
  DialogFooter,
  DrawerContent,
  LinearProgress,
  DrawerHeaderContent,
} from 'react-mdc-web';
import _ from 'lodash';
import Select from 'react-select';
import orderBy from 'lodash/orderBy';
//import 'react-select/dist/react-select.css';
import * as appConfig from '../../../config';
import * as appConstants from '../../../config';
import * as httpUtils from '../../../../utils/httpUtils';
import * as filterUtil from '../../../../utils/filterUtil';
import DateToRender from '../../../../components/DateToRender';
import Pagination from '../../../../components/Pagination/index';
import TableHeader from '../../../../components/TableHeader/index';
import { getFixTableMeasurements } from '../../../../utils/tableMetrics';
import { downloadFormat, message, promotionConfig, PromotionTableBody } from './PromotionConfig';
import DateRangePicker from '../../../../components/DateRangePicker/DateRangePicker';
import BillingSelect from '../../../../components/Select/BillingSelect';

class Promotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showSearchBox: false,
      showFilter: false,
      showMenu: false,
      dataSet: [],
      searchValue: '',
      totalData: 1,
      dateClickCount: 0,
      businessUnitId: '',
      createModalOpen: false,
      assignSkuModalOpen: false,
      createPromotion: {
        file: null,
        businessId: '',
      },
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
        },
      },
      assignedSkuList: {
        business_sku_id: [],
        brand_id: '',
        business_promotion_id: '',
        business_id: '',
      },
      assignedSkuDetail: [],
      fromServerSkuId: [],
      brandList: [],
      skuList: [],
      buList: [],
      showMessage: false,
      // errorMessage:[]
      success: false,
      // assignSkuSuccess:false,
      message: '',
    };
  }

  /** adjust table width and height according to screen **/
  componentDidMount() {
    this.getTableDataForGrid();
    this.getBrandList();
    this.getBUList();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    let table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
  }

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    this.setState({ loading: true });
    const { queryParameters } = this.state;
    httpUtils
      .get(
        appConstants.baseUrl +
          `upload-promotion?bu=${this.state.businessUnitId}&page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}&start_date=${queryParameters.date.start}&end_date=${queryParameters.date.end}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data.list, totalData: response.data.total, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  // get BU list
  getBUList = () => {
    httpUtils.get(appConfig.baseUrl + 'upload/business-company').then(response => {
      if (response.success) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  getAssignedSkuList = (businessPromotionId, businessId) => {
    const self = this;
    this.setState({ loading: true });
    httpUtils
      .get(
        appConstants.baseUrl +
          `upload-promotion/skus?business_id=${businessId}&business_promotion_id=${businessPromotionId}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ assignedSkuDetail: response.data.list, loading: false }, () => self.getSkuFromDetailList());
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  getSkuFromDetailList = () => {
    const { assignedSkuDetail, assignedSkuList } = this.state;
    if (assignedSkuDetail.length) {
      const skuArray = assignedSkuDetail.map((data, key) => {
        return data.businessSkuId;
      });
      assignedSkuList.business_sku_id = skuArray;
      this.setState({ assignedSkuList: assignedSkuList, fromServerSkuId: skuArray });
    }
  };

  setAssignedSkuList = () => {
    const { assignedSkuList } = this.state;
    const skuList = assignedSkuList.business_sku_id.map((data, key) => {
      return { business_sku_id: data };
    });
    const postObj = {
      skuList: skuList,
      business_promotion_id: assignedSkuList.business_promotion_id,
      business_id: assignedSkuList.business_id,
    };
    httpUtils
      .post(appConstants.baseUrl + 'upload-promotion/skus', postObj)
      .then(response => {
        this.setState({ loading: false });
        if (response.success) {
          // alert('Sku successfully assigned');
          this.setState(
            {
              fromServerSkuId: [],
              showMessage: true,
              success: true,
              message: message.skuSuccess,
            },
            () => {
              this.resetAssignedSkuList();
            },
          );
        } else {
          // alert('error');
          // this.setState({assignedSkuList:{business_sku_id:[],promotion_id:[],brand_id:'',fromServerSkuId:[]},skuList:[]})
          this.setState(
            {
              fromServerSkuId: [],
              showMessage: true,
              success: true,
              message: message.skuError,
            },
            () => {
              this.resetAssignedSkuList();
            },
          );
        }
      })
      .catch(error => {
        this.setState(
          {
            fromServerSkuId: [],
            showMessage: true,
            success: true,
            message: message.skuError,
          },
          () => {
            this.resetAssignedSkuList();
          },
        );
      });
    this.closeAssignSkuModal();
  };
  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getTableDataForGrid());
  };

  /** on Date Range Change */
  handleDateRangeChange = (start, end) => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.dataSet;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';

    return orderBy(this.state.dataSet, labelName, sortingName);
  };

  handleFabButtonClick = () => {
    this.setState({ createModalOpen: true });
  };

  closeModal = () => {
    // set false to opening Modal
    this.setState({ createModalOpen: false });
  };

  closeAssignSkuModal = () => {
    this.setState({ assignSkuModalOpen: false, skuList: [] }, this.resetAssignedSkuList());
  };

  // reset assigned sku list
  resetAssignedSkuList = () => {
    this.setState({ assignedSkuList: { business_sku_id: [], brand_id: '', promotion_id: '' } });
  };

  // get the brand list
  getBrandList = () => {
    const { businessUnitId } = this.state;
    httpUtils.get(appConfig.baseUrl + 'brand/list?bu_id=' + businessUnitId).then(response => {
      if (response.success) {
        this.setState({ brandList: response.data.brandList });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = (brandId, buId) => {
    // const brandId = this.state.createSKU.brand;
    // bu_id=1&bu_brand_id=3
    const { assignedSkuList, businessUnitId } = this.state;
    assignedSkuList.brand_id = brandId;
    httpUtils.get(appConfig.baseUrl + 'sku-list?bu_id=' + buId + '&bu_brand_id=' + brandId).then(response => {
      if (response.success) {
        this.setState({ skuList: response.data, assignedSkuList: assignedSkuList });
      }
    });
  };

  // handle assign sku button click
  handleSkuButtonClick = data => {
    const { assignedSkuList } = this.state;
    assignedSkuList.business_promotion_id = data.businessPromotionId;
    assignedSkuList.business_id = data.businessId;
    this.getAssignedSkuList(data.businessPromotionId, data.businessId);
    this.setState({ assignSkuModalOpen: true, assignedSkuList: assignedSkuList });
  };

  // check if checkbox for sku is checked
  checkIfCheckBoxChecked = skuID => {
    const { assignedSkuList } = this.state;
    const result = assignedSkuList.business_sku_id.indexOf(skuID) > -1;

    return result;
  };

  handleSkuCheckboxClick = (skuID, flag) => {
    const { assignedSkuList } = this.state;
    if (flag == true) {
      assignedSkuList.business_sku_id.push(skuID);
    } else if (flag == false) {
      const index = assignedSkuList.business_sku_id.indexOf(skuID);
      if (index !== -1) {
        assignedSkuList.business_sku_id.splice(index, 1);
      }
    }
    this.setState({ assignedSkuList: assignedSkuList });
  };

  handleAssignedSkuDialogCloseClick = () => {
    const { assignedSkuList, fromServerSkuId } = this.state;
    assignedSkuList.business_sku_id = fromServerSkuId;
    this.setState({ assignedSkuList: assignedSkuList });
    this.closeAssignSkuModal();
  };

  // handle the file upload.
  handleFileUpload = event => {
    event.preventDefault();
    const { createPromotion } = this.state;
    createPromotion['file'] = event.target.files[0];
    this.setState({ createPromotion: createPromotion });
  };

  /** download Promotion Report */
  handleDownloadClick = id => {
    let type = '';
    const self = this;
    const schema = 'https://';
    if (id === 1) {
      type = 'sample';
    }
    httpUtils
      .get(appConstants.baseUrl + `download/promotion?type=${type}`)
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ loading: false });
          const url = response.data.url;
          const newWin = window.open(schema + window.location.hostname + url);
          if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
            alert('Please enable pop for this site');
          }
        } else {
          self.setState({ loading: false, message: message.downloadError, success: false, showMessage: true });
        }
      })
      .catch(error => {
        console.error(error);
        self.setState({ loading: false, message: message.downloadError, success: false, showMessage: true });
      });
  };

  // create the promotion
  createPromotion = () => {
    const { createPromotion } = this.state;
    const file = createPromotion.file;
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append('file', file);
    formData.append('businessId', createPromotion.businessId);
    this.setState({ loading: true, createModalOpen: false });
    httpUtils
      .multipartPost(appConfig.baseUrl + 'upload-promotion', formData)
      .then(response => {
        this.setState({ loading: false });
        this.promotionFileInput.value = null;
        if (response.status == 200 && response.success) {
          this.setState({ showMessage: true, success: true, message: message.success });
        } else {
          this.setState({ loading: false, message: message.error, success: false, showMessage: true });
        }
      })
      .catch(error => {
        this.promotionFileInput.value = null;
        this.setState({ showMessage: true, loading: false, success: false, message: message.error });
        // this.setState({showMessage: true, loading:false, errorMessage:error.data.error.details})
      });
  };

  closeMessageWrapper = status => {
    this.setState({ showMessage: status, errorMessage: [] });
  };

  onSearchInputChange = value => {
    const self = this;
    const searchText = value;
    const { queryParameters } = this.state;
    httpUtils
      .get(
        appConstants.baseUrl +
          `upload-promotion?bu=${this.state.businessUnitId}&page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}&search_text=${searchText}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data.list, totalData: response.data.total, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleChange = (field, value) => {
    const { createPromotion } = this.state;
    createPromotion[field] = value;

    this.setState({ createPromotion });
  };

  inputSearch = value => {
    const searchValue = value;
    this.setState({ searchValue: searchValue }, () => {
      this.changeSearch(this.state.searchValue);
    });
  };

  clearInputText = () => {
    const value = '';
    const { showSearchBox } = this.state;
    this.setState({ searchValue: '', showSearchBox: !showSearchBox }, () => {
      this.onSearchInputChange(value);
    });
  };

  changeSearch = _.debounce(term => {
    this.onSearchInputChange(term);
  }, 700);

  render() {
    const {
      loading,
      queryParameters,
      totalData,
      sorting,
      createModalOpen,
      assignSkuModalOpen,
      skuList,
      showMessage,
      errorMessage,
      showSearchBox,
      searchValue,
      createPromotion,
    } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);

    return (
      <div>
        <div>
          {loading && (
            <div className="linear-progress-wrapper temp-progress-wrapper">
              <LinearProgress accent indeterminate />
            </div>
          )}
          <div className={loading ? 'clickable-false' : ''}>
            <div className="message-snackbar">
              {/* {showMessage && <MessageWrapper
                  name="! Error"
                  messageArray={errorMessage}
                  //message="There was error while uploading file"
                  closeMessage={this.closeMessageWrapper}
                  error={true}
                />}*/}

              {
                <Snackbar
                  action="RETRY"
                  className={this.state.success ? 'success-message' : ''}
                  onClick={e => {
                    this.handleFabButtonClick();
                  }}
                  timeout={this.state.success ? 2500 : 4000}
                  onTimeout={() => {
                    this.setState({ showMessage: false });
                  }}
                  open={showMessage}
                >
                  {this.state.message}
                </Snackbar>
              }
            </div>
            <div className="card-header-bar" ref="tableReference">
              <h2>Promotion</h2>
              <div className="header-menu">
                <div className="header-menu-left ">
                  <span
                    className="search-icon-wrapper"
                    onClick={() =>
                      this.setState({
                        showSearchBox: !showSearchBox,
                      })
                    }
                  >
                    <Icon name="search" className="search-icon material-icons" />
                  </span>
                  <span className={`top-search-input-wrapper ${showSearchBox ? 'input-active' : 'input-inactive'}`}>
                    {showSearchBox && (
                      <Textfield
                        value={this.state.searchValue}
                        onChange={e => {
                          this.inputSearch(e.target.value);
                        }}
                      />
                    )}
                    {searchValue && (
                      <Icon name="close" className="cross-icon material-icons" onClick={this.clearInputText} />
                    )}
                  </span>
                </div>
                <div className="header-menu-right">
                  <span className="date-rendered">
                    <Icon name="date_range" className="material-icons" />
                    <DateToRender date={queryParameters.date} />
                  </span>
                  <span className="download-menu" onClick={() => this.setState({ showMenu: !this.state.showMenu })}>
                    <Icon name="get_app" />
                  </span>
                  <MenuAnchor>
                    <Menu
                      open={this.state.showMenu}
                      onClose={() => {
                        this.setState({ showMenu: false });
                      }}
                      className=""
                    >
                      <MenuItem
                        onClick={() => {
                          this.handleDownloadClick(downloadFormat[0].id);
                        }}
                      >
                        {downloadFormat[0].value}
                      </MenuItem>
                    </Menu>
                  </MenuAnchor>
                  <span onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                    <Icon name="filter_list" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div ref="fixedTableBody" className="fixed-table-wrapper">
            <table>
              <TableHeader
                headerDetails={promotionConfig.headerDetails}
                filterHeaderLabel={false}
                handleSorting={this.handleTableSorting}
              />
              {sortedDataSet.map((data, key) => (
                <tbody>
                  <PromotionTableBody data={data} onSkuButtonClick={this.handleSkuButtonClick} />
                </tbody>
              ))}
            </table>
          </div>

          {
            <div ref="fixedTableFooter">
              <Pagination
                pageSize={queryParameters.pagination.limit}
                currentPage={queryParameters.pagination.page}
                orientation="top"
                totalItems={totalData}
                onPageSelect={this.onPageSelect}
              />
            </div>
          }
        </div>
        {/* fab button and dialogs */}
        <div className="create-fab-button">
          <Fab onClick={() => this.handleFabButtonClick()}>
            <Icon name="add" />
          </Fab>
        </div>
        <div dir="rtl">
          <Drawer
            open={this.state.showFilter}
            className="right-drawer"
            onClose={() => {
              this.setState({ showFilter: false });
            }}
          >
            <div className="filter-wrapper">
              <h2 className="default-horizontal-padding-24">
                <span>Filter</span>
                <span className="float-right">
                  <Icon name="replay" onClick={() => this.resetFilter()} />
                  <Icon name="clear" onClick={() => this.setState({ showFilter: false })} />
                </span>
              </h2>
              <div className="divider" />
              <DateRangePicker
                startDate={moment(queryParameters.date.start)}
                endDate={moment(queryParameters.date.end)}
                onChange={this.handleDateRangeChange}
              />
            </div>
          </Drawer>
        </div>
        <Dialog
          open={createModalOpen}
          onClose={() => {
            this.closeModal();
          }}
        >
          <DialogHeader>
            <DialogTitle>Create Promotion</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <div className="dialog-upperpart">
              <Grid className="grid-padding">
                <Cell col={6} tablet={8}>
                  <label>Business Unit</label>
                  <BillingSelect
                    name="form-field-name"
                    param="businessId"
                    clearable={false}
                    value={createPromotion.businessId}
                    valueKey="businessId"
                    labelKey="businessAlias"
                    options={this.state.buList}
                    handleChange={this.handleChange}
                    required={true}
                  />
                </Cell>
                <Cell col={6} tablet={8}>
                  <input
                    type="file"
                    name="resume"
                    className="input-file-upload"
                    ref={ref => (this.promotionFileInput = ref)}
                    onChange={e => this.handleFileUpload(e)}
                  />
                </Cell>
              </Grid>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button accent className="dialog-cancel modal-btn" onClick={() => this.closeModal()}>
              Cancel
            </Button>
            <Button
              accent
              className={
                createPromotion.file && createPromotion.businessId
                  ? 'dialog-ok modal-btn'
                  : 'dialog-ok modal-btn btn-disabled'
              }
              onClick={() => {
                this.createPromotion();
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </Dialog>

        {/* Assign Sku modal*/}

        <Dialog
          open={assignSkuModalOpen}
          onClose={() => {
            this.closeAssignSkuModal();
          }}
          className="assignSkuDialog"
        >
          <DialogHeader>
            <DialogTitle>Assign SKU</DialogTitle>
          </DialogHeader>
          <DialogBody scrollable className="default-horizontal-padding-24">
            <Grid className="grid-padding">
              <Cell col={4} tablet={8}>
                <label>Brand</label>
                <Select
                  name="form-field-name"
                  clearable={false}
                  value={this.state.assignedSkuList.brand_id}
                  valueKey="businessCatalogDetailId"
                  labelKey="title"
                  options={this.state.brandList}
                  onChange={e => this.getSKUListUnderBrand(e.businessCatalogDetailId, e.businessId)}
                />
              </Cell>
              <Cell col={1} />
              <Cell col={7} tablet={8} className="list-with-checkbox">
                <p className="list-header">SKU</p>
                {skuList &&
                  skuList.map((data, key) => {
                    return (
                      <div>
                        <FormField id={`checkbox_${key}`}>
                          <Checkbox
                            checked={this.checkIfCheckBoxChecked(data.business_sku_id)}
                            onChange={({ target: { checked } }) => {
                              this.handleSkuCheckboxClick(data.business_sku_id, checked);
                            }}
                          />
                          <label>{data.title}</label>
                        </FormField>
                      </div>
                    );
                  })}
              </Cell>
            </Grid>
          </DialogBody>
          <DialogFooter>
            <Button accent className="dialog-cancel modal-btn" onClick={() => this.handleAssignedSkuDialogCloseClick()}>
              Cancel
            </Button>
            {/* <Button accent className={this.state.assignedSkuList.business_sku_id.length ? 'dialog-ok modal-btn' : 'dialog-ok modal-btn btn-disabled'}*/}
            <Button
              accent
              className="dialog-ok modal-btn"
              onClick={() => {
                this.setAssignedSkuList();
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

Promotion.contextTypes = {
  router: PropTypes.object,
};

export default Promotion;
