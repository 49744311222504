import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  DialogFooter,
  Button,
} from '../../components/BillingMDC';
import { ACTION } from '../../data/enums/enums';
import BillingTextField from '../../components/TextField/BillingTextField';
const ForgetPasswordDialog = ({ actionType, onModalClose, onSubmit, onInputChange, validationFlag, data }) => {
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
      className='forget__pw--dialog'
    >
      <DialogHeader>
        <DialogTitle>Forgot Password</DialogTitle>
      </DialogHeader>

      <DialogBody>
        <div className={'dialog-upperpart }'}>
          <p>Please enter your email address to go through reset process.</p>
          <BillingTextField
            value={data.resetEmail}
            handleChange={onInputChange}
            floatingLabel='Email'
            param='resetEmail'
            required={true}
            className='right-side-gap'
            emptyField={validationFlag}
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onSubmit('forgot_pw');
          }}
        >
          Send
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
ForgetPasswordDialog.propTypes = {
  actionType: PropTypes.string.isRequired,
  validationFlag: PropTypes.bool.isRequired,
};
ForgetPasswordDialog.defaultProps = {
  actionType: ACTION.NULL,
  validationFlag: false,
};

export default ForgetPasswordDialog;
