import { compose } from 'recompose';

import SKU from './SKU';

import apiInterceptor from '../../../api/interceptor';

import { catalogsApi, buApi } from '../../common/base.api';

const composedSKU = compose(apiInterceptor({ ...catalogsApi, getBUList: buApi.getList }))(SKU);

export default composedSKU;
