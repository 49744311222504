import React, { Component } from 'react';
import withBaseState from '../common/withBaseState';
import { CREDIT_NOTE_CREATE, CREDIT_NOTE_DETAILS } from '../../data/enums/Route';
import tableConfig from './config';
import history from '../../utils/history';
import CreditNoteStyled from './CreditNoteStyled';
import PageView from '../common/pagination/PageView';
import * as queryService from '../common/query.service';
import * as snackService from '../common/snack.service';
import { voucherEncoder } from '../../utils/miscellaneous';
import { BillingSnackBar } from '../../components/BillingMDC';
import { TRANSACTION_TYPE } from '../../data/enums/enums';
import { CREDIT_NOTE_CONFIG } from '../common/domain.config';
import {storeInLocalStorage ,fetchFromLocalStorage} from "../common/common";
import { checkIfApiCallSuccess, creditNoteAPi } from '../common/base.api';

class CreditNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        filter: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
      },
      data: {
        list: [],
        total: 0,
      },
    };
    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }  
  setFilterStateInStorage = () => {
    const { queryParameters} = this.state;
    
    storeInLocalStorage(
      'FILTER',
      {
       queryParameters,
      },
      "CREDIT_NOTE"
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters} =this.state;
    const localData = fetchFromLocalStorage('FILTER', "CREDIT_NOTE");
    this.setState(
      { queryParameters:localData?.queryParameters || queryParameters ,},
      () => {
        this.loadTableData();
      },
    );
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { queryParameters } = this.state;
    const extraQuery = `&jv_transaction_type=[${TRANSACTION_TYPE.CREDIT_NOTE}]`;
    this.setFilterStateInStorage();
    creditNoteAPi.getList(queryParameters, extraQuery).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const { data } = this.state;
        data.list = response.list;
        data.total = response.total;
        this.setState({ data });
      }
    });
  };

  handleFabButtonClick = () => {
    history.push(`/${CREDIT_NOTE_CREATE}`);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  handleClearSearch =() =>{
    const {display}=this.state;
    this.setState({display:{...display,searchBox:false,search:false}});
    this.basePaginationService.clearSearchText();
  }

  handleTableBodyClick = id => {
    const voucherNumber = voucherEncoder(id) || '';
    history.push(`/${CREDIT_NOTE_DETAILS}/${voucherNumber}`);
  };

  render() {
    const { display, queryParameters, data, snack } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <CreditNoteStyled>
        <div className="credit-note">
          <PageView
            domainConfig={CREDIT_NOTE_CONFIG}
            headerConfig={{
              search: true,
              date: true,
              upload: false,
              download: true,
              filter: true,
              create: true,
            }}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            resetFilter={this.basePaginationService.resetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.basePaginationService.handleDownloadClick}
            display={display}
            data={data}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.handleTableBodyClick}
            onPageSelect={this.basePaginationService.onPageSelect}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            createHandler={this.handleFabButtonClick}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.handleClearSearch}
          />

          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </div>
      </CreditNoteStyled>
    );
  }
}

const CreditNoteWithState = withBaseState(CreditNote);

export default CreditNoteWithState;
