import React, { Fragment } from 'react';
import moment from 'moment';
import {
  Button, Cell, Grid, DialogFooter
} from '../../../../components/BillingMDC';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import DatePicker from '../../../../components/DatePicker';

const BatchDialog = ({ batch, validation, onInputChange, onFormCancel, onFormSubmit, batchDialogValidation }) => (
  <Fragment>
    <div className='select-grn'>
      <div className='select-grn-title'>Create New SKU Batch</div>
      <Grid className='grid-padding'>
        <Cell col={4} tablet={8}>
          <BillingTextField
            value={batch.title}
            handleChange={onInputChange}
            floatingLabel='Title'
            param='title'
            required
            emptyField={batchDialogValidation}
          />
        </Cell>

        {/* <Cell col={4} tablet={8}>
          <BillingTextField
            value={batch.rlp}
            handleChange={onInputChange}
            floatingLabel="RLP"
            param="rlp"
            type="number"
            required
            emptyField={batchDialogValidation}
          />
        </Cell> */}

        <Cell col={4} tablet={8}>
          <BillingTextField
            value={batch.dlp}
            handleChange={onInputChange}
            floatingLabel='DLP'
            param='dlp'
            type='number'
            className='right-side-gap'
            required
            emptyField={batchDialogValidation}
            zeroError={false}
          />
        </Cell>
      </Grid>

      <Grid className='grid-padding  date-batch-dialog'>
        <Cell col={12} tablet={12} className='input-select sku-modal-date'>
          <DatePicker
            date={moment(batch.mfgDate)}
            showLabel
            showIcon
            label='Manufactured Date'
            onChange={(e) => onInputChange('mfgDate', e)}
          />
        </Cell>

        <Cell col={12} tablet={12} className='input-select sku-modal-date'>
          <DatePicker
            date={moment(batch.expiryDate)}
            showLabel
            showIcon
            label='Expiry Date'
            onChange={(e) => onInputChange('expiryDate', e)}
          />
        </Cell>
      </Grid>
      {/* <DialogFooter>
        <Button
          accent
          className="dialog-cancel modal-btn"
          onClick={() => {
            onFormCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          accent
          className="dialog-ok modal-btn"
          onClick={() => {
            onFormSubmit();
          }}
        >
          Ok
        </Button>
      </DialogFooter>*/}
    </div>
  </Fragment>
);

export default BatchDialog;
