import { fixedFloatAndCommas } from '../../../utils/conversion';

const getTableData = dataList => {
  let tableDataRows = [];
  let wholeTotalPurchase = 0;
  if (dataList && dataList.length > 0) {
    tableDataRows = dataList?.map((data, index) => {
      wholeTotalPurchase += (data.total_taxable_purchase ?? 0)
      return [
        index + 1,
        data.bill_no.trim() ?? '',
        data.miti ?? '',
        data.invoice_number ?? '',
        data.ledger_name ?? '',
        data.pan_no ?? '',
        data.business_alias ?? 'Miscellaneous',
        data.quantity ?? '0',
        fixedFloatAndCommas(data.tax_exempted_amount) ?? '0',
        fixedFloatAndCommas(data.taxable_purchase_vat) ?? '0',
        fixedFloatAndCommas(data.taxable_purchase_vat) ?? '0',
        fixedFloatAndCommas(data.import_amount) ?? '0',
        fixedFloatAndCommas(data.import_vat) ?? '0',
        fixedFloatAndCommas(data.capitalized_purchase_amount) ?? '0',
        fixedFloatAndCommas(data.capitalized_purchase_vat) ?? '0',
        (JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise) ? fixedFloatAndCommas(data.excise) ?? '0' : '',
      ];
    });
  }
  return {
    tableDataRows,
    wholeTotalPurchase
  };
};

const getFilteredData = (dataList, companyData, middleHeaderData, columnTotal) => {
  const tableData = getTableData(dataList);

  return {
    companyName: companyData?.title ?? '',
    address: companyData?.address ?? '',
    phone: companyData?.phone ?? '',
     panNo: companyData?.panNo || companyData?.panNumber || '',
    email: companyData?.email ?? '',
    title: middleHeaderData?.title ?? '',
    date: middleHeaderData?.date ?? '',
    tableDataRows: tableData?.tableDataRows ?? [],

    tableFooterData: {
      totalItemQuantity: fixedFloatAndCommas(columnTotal?.totalQuantity)?.toString() ?? '0',
      totalTaxExemptedPurchase: fixedFloatAndCommas(columnTotal?.taxExemptedPurchase)?.toString() ?? '0',
      totalTaxableAmount: fixedFloatAndCommas(columnTotal?.wholeTotalPurchase)?.toString() ?? '0',
      totalVat: fixedFloatAndCommas(columnTotal.vat) ?? '0',
      totalExcise: (JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise) ? fixedFloatAndCommas(columnTotal.excise)?.toString() ?? '0' : '',
    },
  };
};

const getPrintData = (dataList, companyData, middleHeaderData, columnTotal) => {
  const ledgerData = getFilteredData(dataList, companyData, middleHeaderData, columnTotal);
  return ledgerData;
};

export default getPrintData;
