import { compose } from 'recompose';

import apiInterceptor from '../../../../api/interceptor';

import SalesReturnDetail from './SalesReturnDetail';
import { srnApi } from '../../../common/base.api';

const composedSalesReturnDetail = compose(
  apiInterceptor({ getDetails: { ...srnApi }, }),
)(SalesReturnDetail);

export default composedSalesReturnDetail;
