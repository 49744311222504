import styled from 'styled-components';

const BillingSelectStyled = styled.div`
  .select-css {
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    .zindex-2__control {
      border: none;
      border-radius: 0;
      min-height: 18px;
    }
    .zindex-2__control--is-focused {
      border: none;
      box-shadow: none;
    }
    .zindex-2__indicator {
      cursor: pointer;
      padding: 0px;
    }
    .zindex-2__value-container {
      padding: 0px 8px;
    }
    .zindex-2__indicator-separator {
      background-color: #fff;
    }
    &.display-inline-table {
      display: inline-table;
      text-align: left;
    }
    .zindex-2__single-value {
      span {
        display: none;
      }
    }
    .zindex-2__menu {
      span {
        font-size: 9px;
        line-height: 9px;
      }
    }
  }
`;
export default BillingSelectStyled;
