
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogTitle,
  DialogFooter,
  Button,
  Grid,
  Cell,
} from '../../../components/BillingMDC';
import BillingTextField from '../../../components/TextField/BillingTextField';
import BillingSelect from '../../../components/Select/BillingSelect';
import { LEDGERS } from '../../../data/enums/enums';
const JvDialogView = (
  {
    onModalClose,
    modalOpen,
    editModalOpen,
    readModalOpen,
    createJV,
    handleInputChange,
    onModalSubmit,
    formEmptyField,
    transactionDisabled,
    tagList,
    renderLedgerSelectOptions,
  },
) => (
  <Dialog
    open={modalOpen || editModalOpen || readModalOpen}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      {editModalOpen && <DialogTitle>Edit Journal Voucher</DialogTitle>}
      {readModalOpen && <DialogTitle>Confirmation Dialog</DialogTitle>}
    </DialogHeader>
    {!readModalOpen && (
      <DialogBody>
        <div className='dialog-upperpart dialog-view'>
          <Grid className='grid-padding'>
            {renderLedgerSelectOptions()}
            <Cell col={4} tablet={8}>
              <BillingTextField
                value={createJV.drAmount}
                handleChange={handleInputChange}
                floatingLabel='Debit'
                param='drAmount'
                type='number'
                required
                zeroError={!transactionDisabled.debit}
                disabled={transactionDisabled.debit}
                className='billing-required right-side-gap'
                emptyField={formEmptyField}
                errorMessage={
                  transactionDisabled.debit
                    ? 'Should not be empty'
                    : createJV.drAmount === 0
                      ? 'Should not be 0'
                      : 'Should not be empty'
                }
              />
            </Cell>
            <Cell col={4} tablet={8}>
              <BillingTextField
                value={createJV.crAmount}
                handleChange={handleInputChange}
                floatingLabel='Credit'
                param='crAmount'
                type='number'
                required
                zeroError={!transactionDisabled.credit}
                className='billing-required right-side-gap'
                emptyField={formEmptyField}
                errorMessage={
                  transactionDisabled.credit
                    ? 'Should not be empty'
                    : createJV.crAmount === 0
                      ? 'Should not be 0'
                      : 'Should not be empty'
                }
                disabled={transactionDisabled.credit}
              />
            </Cell>
            <Cell  col={4} tablet={8}>
              <BillingTextField
                value={createJV.narration}
                param='narration'
                floatingLabel='Narration'
                className='textbox billing-required'
                handleChange={handleInputChange}
              />
            </Cell>
            <Cell col={4} tablet={8} className='input-select'>
              <label>Tag</label>
              <BillingSelect
                name='form-field-name'
                param='tagid'
                clearable={false}
                value={createJV?.tagsId}
                valueKey='idTags'
                labelKey='title'
                options={tagList}
                handleChange={handleInputChange}
              />
            </Cell>
          </Grid>
        </div>
      </DialogBody>
    )}

    {readModalOpen && <div className='dialog-upperpart'>Are you sure you want to progress ?</div>}

    <DialogFooter>
      <Button
        accent
        className='dialog-cancel modal-btn'
        onClick={() => {
          onModalClose();
        }}
      >
        Cancel
      </Button>
      <Button
        accent
        className='dialog-ok modal-btn'
        onClick={() => {
          onModalSubmit();
        }}
      >
        Ok
      </Button>
    </DialogFooter>
  </Dialog>
);

export default JvDialogView;
