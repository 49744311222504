import { compose } from 'recompose';

import PurchaseReturn from './PurchaseReturn';

import apiInterceptor from '../../../api/interceptor';

import { prnApi } from '../../common/base.api';

const composedPurchaseReturn = compose(apiInterceptor({ ...prnApi }))(PurchaseReturn);

export default composedPurchaseReturn;
