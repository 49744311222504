import React from 'react';
import { Icon } from '../../../../components/BillingMDC';
import { ACTION, STOCK_TYPE } from '../../../../data/enums/enums';

const getStockJournalData = (ledger = {}) => {
  const details = {
    brand: '',
    brandTitle: '',
    sku_id: 0,
    skuTitle: '',
    batch_id: '',
    batchTitle: '',
    remarks: '',
    normal: 0,
    damage: 0,
    expiry: 0,
    shortage: 0,
    quantity: 0,
  };

  return details;
};

const apiTransformedSJList = (invoiceList) => invoiceList.map((sku) => {
  const skuList = {
    sku_id: sku.sku_id,
    batch_id: sku.batch_id,
    normal: sku.normal,
    damage: sku.damage,
    expiry: sku.expiry,
    quantity: sku.quantity,
    remarks: sku.remarks,
    shortage: sku.shortage,
  };
  return skuList;
});

const formValidationFieldList = [
  {
    title: 'sku_id',
  },
  {
    title: 'batch_id',
  },
  {
    title: 'remarks',
  }];

const StockLedgerBody = ({ dataList, handleEditIconClick, handleDeleteIconClick }) => (
  <tbody>
    {
    dataList.map((data, index) => (
      <tr>
        <td>
          {index + 1}
        </td>
        <td>{data.skuTitle}</td>
        <td className="left-align">{data.batchTitle}</td>

        <td className="right-align">{data.normal}</td>
        <td className="right-align">{data.damage}</td>
        <td className="right-align">{data.expiry}</td>
        <td className="right-align">{data.quantity}</td>
        <td className="right-align">{data.shortage}</td>
        <td className="left-align">{data.remarks}</td>
        <td className="right-align">
          <Icon name="edit" onClick={() => handleEditIconClick(data, index)} />
          <Icon name="delete" onClick={() => handleDeleteIconClick(index)} />
        </td>
      </tr>
    ))
  }
  </tbody>
);

const customerLedgerConfig = [
  {
    id: 1, label: 'test', title: 'SN', className: null, sortable: false,
  },
  {
    id: 2, label: 'skuTitle', title: 'SKU', className: null, sortable: false,
  },
  {
    id: 3, label: 'batch', title: 'Batch', className: null, sortable: false,
  },
  {
    id: 5, label: 'normal', title: 'Sellable', className: 'right-align', sortable: false,
  },
  {
    id: 6, label: 'damage', title: 'Damage', className: 'right-align', sortable: false,
  },
  {
    id: 7, label: 'expiry', title: 'Expiry', className: 'right-align', sortable: false,
  },
  {
    id: 4, label: 'quantity', title: 'Quantity (pcs)', className: 'right-align', sortable: false,
  },
  {
    id: 8, label: 'shortage', title: 'Shortage', className: 'right-align', sortable: false,
  },
  {
    id: 9, label: 'remarks', title: 'Remarks', className: 'left-align', sortable: false,
  },
  {
    id: 10, label: 'remarks', title: '', className: 'right-align', sortable: false,
  },
];

const config = {
  header: customerLedgerConfig,
  getTableBody: StockLedgerBody,
  getDetail: getStockJournalData,
  formValidationFieldList,
  apiTransformedSJList,
};

export default config;
