import React, { Component } from 'react';
import * as appConstants from '../../config';
import * as httpUtils from '../../../utils/httpUtils';
import { Menu, Icon, Grid, Cell, Button, MenuItem, MenuAnchor, LinearProgress } from 'react-mdc-web';
//import 'react-select/dist/react-select.css';
import TableHeader from '../../../components/TableHeader/TableHeader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { downloadFormat } from './CustomerLedgerConfig';
import history from '../../../utils/history';
import DateToRender from '../../../components/DateToRender/DateToRender';
import { customerLedgerDetailConfig, CustomerLedgerDetailTableBody } from './CustomerLedgerDetailConfig';
import { CUSTOMER_LEDGER_BASE } from '../../../data/enums/Route';

const propTypes = {
  customerDetails: PropTypes.instanceOf(
    PropTypes.shape({
      details: PropTypes.instanceOf(Array),
      businessId: PropTypes.number,
      name: PropTypes.string,
      date: PropTypes.instanceOf(Object),
    }),
  ),
};

const defaultProps = {
  customerDetails: {
    details: [],
    businessId: '',
    name: '',
    date: {},
  },
};

//invoice id is present in the list.
class CustomerLedgerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataSet: props.customerDetails.details,
      sorting: {
        label: '',
        order: 2,
      },
    };
  }

  componentDidMount() {}

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.dataSet;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';
    return orderBy(this.state.dataSet, labelName, sortingName);
  };

  handleCancelClick = () => {
    history.push(`/${CUSTOMER_LEDGER_BASE}`);
  };

  /** handle download click **/
  handleDownloadClick = () => {
    const self = this;
    const schema = 'https://';
    const type = 'CSV';
    const { params, customerDetails } = this.props;
    httpUtils
      .get(
        appConstants.baseUrl +
          `download/detail-ledger-report?start_date=${customerDetails.date.start_date}
      &end_date=${customerDetails.date.end_date}
      &ledger_id=${params.id}
      &bu=${customerDetails.businessId}
      &type=${type}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ loading: false });
          const url = response.data.url;
          const newWin = window.open(schema + window.location.hostname + url);
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            alert('Please enable pop for this site');
          }
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const { loading, sorting } = this.state;
    const { customerDetails } = this.props;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="card-header-bar padding-l-0">
            <div className="">
              <div className="header-menu-left">
                <Icon
                  name="arrow_back"
                  className="menu-icon"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                />
              </div>
              <h2 className="header-menu-right"> Ledger : {this.props.customerDetails.name}</h2>
            </div>
            <div className="header-menu">
              <span className="date-rendered">
                <Icon name="date_range" className="material-icons" />
                <DateToRender date={customerDetails.date} />
              </span>
              <span className="" onClick={() => this.handleDownloadClick()}>
                <Icon name="get_app" />
              </span>
            </div>
          </div>
        </div>
        <div ref="fixedTableBody" className="fixed-table-wrapper">
          <table>
            <TableHeader
              headerDetails={customerLedgerDetailConfig.headerDetails}
              filterHeaderLabel={false}
              handleSorting={this.handleTableSorting}
            />
            {sortedDataSet.map((data, key) => (
              <tbody>
                <CustomerLedgerDetailTableBody data={data} index={key} />
              </tbody>
            ))}
          </table>
        </div>
      </div>
    );
  }
}

CustomerLedgerDetail.contextTypes = {
  router: PropTypes.object,
};

CustomerLedgerDetail.propTypes = propTypes;
CustomerLedgerDetail.defaultProps = defaultProps;

const mapStateToProps = state => {
  return {
    customerDetails: state.customerLedgerDetail || null,
  };
};

const customerLedgerDetail = connect(mapStateToProps)(CustomerLedgerDetail);

export default customerLedgerDetail;
