import styled from 'styled-components';

const LeftDrawerStyled = styled.div`
  background: var(--left-drawer-color);
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: var(--accent-color) transparent;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      background: transparent;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--accent-color);
      height: 25vh;
    }
  }
  .mini-drawer {
    width: 64px !important;
  }
  .mdc-permanent-drawer {
    border-right: none;
    display: flex;
    width: 256px;
    background: var(--left-drawer-color);
  }
  .mdc-permanent-drawer .mdc-list {
    padding: 0;
    padding: 24px 0;
  }
  .mini-drawer .mdc-list p,
  .mdc-permanent-drawer .mdc-list p span,
  .mdc-permanent-drawer .mdc-list p strong {
    padding: 16px 0;
  }
  .mdc-permanent-drawer .mdc-list p:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  .mdc-permanent-drawer .mdc-list .header .bg-accent-color p:hover {
    background-color: #2196f3;
  }
  .mdc-permanent-drawer .mdc-list .header p:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .mini-drawer .mdc-list p,
  .mini-drawer .mdc-list a {
    text-align: center;
  }
  .max-drawer .mdc-list p {
    display: flex;
    flex-wrap: wrap;
  }
  .max-drawer .mdc-list span {
    width: 64px;
    text-align: center;
  }
  .max-drawer.mdc-permanent-drawer .mdc-list strong {
    flex: 1;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
  }
  .mdc-permanent-drawer .mdc-list strong,
  .mdc-permanent-drawer .mdc-list p,
  .mdc-permanent-drawer .mdc-list a {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
    font-weight: var(--medium);
    text-decoration: none;
    line-height: 16px;
    margin: 0;
    cursor: pointer;
  }
  .header:nth-last-of-type(1) {
    border-bottom: 1px solid #dadada;
  }
  .max-drawer .header .header-wrap::before {
    position: absolute;
    right: 15px;
    top: 13px;
    transition: all 0.3s ease-in;
  }
  .max-drawer .header.active .header-wrap::before {
    transition: all 0.3s ease-out;
  }
  .mini-drawer .header .header-wrap p img::before {
    position: absolute;
    right: -1px;
    top: 12px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    transform: rotate(90deg);
  }
  .header {
    border-top: 1px solid #dadada;
    position: relative;
    &.active {
      .header-wrap p {
        background: rgba(218, 218, 218, 0.5);
        border-bottom: 1px solid #dadada;
      }
      & > a p {
        background: rgba(218, 218, 218, 0.2);
      }
    }
  }
  .mdc-permanent-drawer .mdc-list .bg-accent-color strong,
  .mdc-permanent-drawer .mdc-list .bg-accent-color p {
    background-color: #2196f3;
    color: #ffffff;
  }
  .active--tab {
    border-bottom: 2px solid #2196f3;
  }
`;
export default LeftDrawerStyled;
