import React, { Component } from 'react';
import { connect } from 'react-redux';
import tableConfig from './config';
import * as filterUtil from '../../../utils/filterUtil';
import { clone } from '../../../utils/arrayProcessor';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { Grid, Cell, Icon } from '../../../components/BillingMDC';
import TableHeader from '../../../components/TableHeader/TableHeader';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { DAMAGE_EXPIRY_VALUATION_CONFIG } from '../../common/domain.config';
import { stockValuationReportApi } from '../../common/base.api';
import { addIndexInPrintDataSet } from '../../common/common';
import DamageExpiryStyled from './DamageExpiryStyled';
import {groupPrintDataSet} from "../../common/print.service";

class DamageExpiryValuation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          business_id: [],
          catalog_detail_id: [],
        },
      },
      dataList: [],
      data: {
        list: [],
        total: 0,
      },
    };

    const { downloadDamageExpiry } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadDamageExpiry,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    /** tableLayoutService.adjustToScreen(
     this.refs.tableReference,
     this.refs.fixedTableFooter,
     this.refs.fixedTableBody,
     ); */
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;

    getList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.data;
      data.total = response.total;
      this.setState({ data }, () => {
        this.groupPrintDataSetNew();
      });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  handlePrintClick = () => {
    const self = this;
    this.setState({ printButtonClicked: true }, () => {
      window.print();
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false });
    };
  };
  groupPrintDataSetNew() {
    const { data  } = this.state;
    const {company} =this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(data.list);
    const dataList = groupPrintDataSet(orders, printInfoBill, tableConfig);
    this.setState({ dataList });
  }

  render() {
    const { queryParameters, data, display, snack, printButtonClicked, dataList, date } = this.state;
    const { serverResponseWaiting, company } = this.props;
    return (
      <DamageExpiryStyled className="damage-expiry">
        <div className={printButtonClicked ? 'no-print' : 'display-block'}>
          <PageView
            domainConfig={DAMAGE_EXPIRY_VALUATION_CONFIG}
            headerConfig={{
              search: true,
              date: true,
              download: true,
              filter: true,
              create: false,
              print: true,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            resetFilter={this.basePaginationService.resetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.basePaginationService.handleDownloadClick}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            data={data}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onPageSelect={this.basePaginationService.onPageSelect}
            handlePrintClick={this.handlePrintClick}
          />
        </div>

        <div className={printButtonClicked ? 'display-block portrait-type' : 'no-print'}>
          <div>
            {dataList.map((chunk, key) => {
              const { list = [], page = 0 ,remainingLines=[]} = chunk;
              return (
                <div className="print-body zoom-reduce">
                  <div className="pickList-body card-body">
                    <header>
                      <Grid className="flex">
                        <Cell col={2} tablet={2} phone={2} className="left">
                          <div className="header-title">{company.title}</div>
                          <span className="header-title-content">
                            <span className="active-opacity-text">Address: </span>
                            <span>{company.address}</span>
                          </span>
                          <span className="header-title-content">
                            <span className="active-opacity-text">Phone: </span>
                            <span>{company.phone}</span>
                          </span>
                          <span className="no-margin-bottom header-title-content">
                            <span className="active-opacity-text">PAN No: </span>
                            <span>{company.panNumber}</span>
                          </span>
                        </Cell>
                        <Cell col={4} tablet={4} phone={4} className="center">
                          <div className="center-align">
                            <h2>{DAMAGE_EXPIRY_VALUATION_CONFIG.title}</h2>
                            <div className="default-margin-top-3">
                              <span className="header-title-content no-margin-bottom">
                                <span className="active-opacity-text">
                                  Report from
                                  <span className="date-rendered">
                                    {` ${filterUtil.formatToReadableDate(
                                      queryParameters.date.start,
                                    )} - ${filterUtil.formatToReadableDate(queryParameters.date.end)}`}
                                  </span>
                                </span>
                              </span>
                              <span className="header-title-content"></span>
                            </div>
                          </div>
                        </Cell>
                        <Cell col={2} tablet={2} phone={2} className="right">
                          <div className="header-menu-right">
                            <span>
                              <span className="">Date: </span>
                              <span>{filterUtil.formatToReadableDate(date)}</span>
                            </span>
                          </div>
                        </Cell>
                      </Grid>
                    </header>
                    <div className="fixed-table-wrapper">
                      <table className="pdf-table">
                        <TableHeader headerDetails={tableConfig.header} filterHeaderLabel={false} />
                        {tableConfig.getTableBody({ dataList: list,remainingLines: remainingLines})}
                      </table>
                    </div>
                  </div>
                  <div className="footer-block">
                    <PageNumber value={page} totalPage={dataList.length} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </DamageExpiryStyled>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeStockLedger: action => dispatch(action),
  };
}

const DamageExpiryValuationWithState = withBaseState(DamageExpiryValuation);

export default connect(null, mapDispatchToProps)(DamageExpiryValuationWithState);
