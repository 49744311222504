import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Checkbox } from 'react-mdc-web';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const CreateSalesReturnTableBody = ({
  data,
  index,
  selectedCheckBox,
  showCheckBox,
  handleEditIconClick,
  handleDeleteIconClick,
  handleCheckBoxClick,
}) => {
  return (
    <tr>
      <td>
        {!showCheckBox && <span>{index + 1}</span>}
        {showCheckBox && (
          <Checkbox
            checked={selectedCheckBox.includes(data.sales_detail_id)}
            onChange={({ target: { checked } }) => {
              handleCheckBoxClick(data.sales_detail_id, checked, data);
            }}
          />
        )}
      </td>
      <td>{data.skuTitle}</td>
      <td className='right-align'>{data.rate}</td>
      <td className='right-align'>{data.quantity}</td>
      <td className='right-align'>{fixedFloatAndCommas(data.promotion_discount)}</td>
      <td className='right-align'>{fixedFloatAndCommas(data.net_amount)}</td>
      <td className='right-align'>
        <Icon name='edit' onClick={() => handleEditIconClick(data, index)} />
        <Icon name='delete' onClick={() => handleDeleteIconClick(index)} />
      </td>
    </tr>
  );
};

CreateSalesReturnTableBody.defaultProps = {
  selectedCheckBox: [],
  showCheckBox: false,
};

CreateSalesReturnTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  handleDeleteIconClick: PropTypes.func,
  selectedCheckBox: PropTypes.array,
  handleCheckBoxClick: PropTypes.func,
  showCheckBox: PropTypes.bool,
};

const createSalesReturnConfig = {
  headerDetails: [
    { id: 1, label: 'test', title: 'SN', className: null, sortable: false },
    { id: 2, label: 'sku', title: 'SKU', className: null, sortable: false },
    { id: 3, label: 'rate', title: 'Rate', className: 'right-align', sortable: false },
    { id: 4, label: 'quantity', title: 'Quantity (pcs)', className: 'right-align', sortable: false },
    { id: 5, label: 'discount', title: 'Discount', className: 'right-algin', sortable: false },
    { id: 6, label: 'amount', title: 'Amount', className: 'right-align', sortable: false },
    /* {id: 6, label: 'test', title: '', className: null, sortable: false},*/
  ],
  headerTitleToView: ['test', 'sku', 'rate', 'qty', 'promotion', 'discount', 'amount'],
};

const ZERO_FLAG_ERROR_ITEM = {
  QUANTITY: 'quantity',
  RATE: 'rate',
};
export {
  createSalesReturnConfig, CreateSalesReturnTableBody, ZERO_FLAG_ERROR_ITEM
};
