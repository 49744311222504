import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  LinearProgress,
  Fab,
  Button,
  Grid,
  Cell,
  Snackbar,
} from 'react-mdc-web';
import TableHeader from '../../../../components/TableHeader';
import history from '../../../../utils/history';
import tableConfig from './config';
import RadioButtonList from '../../../../components/RadioButtonList/RadioButtonList';
import { STOCK_JOURNAL_BASE } from '../../../../data/enums/Route';
import {
  buApi, catalogsApi, checkIfApiCallSuccess, stockJournalApi,
} from '../../../common/base.api';
import { BillingSnackBar } from '../../../../components/BillingMDC';
import * as snackService from '../../../common/snack.service';
import { handleBatchSelect } from '../../../../utils/handlers';
import LedgerDialog from './Dialog';
import { clone } from '../../../../utils/arrayProcessor';
import { validateForm } from '../../../common/validation';
import { quantityDefaultValues } from '../../../fundamentals/purchaseReturn/create/config';
import { getQuantityValidationStatus } from '../../../common/common';
import StockJournalStyled from '../StockJournalStyled';
import { checkBUId, getIntegratedBUList } from '../../../common/common';
import * as filterUtil from "../../../../utils/filterUtil";

const message = {
  success: 'Stock Journal created successfully.',
  error: '!Error while creating Stock Journal',
};

// invoice id is present in the list.
class CreateStockJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snack: { ...snackService.snackParameters },
      loading: false,
      qtyValidation: false,
      createModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      showMessage: false,
      saveDisable: false,
      success: false,
      batchFormOpen: false,
      batchDialogValidation: true,
      businessUnitId: '',
      message: message.success,
      indexInInvoiceList: 0,
      stockQuantity: quantityDefaultValues,
      createSKU: tableConfig.getDetail(),
      invoiceList: [],
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
      brandList: [],
      skuList: [],
      batchList: [],
      buList: [],
      brandSKUList: [],
      keyValue: '',
      batchDetails: {
        title: '',
        dlp: 0,
        mfgDate: filterUtil.getCurrentDay(),
        expiryDate: filterUtil.getCurrentDay(),
        business_id: 0,
      },
    };
  }

  // after mounted get the customer list.
  componentDidMount() {
    this.getBUList();
  }

  handleBUClick = (id) => {
    this.setState({ businessUnitId: id }, () => {
      this.getBrandList();
    });
  };

  resetStockAndSkuQuantities = () => {
    const { createSKU } = this.state;
    createSKU.normal = 0;
    createSKU.expiry = 0;
    createSKU.shortage = 0;
    createSKU.quantity = 0;
    createSKU.damage = 0;
    this.setState({ stockQuantity: quantityDefaultValues, createSKU });
  }

  onChange = (field, value, detail = {}) => {
    let { createSKU } = this.state;
    createSKU[field] = value;
    createSKU.quantity = createSKU.normal + createSKU.damage + createSKU.expiry;
    if (field === 'brand') {
      this.setState(
        {
          batchList: [],
          keyValue: detail ? detail.businessId : '',
          stockQuantity: 0,
        },
        () => detail && this.getSKUListUnderBrand(value, detail.businessId),
      );
      if (value === '') {
        this.setState({ brandSKUList: [] });
      }
      this.resetSKUDialog();
      this.resetStockAndSkuQuantities();
    }

    if (field === 'sku_id') {
      createSKU.skuTitle = detail.title || '';
      createSKU = {
        ...(handleBatchSelect(createSKU, (detail.batchList.length > 0 && detail.batchList[0]) || {}) || createSKU),
      };
      this.resetStockAndSkuQuantities();
      this.setState({ batchList: detail.batchList || [] }, () => {
        this.getAvailableStock(createSKU.sku_id, createSKU.batch_id,detail.businessId);
      });
    }

    if (field === 'batch_id') {
      createSKU = { ...(handleBatchSelect(createSKU, detail) || createSKU) };
      this.resetStockAndSkuQuantities();
      this.getAvailableStock(createSKU.sku_id, createSKU.batch_id);
    }

    if (field === 'normal' || field === 'damage' || field === 'expiry') {
      createSKU.promotion_discount = 0;
    }

    // update the amount with rate and amount.
    createSKU.amount = Number(Number(createSKU.quantity * createSKU.rate).toFixed(2));
    const discount = Number(createSKU.promotion_discount) + Number(createSKU.trade_discount);
    createSKU.net_amount = Number(Number(createSKU.amount - discount).toFixed(2));

    this.setState({ createSKU });
  };

  checkQuantity = () => {
    const { stockQuantity, createSKU } = this.state;
    const { condition, breakingQuantityLabel } = getQuantityValidationStatus(stockQuantity, createSKU);

    if (!condition) {
      this.setState({
        showMessage: true,
        success: false,
        message: `Should not be greater than available quantity (${breakingQuantityLabel})`,
      });
      return false;
    }
    return true;
  };

  getAvailableStock = (skuId, batchId) => {
    const { businessUnitId, createSKU } = this.state;
    catalogsApi
      .getBatchAvailableStock({ business_id: businessUnitId, sku_id: skuId, batch_id: batchId })
      .then((response) => {
        if (response.data.length > 0) {
          const res = response.data[0];
          const stockQuantity = {
            normal: res.available_quantity ? parseInt(res.available_quantity, 10) : 0,
            damage: res.available_damage ? parseInt(res.available_damage, 10) : 0,
            expiry: res.available_expiry ? parseInt(res.available_expiry, 10) : 0,
            shortage: res.available_shortage ? parseInt(res.available_shortage, 10) : 0,
          };
          createSKU.normal = stockQuantity.normal;
          createSKU.damage = stockQuantity.damage;
          createSKU.expiry = stockQuantity.expiry;
          createSKU.shortage = stockQuantity.shortage;
          createSKU.quantity = createSKU.normal + createSKU.damage + createSKU.expiry;
          this.setState({ stockQuantity, createSKU });
        } else {
         this.resetStockAndSkuQuantities()
          this.setState({
            showMessage: true,
            message: 'SKU stock quantity unknown',
           /* stockQuantity: quantityDefaultValues,*/
            success: false,
          });
        }
      }).catch((err) => {
        this.resetStockAndSkuQuantities();
        this.setState({
          showMessage: true,
          message: '!Error while fetching available stock quantity',
        /*  stockQuantity: quantityDefaultValues,*/
          success:false,
        });
      });
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data, index) => {
    this.setState({
      createSKU: clone(data),
      editModalOpen: true,
      indexInInvoiceList: index,
    });
  };

  // create the sales Return, update the discount value
  createStockJournal = () => {
    const { invoiceList, createSKU } = this.state;
    invoiceList.push(createSKU);
    this.setState({ invoiceList }, () => {
      this.closeModal();
    });
  };

  // editStockJournal
  editStockJournal = (data, index) => {
    const { invoiceList, createSKU } = this.state;
    invoiceList[index] = createSKU;
    this.setState({ invoiceList }, () => {
      this.closeModal();
    });
  };

  // delete the sales Return for particular sku, and update the total value.
  deleteGRNItem = (index) => {
    const { invoiceList, indexInInvoiceList } = this.state;
    if (invoiceList.length >= indexInInvoiceList) {
      invoiceList.splice(indexInInvoiceList, 1);
      this.setState({ invoiceList }, () => {
        this.closeModal();
      });
    }
  };

  // close the modal resetting  all the value
  closeModal = () => {
    this.setState({
      createModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      qtyValidation: false,
    });
    this.resetSKUDialog();
  };

  // reset SKU Dialog
  resetSKUDialog = () => {
    const { validation } = this.state;
    this.setState({ createSKU: tableConfig.getDetail(),skuList:[], validation: { ...validation, flag: false } });
  };

  // handle on modal ok click
  handleModalOkClick = () => {
    const {
      createModalOpen, editModalOpen, createSKU, validation, indexInInvoiceList,
    } = this.state;

    if (createModalOpen || editModalOpen) {
      const valid = validateForm(createSKU, validation, (validStatus) => this.setState({ validation: validStatus }));
      if (valid) {
        if (createModalOpen) {
          this.createStockJournal();
        } else {
          this.editStockJournal(createSKU, indexInInvoiceList);
        }
      }
    } else {
      this.deleteGRNItem();
    }
  };

  directToMainPage = () => {
    const { showMessage, success } = this.state;
    if (!showMessage && success) {
      history.push(`/${STOCK_JOURNAL_BASE}`);
    }
  };

  // get the brand list
  getBrandList = () => {
    const { businessUnitId } = this.state;
    catalogsApi.getBrandList({ business_id: businessUnitId }).then((response) => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ brandList: response.data.list });
      }
    });
  };

  getSKUListUnderBrand = (brandId, businessId) => {
    this.setState({ skuList: [] });
    catalogsApi
      .getSkuUnderBrand({
        business_id: businessId,
        brand_id: brandId,
      })
      .then(response => {
        if (checkIfApiCallSuccess(response)) {
          const list = response.data.list;
          this.setState({ skuList: list, brandSKUList: list });
        }
      });
  };
  getSkuList = async inputValue => {
    const { businessUnitId, buList } = this.state;
    const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);
    const response = await catalogsApi.getSkuList({ business_id: checkBUId(businessUnitId) });
    const filteredBrandList = response.data.list.filter(list => !integratedBUIds.includes(list.businessId));
    const filteredSKUList = filteredBrandList.filter(list =>
      list.title.toLowerCase().includes(inputValue.toLowerCase()),
    );
    this.setState({ skuList: filteredSKUList });
    return filteredSKUList;
  };
  getBrandSkuList = async inputValue => {
    const { brandSKUList } = this.state;
    const filteredSKUList = brandSKUList.filter(list => list.title.toLowerCase().includes(inputValue.toLowerCase()));
    this.setState({ skuList: filteredSKUList });
    return filteredSKUList;
  };

  getBUList = () => {
    buApi.getList().then((response) => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  handleSaveClick = () => {
    const { invoiceList, businessUnitId } = this.state;
    this.setState({ loading: true, saveDisable: true });
    const object = {
      business_id: businessUnitId,
      entered_by: 1,
      skuList: tableConfig.apiTransformedSJList(invoiceList),
    };
    stockJournalApi.create(object)
      .then((response) => {
        this.setState({ loading: false });
        if (response.success) {
          this.setState({
            showMessage: true,
            success: true,
            message: message.success,
            saveDisable: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          showMessage: true,
          success: false,
          message: message.error,
          saveDisable: false,
        });
      });
  };

  handleCancelClick = () => {
    history.push(`/${STOCK_JOURNAL_BASE}`);
  };

  handleDeleteIconClick = (index) => {
    this.setState({ deleteModalOpen: true, indexInInvoiceList: index });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  onBatchFabClick = () => {
    this.resetBatchFormDetail();
    this.setState({
      batchFormOpen: true,
      batchDialogValidation: true,
    });
  };

  onBatchInputChange = (field, value, detail) => {
    const { batchDetails } = this.state;
    batchDetails[field] = value;
    this.setState({ batchDetails });
  };

  resetBatchFormDetail = () => {
    const batchDetails = {
      title: '',
      dlp: 0,
      mfgDate: filterUtil.getCurrentDay(),
      expiryDate: filterUtil.getCurrentDay(),
    };

    this.setState({
      batchDetails,
    });
  };

  onBatchFormSubmit = () => {
    const { batchDetails, createSKU, businessUnitId, batchList, createSku, skuList } = this.state;
    const sku = skuList.find(s => s.businessSkuId === createSKU.sku_id) || {};
    const valid =
        batchDetails.title !== '' &&
        batchDetails.dlp >= 0;

    if (!valid) {
      this.setState({ batchDialogValidation: false });
    } else {
      const batchData = {
        business_id: sku.businessId || 0,
        business_sku_id: createSKU.sku_id,
        title: batchDetails.title,
        dlp: batchDetails.dlp,
        mfg_date: batchDetails.mfgDate,
        expiry_date: batchDetails.expiryDate,
      };

      catalogsApi
          .createSkuBatch(batchData)
          .then(response => {
            const batchResponse = response.data;

            const batch = {
              id: batchResponse.business_batch_id,
              title: batchResponse.title,
              // rlp: batchResponse.rlp,
              dlp: batchResponse.dlp,
              businessBatchId: batchResponse.business_batch_id,
            };
            batchList.push(batch);

            this.setState(
                {
                  batchList,
                  batchFormOpen: false,
                },
                () => {
                  this.onChange('batch_id', batch.businessBatchId, batch);
                },
            );
          })
          .catch(err => {
            throw err;
          });
    }
  };

  onBatchFormCancel = () => {
    this.resetBatchFormDetail();
    this.setState({ batchFormOpen: false });
  };

  render() {
    const {
      loading,
      invoiceList,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      showMessage,
      saveDisable,
      buList,
      createSKU,
      qtyValidation,
      skuList,
      batchList,
      snack,
      brandList,
      businessUnitId,
      message,
      success,
      validation,
      stockQuantity,
      brandSKUList,
      keyValue,
      batchDialogValidation,
      batchDetails,
      batchFormOpen
    } = this.state;
    return (
      <StockJournalStyled>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={`stock-journal-create ${loading ? 'clickable-false' : ''}`}>
          <div className="message-snackbar">
            <Snackbar
              className={success ? 'success-message' : ''}
              timeout={success ? 1000 : 4000}
              onTimeout={() => {
                this.setState({ showMessage: false }, () => {
                  success && this.directToMainPage();
                });
              }}
              open={showMessage}
            >
              {message}
            </Snackbar>
          </div>
          <div className="card-header-bar" ref="tableReference">
            <h2>New Stock Journal</h2>
          </div>
          <div className="card-body">
            <div className="radio-list-wrapper">
              {buList.length && <RadioButtonList data={buList} disableRosiaBu handleBuClick={this.handleBUClick} />}
            </div>
            <div className={businessUnitId ? '' : 'block-overlay'}>
              <div className="table-wrapper">
                <div ref="fixedTableBody" className="fixed-table-wrapper">
                  <table>
                    <TableHeader
                      headerDetails={tableConfig.header}
                      filterHeaderLabel={false}
                      handleSorting={this.handleTableSorting}
                    />
                    {tableConfig.getTableBody({
                      dataList: invoiceList,
                      handleDeleteIconClick: this.handleDeleteIconClick,
                      handleEditIconClick: this.handleEditIconClick,
                    })}
                  </table>
                </div>
                <div className="mini-fab-button">
                  <Fab mini onClick={() => this.setState({ createModalOpen: true })}>
                    <Icon name="add" />
                  </Fab>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="newinvoice-btn-wrapper">
          <Grid>
            <Cell col={8} />
            <Cell col={4} className="right-align">
              <Button
                accent
                className="cancel-btn modal-btn"
                onClick={() => {
                  this.handleCancelClick();
                }}
              >
                Cancel
              </Button>

              <Button
                accent
                className={invoiceList.length ? 'save-btn modal-btn' : 'save-btn modal-btn btn-disabled'}
                disabled={saveDisable}
                onClick={() => {
                  this.handleSaveClick();
                }}
              >
                Save
              </Button>
            </Cell>
          </Grid>
        </div>

        <LedgerDialog
          createModalOpen={createModalOpen}
          editModalOpen={editModalOpen}
          deleteModalOpen={deleteModalOpen}
          createSKU={createSKU}
          skuList={skuList}
          onChange={this.onChange}
          formEmptyField={validation.flag}
          batchList={batchList}
          brandList={brandList}
          qtyValidation={qtyValidation}
          closeModal={this.closeModal}
          handleModalOkClick={this.handleModalOkClick}
          stockQuantity={stockQuantity}
          loadOptions={createSKU.brand === '' ? this.getSkuList : this.getBrandSkuList}
          brandSKUList={brandSKUList}
          keyValue={    keyValue}
          onBatchInputChange={this.onBatchInputChange}
          batchDetails={batchDetails}
          onBatchFormCancel={this.onBatchFormCancel}
          onBatchFormSubmit={this.onBatchFormSubmit}
          batchFormOpen={batchFormOpen}
          onBatchFabClick={this.onBatchFabClick}
          batchDialogValidation={batchDialogValidation}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />

      </StockJournalStyled>
    );
  }
}

CreateStockJournal.contextTypes = {
  router: PropTypes.object,
};

export default CreateStockJournal;
