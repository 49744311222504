import {calculateRelativeDiscount} from "./helpers";
import {fixedFloat} from "../../../utils/conversion";
import {getDiscountedValue} from "../../../utils/handlers";

const resolveDetailList = (invoiceList, billSummary, invoiceIdPresent = false,referenceBillSummary = {}) => {
    const grossInvoiceNetAmount = invoiceList.reduce((acc, item) => acc + item.net_amount, 0);
    let  cashDiscountValue = billSummary.cashDiscount;
    let tradeDiscountValue = billSummary.tradeDiscount;
    if (!invoiceIdPresent) {
        cashDiscountValue = getDiscountedValue(
            grossInvoiceNetAmount,
            billSummary.cashDiscountParam,
            billSummary.cashDiscount,
        );

        const tradeDiscountAmountRef =
            billSummary.tradeDiscountParam === 'per'
                ? grossInvoiceNetAmount - cashDiscountValue
                : billSummary.invoiceGrossValue;

        tradeDiscountValue = getDiscountedValue(
            tradeDiscountAmountRef,
            billSummary.tradeDiscountParam,
            billSummary.tradeDiscount,
        );
    }else{
        cashDiscountValue = referenceBillSummary.cashDiscount || 0;
        tradeDiscountValue = referenceBillSummary.tradeDiscount || 0;
    }
    billSummary.cashDiscountValue = cashDiscountValue;
    billSummary.tradeDiscountValue = tradeDiscountValue;
    return invoiceList.map((invoice) => {
        const bill_discount = calculateRelativeDiscount(grossInvoiceNetAmount, cashDiscountValue, invoice.net_amount);
        const trade_discount = calculateRelativeDiscount(grossInvoiceNetAmount, tradeDiscountValue, invoice.net_amount);
        let taxable_amount = invoice.net_amount - (bill_discount || 0) - (trade_discount || 0);
        const excise = Number(invoice.excise_percent) * Number(taxable_amount) / 100;
        taxable_amount = taxable_amount + excise;
        const vat = Number(Number((invoice.vat_percent / 100) * taxable_amount).toFixed(2)) || 0;
        const tax_exempted_amount = vat === 0 ? taxable_amount - vat : 0;
        return {
            ...invoice,
            excise,
            bill_discount: bill_discount || 0,
            trade_discount: trade_discount || 0,
            taxable_amount: taxable_amount || 0,
            vat: Number(Number((invoice.vat_percent / 100) * taxable_amount).toFixed(2)) || 0,
            tax_exempted_amount,
        };
    });
};

const resolveSingleDetail = (createSKU, invoiceIdPresent = false) => {
    createSKU = convertCreateSKUToNumber(createSKU);
    const isDiscountInRs = createSKU.discount_label === 'rs';
    createSKU.amount = fixedFloat(createSKU.quantity * createSKU.rate);
    createSKU.promotion_discount = invoiceIdPresent ? createSKU.promotion_discount : isDiscountInRs ? Number(createSKU.discount_input) : fixedFloat(createSKU.discount_input * createSKU.amount * 0.01);
    createSKU.net_amount = fixedFloat(createSKU.amount - createSKU.promotion_discount);
    if (createSKU.freeSku) {
        createSKU.net_amount = 0;
        createSKU.discount_input = createSKU.amount
        createSKU.promotion_discount = createSKU.amount
    }
    createSKU.excise = fixedFloat((createSKU.excise_percent || 0) * 0.01 * createSKU.net_amount) || 0;
    createSKU.vat = fixedFloat(createSKU.net_amount + createSKU.excise * createSKU.vat_percent * 0.01)
    return createSKU;
}


const resolveBillSummary = (updatedInvoiceList, previousBillSummary) => {
    let billSummary = resetBillSummary(previousBillSummary)
    for (let invoiceItem of updatedInvoiceList) {
        billSummary.amount += Number(invoiceItem.amount);
        billSummary.promotionDiscountValue += invoiceItem.promotion_discount || invoiceItem.discount || 0;
        billSummary.cashDiscountValue += invoiceItem.bill_discount;
        billSummary.tradeDiscountValue += invoiceItem.trade_discount
        billSummary.taxExemptedAmount += invoiceItem.tax_exempted_amount
        billSummary.excise += invoiceItem.excise;
        billSummary.vatAmount += invoiceItem.vat;
    }
    billSummary.cashDiscountValue = previousBillSummary.cashDiscountValue;
    billSummary.tradeDiscountValue = previousBillSummary.tradeDiscountValue;
    billSummary.invoiceGrossValue = billSummary.amount - billSummary.promotionDiscountValue;
    billSummary.taxableAmount = billSummary.invoiceGrossValue - billSummary.cashDiscountValue - billSummary.tradeDiscountValue+ Number(Number(billSummary.excise));
    billSummary.actualTaxableAmount = billSummary.taxableAmount - (billSummary.taxExemptedAmount || 0)
    billSummary.invoiceNetValue = billSummary.taxableAmount + billSummary.vatAmount
    billSummary.cashDiscountParam = previousBillSummary.cashDiscountParam
    billSummary.tradeDiscountParam = previousBillSummary.tradeDiscountParam
    billSummary.cashDiscount = previousBillSummary.cashDiscount
    billSummary.tradeDiscount = previousBillSummary.tradeDiscount
    return billSummary;
}

const resetBillSummary = (previousBillSummary) => {
    return {
        ...previousBillSummary,
        amount: 0,
        promotionDiscountValue: 0,
        cashDiscountValue: 0,
        tradeDiscountValue: 0,
        taxExemptedAmount: 0,
        excise: 0,
        vatAmount: 0,
        actualTaxableAmount: 0,
        invoiceGrossValue: 0,
        invoiceNetValue: 0
    }
}

const convertCreateSKUToNumber = (createSKU) => {
    const attributeToConvertNumber = [
        'rate',
        'quantity',
        'normal',
        'damage',
        'expiry',
        'shortage',
        'amount',
        'promotion_discount',
        'bill_discount',
        'trade_discount',
        'net_amount',
        'excise',
        'vat_percent',
        'vat',
        'excise_percent'
    ]
    for (let attribute of attributeToConvertNumber) {
        if(createSKU && createSKU.hasOwnProperty(attribute)){
            createSKU[attribute] = +createSKU[attribute] || 0
        }
    }
    return createSKU;
}


// const switchUom = (field, value, )=>{
//     createSKU.uom = {
//         value: 'pcs',
//         pcs: 1,
//         label: 'Pcs',
//     };
//     createSKU[field] = 1;
//     createSKU.maxValue = createSKU.remainingQuantity / createSKU.uom.pcs;
//     createSKU.compare_quantity=createSKU.remainingQuantity / createSKU.uom.pcs;
//     createSKU.rate = createSKU.rlp * createSKU.uom.pcs;
//     createSKU.quantity = Number(
//         (createSKU.normal + createSKU.expiry + createSKU.damage).toFixed(2),
//     );
//     createSKU.amount = Number(Number(createSKU.quantity * createSKU.rate).toFixed(2));
//
// }


export {
    resolveDetailList,
    resolveSingleDetail,
    resolveBillSummary,
    resetBillSummary

}