import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import './sku.scss';

import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';

import { ACTION } from '../../../data/enums/enums';
import DatePicker from '../../../components/DatePicker';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import SampleFileDownload from '../../common/dialog/SampleFileDownload';
import { SKU_CONFIG } from '../../common/domain.config';

const SKUBatchDialog = ({
  sku,
  batch,
  actionType,
  onModalClose,
  onModalSubmit,
  onInputChange, // onChange
  handleFileUpload,
  fileRef,
  validation,
  buList,
  brandList,
  skuList,
}) => (
  <Dialog
    open={actionType !== ACTION.NULL}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      <DialogTitle>
        {actionType}
        {actionType === ACTION.UPLOAD ? <span> SKU</span> : <span> Batch</span>}
      </DialogTitle>
    </DialogHeader>

    <DialogBody>
      <div className='dialog-upperpart'>
        {actionType === ACTION.UPLOAD && (
          <Grid className='grid-padding upload'>
            <Cell col={6} tablet={8}>
              <label>Business Unit</label>
              <BillingSelect
                name='form-field-name'
                param='businessId'
                clearable={false}
                value={sku.businessId}
                valueKey='businessId'
                labelKey='businessAlias'
                options={buList}
                handleChange={onInputChange}
                required
                emptyField={validation.flag}
              />
            </Cell>
            <Cell col={6} table={8} className='file-upload'>
              <input
                type='file'
                name='resume'
                className='input-file-upload'
                ref={(ref) => (fileRef = ref)}
                onChange={(e) => handleFileUpload(e)}
              />
            </Cell>
          </Grid>
        )}
        {actionType !== ACTION.UPLOAD && (
          <div>
            <Grid className='grid-padding'>
              <Cell col={4} tablet={8} className='input-select'>
                <label>Business Unit</label>
                <BillingSelect
                  name='form-field-name'
                  param='businessId'
                  clearable={false}
                  value={batch.businessId}
                  valueKey='businessId'
                  labelKey='businessAlias'
                  options={buList}
                  handleChange={onInputChange}
                  required
                  emptyField={validation.flag}
                />
              </Cell>

              <Cell col={4} tablet={8} className='input-select'>
                <label>Brand</label>
                <BillingSelect
                  name='form-field-name'
                  param='brand'
                  clearable={false}
                  value={batch.brand}
                  valueKey='businessCatalogDetailId'
                  labelKey='title'
                  options={brandList}
                  emptyField={validation.flag}
                  handleChange={onInputChange}
                  required
                />
              </Cell>

              <Cell col={4} tablet={8} className='input-select'>
                <label>SKU</label>
                <BillingSelect
                  name='form-field-name'
                  param='businessSkuId'
                  clearable={false}
                  valueKey='businessSkuId'
                  labelKey='title'
                  options={skuList}
                  emptyField={validation.flag}
                  value={batch.businessSkuId}
                  handleChange={onInputChange}
                  required
                  multipleParam
                />
              </Cell>
            </Grid>

            <Grid className='grid-padding'>
              <Cell col={4} tablet={8}>
                <BillingTextField
                  value={batch.title}
                  handleChange={onInputChange}
                  floatingLabel='Title'
                  param='title'
                  required
                  emptyField={validation.flag}
                />
              </Cell>

              <Cell col={4} tablet={8}>
                <BillingTextField
                  value={batch.rlp}
                  handleChange={onInputChange}
                  floatingLabel='RLP'
                  param='rlp'
                  type='number'
                  required
                  emptyField={validation.flag}
                />
              </Cell>

              <Cell col={4} tablet={8}>
                <BillingTextField
                  value={batch.dlp}
                  handleChange={onInputChange}
                  floatingLabel='DLP'
                  param='dlp'
                  type='number'
                  className='right-side-gap'
                  required
                  emptyField={validation.flag}
                />
              </Cell>
            </Grid>

            <Grid className='grid-padding padding-top-24'>
              <Cell col={6} tablet={8} className='input-select sku-modal-date'>
                <DatePicker
                  date={moment(batch.mfgDate)}
                  showLabel
                  showIcon
                  label='Manufactured Date'
                  onChange={(e) => onInputChange('mfgDate', e)}
                />
              </Cell>

              <Cell col={6} tablet={8} className='input-select sku-modal-date'>
                <DatePicker
                  date={moment(batch.expiryDate)}
                  showLabel
                  showIcon
                  label='Expiry Date'
                  onChange={(e) => onInputChange('expiryDate', e)}
                />
              </Cell>
            </Grid>
          </div>
        )}
      </div>
      {actionType === ACTION.UPLOAD && <SampleFileDownload domain={SKU_CONFIG.domain} />}
    </DialogBody>
    <DialogFooter>
      <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
        Cancel
      </Button>
      <Button
        accent
        disabled={actionType === ACTION.UPLOAD && !sku.file}
        className='dialog-ok modal-btn'
        onClick={() => {
          onModalSubmit();
        }}
      >
        Save
      </Button>
    </DialogFooter>
  </Dialog>
);

export default SKUBatchDialog;
