import { compose } from 'recompose';
import apiInterceptor from '../../../api/interceptor';
import NewPurchaseReturnVatReport from './NewPurchaseReturnVatReport';
import { newPurchaseVatReturnApi } from '../../common/base.api';

const composedNewPurchaseReturnVatReport = compose(apiInterceptor({ ...newPurchaseVatReturnApi }))(
  NewPurchaseReturnVatReport,
);

export default composedNewPurchaseReturnVatReport;
