import { compose } from 'recompose';

import DataBaseLog from './DataBaseLog';

import apiInterceptor from '../../../api/interceptor';

import { databaseApi } from '../../common/base.api';

const composedDataBaseLog = compose(apiInterceptor({ ...databaseApi }))(DataBaseLog);

export default composedDataBaseLog;
