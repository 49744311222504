import styled from 'styled-components';

const ResetPWStyled = styled.div`
  &.form {
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    & > .copyright,
    & > h3,
    .form__logo {
      display: inline-block;
      margin: 0 auto;
      img {
        height: 63px;
        width: 76px;
      }
    }
    .form__logo {
      margin-top: 5vh;
    }
    & > h3 {
      margin: 4vh auto;
      font-size: 20px;
      font-weight: 24px;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
      strong {
        font-weight: 600;
      }
    }
    .form__wrapper {
      width: 445px;
      margin: 0 auto;
      background-color: #ffffff;
    }
    .form__header {
      padding: 24px;
      h3 {
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
    .form__body {
      padding: 0 24px 20px 24px;
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      .mdc-textfield {
        height: 80px !important;
        max-width: unset !important;
      }
    }
    .form__footer {
      padding: 8px 0 8px 24px;
      display: flex;
      justify-content: flex-end;
      .dialog-ok {
        margin-right: 0;
      }
    }
    .copyright {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.38);
      line-height: 16px;
      font-weight: 500;
      margin-top: 22vh;
    }
  }
  .message-snackbar .mdc-snackbar--active {
    bottom: 3%;
    left: 40px;
  }
`;
export default ResetPWStyled;
