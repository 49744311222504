import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import tableConfig from './config';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as queryService from '../../common/query.service';
import { NETSALES_REPORT_CONFIG } from '../../common/domain.config';


const propTypes = {
  onLedgerRowClick: PropTypes.func.isRequired,
};
const defaultProps = {};

class NetSalesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          business_id_thirdParty: [],
        },
      },
      data: {
        list: [],
        total: 0,
      },
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;

    getList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total ? response.total : response.data.length;
      this.setState({ data });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleTableBodyClick = data => {
    const { queryParameters } = this.state;
    const { onLedgerRowClick } = this.props;
    
  };

  render() {
    const { queryParameters, data, display } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <div className="ledger-report">
        <PageView
          domainConfig={NETSALES_REPORT_CONFIG}
          headerConfig={{
            search: false,
            date: true,
            download: true,
            filter: true,
            create: false,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.basePaginationService.resetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleDownloadClick={this.basePaginationService.handleDownloadClick}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleTableBodyClick}
          onPageSelect={this.basePaginationService.onPageSelect}
          handleFilterChange={this.basePaginationService.handleFilterChange}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLedgerRowClick: action => dispatch(action),
  };
}

NetSalesReport.protoTypes = propTypes;
NetSalesReport.defaultProps = defaultProps;

const NetSalesReportWithState = withBaseState(NetSalesReport);

export default connect(null, mapDispatchToProps)(NetSalesReportWithState);
