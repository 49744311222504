import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Cell, Grid, Button
} from 'react-mdc-web';
import ErrorStyled from './ErrorStyled';
import ErrorPageData from './data';
import history from '../../utils/history';

const propTypes = { value: PropTypes.number, };

const defaultProps = { value: '', };

const Error = ({ ...props }) => {
  const { value } = props;
  const error = ErrorPageData.filter((index) => index.value === value).map((index) => (
    <div key={index.value}>
      <div className='error-type'>{index.value}</div>
      <div className='error-message'>{index.name}</div>
      <div className='message'>
        <div>{index.message1}</div>
        <div>{index.message2}</div>
      </div>
    </div>
  ));
  const image = value === 404 ? value : 500;
  return (
    <ErrorStyled>
      <div className='error-wrap'>
        <div className='error-container'>
          <img src={`/error_${image}.svg`} alt='Error Illustrations' />
          {error}
          <div className='newinvoice-btn-wrapper '>
            <Grid>
              <Cell col={3} />
              <Cell col={4} className='center-align'>
                <Button accent className='save-btn modal-btn' onClick={() => history.goBack()}>
                  Back
                </Button>
              </Cell>
            </Grid>
          </div>
        </div>
      </div>
    </ErrorStyled>
  );
};

Error.propTypes = propTypes;
Error.defaultProps = defaultProps;

export default Error;
