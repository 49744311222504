import { compose } from 'recompose';

import apiInterceptor from '../../../../api/interceptor';

import GRNDetails from './Detail';

import { grnApi } from '../../../common/base.api';

const composedGRNDetails = compose(apiInterceptor({ ...grnApi }))(GRNDetails);

export default composedGRNDetails;
