import styled from 'styled-components';
const StockValuationStyled = styled.div`
  &.stock-value-report {
    .view-by-wrap ul{
      flex-direction: column;
    }
    .mdc-form-field>label{
      margin-left:unset;
    }
  }
  @media print{
    &.stock-value-report {
      .portrait-type{
        .print-body{
          height: 100% !important;
        padding:0 !important;
        margin:0.28in 0.3in !important;
        page-break-after: always;
        .mdc-layout-grid__inner{
          display:flex;
        }
        .mdc-layout-grid__cell{
          width:32.2%;
          overflow:hidden;
        }
          .fixed-table-wrapper{
            height:1420px !important;
            table{
              height:100%;
              .blank-tr{
                height:24px !important;
                border:0;
              }
              thead{
                th{
                  font-size: 14px !important;
                }
              }
              tbody{
                td{
                  font-size: 14px !important;
                  width:60px !important;
                  min-width:60px !important;
                  max-width:60px !important;
                  &.title{
                    width:270px !important;
                    min-width:270px !important;
                    max-width:270px !important;
                    white-space:normal !important;
                  }
                  &:last-child{
                    font-weight:500;
                  }
                }
              }
            }
          }
          .footer-block{
            position:unset;
          }
        }
      }
    }
  }
  @media print and (max-width:5.83in) {
    &.stock-value-report .portrait-type .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
          height:1405px !important;
        }
  }
}
`;
export default StockValuationStyled;
