import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  selectedDates: PropTypes.func,
  orientation: PropTypes.string,
};

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      defaultValue: '',
    };
  }

  componentDidMount() {
    this.setState({ defaultValue: this.props.defaultValue ? this.props.defaultValue : '', });
  }

  render() {
    const { showOptions, defaultValue } = this.state;
    const { list, onChange, orientation } = this.props;
    return (
      <div
        className='custom-select-wrapper'
        onClick={() => this.setState({ showOptions: !showOptions })}
        tabIndex='0'
        onBlur={() => this.setState({ showOptions: false })}
      >
        <div className='custom-select'>
          <div className='custom-select-option'>{defaultValue}</div>
        </div>
        {this.state.showOptions ? (
          <ul
            className={orientation === 'top' ? 'custom-options-list-top' : 'custom-options-list-bottom'}
            ref='customSelect'
          >
            {list &&
              list.map((each, key) => (
                <li
                  key={key}
                  onClick={() => {
                    this.setState({ showOptions: false, defaultValue: each.value });
                    onChange({ target: { value: each.value } });
                  }}
                >
                  {each.label}
                </li>
              ))}
          </ul>
        ) : null}
      </div>
    );
  }
}

CustomSelect.propTypes = propTypes;

export default CustomSelect;
