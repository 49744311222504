import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';
import moment from 'moment';

const DatabaseLogTableBody = ({ data, index }) => {
  return (
    <tr>
      <td className='left-align'>{index + 1}</td>
      <td className='left-align'>{data.userid}</td>
      <td className='left-align'>{data.dbid}</td>
      <td className='left-align'>{data.queryid}</td>
      <td className='left-align'>{data.query}</td>
      <td className='left-align'>{data.calls}</td>
      <td className='left-align'>{data.total_time}</td>
      <td className='left-align'>{data.min_time}</td>
      <td className='left-align'>{data.max_time}</td>
      <td className='left-align'>{data.mean_time}</td>
      <td className='left-align'>{data.stddev_time}</td>
      <td className='left-align'>{data.rows}</td>
      <td className='left-align'>{data.shared_blks_hit}</td>
      <td className='left-align'>{data.shared_blks_read}</td>
      <td className='left-align'>{data.shared_blks_dirtied}</td>
      <td className='left-align'>{data.shared_blks_written}</td>
      <td className='left-align'>{data.local_blks_hit}</td>
      <td className='left-align'>{data.local_blks_read}</td>
      <td className='left-align'>{data.local_blks_dirtied}</td>
      <td className='left-align'>{data.local_blks_written}</td>
      <td className='left-align'>{data.temp_blks_read}</td>
      <td className='left-align'>{data.temp_blks_written}</td>
      <td className='left-align'>{data.blk_read_time}</td>
      <td className='left-align'>{data.blk_write_time}</td>
    </tr>
  );
};

DatabaseLogTableBody.propTypes = { data: PropTypes.object.isRequired, };

const databaseLogConfig = {
  headerDetails: [
    { id: 24, label: 'sno', title: 'S.No', className: 'cursor-pointer left-align', sortable: true },
    { id: 1, label: 'userid', title: 'User ID', className: 'cursor-pointer left-align', sortable: true },
    { id: 2, label: 'dbid', title: 'DB ID', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'queryid', title: 'Query ID', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'query', title: 'Query', className: 'cursor-pointer', sortable: true },
    { id: 5, label: 'calls', title: 'Calls', className: 'cursor-pointer', sortable: true },
    { id: 6, label: 'total_time', title: 'Total Time', className: 'cursor-pointer', sortable: true },
    { id: 7, label: 'min_time', title: 'Min Time', className: 'cursor-pointer', sortable: true },
    { id: 8, label: 'max_time', title: 'Max Time', className: 'cursor-pointer', sortable: true },
    { id: 9, label: 'mean_time', title: 'Mean Time', className: 'cursor-pointer', sortable: true },
    { id: 10, label: 'stddev_time', title: 'Stddev Time', className: 'cursor-pointer', sortable: true },
    { id: 11, label: 'rows', title: 'Rows', className: 'cursor-pointer', sortable: true },
    { id: 12, label: 'shared_blks_hit', title: 'Shared Blks Hit', className: 'cursor-pointer', sortable: true },
    { id: 13, label: 'shared_blks_read', title: 'Shared Blks Read', className: 'cursor-pointer', sortable: true },
    { id: 14, label: 'shared_blks_dirtied', title: 'Shared Blks Dirtied', className: 'cursor-pointer', sortable: true },
    {
      id: 15,
      label: 'shared_blks_written',
      title: 'Shared Blks  Written',
      className: 'cursor-pointer',
      sortable: true,
    },
    { id: 16, label: 'local_blks_hit', title: 'Local Blks Hit', className: 'cursor-pointer', sortable: true },
    { id: 17, label: 'local_blks_read', title: 'Local Blks Read', className: 'cursor-pointer', sortable: true },
    { id: 18, label: 'local_blks_dirtied', title: 'Local Blks Dirtied', className: 'cursor-pointer', sortable: true },
    { id: 19, label: 'local_blks_written', title: 'Local Blks Written', className: 'cursor-pointer', sortable: true },
    { id: 20, label: 'temp_blks_read', title: 'Temp Blks Read', className: 'cursor-pointer', sortable: true },
    { id: 21, label: 'temp_blks_written', title: 'Temp Blks Written', className: 'cursor-pointer', sortable: true },
    { id: 22, label: 'blk_read_time', title: 'Blk Read Time', className: 'cursor-pointer', sortable: true },
    { id: 23, label: 'blk_write_time', title: 'Blk Write Time', className: 'cursor-pointer', sortable: true },
  ],
};

export { databaseLogConfig, DatabaseLogTableBody };
