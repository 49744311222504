import React from 'react';
import Download from '../../common/Download';
import {
  Grid, Cell, Icon, Textfield
} from '../../../components/BillingMDC';
import BillingSelect from '../../../components/Select/BillingSelect';

const Header = ({
  domainConfig,
  slabValue,
  slabList,
  queryParameters,
  onSlabChange,
  onDownloadClick,
  reportDate,
  onDrawerIconClick,
  clearSearchText,
  handleSearchChange,
  display,
  controlDisplay,
}) => {
  return (
    <div>
      <div className='header-bar'>
        <Grid>
          <Cell col={2} className='input-select'>
            <label>Stock Ageing Slab</label>
            <BillingSelect
              name='form-field-name'
              clearable={false}
              value={slabList.filter((list) => list.value === slabValue)}
              getOptionLabel={({ title }) => title}
              getOptionValue={({ value }) => value}
              options={slabList}
              onChange={(e) => onSlabChange('slab_no', e, 'value')}
            />
          </Cell>
          <Cell col={2} />
          <Cell col={4}>
            <div className='center-align'>
              <h2>Stock Ageing Report</h2>
              <span className='header-title-content'>
                <span className='active-opacity-text'>
                  As on Date:{' '}
                  <span className='date-rendered'>
                    <span>{reportDate}</span>
                  </span>
                </span>
              </span>
            </div>
          </Cell>
          <Cell col={4}>
            <div className='header-menu-right'>
              <div className='header-menu-left'>
                <span className='search-icon-wrapper' onClick={() => controlDisplay('searchBox', !display.searchBox)}>
                  <Icon name='search' className='search-icon material-icons' />
                </span>
                <span className={`top-search-input-wrapper ${display.searchBox ? 'input-active' : 'input-inactive'}`}>
                  {display.searchBox && (
                    <Textfield
                      value={queryParameters.search}
                      onChange={(e) => {
                        handleSearchChange(e.target.value);
                      }}
                      autoFocus
                    />
                  )}
                  {queryParameters.search && (
                    <Icon name='close' className='cross-icon material-icons' onClick={() => clearSearchText()} />
                  )}
                </span>
              </div>
              <Download config={domainConfig} onClick={onDownloadClick} />
              <span onClick={() => onDrawerIconClick('filter', true)}>
                <Icon name='filter_list' />
              </span>
            </div>
            <div className='header-menu-right'></div>
          </Cell>
        </Grid>
      </div>
    </div>
  );
};

export default Header;
