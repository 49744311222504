const getConfigData = footerData => {
  const columns = [
    [
      { content: 'SN', rowSpan: 2 },
      { content: 'Purchase Invoice No', rowSpan: 2 },
      { content: 'Miti', rowSpan: 2 },
      { content: 'Bill No.', rowSpan: 2 },
      { content: 'Vendor Name', rowSpan: 2 },
      { content: 'PAN No.', rowSpan: 2 },
      { content: 'Item Name', rowSpan: 2 },
      { content: 'Total Quantity', rowSpan: 2 },
      { content: 'Tax Exempted', rowSpan: 2 },
      { content: 'Total Purchase', rowSpan: 2 },
      { content: 'Purchase VAT', rowSpan: 2 },
      { content: 'Import', colSpan: 2, styles: { halign: 'center' } },
      { content: 'Capitalized', colSpan: 2, styles: { halign: 'center' } },
    ],
    [{ content: 'Amount' }, { content: 'VAT' }, { content: 'Amount' }, { content: 'VAT' }],
  ];

  JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && columns[0].push({ content: 'Excise',rowSpan: 2 });


  const footer = [
    [
      '',
      'Total',
      '',
      '',
      '',
      '',
      '',
      { content: footerData?.totalItemQuantity ?? '', styles: { lineWidth: 0.1 }, rowSpan: 2 },
      { content: footerData?.totalTaxExemptedPurchase ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalTaxableAmount ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalVat ?? '', styles: { lineWidth: 0.1 } },
      '',
      '',
      '',
      '',
      { content: footerData?.totalExcise ?? '', styles: { lineWidth: 0.1 } },
    ],
  ];

  const columnStyles = {
    0: { cellWidth: 10 },
    1: { cellWidth: 31},
    2: { cellWidth: 15 },
    3: { cellWidth: 24 },
    5: { cellWidth: 15 },
  };

  const cellsToAlignRight = { 8: '8', 9: '9', 10: '10', 11: '11', 12: '12', 13: '13', 14: '14', 15: '15', 16: '16' };

  return {
    columns,
    footer,
    columnStyles,
    cellsToAlignRight,
  };
};

export default getConfigData;
