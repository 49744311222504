import { compose } from 'recompose';

import Payment from './Payment';

import apiInterceptor from '../../../api/interceptor';

import { paymentApi ,salesInvoiceApi } from '../../common/base.api';

const composedReceipt = compose(apiInterceptor({ ...paymentApi,agentDataList:salesInvoiceApi?.getAgentList, }))(Payment);

export default composedReceipt;
