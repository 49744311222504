import { compose } from 'recompose';

import ContraEntry from './ContraEntry';

import apiInterceptor from '../../api/interceptor';

import { jvApi } from '../common/base.api';

const composedContraEntry = compose(apiInterceptor({ ...jvApi }))(ContraEntry);

export default composedContraEntry;
