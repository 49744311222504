import React, { Component } from 'react';
import * as queryService from '../../common/query.service';
import history from '../../../utils/history';
import * as httpUtils from '../../../utils/httpUtils';
import { SRN_CREATE, SRN_DETAILS } from '../../../data/enums/Route';
import * as tableLayoutService from '../../common/tableLayout';
import * as appConfig from '../../../config';
import { SRN_CONFIG } from '../../common/domain.config';
import Loading from '../../../common/Loading';
import { connect } from 'react-redux';
import DateToRender from '../../../components/DateToRender/DateToRender';
import Download from '../../common/Download';
import { srnHeader, SRNTableBody } from './config';
import TableHeader from '../../../components/TableHeader/TableHeader';
import PageHeaderStyled from '../../common/pagination/PageHeaderStyled';
import DrawerWithFilter from '../../common/DrawerFilter';
import { Icon, Textfield, Fab } from '../../../components/BillingMDC';
import * as downloadService from '../../common/download.service';
import Pagination from '../../../components/Pagination/Pagination';
import CreateBtn from '../../common/pagination/CreateBtn/CreateBtn';
import { voucherEncoder } from '../../../utils/miscellaneous';
import { changeBUFilterQueryTitle } from '../../common/common';
import {storeInLocalStorage ,fetchFromLocalStorage} from "../../common/common";
import SRNStyled from './SRNStyled';
const propTypes = {};
const defaultProps = {};

class SalesReturn extends Component {
  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);
  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };
  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    this.setFilterStateInStorage();
    getList(changeBUFilterQueryTitle(queryParameters)).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total;
      this.setState({ data });
    });
  };
  handleResetFilter =()=>{
    this.queryService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        business_id_thirdParty: [],
      },
    });
  }
  onPageSelect = (page, limit) => this.queryService.onPageSelect(page, limit);
  handleDateRangeChange = (start, end) => this.queryService.handleDateRangeChange(start, end);
  handleDateChange = date => this.queryService.handleDateRangeChange(date);
  handleFilterChange = (field, list) => this.queryService.handleFilterChange(field, list);
  handleTableSorting = (label, order) => this.queryService.handleTableSorting(label, order);
  handleSearchChange = value => this.queryService.handleSearchInputChange(value);
  handleDownloadClick = (type, label) => {
    const { queryParameters } = this.state;
    const { downloadList, detailDownloadList } = this.props;
    // renaming key ,business_id_thirdParty to business_id
    queryParameters.filter['business_id'] = queryParameters.filter['business_id_thirdParty'];
    delete queryParameters.filter['business_id_thirdParty'];

    if (label === 'sales_return_details') {
      detailDownloadList({
        type,
        query: queryParameters,
      }).then(response => downloadService.resolver(response));
    } else {
      downloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response));
    }
  };
  handleFabButtonClick = () => history.push(`/${SRN_CREATE}`);
  onSrnItemClick = data => {
    const encodedVoucherNo = voucherEncoder(data.voucherNumber);
    history.push(`/${SRN_DETAILS}/${encodedVoucherNo}`);
  };
  afterPrint = userId => {
    const self = this;
    const { printDetails } = this.state;
    printDetails.printedBy = userId;

    window.onafterprint = function () {
      self.setState({ startPrint: false });
      httpUtils
        .post(`${appConfig.baseUrl}print`, printDetails)
        .then(response => {
          if (response.success) {
            window.location.reload();
          }
        })
        .catch(error => {
          {
            console.log('error', error);
          }
        });
    };
  };
  handleClearSearch =() =>{
    const {display}=this.state;
    this.setState({display:{...display,searchBox:false,search:false}});
    this.queryService.clearSearchText();
  }
  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  constructor(props) {
    super(props);
    this.state = {
      businessUnitId: '',
      display: {
        filter: false,
        menu: false,
        searchBox: false,
      },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          business_id_thirdParty: [],
        },
      },
      data: {
        list: [],
        total: 1,
      },
    };
    const { downloadList } = this.props;

    this.queryService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getTableDataForGrid,
      downloadList,
    );

    this.handleResetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.getFilterStateFromStorage();
    tableLayoutService.adjustToScreen(this.refs.tableReference, this.refs.fixedTableFooter, this.refs.fixedTableBody);
  }
  setFilterStateInStorage = () => {
    const { queryParameters,display} = this.state;
    storeInLocalStorage(
      'DISPLAY',
      display,
      'SRN'
    );
    storeInLocalStorage(
      'FILTER',
      {
       queryParameters,
      },
      "SRN"
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters,display} =this.state;
    const localData = fetchFromLocalStorage('FILTER', "SRN");
    const localDisplay = fetchFromLocalStorage('DISPLAY', 'SRN');
    this.setState(
      { queryParameters:localData?.queryParameters || queryParameters ,
        display:{...localDisplay,filter:false} || display},
      () => {
        this.getTableDataForGrid();
      },
    );
  };

  render() {
    const { queryParameters, data, display , } = this.state;

    const { serverResponseWaiting } = this.props;
    const filteredList  =Object.values(queryParameters.filter).flat().length;
    return (
      <SRNStyled>
        <Loading display={serverResponseWaiting} />
        <div className={`srn${serverResponseWaiting ? 'clickable-false' : ''}`}>
          <PageHeaderStyled>
            <div className="card-header-bar" ref="tableReference">
              <h2>Sales Return</h2>
              <div className="header-menu">
                <div className="header-menu-left ">
                  <span
                    className="search-icon-wrapper"
                    onClick={() => this.controlDisplay('searchBox', !display.searchBox)}
                  >
                    <Icon name="search" className="search-icon material-icons" />
                  </span>
                  <span className={`top-search-input-wrapper ${display.searchBox ? 'input-active' : 'input-inactive'}`}>
                    {display.searchBox && (
                      <Textfield
                        value={queryParameters.search}
                        onChange={e => {
                          this.handleSearchChange(e.target.value);
                        }}
                        autoFocus
                      />
                    )}
                    {queryParameters.search && (
                      <Icon name="close" className="cross-icon material-icons" onClick={() => this.handleClearSearch()} />
                    )}
                  </span>
                </div>
                <div className="header-menu-right">
                  <span className="date-rendered">
                    <Icon name="date_range" className="material-icons" />
                    <DateToRender date={queryParameters.date} />
                  </span>

                  <Download config={SRN_CONFIG} onClick={this.handleDownloadClick} />

                  <span onClick={() => this.controlDisplay('filter', true)} className="drawer_filter">
                    <Icon name="filter_list" />
                    {filteredList >0 &&  <span>{filteredList}</span>}
                  </span>
                </div>
              </div>
            </div>
          </PageHeaderStyled>

          <div ref="fixedTableBody" className="fixed-table-wrapper end-list-table">
            <table>
              <TableHeader headerDetails={srnHeader.headerDetails} handleSorting={this.handleTableSorting} />

              <SRNTableBody dataList={data.list} onRowClick={this.onSrnItemClick} />
            </table>
          </div>
          <CreateBtn createHandler={this.handleFabButtonClick} />
          <div ref="fixedTableFooter">
            <Pagination
              pageSize={queryParameters.pagination.limit}
              currentPage={queryParameters.pagination.page}
              orientation="top"
              totalItems={data.total}
              onPageSelect={this.onPageSelect}
            />
          </div>
        </div>
        <DrawerWithFilter
          display={display.filter}
          displayController={this.controlDisplay}
          resetFilter={this.handleResetFilter}
          queryParameters={queryParameters}
          onDateRangeChange={this.handleDateRangeChange}
          handleFilterChange={this.handleFilterChange}
        />
      </SRNStyled>
    );
  }
}

SalesReturn.propTypes = propTypes;
SalesReturn.defaultProps = defaultProps;

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
  bu_id: state.billing.bu_id || null,
});
const SalesReturnWithState = connect(mapStateToProps)(SalesReturn);

export default SalesReturnWithState;
