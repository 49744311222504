import { compose } from 'recompose';

import ContraEntryDetail from './ContraEntryDetail';

import apiInterceptor from '../../../api/interceptor';

import { jvApi } from '../../common/base.api';

const ContraEntryVoucherDetail = compose(apiInterceptor({ ...jvApi }))(ContraEntryDetail);

export default ContraEntryVoucherDetail;
