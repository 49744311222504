import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';
import moment from 'moment';

const SKUPriceTableBody = ({ data, onRowClick }) => {
  return (
    <tr
      className={data.skuDetail.hasOwnProperty('businessSkuId') ? 'cursor-pointer' : 'cursor-default'}
      onClick={() => onRowClick(data.skuDetail ? data.skuDetail.businessSkuId : null)}
    >
      <td className='left-align'>{data.skuDetail ? data.skuDetail.businessSkuId : null}</td>
      <td className='left-align'>{data.skuDetail ? data.skuDetail.title : null}</td>
      <td className='right-align'>{data.batchNo}</td>
      <td className='right-align'>{data.rate}</td>
      <td className='left-align'>{moment(data.affectedFrom).format('ll')}</td>
    </tr>
  );
};

SKUPriceTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const skuPriceConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'skuDetail.businessSkuId',
      title: 'SKU ID',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    { id: 2, label: 'skuDetail.title', title: 'SKU', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'batchNo', title: 'Batch', className: 'cursor-pointer right-align', sortable: true },
    { id: 4, label: 'rate', title: 'Rate', className: 'cursor-pointer right-align', sortable: true },
    { id: 6, label: 'affectedFrom', title: 'Effective From', className: 'cursor-pointer left-align', sortable: true },
  ],
};

const downloadFormat = [{ id: 1, value: 'Download SKU Price upload template' }];

const message = {
  success: 'SKU Price uploaded successfully.',
  error: '!Error while uploading SKU Price',
  downloadError: '!Error while downloading sample template',
};

export {
  downloadFormat, message, skuPriceConfig, SKUPriceTableBody
};
