import styled from 'styled-components';

const DataBaseLogStyled = styled.div`
  &.data-base{
    .fixed-table-wrapper{
      table{
        tbody{
          .query{
            width: 300px;
            white-space: normal;
          }
      }
    }
  }
}
`;
export default DataBaseLogStyled;
