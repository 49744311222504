import React from 'react';
import { Icon } from '../../../components/BillingMDC';
import Switch from '../../../components/Switch';
import HorizontalLabelValueBar from '../../common/horizontalLabellValueBar';
import { VALIDATION_TYPES } from '../../common/validation';

const AgentBody = ({ dataList, onRowClick ,handleInputChange }) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr>
        <td className='left-align'>{data.name}</td>
        <td className='left-align'>{data.phone}</td>
        <td className='text-center'>
          <div className='form-switchs '>
            <HorizontalLabelValueBar
              value={
                <Switch
                  onToggle={(checked, event) => handleInputChange('status', checked, data?.idAgent)}
                  checked={data.status}
                  id={`switch-${data.idAgent}`}
                  className='cursor-pointer'
                />
              }
            />
          </div>
        </td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => onRowClick(data, index)} />
        </td>
      </tr>
    ))}
  </tbody>
);

const agentConfig = [
  {
    id: 1,
    label: 'name',
    title: 'Salesperson Name',
    className: '',
    sortable: false,
  },
  {
    id: 2,
    label: 'phone',
    title: 'Phone No.',
    className: '',
    sortable: false,
  },
  {
    id: 3,
    label: 'status',
    title: 'Status',
    className: ' text-center',
    sortable: false,
  },
  {
    id: 4,
    label: '',
    title: '',
    className: '',
    sortable: false,
  },
];

const formValidationFieldList = [{ title: 'name' }, { title: 'phone',type:VALIDATION_TYPES.PHONE_NUMBER}];

const config = {
  header: agentConfig,
  getTableBody: AgentBody,
  formValidationFieldList,
};

export default config;
