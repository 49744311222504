import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {
  Icon,
  Fab,
  Grid,
  Cell,
  Menu,
  Drawer,
  Dialog,
  Button,
  Snackbar,
  MenuItem,
  Textfield,
  MenuAnchor,
  Navigation,
  DialogBody,
  DialogTitle,
  DialogFooter,
  DrawerHeader,
  DialogHeader,
  LinearProgress,
} from 'react-mdc-web';
import orderBy from 'lodash/orderBy';
import * as appConfig from '../../../config';
import history from '../../../../utils/history';
import * as appConstants from '../../../config';
import { debouncer } from '../../../../utils/handlers';
import * as httpUtils from '../../../../utils/httpUtils';
import * as filterUtil from '../../../../utils/filterUtil';
import Pagination from '../../../../components/Pagination/index';
import TableHeader from '../../../../components/TableHeader/index';
import { getFixTableMeasurements } from '../../../../utils/tableMetrics';
import DateRangePicker from '../../../../components/DateRangePicker/DateRangePicker';
import { downloadFormat, message, skuPriceConfig, SKUPriceTableBody } from './SKUPriceConfig';
import BillingSelect from '../../../../components/Select/BillingSelect';
import { SKU_PRICE_HISTORY } from '../../../../data/enums/Route';

class SKUPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showSearchBox: false,
      dataSet: [],
      buList: [],
      totalData: 1,
      dateClickCount: 0,
      businessUnitId: '',
      searchValue: '',
      createModalOpen: false,
      createSKU: {
        file: null,
      },
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
        },
      },
      showMessage: false,
      // errorMessage:[]
      success: false,
      message: '',
    };
  }

  /** adjust table width and height according to screen **/
  componentDidMount() {
    this.getTableDataForGrid();
    this.getBUList();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    let table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
  }

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    this.setState({ loading: true });
    const { queryParameters } = this.state;
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    httpUtils
      .get(
        appConstants.baseUrl +
          `upload-skus/sku-price?bu=${this.state.businessUnitId}&page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data.list, totalData: response.data.total, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
  // get BU list
  getBUList = () => {
    httpUtils.get(appConfig.baseUrl + 'upload/business-company').then(response => {
      if (response.success) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getTableDataForGrid());
  };

  /** on Date Range Change */
  handleDateRangeChange = (start, end) => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.dataSet;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';

    return orderBy(this.state.dataSet, labelName, sortingName);
  };

  handleFabButtonClick = () => {
    this.setState({ createModalOpen: true });
  };

  closeModal = () => {
    // set false to opening Modal
    this.setState({ createModalOpen: false });
  };

  // handle the file upload.
  handleFileUpload = event => {
    event.preventDefault();
    const { createSKU } = this.state;
    createSKU['file'] = event.target.files[0];
    this.setState({ createSKU: createSKU });
  };

  /** download SKU Report */
  handleDownloadClick = id => {
    let type = '';
    const self = this;
    const schema = 'https://';
    if (id === 1) {
      type = 'sample';
    }
    httpUtils
      .get(appConstants.baseUrl + `download/sku-price?type=${type}`)
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ loading: false });
          const url = response.data.url;
          const newWin = window.open(schema + window.location.hostname + url);
          if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
            alert('Please enable pop for this site');
          }
        } else {
          self.setState({ loading: false, message: message.downloadError, success: false, showMessage: true });
        }
      })
      .catch(error => {
        self.setState({ loading: false, message: message.downloadError, success: false, showMessage: true });
        console.error(error);
      });
  };

  handleChange = (field, value) => {
    const { createSKU } = this.state;
    createSKU[field] = value;

    this.setState({ createSKU });
  };

  // create the sku
  createSKU = () => {
    const { createSKU } = this.state;
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append('file', createSKU.file);
    formData.append('businessId', createSKU.businessId);
    this.setState({ loading: true, createModalOpen: false });
    httpUtils
      .multipartPost(appConfig.baseUrl + 'upload-skus/sku-price', formData)
      .then(response => {
        this.setState({ loading: false });
        this.skuFileInput.value = null;
        if (response.status == 200 && response.success) {
          this.setState({ showMessage: true, success: true, message: message.success });
          // alert('sku price uploaded');
        }
      })
      .catch(error => {
        this.skuFileInput.value = null;
        // this.setState({showMessage: true, loading:false, errorMessage:error.data.error.details})
        this.setState({ showMessage: true, loading: false, success: false, message: message.error });
      });
  };

  closeMessageWrapper = status => {
    this.setState({ showMessage: status, errorMessage: [] });
  };

  onSKUPriceItemClick = id => {
    if (id !== undefined) {
      history.push(`/${SKU_PRICE_HISTORY}/${id}`);
    }
    /* todo else {

    }*/
  };

  onSearchInputChange = value => {
    const self = this;
    const searchText = value;
    const { queryParameters } = this.state;
    httpUtils
      .get(
        appConstants.baseUrl +
          `upload-skus/sku-price?bu=${this.state.businessUnitId}&page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}&search_text=${searchText}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data.list, totalData: response.data.total, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  inputSearch = value => {
    const searchValue = value;
    this.setState({ searchValue: searchValue }, () => {
      debouncer(this.onSearchInputChange, 800)(this.state.searchValue);
    });
  };

  clearInputText = () => {
    const value = '';
    const { showSearchBox } = this.state;
    this.setState({ searchValue: '', showSearchBox: !showSearchBox }, () => {
      this.onSearchInputChange(value);
    });
  };

  render() {
    const {
      loading,
      queryParameters,
      totalData,
      sorting,
      createModalOpen,
      showMessage,
      errorMessage,
      showSearchBox,
      searchValue,
      createSKU,
    } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);

    return (
      <div>
        <div>
          {loading && (
            <div className="linear-progress-wrapper temp-progress-wrapper">
              <LinearProgress accent indeterminate />
            </div>
          )}
          <div className={loading ? 'clickable-false' : ''}>
            <div className="message-snackbar">
              {/* {showMessage && <MessageWrapper
                name="! Error"
                messageArray={errorMessage}
                //message="There was error while uploading file"
                closeMessage={this.closeMessageWrapper}
                error={true}
              />
              }*/}
              {
                <Snackbar
                  action="RETRY"
                  className={this.state.success ? 'success-message' : ''}
                  onClick={e => {
                    this.handleFabButtonClick();
                  }}
                  timeout={this.state.success ? 2500 : 4000}
                  onTimeout={() => {
                    this.setState({ showMessage: false });
                  }}
                  open={showMessage}
                >
                  {this.state.message}
                </Snackbar>
              }
            </div>
            <div className="card-header-bar" ref="tableReference">
              <h2>SKU Price</h2>
              <div className="header-menu">
                <div className="header-menu-left ">
                  <span
                    className="search-icon-wrapper"
                    onClick={() =>
                      this.setState({
                        showSearchBox: !showSearchBox,
                      })
                    }
                  >
                    <Icon name="search" className="search-icon material-icons" />
                  </span>
                  <span className={`top-search-input-wrapper ${showSearchBox ? 'input-active' : 'input-inactive'}`}>
                    {showSearchBox && (
                      <Textfield
                        value={this.state.searchValue}
                        onChange={e => {
                          this.inputSearch(e.target.value);
                        }}
                      />
                    )}
                    {searchValue && (
                      <Icon name="close" className="cross-icon material-icons" onClick={this.clearInputText} />
                    )}
                  </span>
                </div>
                <div className="header-menu-right">
                  <span className="download-menu" onClick={() => this.setState({ showMenu: !this.state.showMenu })}>
                    <Icon name="get_app" />
                  </span>
                  <MenuAnchor>
                    <Menu
                      open={this.state.showMenu}
                      onClose={() => {
                        this.setState({ showMenu: false });
                      }}
                      className="single-right-menu transaction-download"
                    >
                      <MenuItem
                        onClick={() => {
                          this.handleDownloadClick(downloadFormat[0].id);
                        }}
                      >
                        {downloadFormat[0].value}
                      </MenuItem>
                    </Menu>
                  </MenuAnchor>
                </div>
              </div>
            </div>
          </div>

          <div ref="fixedTableBody" className="fixed-table-wrapper">
            <table>
              <TableHeader
                headerDetails={skuPriceConfig.headerDetails}
                filterHeaderLabel={false}
                handleSorting={this.handleTableSorting}
              />
              {sortedDataSet.map((data, key) => (
                <tbody>
                  <SKUPriceTableBody data={data} onRowClick={this.onSKUPriceItemClick} />
                </tbody>
              ))}
            </table>
          </div>

          {
            <div ref="fixedTableFooter">
              <Pagination
                pageSize={queryParameters.pagination.limit}
                currentPage={queryParameters.pagination.page}
                orientation="top"
                totalItems={totalData}
                onPageSelect={this.onPageSelect}
              />
            </div>
          }
        </div>
        <div dir="rtl">
          <Drawer
            open={this.state.showFilter}
            className="right-drawer"
            onClose={() => {
              this.setState({ showFilter: false });
            }}
          >
            <div className="filter-wrapper">
              <h2 className="default-horizontal-padding-24">
                <span>Filter</span>
                <span className="float-right">
                  <Icon name="replay" onClick={() => this.resetFilter()} />
                  <Icon name="clear" onClick={() => this.setState({ showFilter: false })} />
                </span>
              </h2>
              <div className="divider" />
              <DateRangePicker
                startDate={moment(queryParameters.date.start)}
                endDate={moment(queryParameters.date.end)}
                onChange={this.handleDateRangeChange}
              />
            </div>
          </Drawer>
        </div>
        {/* fab button and dialogs */}
        <div className="create-fab-button">
          <Fab onClick={() => this.handleFabButtonClick()}>
            <Icon name="add" />
          </Fab>
        </div>
        <Dialog
          open={createModalOpen}
          onClose={() => {
            this.closeModal();
          }}
        >
          <DialogHeader>
            <DialogTitle>Create SKU Price</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <div className="dialog-upperpart">
              <Grid className="grid-padding">
                <Cell col={6} tablet={8}>
                  <label>Business Unit</label>
                  <BillingSelect
                    name="form-field-name"
                    param="businessId"
                    clearable={false}
                    value={createSKU.businessId}
                    valueKey="businessId"
                    labelKey="businessAlias"
                    options={this.state.buList}
                    handleChange={this.handleChange}
                    required={true}
                  />
                </Cell>
                <Cell col={6} tablet={8}>
                  <input
                    type="file"
                    name="resume"
                    className="input-file-upload"
                    ref={ref => (this.skuFileInput = ref)}
                    onChange={e => this.handleFileUpload(e)}
                  />
                </Cell>
              </Grid>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button accent className="dialog-cancel modal-btn" onClick={() => this.closeModal()}>
              Cancel
            </Button>
            <Button
              accent
              className={
                createSKU.file && createSKU.businessId ? 'dialog-ok modal-btn' : 'dialog-ok modal-btn btn-disabled'
              }
              onClick={() => {
                this.createSKU();
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

SKUPrice.contextTypes = {
  router: PropTypes.object,
};

export default SKUPrice;
