import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const JournalVoucherDetailTableBody = ({ data }) => {
  return (
    <tr className='cursor-pointer'>
      <td className='left-align'>{data.customer.name}</td>
      <td className='right-align'>{data.drAmount ? data.drAmount.toLocaleString() : data.dramount}</td>
      <td className='right-align'>{data.crAmount ? data.crAmount.toLocaleString() : data.crAmount}</td>
      <td className='left-align'>{data.refNumber}</td>
      <td className='left-align'>{data.voucherNumber}</td>
      <td className='left-align'>{moment(data.refDate).format('ll')}</td>
      <td className='left-align'>{data.mitiTitle}</td>
      <td className='left-align'>{data.narration}</td>
      <td className='left-align'>{data.partyName}</td>
      <td className='left-align'>{data.partyPanNo}</td>
      <td className='right-align'>{data.partyTaxableAmount}</td>
    </tr>
  );
};

JournalVoucherDetailTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const journalVoucherDetailConfig = {
  headerDetails: [
    { id: 2, label: 'cutomer.name', title: 'Ledger', className: 'cursor-pointer left-align', sortable: true },
    { id: 3, label: 'drAmount', title: 'Debit', className: 'cursor-pointer right-align', sortable: true },
    { id: 4, label: 'crAmount', title: 'Credit', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'refNumber', title: 'Ref No', className: 'cursor-pointer left_align', sortable: true },
    { id: 7, label: 'voucherNumber', title: 'Voucher No.', className: 'cursor-pointer left_align', sortable: true },
    { id: 1, label: 'refDate', title: 'Ref Date', className: 'cursor-pointer left-align', sortable: true },
    { id: 1, label: 'mitiTitle', title: 'Miti', className: 'cursor-pointer left-align', sortable: true },
    { id: 6, label: 'narration', title: 'Narration', className: 'cursor-pointer left-align', sortable: true },
    { id: 8, label: 'party_name', title: 'Party Name', className: 'left-align', sortable: false },
    { id: 9, label: 'party_pan_no', title: 'Party PAN No.', className: 'left-align', sortable: false },
    {
      id: 10,
      label: 'party_taxable_amount',
      title: 'Taxable Amount',
      className: 'right-align',
      sortable: false,
    },
  ],
};

export { journalVoucherDetailConfig, JournalVoucherDetailTableBody };
