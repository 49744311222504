import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { has } from '../../../utils/hasOwnProperty';

const numberConfig = {
  /*minimumFractionDigits: 2,*/
  maximumFractionDigits: 2,
};
const OutstandingReportTableBody = ({ dataList }) => {
  return dataList.map((data, key) => {
    return (
      <tr className='cursor-pointer'>
        {/*<td className="left-align">{moment(data.date).format('ll')}</td>*/}
        <td className='left-align'>{data.vno}</td>
        <td className='left-align'>{has.call(data, 'customer') ? data.customer.customerName : ''}</td>
        {/* <td className="left-align">{data.hasOwnProperty('customer') ? data.customer.branch : ''}</td>*/}
        <td className='left-align'>{has.call(data, 'business') ? data.business.businessUnitTitle : ''}</td>
        <td className='right-align'>{data.billDate}</td>
        <td className='right-align'>{data.dueDate}</td>
        <td className='right-align'>
          {has.call(data, 'billAmount') ? data.billAmount.toLocaleString(undefined, numberConfig) : ''}
        </td>
        <td className='right-align'>
          {has.call(data, 'adjusted') ? data.adjusted.toLocaleString(undefined, numberConfig) : ''}
        </td>
        <td className='right-align'>
          {has.call(data, 'balance') ? data.balance.toLocaleString(undefined, numberConfig) : ''}
        </td>
        <td className='right-align'>{data.dueAge}</td>
      </tr>
    );
  });
};
const OutstandingTotal = ({ totalData }) => {
  return (
    <tr className='cursor-pointer'>
      <td className='left-align'></td>
      <td className='left-align'></td>
      {/*<td className="left-align"></td>*/}
      <td className='left-align'></td>
      <td className='right-align'></td>
      <td className='right-align'></td>
      <td className='right-align'></td>
      <td className='right-align font-medium'>{totalData.title}</td>
      <td className='right-align font-medium'>
        {has.call(totalData, 'value') ? totalData.value.toLocaleString(undefined, numberConfig) : ''}
      </td>
      <td className='right-align'></td>
    </tr>
  );
};

const CarriedBody = ({ carriedSet }) => {
  //debugger;

  return (
    <tr className='cursor-pointer'>
      <td className='left-align'>{carriedSet.title}</td>
      <td className='left-align'></td>
      {/*<td className="left-align"></td>*/}
      <td className='left-align'></td>
      <td className='right-align'></td>
      <td className='right-align'></td>
      <td className='right-align'>
        {has.call(carriedSet, 'carriedAmount') ? carriedSet.carriedAmount.toLocaleString(undefined, numberConfig) : ''}
      </td>
      <td className='right-align'></td>
      <td className='right-align'>
        {has.call(carriedSet, 'carriedAmount') ? carriedSet.carriedAmount.toLocaleString(undefined, numberConfig) : ''}
      </td>
      <td className='right-align'></td>
    </tr>
  );
};

/*
OutstandingReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};
*/

const outstandingReportConfig = {
  headerDetails: [
    { id: 1, label: 'vno', title: 'Voucher Number', className: 'cursor-pointer left-align', sortable: true },
    {
      id: 2,
      label: 'customer.customerName',
      title: 'Customer',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    /* {id: 3, label: 'customer.branch', title: 'Branch', className: 'cursor-pointer left-align', sortable: true},*/
    {
      id: 4,
      label: 'business.businessUnitTitle',
      title: 'Business Unit',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    { id: 5, label: 'billDate', title: 'Bill Date', className: 'cursor-pointer left-align', sortable: true },
    { id: 6, label: 'dueDate', title: 'Due Date', className: 'cursor-pointer left-align', sortable: true },
    { id: 7, label: 'billAmount', title: 'Bill Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 8, label: 'adjusted', title: 'Adjusted', className: 'cursor-pointer right-align', sortable: true },
    { id: 9, label: 'balance', title: 'Balance', className: 'cursor-pointer right-align', sortable: true },
    { id: 10, label: 'dueAge', title: 'Due Age', className: 'cursor-pointer right-align', sortable: true },
  ],
};

export {
  outstandingReportConfig, OutstandingReportTableBody, CarriedBody, OutstandingTotal
};
