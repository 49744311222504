import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import CreatePrnDialog from './DialogView';
import history from '../../../../utils/history';
import { debouncer, getDiscountedValue, handleBatchSelect } from '../../../../utils/handlers';
import { clone } from '../../../../utils/arrayProcessor';
import { validateForm } from '../../../common/validation';
import * as filterUtil from '../../../../utils/filterUtil';
import { isObjectEmpty } from '../../../../utils/objectProcessor';
import { voucherEncoder } from '../../../../utils/miscellaneous';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import { PRN_DETAILS,PURCHASE_RETURN_BASE } from '../../../../data/enums/Route';
import { fixedFloat, fixedFloatAndCommas } from '../../../../utils/conversion';
import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import RadioButtonList from '../../../../components/RadioButtonList/RadioButtonList';
import { buApi, catalogsApi, checkIfApiCallSuccess, ledgerApi, prnApi } from '../../../common/base.api';
import { Button, Cell, Checkbox, Fab, Grid, Icon, LinearProgress, Snackbar } from '../../../../components/BillingMDC';
import GRNStyled from '../../grn/GRNStyled';
import {
  CreatePurchaseReturnTableBody,
  createSkuMapper,
  vendorMapper,
  editFormValidateFieldArray,
  formValidateFieldArray,
  message,
  pageValidateFieldArray,
  quantityDefaultValues,
  getQuantityValidationStatus,
} from './config';
import { DISCOUNT_OPTIONS, VAT_PERCENTAGE, FIXED_DECIMAL_DIGIT } from '../../../../data/enums/enums';
import DiscountBarWithCheckBox from '../../../common/create/DiscountBarWithCheckBox';
import { discountOptions } from '../../sales/create/config';
import BillDiscountPresentor from '../../../common/create/discountPresenter';
import { getPercentage } from '../../../../utils/miscellaneous';
import DiscountBarStyled from '../../../common/create/DiscountBarStyled';
import {getMinimumDateForReturns, isInt, updateDiscountValueOfInvoiceList} from "../../common/helpers";
import { has } from "../../../../utils/hasOwnProperty";
import { checkBUId, getIntegratedBUList } from "../../../common/common";
import CustomerSelect from '../../../../common/CustomerSelect';
import { getBackDatedStatus,getCustomerList } from '../../../../views/fundamentals/common/helpers';
import {resolveBillSummary, resolveDetailList, resolveSingleDetail} from "../../common/fundamentalHelper";

const propTypes = {
  fiscalYearRange: PropTypes.shape({
    start_date: PropTypes.number,
    end_date: PropTypes.number,
    previous_fiscal_year_closed: PropTypes.bool,
  }),
};
const defaultProps = {
  fiscalYearRange: {
    start_date: filterUtil.getCurrentDate(),
    end_date: filterUtil.getCurrentDate(),
    previous_fiscal_year_closed: false,
  },
};

class CreatePurchaseReturn extends Component {
  // get the vendor list for the selection.

  getVendorList = () => {
    ledgerApi.getVendorList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const responseDataList = response.data.list;
        responseDataList.map(vendor => {
          vendor.label = `${vendor.idLedger}. ${vendor.title}`;
        });
        this.setState({ vendorList: responseDataList });
      }
    });
  };

  validationForGrnReturn = (skuObj) => {
    const sellable = ((skuObj.normal * skuObj?.uom?.pcs) <= skuObj.compare_normal);
    const damage = ((skuObj.damage * skuObj?.uom?.pcs) <= skuObj.compare_damage);
    const expiry =  ((skuObj.expiry * skuObj?.uom?.pcs) <= skuObj.compare_expiry);
    const shortage =  ((skuObj.shortage * skuObj?.uom?.pcs) <= skuObj.compare_shortage);
    const stockValidationObj = {
      sellable,
      damage,
      expiry,
      shortage,
    };
    const breakingQuantityLabel = Object.keys(stockValidationObj).reduce((label, s) => {
      if (!stockValidationObj[s]) label = `${label.length === 0 ? label : label + ', '}${s}`;
      return label;
    }, '');
    if (sellable && damage && expiry && shortage) {
      return { condition: true, breakingQuantityLabel };
    }
    return { condition: false, breakingQuantityLabel };
  };

  checkQuantity = skuObj => {
    const { stockQuantity, createSKU, invoiceIdPresent } = this.state;

    const { condition, breakingQuantityLabel } = invoiceIdPresent ? this.validationForGrnReturn(skuObj) : getQuantityValidationStatus(stockQuantity, createSKU);
    if (!condition) {
      this.setState({
        showMessage: true,
        success: false,
        message: `Should not be greater than available quantity (${breakingQuantityLabel})`,
      });
      return false;
    }
    return true;
  };

  getAvailableStock = (skuId, batchId, businessId) => {
    const { stockQtyInfo } = this.state;
    const stockQtyInfoKey = `${skuId}-${batchId}-${businessId}`;
    catalogsApi
      .getBatchAvailableStock({ business_id: businessId, sku_id: skuId, batch_id: batchId })
      .then(response => {
        if (response?.data?.length > 0) {
          const res = response?.data[0];
          const stockQuantity = {
            normal: res.available_quantity ? parseInt(res.available_quantity, 10) : 0,
            damage: res.available_damage ? parseInt(res.available_damage, 10) : 0,
            expiry: res.available_expiry ? parseInt(res.available_expiry, 10) : 0,
            shortage: res.available_shortage ? parseInt(res.available_shortage, 10) : 0,
          };
          stockQtyInfo[stockQtyInfoKey] = stockQuantity;
          this.setState({ stockQuantity, stockQtyInfo });
        } else {
          stockQtyInfo[stockQtyInfoKey] = 0;
          this.setState({
            showMessage: true,
            message: 'Stock quantity not available i.e. 0',
            stockQtyInfo,
            stockQuantity: quantityDefaultValues,
          });
        }
      }).catch((err) => {
        this.setState({
          stockQuantity: quantityDefaultValues,
          showMessage: true,
        });
      });
  };

  // get the brand list
  getBrandList = () => {
    const { businessUnitId, buList } = this.state;
    const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);

    catalogsApi
      .getBrandList({ business_id: checkBUId(businessUnitId) })
      .then((response) => {
        if (checkIfApiCallSuccess(response)) {
          const filteredBrandList = response.data.list.filter(list => !integratedBUIds.includes(list.businessId));
          const insertIndexArr=filteredBrandList.map((a,index)=>{return {...a, indexArr:index+1}});
          this.setState({ brandList:insertIndexArr });
        }
      });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = (brandId,skuId = null, businessId) => {
    const { batchList} = this.state;
    let batches = [...batchList];
    catalogsApi
      .getSkuUnderBrand({ business_id: businessId, brand_id: brandId })
      .then((response) => {
        if (checkIfApiCallSuccess(response)) {
          const skus = response.data.list || [];
          if (skuId) {
            const sku = skus.find((skuList) => skuList.businessSkuId === skuId) || {};
            batches = sku.batchList || [];
          }
          this.setState({ skuList: skus, brandSKUList: skus,batchList: batches });
        }
      });
  };

  // get sku list
  getSkuList = async inputValue => {
    const { businessUnitId,batchList, buList } = this.state;
    const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);
    const nonIntegratedBUIds = buList.filter(list => !integratedBUIds.includes(list.businessId)).map(list=>list.businessId);
    const response = await catalogsApi.getSkuUnderOtherBUList({ business_id: nonIntegratedBUIds });
    const filteredSKUList = response.data.list.filter(list =>
      list.title.toLowerCase().includes(inputValue.toLowerCase()),
    );
    let batchFilteredList;
    if(batchList?.length === 0){
       batchFilteredList = filteredSKUList[0]?.batchList.map((a)=>({...a,batch_id:a?.businessBatchId}))
        }
    this.setState({ skuList: filteredSKUList,batchList:batchFilteredList });
    return filteredSKUList;
  };

  getBrandSkuList = async inputValue => {
    const { brandSKUList } = this.state;
    const filteredSKUList = brandSKUList.filter(list => list.title.toLowerCase().includes(inputValue.toLowerCase()));
    this.setState({ skuList: filteredSKUList });
    return filteredSKUList;
  };

  // get BU list
  getBUList = () => {
    buApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  getSelectedPurchaseList = checkedList => {
    const { invoiceList } = this.state;
    const selectedInvoiceList = invoiceList.filter(item => checkedList.indexOf(item.idPurchaseDetail) > -1);

    return selectedInvoiceList;
  };

  setPurchaseDetails = data => {
    const { purchaseDetails } = this.state;

    purchaseDetails.billDiscount = data.billDiscount;
    purchaseDetails.tradeDiscount = data.tradeDiscount;
    purchaseDetails.amount = data.amount;
    purchaseDetails.amountAfterPromotionDiscount = data.amountAfterPromotionDiscount;

    this.setState({ purchaseDetails });
  };

  getGrnIdDetails = id => {
    const {buList} = this.state;
    this.resetBillSummaryAmounts();
    this.mapVendorDetails({});
    const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);
    this.setState({ loading: true });
    prnApi
      .getAvailablePurchase(id)
      .then(response => {
        this.setState({ loading: false });
        if (response.success && !isObjectEmpty(response.data)) {
          if (!integratedBUIds.includes(response.data.businessId)) {
            const vendorDetails = response.data.customerDetail;
            this.setState({ invoiceIdPresent: true, invoiceIdTaken: false });
            this.mapVendorDetails(vendorDetails);
            this.createListObject(response.data.purchaseInvoice);
            this.setPurchaseDetails(response.data);
            this.mapDiscounts(response.data)
          } else {
            this.setState({
              showMessage: true,
              success: false,
              message: message.misMatchBusinessId,
              invoiceIdPresent: false,
              invoiceList: [],
              invoiceIdTaken: true,
            });
          }
        } else {
          const userMessage = response.message ? `!${response.message}` : '!PRN already done.';
          this.setState({
            showMessage: true,
            success: false,
            message: userMessage,
            invoiceIdPresent: false,
            invoiceList: [],
            invoiceIdTaken: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          showMessage: true,
          success: false,
          message: message.invoiceNumberNotFound,
          invoiceIdPresent: false,
          invoiceList: [],
          invoiceIdTaken: false,
        });
      });
  };

  onPurchaseInvoiceNumberChange = (field, value) => {
    const { billSummary, createSKU } = this.state;
    billSummary[field] = value;
    createSKU.expiry = 0;
    this.setState({ billSummary }, () => {
      debouncer(this.getGrnIdDetails, 900)(billSummary[field]);
    });
  };

  mapDiscounts = data => {
    const {referenceBillSummary} = this.state;
    referenceBillSummary.subTotal = data.subTotalAmount || 0;
    referenceBillSummary.cashDiscount = data.billDiscount || 0;
    referenceBillSummary.tradeDiscount = data.tradeDiscount || 0;
    referenceBillSummary.actualCashDiscount = data.billDiscount || 0;
    referenceBillSummary.actualTradeDiscount = data.tradeDiscount || 0;
    this.setState({referenceBillSummary});
  };


  // handle change of create sku events.
  onChange = (field, value, detail = {}) => {
    let { createSKU } = this.state;

    const { invoiceIdPresent } = this.state;

    createSKU[field] = value;
    if (field === 'brand') {
      createSKU.batch_id = '';
      createSKU.rate = '';
      createSKU.promotion_discount = 0;
      createSKU.skuTitle = '';
      createSKU.business_id = detail ? detail.businessId : '';
      createSKU.sku_id = null;
      createSKU.normal =0 ;
      createSKU.damage = 0;
      createSKU.expiry = 0;
      createSKU.shortage=0;
      createSKU.quantity = 0;
      createSKU.uom = {
        value: 'pcs',
        pcs: 1,
      };
      createSKU.normal =0 ;
      createSKU.damage = 0;
      createSKU.expiry = 0;
      createSKU.shortage=0;
      createSKU.quantity = 0;

      this.setState({ batchList: [], stockQuantity: quantityDefaultValues }, () => value !== null && this.getSKUListUnderBrand(detail?.businessCatalogDetailId,detail?.sku_id, detail?.businessId));
      if (value === null) {
        createSKU.brand = '';
        this.setState({ brandSKUList: [], stockQuantity: quantityDefaultValues });
      }
    }
    if (field === 'sku_id') {
      createSKU.business_id = detail?.businessId;
      createSKU.normal =0 ;
      createSKU.damage = 0;
      createSKU.expiry = 0;
      createSKU.shortage=0;
      createSKU.quantity = 0;
      createSKU.skuTitle = detail.title || '';
      createSKU.uom = {
        value: 'pcs',
        pcs: 1,
      };
      createSKU.excise_percent = JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise
          ? (detail.excisePercent || detail.excise_percent || 0)
          : 0;

        createSKU.uom = detail.uom_list.find(x => x.is_basic);
        createSKU.uomId = detail.uom_list.find(x => x.is_basic)?.id;
        createSKU.uom_list = detail.uom_list.filter(x=>x.status);
      createSKU = {
        ...(handleBatchSelect(createSKU, (detail.batchList.length > 0 && detail.batchList[0]) || {}, 'inventory') ||
          createSKU),
      };
      createSKU.dlp=createSKU.rate;
      createSKU.vat_percent = detail.vatPercent;

      this.setState(
        { batchList: detail.batchList || [], stockQuantity: quantityDefaultValues || [] },
        () => {
          this.getAvailableStock(createSKU.sku_id, createSKU.batch_id, detail.businessId);
        },
      );
    }
    if (field === 'batch_id') {
      createSKU.normal =0 ;
      createSKU.damage = 0;
      createSKU.expiry = 0;
     createSKU.shortage=0;
     createSKU.quantity = 0;
      createSKU = {
        ...(handleBatchSelect(createSKU, detail, 'inventory') || createSKU),
      };
      this.getAvailableStock(createSKU.sku_id, createSKU.batch_id, detail.businessId);
    }

    if (field === 'discount_input') {
      createSKU.promotion_discount = createSKU.discount_input;
    }

    if (field === 'normal' || field === 'damage' || field === 'expiry' || field === 'shortage') {
      createSKU.quantity = Number((createSKU.normal + createSKU.expiry + createSKU.damage + createSKU.shortage ).toFixed(2));
      createSKU.quantity_basic = Number((createSKU.normal + createSKU.expiry + createSKU.damage+ createSKU.shortage) * createSKU.uom.pcs);
      if (invoiceIdPresent) {
        this.checkQuantityAtBasic(createSKU);
      }
      if (invoiceIdPresent) {
        createSKU.promotion_discount = this.calculateRelativeDiscount(
            createSKU.actual_quantity,
            createSKU.actual_promotion_discount,
            createSKU.quantity_basic)
      }
    }

    if (field === 'uom') {
      createSKU.uom = detail;
      createSKU.expiry = 0;
      createSKU.damage = 0;
      createSKU.normal = 0;
      createSKU.shortage = 0;
      createSKU.quantity = 0;
      createSKU.uomId = detail.id;
      createSKU.rate = createSKU.dlp * detail.pcs;
      createSKU.uomRate = createSKU.dlp * detail.pcs;
      createSKU.discount  = 0;
    }
    createSKU = resolveSingleDetail(createSKU, invoiceIdPresent);
    this.setState({ createSKU });
  };

  onPageInputFieldChange = (field, value) => {
    const { billSummary } = this.state;
    billSummary[field] = value;
    this.setState({ billSummary });
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data, index) => {
    const { invoiceIdPresent,brandList } = this.state;
    this.setState({ editModalOpen: true, indexInInvoiceList: index });
    const businessCatalogDetailIdx =brandList.find(a=>a.indexArr===data.brand)?.businessCatalogDetailId;
    if (!invoiceIdPresent)
    {
      businessCatalogDetailIdx !==undefined ? this.getSKUListUnderBrand(businessCatalogDetailIdx, data.sku_id, data.business_id) :this.getSkuList(data?.skuTitle);
    }
    this.setState({ createSKU: clone(data), backUpCreateSku: clone(data) });
    if (invoiceIdPresent) {
      this.setState({
        createSKU: clone({
          ...data
        }),
      });
    }
  };

  // create the sales Return, update the discount value
  createPurchaseReturn = () => {
    const { invoiceList, createSKU } = this.state;
    createSKU.vendor_invoice_number = this.state.billSummary.voucher_number;
    invoiceList.push(createSKU);
    this.setState({ invoiceList }, () => {
      this.handleModalClose();
      // handle SRN item update
      this.handlePRNItemUpdate(invoiceList);
    });
  };

  editPurchaseReturn = (data, index) => {
    const {invoiceList, createSKU, invoiceIdPresent, referenceBillSummary} = this.state;

    this.setState({invoiceList}, () => {
      this.handleModalClose();
    });
    if (invoiceIdPresent) {
      createSKU.bill_discount =
          this.calculateRelativeDiscount(
              referenceBillSummary.subTotal,
              referenceBillSummary.actualCashDiscount,
              createSKU.net_amount,
          ) || 0;
      createSKU.trade_discount =
          this.calculateRelativeDiscount(
              referenceBillSummary.subTotal,
              referenceBillSummary.actualTradeDiscount,
              createSKU.net_amount,
          ) || 0;
    }
    invoiceList[index] = createSKU;
    this.setState({ invoiceList }, () => {
      this.handleModalClose();
    });
    invoiceList[index] = createSKU;
    if (invoiceIdPresent) {
      this.handleSecondaryCheckBoxClick(data.idPurchaseDetail, true, createSKU);
    } else {
      this.handlePRNItemUpdate(invoiceList);
    }
  };

// reset checkbox and billSummary when line delete
resetCheckBox = () =>{
  const { checkBoxForPurchaseReturn } = this.state;
  let selectedInvoiceList = [];
  checkBoxForPurchaseReturn.secondary = [];
  checkBoxForPurchaseReturn.primary = false;
  selectedInvoiceList = this.getSelectedPurchaseList(checkBoxForPurchaseReturn.secondary);
  this.setState({ checkBoxForPurchaseReturn }, () => this.handlePRNItemUpdate(selectedInvoiceList));
}

  // delete the sales Return for particular sku, and update the total value.
  deletePurchaseReturnItem = index => {
    const { invoiceList, indexInInvoiceList,invoiceIdPresent } = this.state;
    if (invoiceList.length >= indexInInvoiceList) {
      invoiceList.splice(indexInInvoiceList, 1);
      this.setState({ invoiceList }, () => {
        this.handleModalClose();
        if(invoiceIdPresent){
          this.resetCheckBox();
        }else{
          this.handlePRNItemUpdate(invoiceList);
        }
      });
    }
  };

  calculateRelativeDiscount(referenceAmount = 1, referenceDiscount, amount) {
    return (amount * referenceDiscount) / referenceAmount;
  }

  computeAndSetWeightedDiscount(amount, promotionDiscount, amountValue, invoiceList) {
    const { billSummary, purchaseDetails } = this.state;
    const baseAmount = purchaseDetails.amountAfterPromotionDiscount || 0;
    billSummary.excise = invoiceList.reduce((acc, item) => acc + Number(item.excise), 0);
    const weightedBillDiscount = baseAmount === 0 ? 0 : (amount / baseAmount) * purchaseDetails.billDiscount;

    const weightedTradeDiscount = baseAmount === 0 ? 0 : (amount / baseAmount) * purchaseDetails.tradeDiscount;
    billSummary.promotion_discount = promotionDiscount;
    billSummary.bill_discount_value = Number(weightedBillDiscount.toFixed(FIXED_DECIMAL_DIGIT));
    billSummary.trade_discount_value = Number(weightedTradeDiscount.toFixed(FIXED_DECIMAL_DIGIT));
    billSummary.amount = amountValue;
    billSummary.taxable_amount =
      amount - billSummary.bill_discount_value - billSummary.trade_discount_value + billSummary.excise;

    if (billSummary.taxable_amount <= 0) {
      billSummary.taxable_amount = amount + billSummary.excise;
      billSummary.bill_discount_value = 0;
      billSummary.bill_discount = 0;
      billSummary.trade_discount_value = 0;
      billSummary.trade_discount = 0;
    }
    billSummary.taxExemptedAmount = invoiceList.reduce((acc, item) => acc + item.tax_exempted_amount, 0);
    billSummary.actualTaxableAmount = billSummary.taxable_amount - (billSummary.taxExemptedAmount || 0);
    billSummary.vat = invoiceList.reduce((acc, item) => acc + Number(item.vat), 0);
    billSummary.net_amount = amount;
    billSummary.gross_amount = billSummary.taxable_amount + billSummary.vat;

    this.setState({ billSummary });
  }

  // when SRN item number changes, update the bill total value
  handlePRNItemUpdate = selectedInvoiceList => {
    let {billSummary, referenceBillSummary, invoiceList, invoiceIdPresent, invoiceIdTaken} = this.state;
    let updatedInvoiceList = [];
    if (invoiceIdPresent) {
      referenceBillSummary.cashDiscount = _.sumBy(selectedInvoiceList, 'bill_discount');
      referenceBillSummary.tradeDiscount = _.sumBy(selectedInvoiceList, 'trade_discount');
      updatedInvoiceList = resolveDetailList(selectedInvoiceList, billSummary, invoiceIdPresent, referenceBillSummary);
      billSummary = resolveBillSummary(updatedInvoiceList, billSummary)
      for (let invoiceListElement in invoiceList) {
        let updatedItemIndex = updatedInvoiceList.findIndex(x => x.idPurchaseDetail === invoiceList[invoiceListElement].idPurchaseDetail)
        if (updatedItemIndex === -1) continue;
        invoiceList[invoiceListElement] = updatedInvoiceList[updatedItemIndex]
      }
    } else {
      invoiceList = resolveDetailList(invoiceList, billSummary, invoiceIdPresent , referenceBillSummary);
      billSummary = resolveBillSummary(invoiceList, billSummary)
    }
    this.setState({billSummary, invoiceList});
  }

  // on okay click for the modal
  handleModalOk = () => {
    const { createModalOpen, editModalOpen, createSKU } = this.state;
    if (editModalOpen || createModalOpen) {
      let qtyValidation = this.checkQuantity(createSKU);
      const valid = this.validateModal();
      if (valid && qtyValidation) {
        if (createModalOpen) {
          this.createPurchaseReturn();
        } else {
          this.editPurchaseReturn(this.state.createSKU, this.state.indexInInvoiceList);
        }
      }
    } else {
      this.deletePurchaseReturnItem();
    }
  };

  /** handle the primary check box click. */
  handlePrimaryCheckBoxClick = flag => {
    const { checkBoxForPurchaseReturn, invoiceList } = this.state;
    checkBoxForPurchaseReturn.primary = flag;
    if (!flag) {
      checkBoxForPurchaseReturn.secondary = [];
      this.resetBillSummaryAmounts();
      this.setState({ checkBoxForPurchaseReturn });
    } else {
      /** run through all the loop within the invoice list */
      const allCheckBox = invoiceList.map(element => element.idPurchaseDetail);
      checkBoxForPurchaseReturn.secondary = allCheckBox;
      const selectedInvoiceList = this.getSelectedPurchaseList(checkBoxForPurchaseReturn.secondary);
      this.setState({ checkBoxForPurchaseReturn }, () => this.handlePRNItemUpdate(selectedInvoiceList));
    }
  };

  /** handle the secondary checkBox click */
  handleSecondaryCheckBoxClick = (id, flag) => {
    const { checkBoxForPurchaseReturn, invoiceList } = this.state;
    let selectedInvoiceList = [];
    if (!flag) {
      // find the index in the list, and clear it.
      // reset the primary checkbox
      const indexInList = checkBoxForPurchaseReturn.secondary.indexOf(id);
      checkBoxForPurchaseReturn.secondary.splice(indexInList, 1);
      checkBoxForPurchaseReturn.primary = false;
      selectedInvoiceList = this.getSelectedPurchaseList(checkBoxForPurchaseReturn.secondary);
      this.setState({ checkBoxForPurchaseReturn }, () => this.handlePRNItemUpdate(selectedInvoiceList));
    } else {
      // check the condition if primary is to be set.
      const indexInList = checkBoxForPurchaseReturn.secondary.indexOf(id);
      if (indexInList === -1) checkBoxForPurchaseReturn.secondary.push(id);
      if (checkBoxForPurchaseReturn.secondary.length === invoiceList.length) {
        checkBoxForPurchaseReturn.primary = true;
      }
      selectedInvoiceList = this.getSelectedPurchaseList(checkBoxForPurchaseReturn.secondary);
      this.setState({ checkBoxForPurchaseReturn }, () => this.handlePRNItemUpdate(selectedInvoiceList));
    }
  };

  // close the modal resetting  all the value
  handleModalClose = () => {
    this.setState({
      createModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      batchList: [],
      brandSKUList: [],
    });
    this.resetSKUDialog();
  };

  handleAfterCreate = () => {
    const { showMessage, success } = this.state;
    if (!showMessage && success) {
      this.directToDetailPage();
    }
  };

  handleBUClick = id => {
    this.setState({ businessUnitId: id }, () => {
      this.getBrandList();
    });
  };
  handleDateChange = date => {
    const formattedDate = moment(date).format('ll');
    this.setState({ date: formattedDate });
  };

  handleStockValidation = () => {
    const { invoiceList, stockQtyInfo } = this.state;
    const skuQtyMapper = invoiceList.reduce((acc, i) => {
      const key = `${i.sku_id}-${i.batch_id}-${i.business_id}`;
      if (has.call(acc, key)) {
        acc[key].quantity = acc[key].quantity + i.quantity;
        acc[key].normal = acc[key].normal + i.normal;
        acc[key].damage = acc[key].damage + i.damage;
        acc[key].expiry = acc[key].expiry + i.expiry;
        acc[key].shortage = acc[key].shortage + i.shortage;
        acc[key].uom = i.uom;
      } else {
        acc[key] = {};
        acc[key].quantity = i.quantity;
        acc[key].normal = i.normal;
        acc[key].damage = i.damage;
        acc[key].expiry = i.expiry;
        acc[key].shortage = i.shortage;
        acc[key].title = i.skuTitle;
        acc[key].uom = i.uom;
      }

      return acc;
    }, {});

    return Object.keys(skuQtyMapper).every(s => {
      const { condition, breakingQuantityLabel } = getQuantityValidationStatus(stockQtyInfo[s], skuQtyMapper[s]);

      if (!condition) {
        this.setState({
          showMessage: true,
          success: false,
          message: `Sku quantity(${breakingQuantityLabel}) of ${skuQtyMapper[s].title} greater than available stock: ${stockQtyInfo[s][breakingQuantityLabel]}`,
        });

        return false;
      }
      return true;
    });
  };

  checkQuantityAtBasic = (createSKU ) => {
    let pcsPerUoms = createSKU?.uom_list?.find(a => a.id === createSKU.uomId)?.pcs;
    let normalPcs = createSKU.normal * pcsPerUoms;
    let damagePcs = createSKU.damage * pcsPerUoms;
    let expiryPcs = createSKU.expiry * pcsPerUoms;
    let shortagePcs = (createSKU.shortage || 0) * pcsPerUoms;

    if ((normalPcs + damagePcs + expiryPcs + shortagePcs) > createSKU.remainingQuantity) {
      this.setState({
        qtyValidation: true,
      });
      return false;
    } else {
      this.setState({
        qtyValidation: false,
      });
      return true;
    }
  };

  handleSaveClick = () => {
    // generate the object to send to the server
    const {
      vendorDetails,
      billSummary,
      invoiceList,
      businessUnitId,
      checkBoxForPurchaseReturn,
      invoiceIdPresent,
      pageValidation,
      date,
    } = this.state;
    const toValidObject = { ...vendorDetails };
    const document_date = date;
    toValidObject.remarks = billSummary.remarks;
    toValidObject.purchase_invoice_number = billSummary.purchase_invoice_number;
    const valid = validateForm(toValidObject, pageValidation, valid => this.setState({ pageValidation: valid }));
    const invoiceListCheck = invoiceIdPresent ? checkBoxForPurchaseReturn.secondary.length > 0 : invoiceList.length > 0;
    if (!invoiceListCheck) {
      this.setState({
        showMessage: true,
        success: false,
        message: message.invoiceListNotSelected,
      });

      return false;
    }
    const stockValidation = invoiceIdPresent ? true : this.handleStockValidation();
    if (valid && invoiceListCheck && stockValidation) {
      let invoiceArray = [];
      if (checkBoxForPurchaseReturn.secondary.length > 0 && checkBoxForPurchaseReturn.primary === false) {
        invoiceArray = invoiceList.filter(
          (item, key) => checkBoxForPurchaseReturn.secondary.indexOf(item.idPurchaseDetail) > -1,
        );
      } else {
        invoiceArray = invoiceList;
      }
      const object = {
        vendor_ref_id: vendorDetails.vendorId,
        business_id: checkBUId(businessUnitId),
        entered_by: billSummary.entered_by,
        excise: Number(billSummary.excise || 0),
        // gross amount set to net amount
        net_amount: billSummary.invoiceNetValue || 0,
        //amount: billSummary.net_amount,
        purchase_invoice_number: billSummary.purchase_invoice_number,
        remarks: billSummary.remarks,
        vat: billSummary.vatAmount || 0,
        amount: billSummary.amount,
        discount: billSummary.promotionDiscountValue || 0,
        address: vendorDetails.address,
        promotion_discount: billSummary.promotionDiscountValue || 0,
        trade_discount: billSummary.tradeDiscountValue,
        bill_discount: billSummary.cashDiscountValue,
        skuList: invoiceArray.map(sku=>{
          sku.uom_id = sku.uomId;
          sku.normal = Number(sku.normal * sku.uom.pcs);
          sku.expiry = Number(sku.expiry * sku.uom.pcs);
          sku.damage = Number(sku.damage * sku.uom.pcs);
          sku.shortage = Number(sku.shortage * sku.uom.pcs);
          sku.quantity = Number(sku.quantity * sku.uom.pcs);
          sku.uom_rate = sku.rate;
          sku.rate = Number((sku.rate / sku.uom.pcs));
          sku.vendor_invoice_number = 'TEST';
          sku.discount = sku.promotion_discount;
          delete sku.uom;
          delete sku.uomId;
          delete sku.uomRate;

          return sku;
        }),
        document_date,
        tax_exempted_amount: billSummary.taxExemptedAmount || 0,
      };
      this.setState({ loading: true, saveDisable: true });
      prnApi
        .create(object)
        .then(response => {
          const prndIdGet = response.data.detail.purchase_return_invoice_number;
          this.setState({ loading: false });
          if (response.success) {
            this.setState({
              showMessage: true,
              success: true,
              message: message.success,
              invoiceList: [],
              saveDisable: true,
              prnNumber: prndIdGet,
            });
          }
        })
        .catch(error => {
          {
            this.setState({
              loading: false,
              showMessage: true,
              success: false,
              message: error.message ? error.message : message.error,
              saveDisable: false,
            });
          }
        });
    }
  };

  directToDetailPage = () => {
    const { prnNumber } = this.state;
    const encodedVoucherNo = voucherEncoder(prnNumber);
    history.push(`/${PRN_DETAILS}/${encodedVoucherNo}`, {
      printEnable: true,
    });
  };

  directToMainPage = () => {
    history.push(`/${PURCHASE_RETURN_BASE}`);
  };

  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInInvoiceList: index });
  };

  validateModal = () => {
    const { createSKU, invoiceIdPresent, formValidation, stockQuantity } = this.state;
    const fieldList = invoiceIdPresent ? editFormValidateFieldArray : formValidateFieldArray;
    const checkIfValid = validateForm(createSKU, { fieldList, flag: formValidation.flag }, valid =>
      this.setState({ formValidation: valid }),
    );
    return checkIfValid;
  };

  createListObject = salesArray => {
    const { invoiceIdPresent } = this.state;
    const grnInvoicedDate = salesArray.length > 0 && salesArray[0].documentDate;
    const invoiceList = salesArray.map((data, key) => {
      const createSkuObj = createSkuMapper(data);
      if (invoiceIdPresent) {
        createSkuObj.uom_list = data.uom_list.filter(x=>x.status);
        createSkuObj.uom = data?.uom_list?.find(a => a.id === data.uomId);
        const isNormalDecimal = data.normal / createSkuObj.uom.pcs || data.quantity / createSkuObj.uom.pcs;
        const isDamageDecimal = data.damage / createSkuObj.uom.pcs;
        const isShortageDecimal = data.shortage / createSkuObj.uom.pcs;
        const isExpiryDecimal = data.expiry / createSkuObj.uom.pcs;

        createSkuObj.rate = data.uomId ? data.uomRate : data.rate;
        if (!isInt(isNormalDecimal)||!isInt(isDamageDecimal)||!isInt(isShortageDecimal)||!isInt(isExpiryDecimal)) {
          createSkuObj.uom = data?.uom_list?.find(a => a.value === 'pcs');
          createSkuObj.uomId = createSkuObj.uom.id;
          createSkuObj.rate = data.rate;
        }
        createSkuObj.quantity = data.quantity / createSkuObj.uom.pcs;
        createSkuObj.normal= data.normal? data.normal / createSkuObj.uom.pcs : 0;
        createSkuObj.damage= data.damage ? data.damage / createSkuObj.uom.pcs : 0;
        createSkuObj.shortage= data.shortage ? data.shortage / createSkuObj.uom.pcs : 0;
        createSkuObj.expiry= data.expiry ? data.expiry / createSkuObj.uom.pcs : 0;
        createSkuObj.dlp= data.rate;
      }
      return createSkuObj;
    });

    this.setState({ invoiceList, invoicedDate: grnInvoicedDate });
  };

  mapVendorDetails = (vendor = {}) => {
    this.setState({ vendorDetails: vendorMapper(vendor) });
  };

  resetSKUDialog = () => {
    const { formValidation } = this.state;
    formValidation.flag = false;
    this.setState({
      createSKU: createSkuMapper({}),
      formValidation,
      skuList: [],
    });
  };

  resetBillSummaryAmounts = () => {
    let { billSummary } = this.state;
    billSummary = {
      ...billSummary,
      amount: 0,
      promotionDiscountValue: 0,
      cashDiscountValue: 0,
      tradeDiscountValue: 0,
      taxExemptedAmount: 0,
      excise: 0,
      vatAmount: 0,
      actualTaxableAmount: 0,
      invoiceGrossValue: 0,
      invoiceNetValue: 0
    }
    this.setState({billSummary});
  };

  handleCheckBoxClick = (fieldName, flag) => {
    this.setState({ [fieldName]: flag });
    const { billSummary } = this.state;
    // if the flag is set off reset value to 0 and disable
    if (fieldName === 'billDiscountChecked') {
      billSummary.cashDiscountValue = 0;
      billSummary.cashDiscount = 0;
      billSummary.cashDiscountParam = discountOptions[0].value;
    } else if (fieldName === 'tradeDiscountChecked') {
      billSummary.tradeDiscountValue = 0;
      billSummary.tradeDiscount = 0;
      billSummary.tradeDiscountParam = discountOptions[0].value;
    }
    this.setState({ billSummary }, () => this.handlePRNItemUpdate());
  };

  // handle Input Field Change.
  onInputFieldChange = (field, value, stateType) => {
    const { billSummary, vendorDetails } = this.state;
    if (stateType === 'vendorDetails') {
      vendorDetails[field] = value;
    } else {
      billSummary[field] = value;

      if (field === 'cashDiscountParam') {
        billSummary.cashDiscount = 0;
        billSummary.cashDiscountValue = 0;
      }
      if (field === 'tradeDiscountParam') {
        billSummary.tradeDiscountValue = 0;
      }
      this.setState({ billSummary, vendorDetails }, () => {
        this.handlePRNItemUpdate();
      });
    }
  };

  constructor(props) {
    super(props);
    const { user, bu_id } = props;
    this.state = {
      success: true,
      loading: false,
      showMessage: false,
      saveDisable: false,
      qtyValidation: false,
      editModalOpen: false,
      invoiceIdTaken: false,
      createModalOpen: false,
      deleteModalOpen: false,
      invoiceIdPresent: false,
      invoicedDate: filterUtil.getCurrentDay(),
      invoiceId: '',
      businessUnitId: null,
      indexInInvoiceList: 0,
      message: message.success,
      buList: [],
      skuList: [],
      batchList: [],
      brandList: [],
      invoiceList: [],
      brandSKUList: [],
      backUpCreateSku: {},
      billDiscountChecked: false,
      tradeDiscountChecked: false,
      prnNumber:null,
      billSummary: {
        remarks: '',
        entered_by: user.idUsers,
        amount: 0,
        purchase_invoice_number: '',
        voucher_number: '',
        taxExemptedAmount: 0,
        actualTaxableAmount: 0,
        promotionDiscountValue: 0,
        cashDiscountParam: DISCOUNT_OPTIONS[0].value,
        cashDiscount: 0,
        cashDiscountValue: 0,
        tradeDiscountParam: DISCOUNT_OPTIONS[0].value,
        tradeDiscount: 0,
        tradeDiscountValue: 0,
        invoiceNetValue: 0,
        invoiceGrossValue: 0,
        vatAmount: 0,
        billAmount: 0,
        billName: '',
        excise:0,
      },
      purchaseDetails: {
        billDiscount: 0,
        tradeDiscount: 0,
        amount: 0,
      },
      checkBoxForPurchaseReturn: {
        primary: false,
        secondary: [],
      },
      createSKU: createSkuMapper({}),
      vendorDetails: vendorMapper({}),
      date: filterUtil.getCurrentDay(),
      formValidation: {
        flag: false,
        fieldList: formValidateFieldArray,
      },
      pageValidation: {
        flag: false,
        fieldList: pageValidateFieldArray,
      },
      stockQuantity:quantityDefaultValues,
      stockQtyInfo: {},
      backDatedStatus:getBackDatedStatus(),
      referenceBillSummary: {
        subTotal: 0,
        cashDiscount: 0,
        tradeDiscount: 0,
        actualCashDiscount: 0,
        actualTradeDiscount: 0
      },
      ledgerList:[],
      ledgerListPartial:[],
    };
    this.promotionError = '!Violates Promotion';
    this.referenceData = {
      subTotal: 1,
      billDiscount: 0,
      tradeDiscount: 0,
    };
  }
  getMinimumDate = () => {
    const { invoiceIdPresent, invoicedDate } = this.state;
    const { fiscalYearRange } = this.props;
    return getMinimumDateForReturns(fiscalYearRange, invoicedDate, invoiceIdPresent)
  };

  componentDidMount() {
    this.getBUList();
    this.getLedgerList();
  }
  getLedgerList = async () => {
    const ledgerListAll =await getCustomerList('vendor');
    this.getOtherListList(ledgerListAll)
  }
  getOtherListList = (ledgerListAll) =>{
    const ledgerListPartial = ledgerListAll.slice(0,300) ;
    this.setState({ledgerList:ledgerListAll,ledgerListPartial})
  }

  render() {
    const {
      createSKU,
      vendorDetails,
      success,
      message,
      date,
      buList,
      businessUnitId,
      invoiceId,
      invoiceIdTaken,
      invoiceIdPresent,
      billSummary,
      checkBoxForPurchaseReturn,
      invoiceList,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      skuList,
      brandList,
      batchList,
      formValidation,
      pageValidation,
      loading,
      showMessage,
      saveDisable,
      billDiscountChecked,
      tradeDiscountChecked,
      brandSKUList,
      stockQuantity,
      prnNumber,
      backDatedStatus,
      ledgerList,
      ledgerListPartial,
    } = this.state;
    const minDate = this.getMinimumDate();
    return (
        <>
          {loading && (
              <div className="linear-progress-wrapper temp-progress-wrapper">
                <LinearProgress accent indeterminate/>
              </div>
          )}
          <div className={`prn-create${loading ? 'clickable-false' : ''}`}>
            <div className="message-snackbar">
              <div className={`prn-create ${loading ? 'clickable-false' : ''}`}>
                <div className='message-snackbar'>
                  {
                    <Snackbar
                        className={success ? 'success-message' : ''}
                        timeout={success ? 1000 : 1500}
                        onTimeout={() => {
                          this.setState({ showMessage: false }, () => {
                            prnNumber?.length && this.handleAfterCreate();
                          });
                        }}
                        open={showMessage}
                    >
                      {message}
                    </Snackbar>
                  }
                </div>
                <div className="card-header-bar clearfix" ref="tableReference">
                  <div className="header-left">
                    <h2>New Purchase Return</h2>
                  </div>
                  <GRNStyled>
                    <div className={`${backDatedStatus || 'disabled-opacityFull'} header-right document_date`}>
                <span className='date-picker-wrapper'>
                  <label>Document Date:</label>
                  <DatePicker
                      date={moment(date)}
                      onChange={this.handleDateChange}
                      maxDate={filterUtil.getCurrentDate()}
                      minDate={filterUtil.getMomentDate(minDate)}
                  />
                </span>
                    </div>
            </GRNStyled>
          </div>
          <div className='card-body'>
            <div className='radio-list-wrapper'>
              {buList.length && (
                <RadioButtonList
                  data={buList}
                  disableRosiaBu
                  handleBuClick={this.handleBUClick}
                  otherBU
                />
              )}
            </div>
            <div className={businessUnitId !== null ? '' : 'block-overlay'}>
              <div className='three-input-wrapper'>
                <Grid>
                  <Cell col={2} className='input-field'>
                    <BillingTextField
                      value={billSummary.purchase_invoice_number}
                      floatingLabel='GRN Number'
                      required
                      className='billing-required'
                      invalidError={invoiceIdTaken}
                      param='purchase_invoice_number'
                      emptyField={pageValidation.flag}
                      handleChange={this.onPurchaseInvoiceNumberChange}
                      errorMessage={
                        invoiceIdTaken
                          ? 'Invoice No. belongs to other Business Unit'
                          : 'Should not be empty'
                      }
                    />
                  </Cell>
                  <Cell col={3} className='input-select'>
                    <label>Vendor Name</label>
                    <CustomerSelect
                        value ={vendorDetails.vendorId}
                        param='title'
                        valueKey='customerId'
                        onHandleChange={(field = '', id = '', vendor = {}) =>
                            this.mapVendorDetails(vendor)
                        }
                        required
                        className={
                          invoiceIdPresent ? 'no-pointer-events inp-disabled' : ''
                        }
                        pageValidation={pageValidation.flag}
                        ledgerList={ledgerList}
                      ledgerListPartial={ledgerListPartial}type='vendor'
                    />
                  </Cell>
                  <Cell col={2} className='input-field'>
                    <BillingTextField
                      value={vendorDetails.address}
                      param="address"
                      floatingLabel='Address'
                      required
                      className='billing-required'
                      emptyField={pageValidation.flag}
                      handleChange={(value,event)=>{this.onInputFieldChange('address',event,'vendorDetails')}}
                    />
                  </Cell>
                  <Cell col={2} className='input-field'>
                    <BillingTextField
                      value={vendorDetails.panNo}
                      floatingLabel='PAN Number'
                      required
                      type='number'
                      rule='isInt'
                      className='billing-required inp-disabled'
                      emptyField={pageValidation.flag}
                    />
                  </Cell>
                  <Cell col={3} className='input-field'>
                    <BillingTextField
                      value={billSummary.remarks}
                      param='remarks'
                      floatingLabel='Remarks'
                      required
                      className='billing-required'
                      emptyField={pageValidation.flag}
                      handleChange={this.onPageInputFieldChange}
                    />
                  </Cell>
                </Grid>
              </div>
              <div className='table-wrapper'>
                <div ref='fixedTableBody' className='fixed-table-wrapper'>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {invoiceIdPresent && (
                            <Checkbox
                              checked={checkBoxForPurchaseReturn.primary}
                              onChange={({ target: { checked } }) => {
                                this.handlePrimaryCheckBoxClick(checked);
                              }}
                            />
                          )}
                          {!invoiceIdPresent && <span>SN</span>}
                        </th>
                        <th>SKU</th>
                        <th>Batch</th>
                        <th>Unit</th>
                        <th className='right-align'>Quantity</th>
                        <th className='right-align'>Sellable</th>
                        <th className='right-align'>Damage</th>
                        <th className='right-align'>Shortage</th>
                        <th className='right-align'>Expiry</th>
                        <th className='right-align'>Rate</th>
                        <th className='right-align'>Amount</th>
                        <th className='right-align'>Discount</th>
                        <th className='right-align'>Net Amount</th>
                        {/*//todo::hemant , remove*/}
                        {/*<th>Icon</th>*/}
                        {/*<th className='right-align'>Bill discount</th>*/}
                        {/*<th className='right-align'>Trade discount</th>*/}
                        {/*<th className='right-align'>excise</th>*/}
                        {/*<th className='right-align'>vat</th>*/}

                        <th />
                      </tr>
                    </thead>

                    <CreatePurchaseReturnTableBody
                      dataList={invoiceList}
                      handleEditIconClick={this.handleEditIconClick}
                      handleDeleteIconClick={this.handleDeleteIconClick}
                      handleCheckBoxClick={this.handleSecondaryCheckBoxClick}
                      selectedCheckBox={checkBoxForPurchaseReturn.secondary}
                      showCheckBox={invoiceIdPresent}
                    />
                  </table>
                </div>

                      {/* Fab Icon */}
                      {!invoiceIdPresent && (
                          <div className="mini-fab-button">
                            <Fab mini onClick={() => this.setState({createModalOpen: true, stockQuantity: quantityDefaultValues})}>
                              <Icon name="add"/>
                            </Fab>
                          </div>
                      )}
                    </div>
                    {/* Purchase Return table summary goes over here. */}
                    <DiscountBarStyled>
                      {!invoiceIdPresent ? (
                          <div className="total-section-wrapper">
                            <Grid>
                              <Cell col={8}/>
                              <Cell col={3} className="right-align active-opacity-text">
                                Sub Total
                              </Cell>
                              <Cell col={1} className="right-align">
                                {fixedFloatAndCommas(billSummary?.invoiceGrossValue) || 0}
                              </Cell>
                            </Grid>
                            <Grid>
                              <Cell col={3}/>
                              <Cell col={8} className="sub-total-wrap right-align">
                                <DiscountBarWithCheckBox
                                    onCheckBoxClick={this.handleCheckBoxClick}
                                    showCheckBox={billDiscountChecked}
                                    discountParam="cashDiscount"
                                    checkBoxParam="billDiscountChecked"
                                    invoiceAmount={billSummary.invoiceNetValue}
                                    discountOptionValue={billSummary.cashDiscountParam}
                                    discountOptionParam="cashDiscountParam"
                                    discountAmount={billSummary.cashDiscount}
                                    discountLabel="Bill Discount"
                                    onInputChange={this.onInputFieldChange}
                                />
                              </Cell>
                              <Cell col={1} className="right-align default-top-padding-12">
                                {fixedFloatAndCommas(billSummary.cashDiscountValue)}
                              </Cell>
                            </Grid>
                            <Grid>
                              <Cell col={3}/>
                              <Cell col={8} className="sub-total-wrap right-align">
                                <DiscountBarWithCheckBox
                                    onCheckBoxClick={this.handleCheckBoxClick}
                                    showCheckBox={tradeDiscountChecked}
                                    discountParam="tradeDiscount"
                                    checkBoxParam="tradeDiscountChecked"
                                    invoiceAmount={billSummary.invoiceNetValue}
                                    discountOptionValue={billSummary.tradeDiscountParam}
                                    discountOptionParam="tradeDiscountParam"
                                    discountAmount={billSummary.tradeDiscount}
                                    discountLabel="Trade Discount"
                                    onInputChange={this.onInputFieldChange}
                                />
                              </Cell>
                              <Cell col={1} className="right-align default-top-padding-12">
                                {fixedFloatAndCommas(billSummary.tradeDiscountValue)}
                              </Cell>
                            </Grid>
                            {JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && (
                                <Grid>
                                  <Cell col={8}/>
                                  <Cell col={3} className="right-align active-opacity-text">
                                    Excise Duty
                                  </Cell>
                                  <Cell col={1} className="right-align">
                                    {fixedFloatAndCommas(billSummary.excise)}
                                  </Cell>
                                </Grid>
                            )}
                            <Grid className="padding-top-24">
                              <Cell col={8}/>
                              <Cell col={3} className="right-align active-opacity-text">
                                {' '}
                                Taxable Amount
                              </Cell>
                              <Cell col={1} className="right-align">
                                {' '}
                                {fixedFloatAndCommas(billSummary?.actualTaxableAmount) || 0}
                              </Cell>
                            </Grid>
                            <Grid>
                              <Cell col={8}/>
                              <Cell col={3} className="right-align active-opacity-text">
                                VAT
                              </Cell>
                              <Cell col={1} className="right-align">
                                {fixedFloatAndCommas(billSummary?.vatAmount || 0)}
                              </Cell>
                            </Grid>
                            <div className="hr"/>
                            <Grid>
                              <Cell col={8}/>
                              <Cell col={3} className="right-align active-opacity-text">
                                Total
                              </Cell>
                              <Cell col={1} className="right-align font-bold">
                                {fixedFloatAndCommas(billSummary?.invoiceNetValue || 0)}
                              </Cell>
                            </Grid>
                          </div>
                      ) : (
                          <div className="total-section-wrapper">
                            <BillDiscountPresentor billSummary={billSummary}/>
                          </div>
                      )}
                    </DiscountBarStyled>
                  </div>
                </div>
                <div className="newinvoice-btn-wrapper">
                  <Grid>
                    <Cell col={8}/>
                    <Cell col={4} className="right-align">
                      <Button
                          accent
                          className="cancel-btn modal-btn"
                          onClick={() => {
                            this.directToMainPage();
                          }}
                      >
                        Cancel
                      </Button>

                      <Button
                          accent
                          className={saveDisable ? 'save-btn modal-btn btn-disabled' : 'save-btn modal-btn'}
                          disabled={saveDisable}
                          onClick={() => {
                            this.handleSaveClick();
                          }}
                      >
                        Save
                      </Button>
                    </Cell>
                  </Grid>
                </div>

                {/* Begin of the dialog Body */}
                <CreatePrnDialog
                    onModalClose={this.handleModalClose}
                    modalOpen={createModalOpen}
                    editModalOpen={editModalOpen}
                    deleteModalOpen={deleteModalOpen}
                    createSKU={createSKU}
                    handleInputChange={this.onChange}
                    onModalSubmit={this.handleModalOk}
                    skuList={skuList}
                    brandList={brandList}
                    batchList={batchList}
                    formEmptyField={formValidation.flag}
                    invoiceIdStatus={invoiceIdPresent}
                    brandSKUList={brandSKUList}
                    stockQuantity={stockQuantity}
                    loadOptions={createSKU.brand === '' ? this.getSkuList : this.getBrandSkuList}
                />
              </div>
            </div>
          </div>
        </>
    );
  }
}

CreatePurchaseReturn.propTypes = propTypes;
CreatePurchaseReturn.defaultProps = defaultProps;

CreatePurchaseReturn.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
  bu_id: state.billing.bu_id || null,
  fiscalYearRange: state?.billing?.fiscalYearRange || null,
});

const createPurchaseReturn = connect(mapStateToProps)(CreatePurchaseReturn);

export default createPurchaseReturn;
