import React from 'react';
import PropTypes from 'prop-types';
import { roundAndCommas } from '../../../../utils/conversion';

const propTypes = {
  dataList: PropTypes.array,
  onRowClick: PropTypes.func,
};
const defaultProps = {};

const AggregatedLedgerDetailBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data, index) => {
      if (index === dataList.length - 1) {
        return (
          <tr className='pagination-wrapper'>
            <td className='left-align font-bold'>Total</td>
            <td className='right-align font-bold'>{data.openingBalance ? roundAndCommas(data.openingBalance) : 0}</td>
            <td className='right-align font-bold'>{data.debit ? roundAndCommas(data.debit) : 0}</td>
            <td className='right-align font-bold'>{data.credit ? roundAndCommas(data.credit) : 0}</td>
            <td className='right-align font-bold'>{data.closingBalance ? roundAndCommas(data.closingBalance) : 0}</td>
          </tr>
        );
      }

      return (
        <tr onClick={() => onRowClick(data)}>
          <td className='left-align'>{data.name}</td>
          <td className='right-align'>{data.openingBalance ? roundAndCommas(data.openingBalance) : 0}</td>
          <td className='right-align'>{data.debit ? roundAndCommas(data.debit) : 0}</td>
          <td className='right-align'>{data.credit ? roundAndCommas(data.credit) : 0}</td>
          <td className='right-align'>{data.closingBalance ? roundAndCommas(data.closingBalance) : 0}</td>
        </tr>
      );
    })}
  </tbody>
);

const AggregatedledgerReportDetailConfig = [
  {
    id: 2,
    label: 'title',
    title: 'Ledger Group',
    className: 'cursor-pointer',
    sortable: true,
  },
  {
    id: 3,
    label: 'opening_balance',
    title: 'Opening',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 4,
    label: 'debit',
    title: 'Debit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 5,
    label: 'credit',
    title: 'Credit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 6,
    label: 'closing_balance',
    title: 'Closing',
    className: 'right-align',
    sortable: false,
  },
];
AggregatedLedgerDetailBody.propTypes = propTypes;

AggregatedLedgerDetailBody.defaultProps = defaultProps;

const config = {
  header: AggregatedledgerReportDetailConfig,
  getTableBody: AggregatedLedgerDetailBody,
};

export default config;
