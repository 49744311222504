import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const SRNTableBody = ({ data, onRowClick }) => {
  return (
    <tr className='cursor-pointer' onClick={() => onRowClick(data)}>
      <td className='left-align'>{data.salesReturnInvoiceNumber}</td>
      <td className='left-align'>{data.refNumber}</td>
      <td className='left-align'>{data.customer.name}</td>
      <td className='right-align'>{data.netAmount.toLocaleString()}</td>
      <td className='left-align'>{moment(data.date).format('ll')}</td>
    </tr>
  );
};

SRNTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const srnConfig = {
  headerDetails: [
    { id: 1, label: 'salesReturnInvoiceNumber', title: 'SRN Number', className: 'cursor-pointer', sortable: true },
    { id: 6, label: 'voucherNumber', title: 'Sales Invoice Number', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'customerRefId', title: 'Customer Name', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'netAmount', title: 'SRN Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 5, label: 'date', title: 'Date', className: 'cursor-pointer left-align', sortable: true },
  ],
};

export { srnConfig, SRNTableBody };
