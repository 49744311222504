import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox, FormField } from '../BillingMDC';

const propTypes = {
  filterList: PropTypes.instanceOf(Array),
  filterTitle: PropTypes.string.isRequired,
  filterLabel: PropTypes.string.isRequired,
  filterFields: PropTypes.instanceOf(Object),
  handleFilterChange: PropTypes.func.isRequired,
  // primaryCheckBoxRequired: PropTypes.bool,
  collapsibleRequired: PropTypes.bool,
  selectedElementList: PropTypes.instanceOf(Array),
  singleChecker: PropTypes.bool,
  showCollapsibleList: PropTypes.bool,
};

const defaultProps = {
  selectedElementList: [],
  filterList: [],
  collapsibleRequired: true,
  singleChecker: false,
  filterFields: {
    idLabel: 'id',
    titleLabel: 'title',
  },
  showCollapsibleList: false,
  // primaryCheckBoxRequired: true,
};

class BaseFilter extends Component {
  constructor(props) {
    super(props);
    const { selectedElementList, filterList, filterFields } = this.props;
    const completeIdList = this.returnCompleteIdList(filterList, filterFields.idLabel);
    this.state = {
      checkedIdList:selectedElementList,
      showCollapsibleList: props.showCollapsibleList,
      completeIdList,
    };
  }
  componentDidMount() {
  }

  componentWillReceiveProps = nextProps => {
    this.setState({ checkedIdList: nextProps.selectedElementList });
  };

  /**
   * @param objectList
   * @param fieldName
   * return the complete ID List for the provided array
   */
  returnCompleteIdList = (objectList, fieldName) => {
    return objectList.map(object => object[fieldName]);
  };

  handlePrimaryCheckBoxClick = () => {
    const { checkedIdList, completeIdList } = this.state;
    const { filterList, filterLabel } = this.props;
    if (checkedIdList.length === filterList.length) {
      this.setState({ checkedIdList: [] }, () => {
        this.props.handleFilterChange(filterLabel, this.state.checkedIdList);
      });
    } else {
      // generate the set of array list
      this.setState({ checkedIdList: [].concat(completeIdList) }, () => {
        this.props.handleFilterChange(filterLabel, this.state.checkedIdList);
      });
    }
  };

  handleCheckBoxClick = (id,e) => {
    const checkedStatus = e.currentTarget.checked;
    let checkedIdList =this.state.checkedIdList;
      if(checkedStatus){
        checkedIdList.push(id) 
      }
      else{
        const index =checkedIdList.indexOf(id);
        checkedIdList.splice(index, 1);
    }
    this.setState({ checkedIdList }, () => {
      this.props.handleFilterChange(this.props.filterLabel, this.state.checkedIdList);
    });
  };

  render() {
    const { checkedIdList, showCollapsibleList } = this.state;
    const { filterList, filterTitle, filterFields, collapsibleRequired } = this.props;
    const { idLabel, titleLabel } = filterFields;
    const filterBody = filterList.map(filterComponent => {
    const checkIdListing =checkedIdList?.flat();
    const filterComponentArr = typeof(filterComponent[idLabel]) === 'number' ?[filterComponent[idLabel]] :filterComponent[idLabel];
    const checkedStatus =checkIdListing.some(r=> filterComponentArr.includes(r));
      return (
        <div key={filterTitle + filterComponent[idLabel]}>
          <FormField id={filterComponent[idLabel]}>
            <Checkbox checked={checkedStatus} onChange={(e) => this.handleCheckBoxClick(filterComponent[idLabel],e)} />
            <label htmlFor={filterComponent[idLabel]}>{filterComponent[titleLabel]}</label>
          </FormField>
        </div>
      );
    });

    return (
      <div>
        {/* {primaryCheckBoxRequired && <div>{filterTitle}</div>} */}
        {collapsibleRequired && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            className={showCollapsibleList ? 'filter-title passive-filter-title' : 'filter-title active-filter-title'}
            onClick={() => {
              this.setState({ showCollapsibleList: !showCollapsibleList });
            }}
          >
            {filterTitle}
          </div>
        )}
        {showCollapsibleList && <div className="filter-content">{filterBody}</div>}
        {!collapsibleRequired && <div>{filterBody}</div>}
      </div>
    );
  }
}

BaseFilter.defaultProps = defaultProps;
/**
 * @type {{filterList: (*), filterTitle: (*), filterFields: (*), handleFilterChange: (*)}}
 * filterList -> complete Filter List Obtained.
 * filterTitle -> Title for the filter.
 * filterFields -> specifies the labelName for id value (e.g id_channel) and title (e.g id_title)
 */

BaseFilter.propTypes = propTypes;

export default BaseFilter;
