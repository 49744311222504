import React from 'react';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { accountReportViewOptions } from '../../common/DrawerFilter/config';
import AccordionTable from '../../../components/CollapsibleTable/AccordionTable';
import ProfitLossChildView from './childView';

const TableBody = ({ data, onRowClick, label, index }) => (
  <>
    <td className='left-align' onClick={() => onRowClick(label, data.list, index)}>
      {' '}
      <span>{data.accountGroup}</span>{' '}
    </td>
    <td className='right-align' onClick={() => onRowClick(label, data.list, index)}>
      <span>{fixedFloatAndCommas(data.balance)}</span>
    </td>
  </>
);

const ProfitLossBody = ({ dataList, onRowClick, activeData, activeView, label }) => (
  <tbody>
    {activeView === accountReportViewOptions[0].label ? (
      <AccordionTable colSpan='2%'>
        {dataList.map((data, index) => (
          <tr
            key={`${data.accountGroupId}_${data.accountGroup}`}
            trigger={TableBody({
              data,
              onRowClick,
              label,
              index,
            })}
          >
            {ProfitLossChildView({ data: activeData })}
          </tr>
        ))}
      </AccordionTable>
    ) : (
      dataList.map((data) => (
        <tr className='collaspsible-disabled'>
          {TableBody({
            data,
            onRowClick: () => null,
          })}
        </tr>
      ))
    )}
  </tbody>
);

const ProfitLossConfig = [
  {
    id: 1,
    className: 'left-align',
    label: 'particulars',
    title: 'Particulars',
  },
  { id: 2, className: 'right-align', label: 'amount', title: 'Amount' },
];

const includeStockFilter = { label: 'show_stock', status: true, title: 'Include Stock Balance' };
const config = {
  header: ProfitLossConfig,
  getProfitBody: ProfitLossBody,
  getLossBody: ProfitLossBody,
  includeStockFilter,
};

export { TableBody, includeStockFilter };
export default config;
