import styled from 'styled-components';

const PickListStyled = styled.div`
  /* for print only */
  .pickList-body {
    p,
    h3,
    h5 {
      margin: 0;
    }
    .total-print{
      border-top:1px solid #000000;
      border-bottom:1px solid #000000 !important;
      td{
        font-weight:600;
      }
    }
    header {
      margin: 4px 0 10px 0;
      .top {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        .text-right {
          p {
            font-size: 16px;
          }
        }
        .text-left {
          p {
            font-size: 20px;
            font-weight: 600;
            text-transform: upperCase;
            strong {
              font-weight: 500;
            }
          }
        }
      }

      .bottom {
        text-align: center;
        width: 100%;
        margin-top: -6px;
        h3 {
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
        }
        p {
          font-size: 15px;
          line-height: 16px;
          margin-top: 4px;
        }
      }
    }
  }
  @media print{
    &.pick-list {
      .portrait{
        .print-body{
          height: 100% !important;
        padding:0 !important;
        margin:0.28in 0.3in !important;
        position: relative;
        overflow: unset;
        page-break-after: always;
          .fixed-table-wrapper{
            height:1400px !important;
            table{
              height:100%;
              .blank-tr{
                height:24px !important;
                border:0;
              }
              thead{
                th{
                  font-size: 14px !important;
                }
              }
              tbody{
                td{
                  font-size: 14px !important;
                  width:80px !important;
                  min-width:80px !important;
                  max-width:80px !important;
                  &.business,
                  &.brand{
                    width:100px !important;
                    min-width:100px !important;
                    max-width:100px !important;
                    white-space:normal !important;
                  }
                  &.sku{
                    width:250px !important;
                    min-width:250px !important;
                    max-width:250px !important;
                    white-space:normal !important;
                  }
                  &:last-child{
                    font-weight:500;
                  }
                }
              }
            }
          }
          .footer-block{
            position: absolute;
            bottom: -25px;
          }
        }
      }
    }
  }

  @media print and (max-width:5.83in) {
    &.pick-list {
      .portrait{
      .print-body{
        height:1520px !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
            height:1410px !important;
        }
      }
    }
  }
}

 table{
  thead:nth-child(2){
  th{
    top:49px;
  }
  }
tr.top-header{
  background: #F4F4F4;
  border-bottom:1px solid white;
  height:48px;
  th{
    font-size:14px;
  font-weight:700;
  color: #000000;
  position:sticky;
  top:0;
  p{
    margin:0;
  }
  p:not(.total){
    margin:0;
    font-size:9px;
    font-weight:400;
    line-height:13px;
    color:rgba(0,0,0,0.53);
  }
  .total{
    font-weight:400;
  }
  span{
    display:block;
    line-height:16px;
  }
  }
}
}
`;
export default PickListStyled;
