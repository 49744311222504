import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';
import '../App.css';

class DynamicSampleFileDownload extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleclick, businessId } = this.props;
    return (
      <span className='sample-csv' onClick={() => handleclick(businessId)}>
        <Icon name='get_app' />
        Sample CSV
      </span>
    );
  }
}

DynamicSampleFileDownload.propTypes = { handleClick: PropTypes.func, };

export default DynamicSampleFileDownload;
