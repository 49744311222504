import React, { Component } from 'react';
import Dialog,{ConfirmationDialog} from './Dialog';
import tableConfig from './config';
import { validateForm } from '../../common/validation';
import { clone } from '../../../utils/arrayProcessor';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import * as downloadService from '../../common/download.service';
import { CUSTOMER_VENDOR_CONFIG } from '../../common/domain.config';
import { checkIfApiCallSuccess, ledgerApi } from '../../common/base.api';
import { AddFab, BillingSnackBar } from '../../../components/BillingMDC';
import { ACTION, LEDGERS, PAN_NO_VALID_DIGIT ,MOBILE_NO_VALID_DIGIT} from '../../../data/enums/enums';
import WarningDialog from './WarningDialog';

class SalesInvoice extends Component {
  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { queryParameters } = this.state;
    const { data } = this.state;
    ledgerApi.getList(queryParameters, '&with_inactive=true')
      .then((response) => {
        data.list = response.data.list;
        data.total = response.data.total;
        this.setState({ data });
      });
  };
  getArea = () => {
    const { areaListAll } = this.props;
    const { queryParameters } = this.state;
    areaListAll(queryParameters).then(response => {
      const filteredArea =response?.list;
      this.setState({ areaList:filteredArea});
    });
  };
  getAgent = () => {
    const { agentListAll } = this.props;
    const { queryParameters } = this.state;
    agentListAll(queryParameters).then(response => {
      const filteredAgent =response?.list;
      this.setState({ agentList:filteredAgent});
    });
  };

  getAccountGroupList = () => {
    ledgerApi.getAccountGroupList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const accountGroupList = response.data;
        this.setState({ accountGroupList });
      }
    });
  };

  handleUploadIconClick = () => {
    this.setState({ dialogType: ACTION.UPLOAD });
  };

  handleEditIconClick = (data) => {
    const ledger = tableConfig.getDetail(data);
    this.fetchLedgerOutStandingBalance(ledger.customerId);
    this.getAccountGroupList();
    this.setState({ dialogType: ACTION.UPDATE, ledger,ledgerBackUp:clone(ledger) });
  };
  
  fetchLedgerOutStandingBalance = (customerId) => {
    ledgerApi.getOutstandingValue(customerId).then((response) => {
      if (checkIfApiCallSuccess(response)) {
        const outstandingAmount = response.data.outstandingAmount || 0;
        if (outstandingAmount > -1 && outstandingAmount < 1) {
          this.setState({ disableStatus: false });
        }
      }
    });
  };

  handleFabButtonClick = () => {
    const ledger = tableConfig.getDetail();
    this.getAccountGroupList();
    this.setState({ dialogType: ACTION.ADD, ledger });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  checkPanAlreadyExist = () => {
    const {ledger,dialogType}=this.state;
    const {checkPanExist} =this.props;
    checkPanExist(ledger).then((res)=>{
      if(res?.matched){
        this.setState({
          warningModal:res.matched,
          ledger
        })}else{
          switch (dialogType) {
            case ACTION.ADD:
              this.createLedger();
              break;
            case ACTION.UPDATE:
              this.editLedger();
              break;
            default:
              break;
          }
        }
    })
  }

  handleInputChange = (field, value) => {
    const { ledger,panValidation } = this.state;
    ledger[field] = value;

    if (field === 'panNo') {
      const flag = !((value.toString().length === PAN_NO_VALID_DIGIT || (value.toString().length === 1 && value === 0)));
      panValidation.flag = flag;
      this.setState({ panValidation });
    }
    if (field === 'openingBalance') {
      ledger.obChanged = true;
    }
    this.setState({ ledger });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({ dialogType: ACTION.NULL, validation, disableStatus: true,confirmStatus:true ,warningModal:false});
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  uploadLedger = () => {
    const { file } = this.state.ledger;
    const formData = new FormData();
    formData.append('file', file);
    const { upload } = this.props;
    this.handleModalClose();
    upload(formData).then((response) => {
      const totalLength = response.ledgerData.length;
      const snack = snackService.generateSuccessMessage(`${totalLength} Entry Created`);
      this.setState({ snack }, () => this.loadTableData());
    }).catch((err) => {
      let msg = '!Error';
      if (err) {
        const { error = {} } = err.data;
        msg = error.message;
      }
      const snack = snackService.generateFailureMessage(msg);
      this.setState({ snack });
    });
  };

  createLedger = () => {
    const { ledger, data } = this.state;
    const { create } = this.props;
    let apiTransformedData = tableConfig.getApiTransformedData(ledger);
    apiTransformedData = this.sanitiseApiRequest(apiTransformedData);
    create(apiTransformedData)
      .then((response) => {
        const snack = snackService.generateSuccessMessage();
        this.resetLedger();
        this.handleModalClose();
        this.setState({ snack,warningModal:false }, () => this.loadTableData());
      }).catch((err) => {
        const snack = snackService.generateFailureMessage(
          err?.message || err?.error?.message || 'Error while creating Ledger',
        );
        this.setState({ snack });
      });
  };

  editLedger = () => {
    const { ledger, data,confirmStatus} = this.state;
    const { update } = this.props;
    const valid =confirmStatus ? this.checkValidation() :true;
  if(valid){
    let apiTransformedData = tableConfig.getApiTransformedData(ledger, ACTION.UPDATE);
    apiTransformedData = this.sanitiseApiRequest(apiTransformedData);
    update({ id: ledger.idLedger, query: apiTransformedData }).then((res) => {
      const updatedDataList = data.list.map(
        (item) => (item.idLedger === Number(res.ledger.idLedger) ? tableConfig.getDetail(res.ledger) : item),
      );
      data.list = updatedDataList;
      const snack = snackService.generateUpdateMessage();
      this.loadTableData();
      this.resetLedger();
      this.handleModalClose();
      this.setState({ data, snack });
    }).catch((err) => {
      const snack = snackService.generateFailureMessage(
        err?.message ||  err?.error?.message || 'Error while updating Ledger',
      );
      this.setState({ snack });
    });
  }
  };

  resetLedger = () => {
    this.setState({ ledger: tableConfig.getDetail() });
  }

  sanitiseApiRequest(data) {
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'string') { data[key] = (data[key]).trim(); }
    });
    return data;
  }
  checkValidation = () =>{
    const {ledger,
    ledgerBackUp,
  } = this.state;
    if(ledger.openingBalance !==ledgerBackUp.openingBalance){
      this.setState({confirmStatus:false,readModal:true},()=>{
      });
      return false;
  }
  return true;
}

  handleModalSubmit = () => {
    // upload or set data from the form.
    const {
      ledger,
      validation,
      panValidation,
      dialogType,
    } = this.state;
    const checkaccountGroup = (ledger.accountGroup === LEDGERS.CUSTOMER.type || ledger.accountGroup === LEDGERS.VENDOR.type);
    const validationConfig = dialogType === ACTION.UPLOAD ? {
      flag: validation.flag,
      fieldList: [{ title: 'file' }],
    } : validation;
    if (Number(ledger?.phoneNumber) !==0  && ledger?.phoneNumber?.toString()?.length !== MOBILE_NO_VALID_DIGIT) {
      validation.flag = true;
      this.setState({ validation });
      return;
    }
    const formValid = validateForm(ledger, validationConfig, (valid) => this.setState({ validation: valid }));
    const PANValidation = Number(ledger.panNo) === 0 ? true : validateForm(ledger, panValidation, (valid) => this.setState({ panValidation: valid }));
    if (checkaccountGroup ? PANValidation : formValid) {
      switch (dialogType) {
        case ACTION.UPLOAD:
          this.uploadLedger();
          break;
        case ACTION.ADD:
          this.checkPanAlreadyExist();
          break;
        case ACTION.UPDATE:
          this.checkPanAlreadyExist();
          break;
        default:
          break;
      }
    }
  };

  handleFileUpload = event => {
    event.preventDefault();
    const { ledger } = this.state;
    ledger.file = event.target.files[0];

    this.setState({ ledger });
  };

  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
      },
      dialogType: ACTION.NULL,
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          account_groups: [],
        },
      },
      data: {
        list: [],
        total: 0,
      },
      ledger: tableConfig.getDetail(),
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
      panValidation: {
        flag: false,
        fieldList: tableConfig.formWithPANValidation,
      },
      accountGroupList: [],
      ledgerBackUp:{},
      disableStatus: true,
      readModal:false,
      confirmStatus:true,
      warningModal:false,
      areaList:[],
      agentList:[],
    };

    this.reference = {
      tableHeader: React.createRef(),
      tableFooter: React.createRef(),
      tableBody: React.createRef(),
      fileRef: React.createRef(),
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    this.getArea();
    this.getAgent();
    /** tableLayoutService.adjustToScreen(
     this.refs.tableReference,
     this.refs.fixedTableFooter,
     this.refs.fixedTableBody,
     ); */
  }

  handleDownloadClick = (type, label) => {
    const { queryParameters } = this.state;
    const { downloadList, detailDownloadList } = this.props;
    if (label === 'customer_ledger') {
      detailDownloadList({
        type,
        query: queryParameters,
        extraQueryString: `&account_group=${LEDGERS.CUSTOMER.type}`,
      }).then((response) => downloadService.resolver(response));
    } else {
      downloadList({ type, query: queryParameters }).then((response) => downloadService.resolver(response));
    }
  };
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        account_groups: [],
      },
    });
  }
  getConfirmation =() =>{
    const snack = snackService.generateSuccessMessage('Confirmation of changing opening Balance is received.');
    this.setState({snack})
    this.handleConfirmModalClose();
  }
  handleConfirmModalClose =() =>{
    this.setState({readModal:false})
  }

  handleWarningClose = () =>{
    const {ledger} =this.state;
    this.setState({
      warningModal:false,
      dialogType: ACTION.ADD,
      ledger
    })
  }

  handleWarningSubmit = () => {
    const {dialogType} = this.state;
    if(dialogType===ACTION.ADD){
      this.createLedger();
    }else{
      this.editLedger();
    }
  }
  render() {
    const {
      queryParameters,
      data,
      display,
      ledger,
      snack,
      dialogType,
      validation,
      accountGroupList,
      panValidation,
      disableStatus,
      ledgerBackUp,
      readModal,
      warningModal,
      areaList,
      agentList,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <div className="customer-ledger">
        <PageView
          domainConfig={CUSTOMER_VENDOR_CONFIG}
          headerConfig={
            {
              search: true,
              upload: true,
              download: true,
              filter: true,
              create: true,
            }
          }

          display={display}
          controlDisplay={this.controlDisplay}
          handleUploadClick={this.handleUploadIconClick}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.handleResetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleDownloadClick={this.handleDownloadClick}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleEditIconClick}
          onPageSelect={this.basePaginationService.onPageSelect}
          createHandler={this.handleFabButtonClick}
          handleFilterChange={this.basePaginationService.handleFilterChange}
        />

        {/* <AddFab handleFabClick={this.handleFabButtonClick} /> */}

        <Dialog
          ledger={ledger}
          ledgerBackUp ={ledgerBackUp}
          validation={validation}
          actionType={dialogType}
          invlidPAN={false}
          formEmptyField={false}
          handleFileUpload={this.handleFileUpload}
          onInputChange={this.handleInputChange}
          onModalClose={this.handleModalClose}
          onModalSubmit={this.handleModalSubmit}
          accountGroupList={accountGroupList}
          panValidation={panValidation.flag}
          ledgerStatus={tableConfig.ledgerStatus}
          disableStatus={disableStatus}
          areaList={areaList}
          agentList={agentList}
        />
        { readModal && 
        <ConfirmationDialog
        ledger={ledger}
        ledgerBackUp ={ledgerBackUp}
        onConfirmModalClose={this.handleConfirmModalClose}
        getConfirmation={this.getConfirmation}
        readModal ={readModal}
        />
  }

  {warningModal && (
    <WarningDialog
      warningModal={warningModal}
      handleWarningClose={this.handleWarningClose}
      onModalSubmit={this.handleWarningSubmit}
      id={ledger.idLedger}
    />
    )
  }

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

const CustomerWithState = withBaseState(SalesInvoice);

export default CustomerWithState;
