import { STATUS } from '../../data/enums/enums';

const getBUFilterList = (buList) => buList.filter((bu) => bu.status === STATUS.ACTIVE && !bu.isRosiaIntegrated);

const getRosiaDisabledList = (buList) => buList.filter((list) => list.isRosiaIntegrated === true);

const getRosiaEnabledStatus = (bu = {}) => {
  return bu.isRosiaIntegrated;
};

const getIntegratedBUList = (buList) => buList.filter((bu) => bu.status === STATUS.ACTIVE && bu.isRosiaIntegrated);

const changeBUFilterQueryTitle = (queryParameters, extraQuery) => ({
  query: { ...queryParameters, filter: { business_id: queryParameters.filter.business_id_thirdParty } },
  extraQuery,
});

const getActiveBUList = (buList) => buList.filter((bu) => bu.status === STATUS.ACTIVE);

const checkBUId = (businessUnitId) => (businessUnitId === 0 ? null : businessUnitId);

const addIndexInPrintDataSet = (dataList) => {
  const group = [...dataList];
  let index = 0;
  for (let i = 0; i < group.length; i++) {
    for (let j = 0; j < group[i].list.length; j++) {
      group[i].list[j].index = index;
      index += 1;
    }
  }
  return [...group];
};
const getSortedByInvoiceNumber = (data) =>{
  const sortedData=data.sort((a, b) => {
    return a.invoice_number.localeCompare(b.invoice_number, 'en', {
      numeric: true,
      sensitivity: 'base'
    })
  })
  return sortedData;
}
const storeInLocalStorage = (key, data, domain) => {
  localStorage.setItem(`${domain}-${key}`, JSON.stringify(data));
};
const fetchFromLocalStorage = (key, domain) => JSON.parse(localStorage.getItem(`${domain}-${key}`));
const getQuantityValidationStatus = (stockQuantity, skuObject) => {
  let breakingQuantityLabel = '';
  const condition = Object.keys(stockQuantity).every((q) => {
    if(stockQuantity[q] < skuObject[q]) {
      breakingQuantityLabel = q;
    }
    return stockQuantity[q] >= skuObject[q];
  })
  return { condition, breakingQuantityLabel };
}

export {
  getBUFilterList, getRosiaDisabledList, getRosiaEnabledStatus,getIntegratedBUList,changeBUFilterQueryTitle,
  getActiveBUList, checkBUId, addIndexInPrintDataSet, getQuantityValidationStatus,getSortedByInvoiceNumber,storeInLocalStorage,fetchFromLocalStorage,
};
