import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../../../components/BillingMDC';

import { ACTION } from '../../../../../data/enums/enums';
import DatePicker from '../../../../../components/DatePicker';
import BillingTextField from '../../../../../components/TextField/BillingTextField';

const propTypes = {
  actionType: PropTypes.string,
  batch: PropTypes.instanceOf(Array),
  onInputChange: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onModalSubmit: PropTypes.func.isRequired,
  validation: PropTypes.shape({
    fieldList: PropTypes.instanceOf(Array),
    flag: PropTypes.bool,
  }),
};

const defaultProps = {
  actionType: null,
  batch: {},
  validation: { flag: false, fieldList: [] },
};

const SKUBatchDialog = ({
  batch,
  actionType,
  onModalClose,
  onModalSubmit,
  onInputChange, // onChange
  validation,
}) => (
  <Dialog
    open={actionType !== ACTION.NULL}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      <DialogTitle>{actionType} SKU Batch/ Rate</DialogTitle>
    </DialogHeader>

    <DialogBody>
      <div className='dialog-upperpart'>
        <div>
          <Grid className='grid-padding'>
            <Cell col={4} tablet={8}>
              <BillingTextField
                value={batch.title}
                handleChange={onInputChange}
                floatingLabel='Batch'
                param='title'
                required
                emptyField={validation.flag}
              />
            </Cell>
            <Cell col={4} tablet={8} className='input-select sku-modal-date'>
              <DatePicker
                date={moment(batch.mfgDate)}
                showLabel
                showIcon
                label='Manufactured Date'
                onChange={(e) => onInputChange('mfgDate', e)}
              />
            </Cell>

            <Cell col={4} tablet={8} className='input-select sku-modal-date'>
              <DatePicker
                date={moment(batch.expiryDate)}
                showLabel
                showIcon
                label='Expiry Date'
                onChange={(e) => onInputChange('expiryDate', e)}
              />
            </Cell>
          </Grid>

          <Grid className='grid-padding padding-top-24'>
            <Cell col={4} tablet={8}>
              <BillingTextField
                value={batch.dlp}
                handleChange={onInputChange}
                floatingLabel='DLP'
                param='dlp'
                type='number'
                className='right-side-gap'
                required
                emptyField={validation.flag}
              />
            </Cell>

            {/* <Cell col={4} tablet={8}>
              <BillingTextField
                value={batch.rlp}
                handleChange={onInputChange}
                floatingLabel="RLP"
                param="rlp"
                type="number"
                required
                emptyField={validation.flag}
              />
            </Cell> */}
            <Cell>
              <BillingTextField
                value={batch.vat}
                handleChange={onInputChange}
                floatingLabel='VAT'
                param='vat'
                type='number'
                className=''
                disabled
              />
            </Cell>
          </Grid>
        </div>
      </div>
    </DialogBody>
    <DialogFooter>
      <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
        Cancel
      </Button>
      <Button
        accent
        className='dialog-ok modal-btn'
        onClick={() => {
          onModalSubmit();
        }}
      >
        Save
      </Button>
    </DialogFooter>
  </Dialog>
);

SKUBatchDialog.propTypes = propTypes;

SKUBatchDialog.defaultProps = defaultProps;

export default SKUBatchDialog;
