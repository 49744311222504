import React from 'react';
import PropTypes from 'prop-types';
import { ACTION } from '../../../data/enums/enums';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';

const propTypes = {
  onModalClose: PropTypes.func,
  onInputChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  onModalSubmit: PropTypes.func,
  validation: PropTypes.shape({
    fieldList: PropTypes.instanceOf(Array),
    flag: PropTypes.bool,
  }),
  ledger: PropTypes.shape({
    list: PropTypes.instanceOf(Array),
    idLedgerGroup: PropTypes.number,
    title: PropTypes.string,
  }),
  customerList: PropTypes.array,
  actionType: PropTypes.string,
};
const defaultProps = {};

const LedgerDialog = ({
  ledger,
  actionType,
  onModalClose,
  onModalSubmit,
  onInputChange,
  onSelectChange,
  customerList,
  validation,
}) => {
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>{actionType}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        {actionType !== ACTION.DELETE && (
          <div className='dialog-upperpart'>
            <Grid className='grid-padding'>
              <Cell col={12}>
                <BillingTextField
                  value={ledger.title}
                  handleChange={onInputChange}
                  floatingLabel='Name'
                  param='title'
                  required={true}
                  className='right-side-gap'
                  emptyField={validation.flag}
                  placeholder='Ledger Groups Title'
                />
              </Cell>
              <Cell col={12}>
                <label>Ledgers</label>
                <BillingSelect
                  multipleParam
                  multipleSelect
                  name='form-field-name'
                  param='ledger_ids'
                  clearable={false}
                  value={customerList.filter(({ customerId }) =>
                    ledger.ledger_ids ? ledger.ledger_ids.includes(customerId) : false,
                  )}
                  getOptionValue={({ customerId }) => customerId}
                  getOptionLabel={({ titlePan }) => titlePan}
                  options={customerList}
                  onChange={(event) => {
                    const customerList = event ? event.map((d) => d.customerId) : event;
                    onSelectChange(customerList, ['ledger_ids']);
                  }}
                  required={true}
                  emptyField={validation.flag}
                  placeholder='Ledger Name (PAN No)'
                  errorMessage={'Should Select at least 1 ledger'}
                />
              </Cell>
            </Grid>
          </div>
        )}
        {actionType === ACTION.DELETE && <div className='default-margin-24'>Are you sure you want to delete ?</div>}
      </DialogBody>
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
LedgerDialog.protoTypes = propTypes;
LedgerDialog.defaultProps = defaultProps;

export default LedgerDialog;
