import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { roundAndCommas } from '../../../utils/conversion';

const propTypes = { data: PropTypes.instanceOf(Array) };

const defaultProps = { data: {} };

const LedgerWiseChildView = ({ data ,onSpanClick }) => (
  <>
    {data.tags && data.tags.map((tag, i) => <Row key={i} data ={data} rowData={tag}onSpanClick={onSpanClick} />)}
  </>
);

const Row = ({ rowData ,onSpanClick }) => (
  <tr onClick={(e) => {
    e.stopPropagation();
    onSpanClick(rowData)}}>
    <td className='ledger' >{rowData.tag.title}</td>
    <td className='text-right opening'>{rowData.opening ? roundAndCommas(rowData.opening) : rowData.opening}</td>
    <td className='openingStatus'>{rowData.openingType}</td>
    <td className='text-right debit'>{rowData.debit ? roundAndCommas(rowData.debit) : rowData.debit }</td>
    <td className='text-right credit'>{rowData.credit ? roundAndCommas(rowData.credit) : rowData.credit}</td>
    <td className='text-right closing'>{rowData.closing ? roundAndCommas(rowData.closing) : rowData.closing}</td>
    <td className='closingStatus'>{rowData.closingType}</td>
  </tr>
);

LedgerWiseChildView.propTypes = propTypes;

LedgerWiseChildView.defaultProps = defaultProps;

export default LedgerWiseChildView;
