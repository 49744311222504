import styled from 'styled-components';

const ProfitLossStyle = styled.div`
  .inner-view {
    background: white;
    height: 100%;
    padding: 38px 24px;
    box-shadow: 0 6px 6px 0 rgba(33, 150, 243, 0.24), 0 0 6px 0 rgba(33, 150, 243, 0.12);
    overflow: auto;
    .trial-view {
      border: 1px solid #e7e7ed;
      width: fit-content;
      min-width:800px;
      max-width:100%;
      overflow: hidden;
      .top-header {
        text-align: center;
        padding: 10px;
        span {
          font-size: 12px;
          padding: 10px;
          color: #393a4a;
        }
      }
      .mid-header {
        height: 32px;
        background: #dff3ff;
        padding: 8px;
        display: flex;
        text-align: center;
        align-items: center;

        span {
          color: #2196f3;
          width: 50%;
          padding: 10px;
          &:first-child {
            border-right: 1px solid #e7e7ed;
          }
        }
      }
      .profit-loss {
        display: flex;
        background: #fff;
        border-top: 1px solid #e7e7ed;
        height: 48px;
        span {
          color: #000000;
          width: 50%;
          padding: 14px 24px;
          font-size: 12px;
          &:nth-child(3) {
            border-left: 1px solid #e7e7ed;
          }
        }
      }
      .total {
        display: flex;
        background: #f8f8f8;
        border-top: 1px solid #e7e7ed;
        height: 48px;
        span {
          font-weight: bold;
          color: #000000;
          font-size: 13px;
          width: 50%;
          padding: 14px 24px;
          font-size: 12px;
          &:nth-child(3) {
            border-left: 1px solid #e7e7ed;
          }
        }
      }
      .table-view {
        display: flex;
        .table-wrap{
          flex:1;
        }
        table {
          thead{
            position:relative;
            th{
              position:sticky;
              z-index:1;
              top: 0;
              box-shadow: inset 0 0px 0 black, inset 0 -1px 0 #e7e7ed;
            }
            &.one th{
              height: 32px;
              background: rgb(223, 243, 255);
              text-align:center;
              color: rgb(33, 150, 243);
              font-weight:400;
            }
          }
          th,
          td {
            white-space: nowrap;

            span{
              white-space: nowrap;
            }
          }
          td {
            line-height: 21px;
            padding: 5px 24px;
          }
          &:first-child {
            tbody {
              tr {
                td:last-child {
                  white-space:nowrap;
                }
              }
            }
          }
          &:last-child { 
          }
          tr {
            height: 32px;
            th {
              background: white;
              font-weight: bold;
              color: #000000;
            }
            td {
              font-size: 12px;
              color: #000000;
            }
          }
          td:last-of-type,
          th:last-of-type {
            width: 10%;
          }
        }
      }
    }
  }
  .collaspsible-disabled {
    td {
      span {
        padding-left: 0 !important;
        &:before {
          border: 0 !important;
        }
      }
    }
  }
  .table-view {
    .cards{
      border:1px solid #e7e7ed;
      flex:1;
      position:relative;
      footer{
       flex:1;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        height:32px;
        background-color:#ffffff;
        align-items:center;
        background: rgb(248, 248, 248);
      border-top: 1px solid rgb(231, 231, 237);
      z-index: 2;
        height: 48px;
        p{
          margin:0;
          font-weight: bold;
        color: rgb(0, 0, 0);
           font-size: 12px;
        }
      }
      .table-wrap{
        height: calc(100vh - 360px);
        overflow: auto;
      }
    }
    footer{
      display:flex;
      p{
        margin:o;
      }
    }
    table {
      thead {
        tr th {
          width: 50%;
        }
      }
      tbody {
        tr {
          td {
            width: 50%;
          }
        }
        .collapsible-body-tr {
          border-top: 2px solid #2196f3;
          & > td {
            padding: 0;
            border: 0 !important;
            table {
              box-shadow: rgb(39 40 51 / 12%) 0px 4px 8px 0px;
              border: none;
              td {
                border: 0 !important;
                padding-left: 50px;
              }
            }
          }
        }
        .collapsible-body-tr {
          & > td {
            /* padding: 0; */
          }
          table {
          }
        }
      }
    }
  }
  table tbody {
    tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #2196f3;
        }
      }
      td:first-child {
        & > span {
          position: relative;
          padding-left: 26px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }
    }
  }
`;
export default ProfitLossStyle;
