import { fixedFloatAndCommas } from '../../../utils/conversion';

const getTableData = dataList => {
  let tableDataRows = [];
  let wholeTotalSales = 0;
  if (dataList && dataList.length > 0) {
    tableDataRows = [...dataList ]?.map((data, index) => {
      const totalSales = (data.tax_exempted_sales ? data.tax_exempted_amount : 0) + (data.taxable_sales_amount ?? 0);
      wholeTotalSales += totalSales;
      return [
        index + 1,
        data.miti ?? '',
        data.bill_no.trim() ?? '',
        data.ledger_name ?? '',
        data.pan_no ?? '',
        data.sku ?? '',
        data.quantity ?? '0',
        (data.quantity > 1 ? 'PCs' : 'PC') ?? '',
        fixedFloatAndCommas(totalSales) ?? '0',
        data.tax_exempted_sales ? fixedFloatAndCommas(data.tax_exempted_amount) : data.tax_exempted_amount,
        fixedFloatAndCommas(data.taxable_sales_amount) ?? '0',
        fixedFloatAndCommas(data.taxable_sales_vat) ?? '0',
        fixedFloatAndCommas(data.export_sales) ?? '0',
        data.export_country ?? '',
        data.export_bill_number ?? '',
        data.export_date ?? '',
        JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise ? fixedFloatAndCommas(data.excise) ?? '0' : '',
      ];
    });
  }
  return {
    tableDataRows,
    wholeTotalSales
  };
};

const getFilteredData = (dataList, companyData, middleHeaderData, columnTotal) => {
  const tableData = getTableData(dataList);

  return {
    companyName: companyData?.title ?? '',
    address: companyData?.address ?? '',
    phone: companyData?.phone ?? '',
    panNo: companyData?.panNo || companyData?.panNumber || '',
    email: companyData?.email ?? '',
    title: middleHeaderData?.title ?? '',
    date: middleHeaderData?.date ?? '',
    tableDataRows: tableData?.tableDataRows ?? [],

    tableFooterData: {
      totalItemQuantity: fixedFloatAndCommas(columnTotal?.totalQuantity)?.toString() ?? '0',
      totalSales: fixedFloatAndCommas(tableData?.wholeTotalSales)?.toString() ?? '0',
      totalTaxExempted: fixedFloatAndCommas(columnTotal?.taxExemptedSales)?.toString() ?? '0',
      totalExportSales: fixedFloatAndCommas(columnTotal.exportSales) ?? '0',
      totalVat: fixedFloatAndCommas(columnTotal.vat)?.toString() ?? '0',
      totalTaxableAmount: fixedFloatAndCommas(columnTotal.totalTaxableAmount)?.toString() ?? '0',
      totalExcise: JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise
        ? fixedFloatAndCommas(columnTotal.excise)?.toString() ?? '0'
        : '',
    },
  };
};

const getPrintData = (dataList, companyData, middleHeaderData, columnTotal) => {
  const ledgerData = getFilteredData(dataList, companyData, middleHeaderData, columnTotal);
  return ledgerData;
};

export default getPrintData;
