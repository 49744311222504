import moment from 'moment/moment';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogTitle,
  DialogFooter,
  Button,
  Grid,
  Cell,
  Checkbox,
} from '../../../components/BillingMDC';
import DatePicker from '../../../components/DatePicker/DatePicker';
import BillingTextField from '../../../components/TextField/BillingTextField';
import BillingSelect from '../../../components/Select/BillingSelect';
import { LEDGERS } from '../../../data/enums/enums';

const CeDialogView = ({
  ledgerList,
  onModalClose,
  modalOpen,
  editModalOpen,
  deleteModalOpen,
  createCE,
  handleInputChange,
  onModalSubmit,
  formEmptyField,
  handleDateChange,
  transactionDisabled,
}) => (
  <Dialog
    open={modalOpen || editModalOpen || deleteModalOpen}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      {modalOpen && <DialogTitle>Add Contra Entry</DialogTitle>}
      {editModalOpen && <DialogTitle>Edit Contra Entry</DialogTitle>}
      {deleteModalOpen && <DialogTitle>Delete Contra Entry</DialogTitle>}
    </DialogHeader>
    {!deleteModalOpen && (
      <DialogBody>
        <div className='dialog-upperpart'>
          <Grid className='grid-padding'>
            <Cell col={6} tablet={6}>
              <label>RefDate</label>
              <span className='dialog-date-picker-wrapper'>
                <DatePicker date={moment(createCE.ref_date)} onChange={(e) => handleDateChange('ref_date', e)} />
              </span>
            </Cell>
            <Cell col={6} tablet={6} className='input-field'>
              <BillingTextField
                value={createCE.ref_number}
                handleChange={handleInputChange}
                floatingLabel='RefNumber'
                param='ref_number'
                required={false}
                className='billing-required right-side-gap'
                emptyField={formEmptyField}
              />
            </Cell>
          </Grid>

          <Grid className='grid-padding'>
            <Cell col={6} tablet={6}>
              <label className='select-custom-label'>To Account</label>
              <BillingSelect
                name='form-field-name'
                param='ledger_id'
                clearable={false}
                value={createCE.ledger_id}
                valueKey='customerId'
                labelKey='title'
                options={ledgerList}
                handleChange={handleInputChange}
                required={true}
                multipleParam={true}
              />
            </Cell>

            <Cell col={6} tablet={6}>
              <BillingTextField
                value={createCE.amount}
                handleChange={handleInputChange}
                floatingLabel='Amount'
                param='amount'
                type='number'
                required={true}
                zeroError={!transactionDisabled.aount}
                className='billing-required right-side-gap'
                emptyField={formEmptyField}
                disabled={transactionDisabled.credit}
              />
            </Cell>
          </Grid>
          <Grid className='grid-padding'>
            <Cell col={12} tablet={12}>
              <BillingTextField
                value={createCE.narration}
                param='narration'
                floatingLabel='Narration'
                required={true}
                className='textbox billing-required'
                emptyField={formEmptyField}
                handleChange={handleInputChange}
              />
            </Cell>
          </Grid>
        </div>
      </DialogBody>
    )}

    {deleteModalOpen && <div className='default-margin-24'>Are you sure you want to delete ?</div>}

    <DialogFooter>
      <Button
        accent
        className='dialog-cancel modal-btn'
        onClick={() => {
          onModalClose();
        }}
      >
        Cancel
      </Button>
      <Button
        accent
        className='dialog-ok modal-btn'
        onClick={() => {
          onModalSubmit();
        }}
      >
        Ok
      </Button>
    </DialogFooter>
  </Dialog>
);

export default CeDialogView;
