import React, { Component } from 'react';
import tableConfig from './config';
import { validateForm } from '../../common/validation';
import * as snackService from '../../common/snack.service';
import { checkIfApiCallSuccess, ledgerApi } from '../../common/base.api';
import {
  BillingSnackBar,
  Button,
  Cell,
  Checkbox,
  FormField,
  Grid,
  Radio,
  RadioGroup,
  Icon,
} from '../../../components/BillingMDC';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import HorizontalLabelValueBar from '../../common/horizontalLabellValueBar';
import Switch from '../../../components/Switch';
import Loading from '../../../common/Loading';
import { decimalRoundingType, LEDGERS } from '../../../data/enums/enums';
import SettingStyled from './SettingStyled';
import { JWT } from '../../../environment';

export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      data: {
        list: [],
        total: 0,
      },
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
      roundingOptions: tableConfig.roundingOptions,
      roundingDigits: tableConfig.roundingDigits,

      settings: {
        decimal_rounding: {
          type: '',
          digits: 2,
          ledger: '',
          status: false,
        },
        editRate: false,
        addTag: false,
        addExcise: false,
        enableA5: false,
        excise_number: '',
        print_remarks: '',
        backDated: false,
        credit: {
          limit: false,
          days: 0,
        },
      },
      ledgerList: [],
    };
  }

  componentDidMount() {
    this.getLedgerList();
    this.fetchSettingsFromApi();
  }

  getLedgerList = () => {
    ledgerApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        let responseDataList = response.data.list;
        responseDataList = responseDataList.filter(ledger => {
          return ledger.accountGroup !== LEDGERS.CUSTOMER.type && ledger.accountGroup !== LEDGERS.VENDOR.type;
        });
        responseDataList = responseDataList.map(ledger => {
          return {
            title: ledger.title,
            customerId: ledger.customerId,
            ledger_id: ledger.idLedger,
          };
        });
        this.setState({ ledgerList: responseDataList });
      }
    });
  };

  fetchSettingsFromApi = () => {
    const { getSettings } = this.props;
    const { settings } = this.state;
    const { queryParameters } = {};
    getSettings(queryParameters).then(response => {
      const mergedSetting = { ...settings, ...response };
      this.setState({ settings: mergedSetting });
    });
  };

  handleSubmit = () => {
    const { settings, validation } = this.state;
    const newValidation = settings.addExcise ? validation : { ...validation, fieldList: [] };
    const formValid = validateForm(settings, newValidation, valid => this.setState({ validation: valid }));
    if (!formValid) return false;
    this.editSettings();
  };

  validateSettings(settings) {
    let decimal_rounding = settings.decimal_rounding;
    if (decimal_rounding.status && decimal_rounding.ledger === '') {
      return { status: false, message: 'Missing ledger.' };
    }
    //todo:: add more validation
    return { status: true, message: '' };
  }

  editSettings = () => {
    const { updateSettings } = this.props;
    const { settings } = this.state;
    const { status, message } = this.validateSettings(settings);
    if (!status) {
      const snack = snackService.generateFailureMessage(message);
      this.setState({
        snack,
      });
      return;
    }

    updateSettings({
      settings,
    })
      .then(res => {
        localStorage.setItem(JWT.LOCAL_STORAGE.SETTINGS.NAME, JSON.stringify(res.settings));
        const rosiaCompany = JSON.parse(localStorage.getItem('rosiaCompany'));
        rosiaCompany.enableExcise = res.enable_excise;
        rosiaCompany.addExcise = res.enable_excise;
        localStorage.setItem('rosiaCompany', JSON.stringify(rosiaCompany));
        const snack = snackService.generateUpdateMessage('Settings updated!');
        this.setState({
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({
          snack,
        });
      });
  };
  handleInputChange = (field, value) => {
    let { settings } = this.state;
    settings.decimal_rounding[field] = value;
    if (field === 'status') {
      settings.decimal_rounding.status = !!value;
      if (!value) {
        settings.decimal_rounding.ledger = '';
        settings.decimal_rounding.type = decimalRoundingType.ROUND_UP;
      }
    }
    this.setState({ settings: settings });
  };
  handleSettingsChange = (field, value) => {
    let { settings } = this.state;
    if (['limit', 'days'].includes(field)) {
      settings.credit[field] = !!value;
    } else if (['print_remarks', 'excise_number'].includes(field)) {
      settings[field] = value;
    } else {
      settings[field] = !!value;
    }
    this.setState({ settings: settings });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { ledgerList, roundingOptions, roundingDigits, snack, validation, settings } = this.state;
    const { serverResponseWaiting } = this.props;
    const roundingStatus = settings.decimal_rounding.status;
    const selectedDigit = settings.decimal_rounding.digits;
    const selectedType = settings.decimal_rounding.type;
    const selectedLedger = settings.decimal_rounding.ledger;
    const addTag = settings.addTag || false;
    const backDated = settings.backDated || false;
    const addExcise = settings.addExcise || false;
    const enableA5 = settings.enableA5 || false;
    return (
      <SettingStyled>
        <div className="pannel-section">
          <div className="pannel-header">
            <h1>Settings</h1>
          </div>
          <Loading display={serverResponseWaiting} />
          <h4>1. Invoice Details</h4>
          <div>
            <div className="pannel-card">
              <Grid className="grid-padding">
                <Cell col={4} tablet={4} className="display_none">
                  <label className="select-custom-label"> Select decimal numbers to be used</label>
                  <BillingSelect
                    name="form-field-name"
                    param={'digits'}
                    clearable={false}
                    value={selectedDigit}
                    valueKey="value"
                    labelKey="title"
                    options={roundingDigits}
                    handleChange={this.handleInputChange}
                    required={true}
                  />
                </Cell>
              </Grid>

              <div className="switch_radio">
                <div className="form-switchs">
                  <HorizontalLabelValueBar
                    label="Decimal rounding"
                    value={
                      <Switch
                        onToggle={(checked, event) => this.handleInputChange('status', checked ? 1 : 0)}
                        checked={roundingStatus}
                      />
                    }
                  />
                </div>

                {roundingStatus && (
                  <div className="radio-list-wrapper flex">
                    <div className="float-left">
                      <RadioGroup
                        onChange={event => {
                          this.handleInputChange('type', event.target.value);
                        }}
                        name="rounding_options"
                        value={selectedType}
                      >
                        {roundingOptions.map(item => (
                          <Radio value={item.value}>{item.title}</Radio>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                )}
              </div>

              {roundingStatus && (
                <Grid className="grid-padding">
                  <Cell col={3} tablet={4}>
                    <label className="select-custom-label"> Select Ledger</label>
                    <BillingSelect
                      name="form-field-name"
                      param="ledger"
                      clearable={false}
                      value={selectedLedger}
                      valueKey="customerId"
                      labelKey="title"
                      options={ledgerList}
                      handleChange={this.handleInputChange}
                      required={true}
                      multipleParam={true}
                    />
                  </Cell>
                </Grid>
              )}

              <Grid className="grid-padding custom-checkbox">
                <Cell>
                  <Checkbox
                    checked={settings.editRate}
                    onChange={({ target: { checked } }) => {
                      this.handleSettingsChange('editRate', checked);
                    }}
                  />
                  <label className="select-custom-label">Allow users to edit RLP (Retail Landing Price)</label>
                </Cell>
              </Grid>
            </div>
          </div>

          <h4>2. Tag Settings</h4>
          <div className="pannel-card">
            <div className="switch_radio">
              <div className="form-switchs">
                <label className="select-custom-label">Make tag compulsory</label>
                <Switch
                  onToggle={(checked, event) => this.handleSettingsChange('addTag', checked ? 1 : 0)}
                  checked={addTag}
                />
              </div>
            </div>
          </div>

          <h4>3. Excise Settings</h4>
          <div className="pannel-card">
            <div className="switch_radio">
              <div className="form-switchs excise-switch">
                <label className="select-custom-label">Enable Excise</label>
                <Switch
                  onToggle={(checked, event) => this.handleSettingsChange('addExcise', checked ? 1 : 0)}
                  checked={addExcise}
                />
              </div>
            </div>
            {addExcise && (
              <div className="form-input excise-number">
                <Grid>
                  <Cell col={4} tablet={4}>
                    <BillingTextField
                      value={settings.excise_number}
                      handleChange={this.handleSettingsChange}
                      floatingLabel="Excise Number *"
                      param="excise_number"
                      required
                      type="number"
                      className="billing-required right-side-gap"
                      emptyField={validation?.flag}
                    />
                  </Cell>
                </Grid>
              </div>
            )}
          </div>
          <h4>4. Print Remarks</h4>
          <div className="pannel-card">
            <div className="form-input print-remarks">
              <Grid>
                <Cell col={12} tablet={12}>
                  <BillingTextField
                    value={settings.print_remarks}
                    handleChange={this.handleSettingsChange}
                    param="print_remarks"
                    type="text"
                    className="billing-required right-side-gap"
                  />
                  <div className="info">
                    <Icon name="info" />
                    <p className="note">Remarks text take lenght of 100 letters only in print. </p>
                  </div>
                </Cell>
              </Grid>
            </div>
          </div>

          <h4>5. Back Date Settings</h4>
          <div className="pannel-card">
            <div className="switch_radio">
              <div className="form-switchs">
                <label className="select-custom-label">Allow user to do back date transactions.</label>
                <Switch
                  onToggle={(checked, event) => this.handleSettingsChange('backDated', checked ? 1 : 0)}
                  checked={backDated}
                />
              </div>
            </div>
            <div className="info">
              <Icon name="info" />
              <p>
                If you enable this configuration, user won’t be able to do back date transactions in Sales return,
                Purchase Return, Collections, Debit Note, Credit Note & Contra Entry.
              </p>
            </div>
          </div>
          <h4>6. Credit Limit/Days Settings</h4>
          <div className="pannel-card credit">
            <div className="switch_radio limit">
              <div className="form-switchs">
                <label className="select-custom-label">
                  Do not allow user to make transactions when <b> credit limit exceeds.</b>
                </label>
                <Switch
                  onToggle={(checked, event) => this.handleSettingsChange('limit', checked ? 1 : 0)}
                  checked={settings?.credit?.limit}
                />
              </div>
            </div>
            <div className="switch_radio days">
              <div className="form-switchs">
                <label className="select-custom-label">
                  Do not allow user to make transactions when <b>credit days exceeds.</b>
                </label>
                <Switch
                  onToggle={(checked, event) => this.handleSettingsChange('days', checked ? 1 : 0)}
                  checked={settings?.credit?.days}
                />
              </div>
            </div>
            <div className="info">
              <Icon name="info" />
              <p>
                If you enable the configuration, user won’t be able to do invoice when party's credit limit or credit
                days exceeds.
              </p>
            </div>
          </div>

          <h4>7. A5 Settings</h4>
          <div className="pannel-card">
            <div className="switch_radio">
              <div className="form-switchs">
                <label className="select-custom-label">Enable A5 for SI And ABI</label>
                <Switch
                  onToggle={(checked, event) => this.handleSettingsChange('enableA5', checked ? 1 : 0)}
                  checked={enableA5}
                />
              </div>
            </div>
            <div className="info">
              <Icon name="info" />
              <p>
                If you enable this configuration, you will also need to change paper size on print tab manaully.
              </p>
            </div>
          </div>

          <div className="button">
            <Button
              accent
              onClick={() => {
                this.handleSubmit();
              }}
            >
              Save
            </Button>
          </div>
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </div>
      </SettingStyled>
    );
  }
}

export default Settings;
