import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const PurchaseReturnDetailTableBody = ({ data, index }) => {
  return (
    <tr className='cursor-pointer'>
      <td className='left-align'>{index + 1}</td>
      <td className='left-align'>{data.idPurchaseReturnDetail}</td>
      <td className='left-align'>{data.title}</td>
      <td className='left-align'>{data.rate}</td>
      <td className='left-align'>{data.quantity}</td>
      <td className='left-align'>{data.discount}</td>
      <td className='left-align'>{data.netAmount.toLocaleString()}</td>
    </tr>
  );
};

PurchaseReturnDetailTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const purchaseReturnDetailConfig = {
  headerDetails: [
    { id: 1, label: 'idPurchaseReturnDetail', title: 'SRN ID', className: 'cursor-pointer', sortable: true },
    { id: 2, label: 'skuId', title: 'Customer Name', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'rate', title: 'SRN Amount', className: 'cursor-pointer right-align', sortable: true },
    { id: 4, label: 'quantity', title: 'Date', className: 'cursor-pointer right-align', sortable: false },
    { id: 5, label: 'promotionDiscount', title: 'Date', className: 'cursor-pointer right-align', sortable: false },
    { id: 6, label: 'netAmount', title: 'Date', className: 'cursor-pointer right-align', sortable: false },
  ],
};

export { purchaseReturnDetailConfig, PurchaseReturnDetailTableBody };
