import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../utils/conversion';
import { formatToReadableDate } from '../../utils/filterUtil';

const debitNoteTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <tr onClick={() => onRowClick(data.voucherNumber)}>
        <td className='left-align'>{data.voucherNumber}</td>
        <td className='left-align'>{formatToReadableDate(data.documentDate)}</td>
        <td className='left-align'>{data.mitiTitle}</td>
        <td className='right-align'>{data.drAmount ? fixedFloatAndCommas(data.drAmount) : 0}</td>
        <td className='left-align'>{data.refNumber}</td>
        <td className='left-align'>{data.narration}</td>
      </tr>
    ))}
  </tbody>
);

debitNoteTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const debitNoteConfig = [
  { id: 1, label: 'voucher_number',className:'cursor-pointer', title: 'Debit Note Number' ,sortable:true},
  { id: 2, label: 'document_date', className:'cursor-pointer', title: 'Date',sortable:true },
  { id: 3, label: 'miti_title', className:'cursor-pointer',title: 'Miti',sortable:true },
  { id: 4, label: 'amount', title: 'Amount', className: 'right-align' },
  { id: 5, label: 'orginal_invoice_number', title: 'Ref Invoice Number' },
  { id: 6, label: 'narration', title: 'Narration' },
];

const config = {
  header: debitNoteConfig,
  getTableBody: debitNoteTableBody,
};

export default config;
