const CATALOGS = [
  {
    LEVEL: 1,
    TITLE: 'Principal',
  },
  {
    LEVEL: 2,
    TITLE: 'Product Category',
  },
  {
    LEVEL: 3,
    TITLE: 'Brand',
  },
  {
    LEVEL: 4,
    TITLE: 'Sub Brand',
  },
  {
    LEVEL: 5,
    TITLE: 'Market Catalog',
  },
  {
    LEVEL: 6,
    TITLE: 'Product Pack',
  },
  {
    LEVEL: 7,
    TITLE: 'Sub Brand Form',
  },
];
const CATALOG_LEVEL_MAPPER = {
  PRINCIPAL: 1,
  PRODUCT_CATEGORY: 2,
  BRAND: 3,
  SUB_BRAND: 4,
  MARKET_CATALOG: 5,
  PRODUCT_PACK: 6,
  SUB_BRAND_FORM: 7,
};

export { CATALOGS, CATALOG_LEVEL_MAPPER };
