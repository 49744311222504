import React, { Fragment } from 'react';
import { getDisplayName } from './component';
import Loading from './Loading';

export default function withLoading(WrappedComponent) {
  function withLoadingScreen({ loading, children, ...props }) {
    return (
      <Fragment>
        <Loading display={loading} />
        <WrappedComponent {...props}>{children}</WrappedComponent>
      </Fragment>
    );
  }

  withLoadingScreen.displayName = `LoadingAndError(${getDisplayName(WrappedComponent)})`;

  return withLoadingScreen;
}
