/**
 * Created by kAy on 8/20/17.
 */
import ReactDOM from 'react-dom';

let getFixTableMeasurements = (referenceDivForTable, fixedTablePagination) => {
  let reference = ReactDOM.findDOMNode(referenceDivForTable).getBoundingClientRect();
  let bottomComponentHeight = 0;

  if (fixedTablePagination !== null) {
    let bottomComponent = ReactDOM.findDOMNode(fixedTablePagination).getBoundingClientRect();
    bottomComponentHeight = bottomComponent.bottom - bottomComponent.top;
  }
  /**
   * 24 padding top and bottom and 2 padding for the borders
   */
  let tableHeight = window.innerHeight - reference.top - reference.height - bottomComponentHeight - 26;
  let tableContentHeight = window.innerHeight - reference.top - reference.height - bottomComponentHeight - 26 - 78;
  let tableWidth = window.innerWidth - 48;

  return {
    tableHeight: tableHeight + 'px',
    tableContentHeight: tableContentHeight + 'px',
    tableHeader: reference.top + 'px',
    tableWidth: tableWidth + 'px',
  };
};

let getPropertyValue = (div, value) => {
  return div.getPropertyValue(value).substr(0, div.getPropertyValue(value).length - 2);
};

export { getFixTableMeasurements, getPropertyValue };
