import { compose } from 'recompose';

import Settings from './Settings';

import apiInterceptor from '../../../api/interceptor';

import { companyApi } from '../../common/base.api';

const composedSettings = compose(apiInterceptor({ ...companyApi }))(Settings);

export default composedSettings;
