import styled from 'styled-components';

const DownloadStyled = styled.div`
  .filter-dropdown-card {
    position: absolute;
    right: 0;
    top: 90% !important;
    box-shadow: none !important;
    border: none !important;

    ul {
      position: absolute;
      right: 0;
      margin: 0;
      padding: 0;
      z-index: 4;
      line-height: 48px;
      min-width: 170px;
      max-width: cal(100vw - 32px);
      list-style-type: none;
      white-space: nowrap;
      overflow-x: hidden;
      overflow-y: auto;
      transform: scale(1, 1);
      background-color: #f4f4f4;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;
      background-color: #ffffff;
      border-radius: 2px;
      box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
      li {
        padding: 0px 16px;
        line-height: 1.75rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 48px;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.86);
      }
    }
  }
`;
export default DownloadStyled;
