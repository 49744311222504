import React, { Component } from 'react';
import {
  Icon,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
} from 'react-mdc-web';
//import 'react-select/dist/react-select.css';
import { skuPriceHistoryConfig, SkuPriceHistoryTableBody } from './SKUPriceHisotryConfig';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import orderBy from 'lodash/orderBy';
import * as appConstants from '../../../config';
import moment from 'moment/moment';
import history from '../../../../utils/history';
import Pagination from '../../../../components/Pagination';
import * as httpUtils from '../../../../utils/httpUtils';
import * as filterUtil from '../../../../utils/filterUtil';
import { SKU_PRICE } from '../../../../data/enums/Route';

//invoice id is present in the list.
class SKUPriceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      date: '',
      headerTitleToView: skuPriceHistoryConfig.headerTitleToView,
      totalData: 1,
      dataSet: [],
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
        },
      },
      skuPriceId: this.props.params.id,
    };
  }

  componentDidMount() {
    this.getDetail();
  }

  getDetail = () => {
    const { queryParameters } = this.state;
    this.setState({ loading: true });
    httpUtils
      .get(
        appConstants.baseUrl +
          `upload-skus/sku-price-history?&page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}&sku_id=${this.props.params.id}`,
      )
      .then(response => {
        this.setState({ loading: false });
        if ((response.status == '200') & response.success) {
          this.setState({ dataSet: response.data.list, totalData: response.data.total });
        } else {
        }
      });
  };

  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getDetail());
  };

  handleCancelClick = () => {
    history.push(`/${SKU_PRICE}`);
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.dataSet;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';
    return orderBy(this.state.dataSet, labelName, sortingName);
  };

  render() {
    const { loading, dataSet, skuPriceId, sorting, queryParameters, totalData } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="card-header-bar">
            <h2> SKU Price : {skuPriceId}</h2>
            {/*<div className="header-menu">
              <div className="header-right">
                <span>{moment().format('ll')}</span>
              </div>
            </div>*/}
          </div>
          <div className="card-body" ref="tableReference">
            <div ref="fixedTableBody" className="fixed-table-wrapper">
              <table>
                <TableHeader
                  headerDetails={skuPriceHistoryConfig.headerDetails}
                  filterHeaderLabel={false}
                  handleSorting={this.handleTableSorting}
                />
                {sortedDataSet.map((data, key) => (
                  <tbody>
                    <SkuPriceHistoryTableBody data={data} index={key} />
                  </tbody>
                ))}
              </table>
            </div>
            {
              <div ref="fixedTableFooter">
                <Pagination
                  pageSize={queryParameters.pagination.limit}
                  currentPage={queryParameters.pagination.page}
                  orientation="top"
                  totalItems={totalData}
                  onPageSelect={this.onPageSelect}
                />
              </div>
            }
          </div>
          <div className="newinvoice-btn-wrapper">
            <Grid>
              <Cell col={8}></Cell>
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>
              </Cell>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
SKUPriceHistory.contextTypes = {
  router: PropTypes.object,
};

export default SKUPriceHistory;
