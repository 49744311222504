import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const balanceType = [
  { title: 'Credit', value: 'Cr' },
  { title: 'Debit', value: 'Dr' },
];

const ACTION_MESSAGE = {
  SUCCESS: {
    UPLOAD: 'Ledgers uploaded successfully.',
    CREATE: 'Ledger created successfully.',
    UPDATE: 'Ledger updated successfully.',
  },
  ERROR: {
    UPLOAD: '!Error while uploading Ledgers',
    CREATE: '!Error while creating Ledger',
    UPDATE: '!Error while updating Ledger',
  },
};

const PAN_NO_VALID_DIGIT = 10;

const LedgersTableBody = ({ data, onEditIconClick, index }) => {
  return (
    <tr>
      <td className='left-align'>{data.customerId}</td>
      <td className='left-align'>{data.name}</td>
      <td className='left-align'>{data.address}</td>
      <td className='left-align'>{data.panNo}</td>
      <td className='left-align'>{data.phoneNumber}</td>
      <td className='left-align'>{data.creditDay}</td>
      <td className='left-align'>{data.creditLimit ? data.creditLimit.toLocaleString() : data.creditLimit}</td>
      <td className='right-align'>
        <Icon name='edit' onClick={() => onEditIconClick(data, index)} />
      </td>
    </tr>
  );
};

LedgersTableBody.propTypes = { data: PropTypes.object.isRequired, };

const ledgersConfig = {
  headerDetails: [
    { id: 1, label: 'customerId', title: 'Customer ID', className: 'cursor-pointer left-align', sortable: true },
    { id: 2, label: 'name', title: 'Title', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'address', title: 'Address', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'panNo', title: 'PAN No', className: 'cursor-pointer', sortable: true },
    { id: 5, label: 'phoneNumber', title: 'Phone No', className: 'cursor-pointer', sortable: true },
    { id: 6, label: 'creditDay', title: 'Credit Day', className: 'cursor-pointer', sortable: true },
    { id: 6, label: 'creditLimit', title: 'Credit Limit', className: 'cursor-pointer', sortable: true },
    { id: 7, label: 'action', title: '', className: 'right-align', sortable: false },
  ],
};

export {
  balanceType, ledgersConfig, LedgersTableBody, ACTION_MESSAGE, PAN_NO_VALID_DIGIT
};
