import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import tableConfig from './config';
import Loading from '../../../../common/Loading';
import * as filterUtil from '../../../../utils/filterUtil';
import { clone } from '../../../../utils/arrayProcessor';
import { debouncer } from '../../../../utils/handlers';
import withBaseState from '../../../common/withBaseState';
import PageView from '../../../common/pagination/PageView';
import * as queryService from '../../../common/query.service';
import * as downloadService from '../../../common/download.service';
import { LEDGER_DETAILS_REPORT_CONFIG } from '../../../common/domain.config';
import { groupPrintDataSet } from '../../../common/print.service';
import LedgerReportStyled from '../LedgerReportStyled';
import { onRowItemClick } from '../../../fundamentals/common/helpers';
import SimplePrint from '../../../../components/PrintComponent/simplePrint/simplePrint';
import getLedgerDetailData from './getLedgerDetailData';
import getConfigData from './getConfigData';

const propTypes = {
  ledgerDetails: PropTypes.shape({
    detail: {
      name: PropTypes.string,
      ledgerId: PropTypes.number,
      debit: PropTypes.number,
      credit: PropTypes.number,
      openingBalance: PropTypes.number,
      closingBalance: PropTypes.number,
    },
    date: {
      start: PropTypes.string,
      end: PropTypes.string,
    },
  }),
  getReportDetails: PropTypes.func.isRequired,
};

const defaultProps = {
  ledgerDetails: {
    detail: {
      name: '',
      ledgerId: null,
      debit: null,
      credit: null,
      openingBalance: null,
      closingBalance: null,
    },
    date: {
      start: filterUtil.getStartOfCurrentMonth(),
      end: filterUtil.getCurrentDay(),
    },
  },
};

class LedgerReportDetail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const queryParams = { ...queryService.queryParameters };
    this.box = React.createRef();
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        ...queryService.queryParameters,
        date: queryParams.date,
        sort: queryParams.sort,
      },
      printButtonClicked: false,
      dataSet: [],
    };
    const { ledgerDetailDownloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      ledgerDetailDownloadList,
    );
    //this.basePaginationService.resetFilter();
    this.setDate();
  }

  componentDidMount() {
    this.loadTableData();
    window.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = event => {
    if (this.box && !this.box.current.contains(event.target)) {
      this.state.printButtonClicked === true && this.setState({ printButtonClicked: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };
  setDate = () => {
    const { queryParameters } = this.state;
    const { ledgerDetails } = this.props;
    const { date } = ledgerDetails;
    queryParameters.date = date ? date : queryParameters.date;

    this.setState({ queryParameters });
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = async (print = false) => {
    const { ledgerDetails, getReportDetails } = this.props;
    const { queryParameters, data } = this.state;
    const { detail } = ledgerDetails;
    const extraQuery = `&ledger_id=${detail.ledgerId}`;
    let query = clone(queryParameters);
    query.pagination.limit = 5000;
    query.pagination.page = 1;
    await getReportDetails({
      queryParameters: print ? { ...query } : { ...queryParameters },
      extraQuery,
    }).then(response => {
      data.list = response.data;
      data.total = response.total ? response.total : response.data.length;
      const nepaliDate = {
        start: response.nepali_date && response.nepali_date.start_date_nepali,
        end: response.nepali_date && response.nepali_date.end_date_nepali,
      };
      const columnTotal = {};
      this.setState(
        {
          dataSet: data.list,
          totalData: response.total,
          nepaliDate,
          columnTotal,
        },
      );
    });
  };
  handleDownloadClick = type => {
    const { ledgerDetails, ledgerDetailDownloadList } = this.props;
    const { queryParameters } = this.state;
    const { detail } = ledgerDetails;
    const extraQuery = `&ledger_id=${detail.ledgerId}`;
    ledgerDetailDownloadList({
      type,
      query: queryParameters,
      extraQuery,
    }).then(response => downloadService.resolver(response));
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handlePrintClick = async () => {
    // await this.loadTableData(true);
    const self = this;
    await self.loadTableData(true);
    self.setState({ printButtonClicked: true });
  };
  groupPrintDataSetNew() {
    const { dataSet } = this.state;
    const { company } = this.props;
    const printInfoBill = { ...company, printInfo: { ...company.printInfo, batchEnabled: false } } || {};
    const orders = clone(dataSet);
    const dataList = groupPrintDataSet(orders, printInfoBill, tableConfig);
    this.setState({ dataList });
  }

  tableRowItemClick(data) {
    return onRowItemClick({
      bill_no: data.voucherNumber,
      transaction_type_id: data.transactionType,
    });
  }

  handleSearchInputChange = value => {
    const { queryParameters } = this.state;
    queryParameters.search = value;
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters }, () => this.loadTableData());
  };
  render() {
    const { data, display, queryParameters, printButtonClicked, dataList, columnTotal, nepaliDate } = this.state;

    const { serverResponseWaiting, ledgerDetails, company } = this.props;
    const { detail, date } = ledgerDetails;
    const printData = getLedgerDetailData({ ...data }, company, ledgerDetails);
    const configData = getConfigData(printData?.tableFooterData);

    return (
      <LedgerReportStyled>
        <Loading display={serverResponseWaiting} />
        <div className="display-block ledger-report-details">
          <PageView
            domainConfig={{ ...LEDGER_DETAILS_REPORT_CONFIG, title: detail.name }}
            headerConfig={{
              search: false,
              date: true,
              download: true,
              filter: true,
              create: false,
              print: true,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            resetFilter={this.basePaginationService.resetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            handleDownloadClick={this.handleDownloadClick}
            data={data}
            loading={serverResponseWaiting}
            queryParameters={{ ...queryParameters, date }}
            tableConfig={tableConfig}
            handlePrintClick={this.handlePrintClick}
            printReference={this.box}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.tableRowItemClick}
            onPageSelect={this.basePaginationService.onPageSelect}
          />
        </div>
        {printButtonClicked && (
          <div className="display-block portrait-type">
            {/* <PrintLedgerReportDetail ledgerList={{ ...data }} companyData={company} ledgerDetails={ledgerDetails} /> */}
            <SimplePrint configData={configData} printData={printData} />
          </div>
        )}
      </LedgerReportStyled>
    );
  }
}

LedgerReportDetail.propTypes = propTypes;

LedgerReportDetail.defaultProps = defaultProps;

const mapStateToProps = state => ({
  ledgerDetails: state.customerLedgerDetail || { detail: {}, date: {} },
});

const ledgerReportDetail = connect(mapStateToProps)(LedgerReportDetail);

const LedgerReportDetailWithState = withBaseState(ledgerReportDetail);

export default LedgerReportDetailWithState;
