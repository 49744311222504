import styled from 'styled-components';

const TagWiseStyled = styled.div`
  .fixed-table-wrapper {
    & > table {
      th {
        z-index: 2;
      }
      tbody {
        tr.inActive {
          transition: all 0.1s ease-in;
        }
        tr.active.accordionExist {
          border-bottom: 1px solid rgb(33, 150, 243);
          background: rgba(243, 250, 255, 0.3);
          height: 56px;
          transition: all 0.4s ease-out;
        }
        .tagNotExist ~ td,
       .tagNotExist{
          cursor:default;
          span:before{
              content:unset;
              cursor:default;
            }
            b{
              font-weight:400;
            }
        }
        .tagExist{
          span{
            b{
              font-weight:400;
              color:rgb(33,150,243);
              position: relative;
              &[data-tooltip]:link, &[data-tooltip]:visited {
                  text-decoration: none;
                  border-bottom: solid 1px;
                }

                &[data-tooltip]:before {
                  content: "";
                  position: absolute;
                  border-right: 6px solid rgb(33,150,243);
                  border-bottom: 6px solid transparent;
                  border-top: 6px solid transparent;
                  display: none;
                  top: 0;
                  right: -21px;
                }

                &[data-tooltip]:after {
                  content: attr(data-tooltip);
                  position: absolute;
                  color: white;
                  top: -9px;
                  background:rgb(33,150,243);
                  padding: 8px;
                  border-radius: 4px;
                  white-space: nowrap;
                  display: none;
                  height: 20px;
                    line-height: 20px;
                    margin-left: 20px;
                }

                &[data-tooltip]:hover:before, &[data-tooltip]:hover:after {
                  display: inline;
                }
            }
          }
        }
        tr.active + .collapsible-body-tr{
          border:0;
        }
        tr.active{
          td{
            span:before{
              content:unset;
            }
          }
          &.accordionExist{
            td{
              span:before{
                content:'';
              }
            }
          }
        }
        .tag{
          width:30%;
        }
        .credit,
        .debit,
        .closing,
        .opening{
            width:15%;
        }
        .openingStatus,
        .closingStatus{
          width:5%;
        }
      }
    }
  }
  .collapsible-body-tr {
    td.collapsible-body-td{
    padding:0;
    .tag{
      padding-left:49px;
    }
    }
    table {
      box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
      cursor: unset;
      th {
        position: relative;
        z-index: 1;
        background: rgba(244, 244, 244, 0.5);
      }
    }
  }
  table tbody {
    tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #2196f3;
        }
      }
      td:first-child {
        & > span {
          position: relative;
          margin-left: 26px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: -26px;
            transition: all 0.23s ease-in-out;
          }
        }
      }
    }
  }
`;
export default TagWiseStyled;
