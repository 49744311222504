import React from 'react';
import TableHeader from '../../../components/TableHeader/TableHeader';
import BillingTextField from '../../../components/TextField/BillingTextField';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const propsTypes = {};
const defaultProps = {
  editStatus: true,
  invoiceList: [],
  handleInputChange: () => null,
  config: {},
};

const ReconcileTableView = ({ editStatus, invoiceList, config, handleInputChange }) => {
  return (
    <div className='table-wrapper'>
      <div className='fixed-table-wrapper'>
        <table>
          <TableHeader headerDetails={config.headerDetails} />
          {invoiceList.map((data, key) => (
            <tbody>
              <tr>
                <td className='left-align'>{key + 1}</td>
                <td className='left-align'>{data.invoiceNumber}</td>
                <td className='right-align'>{data.amount}</td>
                <td className='right-align table-input-right'>
                  <BillingTextField
                    value={data.reconcileAmount}
                    handleChange={handleInputChange}
                    param={key}
                    required={true}
                    type='number'
                    className={`billing-required right-align ${!editStatus ? 'inp-disabled' : ''}`}
                    errorMessage='Invalid Amount'
                    multipleParam={true}
                  />
                </td>
                <td className='right-align'>{fixedFloatAndCommas(data.amount - data.reconcileAmount)}</td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    </div>
  );
};
ReconcileTableView.propTypes = propsTypes;
ReconcileTableView.defaultProps = defaultProps;

export default ReconcileTableView;
