import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import LinearProgress from 'react-mdc-web/lib/LinearProgress';
import '../../../../styles/printStyle.css';
import history from '../../../../utils/history';
import { clone } from '../../../../utils/arrayProcessor';
import * as filterUtil from '../../../../utils/filterUtil';
import { getBillFooterSummary, getBillSummaryDetails, getBillTotalList, MESSAGE, PRINT_COPY, TITLE } from './config';
import PrintBody from '../../../../components/PrintComponent/PrintBody';
import { SALES_INVOICE_BASE } from '../../../../data/enums/Route';
import { CLIENT } from '../../../../data/enums/enums';
import { salesInvoiceApi } from '../../../common/base.api';
import { has } from '../../../../utils/hasOwnProperty';
import * as printService from '../../../common/print.service';
import * as snackService from '../../../common/snack.service';
import BillingSnackBar from '../../../../components/BillingMDC/BillingSnackbar';

const propTypes = {
  postPrintHandler: PropTypes.func,
};

const defaultProps = {
  postPrintHandler: () => window.close(),
};

class InvoiceDetails extends Component {
  mapPrintInfo = userName => {
    const { firstCopyTaxInvoice, dateTime } = this.state;
    if (!firstCopyTaxInvoice && this.client === CLIENT.DBS) {
      this.setState({ printInfo: this.props.printInfo });
    } else {
      const printInfo = [
        { title: 'Printed On', value: dateTime.date },
        { title: 'Printed Time', value: dateTime.time },
        { title: 'Printed By', value: userName },
      ];
      this.setState({ printInfo });
    }
  };

  handleCancelClick = () => {
    history.push(`/${SALES_INVOICE_BASE}`);
  };

  printInvoice = () => {
    const { printDetails } = this.state;
    const { postPrintHandler } = this.props;
    const self = this;

    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });

    window.onafterprint = function () {
      // self.props.afterPrint(user.idUsers, printDetails, this.props.afterPrintCallBack);
      // printService.postPrint(printDetails, postPrintHandler);
    };
    // todo handle if error while notifying server
  };

  // fetch the detail from the server.
  getDetail = () => {
    const { invoiceNumber, userDetail, printDetails, firstCopyTaxInvoice } = this.state;
    this.setState({ loading: true });
    salesInvoiceApi
      .getPrintDetails({ invoice_number: invoiceNumber, user_id: userDetail.id })
      .then(response => {
        this.setState({ loading: false });
        if ((response.status == '200') & response.success) {
          let { printInfo } = this.state;
          const { invoiceDetail, paymentDetail } = this.state;

          const { salesInvoice = {} } = response.data;

          const billTotalList = getBillTotalList({
            subTotal: response.data ? response.data.subTotal || 0 : 0,
            ...salesInvoice,
          });
          const dataMiscellaneousList = getBillSummaryDetails(salesInvoice);
          const billSummary = getBillFooterSummary(salesInvoice);

          if (salesInvoice.paymentMode === 'CASH') {
            paymentDetail.status = true;
            paymentDetail.mode = 'CASH';
          }

          invoiceDetail.value = salesInvoice.userDetail.name;
          if (this.client === CLIENT.ROSIA) {
            if (has.call(response.data, 'actionUserDetail')) {
              userDetail.title = response.data.actionUserDetail.name;
              invoiceDetail.value = response.data.actionUserDetail.name;
            }
            const salesPrintInfo = printService.dataProcessor(salesInvoice, userDetail.id);
            printInfo = !firstCopyTaxInvoice ? salesPrintInfo.printInfo : this.state.printInfo;
            printDetails.invoiceNumber = salesInvoice.invoiceNumber;
            printDetails.printedBy = userDetail.id;
          } else {
            userDetail.title = this.props.user.name;
            userDetail.id = this.props.user.idUsers;
          }

          this.setState(
            {
              billSummary,
              skuList: salesInvoice.salesDetail,
              invoiceId: salesInvoice.invoiceNumber,
              date: moment(salesInvoice.date).format('DD MMM YYYY'),
              miti: salesInvoice.mitiTitle,
              entered_by: salesInvoice.userDetail.name,
              billTotalList,
              dataMiscellaneousList,
              invoiceDetail,
              paymentDetail,
              printInfo,
              userDetail,
            },
            () => this.groupPrintDataset(),
          );
        } else {
          const snack = snackService.generateFailureMessage('Error while loading!');
          this.setState({ loading: false, snack });
        }
      })
      .catch(error => {
        const snack = snackService.generateFailureMessage('Error while loading!');
        this.setState({ loading: false, snack });
      });
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.printInvoice();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  renderPrintContent = () => {
    const {
      date,
      miti,
      print,
      dataList,
      printInfo,
      billSummary,
      invoiceDetail,
      paymentDetail,
      billTotalList,
      firstCopyTaxInvoice,
      dataMiscellaneousList,
    } = this.state;
    const { company } = this.props;
    const printBody = [];
    for (let count = 0; count < PRINT_COPY; count++) {
      const title = TITLE.ORIGINAL; // firstCopyTaxInvoice ? count === 0
      /*? TITLE.ORIGINAL : TITLE.ORIGINAL2
        : TITLE.COPY;*/
      printBody.push(
        <PrintBody
          date={date}
          miti={miti}
          print={print}
          title={title}
          company={company}
          dataList={dataList}
          printInfo={printInfo}
          billSummary={billSummary}
          message={MESSAGE.NEXT_PAGE}
          invoiceDetail={invoiceDetail}
          paymentDetail={paymentDetail}
          billTotalList={billTotalList}
          dataListLength={this.dataListLength}
          dataMiscellaneousList={dataMiscellaneousList}
        />,
      );
    }
    return printBody;
  };

  constructor(props) {
    super(props);
    this.state = {
      date: '',
      miti: '',
      invoiceNumber: '',
      entered_by: '',
      firstCopyTaxInvoice: '',
      print: true,
      loading: false,
      printButtonClicked: false,
      paymentDetail: {
        status: false,
        mode: '',
      },
      billSummary: getBillFooterSummary({}),
      dateTime: {
        date: filterUtil.getCurrentDay(),
        time: filterUtil.getCurrentTime(),
      },
      userDetail: {
        id: '',
        title: '',
      },
      company: {
        title: '',
        address: '',
        phone: '',
        panNumber: '',
      },
      invoiceDetail: { title: 'Invoiced By', value: '' },
      skuList: [],
      dataList: [],
      printInfo: [],
      printDetails: {
        transaction: 'SALES',
        invoiceNumber: '',
        printedBy: '',
      },
      billTotalList: getBillTotalList({}),
      dataMiscellaneousList: getBillSummaryDetails({}),
      snack: { ...snackService.snackParameters },
    };
    this.client = CLIENT.DBS;
    this.dataListLength = 0;
  }

  componentDidMount() {
    this.setupConfig();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  setupConfig() {
    const { match, user = {} } = this.props;
    const { userDetail } = this.state;

    if (match) {
      this.client = CLIENT.ROSIA;
      document.getElementsByClassName('scrollbar-container')[0].classList.add('visibility-hidden');
      document.getElementsByClassName('nav-bar')[0].classList.add('visibility-hidden');
      const id = match.params.id.replace(/%2F/g, '/');
      userDetail.id = match.params.userId;
      this.setState(
        {
          invoiceNumber: id,
          firstCopyTaxInvoice: JSON.parse(match.params.firstCopy),
          userDetail,
          loading: true,
        },
        () => {
          this.mapPrintInfo(user.name);
          this.getDetail();
          window.onafterprint = this.handleAfterPrint;
        },
      );
    } else {
      const printDetails = {
        transaction: 'SALES',
        invoiceNumber: this.props.id,
        printedBy: user.idUsers,
      };
      this.setState(
        {
          invoiceNumber: this.props.id,
          firstCopyTaxInvoice: JSON.parse(this.props.firstCopy),
          company: this.props.company,
          printDetails,
        },
        () => {
          this.mapPrintInfo(user.name);
          this.getDetail();
          window.onafterprint = this.props.afterPrint;
        },
      );
    }
  }

  groupPrintDataset() {
    const { skuList } = this.state;
    const withoutFooterLimit = 35;
    const withFooterLimit = 25;
    let arrayList;
    let tempWholeDataList = [];
    const dataList = [];
    let count = 0;
    arrayList = clone(skuList);
    const chunkAppropriateSize = arrayList.length >= withoutFooterLimit ? withoutFooterLimit : withFooterLimit;
    let chunk = chunkAppropriateSize;
    let indexChunk = chunkAppropriateSize;

    arrayList.forEach((item, index) => {
      item.index = index;
      arrayList[index] = item;
    });

    for (let index = 0, j = arrayList.length; index < j; index = indexChunk) {
      count++;
      let chunkArray = [];
      chunkArray = arrayList.slice(index, index + chunk);
      tempWholeDataList = tempWholeDataList.concat(chunkArray);
      chunk = arrayList.length - tempWholeDataList.length >= withoutFooterLimit ? withoutFooterLimit : withFooterLimit;
      if (arrayList.length - tempWholeDataList.length === 0) {
        chunkArray.footer = true;
        chunkArray.page = count;
      } else {
        chunkArray.footer = false;
        chunkArray.page = count;
      }

      indexChunk = tempWholeDataList.length;
      dataList.push(chunkArray);
    }
    this.dataListLength = dataList.length;
    this.setState({ dataList }, () => {
      this.printInvoice();
    });
  }

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { loading, snack } = this.state;
    return (
      <>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={`sales-invoice-detail pad-b-24${loading ? 'clickable-false' : ''}`}>
          <div className="display-block sales-invoice">{this.renderPrintContent()}</div>
          {/* <BillingSnackBar closeSnack={this.closeSnack} config={snack} /> */}
        </div>
      </>
    );
  }
}

InvoiceDetails.contextTypes = {
  router: PropTypes.object,
};

InvoiceDetails.propTypes = propTypes;
InvoiceDetails.defaultProps = defaultProps;

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
});
const SalesInvoiceDetail = connect(mapStateToProps)(InvoiceDetails);

export default SalesInvoiceDetail;
