import styled from 'styled-components';

const DiscountBarStyled = styled.div`
  .sub-total-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .zindex-2__control {
      width: 75px;
      min-height: 30px;
    }
  }
`;
export default DiscountBarStyled;
