import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';
import { ACTION } from '../../../data/enums/enums';
import BillingTextField from '../../../components/TextField/BillingTextField';

const UserGroupDialog = ({ sku, userGroup, actionType, onModalClose, onModalSubmit, onInputChange, validation }) => (
  <Dialog
    open={actionType !== ACTION.NULL}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      <DialogTitle>{actionType} User Group</DialogTitle>
    </DialogHeader>

    <DialogBody>
      <div className='dialog-upperpart'>
        <Grid className='grid-padding'>
          <Cell col={4} tablet={8}>
            <BillingTextField
              value={userGroup.name}
              handleChange={onInputChange}
              floatingLabel='Title'
              param='name'
              type='text'
              className='right-side-gap'
              required
              emptyField={validation.flag}
            />
          </Cell>
        </Grid>
      </div>
    </DialogBody>
    <DialogFooter>
      <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
        Cancel
      </Button>
      <Button
        accent
        disabled={actionType === ACTION.UPLOAD && !sku.file}
        className='dialog-ok modal-btn'
        onClick={() => {
          onModalSubmit();
        }}
      >
        Save
      </Button>
    </DialogFooter>
  </Dialog>
);

export default UserGroupDialog;
