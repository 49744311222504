import React, { Component } from 'react';
import tableConfig from './config';
import history from '../../../../utils/history';
import * as filterUtil from '../../../../utils/filterUtil';
import { clone } from '../../../../utils/arrayProcessor';
import withBaseState from '../../../common/withBaseState';
import PageView from '../../../common/pagination/PageView';
import { SALES_CREATE } from '../../../../data/enums/Route';
import * as queryService from '../../../common/query.service';
import { checkIfApiCallSuccess } from '../../../common/base.api';
import { Grid, Cell, Icon } from '../../../../components/BillingMDC';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import PageNumber from '../../../../components/PrintComponent/PageNumber';
import { MATERIALIZED_VIEW_CONFIG } from '../../../common/domain.config';
import { defaultHeaderConfig } from '../../../common/pagination/PageHeader';
import * as snackService from '../../../common/snack.service';
import {groupPrintDataSet} from "../../../common/print.service";
import VatReportStyle from '../../../irdReports/NewSRNVatReport/VatReportStyle';

class SalesInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        filter: false,
        menu: false,
        searchBox: false,
        print: true,
      },
      queryParameters: { ...queryService.queryParameters },
      data: {
        list: [],
        total: 1,
      },
      print: {
        start: false,
        serverList: [],
        dataList: [],
      },
      dataList: [],
      printData: [],
    };
    this.dataListLength = 0;
    this.reference = {
      tableHeader: React.createRef(),
      tableFooter: React.createRef(),
      tableBody: React.createRef(),
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );

    this.dataListLength = 0;
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    window.addEventListener('keydown', this.handleKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
  
  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.getDataForPrint();
    }
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };
  
  /** get data for grid, adjust loading flag */
  loadTableData = async( print =false) => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    let query = clone(queryParameters);
    query.pagination.limit = 2000;
    query.pagination.page=1;
    await getList(print?query:queryParameters)
      .then(response => {
        const { data } = this.state;
        data.list = response.list;
        data.total = response.total;
        this.setState({ data }, () => {
          print && this.groupPrintDataSetNew();
        });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage();
      });
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  getDataForPrint = async () => {
    const self = this;
    await this.loadTableData(true);
    self.setState({ print:{ start:true} }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.loadTableData()
      self.setState({ print:{ start:false} });
    };
  };
  groupPrintDataSetNew() {
    const { data } = this.state;
    const {company} =this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(data.list);
    const dataList = groupPrintDataSet(orders, printInfoBill, tableConfig);
    this.setState({ dataList });
  }


  handleFabButtonClick = () => history.push(`${SALES_CREATE}`);

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState({ display });
  };

  render() {
    const { queryParameters, data, display, print, dataList } = this.state;
    const { serverResponseWaiting, company } = this.props;
    return (
      <VatReportStyle>
      <div div className="sales-materialized">
        <div className={print.start ? 'no-print' : 'display-block'}>
          <PageView
            domainConfig={MATERIALIZED_VIEW_CONFIG}
            serverResponseWaiting={serverResponseWaiting}
            display={display}
            controlDisplay={this.controlDisplay}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            resetFilter={this.basePaginationService.resetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.basePaginationService.handleDownloadClick}
            handlePrintClick={this.getDataForPrint}
            tableConfig={tableConfig}
            headerConfig={{
              ...defaultHeaderConfig,
              print: true,
              upload: false,
              create: false,
            }}
            queryParameters={queryParameters}
            data={data}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onPageSelect={this.basePaginationService.onPageSelect}
            handleFabButtonClick={this.handleFabButtonClick}
          />
        </div>
        <div className={print.start ? 'display-block landscape' : 'no-print'}>
          {dataList.map(item => (
            <div className="print-body zoom-reduce">
              <Grid>
                <Cell col={4} tablet={8}>
                  <div className="header-title">{company.title}</div>
                  <span className="header-title-content">
                    <span className="active-opacity-text">Address: </span>
                    <span>{company.address}</span>
                  </span>
                  <span className="header-title-content">
                    <span className="active-opacity-text">Phone: </span>
                    <span>{company.phone}</span>
                  </span>
                  <span className="no-margin-bottom header-title-content">
                    <span className="active-opacity-text">PAN No: </span>
                    <span>{company.panNumber}</span>
                  </span>
                </Cell>
                <Cell col={4}>
                  <div className="center-align">
                    <h2>{MATERIALIZED_VIEW_CONFIG.title}</h2>
                    <div className="default-margin-top-2">
                      <span className="header-title-content no-margin-bottom">
                        <span className="active-opacity-text fs-14">
                          Report from
                          <span className="date-rendered">
                            {` ${filterUtil.formatToReadableDate(
                              queryParameters.date.start,
                            )} - ${filterUtil.formatToReadableDate(queryParameters.date.end)}`}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </Cell>
                <Cell col={4}>
                  <div className="header-menu-right date text-right">
                    <span>
                      {' '}
                      <span className="">Date: </span>
                      <span>{queryParameters.date.end}</span>
                    </span>
                  </div>
                </Cell>
              </Grid>
              <div ref="fixedTableBody" className="fixed-table-wrapper pdf-table-wrapper">
                <table className="pdf-table">
                  <TableHeader headerDetails={tableConfig.header} handleSorting={this.handleTableSorting} />
                  {tableConfig.getTableBody({ dataList: item.list,remainingLines:item.remainingLines })}
                </table>
              </div>
              <div className="footer-block">
                <PageNumber value={item.page} totalPage={this.dataListLength} />
              </div>
            </div>
          ))}
        </div>
      </div>
      </VatReportStyle>
    );
  }
}

const MViewWithState = withBaseState(SalesInvoice);

export default MViewWithState;
