import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import PromotionDetailStyled from './PromotionDetailStyled';
import PageHeaderStyled from '../../../common/pagination/PageHeaderStyled';
import HorizontalLabelValueBar from '../../../common/horizontalLabellValueBar';
import Switch from '../../../../components/Switch';
import {
  ACTION,
  disbursementTypeList,
  promotionCriteriaDimension,
  promotionCriteriaOperation,
  promotionType,
} from '../../../../data/enums/enums';
import { EMPTY } from '../../../../data/enums/errorMessage';
import {
  Button, Icon, Grid, Cell
} from '../../../../components/BillingMDC';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import BillingSelect from '../../../../components/Select/BillingSelect';
import AutoComplete from '../../../../components/AutoComplete';

const propTypes = {
  cancelFlag: PropTypes.bool,
  crudMode: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  updateCrudMode: PropTypes.func.isRequired,
  batchRef: PropTypes.instanceOf(Object),
  catalogDetailRef: PropTypes.instanceOf(Object),
  handleCancelClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
};

const defaultProps = {
  cancelFlag: false,
  crudMode: ACTION.CREATE,
  data: {},
  enableErrorDisplay: false,
  title: '',
  batchRef: {},
  catalogDetailRef: {},
};

const PromotionDetailView = ({
  data,
  title,
  buList,
  skuList,
  crudMode,
  brandList,
  updateCrudMode,
  handleSaveClick,
  handleDateChange,
  handleInputChange,
  handleCancelClick,
  enableErrorDisplay,
  cancelFlag,
}) => {
  const handleAutoCompleteChange = (value, fieldName, e) => {
    handleInputChange(fieldName, value, e);
  };

  return (
    <PromotionDetailStyled className={`pad-b-24 ${crudMode === ACTION.ADD ? 'promotion_create' : 'promotion_details'}`}>
      <PageHeaderStyled>
        <div className='card-header-bar'>
          <h2>{title}</h2>
          <div className='header-menu'>
            {crudMode === ACTION.READ && <Icon name='edit' onClick={() => updateCrudMode(ACTION.UPDATE)} />}
          </div>
        </div>
      </PageHeaderStyled>
      <div className='card-body' style={{ pointerEvents: crudMode === ACTION.READ ? 'none' : '' }}>
        <div className='form-wrap'>
          <h6>General Setting</h6>
          <div className='form-inputs'>
            <HorizontalLabelValueBar
              label='Title'
              value={
                <BillingTextField
                  value={data.title}
                  handleChange={handleInputChange}
                  param='title'
                  required
                  type='text'
                  emptyField={enableErrorDisplay}
                  errorMessage={EMPTY}
                />
              }
            />
          </div>
          <div className='form-inputs'>
            <HorizontalLabelValueBar
              label='Description'
              value={
                <BillingTextField
                  value={data.description}
                  handleChange={handleInputChange}
                  param='description'
                  type='text'
                  className=''
                />
              }
            />
          </div>
          <div className='form-switchs'>
            <HorizontalLabelValueBar
              label='Status'
              value={
                <Switch
                  onToggle={(checked, event) => handleInputChange('active', !!checked)}
                  checked={data.active}
                  id={`switch-${data.promotionId}`}
                />
              }
            />
          </div>
          <div className='form-inputs'>
            <HorizontalLabelValueBar
              label='Start Date'
              value={
                <DatePicker
                  date={moment(data.startDate)}
                  onChange={(e) => handleDateChange('startDate', e)}
                  maxDate={moment(data.endDate)}
                />
              }
            />
          </div>
          <div className='form-inputs'>
            <HorizontalLabelValueBar
              label='End Date'
              value={
                <DatePicker
                  date={moment(data.endDate)}
                  onChange={(e) => handleDateChange('endDate', e)}
                  minDate={moment(data.startDate)}
                />
              }
            />
          </div>
        </div>
        <div className='form-wrap'>
          <h6>Scope</h6>
          <div className='form-inputs'>
            <HorizontalLabelValueBar
              label='Business Unit'
              value={
                <BillingSelect
                  name='form-field-name'
                  param='businessId'
                  clearable={false}
                  value={data.businessId}
                  labelKey='businessAlias'
                  valueKey='businessId'
                  options={buList}
                  handleChange={handleInputChange}
                  required
                  emptyField={enableErrorDisplay}
                  multipleParam={true}
                />
              }
            />
          </div>
          <div className='form-inputs'>
            <HorizontalLabelValueBar
              label='Brand'
              value={
                <BillingSelect
                  name='brandId'
                  param='brandId'
                  clearable={false}
                  value={data.brandId}
                  labelKey='title'
                  valueKey='businessCatalogDetailId'
                  options={brandList}
                  handleChange={handleInputChange}
                  required
                  emptyField={enableErrorDisplay}
                  multipleParam={true}
                  errorMessage={EMPTY}
                />
              }
            />
          </div>
          <div className='form-inputs'>
            <HorizontalLabelValueBar
              label='SKU'
              value={
                <AutoComplete
                  param='skuIds'
                  name='skuIds'
                  dropDownList={skuList}
                  selectedDropDowns={[...data.skuIds]}
                  onDropDownSelection={handleAutoCompleteChange}
                  dropDownValueKey='businessSkuId'
                  multipleParam
                />
              }
            />
          </div>
        </div>
        <div className='form-wrap'>
          <h6>Condition</h6>
          <Grid className='grid-padding'>
            <Cell col={3} tablet={3} className='condition-input'>
              <label>Promotion Type</label>
              <BillingSelect
                name='form-field-name'
                param='criteriaType'
                clearable={false}
                value={data.criteriaType}
                labelKey='title'
                valueKey='value'
                options={promotionType}
                handleChange={handleInputChange}
                required
                emptyField={enableErrorDisplay}
                multipleParam={true}
              />
            </Cell>
            <Cell col={3} tablet={3} className='condition-input'>
              <label>Condition</label>
              <BillingSelect
                name='form-field-name'
                param='criteriaCondition'
                clearable={false}
                value={data.criteriaCondition}
                labelKey='title'
                valueKey='value'
                options={promotionCriteriaDimension}
                handleChange={handleInputChange}
                required
                emptyField={enableErrorDisplay}
                multipleParam={true}
              />
            </Cell>
            <Cell col={3} tablet={3} className='condition-input'>
              <label>Criteria</label>
              <BillingSelect
                name='form-field-name'
                param='operation'
                clearable={false}
                value={data.operation}
                labelKey='title'
                valueKey='value'
                options={promotionCriteriaOperation}
                handleChange={handleInputChange}
                required
                emptyField={enableErrorDisplay}
                multipleParam={true}
              />
            </Cell>
            <Cell col={3} tablet={3} className='condition-input'>
              <label>Value</label>
              <BillingTextField
                value={data.criteriaValue}
                handleChange={handleInputChange}
                param='criteriaValue'
                required
                zeroError
                type='number'
                className='billing-required right-side-gap'
                emptyField={enableErrorDisplay}
              />
            </Cell>
          </Grid>
        </div>
        <div className='form-wrap'>
          <h6>Disbursement</h6>
          <Grid className='grid-padding'>
            <Cell col={3} tablet={3} className='condition-input'>
              <label>Disbursement Type</label>
              <BillingSelect
                name='form-field-name'
                param='disbursementType'
                clearable={false}
                value={data.disbursementType}
                labelKey='title'
                valueKey='value'
                options={disbursementTypeList}
                handleChange={handleInputChange}
                required
                emptyField={enableErrorDisplay}
                multipleParam={true}
              />
            </Cell>
            <Cell col={3} tablet={3} className='condition-input'>
              <label>Value</label>
              <BillingTextField
                value={data.disbursementValue}
                handleChange={handleInputChange}
                param='disbursementValue'
                required
                zeroError
                type='number'
                className='billing-required right-side-gap'
                emptyField={enableErrorDisplay}
              />
            </Cell>
          </Grid>
        </div>
      </div>
      <>
        {crudMode !== ACTION.READ && (
          <div className='btns'>
            <Button accent className='dialog-cancel cancel-btn modal-btn' onClick={() => handleCancelClick()}>
              Cancel
            </Button>
            <Button
              accent
              className='save-btn modal-btn'
              onClick={() => {
                handleSaveClick();
              }}
            >
              Save
            </Button>
          </div>
        )}
      </>
    </PromotionDetailStyled>
  );
};

PromotionDetailView.propTypes = propTypes;
PromotionDetailView.defaultProps = defaultProps;

export default PromotionDetailView;
