import React from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../utils/conversion';

const ContraEntryReportTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <tr onClick={() => onRowClick(data.voucherNumber)}>
        <td className='left-align'>{data.voucherNumber}</td>
        <td className='left-align'>{moment(data.transactionDate).format('ll')}</td>
        <td className='left-align'>{data.mitiTitle}</td>
        <td className='right-align'>{data.drAmount ? fixedFloatAndCommas(data.drAmount) : 0}</td>
        <td className='right-align'>{data.crAmount ? fixedFloatAndCommas(data.crAmount) : 0}</td>
        <td className='left-align'>{data.narration}</td>
      </tr>
    ))}
  </tbody>
);

ContraEntryReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const contraEntryReportConfig = [
  { id: 1, label: 'voucher_number', title: 'CE ID', className: 'cursor-pointer left-align', sortable: true },
  { id: 2, label: 'transaction_date', title: 'Date', className: 'cursor-pointer left-align', sortable: true },
  { id: 3, label: 'miti_title', title: 'Miti', className: 'cursor-pointer left-align', sortable: true },
  { id: 4, label: 'dr_amount', title: 'Debit', className: 'right-align', sortable: false },
  { id: 5, label: 'cr_amount', title: 'Credit', className: 'right-align', sortable: false },
  { id: 6, label: 'narration', title: 'Narration', className: 'left-align', sortable: false },
];

const config = {
  header: contraEntryReportConfig,
  getTableBody: ContraEntryReportTableBody,
};

export default config;
