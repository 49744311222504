import { connect } from 'react-redux';

import React, { Component } from 'react';

import Dialog from './Dialog';
import tableConfig from './config';
import history from '../../../utils/history';
import { setDetails } from '../../../actions';
import { ACTION, STATUS, PRINT_INFO_DEFAULT } from '../../../data/enums/enums';
import { validateForm } from '../../common/validation';
import withBaseState from '../../common/withBaseState';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { BillingSnackBar } from '../../../components/BillingMDC';
import { SALES_INVOICE_BASE } from '../../../data/enums/Route';
import { checkIfApiCallSuccess,companyApi} from '../../common/base.api';
import {
  PAN_NO_VALID_DIGIT,
  EMAIL_RULE,
} from '../../../data/enums/enums';

import './Company.scss';

class SalesInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
      },
      dialogType: ACTION.ADD,
      snack: { ...snackService.snackParameters },
      queryParameters: { ...queryService.queryParameters },
      data: {
        list: [],
        total: 0,
      },
      company: tableConfig.getDetail(),

      buList: [],
      validation: {
        flag: false,
        fieldList: tableConfig.validationFields.form,
      },
      billFormatValidation: {
        flag: false,
        filedList: tableConfig.validationFields.billFormat,
      },
      fiscalYearList:[],
      emailValidation: {
        flag: false,
        fieldList: tableConfig.formWithEmailValidation,
      },
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    this.getFiscalYearList();
    /** tableLayoutService.adjustToScreen(
     this.refs.tableReference,
     this.refs.fixedTableFooter,
     this.refs.fixedTableBody,
     ); */
  }

  getFiscalYearList = () => {
    companyApi.getFiscalYear().then(response => {
      if (checkIfApiCallSuccess(response)) {
        let responseDataList = response.data.list;
        this.setState({ fiscalYearList: responseDataList });
      }
    });
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = async () => {
    const { getDetails, getBUList } = this.props;
    const { dialogType, company } = this.state;
    let dialogMode = dialogType;

    await getDetails().then(async companyDetails => {
      let company;
      if (Object.keys(companyDetails).length === 0) {
        company = tableConfig.getDetail();
      } else {
        company = companyDetails;
        dialogMode = ACTION.UPDATE;
      }

      await getBUList().then(response => {
        const buList = response.list;
        company.buList = buList.filter(bu => bu.status === STATUS.ACTIVE).map(bu => bu.businessId);

        this.setState({
          buList,
          company,
          dialogType: dialogMode,
        });

        //
      });

      /** company.buList = [];
        this.setState({
          company,
          dialogType: dialogMode,
        }); */
    });
  };

  handleEditIconClick = data => {
    const ledger = tableConfig.getDetail(data);
    this.setState({
      dialogType: ACTION.UPDATE,
      ledger,
    });
  };

  handleFabButtonClick = () => {
    const ledger = tableConfig.getDetail();
    this.setState({
      dialogType: ACTION.ADD,
      ledger,
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  onCheckBoxClick = (label, value) => {
    const { company } = this.state;
    const { buList } = company;
    const indexInBuList = company.buList.indexOf(value);
    if (label === 'bu') {
      if (indexInBuList > -1) {
        buList.splice(indexInBuList, 1);
      } else {
        buList.push(value);
      }
      company.buList = buList;
    } else {
      company.printInfo.batchEnabled = value;
    }

    this.setState({ company });
  };

  handleInputChange = (field, value, billFormat = false, printInfo = false) => {
    const { company } = this.state;
    if (billFormat) {
      company.billFormat[field] = value;
    } else if (printInfo) {
      company.printInfo[field] = value;
    } else {
      company[field] = value;
    }

    this.setState({ company });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({ validation });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  createCompany = () => {
    const { company } = this.state;
    const { create, user, bu_id } = this.props;

    const apiTransformedData = tableConfig.getApiTransformedData(company);
    create(apiTransformedData)
      .then(response => {
        const { onCreateSuccess } = this.props;
        const companyResponse = response.company;
        const snack = snackService.generateSuccessMessage();

        const billingDetails = setDetails({
          user,
          bu_id,
          company: companyResponse,
        });

        this.setState({ snack });

        onCreateSuccess(billingDetails);
        history.push(`/${SALES_INVOICE_BASE}`);
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({
          company,
          snack,
        });
      });
  };

  updateCompany = () => {
    const { company, data } = this.state;
    const { update, onUpdateSuccess, user, bu_id, fiscalYearRange, permission } = this.props;
    const apiTransformedData = tableConfig.getApiTransformedData(company, ACTION.UPDATE);
    const {period_id,...apiTransformedFiltered} =apiTransformedData ;
    update({
      id: company.idCompany,
      query: apiTransformedFiltered,
    }).then(res => {
      const updatedDataList = data.list.map(item =>
        item.idLedger === Number(res.ledger.idLedger) ? res.ledger : item,
      );
      data.list = updatedDataList;
      const snack = snackService.generateUpdateMessage();
      this.setState(
        {
          data,
          snack,
        },
        () => history.push(`/${SALES_INVOICE_BASE}`),
      );
      const billingDetails = setDetails({
        user,
        bu_id,
        company,
        fiscalYearRange,
        permission,
      });
      onUpdateSuccess(billingDetails);
    });
  };

  handleModalSubmit = () => {
    const { company, validation, dialogType, billFormatValidation } = this.state;
    if (company.panNumber.toString()== '' || company.panNumber.toString().length !== PAN_NO_VALID_DIGIT) {
      validation.flag = true;
      this.setState({ validation });
      return
    }
    const check = company?.email === '' ? false:company?.email?.match(EMAIL_RULE);
    if(check===null){
     this.setState({emailValidation:{flag:true}})
      return;
    }
    // eslint-disable-next-line no-shadow
    const formValid = validateForm(company, validation, valid => this.setState({ validation: valid }));
    if (!formValid) {
      return;
    }

    const billFormValidation = validateForm(company.billFormat, billFormatValidation, valid =>
      this.setState({ validation: valid }),
    );

    if (billFormValidation) {
      this.handleModalClose();
      switch (dialogType) {
        case ACTION.ADD:
          this.createCompany();
          break;
        case ACTION.UPDATE:
          this.updateCompany();
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { company, snack, dialogType, validation, buList,fiscalYearList,emailValidation } = this.state;
    const { serverResponseWaiting, } = this.props;
    return (
      <div>
        <Dialog
          company={company}
          buList={buList}
          validation={validation}
          actionType={dialogType}
          invlidPAN={false}
          formEmptyField={false}
          onCheckBoxClick={this.onCheckBoxClick}
          handleFileUpload={this.handleFileUpload}
          onInputChange={this.handleInputChange}
          onBillFormatChange={this.handleInputChange}
          onModalSubmit={this.handleModalSubmit}
          fiscalYearList={fiscalYearList}
          serverResponseWaiting={serverResponseWaiting}
          emailValidation={emailValidation.flag}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

const CustomerWithState = withBaseState(SalesInvoice);

const mapDispatchToProps = dispatch => ({
  onCreateSuccess: action => dispatch(action),
  onUpdateSuccess: action => dispatch(action),
});

const CustomerWithActionAndState = connect(null, mapDispatchToProps)(CustomerWithState);

export default CustomerWithActionAndState;
