import React from 'react';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { getPercentage } from '../../../../utils/miscellaneous';
import { VAT_PERCENTAGE } from '../../../../data/enums/enums';
import numberToWords from '../../../../utils/numberToTextConverter';
import { salesInvoiceDetailConfig } from '../../../../v1/views/SalesInvoice/SalesInvoiceDetailConfig';

const TITLE = {
  ORIGINAL: 'TAX INVOICE',
  ORIGINAL2: 'INVOICE',
  COPY: 'INVOICE (Copy Of Original)',
};
const TEMPORARY_TITLE = {
  ORIGINAL: 'ESTIMATE',
  ORIGINAL2: 'ESTIMATE',
  COPY: 'ESTIMATE',
};
const PRINT_COPY = 2;

const MESSAGE = { NEXT_PAGE: 'Continued on next page ...' };

const getTaxableAmount = (netValue = 0, promotionDiscount = 0, tradeDiscount = 0, cashDiscount = 0, excise = 0) =>
  Number(Number(netValue - (promotionDiscount + tradeDiscount + cashDiscount) + excise).toFixed(2));

const getTotals = data => {
  const billPercentage = getPercentage(data.billDiscount || 0, data.subTotal);
  const tradePercentage = getPercentage(data.tradeDiscount || 0, data.subTotal - data.billDiscount);

  const taxableAmount = getTaxableAmount(
    data.amount || 0,
    data.promotionDiscount || 0,
    data.tradeDiscount || 0,
    data.billDiscount || 0,
    data.excise || 0,
  );
  const subTotal = data.subTotal ? Number(Number(data.subTotal).toFixed(2)) : 0;
  const actualTaxableAmount = taxableAmount - data.taxExemptedAmount;
  return {
    subTotal,
    billPercentage,
    taxableAmount,
    tradePercentage,
    actualTaxableAmount,
  };
};
const getBillTotalList = (data = {}) => {
  /* const billPer = getPercentage(data.billDiscount || 0, data.subTotal);
  const tradePer = getPercentage(data.tradeDiscount || 0,
    (data.subTotal - data.billDiscount));

  const taxableAmount = getTaxableAmount(
    data.amount || 0,
    data.promotionDiscount || 0,
    data.tradeDiscount || 0,
    data.billDiscount || 0
  ); */
  const {
    billPercentage: billPer,
    taxableAmount,
    tradePercentage: tradePer,
    subTotal,
    actualTaxableAmount,
  } = getTotals(data);
  let billTotalList = [
    {
      title: 'Sub Total',
      value: data.subTotal ? Number(Number(data.subTotal).toFixed(2)) : 0,
    },
    {
      title: `Bill Discount${billPer ? ` (${billPer}%)` : ''}`,
      value: data.billDiscount || 0,
    },
    {
      title: `Trade Discount${tradePer ? ` (${tradePer}%)` : ''}`,
      value: data.tradeDiscount || 0,
    },

    { title: 'Taxable Amount', value: actualTaxableAmount },

    { title: 'VAT', value: data.vat || 0 },
  ];

  if (JSON.parse(localStorage.getItem('rosiaCompany'))?.enableExcise) {
    billTotalList.splice(3, 0, { title: 'Excise Duty', value: data.excise });
  }

  return billTotalList;
};

const getBillSummaryDetails = (data = {}) => {
  const billSummaryDetails = [
    { title: 'Invoice Id', value: data?.invoiceNumber || '' },
    {
      title: 'Customer Name',
      value: data?.customerName || '',
    },
    {
      title: 'Address',
      value: data?.customerDetail ? data?.customerDetail?.address : '',
    },
    {
      title: 'Phone Number',
      value: data?.customerDetail ? data?.customerDetail?.phoneNumber : '',
    },
    {
      title: 'PAN No',
      value: data?.customerDetail ? data?.customerDetail?.panNo : '',
    },
    {
      title: 'Salesperson',
      value: data?.agentDetail
        ? `${data?.agentDetail?.name} ${data?.agentDetail?.phone?.length ? `(${data?.agentDetail?.phone})` : ''}`
        : '',
    },
  ];

  return billSummaryDetails;
};

const getBillFooterSummary = (data = {}) => {
  const inWords = data.netAmount ? numberToWords(data.netAmount || 0) : '';
  const billFooterSummary = {
    remarks: data.remarks || '',
    grossValue: data.netAmount ? fixedFloatAndCommas(data.netAmount) : 0,
    totalInWords: inWords,
    paymentMode: data.paymentMode || ' ',
  };

  return billFooterSummary;
};

const customerDetailMapper = detail => ({
  invoiceId: detail.refNumber || '',
  customerName: detail.customer ? detail.customer.title : '',
  customerAddress: detail.customer ? detail.customer.address : '',
  panNo: detail.customer ? detail.customer.address : '',
  salesReturnNumber: detail.salesReturnInvoiceNumber || '',
});
const billSummaryMapper = data => ({
  totalValue: data.netAmount || 0,
  remarks: data.remarks || '',
  totalInWords: data.totalInWords || '',
});
const getTotalContent = data => ({
  title: 'Total',
  value: data.netAmount || 0,
});

const headerConfig = [
  {
    id: 1,
    label: 'test',
    title: 'SN',
    className: null,
    sortable: false,
  },
  {
    id: 2,
    label: 'sku',
    title: 'SKU',
    className: null,
    sortable: false,
  },
  {
    id: 4,
    label: 'quantity',
    title: 'Quantity',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 3,
    label: 'unit',
    title: 'Unit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 5,
    label: 'rate',
    title: 'Rate',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 6,
    label: 'amount',
    title: 'Amount',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'discount',
    title: 'Discount',
    className: ' right-align',
    sortable: false,
  },
  {
    id: 8,
    label: 'net_amount',
    title: 'Net Amount',
    className: 'right-align',
    sortable: false,
  },
];

const SIDetailBody = ({ dataList = [], printInfoBill, remainingLines = [] }) => {
  const getUOM = (list, field) => list?.uom_list?.find(a => a?.id === list?.uomId)[field];
  return (
    <tbody>
      {dataList.map((data, index) => (
        <tr className="cursor-pointer">
          <td className="sn">{data.index ? data.index + 1 : index + 1}</td>
          <td className="hide-overflow-text">{data.sku}</td>
          {printInfoBill.batchEnabled && <td className='batch'>{data.batch}</td>}
          <td className="right-align quantity">{data?.quantity / getUOM(data, 'pcs') || data?.quantity}</td>
          <td className="batch right-align">{getUOM(data, 'label') || 'Pcs'}</td>
          <td className="right-align">{fixedFloatAndCommas(data?.uomRate)}</td>
          <td className="right-align">{fixedFloatAndCommas(data.amount)}</td>
          <td className="right-align">{fixedFloatAndCommas(data.promotionDiscount)}</td>
          <td className="right-align">{fixedFloatAndCommas(data.netAmount)}</td>
        </tr>
      ))}
      {remainingLines.length
        ? remainingLines.map(i => (
            <tr className="blank-tr">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))
        : null}
    </tbody>
  );
};

const lengthyAttributes = ['sku'];
const printEnums = {
  withoutFooterLimit: 40,
  withFooterLimit: 29,
  sku: 38,
  batch: 20,
  lengthyAttributes,
};
const printEnumsBatch = {
  withoutFooterLimit: 40,
  withFooterLimit: 29,
  sku: 38,
  batch: 20,
  lengthyAttributes: [...lengthyAttributes, 'batch'],
};
const config = {
  title: 'Sales Invoice',
  header: headerConfig,
  getTableBody: SIDetailBody,
  getSummaryViewList: getBillSummaryDetails,
  customerDetailMapper,
  // getFooterContent,
  getTotalContent,
  billSummaryMapper,
  printEnums,
  printEnumsBatch,
  uniqueIdGetter: i => i.idSalesDetail,
};
export {
  TITLE,
  MESSAGE,
  PRINT_COPY,
  TEMPORARY_TITLE,
  getBillTotalList,
  getBillFooterSummary,
  getBillSummaryDetails,
  config,
  getTotals,
};
