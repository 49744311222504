import { compose } from 'recompose';

import StockJournal from './StockJournal';

import apiInterceptor from '../../../api/interceptor';

import { stockJournalApi } from '../../common/base.api';

const composedStockJournal = compose(apiInterceptor({ ...stockJournalApi }))(StockJournal);

export default composedStockJournal;
