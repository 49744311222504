/**
 * Created by kAy on 8/10/17.
 */
import React, { Component } from 'react';
import { Calendar } from 'react-date-range';
import PropTypes from 'prop-types';
import { Grid, Cell, Icon } from 'react-mdc-web';
import * as ReactDOM from 'react-dom';
import { focusInCurrentTargetWithReference } from '../../utils/focusOutside';
import DateRangeStyled from './DateRangeStyled';
import { formatToMonthDateYear, getMomentDate } from '../../utils/filterUtil';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const propTypes = {
  onChange: PropTypes.func,
  startDate: PropTypes.instanceOf(Object),
  endDate: PropTypes.instanceOf(Object),
  filterDisable:PropTypes.bool,
};

const defaultProps = {
  onChange: () => null,
  startDate: {},
  endDate: {},
  filterDisable:false,
};

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      displayDateView: '',
    };
  }

  componentDidMount() {
    const { startDate, endDate, onChange } = this.props;
    this.setState({
      startDate,
      endDate,
      selectedDates: onChange,
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      startDate: props.startDate,
      endDate: props.endDate,
      selectedDates: props.onChange,
    });
  }

  handleSelect = (date, flag) => {
    this.setState(
      {
        [flag]: date,
        displayDateView: '',
      },
      () => {
        this.state.selectedDates(this.state.startDate, this.state.endDate);
      },
    );
  };

  onBlur = (e, date) => {
    if (!focusInCurrentTargetWithReference(e, ReactDOM.findDOMNode(this.refs[date]))) {
      this.setState({ displayDateView: '' });
    }
  };

  clicked = date => {
    const { displayDateView } = this.state;
    this.setState(
      {
        displayDateView: displayDateView ? '' : date,
      },
      () => {
        if (this.refs[date]) {
          ReactDOM.findDOMNode(this.refs[date]).focus();
        }
      },
    );
  };

  render() {
    const { displayDateView, startDate, endDate } = this.state;
    const { filterDisable } = this.props;
    return (
      <DateRangeStyled>
        <div className="date-range-picker-wrapper">
          <Grid className={`default-vertical-padding-24 no-margin ${
              filterDisable ? 'cursor-not-allow' : ''}`}>
            {startDate ? (
              <Cell
                className={` ${displayDateView === 'startDate'
                ? 'date-picker-holder-active'
                : this.props.disableStartDate
                ? 'date-picker-holder disabled'
                : 'date-picker-holder'} ${
                  filterDisable ? 'pointer-event-none' : ''}`}
                col={6}
                tablet={4}
                onClick={() => this.clicked('startDate')}
              >
                <span className='calendar-icon'>
                  <Icon
                    name="event_note"
                    className={displayDateView === 'startDate' ? 'fixed-calendar-active' : 'fixed-calendar'}
                  />
                </span>
                <span id="date-range-picker-id" className="calendar-date">
                  <span className="calendar-status">From</span>
                  {formatToMonthDateYear(startDate)}
                </span>
                <span className="calendar-arrow-icon">
                  <Icon className="no-margin" name="arrow_drop_down" />
                </span>
              </Cell>
            ) : (
              <span id="date-range-picker-id">Start Date</span>
            )}
            {endDate ? (
              <Cell
                className={` ${displayDateView === 'endDate'
                ? 'date-picker-holder-active no-padding'
                : this.props.disableStartDate
                ? 'date-picker-holder no-padding disabled'
                : 'date-picker-holder no-padding'} ${
                  filterDisable ? 'pointer-event-none' : ''}`}
                col={6}
                tablet={4}
                onClick={() => this.clicked('endDate')}
              >
                <span className=" calendar-icon">
                  <Icon
                    name="event_note"
                    className={displayDateView === 'endDate' ? 'fixed-calendar-active' : 'fixed-calendar'}
                  />
                </span>
                <span id="date-range-picker-id" className="calendar-date">
                  <span className="calendar-status">To</span>
                  {formatToMonthDateYear(endDate)}
                </span>
                <span className="calendar-arrow-icon">
                  <Icon className="no-margin" name="arrow_drop_down" />
                </span>
              </Cell>
            ) : (
              <span id="date-range-picker-id">End Date</span>
            )}
          </Grid>
          {displayDateView === 'startDate' ? (
            <div tabIndex="1" onBlur={e => this.onBlur(e, 'startDate')} className="calender" ref="startDate">
              <Calendar
                date={getMomentDate(startDate).toDate()}
                maxDate={getMomentDate(endDate).toDate()}
                onChange={date => this.handleSelect(date, 'startDate')}
              />
            </div>
          ) : null}
          {displayDateView === 'endDate' ? (
            <div tabIndex="1" onBlur={e => this.onBlur(e, 'endDate')} className="calender" ref="endDate">
              <Calendar
                date={getMomentDate(endDate).toDate()}
                minDate={getMomentDate(startDate).toDate()}
                onChange={date => this.handleSelect(date, 'endDate')}
              />
            </div>
          ) : null}
        </div>
      </DateRangeStyled>
    );
  }
}

DateRangePicker.defaultProps = defaultProps;

DateRangePicker.propTypes = propTypes;

export default DateRangePicker;
