import axios from 'axios';
import { JWT } from '../environment';

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  },
);

/**
 * Send GET request to server.
 * @param {String} url
 * @return {Promise.<T>|Promise<T>}
 */
async function get(url) {
  return axios
    .get(url, {
      headers: {
        Authorization: await _getAccessToken(),
        Accept: 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.message;
    });
}

/**
 * Send array of request promises.
 * @param {Array} array of request promises
 * @return {Promise.<T>|Promise<T>}
 */
async function all(requests) {
  return axios
    .all(requests)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.message;
    });
}

/**
 * Send POST request to server
 * @param {String} url
 * @param {Object} body
 * @return {Promise.<T>|Promise<T>
 */
async function post(url, body) {
  return axios
    .post(url, body, {
      headers: {
        Authorization: await _getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.message;
    });
}

async function multipartPost(url, body) {
  return axios
    .post(url, body, {
      headers: {
        Authorization: await _getAccessToken(),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

/**
 * Send PUT request to server
 * @param {String} url
 * @param {Object} body
 * @return {Promise.<T>|Promise<T>}
 */
async function put(url, id, body) {
  let putUrl = `${url}/${id}`;

  return axios
    .put(putUrl, body, {
      headers: {
        Authorization: await _getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.message;
    });
}

/**
 * Get access token from AsyncStorage.
 * @return {Promise.<TResult>|*}
 * @private
 */
let _getAccessToken = async () => `Bearer ${localStorage.getItem(JWT.LOCAL_STORAGE.TOKEN.NAME)}`;

export {
  get, post, multipartPost, put, all
};

{
  /*
<span className="float-right">
                    Showing {start.toLocaleString()}-{end.toLocaleString()} of {totalItems.toLocaleString()}
                </span>

const start = (currentPage-1) * pageSize + 1;
const end = start + pageSize;
*/
}
