import React, { Component } from 'react';
import PropTypes from 'prop-types';
import tableConfig from './config';
import * as filterUtil from '../../../../utils/filterUtil';
import withBaseState from '../../../common/withBaseState';
import PageView from '../../../common/pagination/PageView';
import * as queryService from '../../../common/query.service';
import * as downloadService from '../../../common/download.service';
import { TAG_WISE_DETAILS_REPORT_CONFIG } from '../../../common/domain.config';
import TagWiseStyled from '../TagWiseStyled';

const propTypes = {
  taWiseDetails: PropTypes.shape({
    detail: {
      name: PropTypes.string,
      ledgerId: PropTypes.number,
      debit: PropTypes.number,
      credit: PropTypes.number,
      openingBalance: PropTypes.number,
      closingBalance: PropTypes.number,
    },
    date: {
      start: PropTypes.string,
      end: PropTypes.string,
    },
  }),
  getReportDetails: PropTypes.func.isRequired,
};

const defaultProps = {
  taWiseDetails: {
    detail: {
      name: '',
      ledgerId: null,
      debit: null,
      credit: null,
      openingBalance: null,
      closingBalance: null,
    },
    date: {
      start: filterUtil.getStartOfCurrentMonth(),
      end: filterUtil.getCurrentDay(),
    },
  },
};

class TagWiseDetail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const queryParams = { ...queryService.queryParameters };
    const { match, tagDetail } = props;
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      data: {
        list: [],
        total: 0,
      },
      detailId: parseInt(match.params.id, 10),
      queryParameters: { date: tagDetail ? tagDetail.date || queryParams.date : queryParams.date, sort: queryParams.sort, pagination: queryParams.pagination},
      printButtonClicked: false,
      dataSet: [],
      columnTotal: {}
    };
    const { downloadList } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }
  componentWillUnmount() {
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { getTagDetailReport } = this.props;
    const { queryParameters, detailId} = this.state;
    const extraQuery = `&tag_ids=[${detailId}]`;
    getTagDetailReport({
     queryParameters: { ...queryParameters },
     extraQuery,
    }).then(response => {
      const { data } = this.state;
      data.list = response.list;
      const columnTotal = response.total;
      this.setState(
        {
          dataSet: data.list,
          totalData: response.total,
          columnTotal,
        },
      );
    });
  };
  handleDownloadClick = type => {
    const { downloadList ,location} = this.props;
    const { queryParameters,detailId } = this.state;
    const { state = {} } = location;
    const extraQueryString = `&tag=${state.title}&tag_ids=[${detailId}]`;
    downloadList({
      type,
      query: queryParameters,
      extraQueryString,
    }).then(response => downloadService.resolver(response));
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  render() {
    const { data, display, queryParameters,  columnTotal} = this.state;

    const { serverResponseWaiting ,location} = this.props;
    const { state = {} } = location;
    return (
      <TagWiseStyled>
        <div className= 'tag-wise-details'>
          <PageView
            domainConfig={{ ...TAG_WISE_DETAILS_REPORT_CONFIG, title: state.title }}
            headerConfig={{
              search: true,
              date: true,
              download: true,
              filter: true,
              create: false,
            }}
            footerConfig={{
              pagination: false,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            resetFilter={this.basePaginationService.resetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            handleDownloadClick={this.handleDownloadClick}
            data={data}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onPageSelect={this.basePaginationService.onPageSelect}
            columnTotal ={columnTotal}
          />
          </div>
        </TagWiseStyled>
    );
  }
}

TagWiseDetail.propTypes = propTypes;

TagWiseDetail.defaultProps = defaultProps;

const TagWiseDetailWithState = withBaseState(TagWiseDetail);

export default TagWiseDetailWithState;
