import styled from 'styled-components';

const EntryLogStyled = styled.div`
  /* entryLog for Print ONly quickFix*/
  .flex {
    .mdc-layout-grid__inner {
      display: flex !important;
    }
  }
  .center,
  .right,
  .left {
    width: 32% !important;
  }
  /* entryLog for Print ONly quickFix End*/

  @media print{
    &.entry-log {
      .portrait{
        .print-body{
          height: 100% !important;
          page-break-after: always;
          padding:0 !important;
          margin:0.28in 0.3in !important;
          .fixed-table-wrapper{
            height:1420px !important;
            table{
              height:100%;
              .blank-tr{
                height:32px !important;
                border:0;
              }
              thead{
                th{
                  font-size: 14px !important;
                }
              }
              tbody{
                td{
                  font-size: 14px !important;
                  &.ledger{
                    width:150px !important;
                    min-width:150px !important;
                    max-width:150px !important;
                    white-space:normal !important;
                  }
                  &:last-child{
                    font-weight:500;
                  }
                }
              }
            }
          }
          .footer-block{
            position:unset;
          }
        }
      }
    }
  }
  @media print and (max-width:5.83in) {
    &.entry-log .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
          height:1405px !important;
        }
    }
  }
`;
export default EntryLogStyled;
