import React, { Component } from 'react';
import * as appConstants from '../../config';
import * as httpUtils from '../../../utils/httpUtils';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
} from 'react-mdc-web';
import { purchaseReturnDetailConfig, PurchaseReturnDetailTableBody } from './PurchaseReturnDetailConfig';
import moment from 'moment';
import PropTypes from 'prop-types';
import history from '../../../utils/history';
import { GRN_CREATE, PURCHASE_RETURN_BASE } from '../../../data/enums/Route';

//invoice id is present in the list.
class PurchaseReturnDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      supplier: {
        customerName: '',
        customerAddress: '',
      },
      GRNNumber: '',
      distributorDetails: {
        title: 'Amazon Distributors Pvt. Ltd.',
        address: 'Jawlakhel, Lalitpur',
        phone: '01-4256783',
        panNo: '4256783',
      },
      billSummary: {
        totalValue: 0,
        remarks: '',
        date: '',
        discount: 0,
      },
      date: '',
      headerTitleToView: purchaseReturnDetailConfig.headerTitleToView,
      skuList: [],
    };
  }

  componentDidMount() {
    this.getDetail();
  }

  getDetail = () => {
    this.setState({ loading: true });
    httpUtils.get(appConstants.baseUrl + `purchase-return/detail?id=${this.props.params.id}`).then(response => {
      this.setState({ loading: false });
      if ((response.status == '200') & response.success) {
        const purchaseReturnDetail = response.data;
        const supplierDetails = {
          name: purchaseReturnDetail.customer.name,
          address: purchaseReturnDetail.customer.address,
        };
        const grnNumber = purchaseReturnDetail.refNumber;
        const billSummary = {
          totalValue: purchaseReturnDetail.netAmount,
          remarks: purchaseReturnDetail.remarks,
          date: purchaseReturnDetail.date,
        };
        const skuList = purchaseReturnDetail.details;
        const date = moment(purchaseReturnDetail.date).format('DD MMM YYYY');
        this.setState({
          supplier: supplierDetails,
          billSummary: billSummary,
          skuList: skuList,
          date: date,
          GRNNumber: grnNumber,
        });
      } else {
      }
    });
  };

  handleCancelClick = () => {
    history.push(`/${PURCHASE_RETURN_BASE}`);
  };

  render() {
    const { loading, skuList, billSummary } = this.state;
    const purchaseReturnId = this.props.params.id;
    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="card-header-bar">
            <h2>Purchase Return: {purchaseReturnId}</h2>
            <div className="header-menu">
              <div className="header-right">
                <span>{moment(billSummary.date).format('ll')}</span>
              </div>
            </div>
          </div>
          <div className="card-body" ref="tableReference">
            <div className="print-invoice three-input-wrapper">
              <Grid>
                <Cell col={2}>
                  <Textfield
                    className="right-side-gap"
                    floatingLabel="PR ID"
                    value={this.props.params.id}
                    onChange={e => this.onInputFieldChange('name', e.target.value)}
                  />
                </Cell>
                <Cell col={2}>
                  <Textfield
                    className="right-side-gap"
                    floatingLabel="GRN Number"
                    value={this.state.GRNNumber}
                    onChange={e => this.onInputFieldChange('name', e.target.value)}
                  />
                </Cell>
                <Cell col={3}>
                  <Textfield
                    className="right-side-gap"
                    floatingLabel="Supplier"
                    value={this.state.supplier.name}
                    onChange={e => this.onInputFieldChange('name', e.target.value)}
                  />
                </Cell>
                <Cell col={2}>
                  <Textfield
                    className="right-side-gap"
                    floatingLabel="Address"
                    value={this.state.supplier.address}
                    onChange={e => this.onInputFieldChange('address', e.target.value)}
                  />
                </Cell>
                <Cell col={3}>
                  <Textfield
                    className="right-side-gap"
                    floatingLabel="Remarks"
                    value={this.state.billSummary.remarks}
                    onChange={e => this.onInputFieldChange('invoiceId', e.target.value)}
                  />
                </Cell>
              </Grid>
            </div>
            <div ref="fixedTableBody" className="fixed-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>PR Detail ID</th>
                    <th>SKU</th>
                    <th>Rate</th>
                    <th>Quantity (pcs)</th>
                    <th>Discount</th>
                    <th>Net Amount</th>
                  </tr>
                </thead>
                {skuList.map((data, key) => (
                  <tbody>
                    <PurchaseReturnDetailTableBody data={data} index={key} />
                  </tbody>
                ))}
              </table>
            </div>
            <div className="total-section-wrapper">
              <div className="hr"></div>
              <Grid>
                <Cell col={8}></Cell>
                <Cell col={3} className="right-align active-opacity-text">
                  Total
                </Cell>
                <Cell col={1} className="right-align font-bold">
                  {' '}
                  {billSummary.totalValue}
                </Cell>
              </Grid>
            </div>
          </div>
          <div className="newinvoice-btn-wrapper">
            <Grid>
              <Cell col={8}></Cell>
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>
              </Cell>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

PurchaseReturnDetails.contextTypes = {
  router: PropTypes.object,
};

export default PurchaseReturnDetails;
