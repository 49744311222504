import { compose } from 'recompose';

import apiInterceptor from '../../../api/interceptor';
import {
  buApi, ledgerApi, debitNoteAPi
} from '../../common/base.api';
import CreateDebitNote from './CreateDebitNote';

const composedCreateDebitNote = compose(
  apiInterceptor({
    ...buApi,
    create: { ...debitNoteAPi },
    getList: { ledgerApi },
  }),
)(CreateDebitNote);

export default composedCreateDebitNote;
