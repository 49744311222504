import React from 'react';
import {
  Grid, Cell, Button, Card, Checkbox
} from '../../../components/BillingMDC';
import BillingSelect from '../../../components/Select/BillingSelect';
import BillingTextField from '../../../components/TextField/BillingTextField';
import { EMPTY, PAN_NO_ERROR } from '../../../data/enums/errorMessage';
import { PAN_NO_VALID_DIGIT, EMAIL_RULE} from '../../../data/enums/enums';
import Loading from '../../../common/Loading';

const CompanyForm = ({
  company,
  onModalSubmit,
  onInputChange, // onChange
  validation,
  buList,
  onCheckBoxClick,
  fiscalYearList,
  actionType,
  serverResponseWaiting,
  emailValidation,
}) =>{ 
  const check = company?.email?.match(EMAIL_RULE);
  const emailCriteriaStatus = check === null;
  return(
  <div className='pannel-section'>
    <Loading display={serverResponseWaiting} />
    <div className='pannel-header'>
      <h1>Welcome</h1>
      Please fill out the form below and proceed.
    </div>

    <h4>1. General Details</h4>
    <div>
      <div className='pannel-card'>
        <Grid className='grid-padding'>
          <Cell col={4} tablet={6}>
            <BillingTextField
              value={company.title}
              handleChange={onInputChange}
              floatingLabel='Company Name'
              param='title'
              required={true}
              className='right-side-gap'
              emptyField={validation.flag}
            />
          </Cell>
          <Cell col={4} tablet={6}>
            <BillingTextField
              value={company.panNumber}
              handleChange={onInputChange}
              floatingLabel='PAN Number'
              param='panNumber'
              type='number'
              required
              emptyField={validation.flag}
              className='right-side-gap'
              errorMessage={
                company.panNumber && company.panNumber.toString().length !== PAN_NO_VALID_DIGIT
                  ? PAN_NO_ERROR
                  : EMPTY
              }
              invalidError={company.panNumber  && company.panNumber .toString().length !== PAN_NO_VALID_DIGIT}
            />
          </Cell>
          <Cell col={4} tablet={6}>
            <BillingTextField
              value={company.address}
              handleChange={onInputChange}
              floatingLabel='Address'
              param='address'
              className='right-side-gap'
              required
              emptyField={validation.flag}

            />
          </Cell>
          <Cell col={4} tablet={6}>
            <BillingTextField
              value={company.email}
              handleChange={onInputChange}
              floatingLabel='Email'
              param='email'
              className='right-side-gap'
              emptyField={emailValidation}
              errorMessage={company?.email === '' ? '' :emailCriteriaStatus ? 'Email is not valid' : ''}
              invalidError={emailValidation}

            />
          </Cell>
          <Cell col={4} tablet={6}>
            <BillingTextField
              value={company.phone}
              handleChange={onInputChange}
              floatingLabel='Phone'
              param='phone'
              className='right-side-gap'
              required
              emptyField={validation.flag}
            />
          </Cell>
          <Cell col={4} tablet={6}
            className={`${actionType ==='Update' && 'disabled_col'}`}
          >
            <label>Fiscal year</label>
            <BillingSelect
              name='form-field-name'
              param='periodId'
              clearable={false}
              value={company.periodId}
              labelKey='title'
              valueKey='idFiscalYear'
              handleChange={onInputChange}
              options={fiscalYearList}
              required={ actionType !=='Update' && true}
              className='right-side-gap'
              emptyField={actionType !=='Update' && validation.flag}
              floatingLabel='Fiscal year'
              disabled={actionType ==='Update' && 'disabled'}
            />
          </Cell>
        </Grid>
      </div>
    </div>
    <h4>2. Bill Format</h4>
    <div className='pannel-card'>
      <Grid className='grid-padding'>
        <Cell col={3} tablet={6}>
          <BillingTextField
            param='jv_prefix'
            floatingLabel='JV Prefix'
            className='right-side-gap'
            emptyField={validation.flag}
            value={company.billFormat.jv_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
          />
        </Cell>
        <Cell col={3} tablet={6}>
          <BillingTextField
            value={company.billFormat.invoice_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
            floatingLabel='Invoice Prefix'
            param='invoice_prefix'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>
        <Cell col={3} tablet={6}>
          <BillingTextField
            value={company.billFormat.purchase_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
            floatingLabel='Purchase Prefix'
            param='purchase_prefix'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>
        <Cell col={3} tablet={6}>
          <BillingTextField
            value={company.billFormat.prn_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
            floatingLabel='PRN Prefix'
            param='prn_prefix'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>
        <Cell col={3} tablet={6}>
          <BillingTextField
            value={company.billFormat.srn_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
            floatingLabel='SRN Prefix'
            param='srn_prefix'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>
        <Cell col={3} tablet={6}>
          <BillingTextField
            value={company.billFormat.payment_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
            floatingLabel='Payment Prefix'
            param='payment_prefix'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>

        <Cell col={3} tablet={6}>
          <BillingTextField
            value={company.billFormat.abi_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
            floatingLabel='ABI Prefix'
            param='abi_prefix'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>
        <Cell col={3} tablet={6}>
          <BillingTextField
            value={company.billFormat.ce_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
            floatingLabel='Contra Entry Prefix'
            param='ce_prefix'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>

        <Cell col={3} tablet={6}>
          <BillingTextField
            value={company.billFormat.pir_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
            floatingLabel='Receipt Prefix'
            param='pir_prefix'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>

        <Cell col={3} tablet={6}>
          <BillingTextField
            value={company.billFormat.credit_note_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
            floatingLabel='Credit Note Prefix'
            param='credit_note_prefix'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>

        <Cell col={3} tablet={6}>
          <BillingTextField
            value={company.billFormat.debit_note_prefix}
            handleChange={(field, value) => onInputChange(field, value, true)}
            floatingLabel='Debit Note Prefix'
            param='debit_note_prefix'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>
      </Grid>
    </div>
    <h4>3. Bill Print</h4>
    <div className='pannel-card bill_print'>
      <Grid className='grid-padding'>
        <Cell col={4} tablet={6}>
          <BillingTextField
            value={Math.floor(parseFloat(company.printInfo.count) || 0)}
            handleChange={(field, value) => onInputChange(field, value, false, true)}
            floatingLabel='Bill Print Count'
            param='count'
            type='number'
            className='right-side-gap'
            emptyField={validation.flag}
          />
        </Cell>
        <Cell col={3} tablet={6}>
          <div className='checkbox'>
            <label htmlFor=''>Show Batch info</label>
            <Checkbox
              checked={company.printInfo.batchEnabled}
              onChange={({ target: { checked } }) => {
                onCheckBoxClick('printInfo', checked);
              }}
            />
          </div>
        </Cell>
      </Grid>
    </div>
    <h4>4. Business Unit</h4>
    <div className='pannel-card'>
      <div className='checkbox'>
        {buList.map((bu) => (
          <div>
            <Checkbox
              checked={company.buList.includes(bu.businessId)}
              onChange={({ target: { checked } }) => {
                onCheckBoxClick('bu', bu.businessId);
              }}
            />
            <span>{bu.businessAlias}</span>
          </div>
        ))}
      </div>
    </div>
    <div className='button'>
      <Button
        accent
        onClick={() => {
          onModalSubmit();
        }}
      >
        Save
      </Button>
    </div>
  </div>
)};

export default CompanyForm;
