import React from 'react';
import PropTypes from 'prop-types';

import Switch from '../../../../components/Switch';
import { ACTION } from '../../../../data/enums/enums';
import { Button, Icon } from '../../../../components/BillingMDC';
import { EMPTY } from '../../../../data/enums/errorMessage';
import HorizontalLabelValueBar from '../../../common/horizontalLabellValueBar';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import CatalogDetails from './catalogDetails';
import Batch from './batch';
import PageHeaderStyled from '../../../common/pagination/PageHeaderStyled';
import SKUDetailStyled from './SKUDetailStyled';
import Dialog from './Dialog';
import BillingSelect from '../../../../components/Select/BillingSelect';
import { VATList, UOMList } from './config';
import SkuPacking from './skuPackaging';

const propTypes = {
  cancelFlag: PropTypes.bool,
  crudMode: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  updateCrudMode: PropTypes.func.isRequired,
  batchRef: PropTypes.instanceOf(Object),
  catalogDetailRef: PropTypes.instanceOf(Object),
  handleCancelClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  excise: PropTypes.bool,
};

const defaultProps = {
  cancelFlag: false,
  crudMode: ACTION.CREATE,
  data: {},
  enableErrorDisplay: false,
  title: '',
  batchRef: {},
  catalogDetailRef: {},
  excise: false,
};

const SkuDetailView = ({
  data,
  title,
  crudMode,
  handleInputChange,
  enableErrorDisplay,
  updateCrudMode,
  catalogDetailRef,
  batchRef,
  handleCancelClick,
  handleSaveClick,
  cancelFlag,
  dialogBox,
  actionType,
  onCancel,
  excise,
  packagingRef,
  id,
  handleVatDialogSubmit
}) => (
  <SKUDetailStyled className={`pad-b-24 ${crudMode === ACTION.ADD ? 'sku_create' : 'sku_details'}`}>
    <PageHeaderStyled>
      <div className='card-header-bar'>
        <h2>{title}</h2>
        <div className='header-menu'>
          {crudMode === ACTION.READ && <Icon name='edit' onClick={() => updateCrudMode(ACTION.UPDATE)} />}
        </div>
      </div>
    </PageHeaderStyled>
    <div className='card-body' style={{ pointerEvents: crudMode === ACTION.READ ? 'none' : '' }}>
      <div className='form-wrap'>
        <h6>General Setting</h6>
        <div className='form-inputs'>
          <HorizontalLabelValueBar
            label='Title'
            value={
              <BillingTextField
                value={data.title}
                handleChange={handleInputChange}
                param='title'
                required
                type='text'
                className=''
                emptyField={enableErrorDisplay}
                errorMessage={EMPTY}
              />
            }
          />
        </div>
        <div className='form-inputs'>
          <HorizontalLabelValueBar
            label='Description'
            value={
              <BillingTextField
                value={data.description}
                handleChange={handleInputChange}
                param='description'
                required
                type='text'
                className=''
                emptyField={enableErrorDisplay}
                errorMessage={EMPTY}
              />
            }
          />
        </div>
        <div className='form-inputs'>
          <HorizontalLabelValueBar
            label='RLP'
            value={
              <BillingTextField
                value={data.rlp}
                handleChange={handleInputChange}
                floatingLabel='RLP'
                param='rlp'
                type='number'
                required
                zeroError={false}
                emptyField={enableErrorDisplay}
                errorMessage={EMPTY}
              />
            }
          />
        </div>
        {excise && (
          <div className='form-inputs'>
            <HorizontalLabelValueBar
              label='Excise Duty Percent'
              value={
                <BillingTextField
                  value={data.excisePercent}
                  handleChange={handleInputChange}
                  floatingLabel='Excise Duty Percent'
                  param='excisePercent'
                  type='number'
                />
              }
            />
          </div>
        )}
        <div className='form-inputs select'>
          <HorizontalLabelValueBar
            label='VAT Percent'
            value={
              <BillingSelect
                name='form-field-name'
                param='vatPercent'
                clearable={false}
                isSearchable={false}
                value={data.vatPercent}
                valueKey='value'
                labelKey='value'
                options={VATList}
                handleChange={handleInputChange}
              />
            }
          />
          {dialogBox && (
            <Dialog actionType={actionType} onModalClose={onCancel} handleSaveClick={handleVatDialogSubmit} data={data} />
          )}
        </div>
        <div className='form-switchs'>
          <HorizontalLabelValueBar
            label='Status'
            value={
              <Switch
                onToggle={(checked, event) => handleInputChange('status', checked ? 1 : 0)}
                checked={data.status}
                id={`switch-${data.skuId}`}
              />
            }
          />
        </div>
        <div className='form-switchs psku'>
          <HorizontalLabelValueBar
            label='PSKU'
            value={
              <Switch
                onToggle={(checked, event) => handleInputChange('psku', checked ? 1 : 0)}
                checked={data.psku}
                id={`switch-psku-${data.skuId}`}
              />
            }
          />
        </div>
      </div>

      {/* <div className="form-wrap">
        <h6>SKU Details</h6> */}
      {/* <div className="form-inputs su">
          <HorizontalLabelValueBar
            label="SU"
            value={
              <BillingTextField
                value={data.su}
                handleChange={handleInputChange}
                param="su"
                type="text"
                emptyField={enableErrorDisplay}
                errorMessage={EMPTY}
              />
            }
          />
        </div> */}
      {/* <div className="form-inputs">
          <HorizontalLabelValueBar
            label="UPC"
            value={
              <BillingTextField
                value={data.upc}
                handleChange={handleInputChange}
                param="upc"
                required={false}
                type="number"
                rule="isInt"
              />
            }
          />
        </div>
        <div className="form-inputs select">
          <HorizontalLabelValueBar
            label="UOM"
            value={
              <BillingSelect
                name="form-field-name"
                param="uom"
                clearable={false}
                isSearchable={false}
                value={data.uom}
                valueKey="value"
                labelKey="label"
                options={UOMList}
                handleChange={handleInputChange}
              />
            }
          />
        </div> */}
      {/* <div className="form-inputs sku_code">
          <HorizontalLabelValueBar
            label="SKU Code"
            value={
              <BillingTextField
                value={data.skucode}
                handleChange={handleInputChange}
                param="skucode"
                required={false}
                type="text"
              />
            }
          />
        </div> */}
      {/* </div> */}
      <div className='form-wrap form-multi-select-wrap'>
        <h6> SKU Packaging</h6>
        <SkuPacking ref={packagingRef} uomList={data.uomList} allUOMList={data.allUOMList} id={id}/>
      </div>
      <div className='form-wrap form-multi-select-wrap'>
        <h6> SKU Catalog</h6>
        <CatalogDetails
          cancelFlag={cancelFlag}
          ref={catalogDetailRef}
          crudMode={crudMode}
          catalogDetailList={data.catalogSkusList}
        />
      </div>
      <div className='form-wrap batch-li'>
        <h6>Batch List</h6>
        <Batch ref={batchRef} crudMode={crudMode} batchList={data.batchList} cancelFlag={cancelFlag} />
      </div>
    </div>
    <>
      {crudMode !== ACTION.READ && (
        <div className='btns'>
          <Button accent className='dialog-cancel cancel-btn modal-btn' onClick={() => handleCancelClick()}>
            Cancel
          </Button>
          <Button
            accent
            className='save-btn modal-btn'
            onClick={() => {
              handleSaveClick();
            }}
          >
            Save
          </Button>
        </div>
      )}
    </>
  </SKUDetailStyled>
);

SkuDetailView.propTypes = propTypes;
SkuDetailView.defaultProps = defaultProps;

export default SkuDetailView;
