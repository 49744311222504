import * as filterUtil from '../../utils/filterUtil';
import * as downloadService from './download.service';
import { SORTING, PAGINATION, fiscalYear, defaultFiscalYear } from '../../data/enums/enums';
import { clone } from '../../utils/arrayProcessor';

const getStartDate = () => {
  const date = localStorage.getItem(fiscalYear);
  const fiscalYearDate = date ? JSON.parse(date) : defaultFiscalYear;
  const monthStart = filterUtil.getStartOfCurrentMonth();
  const fiscalYearStart = filterUtil.formatToDateMonthYear(fiscalYearDate.start_date);
  if (new Date(monthStart) < new Date(fiscalYearStart)) {
    return fiscalYearStart;
  }

  return monthStart;
};

const sampleQueryParameters = {
  search: '',
  sort: {
    label: '',
    order: SORTING.ASC,
  },
  pagination: {
    page: PAGINATION.PAGE,
    limit: PAGINATION.LIMIT,
  },
  /* filter: {
    dse_user_id: [],
  }, */
  date: {
    start: getStartDate(),
    end: filterUtil.getCurrentDay(),
  },
  // singleDate: filterUtil.getCurrentDay(),
  flag: {},
};

const setPagination = (page, limit, queryParams) => {
  const queryParamsReplica = { ...queryParams };

  queryParamsReplica.pagination.page = page;
  queryParamsReplica.pagination.limit = limit;

  return queryParamsReplica;
};

const resetQueryParameters = query => {
  const updatedQuery = { ...query };

  const { filter = {}, pagination = null, date = {}, sort = {}, singleDate = null } = updatedQuery;

  Object.keys(filter).forEach(attribute => {
    updatedQuery.filter[attribute] = [];
  });

  if (pagination && pagination.page) {
    updatedQuery.pagination.page = 1;
  }

  if (date && date.start && date.end) {
    updatedQuery.date.start = getStartDate();
    updatedQuery.date.end = filterUtil.getCurrentDay();
  }

  if (sort.order && sort.label) {
    updatedQuery.sort.label = '';
    updatedQuery.sort.order = SORTING.ASC;
  }

  if (singleDate) {
    updatedQuery.singleDate = filterUtil.getCurrentDay();
  }

  return updatedQuery;
};

const queryGenerator = (query, extraQuery = '', downloadMode = false) => {
  let queryString = '';
  const {
    date = {
      start: null,
      end: null,
    },
    pagination = null,
    filter = {},
    sort = {
      label: null,
      order: null,
    },
    singleDate = null,
    flag = {},
    reference_date = {
      start: null,
      end: null,
    },
  } = query;

  if (date && date.start && date.end) {
    const numericStartDate = filterUtil.formatToNumericDate(date.start);
    const numericEndDate = filterUtil.formatToNumericDate(date.end);
    queryString += `start_date=${numericStartDate}&end_date=${numericEndDate}`;
  }

  if (singleDate) {
    queryString += `date=${filterUtil.formatToNumericDate(singleDate)}`;
  }

  if (reference_date && reference_date.start && reference_date.end) {
    const numericStartDate = filterUtil.formatToNumericDate(reference_date.start);
    const numericEndDate = filterUtil.formatToNumericDate(reference_date.end);
    queryString += `reference_start_date=${numericStartDate}&reference_end_date=${numericEndDate}`;
  }

  if (query.businessid) {
    queryString += `business_id=${query.businessid}`;
  }

  if (query.search) {
    const searchQuery = `search_text=${encodeURIComponent(query.search)}`;

    queryString = queryString === '' ? `${searchQuery}` : `${queryString}&${searchQuery}`;
  }

  if (!downloadMode && pagination && pagination.page) {
    const paginationQuery = `page=${pagination.page}&limit=${pagination.limit}`;

    queryString = queryString === '' ? `${paginationQuery}` : `${queryString}&${paginationQuery}`;
  }

  if (sort.label && sort.order) {
    const sortQuery = `sort_label=${sort.label}&sort_order=${sort.order}`;
    queryString = queryString === '' ? `${sortQuery}` : `${queryString}&${sortQuery}`;
  }

  Object.keys(filter).forEach(key => {
    if (filter[key] && filter[key].length) {
      queryString = queryString ? `${queryString}&${key}=[${filter[key]}]` : `${queryString}&${key}=[${filter[key]}]`;
    }
  });

  Object.keys(flag).forEach(k => {
    queryString = `${queryString}&${flag[k].label}=${flag[k].status}`;
  });

  return `?${queryString}${extraQuery}`;
};

const defaultResetQueryParameters = query => {
  const updatedQuery = { ...query };

  const { pagination = null, date = {}, sort = {} } = updatedQuery;

  if (pagination && pagination.page) {
    updatedQuery.pagination.page = 1;
  }

  if (date && date.start && date.end) {
    updatedQuery.date.start = getStartDate();
    updatedQuery.date.end = filterUtil.getCurrentDay();
  }

  if (sort.order && sort.label) {
    updatedQuery.sort.label = '';
    updatedQuery.sort.order = SORTING.ASC;
  }
  return updatedQuery;
};

class QueryClass {
  constructor(setter, getter, dataLoader, downloadList) {
    this.stateSetter = setter;
    this.stateGetter = getter;
    this.dataLoader = dataLoader;
    this.downloadList = downloadList;
  }

  setQueryParamsAndLoadData = newQueryParams => {
    this.stateSetter(newQueryParams, () => {
      this.dataLoader();
    });
  };

  resetFilter = () => {
    const queryParams = this.stateGetter();
    const newQueryParams = resetQueryParameters(queryParams);
    this.setQueryParamsAndLoadData(newQueryParams);
  };

  defaultResetFilter = defaultQueryParams => {
    const newQueryParams = defaultResetQueryParameters(defaultQueryParams);
    this.setQueryParamsAndLoadData(newQueryParams);
  };

  onPageSelect = (page, limit) => {
    const queryParameters = clone(this.stateGetter());

    queryParameters.pagination.page = page;
    queryParameters.pagination.limit = limit;

    this.setQueryParamsAndLoadData(queryParameters);
  };

  handleDateRangeChange = (start, end) => {
    const queryParameters = this.stateGetter();

    queryParameters.date.start = filterUtil.formatToDateMonthYear(start);
    queryParameters.date.end = filterUtil.formatToDateMonthYear(end);
    if (queryParameters.pagination) {
      queryParameters.pagination.page = 1;
    }

    this.setQueryParamsAndLoadData(queryParameters);
  };

  handleRefDateRangeChange = (start, end) => {
    const queryParameters = this.stateGetter();

    queryParameters.reference_date.start = filterUtil.formatToDateMonthYear(start);
    queryParameters.reference_date.end = filterUtil.formatToDateMonthYear(end);
    if (queryParameters.pagination) {
      queryParameters.pagination.page = 1;
    }

    this.setQueryParamsAndLoadData(queryParameters);
  };

  handleDateChange = date => {
    const queryParameters = this.stateGetter();
    queryParameters.singleDate = filterUtil.formatToDateMonthYear(date);

    this.setQueryParamsAndLoadData(queryParameters);
  };

  handleFilterChange = (fieldValue, selectedValue) => {
    const queryParameter = this.stateGetter();
    queryParameter.filter[fieldValue] = selectedValue;
    queryParameter.pagination.page = 1;
    this.setQueryParamsAndLoadData(queryParameter);
  };

  handleTableSorting = (labelName, sortingOrder) => {
    const queryParameter = this.stateGetter();

    queryParameter.sort.label = labelName;
    queryParameter.sort.order = sortingOrder;

    this.setQueryParamsAndLoadData(queryParameter);
  };

  handleSearchInputChange = value => {
    const queryParameter = this.stateGetter();
    queryParameter.search = value;
    queryParameter.pagination.page = 1;

    this.setQueryParamsAndLoadData(queryParameter);
    // debounce and load data
  };

  clearSearchText = () => {
    const queryParameters = this.stateGetter();
    queryParameters.search = '';
    this.setQueryParamsAndLoadData(queryParameters);
  };

  handleDownloadClick = reportType => {
    const queryParameter = this.stateGetter();
    this.downloadList({
      type: reportType,
      query: queryParameter,
    }).then(response => downloadService.resolver(response));
  };

  handleFlagChange = (value, event) => {
    const queryParameters = this.stateGetter();
    const { name = '' } = event.target;
    queryParameters.flag[name].status = value;
    this.setQueryParamsAndLoadData(queryParameters);
  };
}

export {
  sampleQueryParameters as queryParameters,
  resetQueryParameters,
  queryGenerator,
  setPagination,
  QueryClass,
  getStartDate,
};
