import styled from 'styled-components';

const TrialBalanceStyle = styled.div`
  .right-drawer {
    & > nav {
      .view-by-wrap {
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        padding: 20px 0;
        & > label {
          padding-left: 24px;
          font-size: 14px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.54);
          font-weight: 500;
        }
        & > ul {
          padding-top: 8px;
          display: flex;
          li {
            flex: 1;
            label {
              font-size: 12px;
            }
            &:first-child {
              label {
                display: inline-block;
                white-space: normal;
                width: 153px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
  .inner-view {
    background: white;
    height: 100%;
    padding: 38px 24px;
    box-shadow: 0 6px 6px 0 rgba(33, 150, 243, 0.24), 0 0 6px 0 rgba(33, 150, 243, 0.12);
    .trial-view {
      .table-view {
        overflow: auto;
        table {
          border: 1px solid #e7e7ed;
          min-width: 55%;
          max-width: 100%;
          caption {
            border-right: 1px solid #e7e7ed;
            border-left: 1px solid #e7e7ed;
            padding-bottom: 10px;
            font-size: 12px;
            &:first-child {
              border-top: 1px solid #e7e7ed;
              font-size: 16px;
              padding-bottom: 4px;
              padding-top: 10px;
            }
          }
          .ledger-header {
            th {
              background: #dff3ff;
              color: #2196f3;
              font-size: 12px;
              border-right: 1px solid #e7e7ed;
              text-align: center;
              &:first-child {
                text-align: left;
              }
            }
          }
          tr {
            height: 32px;
            transition: all 0.4s ease-in-out;
            &.active {
              height: 52px;
              transition: all 0.4s ease-in-out;
            }
            th {
              font-size: 12px;
              font-weight: bold;
              color: #000000;
              background: white;
              border-right: 1px solid #e7e7ed;
              &:last-child {
                /* border-right: none; */
              }
            }
            td {
              min-width: 52px;
              font-size: 12px;
              color: #000000;
              border-right: 1px solid #e7e7ed;
              white-space: nowrap;
              &:last-child {
                /* border-right: none; */
              }
            }
          }
          tfoot {
            td {
              font-weight: bold;
              color: #000000;
              /* border-right:none; */
            }
          }
        }
      }
      .collapsible-body-tr {
        border-top: 2px solid #2196f3;
        tr {
          &:first-child {
            th {
              color: #2196f3;
              background: #dff3ff;
              text-align: center;
              padding-right: 0;
              &:first-child {
                text-align: left;
              }
            }
          }
          &:nth-child(2) {
            th {
              background-color: rgba(218, 218, 218, 0.1);
              border-bottom: 1px solid #dadada;
            }
          }
          th {
            border-right: 0 !important;
            padding-right: 0;
            &:last-child {
              padding-right: 16px;
            }
          }
        }
        & > td {
          padding: 0;
          border: 0 !important;
          table {
            min-width: 100%;
            max-width: 100%;
            box-shadow: rgb(39 40 51 / 12%) 0px 4px 8px 0px;
            border: none;
            td {
              border: 0 !important;
              padding-right: 0;
              &:last-child {
                padding-right: 16px;
              }
            }
          }
        }
      }
    }
  }
  .collaspsible-disabled {
    td {
      span {
        padding-left: 0 !important;
        &:before {
          border: 0 !important;
        }
      }
    }
  }
  table tbody {
    tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #2196f3;
        }
      }
      td:first-child {
        & > span {
          position: relative;
          padding-left: 26px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }
    }
  }
`;
export default TrialBalanceStyle;
