import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  DialogFooter,
  Button,
  Grid,
  Cell,
} from '../../../components/BillingMDC';
import BillingTextField from '../../../components/TextField/BillingTextField';
import BillingSelect from '../../../components/Select/BillingSelect';



export const ConfirmationDialog = (
  { readModalOpen,onConfirmModalClose,getConfirmation,summary,editModalOpen,createCE},
) => {
  return(
    <DialogStyled>
      <Dialog
        open={readModalOpen }
        onClose={() => {
          onConfirmModalClose();
        }}
        className='confirmation__dialog'
      >
        <DialogHeader>
          <DialogTitle>{editModalOpen ? 'Edit Contra Entry' :'Confirmation'}</DialogTitle>
        </DialogHeader>
        <DialogBody>
           <p className='read'>
           Are you sure you want to progress ?
          </p>
        </DialogBody>
        <DialogFooter>
          <Button
            accent
            className='dialog-cancel modal-btn'
            onClick={() => onConfirmModalClose()}
          >
          Cancel
          </Button>
          <Button
            accent
            className='dialog-ok modal-btn'
            onClick={() => {
              getConfirmation();
            }}
          >
          Confirm
          </Button>
        </DialogFooter>
      </Dialog>
    </DialogStyled>
  );
};
export const CEDialog = (
  { onConfirmModalClose,editModalOpen,handleInputChange,createCE,formEmptyField,ledgerList,ledgerListCash,onModalSubmit},
) => {
  const ledgerListCashIds =ledgerListCash?.map(({customerId}) =>customerId);
  const check  =ledgerListCashIds.includes(createCE?.customerId)
  const optionList=check ? ledgerListCash :ledgerList;
  return(
    <DialogStyled>
      <Dialog
        open={editModalOpen }
        onClose={() => {
          onConfirmModalClose();
        }}
        className='confirmation__dialog'
      >
        <DialogHeader>
          <DialogTitle>Edit Contra Entry</DialogTitle>
        </DialogHeader>
        <DialogBody>
                  <div className='dialog-upperpart dialog-view'>
             <Grid className='grid-padding'>
             <Cell col={6} tablet={8}>
                  <label className='select-custom-label'>Ledger</label>
                  <BillingSelect
                    name='form-field-name'
                    param='customerId'
                    clearable={false}
                    value={createCE?.customerId}
                    valueKey='customerId'
                    labelKey='title'
                    options={optionList}
                    handleChange={handleInputChange}
                    required
                    multipleParam
                    emptyField={formEmptyField}
                  />
                </Cell>
             <Cell col={6} tablet={8}>
               <BillingTextField
                 value={createCE.drAmount}
                 handleChange={handleInputChange}
                 floatingLabel='Debit'
                 param='drAmount'
                 type='number'
                 required
                 zeroError={false}
                 className='billing-required right-side-gap'
                 emptyField={formEmptyField}
               />
             </Cell>
             <Cell col={6} tablet={8}>
               <BillingTextField
                 value={createCE.crAmount}
                 handleChange={handleInputChange}
                 floatingLabel='Credit'
                 param='crAmount'
                 type='number'
                 required
                 zeroError={false}
                 className='billing-required right-side-gap'
                 emptyField={formEmptyField}
               />
             </Cell>
             <Cell  col={6} tablet={8}>
               <BillingTextField
                 value={createCE.narration}
                 param='narration'
                 floatingLabel='Narration'
                 className='textbox billing-required'
                 handleChange={handleInputChange}
               />
             </Cell>
           </Grid>
           </div>
        </DialogBody>
        <DialogFooter>
          <Button
            accent
            className='dialog-cancel modal-btn'
            onClick={() => onConfirmModalClose(createCE)}
          >
          Cancel
          </Button>
          <Button
            accent
            className='dialog-ok modal-btn'
            onClick={() => {
              onModalSubmit();
            }}
          >
          Ok
          </Button>
        </DialogFooter>
      </Dialog>
    </DialogStyled>
  );
};

export const DialogStyled = styled.div`
  p{
    &.read{
      margin:0;
      margin:24px;
    }
  }
  .confirmation__dialog{
    .mdc-dialog__surface{
      width:300px
    }
  }
`;
