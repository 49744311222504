import React, { Component } from 'react';
import * as appConstants from '../../config';
import * as httpUtils from '../../../utils/httpUtils';
import { LinearProgress, Button, Grid, Cell } from 'react-mdc-web';
import { stockJournalDetailConfig, StockJournalDetailTableBody } from './StockJournalDetailConfig';
import moment from 'moment';
import TableHeader from '../../../components/TableHeader/TableHeader';
import history from '../../../utils/history';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { STOCK_JOURNAL_BASE } from '../../../data/enums/Route';

//invoice id is present in the list.
class StockJournalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      distributorDetails: {
        title: 'Amazon Distributors Pvt. Ltd.',
        address: 'Jawlakhel, Lalitpur',
        phone: '01-4256783',
        panNo: '4256783',
      },
      date: '',
      skuList: [],
      sorting: {
        label: '',
        order: 2,
      },
      headerTitleToView: stockJournalDetailConfig.headerTitleToView,
      stockJournalId: this.props.match.params.id,
    };
  }

  componentDidMount() {
    this.getDetail();
  }

  handleCancelClick = () => {
    history.push(`/${STOCK_JOURNAL_BASE}`);
  };

  getDetail = () => {
    const { stockJournalId } = this.state;
    this.setState({ loading: true });

    httpUtils.get(appConstants.baseUrl + `stock-journals/detail?id=${stockJournalId}`).then(response => {
      this.setState({ loading: false });
      if ((response.status == '200') & response.success) {
        const stockJournalDetail = response.data;
        const skuList = stockJournalDetail.details;
        const date = moment(stockJournalDetail.date).format('DD MMM YYYY');
        this.setState({ skuList: skuList, date: date });
      }
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.skuList;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';
    return orderBy(this.state.skuList, labelName, sortingName);
  };

  render() {
    const { loading, date, sorting, stockJournalId } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    return (
      <>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={`stock-journal-detail pad-b-24${loading ? 'clickable-false' : ''}`}>
          <div className="card-header-bar">
            <h2>Stock Journal: {stockJournalId}</h2>
            <div className="header-menu">
              <div className="header-right">
                <span>{moment(date).format('ll')}</span>
              </div>
            </div>
          </div>
          <div className="card-body" ref="tableReference">
            <div ref="fixedTableBody" className="fixed-table-wrapper">
              <table>
                <TableHeader
                  headerDetails={stockJournalDetailConfig.headerDetails}
                  filterHeaderLabel={false}
                  handleSorting={this.handleTableSorting}
                />
                {sortedDataSet.map((data, key) => (
                  <tbody>
                    <StockJournalDetailTableBody data={data} index={key} />
                  </tbody>
                ))}
              </table>
            </div>
          </div>
          <div className="newinvoice-btn-wrapper">
            <Grid>
              <Cell col={8}></Cell>
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>
              </Cell>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

StockJournalDetails.contextTypes = {
  router: PropTypes.object,
};
export default StockJournalDetails;
