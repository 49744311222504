import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import * as appConstants from '../../../config';
import {
  ACTION_MESSAGE,
  balanceType,
  ledgersConfig,
  LedgersTableBody,
  PAN_NO_VALID_DIGIT,
  validationCondition,
} from './LedgersConfig';
import * as httpUtils from '../../../../utils/httpUtils';
import Pagination from '../../../../components/Pagination/index';
import TableHeader from '../../../../components/TableHeader/index';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Textfield,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Grid,
  Cell,
  Button,
  Snackbar,
} from 'react-mdc-web';
import _ from 'lodash';
import * as appConfig from '../../../config';
import { EVENT_OPERATION } from '../../../../utils/enum';
import { clone } from '../../../../utils/arrayProcessor';
import BillingSelect from '../../../../components/Select/BillingSelect';
import { getFixTableMeasurements } from '../../../../utils/tableMetrics';
import BillingTextField from '../../../../components/TextField/BillingTextField';

// const businessUnitID = 1;

class Ledgers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showSearchBox: false,
      dataSet: [],
      totalData: 1,
      dateClickCount: 0,
      createModalOpen: false,
      editModalOpen: false,
      uploadModalOpen: false,
      businessUnitId: '',
      searchValue: '',
      createLedger: {
        file: null,
      },
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
      },
      showMessage: false,
      // errorMessage:[]
      buList: [],
      periodList: [],
      success: false,
      message: '',
      customer: {
        name: '',
        address: '',
        city: '',
        state: '',
        panNo: '',
        country: '',
        creditDay: 0,
        phoneNumber: '',
        creditLimit: 0,
        openingBalance: 0,
        openingBalanceType: balanceType[1].value,
      },
      invalidPAN: false,
      formEmptyField: false,
      formValidateFieldArray: ['name', 'panNo'],
    };
  }

  /** adjust table width and height according to screen **/
  componentDidMount() {
    this.getBUList();
    this.getPeriodList();
    this.getTableDataForGrid();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    let table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
  }

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    const type = '';
    this.setState({ loading: true });
    const { queryParameters } = this.state;
    httpUtils
      .get(
        appConstants.baseUrl +
          `ledger/customer-list?page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}&type=${type}`,
      )
      .then(response => {
        this.setState({ loading: false });
        if (response.status == 200 && response.success) {
          let responseDataList = response.data;
          self.setState({ dataSet: response.data, totalData: response.total, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  // get the period list.
  getPeriodList = () => {
    const self = this;
    this.setState({ loading: true });
    httpUtils
      .get(appConstants.baseUrl + 'period')
      .then(response => {
        if (response.status === 200 && response.success) {
          self.setState({ loading: false, periodList: response.data.list });
        }
      })
      .catch(error => {
        console.error(error);
        self.setState({ loading: false });
      });
  };

  // get BU list
  getBUList = () => {
    const self = this;
    httpUtils
      .get(appConfig.baseUrl + 'upload/business-company')
      .then(response => {
        if (response.success) {
          self.setState({ buList: response.data.list });
        }
      })
      .catch(error => {
        console.error(error);
        self.setState({ loading: false });
      });
  };

  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getTableDataForGrid());
  };

  onChange = (field, value, all = {}) => {
    const { customer } = this.state;
    customer[field] = value;
    if (field === 'panNo') customer[field] = value === 0 ? '' : value;
    this.setState({ customer, invalidPAN: false });
  };

  handleDateChange = date => {
    const { customer } = this.state;
    customer.fiscalYear = date.toDate();
    this.setState({ customer });
  };

  /** on Date Range Change */
  handleDateRangeChange = (start, end) => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  handleFabButtonClick = () => {
    this.setState({ createModalOpen: true });
  };

  closeModal = () => {
    // set false to opening Modal
    this.setState(
      {
        createModalOpen: false,
        uploadModalOpen: false,
        editModalOpen: false,
        invalidPAN: false,
        formEmptyField: false,
      },
      () => this.resetCustomer(),
    );
  };

  // handle the file upload.
  handleFileUpload = event => {
    event.preventDefault();
    const { createLedger } = this.state;
    createLedger['file'] = event.target.files[0];
    this.setState({ createLedger: createLedger });
  };

  // create the sku
  uploadLedger = () => {
    const file = this.state.createLedger.file;
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append('file', file);
    this.setState({ loading: true, createModalOpen: false });
    httpUtils
      .multipartPost(appConfig.baseUrl + 'ledger/upload', formData)
      .then(response => {
        this.setState({ loading: false });
        if (response.status == 200 && response.success) {
          this.ledgerFileInput.value = null;
          this.setState({
            showMessage: true,
            uploadModalOpen: false,
            success: true,
            message: ACTION_MESSAGE.SUCCESS.UPLOAD,
          });
        }
      })
      .catch(error => {
        this.ledgerFileInput.value = null;
        this.setState({ showMessage: true, loading: false, success: false, message: ACTION_MESSAGE.ERROR.UPLOAD });
        // this.setState({showMessage: true, loading:false, errorMessage:error.data.error.details})
      });
  };

  mapCamelCaseToSnakeCase = (item, type) => {
    const customerObj = {
      name: item.name,
      address: item.address,
      city: item.city,
      state: item.state,
      country: item.country,
      phone_number: item.phoneNumber,
      pan_no: item.panNo,
      credit_limit: item.creditLimit,
      credit_day: item.creditDay,
      opening_balance: item.openingBalance || 0,
      opening_balance_type: item.openingBalanceType || balanceType[1].value,
    };
    if (type === EVENT_OPERATION.UPDATE) customerObj['ledger_id'] = item.customerId;

    return customerObj;
  };

  mapCustomers = item => ({
    name: item.name || '',
    city: item.city || '',
    state: item.state || '',
    panNo: item.panNo || '',
    address: item.address || '',
    country: item.country || '',
    creditDay: item.creditDay || 0,
    creditLimit: item.creditLimit || 0,
    phoneNumber: item.phoneNumber || '',
    openingBalance: item.openingBalance || 0,
    openingBalanceType: item.openingBalanceType || balanceType[1].value,
  });

  createLedger = () => {
    const { customer, totalData, queryParameters, dataSet } = this.state;
    const modifiedCustomer = this.mapCamelCaseToSnakeCase(customer);

    httpUtils
      .post(appConfig.baseUrl + 'ledger/saveWithOB', modifiedCustomer)
      .then(response => {
        this.setState({ loading: false, createModalOpen: false });
        if (response.success) {
          if (totalData < queryParameters.pagination.limit) dataSet.push(response.data);
          this.setState(
            {
              showMessage: true,
              success: true,
              message: ACTION_MESSAGE.SUCCESS.CREATE,
              dataSet,
              createModalOpen: false,
            },
            () => this.resetCustomer(),
          );
        }
      })
      .catch(error => {
        this.setState({ loading: false, showMessage: true, success: false, message: ACTION_MESSAGE.ERROR.CREATE });
      });
  };

  handleEditIconClick = item => {
    // const customer = this.mapCustomers(item);
    /** in assumption that customer item consists of all the required field */
    this.setState({ customer: clone(item), editModalOpen: true });
  };

  editLedger = () => {
    const { customer, dataSet } = this.state;
    const modifiedCustomer = this.mapCamelCaseToSnakeCase(customer, EVENT_OPERATION.UPDATE);

    httpUtils
      .post(appConfig.baseUrl + 'ledger/edit', modifiedCustomer)
      .then(response => {
        this.setState({ loading: false, createModalOpen: false });
        if (response.success) {
          const index = dataSet.findIndex(item => item.customerId === modifiedCustomer.ledger_id);
          dataSet[index] = response.data;
          this.setState(
            { dataSet, showMessage: true, success: true, message: ACTION_MESSAGE.SUCCESS.UPDATE, editModalOpen: false },
            () => this.resetCustomer(),
          );
        }
      })
      .catch(error => {
        this.setState({ loading: false, showMessage: true, success: false, message: ACTION_MESSAGE.ERROR.UPDATE });
      });
  };

  closeMessageWrapper = status => {
    this.setState({ showMessage: status, errorMessage: [] });
  };

  handleOkClick = () => {
    const { createModalOpen, uploadModalOpen, customer, formValidateFieldArray } = this.state;
    if (uploadModalOpen) this.uploadLedger();
    else {
      const valid = this.validateForm(customer, formValidateFieldArray, 'formEmptyField', false);
      if (valid) {
        if (createModalOpen) this.createLedger();
        else this.editLedger();
      }
    }
  };

  handleDownloadClick = () => {
    let type = 'CSV';
    const self = this;
    const schema = 'https://';
    const locationUrl = window.location.hostname;
    // const t = 'ap.billing.rosia.one';
    httpUtils
      .get(appConstants.baseUrl + `download/ledger?type=${type}`)
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ loading: false });
          const url = response.data.url;
          const newWin = window.open(schema + locationUrl + url);
          if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
            alert('Please enable pop for this site');
          }
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  onSearchInputChange = value => {
    const self = this;
    const searchText = value;
    const { queryParameters } = this.state;
    httpUtils
      .get(appConstants.baseUrl + `ledger/customer-list?search_text=${searchText}`)
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data, totalData: response.total, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  validateForm = (formObject, config, flag, zeroFlag) => {
    const data = Object.keys(formObject).find(k => {
      let returnValue = '';
      if (config.indexOf(k) > -1) {
        let value = formObject[k];
        const valid = this.checkIfSatisfiesCondition(k, value);
        if (!valid) {
          this.setState({ [flag]: true });

          return (returnValue = true);
        }
      }

      return returnValue === true;
    });

    return !data;
  };

  checkIfSatisfiesCondition = (item, value) => {
    switch (item) {
      case 'panNo':
        if (value.toString().length > 0) {
          if (value.toString().length === PAN_NO_VALID_DIGIT) return true;
          this.setState({ invalidPAN: true });

          return false;
        }

        return true;
      case 'name':
        if (value) return true;

        return false;
      default:
        return true;
    }
  };

  inputSearch = value => {
    const searchValue = value;
    this.setState({ searchValue: searchValue }, () => {
      this.changeSearch(this.state.searchValue);
    });
  };

  clearInputText = () => {
    const value = '';
    const { showSearchBox } = this.state;
    this.setState({ searchValue: '', showSearchBox: !showSearchBox }, () => {
      this.onSearchInputChange(value);
    });
  };

  changeSearch = _.debounce(term => {
    this.onSearchInputChange(term);
  }, 700);

  resetCustomer = () => {
    const customer = this.mapCustomers({});
    this.setState({ customer });
  };

  render() {
    const {
      buList,
      loading,
      sorting,
      dataSet,
      totalData,
      customer,
      periodList,
      showMessage,
      createLedger,
      errorMessage,
      showSearchBox,
      searchValue,
      editModalOpen,
      queryParameters,
      uploadModalOpen,
      createModalOpen,
    } = this.state;

    // this.state.showMessage // showMessage: true
    // this.state.succcess: // success: true, or false,
    // this.state.message: // message
    // callBack: this.setState({showMessage: false})

    // crud: {
    //  type:
    //  display: true/false,
    //  message: message, // snackMessage
    //
    //
    //
    // }

    return (
      <div>
        <div>
          {loading && (
            <div className="linear-progress-wrapper temp-progress-wrapper">
              <LinearProgress accent indeterminate />
            </div>
          )}
          <div className={loading ? 'clickable-false' : ''}>
            <div className="message-snackbar">
              {
                <Snackbar
                  action="RETRY"
                  className={this.state.success ? 'success-message' : ''}
                  onClick={e => {
                    this.handleFabButtonClick();
                  }}
                  timeout={this.state.success ? 2500 : 4000}
                  onTimeout={() => {
                    this.setState({ showMessage: false });
                  }}
                  open={showMessage}
                >
                  {this.state.message}
                </Snackbar>
              }
            </div>
            <div className="card-header-bar" ref="tableReference">
              <h2>Customer Ledgers</h2>
              <div className="header-menu">
                {/* <div className="header-menu-left ">
                       <span className="search-icon-wrapper" onClick={() => this.setState({
                         showSearchBox: !showSearchBox
                       })}>
                          <Icon name="search" className="search-icon material-icons"/>
                       </span>
                  <span className={`top-search-input-wrapper ${showSearchBox ? 'input-active' : 'input-inactive' }`}>
                                {showSearchBox &&
                                <Textfield
                                  value={this.state.searchValue}
                                  onChange={(e) => {this.inputSearch(e.target.value)}}>
                                </Textfield>}
                    {
                      searchValue &&  <Icon name="close" className="cross-icon material-icons" onClick = {this.clearInputText}/>
                    }
                      </span>
                </div>*/}
                <div className="header-menu-right">
                  <span
                    className=""
                    onClick={() => {
                      this.setState({ uploadModalOpen: true });
                    }}
                  >
                    <Icon name="file_upload" />
                  </span>
                  <span className="" onClick={() => this.handleDownloadClick()}>
                    <Icon name="get_app" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div ref="fixedTableBody" className="fixed-table-wrapper">
            <table>
              <TableHeader
                headerDetails={ledgersConfig.headerDetails}
                filterHeaderLabel={false}
                handleSorting={this.handleTableSorting}
              />

              {dataSet.map((data, key) => (
                <tbody>
                  <LedgersTableBody data={data} onEditIconClick={this.handleEditIconClick} index={key} />
                </tbody>
              ))}
            </table>
          </div>

          {
            <div ref="fixedTableFooter">
              <Pagination
                pageSize={queryParameters.pagination.limit}
                currentPage={queryParameters.pagination.page}
                orientation="top"
                totalItems={totalData}
                onPageSelect={this.onPageSelect}
              />
            </div>
          }
        </div>
        {/* fab button and dialogs */}
        <div className="create-fab-button">
          <Fab onClick={() => this.handleFabButtonClick()}>
            <Icon name="add" />
          </Fab>
        </div>
        <Dialog
          open={createModalOpen || editModalOpen || uploadModalOpen}
          onClose={() => {
            this.closeModal();
          }}
        >
          <DialogHeader>
            {createModalOpen && <DialogTitle>Create Ledger</DialogTitle>}
            {editModalOpen && <DialogTitle>Edit Ledger</DialogTitle>}
            {uploadModalOpen && <DialogTitle>Upload Ledgers</DialogTitle>}
          </DialogHeader>
          <DialogBody>
            <div className="dialog-upperpart">
              {uploadModalOpen && (
                <Grid className="grid-padding">
                  <Cell col={12}>
                    <input
                      type="file"
                      name="resume"
                      accept=".csv, .xls, xlsx"
                      className="input-file-upload"
                      ref={ref => (this.ledgerFileInput = ref)}
                      onChange={e => this.handleFileUpload(e)}
                    />
                  </Cell>
                </Grid>
              )}
              {(createModalOpen || editModalOpen) && (
                <div>
                  <Grid className="grid-padding">
                    <Cell col={5} tablet={8}>
                      <BillingTextField
                        value={customer.name}
                        handleChange={this.onChange}
                        floatingLabel="Name"
                        param="name"
                        required={true}
                        className="right-side-gap"
                        emptyField={this.state.formEmptyField}
                      />
                    </Cell>
                    <Cell col={3} tablet={8}>
                      <BillingTextField
                        value={customer.panNo}
                        handleChange={this.onChange}
                        floatingLabel="PAN Number"
                        param="panNo"
                        required={false}
                        errorMessage="It should contain 10 digits"
                        rule="isInt"
                        type="number"
                        emptyField={this.state.formEmptyField}
                        invalidError={this.state.invalidPAN}
                      />
                    </Cell>
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={customer.address}
                        handleChange={this.onChange}
                        floatingLabel="Address"
                        param="address"
                        required={false}
                        className="right-side-gap"
                      />
                    </Cell>
                  </Grid>
                  <Grid className="grid-padding">
                    <Cell col={3} tablet={8} className="input-select">
                      <label>Opening Balance Type</label>
                      <BillingSelect
                        name="form-field-name"
                        param="openingBalanceType"
                        clearable={false}
                        value={customer.openingBalanceType}
                        valueKey="value"
                        labelKey="title"
                        options={balanceType}
                        handleChange={this.onChange}
                        required={false}
                        disabled={editModalOpen}
                      />
                    </Cell>
                    <Cell col={3} tablet={8}>
                      <BillingTextField
                        value={customer.openingBalance}
                        handleChange={this.onChange}
                        floatingLabel="Opening Balance"
                        param="openingBalance"
                        required={false}
                        disabled={editModalOpen}
                        className="right-side-gap"
                      />
                    </Cell>
                    <Cell col={3} tablet={8}>
                      <BillingTextField
                        value={customer.city}
                        handleChange={this.onChange}
                        floatingLabel="City"
                        param="city"
                        required={false}
                        className="right-side-gap"
                      />
                    </Cell>
                    <Cell col={3} tablet={8}>
                      <BillingTextField
                        value={customer.phoneNumber}
                        handleChange={this.onChange}
                        floatingLabel="Phone Number"
                        param="phoneNumber"
                        required={false}
                        className="right-side-gap"
                      />
                    </Cell>
                  </Grid>
                  <Grid className="grid-padding">
                    <Cell col={5} tablet={8}>
                      <BillingTextField
                        value={customer.creditLimit}
                        handleChange={this.onChange}
                        floatingLabel="Credit Limit"
                        param="creditLimit"
                        type="number"
                        required={false}
                      />
                    </Cell>
                    <Cell col={5} tablet={8}>
                      <BillingTextField
                        value={customer.creditDay}
                        handleChange={this.onChange}
                        floatingLabel="Credit Day"
                        param="creditDay"
                        type="number"
                        rule="isInt"
                        required={false}
                      />
                    </Cell>
                  </Grid>
                </div>
              )}
            </div>
          </DialogBody>
          <DialogFooter>
            <Button accent className="dialog-cancel modal-btn" onClick={() => this.closeModal()}>
              Cancel
            </Button>
            <Button
              accent
              className={
                uploadModalOpen
                  ? createLedger.file
                    ? 'dialog-ok modal-btn'
                    : 'dialog-ok modal-btn btn-disabled'
                  : 'dialog-ok modal-btn'
              }
              onClick={() => {
                this.handleOkClick();
              }}
            >
              {uploadModalOpen ? 'Upload' : 'Save'}
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

Ledgers.contextTypes = {
  router: PropTypes.object,
};

export default Ledgers;
