import React, { Component } from 'react';
import withBaseState from '../../common/withBaseState';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import tableConfig from './config';
import history from '../../../utils/history';
import { PROMOTION_CREATE, PROMOTION_DETAILS } from '../../../data/enums/Route';
import PageView from '../../common/pagination/PageView';
import { BillingSnackBar } from '../../../components/BillingMDC';
import PromotionStyled from './PromotionStyled';
import { promotionApi, checkIfApiCallSuccess } from '../../common/base.api';
import { PROMOTION_CONFIG } from '../../common/domain.config';

class DebitNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        filter: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
      },
      data: {
        list: [],
        total: 0,
      },
    };
    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { queryParameters } = this.state;
    promotionApi.getPromotionList(queryParameters).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const { data } = this.state;
        data.list = response.data.list;
        data.total = response.data.total;
        this.setState({ data });
      }
    });
  };

  handleFabButtonClick = () => {
    history.push(`/${PROMOTION_CREATE}`);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  handleTableBodyClick = id => {
    history.push(`/${PROMOTION_DETAILS}/${id}`);
  };

  render() {
    const { display, queryParameters, data, snack } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <PromotionStyled>
        <div className="debit-note">
          <PageView
            domainConfig={PROMOTION_CONFIG}
            headerConfig={{
              search: true,
              date: true,
              upload: false,
              download: false,
              filter: true,
              create: true,
            }}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            resetFilter={this.basePaginationService.resetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.basePaginationService.handleDownloadClick}
            display={display}
            data={data}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.handleTableBodyClick}
            onPageSelect={this.basePaginationService.onPageSelect}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            createHandler={this.handleFabButtonClick}
          />

          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </div>
      </PromotionStyled>
    );
  }
}

const DebitNoteWithState = withBaseState(DebitNote);

export default DebitNoteWithState;
