import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import LinearProgress from 'react-mdc-web/lib/LinearProgress';
import '../../../styles/printStyle.css';
import * as appConfig from '../../config';
import '../../../styles/printDetailStyle.css';
import * as appConstants from '../../config';
import history from '../../../utils/history';
import { has } from '../../../utils/hasOwnProperty';
import { clone } from '../../../utils/arrayProcessor';
import * as httpUtils from '../../../utils/httpUtils';
import * as filterUtil from '../../../utils/filterUtil';
import { TITLE, MESSAGE, PRINT_COPY } from './config';
import PrintBody from '../../../components/PrintComponent/PrintBody';
import { SALES_INVOICE_BASE } from '../../../data/enums/Route';
import numberToWords from '../../../utils/numberToTextConverter';

let dataListLength = 0;

class InvoicePrintDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      print: true,
      loading: false,
      date: '',
      miti: '',
      entered_by: '',
      salesInvoiceId: '',
      firstCopyTaxInvoice: '',
      company: {
        title: '',
        address: '',
        phone: '',
        panNumber: '',
      },
      userDetail: {
        id: '',
        title: '',
      },
      paymentDetail: {
        status: false,
        mode: '',
      },
      billSummary: {
        remarks: '',
        grossValue: 0,
        totalInWords: '',
        taxableAmount: 0,
      },
      printDetails: {
        transaction: 'SALES',
        invoiceNumber: '',
        printedBy: props.params.userId,
      },
      dateTime: {
        date: filterUtil.getCurrentDay(),
        time: filterUtil.getCurrentTime(),
      },
      invoiceDetail: { title: 'Invoiced By', value: '' },
      skuList: [],
      dataList: [],
      printInfo: [],
      billTotalList: [
        { title: 'VAT', value: 0 },
        { title: 'Sub Total', value: 0 },
        { title: 'Bill Discount', value: 0 },
        { title: 'Trade Discount', value: 0 },
        { title: 'Taxable Amount', value: 0 },
      ],
      dataMiscellaneousList: [
        { title: 'PAN No', value: '' },
        { title: 'Address', value: '' },
        { title: 'Invoice Id', value: '' },
        { title: 'Customer Name', value: '' },
      ],
    };
  }

  componentDidMount() {
    let root = document.getElementsByTagName('html')[0];
    root.setAttribute('class', 'printDetailClass');
    const id = this.props.params.id.replace(/%2F/g, '/');
    const { userDetail } = this.state;
    userDetail.id = this.props.params.userId;
    this.setState(
      {
        salesInvoiceId: id,
        firstCopyTaxInvoice: JSON.parse(this.props.params.firstCopy),
        userDetail: userDetail,
        loading: true,
      },
      () => {
        setTimeout(
          function () {
            this.getDetail();
          }.bind(this),
          800,
        );
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('contextmenu', this.handleMouseClick);
        window.onafterprint = this.handleAfterPrint;
      },
    );
  }

  componentWillUnmount() {
    let root = document.getElementsByTagName('html')[0];
    root.classList.remove('printDetailClass');
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  groupPrintDataset() {
    const { skuList } = this.state;
    const withoutFooterLimit = 36;
    const withFooterLimit = 26;
    let arrayList,
      tempWholeDataList = [];
    let dataList = [];
    let count = 0;
    arrayList = clone(skuList);
    const chunkAppropriateSize = arrayList.length >= withoutFooterLimit ? withoutFooterLimit : withFooterLimit;
    let chunk = chunkAppropriateSize;
    let indexChunk = chunkAppropriateSize;

    arrayList.forEach((item, index) => {
      item['index'] = index;
      arrayList[index] = item;
    });

    for (let index = 0, j = arrayList.length; index < j; index = indexChunk) {
      count++;
      let chunkArray = [];
      chunkArray = arrayList.slice(index, index + chunk);
      tempWholeDataList = tempWholeDataList.concat(chunkArray);
      chunk = arrayList.length - tempWholeDataList.length >= withoutFooterLimit ? withoutFooterLimit : withFooterLimit;
      if (arrayList.length - tempWholeDataList.length === 0) {
        chunkArray['footer'] = true;
        chunkArray['page'] = count;
      } else {
        chunkArray['footer'] = false;
        chunkArray['page'] = count;
      }

      indexChunk = tempWholeDataList.length;
      dataList.push(chunkArray);
    }
    dataListLength = dataList.length;
    this.setState({ dataList: dataList }, () => {
      setTimeout(() => this.printInvoice(), 400);
    });
  }

  mapPrintInfo = () => {
    if (!this.state.firstCopyTaxInvoice) {
      //this.setState({printInfo: this.props.printInfo})
      //todo get print info from detail api
    } else {
      const date = this.state.dateTime.date;
      const time = this.state.dateTime.time;
      const user = this.state.userDetail.title;
      const printInfo = [
        { title: 'Printed On', value: date },
        { title: 'Printed Time', value: time },
        { title: 'Printed By', value: user },
      ];
      this.setState({ printInfo: printInfo });
    }
  };

  handleCancelClick = () => {
    history.push(`/${SALES_INVOICE_BASE}`);
  };
  printInvoice = () => {
    window.print();
  };

  handleAfterPrint = () => {
    const { printDetails } = this.state;
    httpUtils
      .post(appConfig.baseUrl + 'print', printDetails)
      .then(response => {
        if (response.success) {
          window.close();
        }
      })
      .catch(error => {
        {
          console.log('error', error);
        }
      });
  };

  // fetch the detail from the server.
  getDetail = () => {
    this.setState({ loading: true });
    httpUtils
      .get(
        appConstants.baseUrl +
          `sales-invoice/detail?invoice_number=${this.state.salesInvoiceId}&user_id=${this.state.userDetail.id}`,
      )
      .then(response => {
        this.setState({ loading: false });
        if ((response.status == '200') & response.success) {
          let entered_by = '';
          const { userDetail, billSummary, printDetails, invoiceDetail, paymentDetail } = this.state;
          const { salesInvoice = {} } = response.data;
          const { distributorDetail = {} } = response.data;
          const taxableAmount = this.getTaxableAmount(
            salesInvoice.amount || 0,
            salesInvoice.promotionDiscount || 0,
            salesInvoice.tradeDiscount || 0,
            salesInvoice.billDiscount || 0 +
            salesInvoice.excise || 0
          );
          const billTotalList = [
            { title: 'Sub Total', value: Number(Number(response.data.subTotal).toFixed(2)) },
            { title: 'Bill Discount', value: salesInvoice.billDiscount || 0 },
            { title: 'Trade Discount', value: salesInvoice.tradeDiscount || 0 },
            JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise ? { title: 'Excise Duty Amount', value: salesInvoice.excise }:{},
            { title: 'Taxable Amount', value: taxableAmount },
            { title: 'VAT', value: salesInvoice.vat },
          ];
          const dataMiscellaneousList = [
            { title: 'Invoice Id', value: salesInvoice.invoiceNumber },
            { title: 'Customer Name', value: salesInvoice.customerDetail.name },
            { title: 'Address', value: salesInvoice.customerDetail.address },
            { title: 'PAN No', value: salesInvoice.customerDetail.panNo },
          ];
          const company = {
            title: distributorDetail.title,
            address: distributorDetail.address,
            phone: distributorDetail.phone,
            panNumber: distributorDetail.pan_number,
          };
          const printCount = has.call(salesInvoice.printDetail, 'printCount')
            ? parseInt(salesInvoice.printDetail.printCount) + 1
            : '';
          const printInfo = [
            {
              title: 'Printed On',
              value: has.call(salesInvoice.printDetail, 'localPrintDate')
                ? moment(salesInvoice.printDetail.localPrintDate).format('ll')
                : '',
            },
            {
              title: 'Printed Time',
              value: has.call(salesInvoice.printDetail, 'localPrintTime')
                ? salesInvoice.printDetail.localPrintTime
                : '',
            },
            {
              title: 'Printed By',
              value: has.call(salesInvoice.printDetail, 'printedBy')
                ? salesInvoice.printDetail.printedBy.name || entered_by
                : entered_by,
            },
            { title: 'Print Count', value: printCount },
          ];

          if (has.call(response.data, 'actionUserDetail')) {
            userDetail.title = response.data.actionUserDetail.name;
            entered_by = response.data.actionUserDetail.name;
          }

          if (salesInvoice.paymentMode === 'CASH') {
            paymentDetail.status = true;
            paymentDetail.mode = 'CASH';
          }

          billSummary.grossValue = salesInvoice.hasOwnProperty('netAmount')
            ? Number(Number(salesInvoice.netAmount).toFixed(0))
            : salesInvoice.netAmount;
          billSummary.remarks = salesInvoice.remarks;
          billSummary.totalInWords = numberToWords(billSummary.grossValu || 0);
          invoiceDetail.value = salesInvoice.userDetail.name;
          printDetails.invoiceNumber = salesInvoice.invoiceNumber;
          printDetails.printedBy = userDetail.id;

          this.setState(
            {
              company: company,
              printInfo,
              entered_by,
              userDetail,
              billSummary,
              printDetails,
              invoiceDetail,
              paymentDetail,
              billTotalList,
              dataMiscellaneousList,
              miti: salesInvoice.mitiTitle,
              skuList: salesInvoice.salesDetail,
              invoiceId: salesInvoice.invoiceNumber,
              date: moment(salesInvoice.date).format('DD MMM YYYY'),
            },
            () => {
              this.mapPrintInfo();
              this.groupPrintDataset();
            },
          );
        } else {
          alert('error in loading');
        }
      });
  };

  getTaxableAmount = (netValue = 0, promotionDiscount = 0, tradeDiscount = 0, cashDiscount = 0, excise = 0) => {
    return Number(Number(netValue - (promotionDiscount + tradeDiscount + cashDiscount -excise )).toFixed(2));
  };

  handleKeyDown = e => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.printInvoice();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  renderPrintContent = () => {
    const {
      date,
      miti,
      print,
      company,
      dataList,
      printInfo,
      billSummary,
      invoiceDetail,
      paymentDetail,
      billTotalList,
      firstCopyTaxInvoice,
      dataMiscellaneousList,
    } = this.state;

    let printBody = [];
    for (let count = 0; count < PRINT_COPY; count++) {
      const title = firstCopyTaxInvoice ? (count === 0 ? TITLE.ORIGINAL : TITLE.ORIGINAL2) : TITLE.COPY;
      printBody.push(
        <PrintBody
          date={date}
          miti={miti}
          print={print}
          title={title}
          company={company}
          dataList={dataList}
          printInfo={printInfo}
          billSummary={billSummary}
          message={MESSAGE.NEXT_PAGE}
          invoiceDetail={invoiceDetail}
          paymentDetail={paymentDetail}
          billTotalList={billTotalList}
          dataListLength={dataListLength}
          dataMiscellaneousList={dataMiscellaneousList}
        />,
      );
    }
    return printBody;
  };

  render() {
    const { loading } = this.state;

    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="display-block">{this.renderPrintContent()}</div>
        </div>
      </div>
    );
  }
}

InvoicePrintDetails.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
  };
};
const SalesInvoicePrintDetail = connect(mapStateToProps)(InvoicePrintDetails);

export default SalesInvoicePrintDetail;
