import { compose } from 'recompose';

import OutstandingReport from './OutstandingReport';

import apiInterceptor from '../../../api/interceptor';

import { outStandingReportApi, ledgerApi } from '../../common/base.api';

const composedOutstandingReport = compose(
  apiInterceptor({ ...outStandingReportApi, getCustomerList: ledgerApi.getCustomerList }),
)(OutstandingReport);

export default composedOutstandingReport;
