import { compose } from 'recompose';
import CreateCreditNote from './CreateCreditNote';
import apiInterceptor from '../../../api/interceptor';
import {
  buApi, ledgerApi, creditNoteAPi
} from '../../common/base.api';

const composedCreateCreditNote = compose(
  apiInterceptor({
    ...buApi,
    create: { ...creditNoteAPi },
    getList: { ledgerApi },
  }),
)(CreateCreditNote);

export default composedCreateCreditNote;
