import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dialog from './Dialog';
import tableConfig from './config';
import history from '../../../utils/history';
import { ACTION } from '../../../data/enums/enums';
import { setLedgerGroupDetails } from '../../../actions';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import AggregateLedgerReportStyled from './AggregateLedgerReportStyled';
import { AGGREGATE_LEDGER_REPORT_CONFIG } from '../../common/domain.config';
import { AGGREGATE_CUSTOMER_LEDGER_DETAILS } from '../../../data/enums/Route';

const propTypes = {
  onLedgerRowClick: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getCustomerList: PropTypes.func.isRequired,
  getAssignedList: PropTypes.func.isRequired,
  getLedgerGroupList: PropTypes.func.isRequired,
  deleteLedgerGroup: PropTypes.func.isRequired,
  createLedgerGroup: PropTypes.func.isRequired,
  domainConfig: PropTypes.shape({
    title: PropTypes.string,
    downloadList: PropTypes.instanceOf(Array),
    domain: PropTypes.string,
  }).isRequired,
};
const defaultProps = {
  serverResponseWaiting: false,
};

class AggregateLedgerReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      queryParameters: { ...queryService.queryParameters },
      customerList: [],
      filteredTitle: [],
      filteredCustomerList: [],
      data: {
        list: [],
        total: 0,
      },
      dialogType: ACTION.NULL,
      ledger: tableConfig.getDetail(),
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
    };
    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadCustomerList();
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);
  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getLedgerGroupList } = this.props;
    const { queryParameters } = this.state;
    getLedgerGroupList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.data;
      data.total = response.count ? response.count : response.data.length;
      this.setState({ data });
    });
  };

  loadCustomerList = () => {
    const { getCustomerList } = this.props;
    getCustomerList().then(response => {
      const customerWithTitlePan = response.list.map(customer => {
        return {
          ...customer,
          titlePan: customer.panNo === null || 0 ? customer.title : `${customer.title} (${customer.panNo})`,
        };
      });
      this.setState({ customerList: customerWithTitlePan }, () => this.loadFilteredCustomerList());
    });
  };
  loadFilteredCustomerList = () => {
    const { getAssignedList } = this.props;
    const { customerList } = this.state;
    getAssignedList().then(response => {
      const filteredCustomerLists = customerList.filter(customer => !response.includes(customer.customerId));
      this.setState({ filteredCustomerList: filteredCustomerLists });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  deleteLedger = () => {
    const { deleteLedgerGroup } = this.props;
    const { ledger } = this.state;
    const id = ledger.idLedgerGroup;
    deleteLedgerGroup({ id }).then(response => {
      this.loadFilteredCustomerList();
      const snack = snackService.generateSuccessMessage();
      this.setState({ snack }, () => this.loadTableData());
    });
  };

  createLedger = () => {
    const { ledger } = this.state;
    const { createLedgerGroup } = this.props;
    const apiTransformedData = tableConfig.getApiTransformedData(ledger);
    createLedgerGroup(apiTransformedData).then(response => {
      this.loadFilteredCustomerList();
      const snack = snackService.generateSuccessMessage();
      this.setState({ snack }, () => this.loadTableData());
    });
  };

  editLedger = () => {
    const { ledger } = this.state;
    const { updateLedgerGroup } = this.props;
    const apiTransformedData = tableConfig.getApiTransformedData(ledger, ACTION.UPDATE);
    updateLedgerGroup({
      id: ledger.idLedgerGroup,
      query: apiTransformedData,
    }).then(res => {
      this.loadFilteredCustomerList();
      const snack = snackService.generateUpdateMessage();
      this.setState({ snack }, () => {
        this.loadTableData();
      });
    });
  };

  handleSelectChange = (value, field) => {
    const { ledger } = this.state;
    ledger[field] = value;
    this.setState({ ledger });
  };

  handleInputChange = (field, value) => {
    const { ledger } = this.state;
    ledger[field] = value;
    this.setState({ ledger });
  };

  handleFabButtonClick = () => {
    const ledger = tableConfig.getDetail();
    this.setState({
      dialogType: ACTION.ADD_AGGREGATE,
      ledger,
    });
  };
  getLedgersForEditDropDown = ledgerIds => {
    const { customerList } = this.state;
    return customerList.filter(customer => (ledgerIds ? ledgerIds.includes(customer.customerId) : false));
  };
  editHandler = data => {
    const ledger = tableConfig.getDetail(data);
    this.setState({ dialogType: ACTION.UPDATE, ledger });
  };
  deleteHandler = data => {
    const ledger = tableConfig.getDetail(data);
    this.setState({ dialogType: ACTION.DELETE, ledger });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.setState({
      dialogType: ACTION.NULL,
      validation,
    });
  };

  handleModalSubmit = () => {
    const { ledger, dialogType } = this.state;
    const formValid = ledger.title && ledger.ledger_ids && ledger.ledger_ids.length > 0;
    this.setState({ validation: { flag: !formValid } });
    if (formValid) {
      this.handleModalClose();
      switch (dialogType) {
        case ACTION.ADD_AGGREGATE:
          this.createLedger();
          break;
        case ACTION.UPDATE:
          this.editLedger();
          break;
        case ACTION.DELETE:
          this.deleteLedger();
          break;
        default:
          break;
      }
    }
  };

  handleTableBodyClick = data => {
    const { queryParameters } = this.state;
    const { onLedgerRowClick } = this.props;

    const action = setLedgerGroupDetails({
      ledgerGroupDetails: { ...data },
      date: queryParameters.date,
    });
    onLedgerRowClick(action);
    history.push(`/${AGGREGATE_CUSTOMER_LEDGER_DETAILS}/${data.idLedgerGroup}`);
  };

  removeDuplicateArray = data => {
    return [...new Set(data)];
  };

  render() {
    const { queryParameters, data, display, ledger, validation, dialogType, filteredCustomerList } = this.state;
    const { serverResponseWaiting } = this.props;
    const ledgersForEditDropDown =
      dialogType === ACTION.UPDATE ? this.getLedgersForEditDropDown(ledger.ledger_ids) : [];
    const filteredLedgersWithEditCase = [...filteredCustomerList, ...ledgersForEditDropDown];

    return (
      <AggregateLedgerReportStyled>
        <PageView
          domainConfig={AGGREGATE_LEDGER_REPORT_CONFIG}
          headerConfig={{
            search: true,
            date: true,
            download: true,
            filter: true,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.basePaginationService.resetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleDownloadClick={this.basePaginationService.handleDownloadClick}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleTableBodyClick}
          onPageSelect={this.basePaginationService.onPageSelect}
          createHandler={this.handleFabButtonClick}
          editHandler={this.editHandler}
          deleteHandler={this.deleteHandler}
        />
        <Dialog
          ledger={ledger}
          validation={validation}
          actionType={dialogType}
          invlidPAN={false}
          formEmptyField={false}
          onInputChange={this.handleInputChange}
          onSelectChange={this.handleSelectChange}
          onModalClose={this.handleModalClose}
          onModalSubmit={this.handleModalSubmit}
          customerList={this.removeDuplicateArray(filteredLedgersWithEditCase)}
        />
      </AggregateLedgerReportStyled>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLedgerRowClick: action => dispatch(action),
  };
}
AggregateLedgerReport.protoTypes = propTypes;
AggregateLedgerReport.defaultProps = defaultProps;

const AggregateLedgerReportWithState = withBaseState(AggregateLedgerReport);

export default connect(null, mapDispatchToProps)(AggregateLedgerReportWithState);
