import React from 'react';
import moment from 'moment';
import {
  Drawer, Icon, Radio, RadioGroup
} from '../../../components/BillingMDC';
import DateRangePicker from '../../../components/DateRangePicker';
import DatePicker from '../../../components/DatePicker';
import DrawerFilterStyled from './DrawerFilterStyled';
import FilterAggregator from './FilterAggregator';
import PropTypes from 'prop-types';
import Switch from '../../../components/Switch';
import BillingTextField from '../../../components/TextField/BillingTextField';

const propTypes = {
  viewOptions: PropTypes.object,
  config: PropTypes.object,
  onInputChange: PropTypes.func,
  showInputOption: PropTypes.bool,
  inputData: PropTypes.string,
};

const defaultProps = {
  viewOptions: { list: [], handler: () => null, selectedValue: null },
  config: { flag: false },
  onInputChange: () => null,
  showInputOption: false,
  inputData: '',
};

const DrawerWithFilter = ({
  display,
  displayController,
  resetFilter,
  queryParameters,
  onDateRangeChange,
  onDateChange,
  handleFilterChange,
  children,
  disableStartDate,
  disableEndDate,
  viewOptions,
  config,
  handleFlagChange,
  showCollapsibleList,
  showInputOption,
  inputData,
  onInputChange,
  inputTitle,
  filterDisable,
}) => (
  <div dir='rtl'>
    <DrawerFilterStyled>
      <Drawer open={display} className='right-drawer' onClick={() => displayController('filter', false)}>
        <div className='filter-wrapper'>
          <h2 className='default-horizontal-padding-24'>
            <span>Filter</span>
            <span className='float-right'>
              <Icon name='replay' onClick={() => resetFilter()} />
              <Icon name='clear' onClick={() => displayController('filter', false)} />
            </span>
          </h2>
          <div className='divider' />

          {queryParameters.reference_date ? <div className='ref-date-label'>Reference / Cheque Date</div> : null}
          {queryParameters.date ? (
            <DateRangePicker
              startDate={moment(queryParameters.date.start)}
              endDate={moment(queryParameters.date.end)}
              onChange={onDateRangeChange}
              disableStartDate={disableStartDate}
              disableEndDate={disableEndDate}
              filterDisable={filterDisable}
            />
          ) : null}
          {!queryParameters.date && queryParameters.singleDate ? (
            <span>
              <DatePicker date={moment(queryParameters.singleDate)} onChange={onDateChange} />
            </span>
          ) : null}
          {!queryParameters.date && !queryParameters.singleDate && queryParameters.reference_date ? (
            <>
              <DateRangePicker
                startDate={moment(queryParameters.reference_date.start)}
                endDate={moment(queryParameters.reference_date.end)}
                onChange={onDateRangeChange}
                disableStartDate={disableStartDate}
                disableEndDate={disableEndDate}
                filterDisable={filterDisable}
              />
            </>
          ) : null}
        </div>
        {viewOptions && viewOptions.list.length > 0 ? (
          <div className='view-by-wrap'>
            <label htmlFor=''>{viewOptions.title || 'View By'}</label>
            <RadioGroup
              onChange={(e) => viewOptions.handler(e.target.value)}
              name='views'
              value={viewOptions.selectedValue}
            >
              {viewOptions.list.map((item) => (
                <Radio value={item.label} className={'active-radio'}>
                  {item.title}
                </Radio>
              ))}
            </RadioGroup>
          </div>
        ) : (
          <span />
        )}
        <>
          {showInputOption ? (
            <div className='input-by-wrap'>
              <label htmlFor=''>{inputTitle || ''}</label>
              <BillingTextField
                value={inputData}
                handleChange={onInputChange}
                param='inputData'
                type='number'
                className='billing-required right-side-gap'
              />
            </div>
          ) : null}
        </>
        {queryParameters.filter ? (
          <FilterAggregator
            filterValue={queryParameters.filter}
            handleFilterChange={handleFilterChange}
            showCollapsibleList={showCollapsibleList}
          />
        ) : (
          <span />
        )}
        {config.flag ? (
          Object.keys(queryParameters.flag).map((f) => (
            <span>
              <Switch
                onToggle={handleFlagChange}
                name={f}
                checked={queryParameters.flag[f].status}
                labelContent={queryParameters.flag[f].title}
              />
            </span>
          ))
        ) : (
          <span />
        )}
      </Drawer>
    </DrawerFilterStyled>
  </div>
);

DrawerWithFilter.propTypes = propTypes;

DrawerWithFilter.defaultProps = defaultProps;

export default DrawerWithFilter;
