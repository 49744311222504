const BILLING_PROGRAM = {
  REGISTER: 'Register',
  BUSINESS_COMPANY: 'BusinessCompany',
  CATALOG: 'Catalog',
  COMPANY: 'Company',
  DATABASE_LOG: 'DatabaseLog',
  DOWNLOAD: 'Download',
  FISCAL_YEAR: 'FiscalYear',
  JOURNAL_VOUCHER: 'JournalVoucher',
  LEDGER: 'Ledger',
  OPENING_BALANCE: 'OpeningBalance',
  RECEIPT: 'Receipt',
  PAYMENT: 'Payment',
  PRINT: 'Print',
  PURCHASE: 'Purchase',
  PRN: 'PurchaseReturn',
  SRN: 'SalesReturn',
  SI: 'SalesInvoice',
  ABI: 'AbbreviatedInvoice',
  SKU: 'SKU',
  STOCK_JOURNAL: 'StockJournal',
  STOCK_LEDGER: 'StockLedger',
  USER: 'User',
  AGENT: 'Agent',
  REPORT: 'Report',
  SVR: 'Report-SalesVAT',
  PVR: 'Report-PurchaseVAT',
  GRN: 'Purchase',
  OUTSTANDING_REPORT: 'Report-Outstanding',
  AGEING_REPORT: 'Report-Ageing',
  STOCK_AGEING: 'Stock-Aging-Report',
  SRN_VR: 'Report-SRN-VAT',
  PRV: 'Report-PRN-VAT',
  VAT_REPORT: 'Report-VAT',
  SMV: 'Report-SMV',
  ENTRY_LOG: 'Entry Log',
  VENDOR_CUSTOMER: 'Vendor-Customer',
  LEDGER_REPORT: 'Report-Ledger',
  AGGREGATE_CUSTOMER_LEDGER_BASE: 'Aggregated-Ledger-Reports',
  DAY_BOOK_SUMMARY: 'Daybook',
  ACCOUNT_GROUP: 'AccountGroup',
  TRIAL_BALANCE: 'TrialBalance',
  PROFIT_LOSS: 'ProfitLoss',
  BALANCE_SHEET: 'BalanceSheet',
  FISCAL_YEAR_MIGRATION: 'FiscalYearMigration',
  CONTRA_ENTRY: 'ContraEntry',
  SETTINGS: 'Settings',
  TAG: 'Tag',
  DEBIT_NOTE: 'DebitNote',
  CREDIT_NOTE: 'CreditNote',
  PICK_LIST: 'PickList',
  PROMOTION: 'Promotion',
  SALES_REPORT: 'Sales-Archive-Report',
  SRN_REPORT: 'Sales-Return-Archive-Report',
  GRN_REPORT: 'Purchase-Archive-Report',
  PRN_REPORT: 'Purchase-Return-Archive-Report',
  VAT_SUMMARY_REPORT: 'Vat-Summary-Report',
  DAMAGE_EXPIRY_VALUATION_REPORT: 'Damage-Expiry-Valuation-Report',
  AGENT_WISE_OUTSTANDING_REPORT: 'Agent-Wise-Outstanding-Report',
  LEDGER_WISE:'Ledger-Wise-Tag-Report',
  TAG_WISE:'Tag-Wise-Ledger-Report',
  NET_SALES_REPORT:'Net-Sales-Report'
};

export default BILLING_PROGRAM;
