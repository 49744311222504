import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../components/BillingMDC';
import {
  ACTION, PASSWORD_CRITERIA_MSG, PASSWORD_RULE
} from '../../data/enums/enums';
import BillingTextField from '../../components/TextField/BillingTextField';

const ChangePasswordDialog = ({ form, actionType, onModalClose, onModalSubmit, onInputChange, formEmptyField }) => {
  const check = form.newPassword.match(PASSWORD_RULE);
  const pwCriteriaStatus = check === null;
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
      className='user__dialog'
    >
      <DialogHeader>
        <DialogTitle>Change Password</DialogTitle>
      </DialogHeader>

      <DialogBody>
        <div className='dialog-upperpart'>
          <div>
            <Grid className='grid-padding'>
              <Cell col={12} tablet={12}>
                <BillingTextField
                  value={form.oldPassword}
                  handleChange={onInputChange}
                  floatingLabel='Old Password*'
                  param='oldPassword'
                  required
                  zeroError
                  type='password'
                  className='billing-required right-side-gap'
                  emptyField={formEmptyField}
                />
              </Cell>
              <Cell col={12} tablet={12} className='password'>
                <BillingTextField
                  value={form.newPassword}
                  type='password'
                  handleChange={onInputChange}
                  floatingLabel='New Password'
                  param='newPassword'
                  required
                  className='billing-required right-side-gap'
                  pattern={PASSWORD_RULE}
                  emptyField={formEmptyField}
                  errorMessage={
                    form.newPassword === '' ? ' Should not be empty' : pwCriteriaStatus ? PASSWORD_CRITERIA_MSG : ''
                  }
                  invalidError={formEmptyField}
                />
              </Cell>
              <Cell col={12} tablet={12}>
                <BillingTextField
                  value={form.repeatPassword}
                  handleChange={onInputChange}
                  floatingLabel='Confirm Password*'
                  param='repeatPassword'
                  required
                  zeroError
                  type='password'
                  className='billing-required right-side-gap'
                  emptyField={formEmptyField}
                  errorMessage={
                    form.repeatPassword === ''
                      ? 'Should not be empty'
                      : form.newPassword !== form.repeatPassword
                        ? 'Should be same as New Password'
                        : ''
                  }
                  invalidError={formEmptyField && form.newPassword !== form.repeatPassword}
                />
              </Cell>
            </Grid>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
          OK
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

ChangePasswordDialog.propTypes = {
  actionType: PropTypes.string.isRequired,
  formEmptyField: PropTypes.bool.isRequired,
  pwCriteriaStatus: PropTypes.bool.isRequired,
};
ChangePasswordDialog.defaultProps = {
  actionType: ACTION.NULL,
  formEmptyField: false,
  pwCriteriaStatus: false,
};

export default ChangePasswordDialog;
