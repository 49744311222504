import styled from 'styled-components';

const CreateBtnStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 22px 38px;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  span {
    color: rgba(0, 0, 0, 0.27);
    font-size: 13px;
    line-height: 24px;
  }
  .create-fab-button {
    button {
      position: absolute;
      right: 48px;
      top: 5px;
      margin-right: 0 !important;
    }
  }
  &.display-btn-none {
    display: none;
  }
`;
export default CreateBtnStyled;
