import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
  Checkbox,
} from '../../../components/BillingMDC';
import BillingSelect from '../../../components/Select/BillingSelect';
import SampleFileDownload from '../../common/dialog/SampleFileDownload';
import BillingTextField from '../../../components/TextField/BillingTextField';
import {
  EMPTY, INVALID, PAN_NO_ERROR,
} from '../../../data/enums/errorMessage';
import {
  BALANCE_TYPE, LEDGER_TYPE, ACTION, DOMAIN, LEDGERS, MOBILE_NO_VALID_DIGIT,SYSTEM_LEDGER_IDS
} from '../../../data/enums/enums';
import { MOBILE_NO_DIGITS_ERROR } from '../../../data/enums/errorMessage';

const getOptionList = (options,value,type,id,field) =>{
  const activeOptions =options?.filter(a=>a[field]===true);
  const check = activeOptions.map((a)=>a[id]).includes(value);
  const filteredOptions =options?.filter(a =>a[id] ===value)
  const mergedArr= [...activeOptions,...filteredOptions];
  const optionsList=type === ACTION.CREATE ? activeOptions : check ? activeOptions:mergedArr;
  return optionsList;
}

const LedgerDialog = (
  {
    ledger,
    actionType,
    onModalClose,
    onModalSubmit,
    onInputChange, // onChange
    invalidPAN,
    handleFileUpload,
    fileRef,
    validation,
    accountGroupList,
    panValidation,
    ledgerStatus,
    disableStatus,
    ledgerBackUp,
    areaList,
    agentList,
  },
) => {
  const checkaccountGroup=(ledger.accountGroup === LEDGERS.CUSTOMER.type || ledger.accountGroup === LEDGERS.VENDOR.type);
  const areaListFiltered = getOptionList(areaList,ledger?.areaId,actionType,'idAreas','active');
  const agentListFiltered = getOptionList(agentList,ledger?.salesPersonId,actionType,'idAgent','status');
  return (
    <VendorCustomerStyled>
      <Dialog
        open={actionType !== ACTION.NULL }
        onClose={() => {
          onModalClose();
        }}
      >
        <DialogHeader>
          <DialogTitle>{actionType === ACTION.READ ? 'Confirmation Dialog' : actionType}</DialogTitle>
        </DialogHeader>

        <DialogBody>
          <div className={`dialog-upperpart ${checkaccountGroup && 'overflow-active'}`}>
            {actionType === ACTION.READ && <p className='read'>Are you sure you want to progress from opening balance of  {ledgerBackUp?.openingBalance} to {ledger?.openingBalance} ?</p>}
            {actionType === ACTION.UPLOAD && (
              <Grid className='grid-padding upload'>
                <Cell col={12}>
                  <input
                    type='file'
                    name='resume'
                    accept='.csv, .xls, xlsx'
                    className='input-file-upload'
                    ref={(ref) => (fileRef = ref)}
                    onChange={(e) => handleFileUpload(e)}
                  />
                </Cell>
              </Grid>
            )}
            {actionType !== ACTION.UPLOAD && actionType !== ACTION.READ && (
              <>
                <div className='row_one row '>
                  <Grid className='grid-padding'>
                    <Cell col={5} tablet={8}>
                      <BillingTextField
                        value={ledger.title}
                        handleChange={onInputChange}
                        floatingLabel='Name'
                        param='title'
                        required={true}
                        className='right-side-gap'
                        emptyField={validation.flag}
                        disabled={actionType === ACTION.UPDATE && SYSTEM_LEDGER_IDS.includes(ledger.customerId)}
                      />
                    </Cell>

                    <Cell col={4} tablet={8} className='input-select'>
                      <label>Account Group</label>
                      <BillingSelect
                        name='form-field-name'
                        param='accountGroup'
                        clearable={false}
                        value={ledger.accountGroup}
                        valueKey='id_account_group'
                        labelKey='title'
                        options={accountGroupList}
                        handleChange={onInputChange}
                        required={true}
                        emptyField={validation.flag}
                        disabled={actionType === ACTION.UPDATE && SYSTEM_LEDGER_IDS.includes(ledger.customerId)}
                      />
                    </Cell>

                    <Cell col={3}>
                      <div className='common-checkbox'>
                        <label>Cash / Bank Account</label>
                      </div>
                      <Checkbox
                        checked={ledger.isCashBankLedger}
                        onChange={({ target: { checked } }) => {
                          onInputChange('isCashBankLedger', checked);
                        }}
                        disabled={actionType === ACTION.UPDATE && SYSTEM_LEDGER_IDS.includes(ledger.customerId)}
                      />
                      <label>Cash</label>
                    </Cell>
                  </Grid>
                </div>
                <div className='row_two row'>
                  <Grid className='grid-padding '>
                    <Cell col={4} tablet={8} className='input-select'>
                      <label>Opening Balance Type</label>
                      <BillingSelect
                        name='form-field-name'
                        param='openingBalanceType'
                        clearable={false}
                        value={ledger.openingBalanceType}
                        valueKey='value'
                        labelKey='title'
                        options={BALANCE_TYPE}
                        handleChange={onInputChange}
                        required={true}
                      />
                    </Cell>
                    <Cell col={4} tablet={8}>
                      <BillingTextField
                        value={ledger.openingBalance}
                        handleChange={onInputChange}
                        floatingLabel='Opening Balance'
                        param='openingBalance'
                        type='number'
                        zeroError={false}
                        className='right-side-gap'
                      />
                    </Cell>

                    <Cell col={4} tablet={8}>
                      <label>Status</label>
                      <BillingSelect
                        name='form-field-name'
                        param='status'
                        clearable={false}
                        value={ledger.status}
                        labelKey='name'
                        valueKey='status'
                        options={ledgerStatus}
                        handleChange={onInputChange}
                        disabled={(actionType === ACTION.UPDATE && disableStatus)}
                        floatingLabel={'Status'}
                      />
                    </Cell>
                  </Grid>
                </div>
                {checkaccountGroup && (
                  <div className='row_three row'>
                    <Grid className='grid-padding'>
                      <Cell col={4} tablet={8}>
                        <BillingTextField
                          value={ledger.city}
                          handleChange={onInputChange}
                          floatingLabel='City'
                          param='city'
                          required={false}
                          className='right-side-gap'
                        />
                      </Cell>
                      <Cell col={4} tablet={8}>
                        <BillingTextField
                          value={ledger.panNo}
                          handleChange={onInputChange}
                          floatingLabel='PAN Number'
                          param='panNo'
                          required={panValidation}
                          errorMessage={panValidation && ledger.panNo !== 0  ? PAN_NO_ERROR : ''}
                          rule='isInt'
                          type='number'
                          invalidError={checkaccountGroup ? panValidation : false}

                        />
                        </Cell>
                      <Cell col={4} tablet={8}>
                        <BillingTextField
                          value={ledger.address}
                          handleChange={onInputChange}
                          floatingLabel='Address'
                          param='address'
                          required={false}
                          className='right-side-gap'
                        />
                      </Cell>
                      <Cell col={4} tablet={8} className='input-select area'>
                      <label>Area</label>
                      <BillingSelect
                        name='form-field-name'
                        param='areaId'
                        clearable={false}
                        value={ledger?.areaId}
                        valueKey='idAreas'
                        labelKey='title'
                        clearable
                        options={areaListFiltered}
                        handleChange={onInputChange}
                      />
                    </Cell>
                    <Cell col={4} tablet={8} className='input-select area'>
                      <label>Sales Person</label>
                      <BillingSelect
                        name='form-field-name'
                        param='salesPersonId'
                        clearable={false}
                        value={ledger.salesPersonId}
                        valueKey='idAgent'
                        labelKey='name'
                        clearable
                        options={agentListFiltered}
                        handleChange={onInputChange}
                      />
                    </Cell>
                      <Cell col={4} tablet={8}>
                        <BillingTextField
                          value={ledger.phoneNumber}
                          handleChange={onInputChange}
                          floatingLabel='Phone Number'
                          param='phoneNumber'
                          type='number'
                          className='right-side-gap'
                          required={false}
                          emptyField={validation.flag}
                          errorMessage={
                            Number(ledger?.phoneNumber) !==0  && 
                            ledger?.phoneNumber?.toString()?.length !== MOBILE_NO_VALID_DIGIT
                              ? MOBILE_NO_DIGITS_ERROR
                              : ''
                          }
                          invalidError={ledger?.phoneNumber?.toString()?.length !== MOBILE_NO_VALID_DIGIT}
                        />
                      </Cell>
                      <Cell col={4} tablet={8}>
                        <BillingTextField
                          value={ledger.creditLimit}
                          handleChange={onInputChange}
                          floatingLabel='Credit Limit'
                          param='creditLimit'
                          type='number'
                          required={false}
                        />
                      </Cell>
                      <Cell col={4} tablet={8}>
                        <BillingTextField
                          value={ledger.creditDay}
                          handleChange={onInputChange}
                          floatingLabel='Credit Day'
                          param='creditDay'
                          type='number'
                          rule='isInt'
                          required={false}
                        />
                      </Cell>
                    </Grid>
                  </div>
                )}
              </>
            )}
          </div>
          {actionType === ACTION.UPLOAD && (<SampleFileDownload domain={DOMAIN.LEDGERS} />)}
        </DialogBody>
        <DialogFooter>
          <Button
            accent
            className='dialog-cancel modal-btn'
            onClick={() => onModalClose()}
          >
          Cancel
          </Button>
          <Button
            accent
            disabled={actionType === ACTION.UPLOAD && !ledger.file}
            className='dialog-ok modal-btn'
            onClick={() => {
              onModalSubmit();
            }}
          >
          Save
          </Button>
        </DialogFooter>
      </Dialog>
    </VendorCustomerStyled>
  );
};

export default LedgerDialog;

export const ConfirmationDialog = (
  { readModal,onConfirmModalClose,getConfirmation,ledgerBackUp,ledger },
) => {
  return(
    <VendorCustomerStyled>
      <Dialog
        open={readModal }
        onClose={() => {
          onConfirmModalClose();
        }}
        className='confirmation__dialog'
      >
        <DialogHeader>
          <DialogTitle>Confirmation</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <p className='read'>
          Are you sure you want to update opening balance from Rs. {ledgerBackUp?.openingBalance} to Rs. {ledger?.openingBalance} ?
          </p>
        </DialogBody>
        <DialogFooter>
          <Button
            accent
            className='dialog-cancel modal-btn'
            onClick={() => onConfirmModalClose()}
          >
          Cancel
          </Button>
          <Button
            accent
            className='dialog-ok modal-btn'
            onClick={() => {
              getConfirmation();
            }}
          >
          Confirm
          </Button>
        </DialogFooter>
      </Dialog>
    </VendorCustomerStyled>
  );
};

export const VendorCustomerStyled = styled.div`
  p{
    &.read{
      margin:0;
      margin:24px;
    }
  }
  .confirmation__dialog{
    .mdc-dialog__surface{
      width:300px
    }
  }
`;
