import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { getPercentage } from '../../../../utils/miscellaneous';
import { VAT_PERCENTAGE } from '../../../../data/enums/enums';
import numberToWords from '../../../../utils/numberToTextConverter';

const TITLE = {
  ORIGINAL: 'TAX INVOICE',
  ORIGINAL2: 'INVOICE',
  COPY: 'INVOICE (Copy Of Original)',
};
const TEMPORARY_TITLE = {
  ORIGINAL: 'ESTIMATE',
  ORIGINAL2: 'ESTIMATE',
  COPY: 'ESTIMATE',
};
const PRINT_COPY = 3;

const MESSAGE = { NEXT_PAGE: 'Continued on next page ...', };

const getTaxableAmount = (netValue = 0, promotionDiscount = 0, tradeDiscount = 0, cashDiscount = 0) => {
  return Number(Number(netValue - (promotionDiscount + tradeDiscount + cashDiscount)).toFixed(2));
};

const getBillTotalList = (data = {}) => {
  const billPer = getPercentage(data.billDiscount || 0, data.subTotal);
  const tradePer = getPercentage(data.tradeDiscount || 0, data.subTotal - data.billDiscount);

  const taxableAmount = getTaxableAmount(
    data.amount || 0,
    data.promotionDiscount || 0,
    data.tradeDiscount || 0,
    data.billDiscount || 0,
  );
  const billTotalList = [
    { title: 'Sub Total', value: data.subTotal ? Number(Number(data.subTotal).toFixed(2)) : 0 },
    { title: `Bill Discount${billPer ? ` (${billPer}%)` : ''}`, value: data.billDiscount || 0 },
    { title: `Trade Discount${tradePer ? ` (${tradePer}%)` : ''}`, value: data.tradeDiscount || 0 },
    { title: 'Taxable Amount', value: taxableAmount },
    { title: `VAT (${VAT_PERCENTAGE}%)`, value: data.vat || 0 },
  ];

  return billTotalList;
};

const getBillSummaryDetails = (data = {}) => {
  const billSummaryDetails = [
    { title: 'Invoice Id', value: data.invoiceNumber || '' },
    { title: 'Customer Name', value: data.customerDetail ? data.customerDetail.title : '' },
    { title: 'Address', value: data.customerDetail ? data.customerDetail.address : '' },
    { title: 'PAN No', value: data.customerDetail ? data.customerDetail.panNo : '' },
  ];

  return billSummaryDetails;
};

const getBillFooterSummary = (data = {}) => {
  const inWords = data.netAmount ? numberToWords(data.netAmount || 0) : '';
  const billFooterSummary = {
    remarks: data.remarks || '',
    grossValue: data.netAmount ? fixedFloatAndCommas(data.netAmount) : 0,
    totalInWords: inWords,
  };

  return billFooterSummary;
};

export {
  TITLE, MESSAGE, PRINT_COPY, TEMPORARY_TITLE, getBillTotalList, getBillFooterSummary, getBillSummaryDetails
};
