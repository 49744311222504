import PropTypes from 'prop-types';
import React from 'react';
import Dialog from './Dialog';
import { Icon } from 'react-mdc-web';
import { Button } from '../../components/BillingMDC';
import EvolveLogo from '../../styles/svg/Evolve.svg';
import withLoading from '../../common/withLoading';
import LoginStyled from './LoginStyled';
import BillingTextField from '../../components/TextField/BillingTextField';

const propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
};

const LoginView = ({ ...props }) => {
  const errorInLogin = true;

  const {
    data,
    onSubmit,
    errorMessage,
    onInputChange,
    onModalClose,
    dialogType,
    validationFlag,
    handleFabButtonClick,
    showHidePwFunc,
    showHidePw,
  } = props;
  const hideshowPw = showHidePw ? 'input ' : 'password';
  return (
    <LoginStyled>
      <div className='login-gray-background'>
        <div className='login-wrapper'>
          <div className='login-center-div'>
            <div className='login-logo'>
              <img src={EvolveLogo} alt='logo' />
            </div>
            <div className='login-title'>
              <h3>
                Distributor <strong>Billing System</strong>
              </h3>
            </div>
            <div className='login-body'>
              {errorMessage && (
                <div>
                  <span className='invalid-message'>{errorMessage.message}</span>
                </div>
              )}
              <div className='login-form'>
                <div>
                  <div className='input-wrapper'>
                    <BillingTextField
                      value={data.email}
                      handleChange={onInputChange}
                      floatingLabel=' Email Address'
                      placeholder='Email Address'
                      param='email'
                      required={false}
                      className={data.email !== '' ? 'focused-label' : ''}
                    />
                  </div>

                  <div className='input-wrapper password-input'>
                    <BillingTextField
                      value={data.password}
                      handleChange={onInputChange}
                      floatingLabel='Password'
                      placeholder='Password'
                      type={hideshowPw}
                      param='password'
                      required={false}
                      className={data.password !== '' ? 'focused-label' : ''}
                    />
                    <div onClick={showHidePwFunc}>
                      <Icon name={hideshowPw === 'password' ? 'visibility_off' : 'visibility'} />
                    </div>
                  </div>
                  <div className='forget__pw'>
                    <p onClick={() => handleFabButtonClick()}>Forgot Password?</p>
                    <Dialog
                      data={data}
                      validationFlag={validationFlag}
                      actionType={dialogType}
                      onInputChange={onInputChange}
                      onModalClose={onModalClose}
                      onSubmit={onSubmit}
                    />
                  </div>
                  <div>
                    <Button raised accent className='login-btn' onClick={() => onSubmit('login')}>
                      Login
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='login-footer'>© 2017 Evolve</div>
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

LoginView.propTypes = propTypes;

const LoginViewWithLoading = withLoading(LoginView);

export default LoginViewWithLoading;
