import React from 'react';
import {
  Router, Switch, Route, Redirect
} from 'react-router-dom';
import Login from '../login/Login';
import Company from '../configuration/company';
import routes from '../../routes';
import history from '../../utils/history';
import { USER } from '../../data/enums/enums';
import ScrollToTop from '../common/ScrollToTop';
import withLoading from '../../common/withLoading';
import LeftDrawer from '../../components/LeftDrawer/LeftDrawer';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import {
  Grid, Cell, LinearProgress
} from '../../components/BillingMDC';
import { COMPANY } from '../../data/enums/Route';

const propTypes = {};

const BaseView = ({ ...props }) => {
  const { loading, onLogOutClick, onInfoClick, toggleDrawerState, onDownloadClick, drawerState, user, company } = props;
  return (
    <Router history={history}>
      <Router history={history}>
        <Switch>
          <Route exact path='/' render={() => <Redirect to='/login' />} />
          <Route exact path='/login' component={Login} />
          <ScrollToTop>
            <div className='app'>
              {loading && (
                <div className='linear-progress-wrapper temp-progress-wrapper'>
                  <LinearProgress accent indeterminate />
                </div>
              )}
              <NavigationBar
                onLogOutClick={onLogOutClick}
                onInfoClick={onInfoClick}
                onDownloadClick={onDownloadClick}
                onDrawerStateChange={toggleDrawerState}
                drawerState={drawerState}
                user={user}
                company={company}
                superUserId={USER.SUPER_USER_GROUP}
              />
              <div className='content scrollbar-container'>
                {history.location.pathname === `/${COMPANY}` ? (
                  <div className='content-body'>
                    <Route exact path={`/${COMPANY}`} component={Company} />
                  </div>
                ) : (
                  <>
                   <div className='content-drawer'>
                    <LeftDrawer
                      drawerMiniState={drawerState}
                      onDrawerStateChange={toggleDrawerState}
                      path={history.location}
                    />
                    </div>
                    <div className='content-body'>
                      <Grid>
                        <Cell col={12}>
                          <div className='app-body pad-24-0-24-10'>
                            <Route path='/' component={routes} />
                          </div>
                        </Cell>
                      </Grid>
                    </div>
                  </>
                )}
              </div>
            </div>
          </ScrollToTop>
          <Route exact path='`/new/:page`' component={Error} />
        </Switch>
      </Router>
    </Router>
  );
};

BaseView.propTypes = propTypes;

const BaseViewWithLoading = withLoading(BaseView);

export default BaseViewWithLoading;
