import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';
import moment from 'moment/moment';

class CreateJournalVoucherTableBody extends Component {
  render() {
    const { data, index, partyIncluded } = this.props;
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{data.ledger_name}</td>
        <td className='right-align'>{data.dr_amount}</td>
        <td className='right-align'>{data.cr_amount}</td>
        <td className='left-align'>{data.ref_number}</td>
        <td className='left-align'>{moment(data.ref_date).format('ll')}</td>
        <td className='left-align'>{data.narration}</td>
        {partyIncluded && <td className='left-align'>{data.party_name}</td>}
        {partyIncluded && <td className='left-align'>{data.party_pan_no}</td>}
        {partyIncluded && <td className='right-align'>{data.party_taxable_amount}</td>}
        <td className='right-align'>
          <Icon name='edit' onClick={() => this.props.handleEditIconClick(data, index)} />
          <Icon name='delete' onClick={() => this.props.handleDeleteIconClick(index)} />
        </td>
      </tr>
    );
  }
}

CreateJournalVoucherTableBody.defaultProps = { enableUpdate: true, };

CreateJournalVoucherTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteIconClick: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  enableUpdate: PropTypes.bool,
};

const createJournalVoucherConfig = {
  headerDetails: [
    { id: 1, label: 'index', title: 'SN', className: null, sortable: false, display: true },
    { id: 2, label: 'ledger_name', title: 'Ledger', className: null, sortable: false, display: true },
    { id: 3, label: 'dr_amount', title: 'Debit', className: 'right-align', sortable: false, display: true },
    { id: 4, label: 'cr_amount', title: 'Credit', className: 'right-align', sortable: false, display: true },
    { id: 5, label: 'ref_number', title: 'RefNo', className: 'left-align', sortable: false, display: true },
    { id: 6, label: 'ref_date', title: 'RefDate', className: 'left-align', sortable: false, display: true },
    { id: 7, label: 'narration', title: 'Narration', className: 'left-align', sortable: false, display: true },
    { id: 8, label: 'party_name', title: 'Party Name', className: 'left-align', sortable: false, display: false },
    { id: 9, label: 'party_pan_no', title: 'Party PAN No.', className: 'left-align', sortable: false, display: false },
    {
      id: 10,
      label: 'party_taxable_amount',
      title: 'Taxable Amount',
      className: 'right-align',
      sortable: false,
      display: false,
    },
    { id: 11, label: 'action', title: '', className: 'right-align', sortable: false, display: true },
  ],
};

const ledgerType = [
  { value: '1', label: 'Customer Ledger' },
  { value: '2', label: 'Sales Ledger' },
  { value: '3', label: 'Tax Ledger' },
  { value: '4', label: 'Vendor Ledger' },
  { value: '5', label: 'Purchase Ledger' },
];
const vatAccountLedger = 103;
const message = {
  success: 'Journal Voucher created.',
  error: '!Error while creating Journal Voucher',
  balanceError: '!Error Debit and Credit amount must be equal',
};

const partyHeaderList = ['party_name', 'party_pan_no', 'party_taxable_amount'];

export {
  createJournalVoucherConfig,
  CreateJournalVoucherTableBody,
  ledgerType,
  message,
  partyHeaderList,
  vatAccountLedger,
};
