const getCSVHeader = async (event) => {
  return new Promise((resolve, reject) => {
    if (typeof FileReader != 'undefined') {
      const myReader = new FileReader();
      myReader.onload = (e) => {
        const content = myReader.result;
        const csvHeader = content.split(/[\r\n]+/)[0];
        const csvHeaderFiltered = csvHeader.replace(/&/g, '').replace(/</g, '').replace(/"/g, '').split(',');
        const csvHeaderMap = csvHeaderFiltered.map((str, index) => ({
          title: str,
          id: index + 1,
        }));
        resolve({ data: csvHeaderMap, isError: false, message: '' });
      };
      myReader.onerror = (e) => {
        reject({
          data: [],
          isError: true,
          message: 'Error while processing CSV file',
        });
      };
      myReader.readAsText(event.files[0]);
    } else {
      reject({
        data: [],
        isError: true,
        message: 'This browser does not support HTML5.',
      });
    }
  });
};

export { getCSVHeader };
