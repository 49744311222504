import { compose } from 'recompose';

import LedgerReport from './LedgerReport';

import apiInterceptor from '../../../api/interceptor';

import { ledgerApi } from '../../common/base.api';

const composedLedgerReport = compose(apiInterceptor({ ...ledgerApi }))(LedgerReport);

export default composedLedgerReport;
