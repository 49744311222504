import styled from 'styled-components';

const OpeningBlncStyled = styled.div`
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.opening-blnc .no-end-list-table {
    max-height: calc(100vh - 260px) !important;
  }
  .btns{
    text-align: right;
    margin-top: 24px;
  }
  .fixed-table-wrapper {
    & > table {
      th {
        z-index: 2;
      }
      tbody {
        tr.inActive {
          transition: all 0.1s ease-in;
        }
        tr.active {
          border-bottom: 1px solid rgb(33, 150, 243);
          background: rgba(243, 250, 255, 0.3);
          height: 56px;
          transition: all 0.4s ease-out;
        }
      }
    }
  }
  .collapsible-body-tr {
    table {
      box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
      cursor: unset;
      th {
        position: relative;
        z-index: 1;
        background: rgba(244, 244, 244, 0.5);
      }
    }
  }
  .costPer {
    &.disabled{
      cursor: not-allowed;
      &>span{
        pointer-events: none;
      }
    }
    .mdc-textfield {
    width:100px;
    text-align:right;
    margin-right:0;
    input{
      text-align: right;
    }
  }
}
table tbody {
    tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #2196f3;
        }
      }
      td:first-child {
        & > span {
          position: relative;
          padding-left: 26px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }
    }
  }
`;
export default OpeningBlncStyled;
