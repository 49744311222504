import styled from 'styled-components';

const UserGroupStyled = styled.div`
  .not-allowed-cursor {
    cursor: not-allowed;
  }
  &.user-group {
    table {
      thead {
        th {
          width: 50%;
        }
      }
      tbody {
        td {
          cursor: pointer;
        }
      }
    }
    &__details {
      .card-header-bar {
        padding-left: 0;
        h2 span {
          i {
            margin-left: 0 !important;
            color: #9b9c9e;
            font-size: 24px;
            margin-right: 18px;
            transition: all 0.23s ease-in-out;
            &:hover {
              color: #2196f3;
              transition: all 0.23s ease-in-out;
            }
          }
        }
      }
      .react-tabs .react-tabs__tab-list {
        margin: 0;
        margin-bottom: 24px;
      }
      .fixed-table-wrapper table th {
        z-index: 2;
      }
      /* tab section  */
      .card-header-bar {
        background-color: unset;
      }
      .react-tabs .react-tabs__tab-list {
        margin-left: 24px;
      }
      .react-tabs__tab-panel--selected {
        background-color: #fff;
        box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
      }
      .btns {
        text-align: right;
        margin-top: 24px;
      }

      .form-wrap {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
        h6 {
          color: rgba(0, 0, 0, 0.54);
          margin-bottom: 4px;
          font-weight: 500;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          margin-bottom: 24px;
        }
        .form-switchs,
        .form-inputs {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 530px;
          width: 49%;
          margin-bottom: 24px;
          .label {
            flex: 1;
            font-size: 16px;
            margin-right: 16px;
          }
          .input {
            width: 328px;
            width: 62%;
            .mdc-textfield {
              margin: 0;
            }
            .mdc-textfield {
              height: unset;
            }
            label {
              display: none;
            }
            input {
              background: rgba(0, 0, 0, 0.087);
              height: 40px;
              line-height: 40px;
              border-radius: 4px;
              padding: 0;
              border: 0;
              padding: 0 16px;
            }
          }
        }
      }
      .detail-tab-section {
        padding: 24px;
      }
      .read {
        .permission-tab-section .fixed-table-wrapper {
          max-height: calc(100vh - 230px) !important;
        }
      }
      .permission-tab-section {
        .fixed-table-wrapper {
          max-height: calc(100vh - 226px) !important;
          overflow: auto;
          padding-bottom: 24px;
        }
        tr {
          cursor: unset !important;
          td {
            span {
              color: rgba(0, 0, 0, 0.54);
              font-weight: 500;
            }
          }
          &.accordionExist {
            cursor: pointer !important;
          }
        }
        .active .accordion-open {
          background-color: #f3f3f3;
        }
        .accordion-open {
          position: relative;
          span {
            color: rgba(0, 0, 0, 0.54);
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
          }
          span::before {
            content: attr(data-icon);
            font-family: 'Material Icons';
            position: absolute;
            top: 16px;
            left: 24px;
            font-size: 20px;
            transition: all 0.23s ease-in-out;
            color: #9b9c9e;
          }
          &:first-child {
            padding-left: 48px;
          }
        }
        .active .accordion-open span::before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #2196f3;
          color: #2196f3;
        }
        table td {
          width: 50%;
        }
        .padding-l-18 {
          padding-left: 18px !important;
        }
        .collapsible-body-tr {
          border: 0;
          td {
            &:last-of-type {
              padding: 0;
            }
            table td {
              padding-left: 52px;
            }
          }
        }
      }
    }
  }
`;
export default UserGroupStyled;
