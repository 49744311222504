import { compose } from 'recompose';

import StockLedger from './StockValuationReport';

import apiInterceptor from '../../../api/interceptor';

import { stockValuationReportApi } from '../../common/base.api';

const composedStockLedger = compose(apiInterceptor({ ...stockValuationReportApi }))(StockLedger);

export default composedStockLedger;
