import React, { Component } from 'react';
import { connect } from 'react-redux';
import tableConfig from './config';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { DATABASE_CONFIG } from '../../common/domain.config';
import { BillingSnackBar } from '../../../components/BillingMDC';
import DataBaseLogStyled from './DataBaseLogStyled';

class DataBaseLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
      },
      data: {
        list: [],
        total: 0,
      },
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  componentDidMount() {
    this.loadTableData();
  }

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    getList(queryParameters)
      .then((response) => {
        const { data } = this.state;
        data.list = response;
        data.total = response.length;
        this.setState({ data });
      })
      .catch((err) => {
        console.log(err);
        this.sendErrorMessage();
      });
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const {
      queryParameters, data, display, snack,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <DataBaseLogStyled className="data-base">
        <PageView
          domainConfig={DATABASE_CONFIG}
          headerConfig={{
            search: true,
            date: true,
            download: false,
            filter: false,
            create: false,
            print: false,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.basePaginationService.resetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          onPageSelect={this.basePaginationService.onPageSelect}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </DataBaseLogStyled>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeStockLedger: (action) => dispatch(action),
  };
}

const StockLedgerWithState = withBaseState(DataBaseLog);

export default connect(null, mapDispatchToProps)(StockLedgerWithState);
