import { compose } from 'recompose';

import JvDetail from './JournalVoucherDetail';

import apiInterceptor from '../../../api/interceptor';

import { jvApi ,ledgerApi} from '../../common/base.api';

const composedJournalVoucherDetail = compose(apiInterceptor({ ...jvApi ,ledgerListApi:ledgerApi.getList}))(JvDetail);

export default composedJournalVoucherDetail;
