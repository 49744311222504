const getConfigData = footerData => {
  //   const ledgerData = getFilteredData(ledgerList, companyData, ledgerDetails);

  //   return ledgerData;

  const columns = [
    [
      { content: 'SN' },
      { content: 'Date' },
      { content: 'Miti' },
      { content: 'Vouncer Number' },
      { content: 'Narration' },
      { content: 'Debit' },
      { content: 'Credit' },
      { content: 'Balance' },
      { content: '' },
    ],
  ];
  const footer = [
    [
      '',
      'Total',
      '',
      '',
      '',
      { content: footerData?.tableDataTotalDebit ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.tableDataTotalCredit ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.tableDataTotalBalance ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.tableDataTotalDebitORCredit ?? '', styles: { lineWidth: 0.1 } },
    ],
  ];

  const columnStyles = {
    0: { cellWidth: 10 },
    1: { cellWidth: 20 },
    2: { cellWidth: 18 },
  };

  const cellsToAlignRight = { 5: '5', 6: '6', 7: '7' };

  return {
    columns,
    footer,
    columnStyles,
    cellsToAlignRight,
  };
};

export default getConfigData;
