import React from 'react';
import PropTypes from 'prop-types';
import { has } from '../../../utils/hasOwnProperty';

const numberConfig = {
  //minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
const AgeingReportTableBody = ({ data }) => {
  return (
    <tr className='cursor-pointer'>
      <td className='left-align'>{data.ledger_name}</td>
      <td className='right-align'>
        {has.call(data, 'totalbalance')
          ? Number(data.totalbalance).toLocaleString(undefined, numberConfig)
          : data.totalbalance}
      </td>
      <td className='right-align'>
        {has.call(data, 'slab1') ? Number(data.slab1).toLocaleString(undefined, numberConfig) : data.slab1}
      </td>
      <td className='right-align'>
        {has.call(data, 'slab2') ? Number(data.slab2).toLocaleString(undefined, numberConfig) : data.slab2}
      </td>
      <td className='right-align'>
        {has.call(data, 'slab3') ? Number(data.slab3).toLocaleString(undefined, numberConfig) : data.slab3}
      </td>
      <td className='right-align'>
        {has.call(data, 'slab4') ? Number(data.slab4).toLocaleString(undefined, numberConfig) : data.slab4}
      </td>
      <td className='right-align'>
        {has.call(data, 'slab5')
          ? Number(data.slab5).toLocaleString(undefined, numberConfig)
          : data.slab5 === null
            ? 0
            : data.slab5}
      </td>
    </tr>
  );
};
/*const OutstandingTotal = ({totalData}) => {


  return (
    <tr className="cursor-pointer">
      {/!*<td className="left-align">{moment(data.date).format('ll')}</td>*!/}
      <td className="left-align"></td>
      <td className="left-align"></td>
      <td className="left-align"></td>
      <td className="left-align"></td>
      <td className="right-align"></td>
      <td className="right-align"></td>
      <td className="right-align"></td>
      <td className="right-align">{totalData.title}</td>
      <td className="right-align">{totalData.hasOwnProperty('value') ? totalData.value.toLocaleString(undefined, numberConfig): ''}</td>
      <td className="right-align"></td>
    </tr>
  )

};*/

/*
OutstandingReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};
*/

const ageingReportConfig = {
  headerDetails: [
    { id: 1, label: 'ledger_name', title: 'Name', className: 'cursor-pointer left-align', sortable: true },
    { id: 2, label: 'totalBalance', title: 'Balance', className: 'cursor-pointer right-align', sortable: true },
  ],
};

export { ageingReportConfig, AgeingReportTableBody };
