import { compose } from 'recompose';
import Detail from './Detail';

import apiInterceptor from '../../../../api/interceptor';

import { skuDetailsApi, buApi } from '../../../common/base.api';

const composedPromotionDetail = compose(apiInterceptor({ ...skuDetailsApi, getBUList: buApi.getList }))(Detail);

export default composedPromotionDetail;
