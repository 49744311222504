import { compose } from 'recompose';

import OpeningBalance from './OpeningBalance';

import apiInterceptor from '../../../api/interceptor';

import {
  openingBalanceApi, buApi, fiscalYearApi
} from '../../common/base.api';

const composedOpeningBalance = compose(
  apiInterceptor({ ...openingBalanceApi, getBUList: buApi.getList, getFiscalYearList: fiscalYearApi.getList }),
)(OpeningBalance);

export default composedOpeningBalance;
