import { compose } from 'recompose';

import TagWise from './TagWise';

import apiInterceptor from '../../../api/interceptor';

import {
    tagReportApi
} from '../../common/base.api';

const composedLedgerWise = compose(
    apiInterceptor({ ...tagReportApi }),
)(TagWise);

export default composedLedgerWise;


