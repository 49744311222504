import PropTypes from 'prop-types';
import React from 'react';

const PageNumber = ({ value, totalPage }) => {
  return (
    <span className='display-block center-align page-number'>
      {value} of {totalPage}
    </span>
  );
};

PageNumber.propTypes = { value: PropTypes.number, };

export default PageNumber;
