import styled from 'styled-components';

const LoginStyled = styled.div`
  .login-wrapper {
    display: table;
    background: url('./svg/cloud.svg') repeat-x bottom;
    position: absolute;
    height: 100vh;
    width: 100%;
    text-align: center;
  }
  .login-center-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
    margin: auto;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    .login-title {
      margin: 12vh 0 5vh 0;
      & > h3 {
        margin: 0;
        font-size: 20px;
        font-weight: 24px;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
        strong {
          font-weight: 600;
        }
      }
    }
    .login-footer {
      margin-top: 20vh;
    }
  }
  .login-logo {
    margin: 0 auto;
    margin-top: 5vh;
  }
  .login-logo img {
    height: 63px;
    width: 76px;
  }
  .login-title h2 {
    font-weight: var(--regular);
  }
  .login-body {
    width: min(395px, 80%);
  }
  .login-btn {
    background-color: var(--accent-color);
  }
  .input-wrapper {
    position: relative;
    &.password-input {
      position: relative;
      input {
        padding-right: 8%;
      }
      .material-icons {
        position: absolute;
        right: 10px;
        top: 32px;
      }
    }
    .mdc-textfield {
      height: unset !important;
      max-width: unset;
      margin: 0;
      position: relative;
      border-bottom: 1px solid #f1f1f1;
      input {
        background-color: #ffffff !important;
        height: 80px;
        border-bottom: 1px solid #f1f1f1;
        font-size: 16px;
        padding: 0 5% 0 5%;
        border: none;
        outline: none;
        &:not(:focus) ~ label {
          opacity: 0;
          transition: 0.4s ease all;
          -moz-transition: 0.4s ease all;
          -webkit-transition: 0.4s ease all;
        }
        &[value=''] ~ label {
          opacity: 0;
        }
      }
      label {
        position: absolute;
        font-size: 16px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5%;
        top: 63%;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        color: unset;
      }
      input:focus ~ label {
        top: 34%;
        font-size: 12px;
        transform: translateY(-35%);
        color: var(--accent-color) !important;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus {
        -webkit-text-fill-color: inherit;
        -webkit-box-shadow: inherit;
        transition: background-color 5000s ease-in-out 0s;
        &:-webkit-autofill::first-line {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  .login-form button {
    width: 100%;
    line-height: 56px;
    height: 56px;
    font-size: 14px;
    font-weight: var(--medium);
    border-radius: 0;
    color: #ffffff;
  }
  .login-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .login-footer {
    color: #a0a0a0;
    font-weight: var(--medium);
  }
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    background-color: #ffffff !important;
  }
  .forget__pw {
    display: flex;
    justify-content: flex-end;
    & > p {
      font-size: 16px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.38);
      margin: 0;
      margin: 10px 0 16px 0;
      cursor: pointer;
    }
    &--dialog {
      text-align: left;
      .mdc-dialog__header {
        padding: 14px 24px;
      }
      .mdc-dialog__surface {
        max-width: 445px;
        .dialog-upperpart {
          border-bottom: 0;
        }
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.5);
        margin: 0;
        margin-bottom: 24px;
        text-align: left;
      }
      .mdc-textfield {
        max-width: unset;
      }
      footer {
        display: flex;
        padding: 0;
        button {
          width: unset;
          margin: 0;
        }
      }
    }
  }
  & + .message-snackbar .mdc-snackbar--active {
    bottom: 3%;
    left: 3%;
  }
`;
export default LoginStyled;
