import React, { Component } from 'react';
import View from './View';
import tableConfig from './config';
import ProfitLossStyle from '../profitLoss/ProfitLossStyle';
import withBaseState from '../../common/withBaseState';
import * as queryService from '../../common/query.service';
import PageHeader from '../../common/pagination/PageHeader';
import { BALANCE_SHEET_CONFIG } from '../../common/domain.config';
import Loading from '../../../common/Loading';
import * as filterUtil from '../../../utils/filterUtil';
import { accountReportViewOptions } from '../../common/DrawerFilter/config';
import { includeStockFilter } from '../profitLoss/config';
import { PAGINATION } from '../../../data/enums/enums';
import * as downloadService from '../../common/download.service';

class BalanceSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        drawer: true,
      },
      queryParameters: {
        date:{
          start: filterUtil.getCurrentDay(),
          end: filterUtil.getCurrentDay(),
        },
        flag: { stock: includeStockFilter },
        viewType: accountReportViewOptions[0].label,
      },
      data: {
        assetsLedgers: [],
        liabilitiesLedgers: [],
        totalBalance: {},
        total: 0,
      },
      activeAssets: [],
      activeLiabilities: [],
      activeRow: {
        activeAssets: 0,
        activeLiabilities: 0,
      },
      activeView: accountReportViewOptions[0].label,
      startDate:filterUtil.getCurrentDay(),
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
  }
  componentDidMount() {
    this.getStartDate();
  }
  getStartDate = async() => {
    const { getStartDate } = this.props;
    const { queryParameters } = this.state;
    const extraQueryString ='&report=BALANCE_SHEET';
    await getStartDate({query:queryParameters,extraQueryString,}).then(response => {
      const startDate =filterUtil.formatToDateMonthYear(response);
      queryParameters.date.start = startDate;
      this.setState({...queryParameters,startDate},()=>{
        this.loadTableData();
      });
    });
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  loadTableData = () => {
    const { getBalanceSheetList } = this.props;
    const { queryParameters, activeRow ,startDate} = this.state;
    getBalanceSheetList(queryParameters).then(response => {
      const { data } = this.state;
      data.assetsLedgers = response.data.groupedAssets || [];
      data.liabilitiesLedgers = response.data.groupedLiabilities || [];
      data.totalBalance = response.data.totalBalance || {};
      data.total = response.total ? response.total : response.data.length;
      let activeDetail = { activeAssets: [], activeLiabilities: [] };
      const assetsLedgers =
        (data.assetsLedgers.length &&
          data.assetsLedgers[activeRow.activeAssets] &&
          data.assetsLedgers[activeRow.activeAssets].list &&
          data.assetsLedgers[activeRow.activeAssets].list) ||
        [];
      const liabilitiesLedgers =
        (data.liabilitiesLedgers.length &&
          data.liabilitiesLedgers[activeRow.activeLiabilities] &&
          data.liabilitiesLedgers[activeRow.activeLiabilities].list &&
          data.liabilitiesLedgers[activeRow.activeLiabilities].list) ||
        [];
      activeDetail.activeAssets = assetsLedgers;
      activeDetail.activeLiabilities = liabilitiesLedgers;
      this.setState({
        data,
        activeAssets: activeDetail.activeAssets,
        activeLiabilities: activeDetail.activeLiabilities,
      });
    });
  };
  resetFilter = () => {
    const {startDate} =this.state;
    this.basePaginationService.defaultResetFilter({
      flag: { stock: includeStockFilter },
      viewType: accountReportViewOptions[0].label,
    });
    this.setState(
      {
        queryParameters: {
          ...queryService.queryParameters,
            date:{start:startDate,end:filterUtil.getCurrentDay()},
            flag: { stock: {...includeStockFilter,status:true} },
        },
        activeView: accountReportViewOptions[0].label,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  handleRowClick = (label, data, index) => {
    const { activeRow } = this.state;
    const activeDetail = { ...activeRow };
    this.setState({
      [label]: data,
      activeRow: { ...activeDetail, [label]: index },
    });
  };

  handleViewChange = activeView => {
    this.setState({ activeView });
  };

  handleDownloadClick = reportType => {
    const { downloadList } = this.props;
    const { queryParameters, activeView } = this.state;
    const extraQueryString = `&view_type=${activeView}`;
    downloadList({
      type: reportType,
      query: queryParameters,
      extraQueryString,
    }).then(response => downloadService.resolver(response));
  };

  render() {
    const { queryParameters, data, display, activeView, activeAssets, activeLiabilities} = this.state;
    const { serverResponseWaiting, company } = this.props;

    return (
      <ProfitLossStyle>
        <div className="profit-loss">
          <PageHeader
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            handleDownloadClick={this.handleDownloadClick}
            controlDisplay={this.controlDisplay}
            clearSearchText={this.basePaginationService.clearSearchText}
            queryParameters={queryParameters}
            domainConfig={BALANCE_SHEET_CONFIG}
            display={display}
            config={{
              search: false,
              filter: true,
              date: false,
              create: false,
              download: true,
              flag: true,
            }}
            handleUploadClick={this.basePaginationService.handleUploadClick}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handlePrintClick={this.basePaginationService.handlePrintClick}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            handleDateChange={this.basePaginationService.handleDateChange}
            resetFilter={this.resetFilter}
            disableStartDate={false}
            viewOptions={{
              list: accountReportViewOptions,
              handler: this.handleViewChange,
              selectedValue: activeView,
            }}
            handleFlagChange={this.basePaginationService.handleFlagChange}
            serverResponseWaiting={serverResponseWaiting}
            
          />
          <View
            data={data}
            company={company}
            config={tableConfig}
            queryParameters={queryParameters}
            activeView={activeView}
            activeAssets={activeAssets}
            activeLiabilities={activeLiabilities}
            onRowClick={this.handleRowClick}
          />
        </div>
      </ProfitLossStyle>
    );
  }
}
const BalanceSheetWithState = withBaseState(BalanceSheet);

export default BalanceSheetWithState;
