// TODO: Include Prop Types and Use for Composition Pattern.

import React from 'react';

import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
} from '../../../../components/BillingMDC';

import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import { discountOptions } from './config';
import { fixedFloat } from '../../../../utils/conversion';
import BillingAsyncSelect from '../../../../components/AsyncSelect';

/**
 * handleModalClose: this.closeModal
 * createSKU: this.state.createSKU
 * handleInputChange: handleInputChange
 * onModalSubmit: this.handleModalOkClick
 * formEmptyField: this1.state.formEmptyField
 * brandList: this.state.brandList
 * promotionByCriteria: this.state.promotionByCriteria
 * skuList: this.skuList
 * brandList: this.state.brandList
 * handlePromotionSelect: this.handlePromotionSelect
 * */

const CreateInvoiceDialog = ({
  handleModalClose,
  modalOpen,
  editModalOpen,
  deleteModalOpen,
  createSKU,
  handleInputChange,
  onModalSubmit,
  promotionByCriteria,
  skuList,
  brandSKUList,
  brandList,
  batchList,
  formEmptyField,
  handlePromotionSelect,
  stockQuantity,
  keyValue,
  editRate,
  editRatePermission,
  promotionList,
  promotionValidationFailed,
  loadOptions,
  UOMList,
}) => {
  return (
    <Dialog
      open={modalOpen || editModalOpen || deleteModalOpen}
      onClose={() => {
        handleModalClose();
      }}
    >
      <DialogHeader>
        {modalOpen && <DialogTitle>Select SKU</DialogTitle>}
        {editModalOpen && <DialogTitle>Edit Invoice</DialogTitle>}
        {deleteModalOpen && <DialogTitle> Delete Invoice</DialogTitle>}
      </DialogHeader>

      {(modalOpen || editModalOpen) && (
        <DialogBody>
          <div className='dialog-upperpart'>
            <div className='row_one row '>
              <Grid className='grid-padding'>
                <Cell col={4} tablet={8}>
                  <label>Brand</label>
                  <BillingSelect
                    name='form-field-name'
                    param='brand'
                    clearable={true}
                    value={createSKU?.brand}
                    valueKey='indexArr'
                    labelKey='title'
                    options={brandList}
                    onChange={(e) => handleInputChange('brand', e === null? '' : e?.indexArr , e)}
                  />
                </Cell>

                <Cell col={8} tablet={8}>
                  <label>SKU</label>
                  <BillingAsyncSelect
                    param='sku_id'
                    placeholder='Search/Select...'
                    loadOptions={loadOptions}
                    handleChange={handleInputChange}
                    options={skuList}
                    defaultOptions={brandSKUList}
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ businessSkuId }) => businessSkuId}
                    multipleParam
                    required
                    emptyField={formEmptyField}
                    value={skuList.filter(({ businessSkuId }) => businessSkuId === createSKU.sku_id)}
                    valueKey='businessSkuId'
                  />
                </Cell>
                <Cell col={4} tablet={8}>
                  <label>Batch</label>
                  <BillingSelect
                    name='form-field-name'
                    param='batch_id'
                    clearable={false}
                    value={createSKU.batch_id}
                    valueKey='businessBatchId'
                    labelKey='title'
                    options={batchList}
                    handleChange={handleInputChange}
                    multipleParam
                    required={true}
                    emptyField={formEmptyField}
                  />
                </Cell>
                <Cell col={4} tablet={8} className>
                  <BillingTextField
                    value={fixedFloat(createSKU.rate)}
                    handleChange={handleInputChange}
                    floatingLabel='Rate'
                    param='rate'
                    required
                    type='number'
                    disabled={!(editRate && editRatePermission)}
                    zeroError={false}
                    className='billing-required right-side-gap height-50'
                    errorMessage={createSKU.rate === 0 ? 'Should not be 0' : ''}
                    invalidError={formEmptyField && createSKU.rate === 0}
                  />
                </Cell>

                <Cell col={4} tablet={8}>
                  <BillingTextField
                    value={stockQuantity}
                    handleChange={handleInputChange}
                    floatingLabel='Available Stock (pcs)'
                    param='available-stock'
                    type='number'
                    disabled
                    className='billing-required right-side-gap height-50 custom-stock'
                  />
                </Cell>
              </Grid>
            </div>
            <div className='row_two row'>
              <Grid className='grid-padding'>
                <Cell col={4} tablet={8}>
                  <label>UOM</label>
                  <BillingSelect
                    name='form-field-name'
                    param='uom'
                    clearable={false}
                    value={createSKU.uom_list?.filter((list) => list.value === createSKU?.uom?.value)}
                    multipleParam
                    options={createSKU.uom_list}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    onChange={(e) => handleInputChange('uom', e.value, e)}
                  />
                  {createSKU?.uom?.value && createSKU?.uom?.pcs !== 1 && (
                    <span className='upc-value'>{`1 ${createSKU?.uom?.value} = ${createSKU?.uom?.pcs} pcs`}</span>
                  )}
                </Cell>
                <Cell col={4} tablet={8}>
                  <BillingTextField
                    value={createSKU.quantity}
                    handleChange={handleInputChange}
                    floatingLabel='Quantity'
                    param='quantity'
                    required
                    zeroError
                    type='number'
                    // allowMultiZeroBeforeNumber
                    rule='isInt'
                    className='billing-required right-side-gap'
                    emptyField={formEmptyField}
                    errorMessage={
                      createSKU.quantity * createSKU?.uom?.pcs === 0
                        ? 'Should not be 0'
                        : createSKU.quantity * createSKU?.uom?.pcs > stockQuantity
                          ? 'Should not be greater than available quantity'
                          : ''
                    }
                    invalidError={formEmptyField && createSKU.quantity * createSKU?.uom?.pcs > stockQuantity}
                  />
                </Cell>
                <Cell col={2}>
                  <div className='cash-checkbox'>
                    <Checkbox
                      checked={createSKU.freeSku}
                      onChange={({ target: { checked } }) => {
                        handleInputChange('freeSku', checked);
                      }}
                    />
                    <label>Free SKU</label>
                  </div>
                </Cell>
              </Grid>
              {createSKU.quantity * createSKU?.uom?.pcs > stockQuantity ? (
                <div className='error-msg'>
                  <label>{`Should not be greater than available quantity i.e ${stockQuantity}`}</label>
                </div>
              ) : promotionValidationFailed ? (
                <div className='error-msg'>
                  <label>{'Promotion validation failed!'}</label>
                </div>
              ) : null}
              <Grid className='grid-padding'>
                <Cell col={12} tablet={8}>
                  <label>Promotion</label>
                  <BillingSelect
                    name='form-field-name'
                    param='promotion'
                    clearable={true}
                    value={createSKU.selectedPromotionId}
                    valueKey='id'
                    labelKey='title'
                    options={promotionList}
                    handleChange={handleInputChange}
                    emptyField={formEmptyField}
                    multipleParam
                  />
                </Cell>
              </Grid>
            </div>
            <div className='row_three row'>
              <Grid className='grid-padding three'>
                <Cell col={2}>
                  <label className='label'>Discount Type</label>
                  <BillingSelect
                    name='form-field-name'
                    param='discount_label'
                    clearable={false}
                    value={discountOptions.filter((list) => list.value === createSKU.discount_label)}
                    options={discountOptions}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    disabled={createSKU.freeSku || createSKU.selectedPromotionId}
                    onChange={(e) => handleInputChange('discount_label', e.value)}
                  />
                </Cell>
                <Cell col={4}>
                  <BillingTextField
                    disabled={createSKU.freeSku || createSKU.selectedPromotionId}
                    value={createSKU.discount_input}
                    handleChange={handleInputChange}
                    floatingLabel={createSKU.discount_label === 'rs' ? 'Discount' : 'Discount (%)'}
                    param='discount_input'
                    type='number'
                    className='billing-required right-side-gap'
                    max={createSKU.discount_label === 'rs' ? createSKU.quantity * createSKU.rate * createSKU.uom.pcs : 100}
                  />
                </Cell>
                <Cell col={4}>
                  <label>Net Amount</label>
                  <span className='accent-color net-amount dialog-detail'>{createSKU.net_amount}</span>
                </Cell>
              </Grid>
            </div>

            {/*{
            promotionByCriteria.length > 0 && (
              <Grid className="grid-padding">
                <Cell col={12} tablet={12}>
                  <label>Promotion</label>
                  <BillingSelect
                    name="form-field-name"
                    param="test"
                    clearable={false}
                    value={createSKU.promotion_discount_id}
                    valueKey="businessPromotionId"
                    labelKey="promotionTitle"
                    options={promotionByCriteria}
                    handleChange={handlePromotionSelect}
                    required
                    emptyField={formEmptyField}
                    multipleParam
                  />
                </Cell>
              </Grid>
            )
          }*/}
          </div>
        </DialogBody>
      )}

      {deleteModalOpen && <div className='default-margin-24'>Are you sure you want to Delete ?</div>}

      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            handleModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
          Ok
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default CreateInvoiceDialog;
