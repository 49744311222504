import styled from 'styled-components';

const StockLedgerStyled = styled.div`

/* stockLedger for Print ONly quickFix*/
&.stock-ledger{
  table{
    thead{
      th{
        border: 1px solid #e6e3e3;
      }
      &.upperHeader{
        th{top:unset;}
      }
    }
    tbody{
      td{
        .title_div{
          display: flex;
          align-items: center;
            gap: 16px;
            p{
              margin:0;
            }
            .status-label {
              border: 1px solid transparent;
              display: inline-block;
              font-size: 10px;
              font-weight: 600;
              border-radius: 2px;
              min-width: 67px;
              height: 14px;
              line-height: 13.5px;
              text-align: center;
              white-space: nowrap;
              text-transform: uppercase;
              &.active {
                border-color: #47c464;
                color: #287d3c;
              }
              &.passive {
                border-color: #ff8f39;
                color: #b95000;
              }
            }
        }
        &.ob-sellable{
          border-left: 1px solid #e6e3e3;
        }
        &.shortage{
          border-right: 1px solid #e6e3e3;
        }
      }
    }
  }
}
.flex {
    .mdc-layout-grid__inner {
      display: flex !important;
    }
  }
  .center,
  .right,
  .left {
    width: 32% !important;
  }
  /* stockLedger for Print ONly quickFix End*/

  @media print {
    &.stock-ledger{
      .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.28in 0.3in !important;
        page-break-after: always;
        .fixed-table-wrapper{
          height:1420px !important;
          table{
            height:100%;
          }
          thead th{
        border:0;
          }
          tbody{
            td{
              width:60px !important;
              min-width:60px !important;
              max-width:60px !important;
              &.title{
                width:310px !important;
                min-width:310px !important;
                max-width:310px !important;
                white-space:normal !important;
              }
              &.bu{
                width:110px !important;
                min-width:110px !important;
                max-width:110px !important;
                white-space:normal !important;
              }
            }
          }
        }
        .footer-block{
            position:unset;
          }
      }
    }
  }
  @media print and (max-width:5.83in) {
    &.stock-ledger{
      .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
            height:1405px !important;
        }
      }
    }
}
`;
export default StockLedgerStyled;
