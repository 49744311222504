import React, { Component } from 'react';
import {
  Icon,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
} from 'react-mdc-web';
import { journalVoucherDetailConfig, JournalVoucherDetailTableBody } from './JournalVoucherReportDetailConfig';
import TableHeader from '../../../components/TableHeader/TableHeader';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import * as appConstants from '../../config';
import Pagination from '../../../components/Pagination';
import * as httpUtils from '../../../utils/httpUtils';
import * as filterUtil from '../../../utils/filterUtil';
import history from '../../../utils/history';
import { JOURNAL_VOUCHER_BASE } from '../../../data/enums/Route';

let voucherNumber = '';

//invoice id is present in the list.
class JournalVoucherDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      date: '',
      headerTitleToView: journalVoucherDetailConfig.headerTitleToView,
      //journalVoucherDetails:props.journalVoucherDetail,
      totalData: 1,
      dataSet: [],
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
        },
      },
      //voucherNumber:''
    };
  }

  componentDidMount() {
    const voucherNum = this.props.params.id.replace(/%2F/g, '/');
    voucherNumber = voucherNum;
    this.getDetail();
  }

  getDetail = () => {
    const { queryParameters } = this.state;
    this.setState({ loading: true });
    httpUtils
      .get(
        appConstants.baseUrl +
          `report/journal-detail?&page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}&voucher_number=${voucherNumber}`,
      )
      .then(response => {
        this.setState({ loading: false });
        if ((response.status == '200') & response.success) {
          this.setState({ dataSet: response.data.journalVoucherDetail });
        } else {
        }
      });
  };

  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getDetail());
  };

  handleCancelClick = () => {
    history.push(`/${JOURNAL_VOUCHER_BASE}`);
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  /** handle download click
   * params: id => download format type id  */
  handleDownloadClick = () => {
    const type = 'CSV';
    const self = this;
    const schema = 'https://';
    const { queryParameters } = this.state;
    httpUtils
      .get(
        appConstants.baseUrl +
          `download/detail-journal-voucher?bu=
      &page=${queryParameters.pagination.page}
      &limit=${queryParameters.pagination.limit} 
      &voucher_number=${voucherNumber}
      &type=${type}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ loading: false });
          const url = response.data.url;
          const newWin = window.open(schema + window.location.hostname + url);
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            alert('Please enable pop for this site');
          }
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.dataSet;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';
    return orderBy(this.state.dataSet, labelName, sortingName);
  };

  render() {
    const { loading, sorting, queryParameters, totalData } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);

    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="card-header-bar padding-l-0">
            <div className="">
              <div className="header-menu-left">
                <Icon
                  name="arrow_back"
                  className="menu-icon"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                />
              </div>
              <h2 className="header-menu-right"> Journal Voucher : {voucherNumber}</h2>
            </div>
            <div className="header-menu">
              <span className="" onClick={() => this.handleDownloadClick()}>
                <Icon name="get_app" />
              </span>
            </div>
          </div>
          <div className="card-body" ref="tableReference">
            <div ref="fixedTableBody" className="overflow-scrollable fixed-table-wrapper">
              <table>
                <TableHeader
                  headerDetails={journalVoucherDetailConfig.headerDetails}
                  filterHeaderLabel={false}
                  handleSorting={this.handleTableSorting}
                />
                {sortedDataSet.map((data, key) => (
                  <tbody>
                    <JournalVoucherDetailTableBody data={data} index={key} />
                  </tbody>
                ))}
              </table>
            </div>

            {
              <div ref="fixedTableFooter">
                <Pagination
                  pageSize={queryParameters.pagination.limit}
                  currentPage={queryParameters.pagination.page}
                  orientation="top"
                  totalItems={totalData}
                  onPageSelect={this.onPageSelect}
                />
              </div>
            }
          </div>
          {/* <div className = "newinvoice-btn-wrapper">
            <Grid>
              <Cell col={8}></Cell>
              <Cell col={4} className="right-align">
                <Button accent className="cancel-btn modal-btn" onClick={() => {
                  this.handleCancelClick();
                }}>Cancel</Button>
              </Cell>
            </Grid>
          </div>*/}
        </div>
      </div>
    );
  }
}

JournalVoucherDetail.contextTypes = {
  router: PropTypes.object,
};

/*const mapStateToProps = (state) => {
  return {
    journalVoucherDetail : state.journalVoucherDetail || null
  }

};*/

//const journalVoucherDetail = connect(mapStateToProps)(JournalVoucherDetail);

export default JournalVoucherDetail;
