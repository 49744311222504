import styled from 'styled-components';

const AggregateLedgerReportStyled = styled.div`
  .mdc-dialog {
    .mdc-dialog__surface {
      max-width: 400px;
      min-width: 400px;
    }
  }
  .fixed-table-wrapper table th {
    z-index: 2;
  }
  .mdc-textfield__input {
    color: #aaa;
    line-height: 34px;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
`;
export default AggregateLedgerReportStyled;
