import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const SkuPriceHistoryTableBody = ({ data }) => {
  return (
    <tr className='cursor-pointer'>
      <td className='left-align'>{data.skuDetail ? data.skuDetail.businessSkuId : null}</td>
      <td className='left-align'>{data.skuDetail ? data.skuDetail.title : null}</td>
      <td className='right-align'>{data.batchNo}</td>
      <td className='right-align'>{data.rate}</td>
      <td className='left-align'>{moment(data.affectedFrom).format('ll')}</td>
    </tr>
  );
};

SkuPriceHistoryTableBody.propTypes = { data: PropTypes.object.isRequired, };

const skuPriceHistoryConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'skuDetail.businessSkuId',
      title: 'SKU ID',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    { id: 2, label: 'skuDetail.title', title: 'SKU', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'batchNo', title: 'Batch', className: 'cursor-pointer right-align', sortable: true },
    { id: 4, label: 'rate', title: 'Rate', className: 'cursor-pointer right-align', sortable: true },
    { id: 6, label: 'affectedFrom', title: 'Effective From', className: 'cursor-pointer left-align', sortable: true },
  ],
};

export { skuPriceHistoryConfig, SkuPriceHistoryTableBody };
