import React from 'react';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const DOWNLOAD_CONST ={
  DLP:'DLP',
  FIFO:'FIFO',
  WEIGHTED_AVERAGE:'WEIGHTED_AVERAGE',
  FIFO_ANALYSIS:'FIFO_ANALYSIS',
};
const StockLedgerBody = ({ dataList, onRowClick,remainingLines=[] }) => (
  <tbody>
    {dataList.map((data) => (
      <tr onClick={() => onRowClick(data)}>
        <td className='left-align title'>{data.title}</td>
        <td className='right-align'>
          {data.openingBalance ? data.openingBalance.toLocaleString() : data.openingBalance}
        </td>
        <td className='right-align'>
          {data.openingBalanceValue && data.openingBalance
            ? fixedFloatAndCommas(data.openingBalanceValue)
            : 0}
        </td>
        <td className='right-align'>{data.inward ? data.inward.toLocaleString() : data.inward}</td>
        <td className='right-align'>{data.inwardValue ? fixedFloatAndCommas(data.inwardValue) : data.inwardValue}</td>
        <td className='right-align'>{data.outward ? data.outward.toLocaleString() : data.outward}</td>
        <td className='right-align'>
          {data.outwardValue ? fixedFloatAndCommas(data.outwardValue) : data.outwardValue}
        </td>
        {/*<td className='right-align'>{fixedFloatAndCommas(data.adjustment) || 0}</td>*/}
        {/* <td className="right-align">{fixedFloatAndCommas(data.adjustmentValue) || 0}</td>*/}
        <td className='right-align'>
          {data.closingBalanceValuation ? data.closingBalanceValuation.toLocaleString() : data.closingBalanceValuation}
        </td>
        <td className='right-align'>
          {data.closingBalanceValue && data.closingBalanceValuation
            ? fixedFloatAndCommas(data.closingBalanceValue)
            : 0}
        </td>
        {/** <td className="right-align">{data.damage ? fixedFloatAndCommas(data.damage) : data.damage}</td>
          <td className="right-align">{data.damageValue ? fixedFloatAndCommas(data.damageValue) : data.damageValue}</td>
          <td className="right-align">{data.expired ? fixedFloatAndCommas(data.expired) : data.expired}</td>
          <td className="right-align">{data.expiredValue ? fixedFloatAndCommas(data.expiredValue) : data.expiredValue}</td> */}
      </tr>
    ))}
    {remainingLines && remainingLines.length
      ? remainingLines.map((i) => (
        <tr className='blank-tr'>
          <td></td>
          <td colSpan ='14' className=''></td>
        </tr>
      ))
      : null}
  </tbody>
);

const stockLedgerConfig = [
  { id: 2, label: 'skus.title', title: 'SKU', className: '', sortable: false },
  { id: 3, label: 'opening_balance', title: 'Opening', className: ' right-align', sortable: false },
  {
    id: 10,
    label: 'openingBalanceValue',
    title: 'Opening Value',
    className: ' right-align',
    sortable: false,
  },
  { id: 4, label: 'inward', title: 'Inward', className: ' right-align', sortable: false },
  { id: 4, label: 'inwardValue', title: 'Inward Value', className: ' right-align', sortable: false },
  { id: 5, label: 'outward', title: 'Outward', className: ' right-align', sortable: false },
  { id: 5, label: 'outwardValue', title: 'Outward Value', className: ' right-align', sortable: false },
  // { id: 5, label: 'adjustment', title: 'Adjustment', className: ' right-align', sortable: false },
  /* { id: 5, label: 'adjustmentValue', title: 'Adjustment Value', className: ' right-align', sortable: false },*/
  {
    id: 6,
    label: 'closingBalanceValuation',
    title: 'Closing',
    className: ' right-align',
    sortable: false,
  },
  { id: 11, label: 'closingBalance', title: 'Closing Value', className: ' right-align', sortable: false },
  /** { id: 6, label: 'damaged', title: 'Damaged', className: ' right-align', sortable: false },
  { id: 6, label: 'damagedValue', title: 'Damaged Value', className: ' right-align', sortable: false },
  { id: 6, label: 'expired', title: 'Expired', className: ' right-align', sortable: false },
  { id: 6, label: 'expiredValue', title: 'Expired Value', className: ' right-align', sortable: false }, */
];
const lengthyAttributes = ['title'];
const printEnums = {
  withoutFooterLimit: 41,
  withFooterLimit: 41,
  title: 41,
  lengthyAttributes,
};
const ledgerTypeViewOptions = [
  {
    id: 1,
    label: 'DLP',
    title: 'DLP',
  },
  {
    id: 2,
    label: 'FIFO',
    title: 'FIFO',
  },
  {
    id: 3,
    label: 'WEIGHTED_AVERAGE',
    title: 'Weighted Average',
  },
];


const config = {
  header: stockLedgerConfig,
  getTableBody: StockLedgerBody,
  printEnums,
  uniqueIdGetter: (i,index) => `${i.skuId}_${index}`,
  ledgerTypeViewOptions,
  DOWNLOAD_CONST,
};

export default config;
