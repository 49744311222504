import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog, DialogHeader, DialogTitle, DialogBody, DialogFooter, Button
} from '../../../../components/BillingMDC';
import { ACTION } from '../../../../data/enums/enums';

const propTypes = {
  actionType: PropTypes.string,
  onModalClose: PropTypes.func.isRequired,
  onModalSubmit: PropTypes.func.isRequired,
};

const defaultProps = { actionType: null, };

const VATDialog = ({ actionType, onModalClose, data, handleSaveClick }) => (
  <Dialog
    open={actionType !== ACTION.NULL}
    onClose={() => {
      onModalClose();
    }}
  >
    <DialogHeader>
      <DialogTitle>Confirmation</DialogTitle>
    </DialogHeader>

    <DialogBody>
      <div className='dialog-upperpart confirmation'>
        <p>Are you sure you want proceed further taking VAT percent of {data.vatPercent}?</p>
      </div>
    </DialogBody>
    <DialogFooter>
      <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
        Cancel
      </Button>
      <Button
        accent
        className='dialog-ok modal-btn'
        onClick={() => {
          handleSaveClick();
        }}
      >
        Save
      </Button>
    </DialogFooter>
  </Dialog>
);

VATDialog.propTypes = propTypes;

VATDialog.defaultProps = defaultProps;

export default VATDialog;
