/*
 * action creators
 */

import * as actionTypes from '../data/enums/actionTypes';
/*
    customerDetails is a ActionCreator, it needs to return an action
    i.e an object with a type property
 */
export const customerDetails = (details) => ({
  type: actionTypes.CUSTOMER_DETAILS,
  payload: details,
});

export const tagDetails = (details) => ({
  type: actionTypes.TAG_DETAILS,
  payload: details
})
