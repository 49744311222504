import { useMemo } from 'react';
import { fixedFloatAndCommas } from '../../../../utils/conversion';

const addSpaceToConcatText = text => text?.split(',').join(', ');

const getTableData = ledgerList => {
  let tableDataTotalOpening = 0;
  let tableDataTotalClosing = 0;
  let tableDataTotalDebit = 0;
  let tableDataTotalCredit = 0;
  let tableDataRows = [];

  if (ledgerList?.list && ledgerList.list.length > 0) {
    tableDataRows = ledgerList.list?.map((data, index) => {
      tableDataTotalOpening = tableDataTotalOpening + data.openingBalance;
      tableDataTotalClosing = tableDataTotalClosing + data.closingBalance;
      tableDataTotalDebit = tableDataTotalDebit + data.debit;
      tableDataTotalCredit = tableDataTotalCredit + data.credit;

      return [
        index + 1,
        data.name.trim() ?? '',
        // addSpaceToConcatText(data.address) ?? '',
        data.pan_no ?? '0',
        fixedFloatAndCommas(data.openingBalance) ?? '0',
        fixedFloatAndCommas(data.debit) ?? '0',
        fixedFloatAndCommas(data.credit) ?? '0',
        fixedFloatAndCommas(data.closingBalance) ?? '0',
      ];
    });
  }

  return {
    tableDataTotalOpening: fixedFloatAndCommas(tableDataTotalOpening).toString(),
    tableDataTotalClosing: fixedFloatAndCommas(tableDataTotalClosing).toString(),
    tableDataTotalDebit: fixedFloatAndCommas(tableDataTotalDebit).toString(),
    tableDataTotalCredit: fixedFloatAndCommas(tableDataTotalCredit).toString(),
    tableDataRows,
  };
};

const getFilteredData = (ledgerList, companyData, ledgerDate) => {
  const tableData = getTableData(ledgerList);

  return {
    companyName: companyData?.title ?? '',
    companyLogo: companyData?.image ?? '',
    address: companyData?.address ?? '',
    phone: companyData?.phone ?? '',
     panNo: companyData?.panNo || companyData?.panNumber || '',
    email: companyData?.email ?? '',
    reportPeriod: ledgerDate ?? '',
    tableDataRows: tableData?.tableDataRows ?? [],
    tableDataTotalOpening: tableData?.tableDataTotalOpening ?? '',
    tableDataTotalClosing: tableData?.tableDataTotalClosing ?? '',
    tableDataTotalDebit: tableData?.tableDataTotalDebit ?? '',
    tableDataTotalCredit: tableData?.tableDataTotalCredit ?? '',
  };
};

const useLedgerData = (ledgerList, companyData, ledgerDate) => {
  const ledgerData = useMemo(() => {
    return getFilteredData(ledgerList, companyData, ledgerDate);
  }, [ledgerList]);
  return ledgerData;
};

export default useLedgerData;
