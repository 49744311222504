import PropTypes from 'prop-types';
import React from 'react';
import { LinearProgress } from 'react-mdc-web';

const propTypes = { display: PropTypes.bool, };

const defaultProps = { display: false, };

const Loading = ({ display }) => {
  if (!display) {
    return null;
  }

  return (
    <div className='linear-progress-wrapper temp-progress-wrapper'>
      <LinearProgress accent indeterminate />
    </div>
  );
};

Loading.propTypes = propTypes;

Loading.defaultProps = defaultProps;

export default Loading;
