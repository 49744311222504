/**
 * Created by kAy on 9/6/17.
 */
import ReactDOM from 'react-dom';

/**
 * Function checks if the blurred target is inside the daterangepicker div or not
 * @param relatedTarget
 * @param currentTarget
 * @returns {boolean}
 */
export let focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
  if (relatedTarget === null) {
    return false;
  }

  let node = relatedTarget.parentNode;

  while (node !== null) {
    if (node === currentTarget) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
};

/**
 * This function is needed for cases like logout and dropdown toggles,
 * the div whose view is toggled need to be focused by default
 * and that focus is now blurred
 * @param relatedTarget
 * @param currentTarget
 * @param reference
 * @returns {boolean}
 */
export let focusInCurrentTargetWithReference = ({ relatedTarget, currentTarget }, reference) => {
  if (relatedTarget === null) {
    return false;
  }

  let node = relatedTarget.parentNode;

  while (node !== null) {
    if (node === currentTarget) {
      ReactDOM.findDOMNode(reference).focus();

      return true;
    }
    node = node.parentNode;
  }

  return false;
};
