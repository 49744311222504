import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';
const numberConfig = {
  /*minimumFractionDigits: 2,*/
  maximumFractionDigits: 2,
};
const CustomerLedgerDetailTableBody = ({ data, index }) => {
  return (
    <tr>
      <td className='left-align'>{index + 1}</td>
      <td className='left-align'>{moment(data.date).format('ll')}</td>
      <td className='left-align'>{data.vno}</td>
      <td className='left-align'>{data.customer.particulars}</td>
      <td className='right-align'>
        {data.hasOwnProperty('debit') ? data.debit.toLocaleString(undefined, numberConfig) : 0}
      </td>
      <td className='right-align'>
        {data.hasOwnProperty('credit') ? data.credit.toLocaleString(undefined, numberConfig) : 0}
      </td>
      <td className='right-align'>{data.balance ? data.balance.toLocaleString() : data.balance}</td>
      <td className='left-align'>{data.remarks}</td>
      <td className='left-align'>{data.refNumber}</td>
      {/* <td className="left-align">{moment(data.refDate).format('ll')}</td>*/}
    </tr>
  );
};

CustomerLedgerDetailTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const customerLedgerDetailConfig = {
  headerDetails: [
    { id: 1, label: 'index', title: 'SN', className: 'cursor-pointer', sortable: true },
    { id: 2, label: 'date', title: 'Date', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'vno', title: 'Vno', className: 'cursor-pointer', sortable: true },
    { id: 4, label: 'customer.particulars', title: 'Particulars', className: 'cursor-pointer', sortable: true },
    { id: 5, label: 'debit', title: 'Debit', className: 'cursor-pointer right-align', sortable: true },
    { id: 6, label: 'credit', title: 'Credit', className: 'cursor-pointer right-align', sortable: true },
    { id: 7, label: 'balance', title: 'Balance', className: 'cursor-pointer right-align', sortable: true },
    /*{id: 8, label: 'narration', title: 'Narration', className: 'cursor-pointer', sortable: true},*/
    { id: 9, label: 'remarks', title: 'Remarks', className: 'cursor-pointer', sortable: true },
    { id: 10, label: 'refNumber', title: 'Ref No.', className: 'cursor-pointer', sortable: true },
    /*{id: 11, label: 'refDate', title: 'Ref Date.', className: 'cursor-pointer', sortable: true},*/
  ],
};

export { customerLedgerDetailConfig, CustomerLedgerDetailTableBody };
