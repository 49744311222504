import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const DateToRender = ({ date, singleDateExist }) => {
  const startDate = moment(date.start);
  const endDate = moment(date.end);
  const today = moment(new Date());
  let dateToRender = '';
  if (startDate.isSame(endDate)) {
    if (startDate.year() == today.year() && startDate.month() == today.month() && startDate.day() == today.day()) {
      dateToRender = <span>{singleDateExist ? startDate.format('DD MMM , YYYY') : 'Today'}</span>;
    } else if (startDate.year() == today.year()) {
      dateToRender = <span> {startDate.format('MMM D')} </span>;
    } else {
      dateToRender = <span> {startDate.format('MMM D, YYYY')} </span>;
    }
  } else if (startDate.year() == endDate.year() && endDate.year() == today.year()) {
    dateToRender = (
      <span>
        {' '}
        {startDate.format('MMM D')} - {endDate.format('MMM D')}{' '}
      </span>
    );
  } else {
    dateToRender = (
      <span>
        {' '}
        {startDate.format('MMM D, YYYY')} - {endDate.format('MMM D, YYYY')}{' '}
      </span>
    );
  }
  return dateToRender;
};

DateToRender.propTypes = { date: PropTypes.object.isRequired, };

export default DateToRender;
