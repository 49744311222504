import React from 'react';
import { accountReportViewOptions } from '../../common/DrawerFilter/config';
import AccordionTable from '../../../components/CollapsibleTable/AccordionTable';
import ProfitLossChildView from '../profitLoss/childView';
import { TableBody } from '../profitLoss/config';

const BalanceSheetBody = ({ dataList, onRowClick, activeData, activeView, label }) => (
  <tbody>
    {activeView === accountReportViewOptions[0].label ? (
      <AccordionTable colSpan='2%'>
        {dataList.map((data, index) => (
          <tr
            key={`${data.accountGroupId}_${data.accountGroup}`}
            trigger={TableBody({
              data,
              onRowClick,
              label,
              index,
            })}
          >
            {ProfitLossChildView({ data: activeData })}
          </tr>
        ))}
      </AccordionTable>
    ) : (
      dataList.map((data) => (
        <tr className='collaspsible-disabled'>
          {TableBody({
            data,
            onRowClick: () => null,
          })}
        </tr>
      ))
    )}
  </tbody>
);

const BalanceSheetConfig = [
  { id: 1, className: 'left-align', label: 'particulars', title: 'Particulars' },
  { id: 2, className: 'right-align', label: 'amount', title: 'Amount' },
];

const config = {
  header: BalanceSheetConfig,
  getAssetsBody: BalanceSheetBody,
  getLiabilityBody: BalanceSheetBody,
};

export default config;
