import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Menu from '../Menu';
import BillingTextField from '../TextField/BillingTextField';
import { EMPTY } from '../../data/enums/errorMessage';
import Icon from 'react-mdc-web/lib/Icon/Icon';

const propTypes = {
  asyncMode: PropTypes.bool,
  searchText: PropTypes.string,
  dropDownUpdater: PropTypes.func,
  dropDownValueKey: PropTypes.string,
  name: PropTypes.string,
  onDropDownSelection: PropTypes.func,
  dropDownDisplayKey: PropTypes.string,
  dropDownList: PropTypes.instanceOf(Array),
  selectedDropDowns: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  enableValidation: PropTypes.bool,
  enableErrorDisplay: PropTypes.bool,
  multipleParam: PropTypes.bool,
};

const defaultProps = {
  dropDownUpdater: () => null,
  dropDownValueKey: 'id',
  dropDownDisplayKey: 'title',
  name: 'name',
  errorMessage: EMPTY,
  dropDownList: [
    {
      id: 1,
      title: 'one',
      value: 'Nepal',
    },
    {
      id: 2,
      title: 'two',
      value: 'Nepal',
    },
    {
      id: 3,
      title: 'three',
      value: 'Nepal',
    },
    {
      id: 4,
      title: 'four',
      value: 'Nepal',
    },
    {
      id: 5,
      title: 'five',
      value: 'Nepal',
    },
    {
      id: 6,
      title: 'six',
      value: 'Nepal',
    },
  ],
  searchText: '',
  asyncMode: false,
  selectedDropDowns: [],
  onDropDownSelection: () => null,
  placeholder: 'Select...',
  enableValidation: true,
  enableErrorDisplay: false,
  multipleParam: false,
};

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: props.searchText,
    };

    this.reference = {
      autoComplete: React.createRef(),
    };
  }

  handleInputChange = (value, label = 'searchText', event) => {
    const { asyncMode, dropDownUpdater } = this.props;
    this.setState({ searchText: event });

    if (asyncMode) {
      dropDownUpdater(event);
    }
  };

  getDropDownListForUserInput = () => {
    const { searchText } = this.state;
    const { selectedDropDowns, dropDownDisplayKey, dropDownList, asyncMode, dropDownValueKey } = this.props;
    const validDropDownCriteria = el => {
      const searchCriteria = el[dropDownDisplayKey].toLowerCase().includes(searchText.toLowerCase());

      const unSelectedCriteria = asyncMode
        ? selectedDropDowns.indexOf(el) === -1
        : selectedDropDowns.indexOf(el[dropDownValueKey]) === -1;

      return searchCriteria && unSelectedCriteria;
    };

    const unselectedDropDown = dropDownList.filter(validDropDownCriteria) || [];

    return unselectedDropDown;
  };

  handleCrossIconClick = menu => {
    const { selectedDropDowns, onDropDownSelection, dropDownValueKey, asyncMode, name } = this.props;

    const indexInSelectedList = selectedDropDowns.findIndex(
      el => (asyncMode ? el[dropDownValueKey] : el) === menu[dropDownValueKey],
    );

    selectedDropDowns.splice(indexInSelectedList, 1);
    onDropDownSelection(selectedDropDowns, name);
  };

  onDropDownSelection = dropDown => {
    const { selectedDropDowns, onDropDownSelection, asyncMode, dropDownValueKey, name } = this.props;

    if (asyncMode) {
      selectedDropDowns.push(dropDown);
    } else {
      selectedDropDowns.push(dropDown[dropDownValueKey]);
    }
    onDropDownSelection(selectedDropDowns, name);
  };

  getSelectedDropDowns = () => {
    const { selectedDropDowns, asyncMode, dropDownList, dropDownValueKey, dropDownDisplayKey } = this.props;

    if (asyncMode) {
      return selectedDropDowns;
    }

    const transformedDropDownList = [];

    selectedDropDowns.forEach(id => {
      const name = dropDownList.find(element => element[dropDownValueKey] === id);
      if (name) {
        transformedDropDownList.push(name);
      }
    });

    return transformedDropDownList;
  };

  render() {
    const { searchText } = this.state;
    const {
      dropDownDisplayKey,
      placeholder,
      errorMessage,
      enableValidation,
      enableErrorDisplay,
      param,
      multipleParam,
    } = this.props;
    const selectedDropDowns = this.getSelectedDropDowns();

    const updatedDropDownList = this.getDropDownListForUserInput();
    const displayError = enableValidation && enableErrorDisplay;

    const menuHeader = (
      <Fragment>
        <BillingTextField
          param={param}
          type="text"
          value={searchText}
          placeholder={placeholder}
          autoComplete="off"
          handleChange={(value, event) => this.handleInputChange(value, 'searchText', event)}
          multipleParam
        />
      </Fragment>
    );
    return (
      <Fragment>
        <Menu block header={menuHeader} hideOnClick ref={this.reference.autoComplete}>
          <div className="autocomplete-list">
            {updatedDropDownList.map(dropDown => (
              <div key={`selection-${dropDown.dropDownDisplayKey}`} onClick={() => this.onDropDownSelection(dropDown)}>
                <span>{dropDown[dropDownDisplayKey]}</span>
              </div>
            ))}
          </div>
        </Menu>
        <div className="suggested-autocomplete-list-wrap">
          {selectedDropDowns.map((item, index) => (
            <div className="suggested-autocomplete-list" key={index}>
              <span className="text">{item[dropDownDisplayKey]}</span>
              <Icon name="close" className="close" onClick={() => this.handleCrossIconClick(item)} />
            </div>
          ))}
          {displayError && selectedDropDowns && selectedDropDowns.length === 0 && (
            <span className="error-message">{errorMessage}</span>
          )}
        </div>
      </Fragment>
    );
  }
}

AutoComplete.propTypes = propTypes;

AutoComplete.defaultProps = defaultProps;

export default AutoComplete;
