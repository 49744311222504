import React, { Component } from 'react';
import Dialog from './Dialog';
import tableConfig, { createUserMapper } from './config';
import { ACTION, MOBILE_NO_VALID_DIGIT, USER_GROUPS } from '../../../data/enums/enums';
import { validateForm } from '../../common/validation';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import { USERS_CONFIG } from '../../common/domain.config';
import * as queryService from '../../common/query.service';
import * as snackService from '../../common/snack.service';
import { AddFab, BillingSnackBar } from '../../../components/BillingMDC';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: { ...queryService.queryParameters },
      dialogType: ACTION.NULL,
      data: {
        list: [],
        total: 0,
      },
      userGroupList: [],
      user: createUserMapper({}),
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
    };
  }

  /** adjust table width and height according to screen **/
  componentDidMount() {
    this.loadTableData();
    this.getUserGroupList();
    /** tableLayoutService.adjustToScreen(
     this.refs.tableReference,
     this.refs.fixedTableFooter,
     this.refs.fixedTableBody,
     ); */
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters } = this.state;
    getList(queryParameters).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.list = data.list.map(user => createUserMapper(user));
      data.total = response.total;
      this.setState({ data });
    });
  };

  getUserGroupList = () => {
    const { getUserGroupListApi } = this.props;
    const { queryParameters } = this.state;
    getUserGroupListApi(queryParameters).then(response => {
      const dataList = response.list;
      // todo:: technical debt no particular identifier for role designation
      const SUPER_ADMIN_ROLE_ID = 1;
      const updated = dataList
        .filter(item => item.idRoles !== SUPER_ADMIN_ROLE_ID)
        .map(item => {
          return { group_id: item.idRoles.toString(), name: item.name };
        });
      this.setState({ userGroupList: updated });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleEditIconClick = data => {
    this.setState({
      dialogType: ACTION.UPDATE,
      user: createUserMapper(data),
    });
  };

  handleFabButtonClick = () => {
    const { user } = this.state;
    user.name = '';
    user.email = '';
    user.password = '';
    this.setState({
      dialogType: ACTION.ADD,
      user,
    });
  };

  handleModalSubmit = () => {
    const { user, validation, dialogType } = this.state;
    if (dialogType === 'Update') {
      validation.fieldList = validation.fieldList.filter(i => i.title !== 'password');
    }
    if (user.phone_number !== '' && user.phone_number.length !== MOBILE_NO_VALID_DIGIT) {
      validation.flag = false;
      this.setState({ validation });
      return;
    }

    const formValid = validateForm(user, validation, valid => {
      this.setState({ validation: valid });
    });

    if (formValid) {
      this.handleModalClose();

      switch (dialogType) {
        case ACTION.ADD:
          this.createUser();
          break;
        case ACTION.UPDATE:
          this.editUser();
          break;
        default:
          break;
      }
      this.resetUser();
    }
  };
  resetUser = () => {
    const user = createUserMapper({});
    this.setState({ user });
  };

  createUser = () => {
    const { create } = this.props;
    const { user, data } = this.state;
    const userForRequest = {
      email: user.email,
      password: user.password,
      group_id: user.group_id,
      name: user.name.replace(/\s+/g, ' ').trim(),
      phone_number:user.phone_number,
      status: user.status
    };
    create(userForRequest)
      .then(response => {
        data.list.push(createUserMapper(response.user));
        data.list.total += 1; // (data.list.total + 1);
        const snack = snackService.generateSuccessMessage();
        this.setState({
          data,
          snack,
        });
      })
      .catch((err) => {
        const snack = snackService.generateFailureMessage(err);
        this.setState({
          data,
          snack,
        });
      });
  };

  editUser = () => {
    const { update } = this.props;
    const { user, data } = this.state;
    update({
      id: user.idUsers,
      query: {
        name: user.name.replace(/\s+/g, ' ').trim(),
        email: user.email,
        group_id: user.group_id,
        phone_number: user.phone_number,
        status: user.status,
      },
    }).then((res) => {
      data.list = data.list.map((item) =>
        item.idUsers === Number(res.user.idUsers) ? res.user : item
      );
      const snack = snackService.generateUpdateMessage();
      this.setState({
        data,
        snack,
      });
      this.loadTableData();
    }).catch((err) => {
      const snack = snackService.generateFailureMessage(err);
      this.setState({
        snack
      });
    });
  };

  handleModalClose = () => {
    const { validation } = this.state;
    validation.flag = false;
    this.resetUser();
    this.setState({ dialogType: ACTION.NULL });
  };

  handleInputChange = (field, value) => {
    const { user } = this.state;
    user[field] = value;
    this.setState({ user });
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { data, display, snack, dialogType, user, validation, userGroupList } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <div className="users">
        <PageView
          domainConfig={USERS_CONFIG}
          headerConfig={{
            search: false,
            date: false,
            download: false,
            filter: false,
            create: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          data={data}
          tableConfig={tableConfig}
          onTableBodyClick={this.handleEditIconClick}
          createHandler={this.handleFabButtonClick}
          userGroupList={userGroupList}
        />

        {/* <AddFab handleFabClick={this.handleFabButtonClick}/> */}

        <Dialog
          actionType={dialogType}
          onModalSubmit={this.handleModalSubmit}
          onModalClose={this.handleModalClose}
          onInputChange={this.handleInputChange}
          user={user}
          validation={validation}
          userGroupList={userGroupList}
          userStatus={tableConfig.userStatus}
        />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </div>
    );
  }
}

const UsersWithState = withBaseState(Users);

export default UsersWithState;
