import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const SalesInvoiceTableBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <tr className='cursor-pointer' onClick={() => onRowClick(data)}>
        <td className='left-align'>{moment(data.date).format('D MMM YYYY')}</td>
        <td className='left-align'>{data.mitiTitle}</td>
        <td className='left-align'>{data.invoiceNumber}</td>
        <td className='left-align'>{data.customerDetail.title}</td>
        <td className='left-align'>{data.customerDetail.address}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.netAmount)}</td>
      </tr>
    ))}
  </tbody>
);

SalesInvoiceTableBody.propTypes = {
  dataList: PropTypes.instanceOf(Array).isRequired,
  onRowClick: PropTypes.func,
};

const salesInvoiceHeader = {
  headerDetails: [
    {
      id: 1,
      label: 'date',
      title: 'Date',
      className: 'cursor-pointer',
      sortable: true,
    },
    {
      id: 2,
      label: 'miti_title',
      title: 'Miti',
      className: 'cursor-pointer',
      sortable: true,
    },
    { id: 3, label: 'invoice_number', title: 'Invoice Number', className: 'cursor-pointer left-align', sortable: true },
    { id: 4, label: 'ledgers.title', title: 'Customer Name', className: 'cursor-pointer', sortable: true },
    { id: 5, label: 'ledgers.address', title: 'Address', className: 'cursor-pointer', sortable: true },
    { id: 6, label: 'net_amount', title: 'Net Amount', className: 'cursor-pointer right-align', sortable: true },
  ],
};

export { salesInvoiceHeader, SalesInvoiceTableBody };
