import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';

import BillingTextField from '../../../components/TextField/BillingTextField';
import {
  ACTION,
  MOBILE_NO_VALID_DIGIT,
  PASSWORD_CRITERIA_MSG,
  PASSWORD_RULE,
  USER_GROUPS,
} from '../../../data/enums/enums';
import BillingSelect from '../../../components/Select/BillingSelect';
import { MOBILE_NO_DIGITS_ERROR } from '../../../data/enums/errorMessage';

const BusinessGroup = ({
  user,
  actionType,
  onModalClose,
  onModalSubmit,
  onInputChange, // onChange
  validation,
  userGroupList,
  userStatus,
}) => {
  const actionTypeUpdate = actionType === ACTION.UPDATE;
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>{actionType}</DialogTitle>
      </DialogHeader>

      <DialogBody>
        <div className='dialog-upperpart'>
          <Grid className='grid-padding'>
            <Cell col={6} tablet={12}>
              <BillingTextField
                value={user.name}
                handleChange={onInputChange}
                floatingLabel='Name'
                param='name'
                required={true}
                className='right-side-gap'
                emptyField={validation.flag}
              />
            </Cell>

            <Cell col={6} tablet={12}>
              <label>User Group</label>
              <BillingSelect
                name='form-field-name'
                param='group_id'
                clearable={false}
                value={user.group_id}
                labelKey='name'
                valueKey='group_id'
                options={userGroupList}
                handleChange={onInputChange}
                required
                emptyField={validation.flag}
                floatingLabel='User Group'
              />
            </Cell>
          </Grid>
          <Grid className='grid-padding'>
            <Cell col={6} tablet={12}>
              <BillingTextField
                value={user.email}
                handleChange={onInputChange}
                floatingLabel='Email'
                param='email'
                required={true}
                className='right-side-gap'
                emptyField={validation.flag}
              />
            </Cell>

            {!actionTypeUpdate ? (
              <Cell col={6} tablet={12} className='password'>
                <BillingTextField
                  value={user.password}
                  type='password'
                  handleChange={onInputChange}
                  floatingLabel='Password'
                  param='password'
                  required={true}
                  className='right-side-gap'
                  emptyField={validation.flag === true}
                  pattern={PASSWORD_RULE}
                />
                {validation.flag === true && user.password ? (
                  <p className='pw_criteria'>{PASSWORD_CRITERIA_MSG}</p>
                ) : null}
              </Cell>
            ) : null}
          </Grid>
          <Grid className='grid-padding'>
            <Cell col={6} tablet={12}>
              <BillingTextField
                value={user.phone_number}
                handleChange={onInputChange}
                floatingLabel='Phone No.'
                param='phone_number'
                required={false}
                emptyField={true}
                className='right-side-gap'
                errorMessage={
                  user.phone_number && user.phone_number.toString().length !== MOBILE_NO_VALID_DIGIT
                    ? MOBILE_NO_DIGITS_ERROR
                    : 'Should be 10 digits'
                }
                invalidError={user.phone_number && user.phone_number.toString().length !== MOBILE_NO_VALID_DIGIT}
              />
            </Cell>

            <Cell col={6} tablet={12}>
              <label>Status</label>
              <BillingSelect
                name='form-field-name'
                param='status'
                clearable={false}
                value={user.status}
                labelKey='name'
                valueKey='status'
                options={userStatus}
                handleChange={onInputChange}
                required
                emptyField={validation.flag}
                floatingLabel={'Status'}
              />
            </Cell>
          </Grid>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default BusinessGroup;
