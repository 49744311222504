import React, { Component } from 'react';
import tableConfig from './config';
import history from '../../../../utils/history';
import { ACTION } from '../../../../data/enums/enums';
import UserGroupStyled from '../UserGroupStyled';
import { validateForm } from '../../../common/validation';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
import { USER_GROUP } from '../../../../data/enums/Route';
import { EMPTY } from '../../../../data/enums/errorMessage';
import * as queryService from '../../../common/query.service';
import * as snackService from '../../../common/snack.service';
import TableView from '../../../common/pagination/TableView';
import { Button, Icon } from '../../../../components/BillingMDC';
import { BillingSnackBar } from '../../../../components/BillingMDC';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import PageHeaderStyled from '../../../common/pagination/PageHeaderStyled';
import HorizontalLabelValueBar from '../../../common/horizontalLabellValueBar';

class UserGroupDetails extends Component {
  constructor(props) {
    super(props);
    const id = props.match.params.id ? parseInt(props.match.params.id) : 0;
    this.state = {
      display: {
        searchBox: false,
        filter: false,
      },
      id,
      crudMode: ACTION.READ,
      snack: { ...snackService.snackParameters },
      queryParameters: {
        search: queryService.queryParameters.search,
        sort: queryService.queryParameters.sort,
        pagination: queryService.queryParameters.pagination,
      },
      userGroupAccordion: [],
      data: {
        details: {
          name: '',
        },
        list: [],
      },
      tempName: '',
      validation: {
        flag: false,
        fieldList: [{ title: 'name' }],
      },
      activeTab: 1,
    };

    this.reference = {
      tableHeader: React.createRef(),
      tableFooter: React.createRef(),
      tableBody: React.createRef(),
      fileRef: React.createRef(),
    };
    this.basePaginationService = new queryService.QueryClass(this.setQueryParameters, this.getQueryParameters);
    this.basePaginationService.resetFilter();
  }
  componentDidMount() {
    const { id } = this.state;
    if (id) this.getUGDetails(id);
  }
  setQueryParameters = (queryParameters, callBack = () => null) => {
    return this.setState({ queryParameters }, callBack);
  };
  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  getUGDetails = id => {
    const { getDetails } = this.props;
    const { data } = this.state;
    getDetails(id).then(response => {
      data.details = response.details;
      data.list = response.permissions;
      const temp = response.details.name;
      this.setState({ data, tempName: temp });
    });
  };
  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  handleSaveClick = () => {
    const { data, validation } = this.state;
    const formValid = validateForm(data.details, validation, valid => this.setState({ validation: valid }));
    if (formValid) {
      this.updateDescription();
      this.handleCancelClick();
    }
  };
  updateDescription = () => {
    const { id, data } = this.state;
    const { updateDescription } = this.props;
    const queryData = {
      name: data.details.name,
      description: data.details.name,
    };
    updateDescription({
      id: id,
      query: queryData,
    })
      .then(response => {
        const snack = snackService.generateUpdateMessage();
        this.setState({ snack });
        setTimeout(() => {
          this.getBackToUGList();
        }, 1000);
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({ snack });
      });
  };
  handleCancelClick = () => {
    const { validation, data, tempName } = this.state;
    validation.flag = false;
    this.setState({
      crudMode: ACTION.READ,
      validation,
      data: { ...data, details: { ...data.details, name: tempName } },
    });
  };

  updateCrudMode = crudMode => {
    this.setState({ crudMode });
  };
  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  changePermissionStatus = data => {
    const { id } = this.state;
    const { updatePermission } = this.props;
    const childNotExist = data.is_child_exists === false;
    const permissionId = childNotExist ? data.child[0].id : data.id;
    const switchStatus = childNotExist ? data.child[0].status : data.status;
    const queryData = {
      permission_id: permissionId,
      status: switchStatus,
    };
    updatePermission({
      id: id,
      query: queryData,
    })
      .then(response => {
        const snack = snackService.generateUpdateMessage();
        this.setState({ snack });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage();
        this.setState({ snack });
      });
  };

  handleInputChange = (param, value, elementData, parentIndex, childIndex) => {
    const { data } = this.state;
    const childNotExist = (elementData && elementData.is_child_exists === false) || false;
    if (param === 'name') {
      data.details[param] = value;
    }
    if (param === 'status') {
      if (childNotExist) {
        elementData.child[0].status = value;
        data.list[parentIndex].child[0].status = value;
      } else {
        elementData[param] = value;
        data.list[parentIndex].child[childIndex].status = value;
      }
      this.changePermissionStatus(elementData);
    }
    this.setState({ data });
  };

  handleTabChange = tab => {
    this.setState({
      activeTab: tab,
    });
    this.handleCancelClick();
  };
  getBackToUGList = () => {
    history.push(`/${USER_GROUP}`);
  };

  handleRowClick = data => {
    this.setState({
      userGroupAccordion: data.child,
    });
  };

  render() {
    const { snack, data, display, validation, activeTab, crudMode, userGroupAccordion } = this.state;
    const { serverResponseWaiting } = this.props;
    const crudModeRead = crudMode === ACTION.READ;
    return (
      <UserGroupStyled className="user-group__details">
        <PageHeaderStyled>
          <div className="card-header-bar">
            <h2>
              <span>
                {' '}
                <Icon name="keyboard_backspace" className="menu-icon" onClick={this.getBackToUGList} />
              </span>
              General
            </h2>
            <div className="header-menu">
              {crudModeRead && activeTab === 0 && (
                <Icon name="edit" onClick={() => this.updateCrudMode(ACTION.UPDATE)} />
              )}
            </div>
          </div>
        </PageHeaderStyled>
        <div className="tab-section">
          <Tabs selectedIndex={activeTab} onSelect={tabIndex => this.handleTabChange(tabIndex)}>
            <TabList>
              <Tab>Details</Tab>
              <Tab>Permission</Tab>
            </TabList>
            <TabPanel>
              <section id="detail-tab" className={`detail-tab-section ${crudModeRead && 'not-allowed-cursor read'}`}>
                <div className="form-wrap" style={{ pointerEvents: crudModeRead ? 'none' : 'auto' }}>
                  <h6>General Setting</h6>
                  <div className="form-inputs">
                    <HorizontalLabelValueBar
                      label="Title"
                      value={
                        <BillingTextField
                          value={data.details.name}
                          handleChange={this.handleInputChange}
                          param="name"
                          required
                          type="text"
                          className=""
                          emptyField={validation.flag}
                          errorMessage={EMPTY}
                        />
                      }
                    />
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section id="permission-tab" className="permission-tab-section">
                <TableView
                  display={display}
                  onTableBodyClick={this.handleRowClick}
                  data={data}
                  config={tableConfig}
                  serverResponseWaiting={serverResponseWaiting}
                  onTableClickData={userGroupAccordion}
                  btnDisplay={false}
                  footerConfig={{
                    pagination: false,
                  }}
                  handleInputChange={this.handleInputChange}
                />
              </section>
            </TabPanel>
          </Tabs>
        </div>
        <>
          {!crudModeRead && activeTab === 0 && (
            <div className="btns">
              <Button accent className="dialog-cancel cancel-btn modal-btn" onClick={() => this.handleCancelClick()}>
                Cancel
              </Button>
              <Button
                accent
                className="save-btn modal-btn"
                onClick={() => {
                  this.handleSaveClick();
                }}
              >
                Save
              </Button>
            </div>
          )}
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </>
      </UserGroupStyled>
    );
  }
}

export default UserGroupDetails;
