import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const SKUTableBody = ({ data }) => {
  return (
    <tr>
      <td className='left-align'>{data.businessSkuId}</td>
      <td className='left-align'>{data.title}</td>
      <td className='left-align'>{data.upc}</td>
    </tr>
  );
};

SKUTableBody.propTypes = { data: PropTypes.object.isRequired, };

const skuConfig = {
  headerDetails: [
    { id: 1, label: 'businessSkuId', title: 'SKU ID', className: 'cursor-pointer left-align', sortable: true },
    { id: 2, label: 'sku', title: 'SKU', className: 'cursor-pointer', sortable: true },
    { id: 3, label: 'upc', title: 'UPC', className: 'cursor-pointer', sortable: true },
  ],
};

const message = {
  success: 'SKU uploaded successfully.',
  error: '!Error while uploading SKU',
  downloadError: '!Error while downloading sample template',
};

const downloadFormat = [{ id: 1, value: 'Download SKU upload template' }];

export {
  downloadFormat, message, skuConfig, SKUTableBody
};
