import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
  Checkbox,
} from 'react-mdc-web';
import { CreateSalesReturnTableBody } from '../SRN/CreateSRNConfig';
import { fixedFloatAndCommas } from '../../../utils/conversion';

class CreatePurchaseReturnTableBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkPrimary: false,
      selectedCheckBox: [0, 1, 2, 3],
    };
  }
  render() {
    const { data, index, selectedCheckBox, showCheckBox } = this.props;
    return (
      <tr>
        <td>
          {!showCheckBox && <span>{index + 1}</span>}
          {showCheckBox && (
            <Checkbox
              checked={selectedCheckBox.includes(data.idPurchaseDetail)}
              onChange={({ target: { checked } }) => {
                this.props.handleCheckBoxClick(data.idPurchaseDetail, checked);
              }}
            />
          )}
        </td>
        <td>{data.skuTitle}</td>
        <td className='right-align'>{data.quantity}</td>
        <td className='right-align'>{data.rate}</td>
        <td className='right-align'>{data.amount}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.discount)}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.net_amount)}</td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => this.props.handleEditIconClick(data, index)} />
          <Icon name='delete' onClick={() => this.props.handleDeleteIconClick(index)} />
        </td>
      </tr>
    );
  }
}

CreatePurchaseReturnTableBody.defaultProps = {
  selectedCheckBox: [],
  showCheckBox: false,
  enableUpdate: true,
};

CreatePurchaseReturnTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteIconClick: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  selectedCheckBox: PropTypes.array,
  handleCheckBoxClick: PropTypes.func,
  showCheckBox: PropTypes.bool,
  enableUpdate: PropTypes.bool,
};

const createPurchaseReturnConfig = {
  headerDetails: [
    { id: 1, label: 'test', title: 'SN', className: null, sortable: false },
    { id: 2, label: 'sku', title: 'SKU', className: null, sortable: false },
    { id: 3, label: 'quantity', title: 'Quantity (pcs)', className: 'right-align', sortable: false },
    { id: 4, label: 'rate', title: 'Rate', className: 'right-align', sortable: false },
    { id: 5, label: 'amount', title: 'Amount', className: 'right-align', sortable: false },
    { id: 6, label: 'discount', title: 'Discount', className: 'right-align', sortable: false },
    { id: 7, label: 'net_amount', title: 'Net Amount', className: 'right-align', sortable: false },
    { id: 8, label: 'test', title: '', className: 'right-align', sortable: false },
  ],
};

export { createPurchaseReturnConfig, CreatePurchaseReturnTableBody };
