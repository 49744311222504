import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';
import BillingSelect from '../../../components/Select/BillingSelect';
import { ACTION } from '../../../data/enums/enums';
import SIReportStyled from './SIReportStyled';

const SKUBatchDialog = ({
  upload,
  actionType,
  onModalClose,
  onModalSubmit,
  onInputChange, // onChange
  handleFileUpload,
  fileRef,
  validation,
  csvHeaderOptions,
  title,
}) => (
  <SIReportStyled>
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>Upload</DialogTitle>
      </DialogHeader>

      <DialogBody>
        <div className='dialog-upperpart'>
          <Grid className='grid-padding upload'>
            <Cell col={12} tablet={12} className='file-upload'>
              <input
                type='file'
                name='resume'
                accept='.csv'
                className='input-file-upload'
                ref={(ref) => (fileRef = ref)}
                onChange={(e) => handleFileUpload(e)}
                id='myFile'
              />
            </Cell>
            <Cell col={6} tablet={6}>
              <label>{`${title} Invoice Number`}</label>
              <BillingSelect
                name='invoice_number'
                param='invoice_number'
                clearable={false}
                value={upload.invoice_number}
                valueKey='title'
                labelKey='title'
                options={csvHeaderOptions}
                onChange={(e) => onInputChange('invoice_number', e.title)}
                required
                emptyField={validation.flag}
              />
            </Cell>
            <Cell col={6} tablet={6}>
              <label>Customer Name</label>
              <BillingSelect
                name='customer_name'
                param='customer_name'
                clearable={false}
                value={upload.customer_name}
                valueKey='title'
                labelKey='title'
                options={csvHeaderOptions}
                onChange={(e) => onInputChange('customer_name', e.title)}
                required
                emptyField={validation.flag}
              />
            </Cell>
            <Cell col={4} tablet={4} className='date-picker'>
              <label>Date</label>
              <BillingSelect
                name='date'
                param='date'
                clearable={false}
                value={upload.date}
                valueKey='title'
                labelKey='title'
                options={csvHeaderOptions}
                onChange={(e) => onInputChange('date', e.title)}
                required
                emptyField={validation.flag}
              />
            </Cell>
          </Grid>
          {csvHeaderOptions.length > 0 && <p className='note'>*Please map above fields according to your document </p>}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          onClick={() => {
            onModalSubmit();
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  </SIReportStyled>
);

export default SKUBatchDialog;
