import useComplexPrintPdfContent from './useComplexPrintPdfContent';

const ComplexPrint = ({ configData, printData, orientation, variation, openOnNewTab }) => {
  // these enums are not neccessary, I only used it to tell others what properties are available
  const orientationEnums = {
    portrait: true,
    landscape: true,
  };
  const variationEnums = {
    normal: true,
    multipleFooter: true,
  };

  const orientationValue =
    orientationEnums.hasOwnProperty(orientation) && orientationEnums[orientation] ? orientation : 'portrait';
  const variationValue = variationEnums.hasOwnProperty(variation) && variationEnums[variation] ? variation : 'normal';

  const pdfUri = useComplexPrintPdfContent(configData, printData, orientationValue, variationValue, openOnNewTab);

// useEffect(() => {
//   return () => {
//     openOnNewTab && window.close();
//   };
// }, [printData]);

  return <>{pdfUri && <iframe src={pdfUri} />}</>;
};
export default ComplexPrint;
