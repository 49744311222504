import { compose } from 'recompose';

import Base from './Base';

import apiInterceptor from '../../api/interceptor';
import { databaseApi } from '../common/base.api';

const composedBase = compose(apiInterceptor({ downloadDatabase: databaseApi.download }))(Base);

export default composedBase;
