import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import Header from './Header';
import tableConfig from './config';
import Loading from '../../../common/Loading';
import * as filterUtil from '../../../utils/filterUtil';
import DatePicker from '../../../components/DatePicker';
import withBaseState from '../../common/withBaseState';
import DrawerWithFilter from '../../common/DrawerFilter';
import TableView from '../../common/pagination/TableView';
import * as queryService from '../../common/query.service';
import * as downloadService from '../../common/download.service';
import { STOCK_AGEING_REPORT_CONFIG } from '../../common/domain.config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getList: PropTypes.func.isRequired,
  downloadList: PropTypes.func.isRequired,
};
const defaultProps = {
  serverResponseWaiting: false,
  getList: () => null,
  downloadList: () => null,
};
class StockAgeingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        drawer: true,
      },
      queryParameters: {
        ...queryService.queryParameters,
        date: null,
        singleDate: queryService.queryParameters.date.end,
      },
      data: {
        list: [],
        total: 0,
      },
      slabValue: 2,
      reportDate: filterUtil.getCurrentDay(),
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);
  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getList } = this.props;
    const { queryParameters, slabValue, reportDate } = this.state;
    const numericDate = filterUtil.formatToNumericDate(queryParameters.singleDate);
    const { singleDate, ...query } = queryParameters;
    const newQuery = {
      ...query,
      date: { start: queryParameters.singleDate, end: queryParameters.singleDate },
    };
    getList({
      query: newQuery,
      extraQueryString: `&slab_value=${slabValue}&report_date=${numericDate}`,
    }).then(response => {
      const { data } = this.state;
      data.list = response.list;
      data.total = response.total ? response.total : response.list.length;
      this.setState({ data });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  onSlabChange = (filed, slab) => {
    this.setState({ slabValue: slab.value }, () => this.loadTableData());
  };

  handleDateChange = date => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = 1;
    const reportDate = date.format('D MMM YYYY');
    this.setState(
      {
        queryParameters,
        reportDate,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  handleTableSorting = (labelName, sortingOrder) => {
    const { queryParameters } = this.state;
    queryParameters.sort.label = labelName;
    queryParameters.sort.order = sortingOrder;
    this.setState({ queryParameters }, () => this.loadTableData());
  };

  handleDownloadClick = reportType => {
    const queryParameter = this.basePaginationService.stateGetter();
    const { downloadList } = this.props;
    const { reportDate, slabValue } = this.state;
    downloadList({
      type: reportType,
      query: queryParameter,
      extraQueryString: `&report_date=${reportDate}&slab_value=${slabValue}`,
    }).then(response => downloadService.resolver(response));
  };

  render() {
    const { data, display, slabValue, reportDate, queryParameters } = this.state;
    const { serverResponseWaiting } = this.props;
    tableConfig.header = tableConfig.headerGenerator(slabValue);
    return (
      <div className="stock-ageing-report ageing-report">
        <Loading display={serverResponseWaiting} />
        <Header
          domainConfig={STOCK_AGEING_REPORT_CONFIG}
          slabValue={slabValue}
          reportDate={reportDate}
          onSlabChange={this.onSlabChange}
          queryParameters={queryParameters}
          display={display}
          controlDisplay={this.controlDisplay}
          slabList={tableConfig.slabInterval}
          onDrawerIconClick={this.controlDisplay}
          onDownloadClick={this.handleDownloadClick}
          clearSearchText={this.basePaginationService.clearSearchText}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
        />
        <TableView
          config={tableConfig}
          data={data}
          display={display}
          pagination={queryParameters.pagination}
          serverResponseWaiting={serverResponseWaiting}
          onPageSelect={this.basePaginationService.onPageSelect}
          handleTableSorting={this.handleTableSorting}
          btnDisplay={false}
        />
        <DrawerWithFilter
          display={display.filter}
          queryParameters={queryParameters}
          displayController={this.controlDisplay}
          resetFilter={this.basePaginationService.resetFilter}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          onDateChange={this.basePaginationService.handleDateChange}
          disableStartDate={true}
        >
          <div className="padding-top-24">
            <div>
              <DatePicker
                date={moment(reportDate)}
                showLabel
                showIcon
                label="As on Date"
                onChange={this.handleDateChange}
              />
            </div>
          </div>
        </DrawerWithFilter>
      </div>
    );
  }
}
StockAgeingReport.protoTypes = propTypes;
StockAgeingReport.defaultProps = defaultProps;

const StockAgeingReportWithState = withBaseState(StockAgeingReport);

export default StockAgeingReportWithState;
