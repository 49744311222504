/**
 * Created by kAy on 8/13/17.
 */
import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { focusInCurrentTargetWithReference } from './../../utils/focusOutside';

const propTypes = {
  name: PropTypes.string,
  div: PropTypes.object,
};

class CustomPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false,
      style: {
        style: {},
        arrowStyle: {
          display: 'none',
        },
      },
    };
  }

  componentWillMount() {
    this.setState({
      name: this.props.name,
      div: this.props.div,
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      name: props.name,
      div: props.div,
    });
  }

  onBlur = e => {
    /* if (!focusInCurrentTargetWithReference(e, ReactDOM.findDOMNode(this.refs.toolTip))) {
      this.setState({ showStatus: false });
    }*/
  };

  /**
   * This function sets the focus to the opened div by default
   */
  clicked = () => {
    this.setState({ showStatus: !this.state.showStatus }, () => {
      ReactDOM.findDOMNode(this.refs.toolTip).focus();
    });
  };

  render() {
    const { name, div } = this.state;
    const idName = name.replace(/ /g, '');
    return (
      <span>
        <span className="material-icons" id={`${idName}`} onClick={() => this.clicked()}>
          {name}
        </span>
        {this.state.showStatus ? (
          <div>
            {/* <ToolTip active={true} position="bottom" parent={`#${idName}`} style={this.state.style}>
              <div className="popup-wrapper" tabIndex="1" onBlur={this.onBlur} ref="toolTip">
                {div}
              </div>
            </ToolTip>*/}
          </div>
        ) : null}
      </span>
    );
  }
}

CustomPopUp.propTypes = propTypes;

export default CustomPopUp;
