import styled from 'styled-components';

const StockJournalStyled = styled.div`
.stock-journal-dialog{
.dialog-upperpart{
  .row_two{
    .mdc-layout-grid__cell{
      span:last-child{
        font-size:11px;
        opacity:0.7;
      }
    }
  }
}
footer{
      border-top:1px solid rgb(238, 238, 238);
    }
}
@media print {
 .stock_journal_print{
  .print-body{
    height: 100% !important;
    page-break-after: always;
   .fixed-table-wrapper{
    height: 1450px !important;
    table{
      tbody{
        td{
          width:70px !important;
        }
        .remark,
        .batch,
        .bu{
          width:120px !important;
          white-space:normal !important;
          line-height:18px !important;
        }
       .sku{
          width:150px !important;
          white-space:normal !important;
          line-height:18px !important;
        }
      }
    }
  }
}
 }
}
@media print and (max-width: 8.27in){

}
@media print and (max-width:5.83in) {
  .stock_journal_print{
  .print-body{
    /* height: 1565px !important; */
  }
}
}
  @media print {
    .stock_journal_print {
      .print-body {
        height: 100% !important;
        padding:0 !important;
        margin:0.28in 0.3in !important;
        page-break-after: always;
        .fixed-table-wrapper {
          height: 1420px !important;
          table {
            height:100%;
            tbody {
              td {
                width: 70px !important;
              }
              .remark,
              .batch,
              .bu {
                width: 120px !important;
                white-space: normal !important;
                line-height: 18px !important;
              }
              .sku {
                width: 150px !important;
                white-space: normal !important;
                line-height: 18px !important;
              }
            }
          }
        }
        .footer-block{
            position:unset;
          }
      }
    }
  }
  @media print and (max-width: 8.27in) {
  }
  @media print and (max-width: 5.83in) {
    .stock_journal_print {
      .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
          height:1405px !important;
        }
      }
      header{
        .mdc-layout-grid__inner{
          display:inline-block!important;
          width:100%;
        }
        .mdc-layout-grid__cell{
          width:33.33%;
         display:inline-block;
         vertical-align: text-top;
         &:nth-child(2){
           h2{
            text-align:center;
            font-size:18px;
           }
           h5{
            text-align:center;
            font-size:14px;
            font-weight:400;
           }
         }
        }
      }
    }
  }
`;
export default StockJournalStyled;
