import { compose } from 'recompose';

import apiInterceptor from '../../../api/interceptor';

import { loginApi } from '../../common/base.api';

import ResetPassword from './ResetPassword';

const composedResetPW = compose(apiInterceptor({ ...loginApi }))(ResetPassword);

export default composedResetPW;
