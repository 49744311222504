import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';
import '../App.css';

import { SORTING } from '../data/enums/enums';

class SortingElement extends Component {
  render() {
    const { sortingOrder, handleClick, labelName } = this.props;
    return (
      <span className='sorting-arrow' onClick={() => handleClick(labelName)}>
        {sortingOrder !== SORTING.NO_SORT ? (
          <span>
            {sortingOrder === SORTING.ASC ? (
              <Icon name='arrow_upward' />
            ) : (
              <span>
                <Icon name='arrow_downward' />
              </span>
            )}
          </span>
        ) : null}
      </span>
    );
  }
}

SortingElement.propTypes = {
  labelName: PropTypes.string,
  sortingOrder: PropTypes.string,
  handleClick: PropTypes.func,
};

export default SortingElement;
