import ProptTypes from 'prop-types';
import React from 'react';
import { Fab, Icon } from 'react-mdc-web';

const propTypes = { handleFabClick: ProptTypes.func, };

const defaultProps = { handleFabClick: () => null, };

const AdditionFab = ({ handleFabClick }) => (
  <div className='mini-fab-button'>
    <Fab mini onClick={() => handleFabClick()}>
      <Icon name='add' />
    </Fab>
  </div>
);

AdditionFab.propTypes = propTypes;

AdditionFab.defaultProps = defaultProps;

export default AdditionFab;
