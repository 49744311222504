import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Fab,
  Dialog,
  DialogHeader,
  DialogBody,
  Textfield,
  DialogFooter,
  DialogTitle,
  Button,
  Grid,
  Cell,
} from 'react-mdc-web';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { RATE_WITH_VAT_FACTOR } from '../../../data/enums/enums';

class SalesInvoiceDetailTableBody extends Component {

  getUOM =(list) =>list?.uom_list?.find((a) =>a?.id ===list?.uomId);

  render() {
    const { data, index, printInfoBill, isAbbreviatedInvoice } = this.props;
    const item = this.getUOM(data);
    return (
      <tr>
        <td className='srn'>{data.index ? data.index + 1 : index + 1}</td>
        <td className='hide-overflow-text sku'>{data.sku}</td>
        <td className='right-align quantity'>{(data.quantity/item.pcs)|| data.quantity}</td>
        <td className='batch right-align'>{item.label || 'Pcs'}</td>
        <td className='right-align rate'>
          {isAbbreviatedInvoice
              ? fixedFloatAndCommas(data.uomRate * data.exciseFactor * (1 + data.vatPercent / 100))
              : fixedFloatAndCommas(data.uomRate)}
        </td>
        <td className='right-align amount'>{fixedFloatAndCommas(data.amount)}</td>
        <td className='right-align discount'>{fixedFloatAndCommas(data.promotionDiscount)}</td>
        <td className='right-align netAmount'>{fixedFloatAndCommas(data.netAmount)}</td>
      </tr>
    );
  }
}

SalesInvoiceDetailTableBody.defaultProps = { enableUpdate: true, };

SalesInvoiceDetailTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteIconClick: PropTypes.func,
  handleEditIconClick: PropTypes.func,
  enableUpdate: PropTypes.bool,
};

const salesInvoiceDetailConfig = {
  headerDetails: [
    { id: 1, label: 'test', title: 'SN', className: null, sortable: false },
    { id: 2, label: 'sku', title: 'SKU', className: null, sortable: false },
    {
      id: 3,
      label: 'quantity',
      title: 'Quantity',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 4,
      label: 'rate',
      title: 'Rate',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 5,
      label: 'amount',
      title: 'Amount',
      className: 'right-align',
      sortable: false,
    },
    {
      id: 6,
      label: 'discount',
      title: 'Discount',
      className: ' right-align',
      sortable: false,
    },
    {
      id: 7,
      label: 'net_amount',
      title: 'Net Amount',
      className: 'right-align',
      sortable: false,
    },
  ],
};

export { salesInvoiceDetailConfig, SalesInvoiceDetailTableBody };
