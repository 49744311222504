import React from '../../../../node_modules/react';
import PropTypes from '../../../../node_modules/prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const SRNVatReportTableBody = ({ dataSet = [],remainingLines,columnTotal ={},footer =false ,printFlag = false, onTableBodyClick }) => {
  return(
    <>
      {
        dataSet.map((data) => (
          <tr className='cursor-pointer'>
            <td className='left-align'>{data.miti_title}</td>
            <td className='left-align bill-no link-voucher' onClick={() => onTableBodyClick(data)}>{data.bill_no}</td>
            <td className='left-align print_display_none'>{data.ref_number}</td>
            <td className='left-align print_display_none'>{data.sales_invoice_miti_title}</td>
            <td className='left-align ledger-name'>{data.buyer_name}</td>
            <td className='left-align'>{data.buyer_pan_no}</td>
            <td className='left-align'>{data.business_alias || 'Miscellaneous'}</td>
            <td className='right-align'>{data.quantity || 0}</td>
            <td className='right-align'>{data.tax_exempted_amount ? fixedFloatAndCommas(data.tax_exempted_amount) : 0}</td>
            <td className='right-align'>{data.total_taxable_sales ? fixedFloatAndCommas(data.total_taxable_sales) : 0}</td>
            <td className='right-align'>
              {data.taxable_sales_vat ? fixedFloatAndCommas(data.taxable_sales_vat) : data.taxable_sales_vat}
            </td>
            { JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise &&  <td className='right-align'>
        {data.excise ? fixedFloatAndCommas(data.excise) : data.excise}
      </td>}
          </tr>
        ))
      }
      { footer &&
  <Total columnTotal ={columnTotal} printFlag ={printFlag}/>
      }
      {remainingLines && remainingLines.length
        ? remainingLines.map((i) => (
          <tr className='blank-tr'>
            <td></td>
            <td colSpan ='14' className=''></td>
          </tr>
        ))
        : null}
    </>
  )
};

SRNVatReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const Total = ({ columnTotal = {},printFlag=false }) => (
  <tr className='total'>
    <td colSpan={printFlag ? '2' : '4'} />
    <td className='left-align font-bold pad-l-10' colSpan={3}>
    Total
    </td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.totalQuantity) || 0}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.taxExemptedSalesReturn)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.totalTaxableAmount)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.vat)}</td>
    { JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise &&  <td className='right-align font-bold'>
        {columnTotal.excise ? fixedFloatAndCommas(columnTotal.excise) : columnTotal.total_excise}
      </td>}
  </tr>
)
const SVRTableFooter = ({ columnTotal = {}, colSpanTotal = 6, printFlag = false }) => (
  <tfoot className='right-content-footer'>
    <Total columnTotal ={columnTotal} printFlag ={printFlag}/>
  </tfoot>
);

const SRNVatReportConfig = {
  headerDetails: [
    {
      id: 2,
      label: 'miti_title',
      title: 'SRN Miti',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 3,
      label: 'sales_return_invoice_number',
      title: 'SRN Number',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 3,
      label: 'bill_no',
      title: 'Ref Number',
      className: 'cursor-pointer left-align print_display_none',
      sortable: true,
    },
    {
      id: 2,
      label: 'sales_invoice_miti_title',
      title: 'Ref Miti',
      className: 'cursor-pointer left-align print_display_none',
      sortable: true,
    },
    {
      id: 4,
      label: 'buyer_name',
      title: 'Buyers Name',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 5,
      label: 'buyer_pan_no',
      title: 'Buyers PAN No.',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 11,
      label: 'business_alias',
      title: 'Item name',
      className: 'cursor-pointer left-align',
      sortable: true,
    },
    {
      id: 12,
      label: 'quantity',
      title: 'Quantity',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 8,
      label: 'tax_exempted_sales',
      title: 'Tax Exempted Sales Return',
      className: 'cursor-pointer right-align',
      sortable: false,
    },
    {
      id: 9,
      label: 'taxable_sales_amount',
      title: 'Total Sales Return',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    {
      id: 10,
      label: 'taxable_sales_vat',
      title: 'VAT',
      className: 'cursor-pointer right-align',
      sortable: true,
    },
    { id: 11, label: 'excise', title: 'Excise', className: 'cursor-pointer right-align', sortable: true},
  ],
  upperHeaderDetails: [
    {
      id: 1,
      label: 'invoice',
      title: 'Sales Return',
      className: 'table-cell center-align',
      sortable: false,
    },
    {
      id: 2,
      label: 'taxableSales',
      title: 'Taxable Sales Return',
      className: 'table-2-cell center-align',
      sortable: false,
    },
  ],
};
const lengthyAttributes = ['buyer_name',];
const printEnums = {
  withoutFooterLimit: 54,
  withFooterLimit: 53,
  buyer_name: 56,
  lengthyAttributes,
};

const config = {
  header: SRNVatReportConfig,
  getTableBody: SRNVatReportTableBody,
  getTableFooter: SVRTableFooter,
  printEnums,
  uniqueIdGetter: (i) => i.id
};

const getTotalDetails = (data = {}) => {
  const totalDetails = [
    {
      id: 2,
      label: 'miti_title',
      title: '',
      className: 'left-align',
      sortable: true,
    },
    {
      id: 3,
      label: 'sales_return_invoice_number',
      title: '',
      className: 'left-align',
      sortable: true,
    },
    {
      id: 3,
      label: 'bill_no',
      title: '',
      className: 'left-align print_display_none',
      sortable: true,
    },
    {
      id: 2,
      label: 'sales_invoice_miti_title',
      title: '',
      className: 'left-align print_display_none',
      sortable: true,
    },
    {
      id: 4,
      label: 'buyer_name',
      title: 'Total',
      className: 'total left-align',
      sortable: true,
    },
    {
      id: 5,
      label: 'buyer_pan_no',
      title: '.',
      className: 'left-align',
      sortable: true,
    },
    {
      id: 11,
      label: 'business_alias',
      title: '',
      className: 'left-align',
      sortable: true,
    },
    {
      id: 12,
      label: 'quantity',
      title: 'Quantity',
      className: 'right-align',
      sortable: true,
      value:data?.totalQuantity || 0,
    },
    {
      id: 8,
      label: 'tax_exempted_sales',
      title: 'Tax Exempted Sales Return',
      className: 'right-align',
      sortable: false,
      value:fixedFloatAndCommas(data?.taxExemptedSales) || 0,
    },
    {
      id: 9,
      label: 'taxable_sales_amount',
      title: 'Total Sales Return',
      className: 'right-align',
      sortable: true,
      value:fixedFloatAndCommas(data?.grossTotalTaxableAmount) || 0,
    },
    {
      id: 10,
      label: 'taxable_sales_vat',
      title: 'VAT',
      className: 'right-align',
      sortable: true,
      value:fixedFloatAndCommas(data?.vat) || 0,
    },
    {
      id: 11
,      label: 'total_excise',
      title: 'Excise',
      className: 'right-align',
      sortable: true,
      value:fixedFloatAndCommas(data?.excise) || 0,
    },
  ];
  if(!(JSON.parse(localStorage.getItem('rosiaCompany'))?.enableExcise)){
    totalDetails.pop()
  }

  return totalDetails;
};

export {
  SRNVatReportConfig, SRNVatReportTableBody, SVRTableFooter, config,getTotalDetails
};
