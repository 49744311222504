import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogBody,
  Grid,
  Cell,
  DialogFooter,
  Button,
} from '../../../components/BillingMDC';

import BillingTextField from '../../../components/TextField/BillingTextField';
import { ACTION } from '../../../data/enums/enums';

const AreaDialog = ({ area, actionType, onModalClose, onModalSubmit, onInputChange, validationFlag }) => {
  return (
    <Dialog
      open={actionType !== ACTION.NULL}
      onClose={() => {
        onModalClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>{actionType}</DialogTitle>
      </DialogHeader>

      <DialogBody>
        <div className='dialog-upperpart'>
          <Grid className='grid-padding'>
            <Cell col={6} tablet={12}>
              <BillingTextField
                value={area.title}
                handleChange={onInputChange}
                floatingLabel='Area'
                param='title'
                required
                className='right-side-gap'
                emptyField={validationFlag}
              />
            </Cell>
          </Grid>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button accent className='dialog-cancel modal-btn' onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default AreaDialog;
