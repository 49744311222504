const getConfigData = footerData => {
  const columns = [
    [
      { content: 'SN', rowSpan: 2 },
      { content: 'Miti', rowSpan: 2 },
      { content: 'Bill Number', rowSpan: 2 },
      { content: 'Buyers', colSpan: 2, styles: { halign: 'center' } },
      { content: 'Item Name', rowSpan: 2 },
      { content: 'Quantity', rowSpan: 2 },
      { content: 'Tax Exempted Sale', rowSpan: 2 },
      { content: 'Export Amount', rowSpan: 2 },
      { content: 'Total Sales', rowSpan: 2 },
      { content: 'VAT', rowSpan: 2 },
    ],
    [{ content: 'Name' }, { content: 'PAN No.' }],
  ];

  JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && columns[0].push({ content: 'Excise',rowSpan: 2 });


  const footer = [
    [
      '',
      'Total',
      '',
      '',
      '',
      '',
      { content: footerData?.totalItemQuantity ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalTaxExemptedSales ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalExportSales ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalTaxableAmount ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalVat ?? '', styles: { lineWidth: 0.1 } },
      { content: footerData?.totalExcise ?? '', styles: { lineWidth: 0.1 } },
    ],
  ];

  const columnStyles = {
    0: { cellWidth: 10 },
    1: { cellWidth: 15 },
    3: { cellWidth: 24 },
    5: { cellWidth: 18 },
    7: { cellWidth: 15 },
    // 8: { cellWidth: 17 },
  };

  const cellsToAlignRight = { 5: '5', 6: '6', 7: '7', 8: '8', 9: '9' };

  return {
    columns,
    footer,
    columnStyles,
    cellsToAlignRight,
  };
};

export default getConfigData;
