import React, { Fragment } from 'react';
import { Cell, Grid } from '../../components/BillingMDC';

const PageButtonWrapper = ({ renderBtn }) => {
  return (
    <Fragment>
      <div className='newinvoice-btn-wrapper'>
        <Grid>
          <Cell col={8} />
          <Cell col={4} className='right-align'>
            {renderBtn()}
          </Cell>
        </Grid>
      </div>
    </Fragment>
  );
};

export default PageButtonWrapper;
