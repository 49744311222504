import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from 'react-mdc-web';

const StockJournalTableBody = ({ data, onRowClick }) => {
  return (
    <tr className='cursor-pointer' onClick={() => onRowClick(data)}>
      <td className='left-align'>{data.idStockJournal}</td>
      <td className='left-align'>{moment(data.date).format('ll')}</td>
      {/*<td className="left-align">{data.remarks}</td>*/}
    </tr>
  );
};

StockJournalTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};

const stockJournalConfig = {
  headerDetails: [
    { id: 1, label: 'idStockJournal', title: 'SJ ID', className: 'cursor-pointer', sortable: true },
    { id: 2, label: 'date', title: 'Date', className: 'cursor-pointer left-align', sortable: true },
    /* {id: 3, label: 'remarks', title: 'Remarks', className: 'cursor-pointer left-align', sortable: false}*/
  ],
};

export { stockJournalConfig, StockJournalTableBody };
