import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import orderBy from 'lodash/orderBy';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { Fab, Icon, Menu, Drawer, MenuItem, Textfield, MenuAnchor, LinearProgress } from 'react-mdc-web';
import * as appConstants from '../../config';
import history from '../../../utils/history';
import * as httpUtils from '../../../utils/httpUtils';
import Pagination from '../../../components/Pagination';
import TableHeader from '../../../components/TableHeader';
import * as filterUtil from '../../../utils/filterUtil';
import DateToRender from '../../../components/DateToRender';
import { getFixTableMeasurements } from '../../../utils/tableMetrics';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import {
  journalVoucherReportConfig,
  JournalVoucherReportTableBody,
  downloadFormat,
} from './JournalVoucherReportConfig';
import { JOURNAL_VOUCHER_CREATE, JOURNAL_VOUCHER_DETAILS } from '../../../data/enums/Route';

//const businessUnitID = 1;
const userCreatedTransactionType = 5;

class JournalVoucherReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showSearchBox: false,
      //dataSet: [{ledger:'Sales',debit:123458,credit:234567,refNo:12,refDate:'07/03/2018',narration:'Narration'}],
      dataFromServer: [],
      dataSet: [],
      totalData: 1,
      dateClickCount: 0,
      businessUnitId: '',
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
        },
      },
    };
  }

  /** adjust table width and height according to screen **/
  componentDidMount() {
    this.getTableDataForGrid();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    let table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
  }

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    this.setState({ loading: true });
    const { queryParameters } = this.state;
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    httpUtils
      .get(
        appConstants.baseUrl +
          `report/journal-summary?start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&page=${queryParameters.pagination.page}&limit=${queryParameters.pagination.limit}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data.list, totalData: response.data.total, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  groupData = () => {
    const { dataFromServer } = this.state;
    const data = this.groupBy(dataFromServer, 'voucher_number');
    const array = [];
    Object.keys(data).forEach(key => {
      const obj = {
        voucher_number: key,
        ledger_name: data[key][0]['ledger_name'],
        transaction_date: data[key][0]['transaction_date'],
        miti_title: data[key][0]['miti_title'],
        journalDetails: data[key],
      };
      array.push(obj);
    });
    this.setState({ dataSet: array, totalData: array.length });
  };

  groupBy = (data, prop) => {
    return data.reduce((groups, item) => {
      const val = item[prop];
      groups[val] = groups[val] || [];
      groups[val].push(item);

      return groups;
    }, {});
  };

  onJournalVoucherItemClick = (id, type, vNo) => {
    const voucherNo = vNo.replace(/[/]/g, '%2F');
    if (type === userCreatedTransactionType) {
      history.push(`/${JOURNAL_VOUCHER_DETAILS}/${voucherNo}`);
    }
    /* todo else {

    }*/
  };

  /** onPagination Change */
  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getTableDataForGrid());
  };

  /** on Date Range Change */
  handleDateRangeChange = (start, end) => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  handleDateChange = date => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = date.format('DD MMM YYYY');
    queryParameters.date.end = date.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  /** handle download click
   * params: id => download format type id  */
  handleDownloadClick = id => {
    let type = '';
    const self = this;
    const schema = 'https://';
    const { queryParameters } = this.state;
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    if (id === 1) {
      type = 'CSV';
    }
    httpUtils
      .get(
        appConstants.baseUrl +
          `download/journal-voucher?start_date=${queryParameters.date.start_date}
      &end_date=${queryParameters.date.end_date}
      &page=${queryParameters.pagination.page}
      &limit=${queryParameters.pagination.limit} 
      &bu=${this.state.businessUnitId}
      &type=${type}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ loading: false });
          const url = response.data.url;
          const newWin = window.open(schema + window.location.hostname + url);
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            alert('Please enable pop for this site');
          }
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.dataSet;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';
    return orderBy(this.state.dataSet, labelName, sortingName);
  };

  handleFabButtonClick = () => {
    history.push(`${JOURNAL_VOUCHER_CREATE}`);
  };

  render() {
    const { loading, queryParameters, totalData, sorting, showSearchBox } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    return (
      <div>
        <div>
          {loading && (
            <div className="linear-progress-wrapper temp-progress-wrapper">
              <LinearProgress accent indeterminate />
            </div>
          )}
          <div className={loading ? 'clickable-false' : ''}>
            <div className="card-header-bar" ref="tableReference">
              <h2>Journal Voucher</h2>
              <div className="header-menu">
                <div className="header-menu-right">
                  <span className="date-rendered">
                    <Icon name="date_range" className="material-icons" />
                    <DateToRender date={queryParameters.date} />
                  </span>
                  <span className="download-menu" onClick={() => this.setState({ showMenu: !this.state.showMenu })}>
                    <Icon name="get_app" />
                  </span>
                  <MenuAnchor>
                    <Menu
                      open={this.state.showMenu}
                      onClose={() => {
                        this.setState({ showMenu: false });
                      }}
                      className="transaction-download"
                    >
                      <MenuItem
                        onClick={() => {
                          this.handleDownloadClick(downloadFormat[0].id);
                        }}
                      >
                        {downloadFormat[0].value}
                      </MenuItem>
                    </Menu>
                  </MenuAnchor>

                  <span onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                    <Icon name="filter_list" />
                  </span>
                </div>
              </div>
              {/*<Grid>
                <Cell col={4}>
                  <span className="header-title-content">
                    <span className="active-opacity-text">Voucher No:</span>
                    <span>{this.state.voucherNo}123456</span>
                  </span>
                </Cell>
                <Cell col={4}>
                  <div className="center-align">
                    <h2>Journal Voucher</h2>

                  </div>
                </Cell>
                <Cell col={4}>
                  <div className="header-menu-right">
                    <span onClick={() => this.setState({showFilter: !this.state.showFilter})}>
                      <Icon name='filter_list'/>
                    </span>
                  </div>
                  <div className="header-menu-right">
                    <span className="header-right-content">
                     <span className="header-right">{this.state.queryParameters.date.end}</span>
                    </span>
                  </div>
                </Cell>
              </Grid>*/}
            </div>
            <div className="create-fab-button">
              <Fab onClick={() => this.handleFabButtonClick()}>
                <Icon name="add" />
              </Fab>
            </div>
          </div>

          <div ref="fixedTableBody" className="fixed-table-wrapper">
            <table>
              <TableHeader
                headerDetails={journalVoucherReportConfig.headerDetails}
                filterHeaderLabel={false}
                handleSorting={this.handleTableSorting}
              />
              {sortedDataSet.map((data, key) => (
                <tbody>
                  <JournalVoucherReportTableBody
                    data={data}
                    onRowClick={this.onJournalVoucherItemClick}
                    type={userCreatedTransactionType}
                  />
                </tbody>
              ))}
            </table>
          </div>

          {
            <div ref="fixedTableFooter">
              <Pagination
                pageSize={queryParameters.pagination.limit}
                currentPage={queryParameters.pagination.page}
                orientation="top"
                totalItems={totalData}
                onPageSelect={this.onPageSelect}
              />
            </div>
          }
        </div>
        <div dir="rtl">
          <Drawer
            open={this.state.showFilter}
            className="right-drawer"
            onClose={() => {
              this.setState({ showFilter: false });
            }}
          >
            <div className="filter-wrapper">
              <h2 className="default-horizontal-padding-24">
                <span>Filter</span>
                <span className="float-right">
                  <Icon name="replay" onClick={() => this.resetFilter()} />
                  <Icon name="clear" onClick={() => this.setState({ showFilter: false })} />
                </span>
              </h2>
              <div className="divider"></div>
              <DateRangePicker
                startDate={moment(queryParameters.date.start)}
                endDate={moment(queryParameters.date.end)}
                onChange={this.handleDateRangeChange}
              />
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

JournalVoucherReport.contextTypes = {
  router: PropTypes.object,
};

/*function mapDispatchToProps(dispatch) {
  return {
    onJournalVoucherRowClick: (action) => dispatch(action)
  };
}*/

//const journalVoucher = connect(null, mapDispatchToProps)(JournalVoucherReport);

export default JournalVoucherReport;
