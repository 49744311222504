import React from 'react';
import moment from 'moment';
import * as filterUtil from '../../../utils/filterUtil';
import { formatToReadableDate } from '../../../utils/filterUtil';

const migrateTitle = 'Migrate Fiscal Year';
const confirmationTitle = 'Confirm Migration';
const downLoadFYMSumaryTitle = 'Fiscal Year Migration Summary';

const FYMBody = ({ dataList, onRowClick }) => {
  return (
    <tbody>
      {dataList.map((data, index) => (
        <tr>
          <td className='left-align'>{data.startDate !== null && formatToReadableDate(data.startDate)}</td>
          <td className='left-align'>{data.endDate !== null && formatToReadableDate(data.endDate)}</td>
          <td className='left-align'>
            {data.migrationDate !== null &&
              `${formatToReadableDate(data.migrationDate)}/${moment(data.migrationDate).format('hh:mm:ss A')}`}
          </td>
          <td className='right-align'>
            {data.migrationDate === null && data.isFiscalYearCompleted && (
              <span onClick={() => onRowClick(data, index)} className='table__textIcon'>
                Close FY
              </span>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};
const formValidationFieldList = [{ title: 'title' }];

const FYIHeader = [
  {
    id: 1,
    title: 'Fiscal Year start',
    label: 'fiscal_year_start',
    className: '',
    sortable: false,
  },
  {
    id: 2,
    title: 'Fiscal Year End',
    label: 'fiscal_year_end',
    className: '',
    sortable: false,
  },
  {
    id: 3,
    label: 'migration_date',
    title: 'Migration Date/Time',
    className: '',
    sortable: false,
  },
  {
    id: 4,
    label: '',
    title: '',
    className: '',
    sortable: false,
  },
];
const fymMapper = (data = {}) => ({
  title: data.title || '',
  startDate: data.startDate || filterUtil.getCurrentDay(),
  endDate: data.endDate || filterUtil.getCurrentDay(),
  accountGroup: data.accountGroup,
  showStock: data.showStock || true,
  jvEntry: data.jvEntry || true,
});

const config = {
  header: FYIHeader,
  getTableBody: FYMBody,
  confirmationTitle,
  migrateTitle,
};

export default config;
export {
  confirmationTitle, migrateTitle, fymMapper, formValidationFieldList, downLoadFYMSumaryTitle
};
