import { compose } from 'recompose';

import Customer from './Customer';

import apiInterceptor from '../../../api/interceptor';

import { ledgerApi, areaApi ,agentApi} from '../../common/base.api';

const composedCustomer = compose(apiInterceptor({ ...ledgerApi ,areaListAll:areaApi?.getAreaList,agentListAll:agentApi?.getAgentList}))(Customer);

export default composedCustomer;
