import { compose } from 'recompose';

import StockLedger from './StockLedger';

import apiInterceptor from '../../../api/interceptor';

import { stockLedgerApi } from '../../common/base.api';

const composedStockLedger = compose(apiInterceptor({ ...stockLedgerApi }))(StockLedger);

export default composedStockLedger;
